import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import axiosInstance from '../../../../../../../services/api';
import CustomInput from '../../../../../../../components/customInput/customInput';
import LineSkeleton from '../../../../../../../components/lineSkeleton';
import EmptyState from '../../../../../../../components/emptyState/emptyState';
import { Box, Flex, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { HiArrowLeft, HiArrowNarrowLeft, HiChevronDoubleLeft, HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import PrimaryButton from '../../../../../../../components/primaryButton/primarybutton';
import CustomPagination from 'components/customPagination/customPagination';

const StoreSilo = ({tempData, setTempData, setTriggerClose}) => {

  const [loading, setLoading] = useState(false)
  const [loadingx, setLoadingx] = useState(false)
  const [initiatives, setInitiatives] = useState([]);
  const [initiativeStores, setInitiativeStores] = useState([]);
  const [searchVal, setSearchVal] = useState("")

  const [view, setView] = useState("init")
  const [init, setInit] = useState("init")

  const limit = 500

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState('')

  const {iid} = useParams()

  const currentUser = useSelector(({userData})=>  userData?.currentUser);

  const getInitiatives = async() => {
    setLoading(true)
    try {
        const { data } = await axiosInstance.get(currentUser?.role === "Client" ? `initiatives?client=${currentUser?._id}&search=${searchVal}` : `initiatives?search=${searchVal}`)
        setInitiatives(data?.data)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
  }

  useEffect(() => {
    getInitiatives()
  }, [searchVal])

  const getInitiativeStores = async() => {
    setLoading(true)
    try {
        const { data } = await axiosInstance.get(`initiatives/${init?._id}/initiative-stores?limit=${limit}&page=${page}`)
        setInitiativeStores(data?.data)
        setTotal(data?.totalCount);
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
  }

  useEffect(() => {
    getInitiativeStores()
  }, [init, page])


  const handleStoreView = (init) => {
    setInit(init)
    setView("store")
  }

  const handleImport = (data) => {
    setTempData(initiativeStores)
    setTriggerClose('close')
  }

  return (
    <>
    {view === "init" &&
    <Box>
      <CustomInput
      type={"text"}
      placeholder={"Search initiatives"}
      mb="1rem"
      onChange={e => setSearchVal(e.target.value)}
      />

      {loading ?
      <LineSkeleton num={6} />
      :
      initiatives?.length < 1 ?
      <EmptyState text="No stores list references found" />
      :
      initiatives?.filter(i => i?._id !== iid).map(({_id: id, name, createdAt, status, client, description, instructions, isDeleted, imageURL}) => {
        const data = {_id: id, name, createdAt, status, client, description, instructions, isDeleted, imageURL}
        return (
          <Flex cursor={"pointer"} onClick={() => handleStoreView(data)} position={"relative"} padding={".7rem 1rem"} gap=".6rem" alignItems={"center"} mt=".8rem" borderRadius={"6px"} border={"1px solid lightgrey"}>
            <Box height={"35px"} width={"40px"} bg="lightgray" backgroundImage={imageURL} backgroundSize={"cover"} borderRadius={"4px"} />
            <Box>
              <Text fontSize={".9rem"} mb=".15rem" fontWeight={"500"}>{name}</Text>
              <Text fontSize={".75rem"} fontWeight={"500"} opacity={".65"}>{moment(createdAt).calendar()}</Text>
            </Box>
            <Text position={"absolute"} fontSize={".75rem"} fontWeight={"500"} bottom={"3%"} right={"2%"} opacity={".65"}>Client: <span style={{color: "#7F56D9", fontWeight: "600"}}>{client?.companyName}</span></Text>
          </Flex>
        )
      })
      }
    </Box>}

    {view === "store" &&
    <Box>
      <HiArrowNarrowLeft onClick={() => setView("init")} style={{cursor: "pointer", transform: "scale(1.3)"}} />
      <br />
      {loading ?
      <LineSkeleton num={6} />
      :
      initiativeStores?.length < 1 ?
      <EmptyState text="No stores list references found" />
      :
      <Box height={"65vh"} overflowY={"scroll"}>
      {initiativeStores?.map(({_id: id, store, initiative, promoter, supervisor}) => {
        return (
          <Flex onClick={handleImport} padding={".7rem 1rem"} gap=".6rem" alignItems={"center"} mt=".8rem" borderRadius={"6px"} border={"1px solid lightgrey"}>
            <Box height={"35px"} width={"40px"} bg="lightgray" backgroundImage={""} backgroundSize={"cover"} borderRadius={"4px"} />
            <Box>
              <Text fontSize={".9rem"} mb=".1rem" fontWeight={"500"} textTransform={"capitalize"}>{store?.name}</Text>
              <Text fontSize={".75rem"} fontWeight={"500"} opacity={".65"}>{`${store?.address}, ${store?.state}`}</Text>
            </Box>
          </Flex>
        )
      })}
      </Box>
      }
       <Flex gap="2.5rem" mt="1rem" w="fit" alignItems={"center"} px="1.5rem" borderRadius={"24px"} height={"50px"} bg="#FFF" boxShadow={"rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset"}>
            <Text fontWeight={"500"} whiteSpace={"nowrap"} fontSize={".85rem"}>Showing items {((page * limit) + 1) - limit} - {limit > total ? (total * page) : (limit * page)}</Text>
            <Flex alignItems={"center"} gap="1rem">
                {page >= 3 && <HiChevronDoubleLeft cursor={"pointer"} onClick={() => setPage(1)} />}
                <HiChevronLeft cursor={"pointer"} onClick={page >= 2 ? () => setPage(page - 1) : {}} />
                <Text fontWeight={"500"} fontSize={".85rem"}>{page}</Text>
                {(limit * page) < total && <HiChevronRight onClick={() => setPage(page + 1)} cursor={"pointer"} />}
            </Flex>
        </Flex>
      <PrimaryButton onClick={handleImport} mt="1rem" text={"Import List"} />
    </Box>}
    </>
  )
}

export default StoreSilo