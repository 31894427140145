/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../../../services/api";
import CustomInput from "../../../../../components/customInput/customInput";
import LineSkeleton from "../../../../../components/lineSkeleton";
import EmptyState from "../../../../../components/emptyState/emptyState";
import ExportCSV from "../../../../../components/exportCsv";
import { useSelector } from "react-redux";
import {
  buildResourceURL,
  buildResourceURL2,
} from "../../../../../utils/helper";
import ServerExport from "../../../../../components/serverExport/serverExport";
import classes from "./attendance.module.css";
import { formatDateHeader } from "../../../../../helpers/formatDateHeader";
import { numberFormat } from "../../../../../helpers/numberFormatter";
import { convertToHoursAndMinutes } from "../../../../../helpers/convertToHoursAndMinutes";
import { InitiativeEmptyState } from "../../../../../components/initiativeEmptyState/initiativeEmptyState";
import { Loader } from "../../../../../components/Loader/loader";
import { InitiativePaginationComponent } from "components/initiativePaginationComponent/initiativePaginationComponent";
import { ServerExport2 } from "components/NewExport/NewExport";

const AggAttendance = ({ data }) => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  const currentUser = useSelector(({ userData }) => userData?.currentUser);
  const [total, setTotal] = useState('')



  const [paginationValues, setPaginationValues] = useState({
    page: 1,
    limit: 100
  })

  const { limit, page } = paginationValues

  const [searchVal, setSearchVal] = useState("");
  const [inputPage, setInputPage] = useState(page);

  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [startDate, setStartDate] = useState(formattedDate);
  const [endDate, setEndDate] = useState(formattedDate);

  const { iid } = useParams();

  const handleDate = (e) => {
    setStartDate(e.target.value);
  };
  const handleDatex = (e) => {
    setEndDate(e.target.value);
  };


  const queryParams = [
    {
      key: "initiativeId",
      value: iid,
    },
    {
      key: "startDate",
      value: startDate,
    },
    {
      key: "endDate",
      value: endDate,
    },
    {
      key: "searchQuery",
      value: searchVal,
    },
    {
      key: "page",
      value: page,
    },
    {
      key: "limit",
      value: limit,
    },
    {
      key: 'download',
      value: false
    },
  ];

  const getRecords = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        buildResourceURL("attendances/aggregate", queryParams)
      );
      setRecords(data?.aggregateResult);
      setTotal(data?.totalCount)

    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRecords();
  }, [startDate, endDate, searchVal, paginationValues]);

  const refinedData = records?.map((i) => ({
    date: records?.date,
    name: i?.store?.name,
    address: i?.store?.address,
    state: i?.store?.state,
    ownerName: i?.store?.ownerName,
    ownerPhoneNumber: i?.store?.ownerPhoneNumber,
    hoursWorked: i?.totalHoursWorked,
    promoter: i?.promoter?.firstName + " " + i?.promoter?.lastName,
  }));

  const headers = [
    {
      key: "date",
      name: "Date",
    },
    {
      key: "name",
      name: "Store Name",
    },
    {
      key: "address",
      name: "Store Address",
    },
    {
      key: "state",
      name: "Store State",
    },
    {
      key: "promoter",
      name: "Promoter",
    },
    {
      key: "ownerName",
      name: "Store Owner Name",
    },
    {
      key: "ownerPhoneNumber",
      name: "Store Owner Phone",
    },
    {
      key: "hoursWorked",
      name: "Total Hours Worked",
    },
  ];



  const querParamx = queryParams.filter((item) => (
    item.key !== 'page' && item.key !== 'limit' && item.key !== 'download'
  ))

  querParamx.push({
    key: 'download',
    value: true
  })

  return (
    <div className={classes.screenContainer}>
      <Flex mb="1.2rem" alignItems={"center"} justifyContent={"space-between"}>
        <Flex gap={"1.5rem"} alignItems={"center"}>
          <Text whiteSpace={"nowrap"}>
            <strong>{records?.totalCount}</strong> clock-ins
          </Text>
          <CustomInput
            type={"text"}
            required
            value={searchVal}
            placeholder={`Search by outlet or promoter..`}
            onChange={(e) => setSearchVal(e.target.value)}
            mt="0"
            sType="search"
            py="1.19rem"
            width="350px"
          />
        </Flex>
        <Flex gap="1rem" alignItems={"center"}>
        <ServerExport2  entity={"attendances/aggregate"} queryParamsx={querParamx} />

        <Box width={"150px"}>
                        <CustomInput
                            type={"date"}
                            required
                            py=".95rem"
                            placeholder={"Select Date"}
                            onChange={handleDate}
                            mt="0"
                            value={startDate}
                        />
                    </Box>
                    <Box width={"150px"}>
                        <CustomInput
                            type={"date"}
                            required
                            py=".95rem"
                            placeholder={"Select Date"}
                            onChange={handleDatex}
                            mt="0"
                            value={endDate}
                        />
                    </Box>
        </Flex>
      </Flex>

      {loading ? (
        <div className={classes.loader_con}>
          <Loader />
        </div>
      ) : records?.length < 1 ? (
        <InitiativeEmptyState
          searchValues={null}
          name={"Daily attendance for this date"}
        />
      ) : (
        endDate !== "" && (
          <div className={classes.scrollable}>
            <p className={classes.title}>Store Daily Attendance Found: {total}</p>
            <p className={classes.showing}>
              Showing:{" "}
              <span>
                {records?.length || 1}
              </span>
            </p>

            <table className={classes.table}>
              <thead>
                <tr>
                  <th>Store</th>
                  <th>State</th>
                  <th>Store's Contact</th>
                  <th>Promoter</th>
                  <th>Supervisor</th>
                  <th>Hours Worked</th>
                </tr>
              </thead>
              <tbody>
                {records?.map((item, index) => (
                  <tr key={index}>
                    <td className={classes.tooltip_container}>
                      <p className={classes.item_text}>{item?.store?.name}</p>
                      <p className={classes.tooltip}>{item?.store?.name}</p>
                    </td>
                    <td className={classes.tooltip_container}>
                      <p className={classes.item_text}>{item?.store?.state}</p>
                      <p className={classes.tooltip}>{item?.store?.state}</p>
                    </td>
                    <td className={classes.tooltip_container}>
                      <p className={classes.item_text}>
                        {item?.store?.ownerPhoneNumber}
                      </p>
                      <p className={classes.tooltip}>
                        {item?.store?.ownerPhoneNumber}
                      </p>
                    </td>

                    <td className={classes.tooltip_container}>
                      <p
                        className={classes.item_text}
                      >{`${item?.promoter?.firstName} ${item?.promoter?.lastName}`}</p>
                      <p
                        className={classes.tooltip}
                      >{`${item?.promoter?.firstName} ${item?.promoter?.lastName}`}</p>
                    </td>

                    <td className={classes.tooltip_container}>
                      <p
                        className={classes.item_text}
                      >{`${item?.supervisor?.firstName} ${item?.supervisor?.lastName}`}</p>
                      <p
                        className={classes.tooltip}
                      >{`${item?.supervisor?.firstName} ${item?.supervisor?.lastName}`}</p>
                    </td>

                    <td className={classes.tooltip_container}>
                      <div className={classes.attendanceIndicatorContainer}>
                        <div
                          style={
                            item?.totalHoursWorked / 8 > 1
                              ? { width: "100%" }
                              : {
                                width: `${(item?.totalHoursWorked / 8) * 100
                                  }%`,
                              }
                          }
                          className={
                            item?.totalHoursWorked < 2.67
                              ? classes.attendanceIndicatorRed
                              : item?.totalHoursWorked < 5.33
                                ? classes.attendanceIndicatorOrange
                                : classes.attendanceIndicatorGreen
                          }
                        />
                      </div>
                      <p className={classes.item_text}>
                        {convertToHoursAndMinutes(item?.totalHoursWorked)}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <InitiativePaginationComponent
                            page={page} totalCount={total} setSearchValues={setPaginationValues} setInputPage={setInputPage} inputPage={inputPage} limit={paginationValues.limit}
                        />
          </div>
        )
      )}


    </div>
  );
};

export default AggAttendance;
