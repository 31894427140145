import classes from './assetsComponents.module.css'

export function CustomSelect({ groupList, searchValues, setSearchValues, type }) {
console.log(searchValues)

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setSearchValues((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    return (
        <div className={classes.dateContainer}>
            <select
                value={searchValues?.[type] || ""}
                name={type}
                onChange={handleInputChange}
                className={classes.input}
            >
                <option value={""}>{type}</option>
                {groupList?.map((item, index) => (
                    <option value={item?._id} key={index}>
                        {item?.name}
                    </option>
                ))}
            </select>
        </div>
    )
}