import { Box, Divider, Flex, Menu, MenuButton, MenuItem, MenuList, Switch, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { truncateText } from '../../../../../../../../../utils/helper'
import axiosInstance from '../../../../../../../../../services/api'
import CustomDrawer from '../../../../../../../../../components/customDrawer/customDrawer'
import SecondaryButton from '../../../../../../../../../components/secondaryButton/secondarybutton'
import EmptyState from '../../../../../../../../../components/emptyState/emptyState'
import PrimaryButton from '../../../../../../../../../components/primaryButton/primarybutton'
import ExportCSV from '../../../../../../../../../components/exportCsv'
import CreateCompetitor from './createBrand'
import UpdateCompetitor from './updateBrand'

const PreloadedBrands = ({initiative, setRefetch}) => {
    const [brands, setBrands] = useState(initiative?.sosBrands);
    const [loadingx, setLoadingx] = useState(false);
    const [modalType, setModalType] = useState("");
    const [selectedUser, setSelectedUser] = useState(null)

    const [triggerClose, setTriggerClose] = useState("");
    const currentUser = useSelector(({userData})=>  userData?.currentUser);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast()
   
    const handleModal = (type, user) => {
        setModalType(type)
        setSelectedUser(user)
        onOpen()
    }

    const deleteBrand = async() => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.delete(`/initiatives/${initiative?._id}/sos-brands/${selectedUser?.id}`)
            toast({
            description: data?.message,
            status: "success",
            position: "top"
            });
            onClose()
            setRefetch(Math.random())
        } catch(error) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }

    const headers = [
        {
          key: '_id',
          name: 'ID',
        },
        {
          key: 'name',
          name: 'Brand Name',
        },
        {
          key: 'sku',
          name: 'SKU',
        },
        {
          key: 'imageURL',
          name: 'Image',
        },
      ]


  return (
    <>
    <Flex mb="1rem" justify={"flex-end"}>
        </Flex>

        <Flex alignItems={"center"} gap=".8rem" justifyContent={"space-between"}>
            <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>SOS Brands <span style={{padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054"}}>{brands?.length}</span></Text>

            <Flex alignItems={"center"} gap="1rem">
                <ExportCSV headers={headers} items={initiative?.sosBrands} />

                {currentUser?.role?.includes("Admin") &&
                <CustomDrawer
                position={"right"}
                title={"Add Brand"}
                triggerClose={triggerClose}
                setTriggerClose={setTriggerClose}
                toggleElement={
                    <SecondaryButton type="submit" text={"Add Brand"} icon="add" loading={false}  />
                }
                content={
                    <CreateCompetitor iid={initiative?._id} setTriggerClose={setTriggerClose} setRefetch={setRefetch} />
                }
                />}
            </Flex>

        </Flex>
    <Box  p="0 1rem" bg="#FFF" mt="1rem" height={"84vh"} overflowY={"scroll"} borderRadius={"12px"}>
        
        <Table mt="1.2rem" borderRadius={"12px"} overflow={"hidden"}>
            <Thead bg="#F9FAFB" border="1px solid #EAECF0">
                <Tr>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>ID</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Brand Name</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>SKU</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Image</Th>
                    <Th></Th>
                </Tr>
            </Thead>
            
            {brands?.length < 1 ?
            <EmptyState mt="1.5rem" text="There are no preloaded brands yet" />
            :
            <Tbody>
            
            {brands?.map((({_id: id, name, sku, imageURL}) => {
                const data = {id, name, sku, imageURL}
                return (
                    <Tr key={id}>
                        <Td color={"#475467"} fontSize={".9rem"}>{truncateText(id, 10)}</Td>
                        <Td>
                        <Flex alignItems={"center"} gap=".4rem">
                            {/* <Box height={"40px"} width={"40px"} backgroundImage={profilePicture} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}  /> */}
                            <Box>
                                <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{name}</Text>
                            </Box>
                        </Flex>
                        </Td>
                        <Td color={"#475467"} fontSize={".9rem"}>{sku}</Td>
                        <Td color={"#475467"} fontSize={".9rem"}>
                            <Box height={'35px'} width={'38px'} borderRadius={'3px'} backgroundImage={imageURL} backgroundSize={'cover'} />
                        </Td>
                        <Td>
                            <Menu width={"200px !important"}>
                                <MenuButton>
                                    <BiDotsVerticalRounded style={{cursor: "pointer"}} />
                                </MenuButton>
                                <MenuList>
                                    <Divider my=".2rem" />
                                    {currentUser?.role === "Super Admin" &&
                                    <>
                                    <CustomDrawer 
                                    position={"right"}
                                    title={"Update"}
                                    triggerClose={triggerClose}
                                    toggleElement={
                                        <MenuItem fontWeight={"500"} fontSize={".85rem"}>Edit</MenuItem>
                                    }
                                    content={
                                        <UpdateCompetitor iid={initiative?._id} setTriggerClose={setTriggerClose} setRefetch={setRefetch} compData={data} />
                                    }
                                    />
                                    <Divider my=".2rem" />
                                    <MenuItem onClick={() => handleModal('delete', {name, id})} color={"crimson"} fontWeight={"500"} fontSize={".85rem"}>Delete</MenuItem>
                                    <Divider my=".2rem" />
                                    </>}
                                </MenuList>
                            </Menu>
                        </Td>
                    </Tr>
                )
            }))
            }
            </Tbody>}
        </Table>

        <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{`${modalType} Brand`}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {modalType === 'delete' &&
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm deletion of {selectedUser?.name}?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                            <PrimaryButton onClick={deleteBrand} text="Delete" width="150px" bg="crimson !important" padding="1rem !important" loading={loadingx} />
                        </Flex>
                    </>
                    }
                    <Text pb="1rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Cancel</Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    </Box>
    </>
  )
}

export default PreloadedBrands;