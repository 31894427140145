import React, { useState } from 'react'
import { Box, Flex, useToast } from '@chakra-ui/react';
import ImageUploader from '../../../../../../../../../components/imageUploader/imageUploader';
import axiosInstance from '../../../../../../../../../services/api';
import CustomInput from '../../../../../../../../../components/customInput/customInput';
import PrimaryButton from '../../../../../../../../../components/primaryButton/primarybutton';


const CreateCompetitor = ({setTriggerClose, iid, setRefetch}) => {
  const [sku, setSku] = useState("");
  const [name, setName] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingi, setLoadingi] = useState(false);

  const toast = useToast();

  const handleImagesUpload = (images) => {
    setImageURL(images[0])
  };

  const createCompetitor = async(e) => {
    e.preventDefault()
    setLoading(true)
    const datax = imageURL !== '' ?
    {
      name,
      sku,
      imageURL
    }
    :
    {
      name,
      sku,
    }

    try {
      const {data} = await axiosInstance.post(`/initiatives/${iid}/order-brands`, datax)
      toast({
        description: data?.message,
        status: "success",
        position: "top"
      });
      setTriggerClose('close' + (Math.random()))
      setRefetch(Math.random())
    } catch(error) {
      if(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
      });
      }
    } finally {
      setLoading(false)
    }
  }


  return (
    <form onSubmit={createCompetitor}>
        <CustomInput
        type={"text"}
        required
        placeholder={"Brand Name"}
        onChange={e => setName(e.target.value)}
        />
        <CustomInput
        type={"text"}
        required
        placeholder={"SKU"}
        onChange={e => setSku(e.target.value)}
        />
        <Flex my="1rem" gap=".5rem">
            {imageURL !== '' && <Box backgroundImage={imageURL} backgroundSize={"cover"} height={"100px"} width={"120px"} borderRadius={"4px"} backgroundColor={"lightgray"} />}
            <ImageUploader
            onImagesUpload={handleImagesUpload}
            setLoading={setLoadingi}
            loading={loadingi}
            max={1}
            />
        </Flex>
        <PrimaryButton mt="1rem" type="submit" text="Add Brand" func={() => {}} loading={loading} />
    </form>
  )
}

export default CreateCompetitor;