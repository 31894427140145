import { defaultDrawerState } from "../constants/defaultStates";
import UtilityContext from "./utilityContext";
import { useState } from "react";



const UtilityState = ({ children }) => {

    const [drawerProps, setDrawerProps] = useState(defaultDrawerState)

    const draw = ({ title, content }) => {
        setDrawerProps({ title, content })

    }

    const closeDrawer = () => {
        setDrawerProps(defaultDrawerState)
    }




    return (
        <UtilityContext.Provider
            value={{
                draw, drawerProps, closeDrawer,
            }}
        >
            {children}
        </UtilityContext.Provider>
    );
};
export default UtilityState;