import { Box, Flex, useToast } from "@chakra-ui/react";
import CustomInput from "../../../../../../../../components/customInput/customInput";
import CustomSelect from "../../../../../../../../components/customSelect/customSelect";
import ImageUploader from "../../../../../../../../components/imageUploader/imageUploader";
import PrimaryButton from "../../../../../../../../components/primaryButton/primarybutton";
import { useState } from "react";
import axiosInstance from "../../../../../../../../services/api";
import SearchableSelect from "../../../../../../../../components/searchableSelect/searchableSelect";
import { useSelector } from "react-redux";
import CustomTabs from "../../../../../../../../components/customTabs/customTabs";

const CreateShipment = ({setRefetch, setTriggerClose, data}) => {
    const allStores = useSelector(({userData})=>  userData?.allStores);
    const [loadingx, setLoadingx] = useState(false);
    const [brand, setBrand] = useState('');
    const [sku, setSku] = useState('');
    const [totalCase, setTotalCase] = useState('');
    const [units, setUnits] = useState('');
    const [date, setDate] = useState('');
    const [isid, setIsid] = useState('')

    const [comment, setComment] = useState('');
    const [imageURL, setImageURL] = useState("");
    const [loadingu, setLoadingu] = useState(false);

    const [uploadFile, setUploadFile] = useState('')

    const handleImagesUpload = (images) => {
        setImageURL(images[0]);
    };

    const handleSelectChange = (selectedOption) => {
        setIsid(selectedOption?.value);
    };

    const toast = useToast();

    const selectedBrand = data?.brands?.find(i => i?.name === brand);

    const options1 = data?.brands?.map(i => ({
        title: i?.name,
        value: i?.name
    }))

    const options2 = data?.brands?.filter(i => i?.name === brand)?.map(i => ({
        title: i?.sku,
        value: i?.sku
      }))

    const addShipment = async () => {
        setTriggerClose("");
        setLoadingx(true);
        const myObj = {}

        const datax = {
          date: date?.slice(0, 10),
          brandName: brand,
          sku: sku,
          cases: parseInt(totalCase),
          units: parseInt(units),
          state: allStores?.find(x => x._id === isid)?.store?.state,
          comment,
          imageURL,
        };

        for (const key in datax) {
          const value = datax[key];
          if (value !== "" && value !== undefined && value !== null) {
            myObj[key] = value;
          }
        }

        try {
          const res = await axiosInstance.post(
            `initiative-stores/${isid}/shipments`,
            myObj
          );
          toast({
            description: res?.data?.message,
            status: "success",
            position: "top",
          });
          setTriggerClose("close");
          setRefetch(Math.random());
        } catch (error) {
          toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top",
          });
        } finally {
          setLoadingx(false);
        }
      };
    const uploadShipment = async () => {
        setTriggerClose("");
        setLoadingx(true);

        const datax = new FormData()
        datax.append('file', uploadFile)
       
        try {
          const res = await axiosInstance.post(
            `initiative-stores/${isid}/shipments/csv-upload`,
            datax
          );
          toast({
            description: res?.data?.message,
            status: "success",
            position: "top",
          });
          setTriggerClose("close");
          setRefetch(Math.random());
        } catch (error) {
          toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top",
          });
        } finally {
          setLoadingx(false);
        }
      };

      const uniqueBrandsList = options1?.filter((item, index, array) => {
        return (
          index === array.findIndex(obj => obj.title === item.title && obj.value === item.value)
        );
      });

      const storeOptions = allStores?.map(i => ({
            label: i?.store?.name,
            value: i?._id
      }))

  return (
    <>

    <CustomTabs data={[
      {
        title: 'Single',
        render: 
        <>
          <SearchableSelect
            options={storeOptions}
            onChange={handleSelectChange}
            placeholder="Select store"
          />
          <CustomSelect
            placeholder={"Brand"}
            selection={brand}
            setSelection={setBrand}
            options={uniqueBrandsList}
            mb=".8rem"
          />
          <CustomSelect
            placeholder={"SKU"}
            selection={sku}
            setSelection={setSku}
            options={options2}
          />
          <CustomInput
            type={"tel"}
            required
            placeholder={"Total Cases (Number of cases/cartons)"}
            onChange={(e) => setTotalCase(e.target.value)}
          />
          <CustomInput
            type={"tel"}
            required
            placeholder={"Total Units"}
            onChange={(e) => setUnits(e.target.value)}
          />
          <CustomInput
            type={"date"}
            required
            placeholder={"Date"}
            mb={".8rem"}
            onChange={(e) => setDate(e.target.value)}
          />
          <Flex gap=".5rem" alignItems={"center"}>
            {imageURL !== "" && (
              <Box
                backgroundImage={imageURL}
                backgroundSize={"contain"}
                height={"70px"}
                width={"100px"}
                borderRadius={"4px"}
                backgroundColor={"lightgray"}
              />
            )}

            <ImageUploader
              onImagesUpload={handleImagesUpload}
              setLoading={setLoadingu}
              loading={loadingu}
              max={1}
            />
          </Flex>
          <CustomInput
            type={"text"}
            required
            placeholder={"Comment"}
            mb={".8rem"}
            onChange={(e) => setComment(e.target.value)}
          />
          <PrimaryButton
            mt="2rem"
            onClick={addShipment}
            text="Save item"
            loading={loadingx}
          />
        </>
      },
      {
        title: 'Bulk',
        render: 
        <>
          <SearchableSelect
            options={storeOptions}
            onChange={handleSelectChange}
            placeholder="Select store"
          />
          <CustomInput
            type={"file"}
            p="1rem 1rem 2.8rem 1rem"
            required
            placeholder={"Select File"}
            mb={".8rem"}
            accept='.csv'
            cursor="pointer"
            onChange={(e) => setUploadFile(e.target.files[0])}
          />
          <PrimaryButton
            mt="2rem"
            onClick={uploadShipment}
            text="Upload Items"
            loading={loadingx}
          />
        </>
      }
    ]} />

    </>
  );
};

export default CreateShipment;
