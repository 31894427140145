import { userActionTypes } from "../constants/userActionTypes";
import axiosInstance from "../../services/api";

export const loaduser = (navigate) => async (dispatch) => {
  try {
    if (localStorage.PRTK) {
      const { data } = await axiosInstance.get(`/${localStorage?.userType}s/profile`);
      dispatch({ type: userActionTypes.LOADUSER_SUCCESS, payload: data?.data });
    } else {
      localStorage.removeItem("PRTK");
      window.location.href = "/";
    }
  } catch (error) {
    dispatch({ type: userActionTypes.LOADUSER_FAIL, payload: null });
    localStorage.removeItem("PRTK");
    window.location.href = "/";
    if (error.response) {
      localStorage.removeItem("PRTK");
      window.location.href = "/";
    }
  }
};
