import ListCompare from '../../../../../../../../components/listCompare/listCompare'
import PriceCheck from './priceCheck'
import { useState } from 'react'
import { InitiativeHeaderTab } from '../../../../../../../../components/initiativeHeaderTab/initiativeHeaderTab'
import classes from './priceChecks.module.css'
import AggregatePriceChecks from './aggregatePriceChecks'
import PeriodAggregate from './periodAggregate'

const AllPricing = ({ initiative }) => {

    const assignor = {
        "All Price Checked": <PriceCheck data={initiative} />,
        "Brand Aggregates": <AggregatePriceChecks data={initiative} />,
        "Store Aggregate": <PeriodAggregate iData={initiative} />,
        "Not Submitted": <ListCompare listType={'priceCheck'} />
    }

    const [selected, setSelected] = useState(Object.keys(assignor)[0])


    return (
        <div className={classes.container}>
            <InitiativeHeaderTab list={Object.keys(assignor)} selected={selected} setSelected={setSelected} />
            <div>
                {assignor[selected]}
            </div>
        </div>
    )
}

export default AllPricing;