export const userActionTypes = {
    SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
    SIGNUP_FAIL: 'SIGNUP_FAIL',
    SIGNIN_SUCCESS: 'SIGNIN_SUCCESS',
    SIGNIN_FAIL: 'SIGNIN_FAIL',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAIL: 'LOGOUT_FAIL',
    LOADUSER_SUCCESS: 'LOADUSER_SUCCESS',
    LOADUSER_FAIL: 'LOADUSER_FAIL',
    BIKEREQLOADER: "BIKEREQLOADER",
    SAVE_STORES: "SAVE_STORES"
}