import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {MdVerified} from 'react-icons/md'
import {FiUsers} from 'react-icons/fi'
import {LuMail} from 'react-icons/lu'
import { AiOutlineMobile, AiOutlineUser, AiTwotonePhone } from 'react-icons/ai'
import { BsTelephone } from 'react-icons/bs'
import { RxCalendar } from 'react-icons/rx'
import moment from 'moment'
import CopyButton from '../../copyButton'
import axiosInstance from '../../../services/api'
import SpinLoader from '../../spinLoader/spinLoader'
import CustomTabs from '../../customTabs/customTabs'
import CustomInput from '../../customInput/customInput'
import EmptyState from '../../emptyState/emptyState'
import ListMap from '../../mapView/mapViewList'
import { buildResourceURL, buildResourceURL2 } from '../../../utils/helper'
import { useNavigate } from 'react-router-dom'
import ViewTeam from '../viewTeam/viewTeam'
import ServerExport from '../../serverExport/serverExport'
import CustomSelect from '../../customSelect/customSelect'
import AttendanceAgg from './attendanceAgg'
import CustomDrawer from '../../customDrawer/customDrawer'
import ClockOutRationale from '../../../pages/dashboard/initiatives/initiativeDetails/initiativeStores/initiativeStoreDetails/components/attendance/clockOutRationale'
import { ChevronRightIcon } from '@chakra-ui/icons'

const ViewEmployee = ({data, title, type, setRefetch, refetch, setTriggerClose, triggerClose}) => {
  const [stores, setStores] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingx, setLoadingx] = useState(false)
  const [loadingy, setLoadingy] = useState(false)
  const [loadingz, setLoadingz] = useState(false)
  const [date, setDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [routes, setRoutes] = useState([]);
  const [records, setRecords] = useState([])

  const navigate = useNavigate();

  const getStores = async() => {
      setLoading(true)
      try {
          const res = await axiosInstance.get(`stores?user=${data?.id}`)
          setStores(res?.data?.data)
      } catch(error) {
          console.error(error?.response?.data?.message)
      } finally {
          setLoading(false)
      }
  }

  useEffect(() => {
      getStores()
  }, [])

  const queryParams = [
    {
        key: 'user',
        value: data?.id
    },
    {
        key: 'date',
        value: date
    },
    {
        key: 'date[gte]',
        value: startDate
    },
    {
        key: 'date[lte]',
        value: endDate
    },
]

  const getRoutes = async() => {
    setLoadingx(true)
    try {
      const { data } = await axiosInstance.get(buildResourceURL('route-trackers', queryParams))
      setRoutes(data?.data)
    } catch (error) {
      console.error(error?.response?.data?.message)
    } finally {
      setLoadingx(false)
    }
  }

  useEffect(() => {
    getRoutes()
  }, [startDate, setEndDate])

  const personalData = [
    {
      title: `${title} ID`,
      value: data?.id,
      icon: <FiUsers color='#98A2B3' />
    },
    {
      title: "Full Name",
      value: data?.firstName + " " + data?.lastName,
      icon: <AiOutlineUser color='#98A2B3' />
    },
    {
      title: "Email Address",
      value: data?.email,
      icon: <LuMail color='#98A2B3' />
    },
    {
      title: "Phone Number",
      value: data?.phoneNumber,
      icon: <BsTelephone color='#98A2B3' />
    },
    {
      title: "Date Joined",
      value: data?.createdAt ? moment(data?.createdAt)?.calendar() : null,
      icon: <RxCalendar color='#98A2B3' />
    },
    {
      title: "Device Change Requests",
      value: data?.deviceChangeRequestsCount,
      icon: <AiOutlineMobile color='#98A2B3' />
    }
  ]


  const coords = routes?.map((i) => ({
    coordinates: [i?.longitude, i?.latitude]
  }));

  const queryParamsx = [
    {
        key: 'user',
        value: data?.id
    },
    {
        key: 'clockInDate',
        value: date
    }
]

  const getAttendanceRecords = async () => {
    setLoadingz(true);
    try {
      const { data } = await axiosInstance.get(buildResourceURL('attendances', queryParamsx));
      setRecords(data?.data);
    } catch (error) {
      console.log(error?.response?.data?.message);
    } finally {
      setLoadingz(false);
    }
  };

  useEffect(() => {
    getAttendanceRecords();
  }, [date]);

  

  return (
    <Box>
        <Flex alignItems={"center"} gap=".7rem">
            <Box height={"45px"} width={"45px"} borderRadius={'50%'} position={"relative"} backgroundImage={data?.profilePicture} backgroundSize={"cover"} backgroundRepeat={"no-repeat"}>
              {data?.isApproved && <MdVerified color="#1671D9" style={{position: "absolute", zIndex: "10", bottom: "0", right: "-10%", transform: "scale(1.2)"}} />}
            </Box>
            <Box>
              <Text mb=".1rem" color={"#101828"} fontSize={"1.1rem"} fontWeight={"500"} textTransform={"capitalize"}>{data?.firstName + " " + data?.lastName}</Text>
              <Text color={"#475467"} fontSize={".8rem"} fontWeight={"500"}>{data?.email}</Text>
            </Box>
        </Flex>
        <Box mt="3rem">
          {personalData?.map(({title, value, icon}) => (
            <Flex key={title} mb="2rem" alignItems={"center"} justifyContent={"space-between"}>
              <Flex alignItems={"center"} gap="1rem">
                {icon}
                <Box>
                  <Text mb=".1rem" color={"#475467"} fontSize={".8rem"} fontWeight={"500"} textTransform={"capitalize"}>{title}</Text>
                  <Text color={"#101928"} fontSize={".9rem"} fontWeight={"500"}>{value}</Text>
                </Box>
              </Flex>
              <CopyButton toCopy={value} />
            </Flex>
          ))}
        </Box>
        <br />
        {type !== "ns" &&
        <CustomTabs data={[
          {
            title: "Onboarded Outlets",
            render: 
            <>
            <Flex mb=".5rem" justifyContent={"flex-end"}>
              <Text fontSize={".85rem"} color="#7F56D9" onClick={() => navigate(`/outlets?creator=${data?.id}`)} cursor={"pointer"} fontWeight={"500"} textDecoration={"underline"}>Full View</Text>
            </Flex>
            {loading ?
            <SpinLoader />
            :
            stores?.map(({name, area, state, createdAt}) => {
              return (
                <Flex p=".7rem" mb=".8rem" border=".5px solid lightgray" borderRadius={"8px"} justifyContent={"space-between"}>
                  <Box>
                    <Text textTransform={"capitalize"}>{name}</Text>
                    <Text fontSize={".8rem"} opacity={".7"} textTransform={"capitalize"}>{area}, {state}</Text>
                  </Box>
                    <Text fontSize={".7rem"} opacity={".7"} textTransform={"capitalize"}>Added: {moment(createdAt).calendar()}</Text>
                </Flex>
              )
            })}
            </>
          },
          {
            title: "Attendances",
            render: 
            <CustomTabs data={
              [
                {
                  title: "All Records",
                  render: 
                  <>
                  <Flex justifyContent={"flex-end"} mb="1rem" gap=".2rem">
                      <Box>
                        <CustomInput
                        width="130px"
                        type={"date"}
                        mt="0 !important"
                        py=".5rem !important"
                        required
                        placeholder={"Choose Date"}
                        onChange={e => setDate(e.target.value)}
                        cursor="pointer"
                        />
                      </Box>
                  </Flex>
            
            {loadingz ?
            <SpinLoader />
            :
            records?.map(
              ({
                _id: id,
                clockInTime,
                clockOutTime,
                clockInDate,
                hoursWorked,
                locations,
                geofenceEvents,
                initiativeStore
              }) => (
                <Box
                  key={id}
                  p=".7rem 1rem"
                  mb=".8rem"
                  borderRadius={"10px"}
                  border=".5px solid #D0D5DD"
                >
                  <Flex
                    width={"90%"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Box>
                      <Text
                        mb=".2rem"
                        fontWeight={"500"}
                        color={"#667085"}
                        fontSize={".9rem"}
                      >
                        Clock In
                      </Text>
                      <Text
                        fontWeight={"500"}
                        color={"#98A2B3"}
                        fontSize={".83rem"}
                      >
                        {moment(
                          clockInTime,
                          "YYYY-MM-DD HH:mm:ss"
                        ).format("h:mm A")}
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        mb=".2rem"
                        fontWeight={"500"}
                        color={"#667085"}
                        fontSize={".9rem"}
                      >
                        Clock Out
                      </Text>
                      <Text
                        fontWeight={"500"}
                        color={"#98A2B3"}
                        fontSize={".83rem"}
                      >
                        {moment(
                          clockOutTime,
                          "YYYY-MM-DD HH:mm:ss"
                        ).format("h:mm A") || "--"}
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        mb=".2rem"
                        fontWeight={"500"}
                        color={"#667085"}
                        fontSize={".9rem"}
                      >
                        Hours Worked
                      </Text>
                      <Text
                        fontWeight={"500"}
                        color={"#98A2B3"}
                        fontSize={".83rem"}
                      >
                        {hoursWorked || "--"}
                      </Text>
                    </Box>
                    
                  </Flex>
                  <Flex gap="1rem" alignItems={"center"}>
                  <Box mt=".3rem">
                      <Text
                        mb=".2rem"
                        fontWeight={"500"}
                        color={"#667085"}
                        fontSize={".9rem"}
                      >
                        Date
                      </Text>
                      <Text
                        fontWeight={"500"}
                        color={"#98A2B3"}
                        fontSize={".83rem"}
                      >
                        {clockInDate}
                      </Text>
                    </Box>
                  <Box mt=".3rem">
                      <Text
                        mb=".2rem"
                        fontWeight={"500"}
                        color={"#667085"}
                        fontSize={".9rem"}
                      >
                        Store
                      </Text>
                      <Text
                        fontWeight={"500"}
                        color={"#98A2B3"}
                        fontSize={".9rem"}
                        textTransform={"capitalize"}
                      >
                        {`${initiativeStore?.store?.name}, ${initiativeStore?.store?.area} ${initiativeStore?.store?.state}`}
                      </Text>
                    </Box>
                  </Flex>
                 
                  <Flex mt="1rem" justifyContent={'space-between'} alignItems={'center'}>
                                        <Text
                                          width={"fit-content"}
                                          fontSize={"12px"}
                                          fontWeight={"500"}
                                          borderRadius={"20px"}
                                          padding={".2rem .8rem .3rem .8rem"}
                                          bg={
                                            clockOutTime === null
                                              ? "#FFF2DB"
                                              : "#E0FFDB"
                                          }
                                          color={
                                            clockOutTime === null
                                              ? "#EC913C"
                                              : "#248C41"
                                          }
                                          textTransform={"capitalize"}
                                        >
                                          {clockOutTime === null
                                            ? "Pending"
                                            : "Done"}
                                        </Text>
                                        <CustomDrawer
                                        position={"right"}
                                        title={'Clock-out Rationale'}
                                        triggerClose={''}
                                        setTriggerClose={() => {}}
                                        toggleElement={
                                          <Text textDecoration={'underline'} cursor={"pointer"} fontSize={'.87rem'}>Review <ChevronRightIcon /></Text>
                                        }
                                        content={
                                            <ClockOutRationale data={{locations, geofenceEvents, clockInTime, clockOutTime, hoursWorked}} />
                                        }
                                        />
                                      </Flex>
                </Box>
              )
            )}
            </>
                },
                {
                  title: "Period Aggregate",
                  render: <AttendanceAgg userId={data?.id} />
                }
              ]
            }
            />
          },
          {
            title: "Route Tracker",
            render: 
            <>
                  <Flex justifyContent={"flex-end"}>
                    <Flex alignItems={'center'} gap=".5rem">
                      <CustomInput
                      width="150px"
                      type={"date"}
                      mt="0 !important"
                      py=".5rem !important"
                      required
                      placeholder={"Choose Date"}
                      onChange={e => setStartDate(e.target.value)}
                      />
                      -
                      <CustomInput
                      width="150px"
                      type={"date"}
                      mt="0 !important"
                      py=".5rem !important"
                      required
                      placeholder={"Choose Date"}
                      onChange={e => setEndDate(e.target.value)}
                      />
                    </Flex>
                  </Flex>

                  {loadingx ?
                  <SpinLoader mb="6rem" />
                  :
                  coords?.length < 1 ?
                  <Box pt="3rem" pb="6rem">
                    <EmptyState text={"No Location, Check-in or attendance records for this period"} />
                  </Box>
                  :
                  <Flex gap="1rem" my="1rem">
                    {/* <Box height={"500px"} flex={"1 1 200px"}>
                      <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.1rem"} color={"#475467"}>Activity Log</Text>
                      
                    </Box> */}
                    <ListMap coordinates={coords} height={"500px"} defaultZoom={17} />
                  </Flex>}
                </>
          },
          title === "Supervisor" &&
          {
            title: "Team Members",
            render: <ViewTeam id={data?.id} setRefetch={setRefetch} refetch={refetch} setTriggerClose={setTriggerClose} triggerClose={triggerClose} />
          }
        ]} />}
    </Box>
  )
}

export default ViewEmployee;