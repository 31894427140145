/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Flex,
  Heading,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import axiosInstance from "../../../../../../../../services/api";
import { buildResourceURL } from "../../../../../../../../utils/helper";
import PrimaryButton from "../../../../../../../../components/primaryButton/primarybutton";
import { InitiativeQueryComponent } from "../../../../../../../../components/initiativeQueryComponent/initiativeQueryComponent";
import { Loader } from "../../../../../../../../components/Loader/loader";
import classes from "./storeInventory.module.css";
import { defaultSearchValueState } from "../../../../../../../../constants/defaultStates";
import { MdOutlineInventory2, MdStorefront } from "react-icons/md";
import { formatDateHeader } from "../../../../../../../../helpers/formatDateHeader";
import { FaRegUser } from "react-icons/fa";
import { numberFormat } from "../../../../../../../../helpers/numberFormatter";
import { moneyFormat } from "../../../../../../../../helpers/moneyFormatter";
import { InitiativePaginationComponent } from "../../../../../../../../components/initiativePaginationComponent/initiativePaginationComponent";
import { InitiativeEmptyState } from "../../../../../../../../components/initiativeEmptyState/initiativeEmptyState";
import UtilityContext from "../../../../../../../../context/utilityContext";
import moment from "moment";

const StoreInventory = ({ data }) => {
  const [searchValues, setSearchValues] = useState(defaultSearchValueState);
  const { draw } = useContext(UtilityContext);

  const { page } = searchValues;

  const limit = 100;

  const regionsData = data?.client?.statesFilterGroups || [];
  const storeGroupData = data?.initiativeStoresFilterGroups || [];
  const productGroupData = data?.productsFilterGroups || [];

  const [loading, setLoading] = useState(false);
  const [triggerClose, setTriggerClose] = useState("");
  const [loadingd, setLoadingd] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [docs, setDocs] = useState([]);
  const [total, setTotal] = useState("");
  const [refetch, setRefetch] = useState("");
  const [date, setDate] = useState("");
  const { iid, sid } = useParams();
  const [state, setState] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [selected, setSelected] = useState(null);

  const options1 = data
    ? data?.brands?.map((i) => ({
      title: `${i?.name}-${i?.sku}`,
      value: JSON.stringify({ brandName: i?.name, sku: i?.sku }),
    }))
    : [];

  const endDate = new Date(searchValues.endDate); // Create a new date object based on the current date

  endDate.setDate(endDate.getDate() + 1);

  const queryParams = [
    {
      key: sid ? "initiativeStore" : "initiative",
      value: sid ? sid : iid,
    },
    {
      key: "updatedAt[gte]}",
      value: searchValues.startDate,
    },
    {
      key: "updatedAt[lt]",
      value: searchValues?.endDate ? endDate.toISOString().split("T")[0] : "",
    },
    {
      key: "brandName",
      value: JSON.parse(searchValues?.brandName || "{}")?.brandName,
    },
    {
      key: "sku",
      value: JSON.parse(searchValues?.brandName || "{}")?.sku,
    },
    {
      key: "limit",
      value: 100,
    },
    {
      key: "state",
      value: searchValues.states.toLowerCase(),
    },
    {
      key: "initiativeStoresFilterGroupId",
      value: searchValues.groups,
    },
    {
      key: "productsFilterGroupId",
      value: searchValues.products,
    },
    {
      key: "statesFilterGroupId",
      value: searchValues.regions,
    },
    {
      key: "search",
      value: searchValues.searchQuery,
    },
    {
      key: "page",
      value: page,
    },
  ];

  const queryParamsx = [
    {
      key: sid ? "initiativeStore" : "initiative",
      value: sid ? sid : iid,
    },
    {
      key: "updatedAt[gte]}",
      value: searchValues.startDate,
    },
    {
      key: "updatedAt[lt]",
      value: searchValues?.endDate ? endDate.toISOString().split("T")[0] : "",
    },
    {
      key: "brandName",
      value: JSON.parse(searchValues?.brandName || "{}")?.brandName,
    },
    {
      key: "sku",
      value: JSON.parse(searchValues?.brandName || "{}")?.sku,
    },
    {
      key: "state",
      value: searchValues.states.toLowerCase(),
    },
    {
      key: "initiativeStoresFilterGroupId",
      value: searchValues.groups,
    },
    {
      key: "productsFilterGroupId",
      value: searchValues.products,
    },
    {
      key: "statesFilterGroupId",
      value: searchValues.regions,
    },
  ];

  const getDocs = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        buildResourceURL("store-inventories", queryParams)
      );
      setDocs(res?.data?.data);
      setTotal(res?.data?.totalCount);
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDocs();
  }, [searchValues, refetch]);

  const deleteDoc = async (id) => {
    setLoadingd(true);
    try {
      const { data } = await axiosInstance.delete(
        `/store-inventories/${selected}`
      );
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      onClose();
      setRefetch(Math.random());
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingd(false);
    }
  };

  const [inputPage, setInputPage] = useState(page);

  options1.sort((a, b) => a.title.localeCompare(b.title));

  const handleModal = (item) => {
    setSelected(item);
    onOpen();
  };

  return (
    <div className={classes.screenContainer}>
      <InitiativeQueryComponent
        data={data}
        searchValues={searchValues}
        setSearchValues={setSearchValues}
        filterList1={{ title: "Brand", name: "brandName", data: options1 }}
        entity="store-inventories"
        queryParamsx={queryParamsx}
      />

      {loading ? (
        <div className={classes.loader_con}>
          <Loader />
        </div>
      ) : (
        <>
          {docs.length ? (
            <div className={classes.table_container}>
              <div className={classes.titleAndInstructionContainer}>
                <p className={classes.title}>
                  Total Store Inventories Found: {total}
                </p>
                <p className={classes.instruction}>
                  Click on each row to see more details
                </p>
              </div>

              <p className={classes.showing}>
                Showing:{" "}
                <span>
                  {docs.length || 1} of {total}
                </span>
              </p>
              <div className={classes.scrollable}>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th>Updated At</th>
                      <th>Brand</th>
                      <th>SKU</th>
                      <th>Store</th>
                      <th>Location</th>
                      <th>Units</th>
                      <th>Cases</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {docs.map((item, index) => (
                      <tr
                        onClick={() =>
                          draw({
                            title: "Store Inventory",
                            content: (
                              <div className={classes.modalContainer}>
                                <div className={classes.topButtonContainer}>
                                  <button
                                    onClick={() => handleModal(item._id)}
                                    className={classes.deleteButton}
                                  >
                                    Delete
                                  </button>
                                </div>

                                <div
                                  className={classes.modalTitleAndDateContainer}
                                >
                                  <p className={classes.modalTitle}>
                                    {item?.brandName} - {item?.sku}
                                  </p>
                                  <p>{formatDateHeader(moment(item.updatedAt).format('YYYY-MM-DD'))}</p>

                                </div>

                                <div
                                  className={classes.modalStoreAddressContainer}
                                >
                                  <p className={classes.modalStoreAddress}>
                                    {item?.initiativeStore?.store?.name} -{" "}
                                    {item?.initiativeStore?.store?.streetNum ||
                                      "No Bld. num."}
                                    , {item?.initiativeStore?.store?.streetName}
                                  </p>
                                  <MdStorefront className={classes.modalIcon} />
                                </div>

                                <div
                                  className={classes.modalStoreAddressContainer}
                                >
                                  <div style={{ width: "90%" }}>
                                    <p className={classes.modalStoreAddress}>
                                      {item?.availableStockQty}
                                    </p>
                                    <p className={classes.messageDescription}>
                                      Units
                                    </p>
                                  </div>

                                  <MdOutlineInventory2
                                    className={classes.modalIcon}
                                  />
                                </div>

                                <div className={classes.smallGrouped}>
                                  <div className={classes.smallContainer}>
                                    <p className={classes.modalStoreAddress}>
                                      {numberFormat.format(item?.totalCase)}
                                    </p>
                                    <p className={classes.messageDescription}>
                                      Cases
                                    </p>
                                  </div>

                                  <div className={classes.smallContainer}>
                                    <p className={classes.modalStoreAddress}>
                                      {moneyFormat.format(item.totalValue)}
                                    </p>
                                    <p className={classes.messageDescription}>
                                      Value
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ),
                          })
                        }
                        key={index}
                      >

                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>
                            {formatDateHeader(moment(item.updatedAt).format('YYYY-MM-DD'))}
                          </p>
                          <p className={classes.tooltip}>
                            {formatDateHeader(moment(item.updatedAt).format('YYYY-MM-DD'))}
                          </p>
                        </td>


                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>{item.brandName}</p>
                          <p className={classes.tooltip}>{item.brandName}</p>
                        </td>
                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>{item.sku}</p>
                          <p className={classes.tooltip}>{item.sku}</p>
                        </td>
                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>
                            {item.initiativeStore?.store?.name}
                          </p>
                          <p className={classes.tooltip}>
                            {item.initiativeStore?.store?.name}
                          </p>
                        </td>
                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>
                            {item.initiativeStore?.store?.state}
                          </p>
                          <p className={classes.tooltip}>
                            {item.initiativeStore?.store?.state}
                          </p>
                        </td>
                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>
                            {numberFormat.format(item.availableStockQty)}
                          </p>
                          <p className={classes.tooltip}>
                            {numberFormat.format(item.availableStockQty)}
                          </p>
                        </td>
                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>
                            {numberFormat.format(item.totalCase)}
                          </p>
                          <p className={classes.tooltip}>
                            {numberFormat.format(item.totalCase)}
                          </p>
                        </td>
                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>
                            {moneyFormat.format(item.totalValue)}
                          </p>
                          <p className={classes.tooltip}>
                            {moneyFormat.format(item.totalValue)}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <InitiativePaginationComponent
                page={page}
                totalCount={total}
                setSearchValues={setSearchValues}
                setInputPage={setInputPage}
                inputPage={inputPage}
                limit={limit}
              />
            </div>
          ) : (
            <InitiativeEmptyState searchValues={searchValues} name={"orders"} />
          )}

          <Modal
            isCentered
            motionPreset="slideInBottom"
            isOpen={isOpen}
            onClose={onClose}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader
                fontSize={"1rem"}
                textTransform={"capitalize"}
              >{`Delete Store Inventory`}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {/* {modalType === 'delete' && */}
                <>
                  <Text
                    textAlign={"center"}
                    color={"#475467"}
                    fontWeight={"500"}
                  >
                    Confirm deletion of store inventory?
                  </Text>
                  <Flex justifyContent={"center"} my="1.3rem">
                    <PrimaryButton
                      onClick={deleteDoc}
                      text="Delete"
                      width="150px"
                      bg="crimson !important"
                      padding="1rem !important"
                      loading={loadingd}
                    />
                  </Flex>
                </>
                {/* 
                    } */}
                <Text
                  pb="1rem"
                  textAlign={"center"}
                  fontSize={".9rem"}
                  fontWeight={"500"}
                  cursor={"pointer"}
                  onClick={onClose}
                >
                  Cancel
                </Text>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </div>
  );
};

export default StoreInventory;
