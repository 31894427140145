/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Sales from './sales'
import AggregateSales from './aggregateSales'
import axiosInstance from '../../../../../../../../services/api'
import { useParams } from 'react-router-dom'
import Overview from '../../../../../../../../components/overView/overView'
import CustomSelect from '../../../../../../../../components/customSelect/customSelect'
import ListCompare from '../../../../../../../../components/listCompare/listCompare'
import PeriodAggregate from './periodAggregate'
import { InitiativeHeaderTab } from '../../../../../../../../components/initiativeHeaderTab/initiativeHeaderTab'
import classes from './sales.module.css'
import { formatNumber } from '../../../../../../../../utils/helper'
import { constants } from '../../../../../../../../utils/constants'

const AllSales = ({ initiative }) => {
    const [analyticsData, setAnalyticsData] = useState(null);
    const [loading, setLoading] = useState(false);

    const { iid } = useParams()

    const [brand, setBrand] = useState(initiative?.brands[0]?._id);

    const options1 = initiative?.brands?.map(i => ({
        title: `${i?.name}-${i?.sku}`,
        value: i?._id
    }))

    const getSalesAnalytics = async () => {
        setLoading(true)
        try {
            const { data } = await axiosInstance.get(`/sales/analytics/initiative?initiativeId=${iid}&brandName=${initiative?.brands?.filter(i => i._id === brand)[0]?.name}&sku=${initiative?.brands?.filter(i => i._id === brand)[0]?.sku}&target=${initiative?.brands?.filter(i => i._id === brand)[0]?.target}&durationInDays=${initiative?.brands?.filter(i => i._id === brand)[0]?.durationInDays}`)
            setAnalyticsData(data?.data)
        } catch (error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getSalesAnalytics()
    }, [brand])


    const datax = [
        {
            title: "Sales achieved (units)",
            value: analyticsData?.totalUnitsSold?.toLocaleString() || 0,
        },
        {
            title: "Sales achieved (cases)",
            value: formatNumber(analyticsData?.totalCase) || 0,
        },
        {
            title: "Total Value",
            value: `${constants?.currency}${analyticsData?.totalValue?.toLocaleString()}` || 0,
        },
        {
            title: "Sales target",
            value: analyticsData?.cumulativeTarget?.toLocaleString() || 0,
            // value: details?.initiative?.brands?.filter(i => i?._id === brand)[0]?.target,
        },
        {
            title: 'Target met',
            value: `${analyticsData?.cumulativeTargetMetPercentage || 0}%`,
        },
        {
            title: "Time gone",
            value: `${analyticsData?.daysGone || 0}/${analyticsData?.durationInDays || 0} (${analyticsData?.timeGonePercentage || 0}%)`,
            // value: details?.initiative?.brands?.filter(i => i?._id === brand)[0]?.durationInDays,
        }
    ]


    datax.sort((a, b) => a.title.localeCompare(b.title))

    const assignor = {
        "Brands View": <>

            <CustomSelect
                selection={brand}
                setSelection={setBrand}
                options={options1}
                height="37px"
                fontSize=".8rem"
                width="150px"
            />
            <br />
            <Overview data={datax} loading={loading} />
        </>,
        "All Sales": <Sales data={initiative} />,
        "Brand Aggregates": <AggregateSales data={initiative} />,
        "Store Aggregates": <PeriodAggregate iData={initiative} />,
        "Not Submitted": <ListCompare listType={'sale'} />
    }

    const [selected, setSelected] = useState(Object.keys(assignor)[0])


    return (
        <div className={classes.container}>
            <InitiativeHeaderTab list={Object.keys(assignor)} selected={selected} setSelected={setSelected} />


            <div>
                {assignor[selected]}
            </div>

        </div>
    )
}

export default AllSales