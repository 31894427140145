import { useState } from "react";
import statesLgaJson from "../../../../../utils/stateLGAs";
import CustomInput from "../../../../../components/customInput/customInput";
import { Divider, Flex, Text, useToast } from "@chakra-ui/react";
import PrimaryButton from "../../../../../components/primaryButton/primarybutton";
import axiosInstance from "../../../../../services/api";


const CreateRegions = ({cid, setTriggerClose}) => {

  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    states: [],
  });

  const toast = useToast()

  const handleNameChange = (e) => {
    setFormData({ ...formData, name: e.target.value });
  };

  const handleStateChange = (e) => {
    const stateName = e.target.value;
    const isChecked = e.target.checked;
    if (isChecked) {
      // Add the state to the list
      setFormData({
        ...formData,
        states: [...formData.states, stateName],
      });
    } else {
      // Remove the state from the list
      setFormData({
        ...formData,
        states: formData.states.filter((state) => state !== stateName),
      });
    }
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const { data } = await axiosInstance.post(`/clients/${cid}/states-filter-groups`, formData)
      toast({
        description: data?.message,
        status: "success",
        position: "top"
      });
      setTriggerClose('close' + (Math.random()))
      window.location.reload()
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
      });
    } finally {
        setLoading(false)
    }
  };

  const states = statesLgaJson?.map(i => i?.state)


  return (
    <>
    <form onSubmit={handleSubmit}>
      <CustomInput
        type={"text"}
        required
        placeholder={"Grouping Name"}
        value={formData.name}
        onChange={handleNameChange}
        id="name"
      />
      <Text mt="1rem" opacity={".8"} fontWeight={"500"} fontSize={".95rem"}>Select States</Text>
      <Divider my=".3rem" />
      <Flex gap=".5rem" alignItems={"center"} flexWrap={"wrap"}>
        {states.map((state) => (
          <Flex alignItems={"center"} gap=".2rem" key={state}>
            <input
              type="checkbox"
              id={state}
              value={state}
              checked={formData.states.includes(state)}
              onChange={handleStateChange}
            />
            <label style={{whiteSpace: "nowrap"}} htmlFor={state}>{state}</label>
          </Flex>
        ))}
      </Flex>
      <PrimaryButton mt="3rem" type="submit" text="Add Grouping" loading={loading} />
    </form>
    </>
  )
}

export default CreateRegions;