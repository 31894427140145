import { Box, Flex } from "@chakra-ui/react"
import Sidebar from "../../components/sidebar/sidebar";
import Navbar from "../../components/navbar/navbar";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from './dashboard.module.css'

const Dashboard = ({ collapsed, setCollapsed, component }) => {
  // const currentUser = useSelector(({userData})=>  userData?.currentUser);
  // const navigate = useNavigate()

  // useEffect(() => {
  //   if(currentUser?.role === 'Client') {
  //     navigate('/initiatives')
  //   }
  // }, [currentUser])

  return (
    <div className={classes.container}>
      <Sidebar collapsed={true} setCollapsed={setCollapsed} />
      <div style={{ width: '100%' }}>
        <Navbar collapsed={collapsed} />
        <div className={classes.componentContainer}>
          {component}
        </div>
      </div>
    </div>
  )
}

export default Dashboard;