import StoreInventory from './storeInventory'
import AggregateStoreInventory from './aggregateStoreInventory'
import PeriodAggregate from './periodAggregate'
import { InitiativeHeaderTab } from '../../../../../../../../components/initiativeHeaderTab/initiativeHeaderTab'
import { useState } from 'react'
import classes from './storeInventory.module.css'

const AllStoreInventory = ({ initiative }) => {

    const assignor = {
        "All": <StoreInventory data={initiative} />,
        "Brand Aggregates": <AggregateStoreInventory data={initiative} />,
        "Store Aggregates": <PeriodAggregate iData={initiative} />,
    }

    const [selected, setSelected] = useState(Object.keys(assignor)[0])

    return (
        <div className={classes.container}>
            <InitiativeHeaderTab list={Object.keys(assignor)} selected={selected} setSelected={setSelected} />
            <div>
                {assignor[selected]}
            </div>
        </div>
    )
}

export default AllStoreInventory;