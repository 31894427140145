import { Box, Divider, Flex, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import CustomSelect from '../../../../../../components/customSelect/customSelect'
import axiosInstance from '../../../../../../services/api'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import PrimaryButton from '../../../../../../components/primaryButton/primarybutton'
import Prizes from './prizes/prizes'
import CustomInput from '../../../../../../components/customInput/customInput'
import { AiFillCloseCircle } from 'react-icons/ai';

const Draws = () => {
  const navigate = useNavigate()
  const {search} = useLocation()

    const searchx = window.location.search;
    const params = new URLSearchParams(searchx);
    const mode = params.get('mode');
    const iid = params.get('iid')

  const [drawType, setDrawType] = useState("")
  const [games, setGames] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingx, setLoadingx] = useState(false)

  const [question, setQuestion] = useState("")
  const [answer, setAnswer] = useState("")
  const [saved, setSaved] = useState(false)
  const [quizList, setQuizList] = useState([])

  const [datum, setDatum] = useState(null)

  const getGames = async() => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.get('/game-categories')
      setGames(data?.data)
    } catch(error) {
        console.errror(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
  }

  useEffect(() => {
    getGames()
  }, [])


  const drawOptions = games?.map(i => ({
    title: i?.name,
    value: i?.name
  }))

  const [tags, setTags] = useState([])

  const toast = useToast();

  const handleAddTags = (tags) => {
    setTags(tags)
  }

  const createDraw = async() => {
    setLoadingx(true)
    const datax = drawType === "true or false" ?
    {
        name: drawType,
        imageURL: games?.find(i => i.name === drawType)?.imageURL,
        questions: quizList
    }
    :
    {
      name: drawType,
      imageURL: games?.find(i => i.name === drawType)?.imageURL
    }
    try {
        const { data } = await axiosInstance.post(`/initiatives/${iid}/games`, datax)
        toast({
        description: data?.message,
        status: "success",
        position: "top"
        });
        setDatum(data?.data)
        if(mode === 'add') {
          navigate(-1)
        }
        // navigate(`/initiatives/create/4?iid=${search.slice(5)}`)
    } catch(error) {
        toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
        });
    } finally {
        setLoadingx(false)
    }
}

  const answerOptions = [
    {
      title: "True",
      value: "true"
    },
    {
      title: "False",
      value: "false"
    }
  ]

  const handleAdd = () => {
    setQuizList([
      ...quizList,
      {
        text: question,
        answer
      }
    ])
    setQuestion("")
    setAnswer("")
    setSaved(true)
  }

  const removeQuiz = (val) => {
    const arr2 = quizList.filter(i => i.text !== val)
    setQuizList(arr2)
  };

  return (
    <>
        <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Text fontWeight={"500"}>Select Game Type and prizes to be won.</Text>
            <Text fontSize=".87rem" cursor={"pointer"} textDecoration={"underline"} onClick={() => navigate(`/initiatives/create/4?iid=${search.slice(5)}`)}>Skip and add later</Text>
        </Flex>

       {!loading &&
        <Box maxW={"600px"} mt="3rem" mx="auto">
            <CustomSelect
            required
            options={drawOptions}
            placeholder={"Draw Type"}
            onChange={e => setDrawType(e.target.value)}
            mb="1rem"
            borderRadius="10px"
            />

            {(drawType?.toLowerCase().includes('true') || drawType?.toLowerCase().includes('q')) &&
              <>

                <Flex gap={"1rem"} alignItems={"center"}>
                  <CustomInput
                  type={"text"}
                  name="name"
                  placeholder={`Question ${quizList?.length + 1}`}
                  onChange={e => setQuestion(e.target.value)}
                  value={question}
                  mt="0 !important"
                  width="400px"
                  />
                  <CustomSelect
                  required
                  options={answerOptions}
                  value={answer}
                  placeholder={"Expected Answer"}
                  onChange={e => setAnswer(e.target.value)}
                  borderRadius="10px"
                  mt="0 !important"
                  />
                </Flex>

                <Box my="1.5rem">
                  {quizList?.map(({text, answer}) => {
                    return (
                      <Text position={"relative"} mb=".5rem" key={text} padding={".4rem 1rem"} borderRadius={"4px"} border={"1px solid lightgray"}>{text}?  <strong>{answer}</strong> <AiFillCloseCircle onClick={() => removeQuiz(text)} color='#EB5017' fontSize={"1.2rem"} style={{position: "absolute", right: "-1.5%", top: "20%", cursor: "pointer"}} /></Text>
                    )
                  })}
                </Box>

                <Flex justifyContent={"center"}>
                  <PrimaryButton mt="2rem" text="Save" width="120px" py="1.4rem" onClick={handleAdd} loading={false} isDisabled={question === "" ?? answer === ""} />
                </Flex>
              </>
            }


           
            {(drawType === "spin the wheel" || drawType === "test" || drawType === "word search") && <PrimaryButton mt="3rem" type="submit" text="Add Draw" onClick={createDraw} loading={loadingx}/>}
            {(drawType === "true or false" || drawType === "q&a") && <PrimaryButton mt="3rem" type="submit" text="Add Draw" onClick={createDraw} loading={loadingx} isDisabled={saved === false} />}
        </Box>}
        <Divider my="1.5rem" />
        {mode === 'new' && <Prizes datum={datum} />}
    </>
  )
}

export default Draws