import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import SecondaryButton from '../../../../components/secondaryButton/secondarybutton'
import EmptyState from '../../../../components/emptyState/emptyState'
import axiosInstance from '../../../../services/api'
import { HiArrowRight } from 'react-icons/hi'
import moment from 'moment'
import LineSkeleton from '../../../../components/lineSkeleton'
import { useNavigate } from 'react-router-dom'
import { buildResourceURL } from '../../../../utils/helper'
import classes from '../home.module.css'

const InitiativesView = ({ size }) => {
    const [initiatives, setInitiatives] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingc, setLoadingc] = useState(false);
    const [triggerClose, setTriggerClose] = useState("")

    const navigate = useNavigate()

    const [page, setPage] = useState(1)
    const limit = 5

    const queryParams = [
        {
            key: 'isDeleted',
            value: false
        },
        {
            key: 'page',
            value: page
        },
        {
            key: 'limit',
            value: limit
        }
    ]

    const getInitiatives = async () => {
        try {
            const { data } = await axiosInstance.get(buildResourceURL('initiatives', queryParams))
            setInitiatives(data?.data)
        } catch (error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getInitiatives()
    }, [])

    return (
        <Box boxShadow="0px 1px 2px 0px #1018280D" flex={["1 0 300px", "1 1 400px"]} bg="#FFF" border=".5px solid #F2F4F7" p="1.3rem" borderRadius={"12px"}>
            <Flex alignItems={"center"} justifyContent={"space-between"}>
                <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>Initiatives <span style={{ padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054" }}>{initiatives?.length}</span></Text>
                <Flex alignItems={"center"} gap=".8rem">
                    <button onClick={(e) => { e?.preventDefault(); navigate('/initiatives/create/1') }} className={classes.createButton}>Create Initiative</button>
                </Flex>
            </Flex>
            <Divider my="1.5rem" />
            <Box height={size === 'full' ? "auto" : "267.41px"} overflow={size === "full" ? "none" : "scroll"}
                sx={
                    {
                        '::-webkit-scrollbar': {
                            display: 'none'
                        }
                    }
                }>
                {loading ?
                    <LineSkeleton num={6} />
                    :
                    initiatives?.length < 1 ?
                        <EmptyState mt="1.5rem" text="You have not added any initiatives yet" />
                        :
                        initiatives?.slice(0, 10)?.map((({ _id: id, name, createdAt, imageURL }) => {
                            return (
                                <Flex key={id} onClick={() => navigate(`/initiatives/${id}`)} cursor={"pointer"} padding={".7rem"} borderRadius={"16px"} border="1px solid #EAECF0" mb=".8rem">
                                    <Flex alignItems={"center"} w="100%" justifyContent={"space-between"}>
                                        <Flex alignItems={"center"} gap=".7rem">
                                            <Box height={"50px"} width={"60px"} borderRadius={"6px"} backgroundImage={imageURL} backgroundSize={"cover"} backgroundRepeat={"no-repeat"} />
                                            <Box>
                                                <Text mb=".2rem" color={"#667085"} fontWeight={"500"}>{name}</Text>
                                                <Text fontWeight={"300"} fontSize={".85rem"} color="#667085">{moment(createdAt).calendar()}</Text>
                                            </Box>
                                        </Flex>
                                        <HiArrowRight />
                                    </Flex>
                                </Flex>
                            )
                        }))
                }
            </Box>
            <br />
            <Text onClick={() => navigate('/initiatives')} color={"#ff6f61"} fontSize={".9rem"} fontWeight={"600"} cursor={"pointer"}>See more</Text>

        </Box>
    )
}

export default InitiativesView;