import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import CustomInput from '../../../../components/customInput/customInput';
import LineSkeleton from '../../../../components/lineSkeleton';
import EmptyState from '../../../../components/emptyState/emptyState';
import { buildResourceURL } from '../../../../utils/helper';
import axiosInstance from '../../../../services/api';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';

const LinkStore = ({setTriggerClose, clientId}) => {

  const [loading, setLoading] = useState(false)
  const [loadingx, setLoadingx] = useState(false)
  const [stores, setStores] = useState([])
  const [sid, setSid] = useState("")
  const [searchVal, setSearchVal] = useState("")
  const {iid} = useParams()

  const toast = useToast()

  const currentUser = useSelector(({userData})=>  userData?.currentUser);

  const queryParams = [
    {
        key: 'isDeleted',
        value: false
    },
    {
        key: 'isApproved',
        value: true
    },
    {
        key: 'search',
        value: searchVal
    },
    {
        key: 'limit',
        value: 7
    }
]

  const getStores = async() => {
    setLoading(true)
    try {
        const { data } = await axiosInstance.get(buildResourceURL('stores', queryParams))
        setStores(data?.data)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
}

useEffect(() => {
    getStores()
},[searchVal])

  const linkStore = async() => {
    setLoadingx(true)
    const datax = {
        clientId: clientId || currentUser?._id
    }
    try {
        const { data } = await axiosInstance.patch(`/stores/${sid}/add-client`, datax)
        toast({
            description: data?.message,
            status: "success",
            position: 'top'
        });
        setTriggerClose('close' + (Math.random()))
    } catch(error) {
        toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
          });
    } finally {
        setLoadingx(false)
    }
  }




  return (
    <>
    <Box>
      <CustomInput
      type={"text"}
      placeholder={"Search stores"}
      mb="1rem"
      onChange={e => setSearchVal(e.target.value)}
      />

      {loading ?
      <LineSkeleton num={6} />
      :
      stores?.length < 1 ?
      <EmptyState text="No stores found" />
      :
      stores?.map(({_id: id, name, ownerName, ownerPhoneNumber, phoneNumber, team, type, isApproved, isDeleted, createdAt, area, state, level, category, imageURL, location, comment, user, userRole, address, ownerEmail, productSource, purchaseWillingness, buyingPreferences}) => {
        return (
          <Flex onClick={() => setSid(id)} cursor={"pointer"} position={"relative"} padding={".7rem 1rem"} gap=".6rem" alignItems={"center"} mt=".8rem" borderRadius={"6px"} border={sid === id ? "2px solid #7F56D9": "1px solid lightgrey"}>
            <Box height={"35px"} width={"40px"} bg="lightgray" backgroundImage={imageURL} backgroundSize={"cover"} borderRadius={"4px"} />
            <Box>
              <Text fontSize={".9rem"} mb=".15rem" fontWeight={"500"}>{name}</Text>
              <Text fontSize={".75rem"} fontWeight={"500"} opacity={".65"}>{moment(createdAt).calendar()}</Text>
            </Box>
          </Flex>
        )
      })
      }
    </Box>

    <PrimaryButton mt="3rem" onClick={linkStore} text="Link Outlet to Client" loading={loadingx} disabled={sid === ""} />
    </>
  )
}

export default LinkStore;