import { Box, Flex, Heading, Image, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/primaryButton/primarybutton";
import CustomInput from "../../../components/customInput/customInput";
import AuthBg from "../../../assets/bg-element.webp";
import FullLogo from "../../../assets/full-logo.svg";
import Illustration from "./illustration.svg";
import OtpInput from "react-otp-input";
import axiosInstance2 from "../../../services/UnAuthApi";

const Verify = () => {
  const [username, setUsername] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();

  const toast = useToast();

  const navigate = useNavigate();

  const verifyForgotPasswordToken = async () => {
    setLoading(true);
    const datax = {
      token: otp,
      email: state?.email,
      tokenType: "forgot_password"
    };
    try {
      const { data } = await axiosInstance2.post("/auth/verify-token", datax);
      navigate("/forgot-password/reset", {state: {token: otp}});
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.errors[0],
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const verifySignupToken = async () => {
    setLoading(true);
    const datax = {
      token: otp,
      email: state?.email
    };
    try {
      const { data } = await axiosInstance2.post("/auth/verify-email", datax);
      navigate("/login");
      toast({
        // title: "",
        description: "Email verified successfully!",
        status: "success",
        position: "top",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.errors[0],
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setOtp(e);
  };

  const handleVerify = (e) => {
    e.preventDefault();
    if(state?.source === "forgot_password") {
      verifyForgotPasswordToken()
    } else if(state?.source === "signup") {
      verifySignupToken()
    } else {
      return;
    }
  }

  return (
    <Box
      width={"100vw"}
      height="80vh"
      overflow={"hidden"}
      backgroundImage={AuthBg}
      backgroundSize="cover"
      p="1rem"
    >
      <Box
        padding={["2rem .7rem 1.5rem .7rem", "2rem 2rem 1.5rem 2rem"]}
        maxWidth={"600px"}
        bg="#003C4E"
        mx="auto"
        mt="3vh"
        boxShadow={"0px 4px 24px rgba(0, 0, 0, 0.16)"}
        borderRadius={["16px", "40px"]}
        textAlign={"center"}
      >
        <Image src={FullLogo} width={["50%", "33%"]} mx="auto" />
        <Image mt="2rem" src={Illustration} width={["30%", "16%"]} mx="auto" />
        <Heading
          mt={6}
          fontWeight={"700"}
          fontSize={["1.35rem", "1.5rem"]}
          color="#FFF"
        >
          Verification Code
        </Heading>
        <Text mt={2} mb="1rem" color={"#CFD0D3"} fontSize={".9rem"}>
          We have sent a verification code to {state?.email}
        </Text>
        <form onSubmit={handleVerify}>
          <Box margin="0 auto" width={["85%", "50%"]}>
            <OtpInput
              containerStyle={{
                display: "flex",
                margin: "0 auto",
                justifyContent: "space-between",
                gap: "1rem",
              }}
              inputStyle={{
                height: "58px",
                width: "58px",
                background: "#1D5E82",
                color: "#FFF",
                fontSize: "1.1rem",
                fontWeight: "600",
                borderRadius: "50%",
                borderBottom: "2px solid #02BAF1",
              }}
              focusStyle={{
                boxShadow: "none",
                outline: "none",
              }}
              value={otp}
              onChange={handleChange}
              numInputs={4}
              isInputNum={true}
              shouldAutoFocus={true}
            />
          </Box>
          <br />
          <PrimaryButton type="submit" text="Submit" loading={loading} />
          <Text
            mt="1.2rem"
            mb=".3rem"
            textAlign={"center"}
            fontSize={".9rem"}
            color="#CFD0D3"
          >
            Didn’t receive the code?{" "}
            <span
              onClick={() => {}}
              style={{
                fontWeight: "500",
                color: "#5DDAFF",
                cursor: "pointer",
                transition: "250ms ease",
              }}
            >
              Resend
            </span>
          </Text>
        </form>
      </Box>
    </Box>
  );
};

export default Verify;
