/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import CustomSelect from '../../../../../../../../components/customSelect/customSelect';
import CustomInput from '../../../../../../../../components/customInput/customInput';
import PrimaryButton from '../../../../../../../../components/primaryButton/primarybutton';
import axiosInstance from '../../../../../../../../services/api';
import LineSkeleton from '../../../../../../../../components/lineSkeleton';
import EmptyState from '../../../../../../../../components/emptyState/emptyState';
import CustomTable from '../../../../../../../../components/customTable/customTable';
import { useParams } from 'react-router-dom';
import { buildResourceURL, formatNumber } from '../../../../../../../../utils/helper';
import { useSelector } from 'react-redux';
import statesLgaJson from '../../../../../../../../utils/stateLGAs';
import ExportCSV from '../../../../../../../../components/exportCsv';
import { constants } from '../../../../../../../../utils/constants';
import classes from './competitors.module.css'
import { InitiativeQueryComponent } from '../../../../../../../../components/initiativeQueryComponent/initiativeQueryComponent';
import { Loader } from '../../../../../../../../components/Loader/loader';
import { defaultSearchValueState } from '../../../../../../../../constants/defaultStates';
import { moneyFormat } from '../../../../../../../../helpers/moneyFormatter';
import { numberFormat } from '../../../../../../../../helpers/numberFormatter';
import { InitiativeEmptyState } from '../../../../../../../../components/initiativeEmptyState/initiativeEmptyState';
import { InitiativePaginationComponent } from 'components/initiativePaginationComponent/initiativePaginationComponent';

const PeriodAggregate = ({ iData }) => {
    const [brand, setBrand] = useState("")
    const [loading, setLoading] = useState(false)
    const { iid } = useParams();
    const [showBrandColumn, setShowBrandColumn] = useState(false)
    const [total, setTotal] = useState('')

    const [searchValues, setSearchValues] = useState(defaultSearchValueState)
    const [inputPage, setInputPage] = useState(searchValues.page)




    const storeGroupData = iData?.initiativeStoresFilterGroups || [];
    const regionsData = iData?.client?.statesFilterGroups || []
    const productGroupData = iData?.productsFilterGroups || [];


    const endDate = new Date(searchValues.endDate);  // Create a new date object based on the current date
    endDate.setDate(endDate.getDate() + 1);


    const storeGroupQuery = storeGroupData.filter(item => item.name.toLowerCase() === searchValues.groups.toLowerCase())[0]?.['initiativeStoresIDs'].map((item) => item).join(',') || ''
    const regionGroupQuery = regionsData.filter(item => item.name.toLowerCase() === searchValues.regions.toLowerCase())[0]?.['states'].map((item) => item).join(',') || ''
    const productGroupQuery = productGroupData.filter(item => item.name.toLowerCase() === searchValues.products.toLowerCase())[0]?.['products'].map((item) => item).join(',') || ''


    const [docs, setDocs] = useState([])


    const options1 = iData?.brands?.map(i => ({
        title: `${i?.name}-${i?.sku}`,
        value: JSON.stringify({ brandName: i?.name, sku: i?.sku })
    }))

    options1.sort((a, b) => a.title.localeCompare(b.title))

    const queryParams = [
        {
            key: "initiativeId",
            value: iid
        },
        {
            key: "brandName",
            value: JSON.parse(searchValues?.brandName || "{}")?.brandName
        },
        {
            key: "sku",
            value: JSON.parse(searchValues?.brandName || "{}")?.sku
        },
        {
            key: "startDate",
            value: searchValues?.startDate
        },
        {
            key: "endDate",
            value: (searchValues?.startDate && searchValues?.endDate) ? endDate.toISOString().split('T')[0] : ''
        },
        {
            key: 'state',
            value: searchValues.states.toLowerCase()
        },
        {
            key: 'initiativeStoresFilterGroupId',
            value: searchValues.groups
        },
        {
            key: 'productsFilterGroupId',
            value: searchValues.products
        },
        {
            key: 'statesFilterGroupId',
            value: searchValues.regions
        },
        {
            key: 'searchQuery',
            value: searchValues.searchQuery
        },
        {
            key: 'page',
            value: searchValues?.page
        },
        {
            key: 'limit',
            value: searchValues.limit
        },
        {
            key: 'download',
            value: false
        },
    ]

    const getResults = async (e) => {
        e?.preventDefault()
        setLoading(true)
        try {
            const { data } = await axiosInstance.get(buildResourceURL(`/competitors/aggregate/store`, queryParams))
            // console.log(data?.data)
            setDocs(data?.data)
            setTotal(data?.totalCount)
            if (brand !== "") {
                setShowBrandColumn(true)
            } else {
                setShowBrandColumn(false)
            }
        } catch (error) {
            console.log(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getResults()
    }, [searchValues])


    const refinedData = docs?.map(i => ({
        outlet: i?.store?.name,
        location: i?.store?.state,
        lga: i?.store?.area,
        total: i?.total,
        avg: i?.avg,
        brand: `${iData?.brands?.filter(i => i._id === brand)[0]?.name} ${iData?.brands?.filter(i => i._id === brand)[0]?.sku}`,
        totalCase: i?.totalCase,
        avgTotalCase: i?.avgTotalCase,
        totalValue: i?.totalValue,
        avgTotalValue: i?.avgTotalValue,
    }))

    const headers = [
        {
            key: 'outlet',
            name: 'Outlet',
        },
        {
            key: 'location',
            name: 'Location',
        },
        {
            key: 'lga',
            name: 'LGA',
        },
        brand !== "" &&
        {
            key: 'brand',
            name: 'Brand',
        },
        {
            key: 'total',
            name: 'Total',
        },
        {
            key: 'avg',
            name: 'Average',
        },
        {
            key: 'totalCase',
            name: 'Total Case',
        },
        {
            key: 'avgTotalCase',
            name: 'Average Total Case',
        },
        {
            key: 'totalValue',
            name: 'Total Value',
        },
        {
            key: 'avgTotalValue',
            name: 'Average Total Value',
        },
    ]



    const querParamx = queryParams.filter((item) => (
        item.key !== 'page' && item.key !== 'limit' && item.key !== 'download'
    ))

    querParamx.push({
        key: 'download',
        value: true
    })



    return (
        <div className={classes.screenContainer}>

            <InitiativeQueryComponent
                data={iData}
                searchValues={searchValues}
                setSearchValues={setSearchValues}
                filterList1={{ title: 'Brand', name: 'brandName', data: options1 }}
                serverExport2={true}
                entity="competitors/aggregate/store"
                queryParamsx={querParamx}
                placeholder={'search by store name'}
            />


            {
                loading ?
                    <div className={classes.loader_con}>
                        <Loader />
                    </div>
                    :
                    <>
                        {
                            docs.length ?
                                <div className={classes.table_container}>

                                    <div className={classes.titleAndInstructionContainer}>
                                        <p className={classes.title}>Store Aggregates (Competitors) Found: {total}</p>
                                        <p className={classes.instruction}>Click on each row to see more </p>
                                    </div>

                                    <p className={classes.showing}>
                                        Showing: <span>{docs.length || 1} </span>
                                    </p>
                                    <div className={classes.scrollable}>
                                        <table className={classes.table}>
                                            <thead>
                                                <tr>
                                                    <th>Store</th>
                                                    <th>Location</th>
                                                    <th>LGA</th>
                                                    <th>Average</th>
                                                    <th>Entries</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {docs.map((item, index) => (
                                                    <tr key={index}>

                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>{item?.store?.name}</p>
                                                            <p className={classes.tooltip}>{item?.store?.name}</p>
                                                        </td>
                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>{item?.store?.state}</p>
                                                            <p className={classes.tooltip}>{item?.store?.state}</p>
                                                        </td>
                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>{item?.store?.area}</p>
                                                            <p className={classes.tooltip}>{item?.store?.area}</p>
                                                        </td>
                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>{moneyFormat.format(item.avg)}</p>
                                                            <p className={classes.tooltip}>{moneyFormat.format(item.avg)}</p>
                                                        </td>

                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>{numberFormat.format(item.count)}</p>
                                                            <p className={classes.tooltip}>{numberFormat.format(item.count)}</p>
                                                        </td>

                                                    </tr>
                                                ))}
                                            </tbody>


                                        </table>

                                    </div>

                                    <InitiativePaginationComponent
                                        page={searchValues.page} totalCount={total} setSearchValues={setSearchValues} setInputPage={setInputPage} inputPage={inputPage} limit={searchValues.limit}
                                    />

                                </div>
                                :
                                <InitiativeEmptyState searchValues={searchValues} name={'store aggregate competitors'} />

                        }

                    </>
            }



        </div>
    )
}

export default PeriodAggregate;