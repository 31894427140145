
import classes from './activeResources.module.css'

const defaultUglyPlaceHolder = 'https://res.cloudinary.com/aoproton/image/upload/v1592466937/ichurch/uccsobarpmaobunukx6v.png'


export function ActiveResourceCard({ img, name, mail, phoneNumber }) {
    
    return (
        <div className={classes.cardContainer}>
            <div className={classes.cardImage} style={img !== defaultUglyPlaceHolder ? { backgroundImage: `url(${img})` } : {}} />
            <p className={classes.cardName}>{name}</p>
            <a className={classes.cardLinks} href={`tel:${phoneNumber}`} target="_blank" rel="noopener noreferrer">{phoneNumber}</a>
            <a className={classes.cardLinks} href={`mailto:${mail}`} target="_blank" rel="noopener noreferrer">{mail}</a>
        </div>
    )
}