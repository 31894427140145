import React, { useState } from 'react'
import { Box, Checkbox, Flex, Text, useToast } from '@chakra-ui/react';
import TagsInput from 'react-tagsinput';
import CustomInput from '../../../../../components/customInput/customInput';
import CustomSelect from '../../../../../components/customSelect/customSelect';
import PrimaryButton from '../../../../../components/primaryButton/primarybutton';
import axiosInstance from '../../../../../services/api';

const EditQuestion = ({data, setRefetch, surveyId, onClose}) => {
  const [title, setTitle] = useState(data?.title);
  const [fieldName, setFieldName] = useState(data?.fieldName);
  const [fieldType, setFieldType] = useState(data?.fieldType);
  const [isRequired, setIsRequired] = useState(data?.isRequired);
  const [fieldOptions, setFieldOptions] = useState(data?.fieldOptions);

  const toast = useToast()
  
  const [loading, setLoading] = useState(false);

  const handleAddOptions = (option) => {
    setFieldOptions(option)
  }

  const addQuestion = async(e) => {
    e.preventDefault()
    setLoading(true)
    const datax = fieldOptions?.length > 0 ? 
    {
      title,
      fieldName,
      fieldType,
      fieldOptions,
      isRequired
    }
    :
    {
      title,
      fieldName,
      fieldType,
      isRequired
    }
    try {
      const res = await axiosInstance.patch(`/surveys/${surveyId}/questions/${data?._id}`, datax)
      toast({
        description: res?.data?.message,
        status: "success",
        position: "top"
      });
      onClose()
      setRefetch(Math.random())
      setFieldName('')
      setFieldType('')
      setTitle('')
      setFieldOptions([])
    } catch(error) {
      if(error?.response?.data?.message) {
        toast({
          title: "An Error Occurred",
          description: error?.response?.data?.message,
          status: "error",
          position: "top"
        });
      }
    } finally {
      setLoading(false)
    }
  }

  const fieldTypeOptions = [
    {
        title: "Text",
        value: "text"
    },
    {
        title: "Select",
        value: "select"
    },
    {
        title: "Email",
        value: "email"
    },
    {
        title: "Tel",
        value: "tel"
    },
    {
      title: "Date",
      value: "date"
    },
    {
        title: "Textarea",
        value: "textarea"
    },
    {
        title: "Image",
        value: "image"
    },
    {
        title: "Checkbox",
        value: "checkbox"
    },
    {
        title: "Radio",
        value: "radio"
    },
  ]


  return (
    <Box mb="2rem">
        <CustomInput
        type={"text"}
        required
        placeholder={"Title"}
        onChange={e => setTitle(e.target.value)}
        value={title}
        />
        <CustomInput
        type={"text"}
        required
        placeholder={"Field Name"}
        onChange={e => setFieldName(e.target.value)}
        mt=".5rem"
        value={fieldName}
        />
        <CustomSelect
        required
        options={fieldTypeOptions}
        value={fieldType}
        placeholder={"Field Type"}
        onChange={e => setFieldType(e.target.value)}
        borderRadius="10px"
        my=".5rem"
        />
        {(fieldType === "select" || fieldType === "checkbox" || fieldType === "radio") && <TagsInput allowUnique={true} value={fieldOptions} className='tag-input' inputProps={{placeholder: "Add Options"}} maxTags={100} onChange={handleAddOptions} />}
        <Flex alignItems={"center"} mt=".7rem" gap=".3rem">
            <Checkbox defaultChecked={isRequired} onChange={(e) => setIsRequired(e.target.checked)} />
            <Text opacity={".8"}>Is Field Required ?</Text>
        </Flex>
        
        <PrimaryButton mt="2rem" type="submit" text="Update Question" onClick={addQuestion} loading={loading} />
    </Box>
  )
}

export default EditQuestion;