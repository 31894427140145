import React from 'react'
import EmployeeTable from '../../../components/employeeView/employeeTable/employeeTable';
import { useSelector } from 'react-redux';

const Promoters = () => {
  const currentUser = useSelector(({ userData }) => userData.currentUser);
  const clientId = currentUser?.role === 'Client' ? currentUser?._id : ''
  return (
    <EmployeeTable title={"Promoter"} clientId={clientId} />
  )
}

export default Promoters;