import { initiativeNavItems } from '../../constants/initiativeNavItems'
import classes from './initiativeSideNav.module.css'



export function InitiativeSideNav({ activeScreen, setActiveScreen }) {
    return (
        <div className={classes.container}>
            <p className={classes.title}>Activities</p>

            <div className={classes.navItemsGrouped}>
                {["Stores", ...initiativeNavItems]?.map((item, index) => (
                    <p onClick={() => setActiveScreen(item)} style={activeScreen === item ? { color: '#fff', background: '#001a2ac8' } : {}} className={classes.navItem} key={index}>
                        {item}
                    </p>
                ))}
            </div>
        </div>
    )
}