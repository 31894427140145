import React from "react";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";

const Map = ({coordinates, height}) => {

  const MyMapComponent = withGoogleMap((props) => (
    <GoogleMap defaultZoom={18} defaultCenter={{ lat: coordinates[1], lng: coordinates[0]  }}>
      <Marker 
        key={Math.random()} 
        labelStyle={{color: "red !important"}} 
        defaultTitle="hgchgc" 
        position={{  lat: coordinates[1], lng: coordinates[0]  }} 
      />
    </GoogleMap>
  ));


  return (
    <div className="live-tracking" style={{flex: 3}}>
        <div style={{position: "relative"}} className="map-area">
            <MyMapComponent isMarkerShown
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: height }} />}
            mapElement={<div style={{ height: `100%` }} />}
            />
        </div>
    </div>
  );
};

export default Map;