/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../../../services/api';
import CustomInput from '../../../../../components/customInput/customInput';
import LineSkeleton from '../../../../../components/lineSkeleton';
import EmptyState from '../../../../../components/emptyState/emptyState';
import ExportCSV from '../../../../../components/exportCsv';
import { useSelector } from 'react-redux';
import { buildResourceURL, buildResourceURL2 } from '../../../../../utils/helper';
import ServerExport from '../../../../../components/serverExport/serverExport';
import { convertToHoursAndMinutes } from '../../../../../helpers/convertToHoursAndMinutes';
import classes from './attendance.module.css'
import { InitiativeEmptyState } from '../../../../../components/initiativeEmptyState/initiativeEmptyState';
import { Loader } from '../../../../../components/Loader/loader';
import { ServerExport2 } from 'components/NewExport/NewExport';
import { InitiativePaginationComponent } from 'components/initiativePaginationComponent/initiativePaginationComponent';



const TotalPeriodAggAttendance = ({ data }) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    const [searchVal, setSearchVal] = useState('')

    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const [startDate, setStartDate] = useState(formattedDate)
    const [endDate, setEndDate] = useState(formattedDate)
    const [total, setTotal] = useState('')


    const [paginationValues, setPaginationValues] = useState({
        page: 1,
        limit: 100
    })

    const { limit, page } = paginationValues

    const [inputPage, setInputPage] = useState(page);

    const { iid } = useParams();




    const handleDate = (e) => {
        setStartDate(e.target.value)
    }
    const handleDatex = (e) => {
        setEndDate(e.target.value)
    }

    const queryParams = [
        {
            key: 'initiativeId',
            value: iid
        },
        {
            key: 'startDate',
            value: startDate
        },
        {
            key: 'endDate',
            value: endDate
        },
        {
            key: "searchQuery",
            value: searchVal,
        },
        {
            key: "page",
            value: page,
        },
        {
            key: "limit",
            value: limit,
        },
        {
            key: 'download',
            value: false
        },
    ]

    const getRecords = async () => {
        setLoading(true)
        try {
            const { data } = await axiosInstance.get(buildResourceURL('attendances/aggregate/users/total', queryParams))
            setRecords(data?.aggregateResult)
            setTotal(data?.totalCount)
        } catch (error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getRecords()
    }, [startDate, endDate, searchVal, paginationValues])

    const refinedData = records?.map(i => ({
        hoursWorked: i?.totalHoursWorked,
        promoter: i?.user?.firstName + " " + i?.user?.lastName
    }))

    const headers = [
        {
            key: 'promoter',
            name: 'User',
        },
        {
            key: 'hoursWorked',
            name: 'Total Hours Worked',
        },
    ]

    // const filteredRecords = searchVal !== '' ? records?.aggregateResult?.filter(i => i?.promoter?.firstName?.toLowerCase().includes(searchVal?.toLowerCase())) : records?.aggregateResult


    const querParamx = queryParams.filter((item) => (
        item.key !== 'page' && item.key !== 'limit' && item.key !== 'download'
    ))

    querParamx.push({
        key: 'download',
        value: true
    })

    return (
        <div className={classes.screenContainer}>
            <Flex mb="1.2rem" alignItems={"center"} justifyContent={"space-between"}>
                <Flex gap={"1.5rem"} alignItems={"center"}>
                    <Text whiteSpace={"nowrap"}><strong>{records?.length}</strong> clock-ins</Text>
                    <CustomInput
                        type={"text"}
                        required
                        value={searchVal}
                        placeholder={`Search by user..`}
                        onChange={e => setSearchVal(e.target.value)}
                        mt="0"
                        sType="search"
                        py="1.19rem"
                        width="350px"
                    />
                </Flex>
                <Flex gap="1rem" alignItems={"center"}>
                    <ServerExport2 entity={"attendances/aggregate/users/total"} queryParamsx={querParamx} />

                    <Box width={"150px"}>
                        <CustomInput
                            type={"date"}
                            required
                            py=".95rem"
                            placeholder={"Select Date"}
                            onChange={handleDate}
                            mt="0"
                            value={startDate}
                        />
                    </Box>
                    <Box width={"150px"}>
                        <CustomInput
                            type={"date"}
                            required
                            py=".95rem"
                            placeholder={"Select Date"}
                            onChange={handleDatex}
                            mt="0"
                            value={endDate}
                        />
                    </Box>
                </Flex>
            </Flex>

            {loading ?
                <div className={classes.loader_con}>
                    <Loader />
                </div>
                :
                records?.length < 1 ? <InitiativeEmptyState searchValues={null} name={'Total Periodic aggregated attendance for this date'} />
                    :
                    endDate !== "" &&
                    <div className={classes.scrollable}>

                        <p className={classes.title}>Users Periodic Aggregate Found: {total}</p>
                        <p className={classes.showing}>
                            Showing:{" "}
                            <span>
                                {records?.length || 1}
                            </span>
                        </p>


                        <table className={classes.table}>
                            <thead>
                                <tr>
                                    <th>User</th>
                                    <th>Role</th>
                                    <th>Hours Worked</th>

                                </tr>
                            </thead>
                            <tbody>
                                {records?.map((item, index) => (
                                    <tr key={index}>

                                        <td className={classes.tooltip_container}>
                                            <p className={classes.item_text}>{`${item?.user?.firstName} ${item?.user?.lastName}`}</p>
                                            <p className={classes.tooltip}>{`${item?.user?.firstName} ${item?.user?.lastName}`}</p>
                                        </td>
                                        <td className={classes.tooltip_container}>
                                            <p className={classes.item_text}>{item?.user?.role}</p>
                                            <p className={classes.tooltip}>{item?.user?.role}</p>
                                        </td>

                                        <td className={classes.tooltip_container}>
                                            <div className={classes.attendanceIndicatorContainer}>
                                                <div style={(item?.totalHoursWorked / 8) > 1 ? { width: '100%' } : { width: `${item?.totalHoursWorked / 8 * 100}%` }} className={item?.totalHoursWorked < 2.67 ? classes.attendanceIndicatorRed : item?.totalHoursWorked < 5.33 ? classes.attendanceIndicatorOrange : classes.attendanceIndicatorGreen} />
                                            </div>
                                            <p className={classes.item_text}>{convertToHoursAndMinutes(item?.totalHoursWorked)}</p>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>


                        </table>

                        <InitiativePaginationComponent
                            page={page} totalCount={total} setSearchValues={setPaginationValues} setInputPage={setInputPage} inputPage={inputPage} limit={paginationValues.limit}
                        />

                    </div>

                    
            }
        </div >
    )
}

export default TotalPeriodAggAttendance;