/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import axiosInstance from '../../../../../../../../services/api';
import { buildResourceURL } from '../../../../../../../../utils/helper';
import { InitiativeEmptyState } from '../../../../../../../../components/initiativeEmptyState/initiativeEmptyState';
import { InitiativePaginationComponent } from '../../../../../../../../components/initiativePaginationComponent/initiativePaginationComponent';
import { FaRegUser } from 'react-icons/fa';
import { MdStorefront } from 'react-icons/md';
import { formatDateHeader } from '../../../../../../../../helpers/formatDateHeader';
import { moneyFormat } from '../../../../../../../../helpers/moneyFormatter';
import classes from './competitors.module.css'
import { InitiativeQueryComponent } from '../../../../../../../../components/initiativeQueryComponent/initiativeQueryComponent';
import { Loader } from '../../../../../../../../components/Loader/loader';
import { defaultSearchValueState } from '../../../../../../../../constants/defaultStates';
import UtilityContext from '../../../../../../../../context/utilityContext';
import defaultImage from '../../../../../../../../assets/placeholderImage.png'
import { useParams } from 'react-router-dom';
import { TbMoneybag } from "react-icons/tb";
import moment from 'moment';


const Competitors = ({ data }) => {

    const [loading, setLoading] = useState(false);
    const [docs, setDocs] = useState([])
    const [total, setTotal] = useState('')

    const [searchValues, setSearchValues] = useState(defaultSearchValueState)
    const { draw } = useContext(UtilityContext)

    const { page } = searchValues

    const limit = 50;

    const endDate = new Date(searchValues.endDate);  // Create a new date object based on the current date

    endDate.setDate(endDate.getDate() + 1);


    const { sid } = useParams();


    const queryParams = [
        {
            key: sid ? 'initiativeStore' : 'initiative',
            value: sid ? sid : data?._id
        },
        {
            key: 'date[gte]',
            value: searchValues.startDate
        },
        {
            key: 'date[lt]',
            value: searchValues?.endDate ? endDate.toISOString().split('T')[0] : ""
        },
        {
            key: 'state',
            value: searchValues.states.toLowerCase()
        },
        {
            key: 'limit',
            value: limit
        },
        {
            key: 'page',
            value: page
        },
        {
            key: 'sort',
            value: '-date'
        },
        {
            key: 'initiativeStoresFilterGroupId',
            value: searchValues.groups
        },
        {
            key: 'productsFilterGroupId',
            value: searchValues.products
        },
        {
            key: 'statesFilterGroupId',
            value: searchValues.regions
        },
        {
            key: 'search',
            value: searchValues.searchQuery
        },
    ]


    const queryParamsx = [
        {
            key: sid ? 'initiativeStore' : 'initiative',
            value: sid ? sid : data?._id
        },
        {
            key: 'date[gte]',
            value: searchValues.startDate
        },
        {
            key: 'date[lt]',
            value: searchValues?.endDate ? endDate.toISOString().split('T')[0] : ""
        },
        {
            key: 'state',
            value: searchValues.states.toLowerCase()
        },
        {
            key: 'initiativeStoresFilterGroupId',
            value: searchValues.groups
        },
        {
            key: 'productsFilterGroupId',
            value: searchValues.products
        },
        {
            key: 'statesFilterGroupId',
            value: searchValues.regions
        },
    ]

    const getDocs = async () => {
        setLoading(true)
        try {
            const res = await axiosInstance.get(buildResourceURL('competitors', queryParams))
            setDocs(res?.data?.data)
            setTotal(res?.data?.totalCount)
        } catch (error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getDocs()
    }, [searchValues])



    const [inputPage, setInputPage] = useState(page)



    return (
        < div className={classes.screenContainer}>

            <InitiativeQueryComponent
                data={data}
                searchValues={searchValues}
                setSearchValues={setSearchValues}
                entity='competitors' queryParamsx={queryParamsx}
            />

            {
                loading ?
                    <div className={classes.loader_con}>
                        <Loader />
                    </div>
                    :
                    <>
                        {
                            docs.length ?
                                <div className={classes.table_container}>

                                    <div className={classes.titleAndInstructionContainer}>
                                        <p className={classes.title}>Total Competitors Found: {total}</p>
                                        <p className={classes.instruction}>Click on each row to see more details</p>
                                    </div>

                                    <p className={classes.showing}>
                                        Showing: <span>{docs.length || 1} of {total}</span>
                                    </p>
                                    <div className={classes.scrollable}>
                                        <table className={classes.table}>
                                            <thead>
                                                <tr>
                                                    <th>Creation Date</th>
                                                    <th>Brand</th>
                                                    <th>SKU</th>
                                                    <th>Store</th>
                                                    <th>Location</th>
                                                    <th>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {docs.map((item, index) => (
                                                    <tr onClick={() => draw({
                                                        title: 'Competitors', content:
                                                            <div className={classes.modalContainer}>

                                                                <img onClick={item.imageURL ? () => window.open(item.imageURL) : null} src={item.imageURL || defaultImage} alt='' className={classes.image} />

                                                                <div className={classes.modalTitleAndDateContainer}>
                                                                    <p className={classes.modalTitle}>{item?.brandName} - {item?.sku}</p>
                                                                    <p>{formatDateHeader(moment(item.date).format('YYYY-MM-DD'))}</p>
                                                                </div>

                                                                <div className={classes.modalStoreAddressContainer}>
                                                                    <p className={classes.modalStoreAddress}>{item?.initiativeStore?.store?.name} - {item?.initiativeStore?.store?.streetNum || 'No Bld. num.'}, {item?.initiativeStore?.store?.streetName}</p>
                                                                    <MdStorefront className={classes.modalIcon} />
                                                                </div>

                                                                <div className={classes.modalStoreAddressContainer}>
                                                                    <div style={{ width: '90%' }}>
                                                                        <p className={classes.modalStoreAddress} >{item?.user?.name ? item?.user?.name : `${item?.user?.firstName} ${item?.user?.lastName || ''}`}</p>
                                                                        <p className={classes.messageDescription}>{item?.user?.role}</p>
                                                                    </div>

                                                                    <FaRegUser className={classes.modalIcon} />
                                                                </div>


                                                                <div className={classes.modalStoreAddressContainer}>
                                                                    <div style={{ width: '90%' }}>
                                                                        <p className={classes.modalStoreAddress} >{moneyFormat.format(item?.price)}</p>
                                                                        <p className={classes.messageDescription}>Price</p>
                                                                    </div>

                                                                    <TbMoneybag className={classes.modalIcon} />
                                                                </div>


                                                            </div>
                                                    })} key={index}>
                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>
                                                                {formatDateHeader(moment(item.date).format('YYYY-MM-DD'))}
                                                            </p>
                                                            <p className={classes.tooltip}>
                                                                {formatDateHeader(moment(item.date).format('YYYY-MM-DD'))}
                                                            </p>
                                                        </td>

                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>{item.brandName}</p>
                                                            <p className={classes.tooltip}>{item.brandName}</p>
                                                        </td>
                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>{item.sku}</p>
                                                            <p className={classes.tooltip}>{item.sku}</p>
                                                        </td>
                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>{item.initiativeStore?.store?.name}</p>
                                                            <p className={classes.tooltip}>{item.initiativeStore?.store?.name}</p>
                                                        </td>
                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>{item.initiativeStore?.store?.state}</p>
                                                            <p className={classes.tooltip}>{item.initiativeStore?.store?.state}</p>
                                                        </td>
                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>{moneyFormat.format(item.price)}</p>
                                                            <p className={classes.tooltip}>{moneyFormat.format(item.price)}</p>
                                                        </td>

                                                    </tr>
                                                ))}
                                            </tbody>


                                        </table>

                                    </div>




                                    <InitiativePaginationComponent
                                        page={page} totalCount={total} setSearchValues={setSearchValues} setInputPage={setInputPage} inputPage={inputPage} limit={limit}
                                    />
                                </div>
                                :
                                <InitiativeEmptyState searchValues={searchValues} name={'competitors'} />

                        }

                    </>
            }



        </div>

    )
}

export default Competitors;
