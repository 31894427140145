import { Box, Checkbox, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure, useToast } from '@chakra-ui/react';
import CustomInput from 'components/customInput/customInput';
import PrimaryButton from 'components/primaryButton/primarybutton';
import SpinLoader from 'components/spinLoader/spinLoader';
import React, { useEffect, useState } from 'react'
import { RiCloseCircleLine } from 'react-icons/ri';
import axiosInstance from 'services/api';

const Assignees = ({surveyId}) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const [refetch, setRefetch] = useState('');
  const [searchVal, setSearchVal] = useState('')
  const [selectedUsers, setSelectedUsers] = useState([]);

   const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast()

  const getUsers = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(`/surveys/${surveyId}/assigned-users`);
      setUsers(data?.data);
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const unassignUsers = async () => {
    setLoadingx(true);
    const datax = {
        userIDs: selectedUsers
    }
    try {
      const { data } = await axiosInstance.patch(`/surveys/${surveyId}/unassign-users`, datax);
      setRefetch(Math.random());
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      setSelectedUsers([])
      onClose()
    } catch (error) {
      console.error(error?.response?.data?.message);
      if (error?.response?.data?.message) {
        toast({
          title: "An Error Occurred",
          description: error?.response?.data?.message,
          status: "error",
          position: "top",
        });
      }
    } finally {
      setLoadingx(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, [surveyId, refetch]);

  const selectAll = () => {
    if (selectedUsers?.length === users?.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(users?.map((i) => i?._id));
    }
  };

  const handleSelect = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add item to the array if it doesn't already exist
      if (!selectedUsers?.includes(value)) {
        setSelectedUsers([...selectedUsers, value]);
      }
    } else {
      // Remove item from the array if it exists
      const filteredItems = selectedUsers.filter((item) => item !== value);
      setSelectedUsers(filteredItems);
    }
  };


  const filteredUsers = !loading ? users?.filter(x => x?.firstName?.toLowerCase()?.includes(searchVal?.toLowerCase())) : []

  return (
    <div>
         <CustomInput
        type={"text"}
        required
        value={searchVal}
        placeholder='Search Users..'
        onChange={e => setSearchVal(e.target.value)}
        mt="0"
        mb="1rem"
        sType="search"
        py="1.19rem"
        />
       {!loading &&
        <Flex justifyContent={"space-between"} alignItems={"center"} mb=".7rem">
        <Flex gap=".7rem" alignItems={"center"}>
        <Checkbox
        onChange={selectAll}
        isChecked={selectedUsers?.length === users?.length}
        colorScheme="green"
        />
        <Text>{selectedUsers?.length} selected</Text>
        </Flex>
        {selectedUsers?.length > 0 && <PrimaryButton width="120px" py="1rem" text="Unassign" onClick={() => onOpen()} />}
        </Flex>}
        {loading ?
        <SpinLoader />
        :
        filteredUsers?.map(({firstName, lastName, email, _id: id}) => {
            return (
                <Flex key={id} alignItems={"center"} justifyContent={"space-between"} padding={'.4rem .5rem'} mb=".4rem" borderRadius={"4px"} border="1px solid lightgray">
                    <Flex alignItems={"center"} gap="1rem">
                    <Checkbox
                    onChange={(e) => handleSelect(e)}
                    value={id}
                    isChecked={selectedUsers?.includes(id)}
                    colorScheme="green"
                    />
                    <Box>
                        <Text fontWeight={"500"} textTransform={"capitalize"} fontSize={".93rem"}>{firstName} {lastName}</Text>
                        <Text fontSize={".87rem"}>{email}</Text>
                    </Box>
                    </Flex>
                </Flex>
            )
        })}

<Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{'Unassign users'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {/* {modalType === 'delete' && */}
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm unassignment of user(s)?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                            <PrimaryButton onClick={unassignUsers} text="Unassign" width="150px" padding="1rem !important" loading={loadingx} />
                        </Flex>
                    </>
                    {/* } */}
                    <Text pb="1rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Cancel</Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    </div>
  )
}

export default Assignees;