const moment = require('moment');

export const formatDateHeader = (dateStr) => {
    const today = moment().format('YYYY-MM-DD');
    const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD');

    if (dateStr === today) {
        return 'Today';
    } else if (dateStr === yesterday) {
        return 'Yesterday';
    } else {
        return moment(dateStr).format('DD/MM/YYYY');
    }
};
