import ListCompare from '../../../../../../../../components/listCompare/listCompare'
import PeriodAggregate from './periodAggregate'
import Sos from './sos'
import AggregateSos from './aggregateSos'
import { InitiativeHeaderTab } from '../../../../../../../../components/initiativeHeaderTab/initiativeHeaderTab'
import { useState } from 'react'
import classes from './sos.module.css'
import StoreBrandAggregate from './storeBrandAggregate'
import PreloadedBrands from './PreloadedSos/preloadedBrands'

const AllSos = ({ initiative, setRefetch }) => {
   

    const assignor = {
        "All SOS": <Sos data={initiative} />,
        "Brand Aggregates": <AggregateSos data={initiative} />,
        "Store Aggregates": <PeriodAggregate iData={initiative} />,
        "Store/Brand Aggregates": <StoreBrandAggregate iData={initiative} />,
        "Not Submitted": <ListCompare listType={'sos'} />,
        "Preloaded SOS Brands": <PreloadedBrands initiative={initiative} setRefetch={setRefetch} />
    }

    const [selected, setSelected] = useState(Object.keys(assignor)[0])

    return (
        <div className={classes.container}>
            <InitiativeHeaderTab list={Object.keys(assignor)} selected={selected} setSelected={setSelected} />
            <div>
                {assignor[selected]}
            </div>
        </div>
    )
}

export default AllSos;