import { Box, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import PrimaryButton from '../../primaryButton/primarybutton'
import './style.css'
import axiosInstance from '../../../services/api'

const InviteEmployee = ({title, setTriggerClose, setRefetch}) => {
  const [loading, setLoading] = useState()
  const [tags, setTags] = useState([])

  const toast = useToast();

  const handleAddTags = (tags) => {
    setTags(tags)
  }

  const sendInvite = async() => {
    setLoading(true)
    const datax = {
        role: title,
        emails: tags
    }
    try {
        const { data } = await axiosInstance.post('employees/invite', datax)
        toast({
        description: data?.message,
        status: "success",
        position: "top"
        });
        setTriggerClose('close')
        setRefetch(Math.random())
    } catch(error) {
        toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
        });
    } finally {
        setLoading(false)
    }
}


  return (
    <Box mt="1rem">
     <TagsInput allowUnique={true} value={tags} className='tag-input' inputProps={{placeholder: "Add Email"}} maxTags={20} onChange={handleAddTags} />
     <PrimaryButton mt="6rem" type="submit" text="Send Invites" onClick={sendInvite} loading={loading} />
    </Box>
  )
}

export default InviteEmployee