import classes from './assetsSideNav.module.css'
import { assetsNavItems } from 'constants/assetsNavItems'



export function AssetSideNav({ activeScreen, setActiveScreen }) {
    return (
        <div className={classes.container}>
            <p className={classes.title}>Activities</p>

            <div className={classes.navItemsGrouped}>
                {assetsNavItems?.map((item, index) => (
                    <p onClick={() => setActiveScreen(item)} style={activeScreen === item ? { color: '#fff', background: '#001a2ac8' } : {}} className={classes.navItem} key={index}>
                        {item}
                    </p>
                ))}
            </div>
        </div>
    )
}