/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../../../services/api';
import ResourceListTemplate from './resourceListTemplate';
import { buildResourceURL } from '../../../../../utils/helper';
import classes from './activeResources.module.css'
import { Loader } from '../../../../../components/Loader/loader';
import { InitiativeHeaderTab } from '../../../../../components/initiativeHeaderTab/initiativeHeaderTab';
import { useParams } from 'react-router-dom';

const ActiveResources = () => {
    const [stores, setStores] = useState([]);
    const [subUsers, setSubUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [, setLoadingx] = useState(false);


    const { iid: initiativeId } = useParams()


    const getInitiativeStores = async () => {
        setLoading(true)
        try {
            const { data } = await axiosInstance.get(`initiatives/${initiativeId}/initiative-stores?limit=500`)
            setStores(data?.data)
            console.log(data?.data)
        } catch (error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }



    useEffect(() => {
        getInitiativeStores()
    }, [])

    const queryParams = [
        {
            key: 'initiatives[in]',
            value: initiativeId
        },
    ]
    const getSubuser = async () => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.get(buildResourceURL('subusers', queryParams))
            setSubUsers(data?.data)
        } catch (error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoadingx(false)
        }
    }

    useEffect(() => {
        getSubuser()
    }, [])

    const activePromoters = stores?.map(i => ({...i?.promoter, role: 'promoter'}))
    const activeSupervisors = stores?.map(i => ({...i?.supervisor, role: 'supervisor'}))
    const activeSubusers = subUsers?.map(i => i)



    const assignor = {
        "Promoters": <ResourceListTemplate data={activePromoters} type='Promoters' />,
        "Supervisors": <ResourceListTemplate data={activeSupervisors} type='Supervisors' />,
        "Sub Users": <ResourceListTemplate data={activeSubusers} type='Sub Users' />
    }

    const [selected, setSelected] = useState(Object.keys(assignor)[0])

    return (
        <div className={classes.container}>
            {loading ?
                <div className={classes.loadingContainer}>
                    <Loader />
                </div>
                :
                // <CustomTabs data={datax} />

                <div className={classes.container}>
                    <InitiativeHeaderTab list={Object.keys(assignor)} selected={selected} setSelected={setSelected} />
                    <div>
                        {assignor[selected]}
                    </div>
                </div>
            }
        </div>
    )
}

export default ActiveResources;