import { Box } from '@chakra-ui/react'
import {lazy, Suspense} from 'react'
import CustomTabs from '../../../../components/customTabs/customTabs'
import SpinLoader from '../../../../components/spinLoader/spinLoader'
import Tasks from './tasks/tasks'
import AllSales from './initiativeStores/initiativeStoreDetails/components/sales/allSales'
import AllInventory from './initiativeStores/initiativeStoreDetails/components/inventory/allInventory'
import AggAttendance from './aggAttendance/aggAttendance'
import Reports from './reports/OutletReports'
import ShelfAndPosm from './initiativeStores/initiativeStoreDetails/components/shelfPosm/shelfPosm'
import ListCompare from '../../../../components/listCompare/listCompare'
import Rewards from './rewards/rewards'
import { useSelector } from 'react-redux'
import AllPricing from './initiativeStores/initiativeStoreDetails/components/priceCheck/allPricing'
import AllOrders from './initiativeStores/initiativeStoreDetails/components/orders/allOrders'
import AllPayments from './initiativeStores/initiativeStoreDetails/components/payments/allPayments'
import SurveysTable from '../../surveys/surveysTable/surveysTable'
import AllCompetitors from './initiativeStores/initiativeStoreDetails/components/competitors/allCompetitors'
import AllShipments from './initiativeStores/initiativeStoreDetails/components/shipments/allShipments'
import MessagesTable from './initiativeStores/initiativeStoreDetails/components/messages/messages'
import AllMsl from './initiativeStores/initiativeStoreDetails/components/msl/allMsl'
import AllSos from './initiativeStores/initiativeStoreDetails/components/sos/allSos'
import ActiveResources from './activeResources/activeResources'
import IStores from './iStores/iStores'
import AllStoreInventory from './initiativeStores/initiativeStoreDetails/components/storeInventory/allStoreInventory'

const InitiativeDetails = ({initiative, setRefetch, loading, iid}) => {

    const currentUser = useSelector(({userData})=>  userData?.currentUser);

    // const data = [
    //     {
    //         title: "Mechanics",
    //         render: <Overview initiative={initiative} setRefetch={setRefetch} />
    //     },
    //     {
    //         title: "Shelf & POSM",
    //         render: <ShelfAndPosm data={initiative} src={"full"} />
    //     },
    //     {
    //         title: "Competitors",
    //         render: <AllCompetitors initiative={initiative} setRefetch={setRefetch} />
    //     },
    //     {
    //         title: "Price Checks",
    //         render: <AllPricing initiative={initiative} />
    //     },
    //     {
    //         title: "Sales",
    //         render: <AllSales data={initiative} initiative={initiative} />
    //     },
    //     {
    //         title: "Shelf Inventory",
    //         render: <AllInventory data={initiative} initiative={initiative} />
    //     },
    //     {
    //         title: "Out of Stock",
    //         render: <AllOos data={initiative} initiative={initiative} />
    //     },
    //     {
    //         title: "Shipments",
    //         render: <AllShipments data={initiative} initiative={initiative} />
    //     },
    //     {
    //         title: "Store Inventory",
    //         render: <AllStoreInventory data={initiative} initiative={initiative} />
    //     },
    //     {
    //         title: "Tasks",
    //         render: <Tasks data={initiative} />
    //     },
    //     {
    //         title: "Attendance",
    //         render: <AllAttendance initiative={initiative} />
    //     },
    //     {
    //         title: "Reports",
    //         render: <AllReports data={initiative} />
    //     },
    //     {
    //         title: "Stores",
    //         render: <IStores data={initiative} setRefetch={setRefetch} />
    //     },
    //     {
    //         title: "Rewards",
    //         render: <Rewards data={initiative} />
    //     },
    //     {
    //         title: "Orders",
    //         render: <AllOrders data={initiative} initiative={initiative} />
    //     },
    //     {
    //         title: "MSL",
    //         render: <AllMsl data={initiative} initiative={initiative} setRefetch={setRefetch} />
    //     },
    //     {
    //         title: "Share of Shelf",
    //         render: <AllSos data={initiative} initiative={initiative} setRefetch={setRefetch}  />
    //     },
    //     {
    //         title: "Payments",
    //         render: <AllPayments data={initiative} initiative={initiative} />
    //     },
    //     {
    //         title: "Surveys",
    //         render: <SurveysTable initiativeId={initiative?._id} />
    //     },
    //     {
    //         title: "Message Center",
    //         render: <MessagesTable initiativeId={initiative?._id} />
    //     },
    //     (currentUser?.role?.includes('Admin') || currentUser?.role === 'Agency') &&
    //     {
    //         title: "Active Resources",
    //         render: <ActiveResources initiativeId={initiative?._id} />
    //     },
    // ]
  return (
    <Box>
        {loading ?
        <SpinLoader />
        :
        <Box bg="#FFF" mt="1rem">
            <Box mt="1rem">
            <Suspense fallback={<SpinLoader />}>
                {/* <CustomTabs data={data} /> */}
            </Suspense>
            </Box>
        </Box>}
    </Box>
  )
}

export default InitiativeDetails