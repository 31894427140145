export const assetsNavItems = [
    "Active Assets",
    "Assets Comments",
    "Assets Conditions",
    "Assets Images",
    "Assets Inventories",
    "Assets MSL Scores",
]

export const assetsOverViewItems = [
    "Analytics", "Overview",
]

assetsNavItems.sort()
assetsOverViewItems.sort()