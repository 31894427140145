/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import axiosInstance from '../../../../../services/api';
import LineSkeleton from '../../../../../components/lineSkeleton';
import EmptyState from '../../../../../components/emptyState/emptyState';
import moment from 'moment';
import { buildResourceURL, truncateText } from '../../../../../utils/helper';
import { useParams } from 'react-router-dom';
import CustomInput from '../../../../../components/customInput/customInput';
import ExportCSV from '../../../../../components/exportCsv';
import { InitiativeEmptyState } from '../../../../../components/initiativeEmptyState/initiativeEmptyState';
import { InitiativePaginationComponent } from '../../../../../components/initiativePaginationComponent/initiativePaginationComponent';
import { numberFormat } from '../../../../../helpers/numberFormatter';
import { moneyFormat } from '../../../../../helpers/moneyFormatter';
import { formatDateHeader } from '../../../../../helpers/formatDateHeader';
import classes from '../initiativeStores/initiativeStoreDetails/components/msl/msl.module.css'
import { InitiativeQueryComponent } from '../../../../../components/initiativeQueryComponent/initiativeQueryComponent';
import { defaultSearchValueState } from '../../../../../constants/defaultStates';
import { Loader } from '../../../../../components/Loader/loader';
import UtilityContext from '../../../../../context/utilityContext';
import { MdStorefront } from 'react-icons/md';
import { FaRegUser } from 'react-icons/fa';


const Reports = ({ data }) => {
  const [reports, setReports] = useState([])
  const [loading, setLoading] = useState(true)
  const { iid } = useParams()

  const { draw } = useContext(UtilityContext)


  const [searchValues, setSearchValues] = useState(defaultSearchValueState)

  const { page } = searchValues


  const endDate = new Date(searchValues.endDate);  // Create a new date object based on the current date
  endDate.setDate(endDate.getDate() + 1);


  const queryParams = [
    {
      key: 'initiative',
      value: iid
    },
    {
      key: 'date[gte]',
      value: searchValues.startDate
    },
    {
      key: 'date[lt]',
      value: searchValues?.endDate ? endDate.toISOString().split('T')[0] : ""
    },
    {
      key: 'sort',
      value: '-date'
    },
  ]

  const getReports = async () => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.get(buildResourceURL('reports', queryParams))
      setReports(data?.data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getReports()
  }, [searchValues])


  const [inputPage, setInputPage] = useState(page)



  return (
    <div className={classes.screenContainer}>

      <InitiativeQueryComponent
        data={null}
        searchValues={searchValues}
        setSearchValues={setSearchValues}
        entity='reports' queryParamsx={queryParams}
      />



      {
        loading ?
          <div className={classes.loader_con}>
            <Loader />
          </div>
          :
          <>
            {
              reports.length ?
                <div className={classes.table_container}>

                  <div className={classes.titleAndInstructionContainer}>
                    <p className={classes.title}>Total Sales data Found: {reports?.length}</p>
                    <p className={classes.instruction}>Click on each row to see more </p>
                  </div>


                  <p className={classes.showing}>
                    Showing: <span>{reports.length || 1} of {reports?.length}</span>
                  </p>
                  <div className={classes.scrollable}>
                    <table className={classes.table}>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Name</th>
                          <th>Supervisor</th>
                          <th>Submitted On</th>
                          <th>Comment</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {reports.map((item, index) => (
                          <tr
                            onClick={() => draw({
                              title: 'Competitors', content:
                                <div className={classes.modalContainer}>

                                  <button onClick={() => window.open(item.uploadURLs[0])} className={classes.resolveButton} >
                                    View report
                                  </button>

                                  <div className={classes.modalTitleAndDateContainer}>
                                    <p className={classes.modalTitle}>{item?.name}</p>
                                    <p>{formatDateHeader(moment(item.date).format('YYYY-MM-DD'))}</p>
                                  </div>

                                  <div className={classes.modalStoreAddressContainer}>
                                    <div style={{ width: '90%' }}>
                                      <p className={classes.modalStoreAddress} >{item?.user?.name ? item?.user?.name : `${item?.user?.firstName} ${item?.user?.lastName || ''}`}</p>
                                      <p className={classes.messageDescription}>{item?.user?.role}</p>
                                    </div>

                                    <FaRegUser className={classes.modalIcon} />
                                  </div>

                                  <div className={classes.modalStoreAddressContainer}>
                                    <p className={classes.messageDescription}>{item?.comment || 'No comment found'}</p>
                                  </div>


                                </div>
                            })}
                            key={index}>
                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>
                                {formatDateHeader(moment(item.date).format('YYYY-MM-DD'))}
                              </p>
                              <p className={classes.tooltip}>
                                {formatDateHeader(moment(item.date).format('YYYY-MM-DD'))}
                              </p>
                            </td>

                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{item?.name} </p>
                              <p className={classes.tooltip}>{item?.name}</p>
                            </td>
                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{item?.user?.firstName} {item?.user?.lastName}</p>
                              <p className={classes.tooltip}>{item?.user?.firstName} {item?.user?.lastName}</p>
                            </td>
                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{moment(item?.createdAt).calendar()}</p>
                              <p className={classes.tooltip}>{moment(item?.createdAt).calendar()}</p>
                            </td>
                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{item?.comment}</p>
                              <p className={classes.tooltip}>{item.comment}</p>
                            </td>
                            <td onClick={() => { draw({}); window.open(item.uploadURLs[0]) }} className={classes.tooltip_container}>
                              <p className={classes.item_text}>View Report</p>
                              <p className={classes.tooltip}>View Report</p>
                            </td>

                          </tr>
                        ))}
                      </tbody>


                    </table>

                  </div>




                  <InitiativePaginationComponent
                    page={page} totalCount={reports?.length} setSearchValues={setSearchValues} setInputPage={setInputPage} inputPage={inputPage}
                  />
                </div>
                :
                <InitiativeEmptyState searchValues={searchValues} name={'general report'} />

            }

          </>
      }



    </div>
  )
}

export default Reports;