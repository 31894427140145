import React, { useEffect, useState } from 'react'
import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import axiosInstance from '../../../../services/api';
import CustomInput from '../../../../components/customInput/customInput';
import CustomSelect from '../../../../components/customSelect/customSelect';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import CustomTextArea from '../../../../components/customTextArea/customTextArea';
import ImageUploader from '../../../../components/imageUploader/imageUploader';
import statesLgaJson from '../../../../utils/stateLGAs';
import { useSelector } from 'react-redux';
import { buildResourceURL } from '../../../../utils/helper';

const places = statesLgaJson?.map(i => ({
  title: i?.state,
  value: i?.state,
  listValue: i?.lgas?.map(i => ({
    title: i,
    value: i,
  }))
}))

const CreateStore = ({loading, setLoading, setTriggerClose}) => {
  const [storeName, setStoreName] = useState("");
  const [ownersFName, setOwnersFName] = useState("");
  const [ownersLName, setOwnersLName] = useState("");
  const [storeType, setStoreType] = useState("");
  const [storeLevel, setStoreLevel] = useState("");
  const [phone, setPhone] = useState("");
  const [coordinates, setCoordinates] = useState(null);
  const [category, setCategory] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [state, setState] = useState("");
  const [area, setArea] = useState("");
  const [town, setTown] = useState("");
  const [client, setClient] = useState("");
  const [clients, setClients] = useState([]);
  const [loadingi, setLoadingi] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  // const [productSource, setProductSource] = useState("")

  const [stores, setStores] = useState([]);

  const [team, setTeam] = useState("")
  const [brand, setBrand] = useState("")
  const [additionalInfo, setAdditionalInfo] = useState('')
  const [storePhone, setStorePhone] = useState("")
  const [streetNumber, setStreetNumber] = useState("")
  const [streetName, setStreetName] = useState("")

  const [categories, setCategories] = useState([]);

  // const [purchaseWillingness, setPurchaseWillingness] = useState("")
  // const [buyingPreferences, setBuyingPreferences] = useState([])
  

  const [searchVal, setSearchVal] = useState("");
  const [selected, setSelected] = useState("")

  const currentUser = useSelector(({ userData }) => userData.currentUser);

  const storeUrl = currentUser?.role === "Agency" ? 'agencies/stores' : 'stores'

  const toast = useToast();

  const queryParams = [
    {
        key: 'isDeleted',
        value: false
    },
    {
        key: 'isApproved',
        value: true
    },
    {
        key: 'search',
        value: searchVal
    },
]

const getStores = async() => {
  setLoading(true)
  try {
      const { data } = await axiosInstance.get(buildResourceURL('stores', queryParams))
      setStores(data?.data)
      // setTotal(data?.totalCount)
  } catch(error) {
      console.error(error?.response?.data?.message)
  } finally {
      setLoading(false)
  }
}

useEffect(() => {
  getStores()
}, [searchVal])

  useEffect(() => {
    fetchUserLocation();
  }, []);

  const fetchUserLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({latitude, longitude})
        },
        (error) => {
          console.error('Error fetching user location:', error);
        }
      );
    } else {
      console.error('Geolocation is not available in this browser.');
    }
  };

  const getCategories = async() => {
    setLoading(true)
    console.log('categories');
    try {
        const { data } = await axiosInstance.get(`store-categories`)
        setCategories(data?.data)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
}

useEffect(() => {
    getCategories()
}, [])

const categoriesx = categories?.map(i => ({
  title: i?.name,
  value: i?.name,
  listValue: i?.types?.map(v => ({
    title: v?.name,
    value: v?.name,
    listValue: v?.levels?.map(k => ({
      title: k,
      value: k
    }))
  }))
}))

  const getClients = async() => {
    setLoadingx(true)
    try {
        const { data } = await axiosInstance.get('clients')
        setClients(data?.data)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoadingx(false)
    }
  }

  useEffect(() => {
    getClients()
  }, [])

  const createStore = async(e) => {
    e.preventDefault()
    setLoading(true)
    const datax = client?.length > 0 || currentUser?.role === "Client" ?
    {
      name: storeName,
      phoneNumber: storePhone,
      streetName,
      streetNumber,
      state,
      area,
      town,
      ownerFirstName: ownersFName,
      ownerLastName: ownersLName,
      ownerPhoneNumber: phone,
      type: storeType,
      level: storeLevel,
      coordinates,
      category,
      imageURL,
      team,
      brand,
      additionalInfo,
      clientId: currentUser?.role === "Client" ? currentUser?._id : client
    }
    :
    {
      name: storeName,
      phoneNumber: storePhone,
      streetName,
      streetNumber,
      state,
      area,
      town,
      ownerFirstName: ownersFName,
      ownerLastName: ownersLName,
      ownerPhoneNumber: phone,
      type: storeType,
      level: storeLevel,
      coordinates,
      category,
      imageURL,
      team,
      brand,
      additionalInfo,
    }
    try {
      const {data} = await axiosInstance.post('/stores', datax)
      toast({
        description: data?.message,
        status: "success",
        position: 'top'
      });
      setTriggerClose('close' + (Math.random()))
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
      });
    } finally {
      setLoading(false)
    }
  }

  const createStorex = async(e) => {
    e.preventDefault()
    setLoading(true)
    const datax = {
      storeId: selected,
    }

    try {
      const {data} = await axiosInstance.post(storeUrl, datax)
      toast({
        description: data?.message,
        status: "success",
        position: 'top'
      });
      setTriggerClose('close' + (Math.random()))
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
      });
    } finally {
      setLoading(false)
    }
  }

  const handleImagesUpload = (images) => {
    setImageURL(images[0])
  };

  const clientOptions = clients?.map(i => ({
    title: i?.companyName,
    value: i?._id
  }))

  const teamOptions = [];

  for (let i = 0; i < 100; i++) {
    const newObj = {
      title: `Team ${i + 1}`,
      value: i + 1,
    };
    teamOptions.push(newObj);
  }

  const arr1 = categoriesx?.find(i => i?.value === category)
  const arr2 = arr1?.listValue?.find(i => i?.value === storeType)

  // const sourceOpts = [
  //   {
  //     title: "Open Market",
  //     value: "open market"
  //   },
  //   {
  //     title: "Wholesaler near me",
  //     value: "wholesaler near me"
  //   },
  //   {
  //     title: "Direct from the distributor",
  //     value: "direct from the distributor"
  //   },
  //   {
  //     title: "Company supplies me directly in my outlet",
  //     value: "company supplies me directly in my outlet"
  //   },
  //   {
  //     title: "Not applicable",
  //     value: "not applicable"
  //   },
  // ]


  // const products = [
  //   'Raw Foodstuff',
  //   'Packaged Company Products',
  //   'Fruits & Vegetables',
  //   'Meat & Frozen Food'
  // ]

  // const handleStateChange = (e) => {
  //   const stateName = e.target.value;
  //   const isChecked = e.target.checked;
  //   if (isChecked) {
  //     setBuyingPreferences([...buyingPreferences, stateName])
  //   } else {
  //     // Remove the state from the list
  //     setBuyingPreferences(buyingPreferences?.filter((state) => state !== stateName))
  //     // setFormData({
  //     //   ...formData,
  //     //   states: formData.states.filter((state) => state !== stateName),
  //     // });
  //   }
  // };


  return (
    <>
    {currentUser?.role === "Agency" ?
    <>
    <form>
      <CustomInput
        type={"text"}
        required
        placeholder={"Search Outlet"}
        onChange={e => setSearchVal(e.target.value)}
        // sType={"search"}
      />
    </form>
    <br />
    {searchVal?.length >= 3 &&
    <Box>
      {stores?.slice(0, 5)?.map(({_id: id, name, state, area, isApproved}) => {
        return (
          <Flex key={id} padding={"0"} borderRadius={"16px"} p=".5rem" border={selected === id ? "2px solid #7F56D9" : "2px solid #FFF"} mb=".7rem" cursor={"pointer"} onClick={() => setSelected(id)}>
              <Flex alignItems={"center"} w="100%" justifyContent={"space-between"}>
                  <Flex alignItems={"center"}>
                      {/* <Box height={"50px"} width={"60px"} backgroundImage={profilePicture} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}  /> */}
                      <Box>
                          <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{name}</Text>
                          <Text mb=".2rem" color={"#101828"} fontSize={".85rem"} opacity={".6"} fontWeight={"500"} textTransform={"capitalize"}>{`${area}, ${state}`}</Text>
                      </Box>
                  </Flex>
                  <Text fontSize={"12px"} fontWeight={"500"} color={"#98A2B3"}>{isApproved ? "Verified" : "Unverified"}</Text>
              </Flex>
          </Flex>
        )
      })}
    </Box>}

    <PrimaryButton mt="5rem" type="submit" text={`Add Outlet`} onClick={createStorex} loading={loading} />
    </>
    :
    <form onSubmit={createStore}>
        <CustomInput
        type={"text"}
        required
        placeholder={"Outlet Name"}
        onChange={e => setStoreName(e.target.value)}
        />
        <CustomInput
        type={"tel"}
        required
        placeholder={"Outlet Phone Number"}
        minLength={11}
        maxLength={11}
        onChange={e => setStorePhone(e.target.value)}
        />
        {/* <CustomInput
        type={"text"}
        required
        placeholder={"Address"}
        onChange={e => setAddress(e.target.value)}
        /> */}
        <CustomInput
        type={"text"}
        required
        placeholder={"Street Number"}
        onChange={e => setStreetNumber(e.target.value)}
        />
        <CustomInput
        type={"text"}
        required
        placeholder={"Street Name"}
        onChange={e => setStreetName(e.target.value)}
        />

        <Flex gap={"1rem"}>
          <CustomSelect
          required
          options={places}
          placeholder={"State"}
          onChange={e => setState(e.target.value)}
          />
          <CustomSelect
          required
          options={places?.filter(i => i.value === state)[0]?.listValue}
          placeholder={"Area"}
          onChange={e => setArea(e.target.value)}
          />
        </Flex>

        <Flex gap={"1rem"}>
          <CustomInput
          type={"text"}
          required
          placeholder={"Town"}
          onChange={e => setTown(e.target.value)}
          />
          <CustomSelect
          required
          options={[
            {
              title: "Nigeria",
              value: "nigeria"
            }
          ]}
          placeholder={""}
          />
        </Flex>

        <Flex gap={"1rem"}>
          <CustomInput
          type={"text"}
          required
          placeholder={"Owners First Name"}
          onChange={e => setOwnersFName(e.target.value)}
          />
          <CustomInput
          type={"text"}
          required
          placeholder={"Owners Last Name"}
          onChange={e => setOwnersLName(e.target.value)}
          />
        </Flex>
        
        
        <CustomInput
        type={"tel"}
        required
        placeholder={"Owners Phone Number"}
        minLength={11}
        maxLength={11}
        onChange={e => setPhone(e.target.value)}
        />
        <CustomSelect
        required
        options={categoriesx}
        placeholder={"Outlet category"}
        onChange={e => setCategory(e.target.value)}
        />
        <CustomSelect
        required
        options={categoriesx?.filter(i => i.value === category)[0]?.listValue}
        placeholder={"Type of outlet"}
        onChange={e => setStoreType(e.target.value)}
        />
        <CustomSelect
        required
        options={arr2?.listValue}
        placeholder={"Level of store"}
        onChange={e => setStoreLevel(e.target.value)}
        />

        {/* <FormControl mt="1rem">
          <FormLabel fontSize={".87rem"}>Sales</FormLabel> */}
          <CustomInput
          type={"text"}
          placeholder={"Brand"}
          onChange={e => setBrand(e.target.value)}
          mb="1rem"
          />
         {/* </FormControl> */}

         <CustomTextArea
        rows="2"
        placeholder={"Additional Information"}
        onChange={e => setAdditionalInfo(e.target.value)}
        mb="0"
        />





        {/* <FormControl mt="1rem">
          <FormLabel fontSize={".87rem"}>Where do you buy most of your foodstuff from?</FormLabel>
          <CustomSelect
          required
          options={sourceOpts}
          placeholder={"Select"}
          onChange={e => setProductSource(e.target.value)}
          />
        </FormControl>
        <FormControl mt="1rem">
          <FormLabel fontSize={".87rem"}>Would you buy from us if we brought products to you?</FormLabel>
          <CustomSelect
          required
          options={purchaseOpts}
          placeholder={"Select"}
          onChange={e => setPurchaseWillingness(e.target.value)}
          />
        </FormControl>
        <FormControl mt="1rem">
          <FormLabel fontSize={".87rem"}>Which of these would you buy if we deliver to you? </FormLabel>
          <Flex gap=".5rem" p=".3rem" border=".5px solid lightgray" borderRadius={"8px"} alignItems={"center"} flexWrap={"wrap"}>
            {products?.map((state) => (
              <Flex alignItems={"center"} gap=".2rem" key={state}>
                <input
                  type="checkbox"
                  id={state}
                  value={state}
                  checked={buyingPreferences.includes(state)}
                  onChange={handleStateChange}
                />
                <label style={{whiteSpace: "nowrap"}} htmlFor={state}>{state}</label>
              </Flex>
            ))}
          </Flex>
        </FormControl> */}


        <Flex my="1rem" gap=".5rem">
            {imageURL !== '' && <Box backgroundImage={imageURL} backgroundSize={"cover"} height={"100px"} width={"120px"} borderRadius={"4px"} backgroundColor={"lightgray"} />}
            <ImageUploader
            onImagesUpload={handleImagesUpload}
            setLoading={setLoadingi}
            loading={loadingi}
            max={1}
            />
        </Flex>
        {/* <CustomTextArea
        rows="2"
        placeholder={"Comment (Optional)"}
        onChange={e => setComment(e.target.value)}
        mb="0"
        /> */}
        <CustomSelect
        options={teamOptions}
        placeholder={"Team"}
        onChange={e => setTeam(e.target.value)}
        />
        {currentUser?.role !== "Client" &&
        <CustomSelect
        options={[{title: "Not Applicable", value: ""}, ...clientOptions]}
        placeholder={"Client"}
        onChange={e => setClient(e.target.value)}
        />}
        <PrimaryButton mt="1rem" type="submit" text="Add Outlet" icon="add" func={() => {}} loading={loading} />
    </form>}
    </>
  )
}

export default CreateStore;