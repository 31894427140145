/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { InitiativeQueryComponent } from "../../../../../../../../components/initiativeQueryComponent/initiativeQueryComponent";
import { defaultSearchValueState } from "../../../../../../../../constants/defaultStates";
import classes from "./analytics.module.css";
import { useParams } from "react-router-dom";
import { InitiativeEmptyState } from "../../../../../../../../components/initiativeEmptyState/initiativeEmptyState";
import { Loader } from "../../../../../../../../components/Loader/loader";
import { formatDateHeader } from "../../../../../../../../helpers/formatDateHeader";
import axios from "axios";
import { numberFormat } from "../../../../../../../../helpers/numberFormatter";
import { moneyFormat } from "../../../../../../../../helpers/moneyFormatter";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";

export default function Analytics({ data }) {
  const [searchValues, setSearchValues] = useState(defaultSearchValueState);
  const [loading, setLoading] = useState(false);
  const [apologize, setApologize] = useState(false);
  const [docs, setDocs] = useState(null);

  const { iid, sid } = useParams();

  const endDate = new Date(searchValues.endDate); // Create a new date object based on the current date

  endDate.setDate(endDate.getDate() + 1);

  const getDocs = async () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("PRTK")}`,
      },
    };

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL
        }/periodic-mail/analytics?initiativeId=${sid ? sid : iid}&start=${searchValues.startDate
        }&end=${searchValues?.endDate ? endDate.toISOString().split("T")[0] : ""
        }`,
        config
      );
      setDocs(res?.data?.result);
      console.log(res?.data?.result);
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let timer;

    if (loading) {
      // Start the timer when loading begins
      timer = setTimeout(() => {
        setApologize(true); // Set apologize to true after 10 seconds
      }, 5000); // 10000ms = 10 seconds
    } else {
      // Clear the timer when loading is complete
      setApologize(false);
      clearTimeout(timer);
    }

    // Cleanup: clear timeout when component unmounts or on re-render
    return () => clearTimeout(timer);
  }, [loading]); // Effect runs when `loading` changes

  useEffect(() => {
    getDocs();
  }, [searchValues]);

  const excelData = data
    ? Object.keys(data).map((store) => {
      const storeData = data[store];
      const rowData = { "Store Name": store };
      rowData["Sales (Total)"] = numberFormat.format(
        storeData.sales?.total || 0
      );

      return rowData;
    })
    : {};

  return (
    <div className={classes.screenContainer}>
      <InitiativeQueryComponent
        searchValues={searchValues}
        setSearchValues={setSearchValues}
        newExcel={{ excelData, title: "Analytics-Summary" }}
        noSearch={true}
      />

      {loading ? (
        <div className={classes.loader_con}>
          <Loader />
          {apologize && (
            <p className={classes.apology}>
              Is it taking a longer while to load? It's not your fault, just be
              a bit patient, we are aggregating the data
            </p>
          )}
        </div>
      ) : (
        <>
          {docs ? (
            <div className={classes.table_container}>
              <p className={classes.showing}>
                Showing report for:
                <span style={{ fontWeight: "bold" }}>
                  {formatDateHeader(moment(docs?.start).format('YYYY-MM-DD'))} to{" "}
                  {formatDateHeader(moment(docs?.end).format('YYYY-MM-DD'))}
                </span>

              </p>
              <div className={classes.scrollable}>
                <div className={classes.cardGrouped}>
                  <Card
                    title={"Sales Cases"}
                    value={docs?.byBrandData?.overall?.overallCasesSold || 0}
                    background={"#001a2ac8"}
                    color={"#fff"}
                  />

                  <Card
                    title={"Sales revenues"}
                    value={docs?.byBrandData?.overall?.revenueGenerated || 0}
                  />

                  <Card
                    title={"Sales revenues"}
                    value={docs?.byBrandData?.overall?.revenueGenerated || 0}
                  />
                </div>

                <div className={classes.cardGrouped}>
                  <Card
                    title={"Average Brand MSL score"}
                    value={docs?.overAllMsl || 0}
                  />

                  <Card
                    title={"Productive Outlets"}
                    value={docs?.productiveOutlets || 0}
                  />

                  <Card
                    title={"Store inventory cases"}
                    value={
                      docs?.overallStoreInventories?.totalStoreInventoryCases ||
                      0
                    }
                  />
                </div>
              </div>

              {docs?.groupedRegions?.length > 0 && (
                <RegionsBarChart
                  docs={docs}
                  numberFormat={numberFormat}
                  moneyFormat={moneyFormat}
                />
              )}


              <div className={classes.groupedPerformance}>
                <SKUCasesPerformance
                  array={docs?.byBrandData?.salesByCases?.top3SalesByCases}
                  docs={docs}
                  color={"#001a2ac8"}
                  title={"Top performing SKUs (cases)"}
                />

                <SKUCasesPerformance
                  array={docs?.byBrandData?.salesByCases?.bottom3SalesByCases}
                  docs={docs}
                  color={"#a10000"}
                  title={"Least performing SKUs (cases)"}
                />
              </div>

              <div className={classes.groupedPerformance}>
                <SKUValuesPerformance
                  array={docs?.byBrandData?.salesByValue?.top3SalesByValue}
                  docs={docs}
                  color={"#001a2ac8"}
                  title={"Top performing SKUs (revenues)"}
                />

                <SKUValuesPerformance
                  array={docs?.byBrandData?.salesByValue?.bottom3SalesByValue}
                  docs={docs}
                  color={"#a10000"}
                  title={"Least performing SKUs (revenues)"}
                />
              </div>

              <div className={classes.groupedPerformance}>
                <StoreCasesPerformance
                  array={docs?.byStoreData?.salesByCases?.top3SalesByCases}
                  docs={docs}
                  color={"#001a2ac8"}
                  title={"Top performing stores (cases)"}
                />

                <StoreCasesPerformance
                  array={docs?.byStoreData?.salesByCases?.bottom3SalesByCases}
                  docs={docs}
                  color={"#a10000"}
                  title={"Least performing stores (cases)"}
                />
              </div>

              <div className={classes.groupedPerformance}>
                <StoreValuesPerformance
                  array={docs?.byStoreData?.salesByValue?.top3SalesByValue}
                  docs={docs}
                  color={"#001a2ac8"}
                  title={"Top performing stores (revenues)"}
                />

                <StoreValuesPerformance
                  array={docs?.byStoreData?.salesByValue?.bottom3SalesByValue}
                  docs={docs}
                  color={"#a10000"}
                  title={"Least performing stores (revenues)"}
                />
              </div>

              <div className={classes.groupedPerformance}>
                <SKUMSLSpread
                  array={docs?.mslLevelData?.top3}
                  color={"green"}
                  title={"Most in stock SKU"}
                />

                <SKUMSLSpread
                  array={docs?.mslLevelData?.bottom3}
                  color={"red"}
                  title={"Least in stock SKU"}
                />
              </div>

              <div className={classes.groupedPerformance}>
                <StoresMSLSpread
                  array={docs?.storesMslLevelData?.top3}
                  color={"green"}
                  title={"Stores with the most SKU spread"}
                />

                <StoresMSLSpread
                  array={docs?.storesMslLevelData?.bottom3}
                  color={"red"}
                  title={"Stores with the least SKU spread"}
                />
              </div>
            </div>
          ) : (
            <InitiativeEmptyState
              searchValues={searchValues}
              name={"analytics"}
            />
          )}
        </>
      )}
    </div>
  );
}

function Card({ title, value, background, color }) {
  return (
    <div style={{ background, color }} className={classes.cardContainer}>
      <p>{title}</p>
      <p className={classes.cardValue}>{numberFormat.format(value)}</p>
    </div>
  );
}

function RegionsBarChart({ docs, numberFormat, moneyFormat }) {
  // Prepare data for the chart
  const data = docs?.groupedRegions?.map((regionObj) => {
    const region = Object.keys(regionObj)[0];
    return {
      name: region,
      totalCases: parseFloat(
        (
          (regionObj[region].totalCases * 100) /
          docs?.byBrandData?.overall?.overallCasesSold
        ).toFixed(2)
      ),
      totalValue: parseFloat(
        (
          (regionObj[region].totalValue * 100) /
          docs?.byBrandData?.overall?.revenueGenerated
        ).toFixed(2)
      ),
    };
  });

  return (
    <div className={classes.table}>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <XAxis
            dataKey="name"
            tick={{ fontSize: 12 }}
            tickFormatter={(name) =>
              name.length > 10 ? `${name.slice(0, 10)}...` : name
            }
          />
          <YAxis tickFormatter={(value) => `${value}%`} />
          <Tooltip
            formatter={(value, name) => {
              if (name === "totalCases") {
                return [
                  `${numberFormat.format(
                    (value / 100) * docs?.byBrandData?.overall?.overallCasesSold
                  )} (${value}%)`,
                  "Total Cases",
                ];
              } else if (name === "totalValue") {
                return [
                  `${moneyFormat.format(
                    (value / 100) * docs?.byBrandData?.overall?.revenueGenerated
                  )} (${value}%)`,
                  "Total Value",
                ];
              }
              return value;
            }}
          />
          <Legend />
          <Bar dataKey="totalCases" fill="#8884d8" name="Total Cases" />
          <Bar dataKey="totalValue" fill="#82ca9d" name="Total Value" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

function SKUCasesPerformance({ array, docs, color, title }) {
  return (
    <div className={classes.performanceParentContainer}>
      <p className={classes.performanceContainerTitle}>{title}</p>
      {!array.length && (
        <div
          style={{ textAlign: "center" }}
          className={classes.performanceContainer}
        >
          <p>😅</p>
          <p>Nothing appeared here</p>
        </div>
      )}
      {array?.map((item, index) => (
        <div key={index} className={classes.performanceContainer}>
          <p
            className={classes.performanceTitle}
          >{`${item.brandName}-${item.sku}`}</p>
          <p style={{ background: color }} className={classes.performanceValue}>
            {" "}
            {numberFormat.format(item.totalCase)}
          </p>
          <p className={classes.progress}>
            {(
              (item.totalCase * 100) /
              docs?.byBrandData?.overall?.overallCasesSold
            ).toFixed(2)}
            % of overall sales
          </p>
          <div className={classes.progressContainer}>
            <div
              style={{
                width: `${(item.totalCase * 100) /
                  docs?.byBrandData?.overall?.overallCasesSold
                  }%`,
                height: "100%",
                backgroundColor: color,
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

function SKUValuesPerformance({ array, docs, color, title }) {
  return (
    <div className={classes.performanceParentContainer}>
      <p className={classes.performanceContainerTitle}>{title}</p>
      {array?.map((item, index) => (
        <div key={index} className={classes.performanceContainer}>
          <p
            className={classes.performanceTitle}
          >{`${item.brandName}-${item.sku}`}</p>
          <p style={{ background: color }} className={classes.performanceValue}>
            {" "}
            {moneyFormat.format(item.totalValue)}
          </p>
          <p className={classes.progress}>
            {(
              (item.totalValue * 100) /
              docs?.byBrandData?.overall?.revenueGenerated
            ).toFixed(2)}
            % of overall sales
          </p>
          <div className={classes.progressContainer}>
            <div
              style={{
                width: `${(item.totalValue * 100) /
                  docs?.byBrandData?.overall?.revenueGenerated
                  }%`,
                height: "100%",
                backgroundColor: color,
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

function StoreCasesPerformance({ array, docs, color, title }) {
  return (
    <div className={classes.performanceParentContainer}>
      <p className={classes.performanceContainerTitle}>{title}</p>
      {!array.length && (
        <div
          style={{ textAlign: "center" }}
          className={classes.performanceContainer}
        >
          <p>😅</p>
          <p>Nothing appeared here</p>
        </div>
      )}
      {array?.map((item, index) => (
        <div key={index} className={classes.performanceContainer}>
          <p className={classes.performanceTitle}>
            {item?.initiativeStore?.store?.name}
          </p>
          <p className={classes.progress}>
            {`${item?.initiativeStore?.store?.town}, ${item?.initiativeStore?.store?.area}, ${item?.initiativeStore?.store?.state}`}
          </p>
          <p style={{ background: color }} className={classes.performanceValue}>
            {" "}
            {numberFormat.format(item.totalCase)}
          </p>
          <p className={classes.progress}>
            {(
              (item.totalCase * 100) /
              docs?.byBrandData?.overall?.overallCasesSold
            ).toFixed(2)}
            % of overall sales
          </p>
          <div className={classes.progressContainer}>
            <div
              style={{
                width: `${(item.totalCase * 100) /
                  docs?.byBrandData?.overall?.overallCasesSold
                  }%`,
                height: "100%",
                backgroundColor: color,
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

function StoreValuesPerformance({ array, docs, color, title }) {
  return (
    <div className={classes.performanceParentContainer}>
      <p className={classes.performanceContainerTitle}>{title}</p>
      {array?.map((item, index) => (
        <div key={index} className={classes.performanceContainer}>
          <p className={classes.performanceTitle}>
            {item?.initiativeStore?.store?.name}
          </p>
          <p className={classes.progress}>
            {`${item?.initiativeStore?.store?.town}, ${item?.initiativeStore?.store?.area}, ${item?.initiativeStore?.store?.state}`}
          </p>
          <p style={{ background: color }} className={classes.performanceValue}>
            {" "}
            {moneyFormat.format(item.totalValue)}
          </p>
          <p className={classes.progress}>
            {(
              (item.totalValue * 100) /
              docs?.byBrandData?.overall?.revenueGenerated
            ).toFixed(2)}
            % of overall sales
          </p>
          <div className={classes.progressContainer}>
            <div
              style={{
                width: `${(item.totalValue * 100) /
                  docs?.byBrandData?.overall?.revenueGenerated
                  }%`,
                height: "100%",
                backgroundColor: color,
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

function SKUMSLSpread({ array, color, title }) {
  return (
    <div className={classes.performanceParentContainer}>
      <p className={classes.performanceContainerTitle}>{title}</p>
      {array?.map((item, index) => (
        <div key={index} className={classes.performanceContainer}>
          <p style={{ textAlign: "center" }} className={classes.progress}>
            {item.brand} - {item.sku}
          </p>
          <p style={{ color, fontStyle: "italic", textAlign: "center" }}>
            Available {item.percentageAvailability.toFixed(2)}% of the time
          </p>
        </div>
      ))}
    </div>
  );
}

function StoresMSLSpread({ array, color, title }) {
  return (
    <div className={classes.performanceParentContainer}>
      <p className={classes.performanceContainerTitle}>{title}</p>
      {array?.map((item, index) => (
        <div key={index} className={classes.performanceContainer}>
          <p
            style={{ textAlign: "center" }}
            className={classes.performanceTitle}
          >
            {item?.storeName}
          </p>
          <p style={{ color, fontStyle: "italic", textAlign: "center" }}>
            Available {item.percentageAvailability.toFixed(2)}% of the time
          </p>
        </div>
      ))}
    </div>
  );
}
