import { Box, Divider, Flex, Menu, MenuButton, MenuItem, MenuList, Switch, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
import { buildResourceURL } from '../../../utils/helper'
import axiosInstance from '../../../services/api'
import CustomInput from '../../../components/customInput/customInput'
import CustomDrawer from '../../../components/customDrawer/customDrawer'
import SecondaryButton from '../../../components/secondaryButton/secondarybutton'
import EmptyState from '../../../components/emptyState/emptyState'
import LineSkeleton from '../../../components/lineSkeleton'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import PrimaryButton from '../../../components/primaryButton/primarybutton'
import CustomSelect from '../../../components/customSelect/customSelect'
import ExportCSV from '../../../components/exportCsv'
import ServerExport from '../../../components/serverExport/serverExport'
import EditAdmin from './editAmin/editAdmin'


const Admins = ({title}) => {
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingc, setLoadingc] = useState(false);
    const [loadingx, setLoadingx] = useState(false);
    const [triggerClose, setTriggerClose] = useState("");
    const [modalType, setModalType] = useState("");
    const [selectedUser, setSelectedUser] = useState(null)
    const [refetch, setRefetch] = useState("");
    const [binView, setBinView] = useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [status, setStatus] = useState("all");

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [superx, setSuperx] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast()

    const queryParams = [
        // {
        //     key: 'isDeleted',
        //     value: binView === false ? "" : binView
        // },
        // {
        //     key: 'role',
        //     value: "Admin"
        // },
        {
            key: 'search',
            value: searchVal
        },
        // {
        //     key: 'isApproved',
        //     value: status === "all" ? "" : status
        // }
    ]

    const getEmployees = async() => {
        setLoading(true)
        try {
            const { data } = await axiosInstance.get(buildResourceURL('admins', queryParams))
            setEmployees(data?.data)
        } catch(error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getEmployees()
    }, [triggerClose, refetch, searchVal, status])

    const handleModal = (type, user) => {
        setModalType(type)
        setSelectedUser(user)
        onOpen()
    }

    const createEmployee = async(e) => {
        e.preventDefault()
        setLoading(true)
        const datax = {
          firstName,
          lastName,
          email,
          password,
          makeSuperAdmin: superx,
          phoneNumber: phoneNumber.charAt(0) === "0"
          ? "+234" + phoneNumber.slice(1)
          : "+234" + phoneNumber,
        }
        try {
          const {data} = await axiosInstance.post('/admins', datax)
          toast({
            description: data?.message,
            status: "success",
            position: "top"
          });
          setTriggerClose('close' + (Math.random()))
        } catch(error) {
          toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top"
          });
        } finally {
          setLoading(false)
        }
      }

    const approveEmployee = async() => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.patch(`employees/${selectedUser?.id}/approve`)
            toast({
            description: data?.message,
            status: "success",
            position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch(error) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }

    const suspendEmployee = async() => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.delete(`employees/${selectedUser?.id}/soft`)
            toast({
            description: data?.message,
            status: "success",
            position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch(error) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }

    const restoreEmployee = async() => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.patch(`employees/${selectedUser?.id}/restore`)
            toast({
            description: data?.message,
            status: "success",
            position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch(error) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }

    const deleteEmployee = async() => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.delete(`admins/${selectedUser?.id}`)
            toast({
            description: data?.message,
            status: "success",
            position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch(error) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }

    const handleViewBin = () => {
        setBinView(!binView)
        setRefetch(Math.random())
    }

    const headers = [
        {
          key: '_id',
          name: 'ID',
        },
        {
          key: 'firstName',
          name: 'First Name',
        },
        {
          key: 'lastName',
          name: 'Last Name',
        },
        {
          key: 'email',
          name: 'Email',
        },
        {
          key: 'phoneNumber',
          name: 'Phone Number',
        },
        {
          key: 'role',
          name: 'Role',
        },
        {
          key: 'isDefaultAdmin',
          name: "Default Admin",
        },
        {
          key: 'createdAt',
          name: 'Joined',
        },
      ]

  return (
    <Box bg="#FFF" mt=".7rem" p="1.5rem 1rem" borderRadius={"12px"}>
        
        <Flex alignItems={"center"} gap=".8rem" justifyContent={"space-between"}>
            
            <Flex gap="1.5rem" alignItems={"center"}>
            <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>Admins <span style={{padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054"}}>{employees?.length}</span></Text>
                {/* <CustomInput
                type={"text"}
                required
                value={searchVal}
                placeholder={`Search admins..`}
                onChange={e => setSearchVal(e.target.value)}
                mt="0"
                sType="search"
                py="1.19rem"
                width="350px"
                /> */}
            </Flex>

            <Flex alignItems={"center"} gap="1rem">
            
            {/* <ExportCSV headers={headers} items={employees} /> */}
            <ServerExport entity={"admins"} />

            <CustomDrawer
            position={"right"}
            title={`Add Admin`}
            triggerClose={triggerClose}
            setTriggerClose={setTriggerClose}
            toggleElement={
                <SecondaryButton type="submit" text={`Add admin`} icon="add" loading={false}  />
            }
            content={
                <>
                    <form onSubmit={createEmployee}>
                        <CustomInput
                        type={"text"}
                        required
                        placeholder={"First Name"}
                        onChange={e => setFirstName(e.target.value)}
                        />
                        <CustomInput
                        type={"text"}
                        required
                        placeholder={"Last Name"}
                        onChange={e => setLastName(e.target.value)}
                        />
                        <CustomInput
                        type={"email"}
                        required
                        placeholder={"Email Address"}
                        onChange={e => setEmail(e.target.value)}
                        />
                        <CustomInput
                        type={"tel"}
                        minLength={11}
                        maxLength={11}
                        required
                        placeholder={"Phone number"}
                        onChange={e => setPhoneNumber(e.target.value)}
                        />
                        <CustomInput
                        type={"password"}
                        required
                        placeholder={"Password"}
                        mb={".8rem"}
                        onChange={e => setPassword(e.target.value)}
                        />
                        <Flex gap=".6rem" mt=".8rem">
                            <Text fontSize={".9rem"} fontWeight={"500"} opacity={.7}>Make Super Admin ?</Text>
                            <Switch size={"md"} onChange={e => setSuperx(e.target.checked)} />
                        </Flex>
                        
                        <PrimaryButton mt="3rem" type="submit" text={`Add admin`} func={() => {}} loading={loading} />
                    </form>
                </>
            }
            />

            </Flex>

        </Flex>
        <Table mt="1.2rem" borderRadius={"12px"} overflow={"hidden"}>
            <Thead bg="#F9FAFB" border="1px solid #EAECF0">
                <Tr>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Name</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Email</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Phone</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Default Admin?</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Role</Th>
                    <Th></Th>
                </Tr>
            </Thead>
            
            {loading ? 
            <LineSkeleton width="100%" num={10} />
            :
            employees?.length < 1 ?
            <EmptyState mt="1.5rem" text="There are no employees yet" />
            :
            <Tbody>
            
            {employees?.map((({_id: id, firstName, lastName, email, profilePicture, isApproved, isDefaultAdmin, phoneNumber, createdAt, role}) => {
                const data = {id, firstName, lastName, email, profilePicture, isApproved, isDefaultAdmin, phoneNumber, createdAt, role}
                return (
                    <Tr key={id}>
                        <Td>
                        <Flex alignItems={"center"} gap=".4rem">
                            <Box height={"40px"} width={"40px"} backgroundImage={profilePicture} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}  />
                            <Box>
                                <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{firstName + " " + lastName}</Text>
                            </Box>
                        </Flex>
                        </Td>
                        <Td color={"#475467"} fontSize={".9rem"}>{email}</Td>
                        <Td color={"#475467"} fontWeight={"500"} fontSize={".85rem"}>{phoneNumber}</Td>
                        <Td fontWeight={"500"} fontSize={".85rem"}>{isDefaultAdmin ? "Yes" : "No"}</Td>
                        <Td>
                            <Text textAlign={"center"} fontSize={"12px"} fontWeight={"500"} p=".1rem" borderRadius={"16px"} border={"1px solid lightgray"} color={"#475467"} bg={"#F9FAFB"}>{role}</Text>
                        </Td>
                        <Td>
                            
                            <Menu width={"200px !important"}>
                                <MenuButton>
                                    <BiDotsVerticalRounded style={{cursor: "pointer"}} />
                                </MenuButton>
                                <MenuList>
                                    <Divider my=".2rem" />
                                    <CustomDrawer 
                                    position={"right"}
                                    title={'Update details'}
                                    triggerClose={triggerClose}
                                    toggleElement={
                                        <MenuItem fontWeight={"500"} fontSize={".85rem"}>Edit</MenuItem>
                                    }
                                    content={
                                        <EditAdmin setTriggerClose={setTriggerClose} data={data} />
                                    }
                                    />
                                    <Divider my=".2rem" />
                                    <MenuItem onClick={() => handleModal('delete', {firstName, lastName, id: id})} color={"crimson"} fontWeight={"500"} fontSize={".85rem"}>Delete {title?.toLowerCase()}</MenuItem>
                                    <Divider my=".2rem" />
                                </MenuList>
                            </Menu>
                        </Td>
                    </Tr>
                )
            }))
            }
            </Tbody>}
        </Table>

        <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{`${modalType} Admin`}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {modalType === 'approve' &&
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm approval of {selectedUser?.firstName + " " + selectedUser?.lastName}?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                            <PrimaryButton onClick={approveEmployee} text="Approve" width="150px" padding="1rem !important" loading={loadingx} />
                        </Flex>
                    </>
                    }
                    {modalType === 'suspend' &&
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm suspension of {selectedUser?.firstName + " " + selectedUser?.lastName}?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                            <PrimaryButton onClick={suspendEmployee} text="Suspend" width="150px" padding="1rem !important" loading={loadingx} />
                        </Flex>
                    </>
                    }
                    {modalType === 'restore' &&
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm restore of {selectedUser?.firstName + " " + selectedUser?.lastName}?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                            <PrimaryButton onClick={restoreEmployee} text="Restore" width="150px" padding="1rem !important" loading={loadingx} />
                        </Flex>
                    </>
                    }
                    {modalType === 'delete' &&
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm deletion of {selectedUser?.firstName + " " + selectedUser?.lastName}?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                            <PrimaryButton onClick={deleteEmployee} text="Delete" width="150px" bg="crimson !important" padding="1rem !important" loading={loadingx} />
                        </Flex>
                    </>
                    }
                    <Text pb="1rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Cancel</Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    </Box>
  )
}

export default Admins;