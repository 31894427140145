import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axiosInstance from '../../../../services/api'
import LineSkeleton from '../../../../components/lineSkeleton'
import EmptyState from '../../../../components/emptyState/emptyState'
import CustomDrawer from '../../../../components/customDrawer/customDrawer'
import SecondaryButton from '../../../../components/secondaryButton/secondarybutton'
import CreateStore from '../../stores/createStore/createStore'
import { useSelector } from 'react-redux'
import classes from '../home.module.css'

const StoresView = ({setTotalStores}) => {
  const [stores, setStores] = useState([]);
  const [totalStoresx, setTotalStoresx] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingc, setLoadingc] = useState(false);
  const [triggerClose, setTriggerClose] = useState("")

  const navigate = useNavigate()

  const currentUser = useSelector(({ userData }) => userData.currentUser);

  const storeUrl = currentUser?.role === "Agency" ? 'agencies/stores' : 'stores'

  const getStores = async() => {
    try {
        const { data } = await axiosInstance.get(`${storeUrl}?limit=5`)
        setStores(data?.data)
        setTotalStores(data?.totalCount)
        setTotalStoresx(data?.totalCount)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
}

useEffect(() => {
    getStores()
}, [triggerClose])

  return (
    <Box boxShadow="0px 1px 2px 0px #1018280D" flex={["1 0 300px", "1 1 400px"]} bg="#FFF" border=".5px solid #F2F4F7" p="1.3rem" borderRadius={"12px"}>
        <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>Outlets <span style={{padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054"}}>{totalStoresx}</span></Text>
            <Flex alignItems={"center"} gap=".8rem">
                <CustomDrawer
                position={"right"}
                title={'Add Store'}
                triggerClose={triggerClose}
                setTriggerClose={setTriggerClose}
                toggleElement={
                    <button className={classes.createButton}>Add Outlet</button>
                }
                content={
                    <CreateStore loading={loadingc} setTriggerClose={setTriggerClose} setLoading={setLoadingc} />
                }
                />
            </Flex>
        </Flex>
        <Divider my="1.5rem" />
        <Box height={"267.41px"} overflow={"scroll"}
        sx={
            { 
            '::-webkit-scrollbar':{
                display:'none'
            }
            }
        }>
            {loading ? 
            <LineSkeleton num={6} />
            :
            stores?.length < 1 ?
            <EmptyState mt="1.5rem" text="There are no employees yet" />
            :
            stores?.map((({id, name, state, area, isApproved}) => {
                return (
                    <Flex key={id} padding={"0 .7rem"} borderRadius={"16px"} mb="1.7rem">
                        <Flex alignItems={"center"} w="100%" justifyContent={"space-between"}>
                            <Flex alignItems={"center"}>
                                {/* <Box height={"50px"} width={"60px"} backgroundImage={profilePicture} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}  /> */}
                                <Box>
                                    <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{name}</Text>
                                    <Text mb=".2rem" color={"#101828"} fontSize={".85rem"} opacity={".6"} fontWeight={"500"} textTransform={"capitalize"}>{`${area}, ${state}`}</Text>
                                </Box>
                            </Flex>
                            <Text fontSize={"12px"} fontWeight={"500"} color={"#98A2B3"}>{isApproved ? "Verified" : "Unverified"}</Text>
                        </Flex>
                    </Flex>
                )
            }))
            }
        </Box>
        <br />

        <Text onClick={() => navigate('/stores')} color={"#ff6f61"} fontSize={".9rem"} fontWeight={"600"} cursor={"pointer"}>See more</Text>
    </Box>
  )
}

export default StoresView;