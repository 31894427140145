import React, { useState } from 'react'
import axiosInstance from '../../../../../../services/api';
import { useSelector } from 'react-redux';
import { Box, Divider, Flex, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import ExportCSV from '../../../../../../components/exportCsv';
import CustomDrawer from '../../../../../../components/customDrawer/customDrawer';
import EmptyState from '../../../../../../components/emptyState/emptyState';
import { truncateText } from '../../../../../../utils/helper';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import PrimaryButton from '../../../../../../components/primaryButton/primarybutton';
import UpdateGroup from './updateGroup';
import classes from '../../../../initiatives/createInitiative/components/assignStore/store.module.css'

const StoreGroups = ({ initiative, setRefetch, allStores }) => {
    const [storeGroups, setStoreGroups] = useState(initiative?.initiativeStoresFilterGroups ?? []);
    const [loadingx, setLoadingx] = useState(false);
    const [modalType, setModalType] = useState("");
    const [selectedUser, setSelectedUser] = useState(null)

    const [triggerClose, setTriggerClose] = useState("");
    const currentUser = useSelector(({ userData }) => userData?.currentUser);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast()


    const handleModal = (type, user) => {
        setModalType(type)
        setSelectedUser(user)
        onOpen()
    }

    const deleteGroup = async () => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.delete(`/initiatives/${initiative?._id}/initiative-stores-filter-groups/${selectedUser?.id}`)
            toast({
                description: data?.message,
                status: "success",
                position: "top"
            });
            onClose()
            setRefetch(Math.random())
        } catch (error) {
            if (error?.response?.data?.message) {
                toast({
                    title: "An Error Occurred",
                    description: error?.response?.data?.message,
                    status: "error",
                    position: "top"
                })
            }
        } finally {
            setLoadingx(false)
        }
    }

    const headers = [
        {
            key: '_id',
            name: 'ID',
        },
        {
            key: 'name',
            name: 'Name',
        },
    ]





    return (
        <div className={classes.screenContainer}>
            <Flex mb="1rem" justify={"flex-end"}>
            </Flex>

            <Flex alignItems={"center"} gap=".8rem" justifyContent={"space-between"}>
                <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>Store Groups <span style={{ padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054" }}>{storeGroups?.length}</span></Text>

                <Flex alignItems={"center"} gap="1rem">
                    <ExportCSV headers={headers} items={initiative?.initiativeStoresFilterGroups} />
                </Flex>

            </Flex>
            <Box p="0 1rem" bg="#FFF" mt="1rem" height={"84vh"} overflowY={"scroll"} borderRadius={"12px"}>

                <Table mt="1.2rem" overflow={"hidden"}>
                    <Thead bg="#001a2ac8"  border="1px solid #EAECF0">
                        <Tr>
                            <Th textTransform={"capitalize !important"} color={"#fff"}>ID</Th>
                            <Th textTransform={"capitalize !important"} color={"#fff"}>Name</Th>
                            <Th textTransform={"capitalize !important"} color={"#fff"}>Store IDs</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>

                    {storeGroups?.length < 1 ?
                        <EmptyState mt="1.5rem" text="There are no store groups created yet" />
                        :
                        <Tbody>

                            {storeGroups?.map((({ _id: id, name, initiativeStoresIDs }) => {
                                const data = { _id: id, name, initiativeStoresIDs }
                                return (
                                    <Tr key={id}>
                                        <Td color={"#475467"} fontSize={".9rem"}>{truncateText(id, 10)}</Td>
                                        <Td>
                                            <Flex alignItems={"center"} gap=".4rem">
                                                <Box>
                                                    <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{name}</Text>
                                                </Box>
                                            </Flex>
                                        </Td>
                                        <Td color={"#475467"} fontSize={".9rem"}>{initiativeStoresIDs?.slice(0, 3)?.map(i =>
                                            <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"}>{i}</Text>
                                        )}...<strong>{initiativeStoresIDs?.length}</strong> stores</Td>
                                        <Td>
                                            <Menu width={"200px !important"}>
                                                <MenuButton>
                                                    <BiDotsVerticalRounded style={{ cursor: "pointer" }} />
                                                </MenuButton>
                                                <MenuList>
                                                    <Divider my=".2rem" />
                                                    {/* {currentUser?.role === "Super Admin" && */}
                                                    <>
                                                        <CustomDrawer
                                                            position={"right"}
                                                            title={"Update"}
                                                            triggerClose={triggerClose}
                                                            toggleElement={
                                                                <MenuItem fontWeight={"500"} fontSize={".85rem"}>Edit</MenuItem>
                                                            }
                                                            content={
                                                                <UpdateGroup iid={initiative?._id} setTriggerClose={setTriggerClose} setRefetch={setRefetch} groupData={data} allStores={allStores} />
                                                            }
                                                        />
                                                        <Divider my=".2rem" />
                                                        <MenuItem onClick={() => handleModal('delete', { name, id })} color={"crimson"} fontWeight={"500"} fontSize={".85rem"}>Delete</MenuItem>
                                                        <Divider my=".2rem" />
                                                    </>
                                                    {/* } */}
                                                </MenuList>
                                            </Menu>
                                        </Td>
                                    </Tr>
                                )
                            }))
                            }
                        </Tbody>}
                </Table>

                <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{`${modalType} Group`}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {modalType === 'delete' &&
                                <>
                                    <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm deletion of {selectedUser?.name}?</Text>
                                    <Flex justifyContent={"center"} my="1.3rem">
                                        <PrimaryButton onClick={deleteGroup} text="Delete" width="150px" bg="crimson !important" padding="1rem !important" loading={loadingx} />
                                    </Flex>
                                </>
                            }
                            <Text pb="1rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Cancel</Text>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </Box>
        </div>
    )
}

export default StoreGroups