import { Box, Divider, Flex, Heading, Text, useToast } from '@chakra-ui/react';
import React, { useState } from 'react'
import CustomDrawer from '../../../../../components/customDrawer/customDrawer';
import CustomInput from '../../../../../components/customInput/customInput';
import CustomTextArea from '../../../../../components/customTextArea/customTextArea';
import axiosInstance from '../../../../../services/api';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../../../../components/primaryButton/primarybutton';
import { EditIcon } from '@chakra-ui/icons';
import ImageUploader from '../../../../../components/imageUploader/imageUploader';
import { useSelector } from 'react-redux';
import { AiOutlinePlus } from 'react-icons/ai';
import ShelfPosm from '../../createInitiative/components/shelfPosm/shelfPosm';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import DrawQuestions from './drawQuestions/drawQuestions';
import CustomTabs from '../../../../../components/customTabs/customTabs';
import SecondaryButton from '../../../../../components/secondaryButton/secondarybutton';
import Prizes from './prizes/prizes';
import ExportCSV from '../../../../../components/exportCsv';
import classes from './mechanics.module.css'
import { CiSearch } from 'react-icons/ci';

const Mechanics = ({ data, merged, setRefetch }) => {

  const [triggerClose, setTriggerClose] = useState("");
  const [loadingx, setLoadingx] = useState(false);
  const [loadingi, setLoadingi] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [tags, setTags] = useState([])

  const [promoName, setPromoName] = useState(data?.games?.find(x => x.name.includes('spin'))?.title);
  const [promoName2, setPromoName2] = useState(data?.games?.find(x => x.name.includes('true'))?.title);

  const [idx, setIdx] = useState("")
  const [namex, setNamex] = useState("");
  const [skux, setSkux] = useState("");
  const [unitx, setUnitx] = useState("");
  const [durationx, setDurationx] = useState("");
  const [caseUnitsNumberx, setCaseUnitsNumberx] = useState("");
  const [pricePerCasex, setPricePerCasex] = useState("");
  const [imagex, setImagex] = useState("");
  const [imagex1, setImagex1] = useState("");
  const [imagey, setImagey] = useState(data?.games?.find(x => x.name.includes('spin'))?.imageURL);
  const [imagez, setImagez] = useState(data?.games?.find(x => x.name.includes('true'))?.imageURL);


  const toast = useToast();
  const navigate = useNavigate()
  const currentUser = useSelector(({ userData }) => userData?.currentUser)

  const [formValues, setFormValues] = useState({
    name: data?.name,
    description: data?.description,
    instructions: data?.instructions,
    imageURL: imagex1 || data?.imageURL
  });

  const handleImagesUpload = (images) => {
    setImagex(images[0])
  };

  const handleImagesUploady = (images) => {
    setImagey(images[0])
  };

  const handleImagesUploadz = (images) => {
    setImagez(images[0])
  };

  const handleImagesUploadx = (images) => {
    setFormValues({ ...formValues, imageURL: images[0] });
    // setImagex1(images[0])
    // console.log(images[0])
  };

  const [brandValues, setBrandValues] = useState({
    name: selectedBrand?.name,
    sku: selectedBrand?.sku,
    target: '',
    durationInDays: '',
    image: '',
    caseUnitsNumber: '',

  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    if (name === 'SKUs') {
      setBrandValues({ ...brandValues, [name]: value?.split(',')?.map(i => i?.trim()) });
    } else if (name === 'target' || name === 'durationInDays') {
      setBrandValues({ ...brandValues, [name]: value ? parseInt(value) : "" });
    } else {
      setBrandValues({ ...brandValues, [name]: value });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoadingx(true)
    try {
      const res = await axiosInstance.patch(`initiatives/${data?._id}`, formValues)
      toast({
        description: res?.data?.message,
        status: "success",
        position: "top"
      });
      setTriggerClose('close' + (Math.random()))
      setRefetch(Math.random())
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
      });
    } finally {
      setLoadingx(false)
    }
  }

  const handleEditBrand = (i) => {
    setIdx(i?._id)
    setNamex(i?.name)
    setSkux(i?.sku)
    setUnitx(i?.target)
    setDurationx(i?.durationInDays)
    setImagex(i?.image)
    setPricePerCasex(i?.pricePerCase)
    setCaseUnitsNumberx(i?.caseUnitsNumber)
  }

  const handleBrandUpdate = async (e) => {
    e.preventDefault();

    if (namex?.includes('-')) {
      toast({
        title: "Invalid Input",
        description: "Brand name cannot contain the character '-'",
        status: "info",
        position: "top"
      });
    } else {
      setLoadingx(true)
      const datax = {
        name: namex,
        sku: skux,
        target: parseInt(unitx),
        durationInDays: parseInt(durationx),
        image: imagex,
        pricePerCase: parseInt(pricePerCasex),
        caseUnitsNumber: parseInt(caseUnitsNumberx)
      }
      try {
        const res = await axiosInstance.patch(`initiatives/${data?._id}/brands/${idx}`, datax)
        toast({
          description: res?.data?.message,
          status: "success",
          position: "top"
        });
        setTriggerClose('close' + (Math.random()))
        setRefetch(Math.random())
      } catch (error) {
        toast({
          title: "An Error Occurred",
          description: error?.response?.data?.message,
          status: "error",
          position: "top"
        });
      } finally {
        setLoadingx(false)
      }
    }
  }

  const handleBrandCreate = async (e) => {
    e.preventDefault();

    if (namex?.includes('-')) {
      toast({
        title: "Invalid Input",
        description: "Brand name cannot contain the character '-'",
        status: "info",
        position: "top"
      });
    } else {
      setLoadingx(true)
      const datax = {
        name: namex,
        sku: skux,
        target: parseInt(unitx),
        durationInDays: parseInt(durationx),
        image: imagex,
        pricePerCase: parseInt(pricePerCasex),
        caseUnitsNumber: parseInt(caseUnitsNumberx)
      }
      try {
        const res = await axiosInstance.post(`initiatives/${data?._id}/brands/`, datax)
        toast({
          description: res?.data?.message,
          status: "success",
          position: "top"
        });
        setTriggerClose('close' + (Math.random()))
        setRefetch(Math.random())
        setImagex('')
      } catch (error) {
        toast({
          title: "An Error Occurred",
          description: error?.response?.data?.message,
          status: "error",
          position: "top"
        });
      } finally {
        setLoadingx(false)
      }
    }

  }

  const deleteBrand = async () => {
    setLoadingx(true)
    try {
      const res = await axiosInstance.delete(`initiatives/${data?._id}/brands/${idx}`)
      toast({
        description: res?.data?.message,
        status: "success",
        position: "top"
      });
      setTriggerClose('close' + (Math.random()))
      setRefetch(Math.random())
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
      });
    } finally {
      setLoadingx(false)
    }
  }

  const deleteGame = async (gid) => {
    setLoadingx(true)
    try {
      const res = await axiosInstance.delete(`initiatives/${data?._id}/games/${gid}`)
      toast({
        description: res?.data?.message,
        status: "success",
        position: "top"
      });
      setTriggerClose('close' + (Math.random()))
      setRefetch(Math.random())
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
      });
    } finally {
      setLoadingx(false)
    }
  }



  const handleGameUpdate = async(gid) => {
    setLoadingx(true)
    const myObj = {}

    const datax = {
      title: promoName || promoName2,
      imageURL: imagey,
    }

    for (const key in datax) {
      const value = datax[key];
      if (value !== "" && value !== undefined && value !== null) {
        myObj[key] = value;
      }
    }
    try {
      const res = await axiosInstance.patch(`initiatives/${data?._id}/games/${gid}`, myObj)
      toast({
        description: res?.data?.message,
        status: "success",
        position: "top"
      });
      setTriggerClose('close' + (Math.random()))
      setRefetch(Math.random())
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
      });
    } finally {
      setLoadingx(false)
    }
  }



  const tabData = data ? data?.games?.map(i => (
    {
      title: i?.name,
      render: i?.name === "true or false" ? 
      <>
        <Flex mb="1rem" justifyContent={"flex-end"}>
          <Text onClick={() => deleteGame(i?._id)} cursor={"pointer"} fontSize={".9rem"} color={"#D92D20"} fontWeight={"500"}>Delete Game</Text>
        </Flex>
        <Box>
          <CustomInput
          type={"text"}
          name="name"
          placeholder={"Promo name"}
          onChange={e => setPromoName2(e.target.value)}
          value={promoName2}
          />
        </Box>
        <Flex mt="1rem" gap=".5rem">
            {imagez !== '' && <Box backgroundImage={imagez} backgroundSize={"cover"} backgroundPosition={'center'} height={"100px"} width={"120px"} borderRadius={"4px"} backgroundColor={"lightgray"} />}
            <ImageUploader
            onImagesUpload={handleImagesUploadz}
            setLoading={setLoadingi}
            loading={loadingi}
            max={1}
            />
        </Flex>
        <PrimaryButton text="Update" onClick={() => handleGameUpdate(i?._id)} loading={loadingx} mt="1.5rem" />
        <Divider my="1rem" />
        <DrawQuestions data={data} />
      </>
      :
      <>
        <Flex mb="1rem" justifyContent={"flex-end"}>
          <Text onClick={() => deleteGame(i?._id)} cursor={"pointer"} fontSize={".9rem"} color={"#D92D20"} fontWeight={"500"}>Delete Game</Text>
        </Flex>
        <Box>
          <CustomInput
          type={"text"}
          name="name"
          placeholder={"Promo name"}
          onChange={e => setPromoName(e.target.value)}
          value={promoName}
          />
        </Box>
        <Flex mt="1rem" gap=".5rem">
            {imagey !== '' && <Box backgroundImage={imagey} backgroundSize={"cover"} backgroundPosition={'center'} height={"100px"} width={"120px"} borderRadius={"4px"} backgroundColor={"lightgray"} />}
            <ImageUploader
            onImagesUpload={handleImagesUploady}
            setLoading={setLoadingi}
            loading={loadingi}
            max={1}
            />
        </Flex>
        <PrimaryButton text="Update" onClick={() => handleGameUpdate(i?._id)} loading={loadingx} mt="1.5rem" />
      </>
    }
  )) : []

  const finalTabData = [
    ...tabData,
    {
      title: "Prizes",
      render: <Prizes triggerClose={triggerClose} setTriggerClose={setTriggerClose} setRefetch={setRefetch} datum={data} />
    }
  ]

  const headers = [
    {
      key: 'name',
      name: 'Brand',
    },
    {
      key: 'sku',
      name: 'SKU',
    },
    {
      key: 'target',
      name: 'Target',
    },
    {
      key: 'durationInDays',
      name: 'Duration In Days',
    },
    {
      key: 'caseUnitsNumber',
      name: 'Case Units Number',
    },
    {
      key: 'pricePerCase',
      name: 'Price Per Case',
    },
    {
      key: 'image',
      name: 'Image',
    },
  ]

  const { brands } = data || []

  if (brands.length > 0) {
    brands.sort((a, b) => {
      const brandComparison = a.name.localeCompare(b.name);
      if (brandComparison !== 0) return brandComparison;
      return a.sku - b.sku;
    });
  }


  return (
    <div className={classes.container}>
      <div alignItems={"center"} justifyContent={"space-between"}>
        <div className={classes.detailsSection}>
          <img src={data?.imageURL} alt={`${data?.name}'s logo`} className={classes.img} />

          <div className={classes.nameAndDescriptionContainer}>
            <p className={classes.name}>{data?.name}</p>
            <p className={classes.description}>{data?.description}</p>
          </div>

          <div className={classes.editInitiativeDetailsButton}>
            <CustomDrawer
              position={"right"}
              title={"Edit Initiative Details"}
              triggerClose={triggerClose}
              setTriggerClose={setTriggerClose}
              toggleElement={
                <p className={classes.editInitiativeText}> edit</p>
              }
              content={
                <>
                  <form onSubmit={handleUpdate}>
                    <CustomInput
                      type={"text"}
                      required
                      name="name"
                      placeholder={"Initiative Name"}
                      onChange={e => handleInputChange(e)}
                      value={formValues.name}
                    />
                    <CustomTextArea
                      required
                      rows={"3"}
                      name="description"
                      placeholder={"Description"}
                      onChange={e => handleInputChange(e)}
                      value={formValues.description}
                    />
                    <CustomTextArea
                      required
                      rows={"3"}
                      name="instructions"
                      placeholder={"Instructions"}
                      onChange={e => handleInputChange(e)}
                      value={formValues.instructions}
                    />
                    <Text color={""} mt=".8rem" mb=".5rem" fontWeight={"400"}>Initiative image</Text>
                    <Flex gap=".5rem">
                      {(formValues?.imageURL !== '' || formValues?.imageURL !== undefined) && <Box backgroundImage={formValues?.imageURL} backgroundSize={"cover"} height={"100px"} width={"120px"} borderRadius={"4px"} backgroundColor={"lightgray"} />}
                      <ImageUploader
                        onImagesUpload={handleImagesUploadx}
                        setLoading={setLoading1}
                        loading={loading1}
                        max={1}
                      />
                    </Flex>
                    <PrimaryButton type="submit" mt="2rem" loading={loadingx} text="Update Details" />
                  </form>
                </>
              }
            />
          </div>

        </div>
      </div>

      <div className={classes.searchAndExport}>
        <div className={classes.searchContainer}>
          <CiSearch className={classes.searchIcon} />
          <input type='text' placeholder='search' className={classes.input} />
        </div>
        <ExportCSV headers={headers} items={data?.brands} />
      </div>




      <p className={classes.title}>Total SKUs Found: {data?.brands?.length}</p>

      {((currentUser?.role !== "Super Admin" && data?.status === "Pending") || (currentUser?.role === "Super Admin" && data?.status !== "Completed")) &&
        <CustomDrawer
          position={"right"}
          title={"Add Initiative Brand"}
          triggerClose={triggerClose}
          setTriggerClose={setTriggerClose}
          toggleElement={
            <p className={classes.addSKUBtn}>Add SKU</p>
          }
          content={
            <>
              <form onSubmit={handleBrandCreate}>
                <CustomInput
                  type={"text"}
                  name="name"
                  // value={namex}
                  placeholder={"Brand name"}
                  onChange={e => setNamex(e.target.value)}
                  label={"Brand"}
                />
                <CustomInput
                  type={"text"}
                  name="sku"
                  // value={skux}
                  placeholder={"SKU"}
                  onChange={e => setSkux(e.target.value)}
                  label="SKU"
                />
                <CustomInput
                  type={"tel"}
                  name="target"
                  // value={unitx}
                  placeholder={"Cases Target"}
                  onChange={e => setUnitx(e.target.value)}
                  label="Cases Target"
                />
                <CustomInput
                  type={"tel"}
                  name="durationInDays"
                  // value={durationx}
                  placeholder={"Duration (Days)"}
                  onChange={e => setDurationx(e.target.value)}
                  label="Duration (Days)"
                />
                <CustomInput
                  type={"tel"}
                  name="caseUnitsNumber"
                  // value={caseUnitsNumberx}
                  placeholder={"Case Units Number"}
                  onChange={e => setCaseUnitsNumberx(e.target.value)}
                  label="Case Units Number"
                />
                <CustomInput
                  type={"tel"}
                  name="pricePerCase"
                  // value={pricePerCasex}
                  placeholder={"Price Per Case"}
                  onChange={e => setPricePerCasex(e.target.value)}
                  mb="1rem"
                  label="Price Per Case"
                />
                <Flex mt="1rem" gap=".5rem">
                  {imagex !== '' && <Box backgroundImage={imagex} backgroundSize={"cover"} height={"100px"} width={"120px"} borderRadius={"4px"} backgroundColor={"lightgray"} />}
                  <ImageUploader
                    onImagesUpload={handleImagesUpload}
                    setLoading={setLoadingi}
                    loading={loadingi}
                    max={1}
                  />
                </Flex>
                <PrimaryButton type="submit" mt="2rem" loading={loadingx} text="Add Brand" />
              </form>
            </>
          }
        />}


      <div className={classes.groupedCards}>
        {data?.brands?.map((i) => (
          <div key={i?._id} className={classes.cardContainer}>
            <img className={classes.cardImage} src={i?.image} alt='' />

            <p className={classes.cardName}>{i?.name}</p>
            <p className={classes.cardSKU}>SKU: {i?.sku}</p>


            {/* {((currentUser?.role !== "Super Admin" && data?.status === "Pending") || (currentUser?.role === "Super Admin" && data?.status !== "Completed")) && */}
              <CustomDrawer
                position={"right"}
                title={"Edit Initiative Brand"}
                triggerClose={triggerClose}
                setTriggerClose={setTriggerClose}
                toggleElement={

                  <p onClick={() => handleEditBrand(i)} className={classes.editInitiativeBrand}>edit</p>
                }
                content={
                  <>
                    <Flex mt="1rem" justifyContent={"flex-end"}>
                      <Text onClick={deleteBrand} cursor={"pointer"} fontSize={".9rem"} color={"#D92D20"} fontWeight={"500"}>Delete Brand</Text>
                    </Flex>
                    <form onSubmit={handleBrandUpdate}>
                      <CustomInput
                        type={"text"}
                        name="name"
                        value={namex}
                        placeholder={"Brand name"}
                        onChange={e => setNamex(e.target.value)}
                        label={"Brand"}
                      />
                      <CustomInput
                        type={"text"}
                        name="sku"
                        value={skux}
                        placeholder={"SKU"}
                        onChange={e => setSkux(e.target.value)}
                        label="SKU"
                      />
                      <CustomInput
                        type={"tel"}
                        name="target"
                        value={unitx}
                        placeholder={"Cases Target"}
                        onChange={e => setUnitx(e.target.value)}
                        label="Cases Target"
                      />
                      <CustomInput
                        type={"tel"}
                        name="durationInDays"
                        value={durationx}
                        placeholder={"Duration (Days)"}
                        onChange={e => setDurationx(e.target.value)}
                        label="Duration (Days)"
                      />
                      <CustomInput
                        type={"tel"}
                        name="caseUnitsNumber"
                        value={caseUnitsNumberx}
                        placeholder={"Case Units Number"}
                        onChange={e => setCaseUnitsNumberx(e.target.value)}
                        label="Case Units Number"
                      />
                      <CustomInput
                        type={"tel"}
                        name="pricePerCase"
                        value={pricePerCasex}
                        placeholder={"Price Per Case"}
                        onChange={e => setPricePerCasex(e.target.value)}
                        mb="1rem"
                        label="Price Per Case"
                      />
                      <Flex mt="1rem" gap=".5rem">
                        {imagex !== '' && <Box backgroundImage={imagex} backgroundSize={"cover"} height={"100px"} width={"120px"} borderRadius={"4px"} backgroundColor={"lightgray"} />}
                        <ImageUploader
                          onImagesUpload={handleImagesUpload}
                          setLoading={setLoadingi}
                          loading={loadingi}
                          max={1}
                        />
                      </Flex>
                      <PrimaryButton type="submit" mt="2rem" loading={loadingx} text="Update Brand Details" />
                    </form>
                  </>
                }
              />
              {/* } */}
          </div>
        ))}
      </div>



      <p className={classes.title}>What to do?</p>
      <p className={classes.description}>{data?.instructions}</p>
      <br />
      <>
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <Heading fontWeight={500} fontSize="1.1rem" color="#475467" fontFamily='Roboto'>Initiative Rewards</Heading>
          <CustomDrawer
            position={"right"}
            title={"Manage Rewards"}
            triggerClose={triggerClose}
            setTriggerClose={setTriggerClose}
            toggleElement={
              <Text onClick={() => setTags(data?.games[0]?.prizes)} cursor={"pointer"} fontSize={".9rem"} color={"#D92D20"} fontWeight={"500"}>Edit</Text>
            }
            content={
              <>
                <Flex justifyContent={"flex-end"}>
                  <SecondaryButton mt="1rem" icon="add" text="Add Game" onClick={() => navigate(`/initiatives/create/3?iid=${data?._id}&mode=add`)} loading={loadingx} />
                </Flex>
                <Box maxW={"600px"} mt="1.5rem" mx="auto">
                  <CustomTabs data={finalTabData} />
                </Box>
              </>
            }
          />
        </Flex>
        {data?.games?.map(({ name }) => (
          <Text key={name} mt={2} color={"#667085"} fontSize={".9rem"} textTransform={"capitalize"}><strong>{name}</strong></Text>
        ))}
        {data?.gamePrizes?.length > 0 && <Text mt={2} color={"#667085"} fontSize={".9rem"} textTransform={"capitalize"}><strong>Prizes: </strong>{data?.gamePrizes?.map(i => i?.name).join(', ')}</Text>}
      </>

      <br /><br />
      {merged === true &&
        <>
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Heading fontWeight={500} fontSize="1.1rem" color="#475467" fontFamily='Roboto'>Shelf Image examples</Heading>
            <CustomDrawer
              position={"right"}
              title={"Edit Initiative Details"}
              triggerClose={triggerClose}
              setTriggerClose={setTriggerClose}
              toggleElement={
                <Text cursor={"pointer"} fontSize={".9rem"} color={"#D92D20"} fontWeight={"500"}>Edit</Text>
              }
              content={
                <ShelfPosm src={"edit"} setRefetch={setRefetch} setTriggerClose={setTriggerClose} data={data} />
              }
            />
          </Flex>

          <Flex mt="1.2rem" gap={[".8rem", "1.2rem"]}>
            {data?.shelfImages?.map((i) => (
              <Box key={i} flex="1 1 300px">
                <Box backgroundImage={i} backgroundSize={"cover"} height={"180px"} borderRadius={"4px"} />
              </Box>
            ))}
          </Flex>
          <br />
          <Heading fontWeight={500} fontSize="1.1rem" color="#475467" fontFamily='Roboto'>POSM Image examples</Heading>
          <Flex mt="1.2rem" gap={[".8rem", "1.2rem"]}>
            {data?.posmImages?.map((i) => (
              <Box key={i} flex="1 1 300px">
                <Box backgroundImage={i} backgroundSize={"cover"} height={"180px"} borderRadius={"4px"} />
              </Box>
            ))}
          </Flex>
        </>}
    </div>
  )
}

export default Mechanics;