import { Box, Divider, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import CreateRegions from '../createRegion/createRegions';
import CustomDrawer from '../../../../../components/customDrawer/customDrawer';
import SecondaryButton from '../../../../../components/secondaryButton/secondarybutton';
import LineSkeleton from '../../../../../components/lineSkeleton';
import EmptyState from '../../../../../components/emptyState/emptyState';
import { truncateText } from '../../../../../utils/helper';
import axiosInstance from '../../../../../services/api';
import PrimaryButton from '../../../../../components/primaryButton/primarybutton';
import EditRegions from '../editRegion/editRegions';

const RegionsTable = ({regionsData, clientId}) => {
    const [regions, setRegions] = useState(regionsData);
    const [loading, setLoading] = useState(false);
    const [loadingd, setLoadingd] = useState(false);
    const [triggerClose, setTriggerClose] = useState("");
    const [refetch, setRefetch] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedUser, setSelectedUser] = useState(null)

    const currentUser = useSelector(({userData})=>  userData?.currentUser);
    const toast = useToast()


    

    const deleteGroup = async() => {
        setLoadingd(true)
        try {
            const { data } = await axiosInstance.delete(`/clients/${clientId}/states-filter-groups/${selectedUser?.id}`)
            toast({
            description: data?.message,
            status: "success",
            position: "top"
            });
            setRefetch(Math.random())
            onClose()
            window.location.reload()
        } catch(error) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top"
            });
        } finally {
            setLoadingd(false)
        }
    }

    const handleModal = (user) => {
        setSelectedUser(user)
        onOpen()
      }



  return (
    <>
        <Flex alignItems={"center"} gap=".8rem" justifyContent={"space-between"}>
            <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>Regions <span style={{padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054"}}>{regions?.length}</span></Text>
            {(currentUser?.role === "Super Admin" || currentUser?.role === "Client") &&
            <CustomDrawer
            position={"right"}
            title={"Create Regions"}
            triggerClose={triggerClose}
            setTriggerClose={setTriggerClose}
            toggleElement={
                <SecondaryButton type="submit" text={"Add Regions Group"} icon="add" loading={false}  />
            }
            content={
               <CreateRegions cid={clientId} setTriggerClose={setTriggerClose} triggerClose={triggerClose} />
            }
            />}
        </Flex>
    
        <Box bg="#FFF">
            <Table mt="1.2rem" borderRadius={"12px"} overflow={"hidden"}>
                <Thead bg="#F9FAFB" border="1px solid #EAECF0">
                    <Tr>
                        <Th textTransform={"capitalize !important"} color={"#475467"}>ID</Th>
                        <Th textTransform={"capitalize !important"} color={"#475467"}>Name</Th>
                        <Th textTransform={"capitalize !important"} color={"#475467"}>States</Th>
                        <Th></Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                
                <Tbody>
                {loading ? 
                <LineSkeleton width="100%" num={10} />
                :
                regions?.length < 1 ?
                <EmptyState mt="1.5rem" text="There are no regions added yet" />
                :
                regions?.map((({id, name, states}) => {
                    const data = {id, name, states}
                    return (
                        <Tr key={id} cursor={"pointer"}>
                            <Td color={"#475467"} fontSize={".9rem"}>{truncateText(id, 15)}</Td>
                            <Td>
                                <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{name}</Text>
                            </Td>
                            <Td color={"#475467"} fontSize={".9rem"}>{states?.slice(0, 8)?.map(i => i)?.join(", ")}...</Td>
                            
                            {(currentUser?.role === "Super Admin" || currentUser?.role === "Client") &&
                                <Td display={"flex"} gap="1rem" fontSize={".85rem"} cursor={"pointer"} fontWeight={"500"}>
                                    <CustomDrawer
                                    position={"right"}
                                    title={"Edit Region Grpup"}
                                    triggerClose={triggerClose}
                                    setTriggerClose={setTriggerClose}
                                    toggleElement={
                                        <Text textDecoration={"underline"} mb=".2rem" fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>Edit</Text>
                                    }
                                    content={
                                    <EditRegions cid={clientId} datax={data} setTriggerClose={setTriggerClose} triggerClose={triggerClose} />
                                    }
                                    />
                                    <Text onClick={() => handleModal({name, id})} textDecoration={"underline"} mb=".2rem" color={"crimson"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>Delete</Text>
                                </Td>}
                        </Tr>
                    )
                }))
                }
                </Tbody>
            </Table>
        </Box>

        <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{'Delete Group'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm deletion of {selectedUser?.name}?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                        <PrimaryButton onClick={deleteGroup} text="Delete" width="150px" bg="crimson !important" padding="1rem !important" loading={loadingd} />
                        </Flex>
                    </>
                    <Text pb="1rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Cancel</Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    </>
  )
}

export default RegionsTable;