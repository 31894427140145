import React, { useEffect, useState } from 'react'
import CustomInput from '../../../../components/customInput/customInput';
import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import axiosInstance from '../../../../services/api';
import { buildResourceURL } from '../../../../utils/helper';

const AssignAgency = ({setTriggerClose, setRefetch, iid}) => {
  
  const [searchVal, setSearchVal] = useState("");
  const [selected, setSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const toast = useToast();

  const queryParams = [
    {
        key: 'isDeleted',
        value: false
    }
]

  const getAgencies = async() => {
    setLoading(true)
    try {
        const { data } = await axiosInstance.get(buildResourceURL('agencies', queryParams))
        setAgencies(data?.data)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
  }
  
  useEffect(() => {
    getAgencies()
  }, [])

  const assign = async(e) => {
    e.preventDefault()
    setLoadingx(true)
    const datax = {
      agencyId: selected,
    }

    try {
      const {data} = await axiosInstance.patch(`/initiatives/${iid}/assign-agency`, datax)
      toast({
        description: data?.message,
        status: "success",
        position: 'top'
      });
      setTriggerClose('close' + (Math.random()))
      setRefetch(Math.random())
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
      });
    } finally {
      setLoadingx(false)
    }
  }

  return (
    <>
    <form>
      <CustomInput
        type={"text"}
        required
        placeholder={"Search Agencies"}
        onChange={e => setSearchVal(e.target.value)}
        // sType={"search"}
      />
    </form>
    <br />
    <Box>
      {agencies?.filter(i => i.name.toLowerCase().includes(searchVal?.toLowerCase()))?.map(({_id: id, name, email}) => {
        return (
          <Flex key={id} padding={"0"} borderRadius={"16px"} p=".5rem" border={selected === id ? "2px solid #7F56D9" : "2px solid #FFF"} mb=".7rem" cursor={"pointer"} onClick={() => setSelected(id)}>
              <Flex alignItems={"center"} w="100%" justifyContent={"space-between"}>
                  <Flex alignItems={"center"}>
                      {/* <Box height={"50px"} width={"60px"} backgroundImage={profilePicture} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}  /> */}
                      <Box>
                          <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{name}</Text>
                          <Text mb=".2rem" color={"#101828"} fontSize={".85rem"} opacity={".6"} fontWeight={"500"}>{email}</Text>
                      </Box>
                  </Flex>
              </Flex>
          </Flex>
        )
      })}
    </Box>

    <PrimaryButton mt="5rem" type="submit" text={`Assign`} onClick={assign} loading={loadingx} />
    </>
  )
}

export default AssignAgency;