import { Box, Flex, Text } from "@chakra-ui/react";
import moment from "moment";
import ListMap from "../../../../../../../../components/mapView/mapViewList";

const ClockOutRationale = ({ data }) => {
  console.log(data);
  const coords = data?.locations?.map((i) => ({
    coordinates: [i?.longitude, i?.latitude]
  }));

  return (
    <>
      <Flex
        width={"90%"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mb=".5rem"
      >
        <Box>
          <Text
            mb=".2rem"
            fontWeight={"500"}
            color={"#667085"}
            fontSize={".9rem"}
          >
            Clock In
          </Text>
          <Text fontWeight={"500"} color={"#98A2B3"} fontSize={".83rem"}>
            {moment(data?.clockInTime, "YYYY-MM-DD HH:mm:ss").format("h:mm A")}
          </Text>
        </Box>
        <Box>
          <Text
            mb=".2rem"
            fontWeight={"500"}
            color={"#667085"}
            fontSize={".9rem"}
          >
            Clock Out
          </Text>
          <Text fontWeight={"500"} color={"#98A2B3"} fontSize={".83rem"}>
            {moment(data?.clockOutTime, "YYYY-MM-DD HH:mm:ss").format(
              "h:mm A"
            ) || "--"}
          </Text>
        </Box>
        <Box>
          <Text
            mb=".2rem"
            fontWeight={"500"}
            color={"#667085"}
            fontSize={".9rem"}
          >
            Hours Worked
          </Text>
          <Text fontWeight={"500"} color={"#98A2B3"} fontSize={".83rem"}>
            {data?.hoursWorked || "--"}
          </Text>
        </Box>
      </Flex>

      {data?.locations?.length > 0 &&
      <>
        <ListMap coordinates={coords} height={"300px"} defaultZoom={16} />

        <Text mt="1rem" fontWeight={"600"}>Attendance for {data?.clockInTime?.slice(0, 10)}</Text>

        {data?.geofenceEvents?.map(({event, timeStamp, _id}) => {
            return (
                <Box key={_id} mt=".7rem" border={".5px solid lightgray"} p=".6rem" borderRadius={'6px'}>
                    <Text>You are <span style={{fontWeight: '600', textTransform: 'uppercase'}}>{event}</span>ing the store</Text>
                    <Text>{moment(timeStamp).calendar()}</Text>
                </Box>
            )
        })}
      </>}
    </>
  );
};

export default ClockOutRationale;
