import { Box, Divider, Flex, FormControl, FormLabel, Menu, MenuButton, MenuItem, MenuList, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import axiosInstance from '../../../services/api'
import SecondaryButton from '../../../components/secondaryButton/secondarybutton'
import EmptyState from '../../../components/emptyState/emptyState'
import PrimaryButton from '../../../components/primaryButton/primarybutton'
import LineSkeleton from '../../../components/lineSkeleton'
import { buildResourceURL, truncateText } from '../../../utils/helper'
import { useNavigate } from 'react-router-dom'
import ExportCSV from '../../../components/exportCsv'
import CustomInput from '../../../components/customInput/customInput'
import { useSelector } from 'react-redux'
import CustomPagination from '../../../components/customPagination/customPagination'
import CustomDrawer from '../../../components/customDrawer/customDrawer'
import AssignAgency from './assignAgency/assignAgency'
import { Loader } from '../../../components/Loader/loader'
import classes from './initiatives.module.css'
import { formatDateHeader } from '../../../helpers/formatDateHeader'
import { InitiativePaginationComponent } from '../../../components/initiativePaginationComponent/initiativePaginationComponent'
import { InitiativeEmptyState } from '../../../components/initiativeEmptyState/initiativeEmptyState'
import { ImBin2 } from "react-icons/im";
import { SiBuzzfeed } from "react-icons/si";
import { defaultSearchValueState } from '../../../constants/defaultStates'
import moment from 'moment'

const Initiatives = ({ clientId }) => {
    const [initiatives, setInitiatives] = useState([]);
    const [totalInitiatives, setTotalInitiatives] = useState("");
    const [loading, setLoading] = useState(true);
    const [loadingx, setLoadingx] = useState(false);
    const [modalType, setModalType] = useState("");
    const [selectedUser, setSelectedUser] = useState(null)
    const [refetch, setRefetch] = useState("");
    const [binView, setBinView] = useState(false);

    const [triggerClose, setTriggerClose] = useState("")

    const [date, setDate] = useState("")

    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast()
    const navigate = useNavigate()

    const currentUser = useSelector(({ userData }) => userData?.currentUser);

    const limit = 15

    const [searchValues, setSearchValues] = useState(defaultSearchValueState)
    const { page } = searchValues

    const queryParams = [
        {
            key: 'isDeleted',
            value: binView === false ? "" : binView
        },
        {
            key: 'client',
            value: clientId
        },
        {
            key: 'page',
            value: page
        },
        {
            key: 'limit',
            value: limit
        }
    ]

    const getInitiatives = async () => {
        try {
            const { data } = await axiosInstance.get(currentUser?.role === 'Basic Subuser' ? '/subusers/initiatives' : buildResourceURL('initiatives', queryParams))
            setInitiatives(data?.data)
            setTotalInitiatives(data?.totalCount)
        } catch (error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getInitiatives()
    }, [searchValues, refetch])

    const handleModal = (type, user) => {
        setModalType(type)
        setSelectedUser(user)
        onOpen()
    }


    const suspendEmployee = async () => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.delete(`initiatives/${selectedUser?.id}/soft`)
            toast({
                description: data?.message,
                status: "success",
                position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch (error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }

    const restoreEmployee = async () => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.patch(`initiatives/${selectedUser?.id}/restore`)
            toast({
                description: data?.message,
                status: "success",
                position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch (error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }

    const deleteEmployee = async () => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.delete(`initiatives/${selectedUser?.id}`)
            toast({
                description: data?.message,
                status: "success",
                position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch (error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }


    const startInitiative = async (val) => {
        setLoadingx(true)
        const datax = date ?
            {
                status: val,
                date
            }
            :
            {
                status: val
            }
        try {
            const { data } = await axiosInstance.patch(`initiatives/${selectedUser?.id}/change-status`, datax)
            toast({
                description: data?.message,
                status: "success",
                position: "top"
            });
            setDate("")
            setRefetch(Math.random())
            onClose()
        } catch (error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }

    const handleViewBin = () => {
        setBinView(!binView)
        setRefetch(Math.random())
    }

    const refinedData = initiatives?.map(i => ({
        _id: i?._id,
        name: i?.name,
        client: i?.client?.companyName,
        description: i?.decription,
        instructions: i?.instructions,

    }))


    const headers = [
        {
            key: '_id',
            name: 'ID',
        },
        {
            key: 'name',
            name: 'Name',
        },
        {
            key: 'email',
            name: 'Email',
        },
        {
            key: 'phoneNumber',
            name: 'Phone Number',
        },
        {
            key: 'role',
            name: 'Role',
        },
        {
            key: 'isDeleted',
            name: 'Deleted?',
        },
        {
            key: 'createdAt',
            name: 'Joined',
        },
    ]

    const unassign = async (e) => {
        e.preventDefault()
        setLoadingx(true)

        try {
            const { data } = await axiosInstance.patch(`/initiatives/${selectedUser?.id}/unassign-agency`)
            toast({
                description: data?.message,
                status: "success",
                position: 'top'
            });
            setRefetch(Math.random())
            onClose()
        } catch (error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
            });
        } finally {
            setLoadingx(false)
        }
    }

    const [inputPage, setInputPage] = useState(page)


    const colorAssignor = {
        'Ongoing': '#007bff',    // Bright blue
        'Pending': '#ffc107',    // Yellow
        'Completed': '#28a745'   // Green
    };

    return (
        // <>
        // <Flex mb="1rem" justify={"flex-end"}>
        // {currentUser?.role !== 'Basic Subuser' && 
        //     <Menu width={"200px !important"}>
        //         <MenuButton>
        //             <BiDotsVerticalRounded style={{cursor: "pointer"}} />
        //         </MenuButton>
        //         <MenuList>
        //             <Divider my=".2rem" />
        //             <MenuItem onClick={handleViewBin} fontWeight={"500"} fontSize={".85rem"}>{binView ? "Back to full list" : "View Bin"}</MenuItem>
        //             <Divider my=".2rem" />
        //         </MenuList>
        //     </Menu>}
        //     </Flex>
        //     <Flex alignItems={"center"} gap=".8rem" justifyContent={"space-between"}>
        //         <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>Initiatives <span style={{padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054"}}>{totalInitiatives}</span></Text>


        //         {currentUser?.role !== 'Basic Subuser' && <SecondaryButton onClick={() => navigate('/initiatives/create/1')} text={"Create Initiative"} icon="add" loading={false}  />}

        //     </Flex>
        // <Box bg="#FFF" mt=".7rem" p="1.5rem 1rem" borderRadius={"12px"}>
        //     <Table mt="1.2rem" borderRadius={"12px"} overflow={"hidden"}>
        //         <Thead bg="#F9FAFB" border="1px solid #EAECF0">
        //             <Tr>
        //                 <Th textTransform={"capitalize !important"} color={"#475467"}>ID</Th>
        //                 <Th textTransform={"capitalize !important"} color={"#475467"}>Name</Th>
        //                 {clientId === undefined && <Th textTransform={"capitalize !important"} color={"#475467"}>Client</Th>}
        //                 <Th textTransform={"capitalize !important"} color={"#475467"}>Description</Th>
        //                 <Th textTransform={"capitalize !important"} color={"#475467"}>Instructions</Th>
        //                 <Th textTransform={"capitalize !important"} color={"#475467"}>Created</Th>
        //                 <Th textTransform={"capitalize !important"} color={"#475467"}>Agency</Th>
        //                 <Th textTransform={"capitalize !important"} color={"#475467"}>Status</Th>
        //                 <Th textTransform={"capitalize !important"} color={"#475467"}></Th>
        //                 <Th></Th>
        //             </Tr>
        //         </Thead>

        //         {loading ? 
        //         <LineSkeleton width="100%" num={10} />
        //         :
        //         initiatives?.length < 1 ?
        //         <EmptyState mt="1.5rem" text="No initiatives created yet" />
        //         :
        //         <Tbody>

        //         {initiatives?.map((({_id: id, name, createdAt, status, client, description, instructions, isDeleted, agency}) => {
        //             return (
        //                 <Tr key={id}>
        //                     <Td color={"#475467"} fontSize={".9rem"}>{truncateText(id, 10)}</Td>
        //                     <Td>
        //                     <Flex alignItems={"center"} gap=".4rem">
        //                         {/* <Box height={"40px"} width={"40px"} backgroundImage={profilePicture} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}  /> */}
        //                         <Box>
        //                             <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{name}</Text>
        //                         </Box>
        //                     </Flex>
        //                     </Td>
        //                     {clientId === undefined && <Td color={"#475467"} fontSize={".9rem"}>{client?.companyName}</Td>}
        //                     <Td color={"#475467"} fontSize={".9rem"}>{truncateText(description, 15)}</Td>
        //                     <Td color={"#475467"} fontSize={".9rem"}>{truncateText(instructions, 15)}</Td>
        //                     <Td color={"#475467"} fontWeight={"500"} fontSize={".85rem"}>{createdAt?.slice(0,10)}</Td>
        //                     <Td fontWeight={"500"} fontSize={".85rem"}>{agency !== null ? agency?.name : "--"}</Td>
        //                     <Td fontWeight={"500"} fontSize={".85rem"}>
        //                         <Text textAlign={"center"} fontSize={"12px"} fontWeight={"500"} p=".1rem .5rem" borderRadius={"16px"} border={status === "Pending" ? "1px solid lightgray" : status === "Ongoing" ? "1px solid goldenrod" : "1px solid #ABEFC6"} color={status === "Pending" ? "#475467" : status === "Ongoing" ? "goldenrod" : "#067647"} bg={status === "Pending" ? "#F9FAFB" : status === "Ongoing" ? "" : "#ECFDF3"}>{status}</Text>
        //                     </Td>
        //                     <Td>

        //                     </Td>
        //                 </Tr>
        //             )
        //         }))
        //         }
        //         </Tbody>}
        //     </Table>



        //     <CustomPagination
        //      page={page}
        //      setPage={setPage}
        //      pageSize={limit}
        //      totalElements={totalInitiatives}
        //     />
        // </Box>
        // </>

        < div className={classes.screenContainer}>

            {
                loading ?
                    <div className={classes.loader_con}>
                        <Loader />
                    </div>
                    :
                    <>
                        {
                            initiatives.length ?
                                <div className={classes.table_container}>

                                    <div className={classes.holdsButton}>
                                        <div className={classes.titleAndInstructionContainer}>
                                            <p style={binView ? { color: '#690404' } : {}} className={classes.title}>Initiatives {binView ? 'Bin' : ''}: {totalInitiatives}</p>
                                            <p className={classes.instruction}>Click on the table row for more actions e.g restart, delete, etc.</p>
                                        </div>
                                        {currentUser?.role !== 'Basic Subuser' &&
                                            <div className={classes.binGrouped}>
                                                <button className={classes.createButton} onClick={(e) => { e?.preventDefault(); navigate('/initiatives/create/1') }}>Create Initiative</button>
                                                {currentUser?.role === 'Super Admin' &&
                                                <div style={binView ? { background: '#046917' } : { background: '#690404' }} onClick={handleViewBin} className={classes.binContainer}>
                                                    {!binView ? <ImBin2 className={classes.bin} /> : <SiBuzzfeed className={classes.bin} />}
                                                </div>}

                                            </div>
                                        }
                                    </div>



                                    <p className={classes.showing}>
                                        Showing: <span>{initiatives.length || 1} of {totalInitiatives}</span>
                                    </p>
                                    <div className={classes.scrollable}>
                                        <table className={classes.table}>
                                            <thead>
                                                <tr>
                                                    <th style={binView ? { background: '#690404', color: '#fff' } : {}}>Creation Date</th>
                                                    <th style={binView ? { background: '#690404', color: '#fff' } : {}}>Name</th>
                                                    <th style={binView ? { background: '#690404', color: '#fff' } : {}}>Client</th>
                                                    <th style={binView ? { background: '#690404', color: '#fff' } : {}}>Description</th>
                                                    <th style={binView ? { background: '#690404', color: '#fff' } : {}}>Instructions</th>
                                                    <th style={binView ? { background: '#690404', color: '#fff' } : {}}>Agency</th>
                                                    <th style={binView ? { background: '#690404', color: '#fff' } : {}}>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {initiatives.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>
                                                                {formatDateHeader(moment(item.createdAt).format('YYYY-MM-DD'))}
                                                            </p>
                                                            <p className={classes.tooltip}>
                                                                {formatDateHeader(moment(item.createdAt).format('YYYY-MM-DD'))}
                                                            </p>
                                                        </td>

                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>{item.name}</p>
                                                            <p className={classes.tooltip}>{item.name}</p>
                                                        </td>
                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>{item.client?.companyName}</p>
                                                            <p className={classes.tooltip}>{item.client?.companyName}</p>
                                                        </td>
                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>{item.description}</p>
                                                            <p className={classes.tooltip}>{item.description}</p>
                                                        </td>
                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>{item.instructions}</p>
                                                            <p className={classes.tooltip}>{item.instructions}</p>
                                                        </td>
                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>{item.agency?.name || ''}</p>
                                                            <p className={classes.tooltip}>{item.agency?.name || ''}</p>
                                                        </td>
                                                        <td style={{ display: 'flex', justifyContent: 'space-between' }} className={classes.tooltip_container}>
                                                            <p style={{ background: colorAssignor[item.status] }} className={classes.status}>{item.status}</p>
                                                            <Menu width={"50px !important"}>
                                                                <MenuButton>
                                                                    <BiDotsVerticalRounded className={classes.kebab} style={{ cursor: "pointer" }} />
                                                                </MenuButton>
                                                                <MenuList>
                                                                    <Divider my=".2rem" />
                                                                    <MenuItem onClick={() => navigate(`/initiatives/${item._id}`)} fontWeight={"500"} fontSize={".85rem"}>View Initiative</MenuItem>
                                                                    <Divider my=".2rem" />
                                                                    {(currentUser?.role !== "Client" && currentUser?.role !== "Basic Subuser") && item.status !== "Completed" && <MenuItem onClick={() => handleModal(item.status === "Pending" ? 'start' : 'restart', { name: item.name, id: item._id, status: item.status })} fontWeight={"500"} fontSize={".85rem"}>{item.status === "Pending" ? "Start" : item.status === "Ongoing" ? "Restart" : ""} Initiative</MenuItem>}
                                                                    <Divider my=".2rem" />
                                                                    {(currentUser?.role !== "Basic Subuser") && item.status === "Ongoing" && <MenuItem onClick={() => handleModal('complete', { name: item.name, id: item._id, status: item.status })} fontWeight={"500"} fontSize={".85rem"}>Complete Initiative</MenuItem>}
                                                                    <Divider my=".2rem" />
                                                                    {currentUser?.role === "Super Admin" &&
                                                                        <>
                                                                            {item.isDeleted ?
                                                                                <MenuItem onClick={() => handleModal('restore', { name: item.name, id: item._id })} fontWeight={"500"} fontSize={".85rem"}>Restore Initiative</MenuItem>
                                                                                :
                                                                                <MenuItem onClick={() => handleModal('suspend', { name: item.name, id: item._id })} fontWeight={"500"} fontSize={".85rem"}>Suspend Initiative</MenuItem>
                                                                            }
                                                                            <Divider my=".2rem" />
                                                                            {/* <MenuItem onClick={() => handleModal('delete', {name, id})} color={"crimson"} fontWeight={"500"} fontSize={".85rem"}>Delete Initiative</MenuItem>
                                        <Divider my=".2rem" /> */}
                                                                        </>}
                                                                    {currentUser?.role === "Client" &&
                                                                        <>
                                                                            {item.agency === null ?
                                                                                <CustomDrawer
                                                                                    position={"right"}
                                                                                    title={"Assign Agency"}
                                                                                    triggerClose={triggerClose}
                                                                                    setTriggerClose={setTriggerClose}
                                                                                    toggleElement={
                                                                                        <MenuItem fontWeight={"500"} fontSize={".85rem"}>Assign Agency</MenuItem>
                                                                                    }
                                                                                    content={
                                                                                        <AssignAgency setTriggerClose={setTriggerClose} setRefetch={setRefetch} iid={item._id} />
                                                                                    }
                                                                                />
                                                                                :
                                                                                <MenuItem onClick={() => handleModal('unassign', { name: item.name, id: item._id })} fontWeight={"500"} fontSize={".85rem"}>Unassign Agency</MenuItem>
                                                                            }
                                                                        </>
                                                                    }
                                                                </MenuList>
                                                            </Menu>
                                                        </td>



                                                    </tr>
                                                ))}
                                            </tbody>


                                        </table>

                                    </div>




                                    <InitiativePaginationComponent
                                        page={page} totalCount={totalInitiatives} setSearchValues={setSearchValues} setInputPage={setInputPage} inputPage={inputPage} limit={limit}
                                    />
                                </div>
                                :
                                <InitiativeEmptyState searchValues={searchValues} name={'Initiatives'} />

                        }


                        <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{`${modalType} Initiative`}</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    {modalType === 'suspend' &&
                                        <>
                                            <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm suspension of {selectedUser?.name}?</Text>
                                            <Flex justifyContent={"center"} my="1.3rem">
                                                <PrimaryButton onClick={suspendEmployee} text="Suspend" width="150px" padding="1rem !important" loading={loadingx} />
                                            </Flex>
                                        </>
                                    }
                                    {modalType === 'restore' &&
                                        <>
                                            <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm restore of {selectedUser?.name}?</Text>
                                            <Flex justifyContent={"center"} my="1.3rem">
                                                <PrimaryButton onClick={restoreEmployee} text="Restore" width="150px" padding="1rem !important" loading={loadingx} />
                                            </Flex>
                                        </>
                                    }
                                    {modalType === 'unassign' &&
                                        <>
                                            <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm unassigning agency from {selectedUser?.name}?</Text>
                                            <Flex justifyContent={"center"} my="1.3rem">
                                                <PrimaryButton onClick={unassign} text="Unassign" width="150px" padding="1rem !important" loading={loadingx} />
                                            </Flex>
                                        </>
                                    }
                                    {modalType === 'delete' &&
                                        <>
                                            <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm deletion of {selectedUser?.name}?</Text>
                                            <Flex justifyContent={"center"} my="1.3rem">
                                                <PrimaryButton onClick={deleteEmployee} text="Delete" width="150px" bg="crimson !important" padding="1rem !important" loading={loadingx} />
                                            </Flex>
                                        </>
                                    }
                                    {modalType === 'start' &&
                                        <>
                                            <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm commencement of {selectedUser?.name}?</Text>
                                            <FormControl my="1.5rem">
                                                <FormLabel fontSize={".85rem"} transform={"translateY(2rem)"} opacity={".7"}>Set start date (Optional, start date would default to current date if unset)</FormLabel>
                                            </FormControl>
                                            <CustomInput
                                                type={"date"}
                                                onChange={e => setDate(e.target.value)}
                                            />
                                            <br />
                                            <Flex justifyContent={"center"} my="1.3rem">
                                                <PrimaryButton onClick={() => startInitiative("Ongoing")} text="Start" width="150px" padding="1rem !important" loading={loadingx} />
                                            </Flex>
                                        </>
                                    }
                                    {modalType === 'restart' &&
                                        <>
                                            <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm date reset of {selectedUser?.name}?</Text>
                                            {/* <FormControl my="1.5rem">
                                <FormLabel fontSize={".85rem"} transform={"translateY(2rem)"} opacity={".7"}>Set start date (Optional, start date would default to current date if unset)</FormLabel>
                            </FormControl>
                            <CustomInput
                            type={"date"}
                            onChange={e => setDate(e.target.value)}
                            /> */}
                                            <br />
                                            <Flex justifyContent={"center"} my="1.3rem">
                                                <PrimaryButton onClick={() => startInitiative("Pending")} text="Start" width="150px" padding="1rem !important" loading={loadingx} />
                                            </Flex>
                                        </>
                                    }
                                    {modalType === 'complete' &&
                                        <>
                                            <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm completion of {selectedUser?.name}?</Text>
                                            <FormControl my="1.5rem">
                                                <FormLabel fontSize={".85rem"} transform={"translateY(2rem)"} opacity={".7"}>Set end date (Optional, end date would default to current date if unset)</FormLabel>
                                            </FormControl>
                                            <CustomInput
                                                type={"date"}
                                                onChange={e => setDate(e.target.value)}
                                            />
                                            <br />
                                            <Flex justifyContent={"center"} my="1.3rem">
                                                <PrimaryButton onClick={() => startInitiative("Completed")} text="Start" width="150px" padding="1rem !important" loading={loadingx} />
                                            </Flex>
                                        </>
                                    }
                                    <Text pb="1rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Cancel</Text>
                                </ModalBody>
                            </ModalContent>
                        </Modal>

                    </>
            }



        </div>
    )
}

export default Initiatives;