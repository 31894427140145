import React, { useState } from 'react'
import { useToast } from '@chakra-ui/react';
import CustomInput from '../../../../../components/customInput/customInput';
import axiosInstance from '../../../../../services/api';
import PrimaryButton from '../../../../../components/primaryButton/primarybutton';


const EditAgency = ({loading, setLoading, setTriggerClose, datay}) => {
  const [email, setEmail] = useState(datay?.email);
  const [name, setName] = useState(datay?.name);
  const [phoneNumber, setPhoneNumber] = useState(datay?.phoneNumber);

  const toast = useToast();

  const editAgency = async(e) => {
    e.preventDefault()
    setLoading(true)
    const datax = {
      name,
      email,
      phoneNumber: phoneNumber.charAt(0) === "0"
      ? "+234" + phoneNumber.slice(1)
      :
      phoneNumber.charAt(0) === "+" ?
      phoneNumber
      : "+234" + phoneNumber,
    }
    try {
      const {data} = await axiosInstance.patch(`/agencies/${datay?.id}`, datax)
      toast({
        description: data?.message,
        status: "success",
        position: "top"
      });
      setTriggerClose('close' + (Math.random()))
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
      });
    } finally {
      setLoading(false)
    }
  }


  return (
    <form onSubmit={editAgency}>
        <CustomInput
        type={"text"}
        required
        value={name}
        placeholder={"Name"}
        onChange={e => setName(e.target.value)}
        />
        <CustomInput
        type={"email"}
        required
        value={email}
        placeholder={"Email Address"}
        onChange={e => setEmail(e.target.value)}
        />
         <CustomInput
        type={"tel"}
        minLength={15}
        maxLength={15}
        required
        value={phoneNumber}
        placeholder={"Phone number"}
        mb={".8rem"}
        onChange={e => setPhoneNumber(e.target.value)}
        />
        <PrimaryButton mt="1rem" type="submit" text="Update agency" func={() => {}} loading={loading} />
    </form>
  )
}

export default EditAgency;