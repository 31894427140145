import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import Shelf from './shelf/shelf';
import Posm from './posm/posm';
import CustomTabs from '../../../../../../../../components/customTabs/customTabs';
import axiosInstance from '../../../../../../../../services/api';
import { useParams } from 'react-router-dom';
import { BiCalendarAlt } from 'react-icons/bi';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'; 
import { buildResourceURL, getCurrentDate, getDateRelativeToPresetDay, getNextDay } from '../../../../../../../../utils/helper';
import SpinLoader from '../../../../../../../../components/spinLoader/spinLoader';
import ListCompare from '../../../../../../../../components/listCompare/listCompare';
import CustomInput from '../../../../../../../../components/customInput/customInput';
import { useSelector } from 'react-redux';
import CustomSelect from '../../../../../../../../components/customSelect/customSelect';
import statesLgaJson from '../../../../../../../../utils/stateLGAs';

const ShelfAndPosm = ({data, src}) => {
    const [spdocs, setSpdocs] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingx, setLoadingx] = useState(false)
    const {iid} = useParams()

    const currentUser = useSelector(({userData})=>  userData?.currentUser);


    // const [showDateFilter, setShowDateFilter] = useState(false)
    // const [startDate, setStartDate] = useState("");
    //   const [endDate, setEndDate] = useState("");
      const [refetch, setRefetch] = useState("");
      const [date, setDate] = useState(getCurrentDate())

      const [state, setState] = useState("");
      const [region, setRegion] = useState("");
      const [group, setGroup] = useState("");

  const places = statesLgaJson?.map(i => ({
      title: i?.state,
      value: i?.state?.toLowerCase(),
      listValue: i?.lgas?.map(i => ({
          title: i,
          value: i,
      }))
  }))

  const regionsData = data?.client?.statesFilterGroups || []
  const regionsDataOpts = regionsData?.map(i => ({
      title: i?.name,
      value: i?.name?.toLowerCase()
  }))

  const storeGroupData = data?.initiativeStoresFilterGroups || []
  const storeGroupDataOpts = storeGroupData?.map(i => ({
      title: i?.name,
      value: i?.name?.toLowerCase()
  }))

  const [product, setProduct] = useState("");
  
  const productGroupData = data?.productsFilterGroups || []
  const productGroupDataOpts = productGroupData?.map(i => ({
      title: i?.name,
      value: i?.products?.join(',')
  }))

  
    //   const handleSelect = (ranges) => {
  
    //     const date1 = new Date(ranges?.selection?.startDate);
    //     const date2 = new Date(ranges?.selection?.endDate);
  
    //     const year1 = date1.getFullYear();
    //     const month1 = String(date1.getMonth() + 1).padStart(2, '0');
    //     const day1 = String(date1.getDate()).padStart(2, '0');
  
    //     const year2 = date2.getFullYear();
    //     const month2 = String(date2.getMonth() + 1).padStart(2, '0');
    //     const day2 = String(date2.getDate()).padStart(2, '0');
  
    //     const formattedDate1 = `${year1}-${month1}-${day1}`;
    //     const formattedDate2 = `${year2}-${month2}-${day2}`;
  
    //     setStartDate(formattedDate1)
    //     setEndDate(formattedDate2)
  
    //     setRefetch(Math.random())
    //   }
  
    // const selectionRange = {
    //   startDate: new Date(),
    //   endDate: new Date(),
    //   key: 'selection',
    // }
  
    // const clearDates = () => {
    //   setStartDate("")
    //   setEndDate("")
    // }

    const queryParams = [
        {
            key: 'createdAt[gte]',
            value: date
        },
        {
            key: 'createdAt[lt]',
            value: getDateRelativeToPresetDay(date, 1)
        },
        {
            key: 'initiative',
            value: iid
        },
        {
            key: 'limit',
            value: 350
        },
        {
            key: 'state',
            value: state
        },
        {
            key: 'products',
            value: product
        },
        ...regionsData?.find(i => i?.name === region)?.states?.map(k => ({
        key: 'state[in]',
        value: k
        })) || [],
        ...storeGroupData?.find(i => i?.name?.toLowerCase() === group)?.initiativeStoresIDs?.map(k => ({
        key: 'initiativeStore[in]',
        value: k
        })) || [],
    ]

    const getStoreDocs = async() => {
        setLoading(true)
        try {
            const res = await axiosInstance.get(buildResourceURL(`shelf-and-posm`, queryParams))
            // console.log(res?.data?.data)
            if(src === 'full') {
                setSpdocs(res?.data?.data)
            } else {
                setSpdocs(res?.data?.data?.filter(i => i?.initiativeStore?.store?._id === data?.store?._id))
            }
        } catch(error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }
    
    useEffect(() => {
    getStoreDocs()
    }, [date, refetch, state, region, group, product])


    const datax = [
        {
            title: "Shelf",
            render: <CustomTabs data={[
                {
                    title: "All",
                    render: <Shelf src={src} loading={loading} data={spdocs?.filter(i => i?.type === 'shelf')} />
                },
                src === 'full' && 
                // currentUser?.role !== "Client" &&
                {
                    title: "Not Submitted",
                    render: <ListCompare listType={'shelfAndPosm'} />
                }
            ]} />

        },
        {
            title: "POSM",
            render: <CustomTabs data={[
                {
                    title: "All",
                    render: <Posm src={src} loading={loading} data={spdocs?.filter(i => i?.type === 'posm')} />
                },
                src === 'full' && 
                // currentUser?.role !== "Client" &&
                {
                    title: "Not Submitted",
                    render: <ListCompare listType={'shelfAndPosm'} />
                }
            ]} />
        }
    ]

    
  return (
    <>
    
    <Box>
        
        <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Heading mt=".5rem" mb=".8rem" fontWeight={500} fontSize="1.1rem" color="#475467" fontFamily='Roboto'>Uploads</Heading>
            <Flex gap={".7rem"}>
            <CustomSelect
                selection={state}
                setSelection={setState}
                options={[{title: "All States", value: ""}, ...places]}
                height="40px"
                fontSize=".8rem"
                width="120px"
                mt="0"
                cursor="pointer"
            />
            <CustomSelect
                selection={region}
                setSelection={setRegion}
                options={[{title: "All Regions", value: ""}, ...regionsDataOpts]}
                height="40px"
                fontSize=".8rem"
                width="120px"
                mt="0"
                cursor="pointer"
            />
            <CustomSelect
                selection={group}
                setSelection={setGroup}
                options={[{title: "All Store Groups", value: ""}, ...storeGroupDataOpts]}
                height="40px"
                fontSize=".8rem"
                width="120px"
                mt="0"
                cursor="pointer"
            />
            <CustomSelect
                selection={product}
                setSelection={setProduct}
                options={[{title: "All Product Groups", value: ""}, ...productGroupDataOpts]}
                height="40px"
                fontSize=".8rem"
                width="158px"
                mt="0"
                cursor="pointer"
            />
            <Box width={"150px"}>
                <CustomInput
                type={"date"}
                required
                py=".95rem"
                placeholder={"Select Date"}
                onChange={e => setDate(e.target.value)}
                mt="0"
                value={date}
                />
            </Box>
            </Flex>
            
            {/* <Flex onClick={() => setShowDateFilter(!showDateFilter)} p=".7rem" justifyContent={"flex-end"} alignItems={"center"} gap=".5rem" height="40px !important" borderRadius={"12px"} position={"relative"} cursor={"pointer"}>
                <Text whiteSpace={"nowrap"}>{showDateFilter ? "Close Filter" : "Filter Date"}</Text>
                <BiCalendarAlt style={{opacity: showDateFilter ? "0" : "1"}} />
                {showDateFilter &&
                <Box position={"absolute"} top="10%" bg="gray" p=".02rem" zIndex={"20"}>
                    <Flex py=".7rem" alignItems={"center"} px=".8rem" justifyContent={"space-between"}>
                        <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={".95rem"} color={"#FFF"}></Text>
                        <Text onClick={clearDates} whiteSpace={"nowrap"} fontWeight={"500"} fontSize={".95rem"} color={"#FFF"}>Show all</Text>
                    </Flex>
                    
                    {<DateRangePicker
                        ranges={[selectionRange]}
                        onChange={handleSelect}
                        showMonthAndYearPickers={false}
                    />}
                </Box>}
            </Flex> */}
        </Flex>
        
        <CustomTabs data={datax} />
        
    </Box>
    </>
  )
}

export default ShelfAndPosm;