import { Box, Divider, Flex, Heading, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axiosInstance from '../../../../../../services/api'
import SpinLoader from '../../../../../../components/spinLoader/spinLoader'
import { MdVerified } from 'react-icons/md'
import CopyButton from '../../../../../../components/copyButton'
import { RxCalendar } from 'react-icons/rx'
import moment from 'moment'
import { BsTelephone } from 'react-icons/bs'
import { AiOutlineUser } from 'react-icons/ai'
import { FiUsers } from 'react-icons/fi'
import GoBack from '../../../../../../components/goBack'
import { BiLocationPlus } from 'react-icons/bi'
import CustomDrawer from '../../../../../../components/customDrawer/customDrawer'
import ViewEmployee from '../../../../../../components/employeeView/viewEmployee/viewEmployee'
import { HiChevronRight } from 'react-icons/hi'
import PrimaryButton from '../../../../../../components/primaryButton/primarybutton'
import Map from '../../../../../../components/mapView/mapView'
import { useSelector } from 'react-redux'

const StoreFront = () => {
    const [initiativeStore, setInitiativeStore] = useState("")
    const [loading, setLoading] = useState(true)
    const [triggerClose, setTriggerClose] = useState("");
    const {sid, id, iid} = useParams()
    const navigate = useNavigate()

    const currentUser = useSelector(({userData})=>  userData?.currentUser);

    const getInitiativeStore = async() => {
        try {
            const { data } = await axiosInstance.get(`initiative-stores/${sid}`)
            setInitiativeStore(data?.data)
        } catch(error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
      }
    
      useEffect(() => {
        getInitiativeStore()
      }, [])

    const storeData = [
        {
          title: "Outlet ID",
          value: initiativeStore?.store?._id,
          icon: <FiUsers color='#98A2B3' />
        },
        {
            title: "Location",
            value: initiativeStore?.store?.address,
            icon: <BiLocationPlus color='#98A2B3' />
        },
        {
          title: "Owner Name",
          value: initiativeStore?.store?.ownerName,
          icon: <AiOutlineUser color='#98A2B3' />
        },
        {
          title: "Phone Number",
          value: initiativeStore?.store?.ownerPhoneNumber,
          icon: <BsTelephone color='#98A2B3' />
        },
        {
          title: "Date Created",
          value: moment(initiativeStore?.createdAt)?.calendar(),
          icon: <RxCalendar color='#98A2B3' />
        }
      ]


  return (
    <>
    <GoBack />
    {loading ?
    <SpinLoader />
    :
    <Flex mt=".8rem" gap={"1rem"} height={"81vh"}>
        <Box flex={"1 1 300px"} bg="#FFF" p="1.3rem">
            <Flex alignItems={"center"} gap="1rem">
                <Box height={"45px"} width={"45px"} borderRadius={"50%"} position={"relative"} bg="#FFE7CC" backgroundSize={"contain"} backgroundRepeat={"no-repeat"}>
                <MdVerified color="#1671D9" style={{position: "absolute", zIndex: "10", bottom: "0", right: "-10%", transform: "scale(1.2)"}} />
                </Box>
                <Box>
                    <Text mb=".1rem" color={"#101828"} fontSize={"1.1rem"} fontWeight={"500"} textTransform={"capitalize"}>{initiativeStore?.store?.name}</Text>
                    <Text color={"#475467"} fontSize={".8rem"} fontWeight={"500"}>{initiativeStore?.store?.address}</Text>
                </Box>
            </Flex>
            <Box mt="2rem">
            {storeData?.map(({title, value, icon}) => (
                <Flex key={title} mb="1.4rem" alignItems={"center"} justifyContent={"space-between"}>
                <Flex alignItems={"center"} gap="1rem">
                    {icon}
                    <Box>
                    <Text mb=".1rem" color={"#475467"} fontSize={".8rem"} fontWeight={"500"} textTransform={"capitalize"}>{title}</Text>
                    <Text color={"#101928"} fontSize={".9rem"} fontWeight={"500"}>{value}</Text>
                    </Box>
                </Flex>
                <CopyButton toCopy={value} />
                </Flex>
            ))}
            </Box>
            <Divider mb="1rem" />
            {currentUser?.role === "Client" ?
            <Flex alignItems={"center"} cursor={"pointer"} justifyContent={"space-between"}>
                <Flex alignItems={"center"} gap="1rem">
                    <Box height={"38px"} width={"38px"} borderRadius={"50%"} position={"relative"} backgroundImage={initiativeStore?.supervisor?.profilePicture} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}>
                    <MdVerified color="#1671D9" style={{position: "absolute", zIndex: "10", bottom: "0", right: "-18%", transform: "scale(1)"}} />
                    </Box>
                    <Box>
                        <Text color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{initiativeStore?.supervisor?.firstName + " " + initiativeStore?.supervisor?.lastName}</Text>
                        <Text color={"#475467"} fontSize={".8rem"} fontWeight={"500"}>Supervisor</Text>
                    </Box>
                </Flex>
                {/* <HiChevronRight fontSize={"1.2rem"} color='#98A2B3' /> */}
            </Flex>
            :
            <CustomDrawer 
            position={"right"}
            title={`Supervisor details`}
            triggerClose={triggerClose}
            setTriggerClose={setTriggerClose}
            toggleElement={
                <Flex alignItems={"center"} cursor={"pointer"} justifyContent={"space-between"}>
                    <Flex alignItems={"center"} gap="1rem">
                        <Box height={"38px"} width={"38px"} borderRadius={"50%"} position={"relative"} backgroundImage={initiativeStore?.supervisor?.profilePicture} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}>
                        <MdVerified color="#1671D9" style={{position: "absolute", zIndex: "10", bottom: "0", right: "-18%", transform: "scale(1)"}} />
                        </Box>
                        <Box>
                            <Text color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{initiativeStore?.supervisor?.firstName + " " + initiativeStore?.supervisor?.lastName}</Text>
                            <Text color={"#475467"} fontSize={".8rem"} fontWeight={"500"}>Supervisor</Text>
                        </Box>
                    </Flex>
                    <HiChevronRight fontSize={"1.2rem"} color='#98A2B3' />
                </Flex>
            }
            content={
                <ViewEmployee title={"Supervisor"} data={initiativeStore?.supervisor} />
            }
            />}
            <br />
            {currentUser?.role === "Client" ?
            <Flex alignItems={"center"} cursor={"pointer"} justifyContent={"space-between"}>
                <Flex alignItems={"center"} gap="1rem">
                    <Box height={"38px"} width={"38px"} borderRadius={"50%"} position={"relative"} backgroundImage={initiativeStore?.promoter?.profilePicture} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}>
                    <MdVerified color="#1671D9" style={{position: "absolute", zIndex: "10", bottom: "0", right: "-18%", transform: "scale(1)"}} />
                    </Box>
                    <Box>
                        <Text mb=".1rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{initiativeStore?.promoter?.firstName + " " + initiativeStore?.promoter?.lastName}</Text>
                        <Text color={"#475467"} fontSize={".8rem"} fontWeight={"500"}>Promoter</Text>
                    </Box>
                </Flex>
                {/* <HiChevronRight fontSize={"1.2rem"} color='#98A2B3' /> */}
            </Flex>
            :
            <CustomDrawer 
            position={"right"}
            title={`Promoter details`}
            triggerClose={null}
            setTriggerClose={() => {}}
            toggleElement={
                <Flex alignItems={"center"} cursor={"pointer"} justifyContent={"space-between"}>
                    <Flex alignItems={"center"} gap="1rem">
                        <Box height={"38px"} width={"38px"} borderRadius={"50%"} position={"relative"} backgroundImage={initiativeStore?.promoter?.profilePicture} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}>
                        <MdVerified color="#1671D9" style={{position: "absolute", zIndex: "10", bottom: "0", right: "-18%", transform: "scale(1)"}} />
                        </Box>
                        <Box>
                            <Text mb=".1rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{initiativeStore?.promoter?.firstName + " " + initiativeStore?.promoter?.lastName}</Text>
                            <Text color={"#475467"} fontSize={".8rem"} fontWeight={"500"}>Promoter</Text>
                        </Box>
                    </Flex>
                    <HiChevronRight fontSize={"1.2rem"} color='#98A2B3' />
                </Flex>
            }
            content={
                <ViewEmployee title={"Promoter"} data={initiativeStore?.promoter} />
            }
            />}

            <PrimaryButton onClick={() => navigate(`/clients/${id}/initiatives/${iid}/store/${sid}`)} mt="2rem" text={"Enter Outlet"} />
        </Box>
        <Map coordinates={initiativeStore?.store?.location?.coordinates} height={"660px"} />
    </Flex>
    }
    </>
  )
}

export default StoreFront