import { Box, Flex, Grid, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import moment from "moment";
import React, { useState } from "react";
import EmptyState from "../../../../../../../../../components/emptyState/emptyState";
import CustomInput from "../../../../../../../../../components/customInput/customInput";
import { HiArrowNarrowRight } from "react-icons/hi";
import CustomDrawer from "../../../../../../../../../components/customDrawer/customDrawer";
import LineSkeleton from "../../../../../../../../../components/lineSkeleton";
import { useSelector } from "react-redux";


const Shelf = ({ data, src, loading }) => {
  const [searchVal, setSearchVal] = useState("");

  const currentUser = useSelector(({userData})=>  userData?.currentUser);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [img, setImg] = useState("")

  const handleDownload = async (imgData) => {
    const imageUrl = imgData?.imageURL;

    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${imgData?.initiativeStore?.store?.name}_shelf_image.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Release the object URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  const handleOpen = (imgData) => {
    setImg(imgData)
    onOpen()
  }



  const initiativeStoreList = data?.map(i => i?.initiativeStore?.store?.name)
  const uniqueList = [...new Set(initiativeStoreList)]

  const filteredUniqueList = searchVal?.length > 0 ? uniqueList?.filter(v => v?.toLowerCase()?.includes(searchVal?.toLowerCase())) : uniqueList

  // const transformedData = uniqueList?.map(i => ({
  //   storeName: i,
  //   otherData: data?.filter(v => v?.initiativeStore?.store?.name === i)
  // }))

  // const temp = data?.filter(v => v?.initiativeStore?.store?.name === "shoprite")?.map(k => k.imageURL)
  // console.log(filteredUniqueList, data)
 
  return (
    <Box position={"relative"}>
      <CustomInput
      type={"text"}
      required
      value={searchVal}
      placeholder={`Search by outlets..`}
      onChange={e => setSearchVal(e.target.value)}
      mt="0"
      sType="search"
      py="1.19rem"
      width="350px"
      />
      <br />
      <Text fontSize={".9rem"}><strong>{filteredUniqueList?.length}</strong> submissions</Text>
      {loading ?
      <LineSkeleton num={8} />
      :
      data?.length < 1 ? (
        <EmptyState text="No uploads yet" />
      ) : (
        <Table mt="1.2rem" borderRadius={"12px"} overflow={"hidden"}>
            <Thead bg="#F9FAFB" border="1px solid #EAECF0">
                <Tr>
                    <Th whiteSpace={"nowrap"} textTransform={"capitalize !important"} color={"#475467"}>Store</Th>
                    <Th whiteSpace={"nowrap"} textTransform={"capitalize !important"} color={"#475467"}>Submitted By</Th>
                    <Th whiteSpace={"nowrap"} textTransform={"capitalize !important"} color={"#475467"}>Submitted On</Th>
                    <Th whiteSpace={"nowrap"} textTransform={"capitalize !important"} color={"#475467"}>Images</Th>
                    <Th whiteSpace={"nowrap"} textTransform={"capitalize !important"} color={"#475467"}></Th>
                </Tr>
            </Thead>
            
            <Tbody>
            {filteredUniqueList?.map(((i) => {
                const datax = {
                  store: i ?? "--",
                  promoter: data?.find(v => v?.initiativeStore?.store?.name === i)?.user?.firstName + " " + data?.find(v => v?.initiativeStore?.store?.name === i)?.user?.lastName,
                  // dateSubmitted: moment(data?.find(v => v?.initiativeStore?.store?.name === i)?.createdAt).calendar(),
                  dateSubmitted: `${data?.filter(v => v?.initiativeStore?.store?.name === i)?.slice(0,2)?.map(k => k?.createdAt)}`,
                  images: data?.filter(v => v?.initiativeStore?.store?.name === i)?.slice(0,4)
                }
                return (
                    <Tr key={i}>
                        <Td>
                            <Text mb=".2rem" color={"#101828"} maxW={"200px"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{datax?.store}</Text>
                        </Td>
                        <Td color={"#475467"} fontSize={".9rem"} textTransform={"capitalize"}>{datax?.promoter}</Td>
                        <Td color={"#475467"} fontSize={".8rem"}>{moment(datax?.images[0]?.createdAt).calendar()?.slice(-8)} - {datax?.images[1] ? moment(datax?.images[1]?.createdAt).calendar()?.slice(-8) : ''}</Td>
                        <Td>
                          {/* <Flex gap={".5rem"}>
                          {datax?.images?.map(k => (
                            <Box height={"35px"} width={"40px"} bg="grey" backgroundImage={k?.imageURL} backgroundSize="cover" borderRadius={"4px"}></Box>
                          ))}
                          </Flex> */}
                          <Text>{datax?.images?.length} images</Text>
                        </Td>
                        <Td>
                        <CustomDrawer
                        position={"right"}
                        title={"Shelf Submission"}
                        triggerClose={""}
                        setTriggerClose={() => {}}
                        toggleElement={
                          <HiArrowNarrowRight cursor={"pointer"} />
                        }
                        content={
                            <>
                             <Flex my="1rem" alignItems={"center"} justifyContent={"space-between"}>
                              <Flex alignItems={"center"} gap="1rem">
                                <Box>
                                  <Text mb=".1rem" color={"#475467"} fontSize={".8rem"} fontWeight={"500"} textTransform={"capitalize"}>Store</Text>
                                  <Text color={"#101928"} fontSize={".9rem"} textTransform={"capitalize"} fontWeight={"500"}>{datax?.store}</Text>
                                </Box>
                              </Flex>
                            </Flex>
                             <Flex mb="1rem" alignItems={"center"} justifyContent={"space-between"}>
                              <Flex alignItems={"center"} gap="1rem">
                                <Box>
                                  <Text mb=".1rem" color={"#475467"} fontSize={".8rem"} fontWeight={"500"} textTransform={"capitalize"}>Promoter</Text>
                                  <Text color={"#101928"} fontSize={".9rem"} textTransform={"capitalize"} fontWeight={"500"}>{datax?.promoter}</Text>
                                </Box>
                              </Flex>
                            </Flex>
                             <Flex mb="1rem" alignItems={"center"} justifyContent={"space-between"}>
                              <Flex alignItems={"center"} gap="1rem">
                                <Box>
                                  <Text mb=".1rem" color={"#475467"} fontSize={".8rem"} fontWeight={"500"} textTransform={"capitalize"}>Date Submited</Text>
                                  <Text color={"#101928"} fontSize={".9rem"} textTransform={"capitalize"} fontWeight={"500"}>{datax?.dateSubmitted}</Text>
                                </Box>
                              </Flex>
                            </Flex>
                             <Flex mb="1rem" alignItems={"center"} justifyContent={"space-between"}>
                              <Flex alignItems={"center"} gap="1rem">
                                <Box>
                                  <Text mb=".6rem" color={"#475467"} fontSize={".8rem"} fontWeight={"500"} textTransform={"capitalize"}>Images</Text>
                                  <Grid
                                    width={"100%"}
                                    templateColumns={[
                                      "repeat(auto-fill, minmax(150px, 1fr))",
                                      "repeat(auto-fill, minmax(400px, 1fr))",
                                    ]}
                                    gap={"1rem"}
                                  >
                                    {datax?.images?.map(k => (
                                      <Box>
                                        <Box
                                          bg="lightgray"
                                          backgroundImage={k?.imageURL}
                                          backgroundSize={"cover"}
                                          height={"180px"}
                                          borderRadius={"4px"}
                                          cursor={"pointer"}
                                          onClick={() => handleOpen(k)}
                                        />
                                        <Flex
                                          gap="3rem"
                                          justifyContent={"space-between"}
                                          alignItems={"center"}
                                        >
                                          <Text mt=".2rem" color={"#000"} fontSize={".8rem"}>
                                            {data?.find(v => v?.imageURL === k?.imageURL)?.comment}
                                          </Text>
                                          <Text
                                            mt=".2rem"
                                            color={"#000"}
                                            opacity={".8"}
                                            fontSize={".7rem"}
                                          >
                                            {moment(data?.find(v => v?.imageURL === k?.imageURL)?.createdAt).calendar()}
                                          </Text>
                                        </Flex>
                                      </Box>
                                    ))}
                                  </Grid>
                                  
                                </Box>
                              </Flex>
                            </Flex>
                            </>
                        }
                        />
                        </Td>
                    </Tr>
                )
            }))
            }
            </Tbody>
        </Table>

      //   filteredUniqueList?.map(i => (
      //     <Box>
      //       <Text mb=".5rem" textTransform={"capitalize"} fontWeight={"600"}>{i}</Text>
      //       <Grid
      //         width={"100%"}
      //         templateColumns={[
      //           "repeat(auto-fill, minmax(150px, 1fr))",
      //           "repeat(auto-fill, minmax(250px, 1fr))",
      //         ]}
      //         gap={"1rem"}
      //       >
      //         {data?.filter(v => v?.initiativeStore?.store?.name === i)?.slice(0, 4)?.map((i) => (
      //           <Box>
      //             <Box
      //               bg="lightgray"
      //               backgroundImage={i?.imageURL}
      //               backgroundSize={"cover"}
      //               height={"180px"}
      //               borderRadius={"4px"}
      //               position={"relative"}
      //               cursor={"pointer"}
      //               onClick={() => handleDownload(i)}
      //             >
      //               <Text fontSize={"13px"} bg="#FFF" position={"absolute"} border="1px solid grey" p=".05rem .6rem .15rem .6rem" top={"3%"} right={"2%"} borderRadius={"18px"}>{i?.user?.firstName + " " + i?.user?.lastName}</Text>
      //             </Box>
      //             <Flex
      //               gap="3rem"
      //               justifyContent={"space-between"}
      //               alignItems={"center"}
      //             >
      //               <Text mt=".2rem" color={"#000"} fontSize={".8rem"}>
      //                 {i?.comment}
      //               </Text>
      //               <Text
      //                 mt=".2rem"
      //                 color={"#000"}
      //                 opacity={".8"}
      //                 fontSize={".7rem"}
      //               >
      //                 {moment(i?.updatedAt).calendar()}
      //               </Text>
      //             </Flex>
      //           </Box>
      //         ))}
      //     </Grid>
      //     <Divider my="1rem" />
      //     </Box>
      //   ))
      // )
      )}


        <Modal size={"6xl"} isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{'Outlet Image'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {/* <Image src={data?.imageURL} /> */}
                    <Box cursor={"pointer"} onClick={() => handleDownload(img)} height={"68vh"} borderRadius={"4px"} width={"100%"} backgroundImage={img?.imageURL} backgroundSize={"contain"} backgroundPosition={"center"} backgroundRepeat={"no-repeat"} />
                    <Text pb="1rem" mt="2rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Close</Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    </Box>
  );
};

export default Shelf;