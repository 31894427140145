import React, { useEffect, useState } from 'react'
import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import axiosInstance from '../../../services/api';
import CustomInput from '../../customInput/customInput';
import PrimaryButton from '../../primaryButton/primarybutton';
import { useSelector } from 'react-redux';
import { buildResourceURL } from '../../../utils/helper';
import CustomSelect from 'components/customSelect/customSelect';
import countriesData from '../../../utils/countries.json'


const AddEmployee = ({loading, setLoading, setTriggerClose, title, clientId}) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [searchVal, setSearchVal] = useState("");
  const [employees, setEmployees] = useState([]);
  const [loadingv, setLoadingv] = useState(true);
  const [country, setCountry] = useState("")

  const [selected, setSelected] = useState("")

  const currentUser = useSelector(({ userData }) => userData.currentUser);

  const employeeUrl = currentUser?.role === "Agency" ? 'agencies/employees' : 'employees'

    const queryParams = [
        {
            key: 'isDeleted',
            value: false
        },
        {
            key: 'role',
            value: title
        },
        {
            key: 'search',
            value: searchVal
        }
    ]

    const getEmployees = async() => {
        setLoadingv(true)
        try {
            const { data } = await axiosInstance.get(buildResourceURL(employeeUrl, queryParams))
            setEmployees(data?.data)
            // setTotal(data?.totalCount)
        } catch(error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoadingv(false)
        }
    }

    useEffect(() => {
        getEmployees()
    }, [searchVal])

  const toast = useToast();

  const createEmployee = async(e) => {
    e.preventDefault()
    setLoading(true)
    const datax = {
      firstName,
      lastName,
      email,
      country,
      role: title,
      phoneNumber: phoneNumber.charAt(0) === "0"
      ? "+234" + phoneNumber.slice(1)
      : "+234" + phoneNumber,
    }
    try {
      const {data} = await axiosInstance.post(clientId ? 'clients/employees' : employeeUrl, datax)
      toast({
        description: data?.message,
        status: "success",
        position: "top"
      });
      setTriggerClose('close' + (Math.random()))
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
      });
    } finally {
      setLoading(false)
    }
  }

  const createEmployeex = async(e) => {
    e.preventDefault()
    setLoading(true)
    const datax = {
      employeeId: selected
    }
    try {
      const {data} = await axiosInstance.post(employeeUrl, datax)
      toast({
        description: data?.message,
        status: "success",
        position: "top"
      });
      setTriggerClose('close' + (Math.random()))
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
      });
    } finally {
      setLoading(false)
    }
  }

  const countries = countriesData?.map(x => ({
        title: x?.name,
        value: x?.name?.toLowerCase()
      }))


  return (
    <>
    
    {currentUser?.role === "Agency" ?
    <>
    <form>
      <CustomInput
        type={"text"}
        required
        placeholder={"Search Promoter"}
        onChange={e => setSearchVal(e.target.value)}
        // sType={"search"}
      />
    </form>
    <br />
    {searchVal?.length >= 3 &&
    <Box>
      {employees?.slice(0, 5)?.map(({_id: id, firstName, lastName, email, profilePicture, isApproved}) => {
        return (
          <Flex key={id} padding={"0"} borderRadius={"16px"} p=".5rem" border={selected === id ? "2px solid #7F56D9" : "2px solid #FFF"} mb=".7rem" cursor={"pointer"} onClick={() => setSelected(id)}>
              <Flex alignItems={"center"} w="100%" justifyContent={"space-between"}>
                  <Flex alignItems={"center"}>
                      <Box height={"50px"} width={"60px"} backgroundImage={profilePicture} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}  />
                      <Box>
                          <Text mb=".2rem" color={"#667085"} textTransform={"capitalize"} fontWeight={"500"}>{firstName + " " + lastName}</Text>
                          <Text fontWeight={"300"} fontSize={".85rem"} color="#667085">{email}</Text>
                      </Box>
                  </Flex>
                  <Text fontSize={"12px"} fontWeight={"500"} color={"#98A2B3"}>{isApproved ? "Verified" : "Unverified"}</Text>
              </Flex>
          </Flex>
        )
      })}
    </Box>}

    <PrimaryButton mt="5rem" type="submit" text={`Add ${title}`} onClick={createEmployeex} loading={loading} />
    </>
    :
    <form onSubmit={createEmployee}>
        <CustomInput
        type={"text"}
        required
        placeholder={"First Name"}
        onChange={e => setFirstName(e.target.value)}
        />
        <CustomInput
        type={"text"}
        required
        placeholder={"Last Name"}
        onChange={e => setLastName(e.target.value)}
        />
        <CustomInput
        type={"email"}
        required
        placeholder={"Email Address"}
        onChange={e => setEmail(e.target.value)}
        />
        <CustomSelect
            required
            options={countries}
            placeholder={"Country"}
            onChange={e => setCountry(e.target.value)}
          />
         <CustomInput
        type={"tel"}
        minLength={11}
        maxLength={11}
        required
        placeholder={"Phone number"}
        mb={".8rem"}
        onChange={e => setPhoneNumber(e.target.value)}
        />
        <PrimaryButton mt="1rem" type="submit" text={`Add ${title}`} func={() => {}} loading={loading} />
    </form>}

    </>
  )
}

export default AddEmployee;