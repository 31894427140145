import { Box, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react'
import CustomTabs from '../../../../components/customTabs/customTabs';
import Map from '../../../../components/mapView/mapView';
import { BsDot } from 'react-icons/bs';
import CopyButton from '../../../../components/copyButton';
import moment from 'moment';

const ViewStore = ({data}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const datax = [
      {
          title: "Outlet Image",
          render: <Box height={"250px"} onClick={onOpen} cursor={"pointer"} borderRadius={"4px"} backgroundImage={data?.imageURL} backgroundSize={"cover"}></Box>
      },
      {
          title: "Map View",
          render: <Map coordinates={data?.location?.coordinates} height={"250px"} />
      }
  ]

  const storeDetails = [
    {
      title: "Outlet Name",
      value: data?.name,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Outlet ID",
      value: data?.id,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Outlet Location",
      value: `${data?.area}, ${data?.state}`,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Street Number & Name",
      value: `${data?.streetNumber}, ${data?.streetName}`,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Outlet Phone Number",
      value: data?.phoneNumber,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Outlet Category",
      value: data?.category,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Outlet Type",
      value: data?.type,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Outlet Level",
      value: data?.level,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Outlet Contact's Name",
      value: data?.ownerName,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Outlet Contact Phone",
      value: data?.ownerPhoneNumber,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Outlet Contact's Email",
      value: data?.ownerEmail,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Brand",
      value: data?.brand,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Additional Information",
      value: data?.additionalInfo,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Products Source",
      value: data?.productSource,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Purchase Willingness",
      value: data?.purchaseWillingness,
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Buying Preferences",
      value: data?.buyingPreferences?.join(', '),
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Status",
      value: data?.isApproved ? "Approved" : "Pending Approval",
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Date Created",
      value: moment(data?.createdAt).calendar(),
      icon: <BsDot color='#98A2B3' />
    },
    {
      title: "Comments",
      value: data?.comment,
      icon: <BsDot color='#98A2B3' />
    },
  ]

  const handleDownload = async (imgData) => {
    const imageUrl = imgData?.imageURL;

    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${imgData?.name}_image.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Release the object URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  return (
    <Box>
      <CustomTabs data={datax} />
      <Box mt="1rem">
        {storeDetails?.map(({title, value, icon}) => (
          <Flex key={title} mb="1rem" alignItems={"center"} justifyContent={"space-between"}>
            <Flex alignItems={"center"} gap="1rem">
              {/* {icon} */}
              {value &&
              <Box>
                <Text mb=".1rem" color={"#475467"} fontSize={".8rem"} fontWeight={"500"} textTransform={"capitalize"}>{title}</Text>
                <Text color={"#101928"} fontSize={".9rem"} fontWeight={"500"}>{value}</Text>
              </Box>}
            </Flex>
            {title === "Store ID" && <CopyButton toCopy={value} />}
          </Flex>
        ))}
      </Box>




      <Modal size={"6xl"} isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{'Outlet Image'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {/* <Image src={data?.imageURL} /> */}
                    <Box cursor={"pointer"} onClick={() => handleDownload(data)} height={"68vh"} borderRadius={"4px"} width={"100%"} backgroundImage={data?.imageURL} backgroundSize={"contain"} backgroundPosition={"center"} backgroundRepeat={"no-repeat"} />
                    <Text pb="1rem" mt="2rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Close</Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    </Box>
  )
}

export default ViewStore;