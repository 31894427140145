import { Box, Flex, Heading } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import Metrics from '../../../components/metrics/metrics';
import ClientsView from './clientsView/clientsView';
import ChartView from './chartView/chartView';
import SupervisorsView from './supervisorsView/supervisorsView';
import PromotersView from './promotersView/promotersView';
import StoresView from './storesView/storesView';
import RequestsView from './requestsView/requestsView';
import { useSelector } from 'react-redux';
import { buildResourceURL } from '../../../utils/helper';
import axiosInstance from '../../../services/api';
import InitiativesView from './initiativesView/initiativesView';
import SurveysView from './surveysView/surveysView';

const Home = () => {
  const [totalClients, setTotalClients] = useState(0)
  const [totalPromoters, setTotalPromoters] = useState(0)
  const [totalSupervisors, setTotalSupervisors] = useState(0)
  const [totalStores, setTotalStores] = useState(0)
  const [totalSurveys, setTotalSurveys] = useState(0)

  const [totalInitiatives, setTotalInitiatives] = useState("")
  const [loading, setLoading] = useState(true)

  const currentUser = useSelector(({ userData }) => userData?.currentUser);

  const queryParams = [

  ]

  const getInitiatives = async () => {
    try {
      const { data } = await axiosInstance.get(buildResourceURL('initiatives', queryParams))
      setTotalInitiatives(data?.data?.length)
    } catch (error) {
      console.error(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getInitiatives()
  }, [])

  const data = [
    {
      title: "Outlets",
      value: totalStores ?? 0
    },
    {
      title: "Supervisors",
      value: totalSupervisors ?? 0
    },
    {
      title: "Promoters",
      value: totalPromoters ?? 0
    },
    {
      title: "Initiatives",
      value: loading ? "0" : totalInitiatives
    },
    {
      title: "Surveys",
      value: totalSurveys ?? 0
    },
    {
      title: "Clients",
      value: totalClients ?? 0
    },
  ]

  return (
    <Box>
      {currentUser?.role !== "Client" && <Metrics data={data} />}
      <Flex mt="1rem" gap="1rem" flexWrap={"wrap"}>
        <ClientsView setTotalClients={setTotalClients} />
        <InitiativesView />
        {/* <ChartView /> */}
      </Flex>
      <Flex mt="1rem" gap="1rem" flexWrap={"wrap"}>
        <SupervisorsView setTotalSupervisors={setTotalSupervisors} />
        <PromotersView setTotalPromoters={setTotalPromoters} />
      </Flex>
      <Flex mt="1rem" gap="1rem" flexWrap={"wrap"}>
        <StoresView setTotalStores={setTotalStores} />
        <SurveysView totalSurveys={totalSurveys} setTotalSurveys={setTotalSurveys} />
        {/* <RequestsView /> */}
      </Flex>
    </Box>
  )
}

export default Home;