/* eslint-disable react-hooks/exhaustive-deps */
import { Skeleton, useToast } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classes from './techSupportTickets.module.css'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { RiSearch2Line } from 'react-icons/ri';
import { getStatusEnums, statusColors } from '../../constants/techSupportTickets';
import { MdRefresh } from 'react-icons/md';


const url = process.env.REACT_APP_BASE_URL

export default function TechSupportTickets() {

    const toast = useToast();
    const currentUser = useSelector(({ userData }) => userData?.currentUser);
    const { role } = currentUser
    const navigate = useNavigate()

    const defaultSearchValues = {
        page: 1,
        status: 'open',
        searchQuery: ''
    }

    const [ticketsData, setTicketsData] = useState(null)
    const [updatedTickets, setUpdatedTickets] = useState(null)
    const [gettingAllTickets, setGettingAllTickets] = useState(false)
    const [updating, setUpdating] = useState(null)
    const [searchValues, setSearchValues] = useState(defaultSearchValues)

    const { page, searchQuery, status } = searchValues

    const statusEnums = getStatusEnums()

    const getAllTickets = async () => {

        const config = {
            headers: {
                authorization: `Bearer ${localStorage.getItem('PRTK')}`,
            }
        }

        setGettingAllTickets(true)

        try {
            const res = await axios.get(`${url}/tech-support-ticket?page=${page}&searchQuery=${searchQuery}&status=${status}`, config)
            setTicketsData(res.data);


        } catch (error) {
            const msg = error?.response?.data?.msg || 'unexpected error'
            return toast({
                description: msg,
                status: "error",
            });
        } finally {
            setGettingAllTickets(false)
        }
    }


    const updateStatus = async (id, status) => {

        const config = {
            headers: {
                authorization: `Bearer ${localStorage.getItem('PRTK')}`,
            }
        }

        setUpdating(id)

        try {
            const res = await axios.patch(`${url}/tech-support-ticket/${id}?status=${status}`, JSON.stringify({}), config)

            setUpdatedTickets(res.data);


        } catch (error) {
            const msg = error?.response?.data?.msg || 'unexpected error'
            return toast({
                description: msg,
                status: "error",
            });
        } finally {
            setUpdating(null)
        }
    }

    useEffect(() => {
        if (role !== 'Admin' && role !== 'Super Admin') {
            navigate('/')
        } else {
            getAllTickets()
        }

    }, [searchQuery, page, status, updatedTickets])


    return (
        <div style={{ height: '100%' }}>

            <div style={{ borderColor: statusColors[status] }} className={classes.search_container}>
                <RiSearch2Line className={classes.search_icon} />
                <input onChange={(e) => { setSearchValues((prev) => ({ ...prev, searchQuery: e.target.value })) }} placeholder='Search ticket id, user id, email, description, status' type='text' className={classes.search_input} />
            </div>


            <p onClick={() => {
                setSearchValues(defaultSearchValues)
            }} className={classes.title}>
                Refresh
                <MdRefresh
                    className={gettingAllTickets ? classes.rotating : classes.rotating_inactive}

                />
            </p>
            <div className={classes.filtersItemContainer}>
                <p>Filter by:</p>
                {statusEnums?.map((item, index) => (
                    <p onClick={() => {
                        setSearchValues((prev) => ({
                            ...prev, status: item
                        }))
                    }} style={status === item ? { background: statusColors[item], color: '#fff', borderColor: statusColors[item] } : { borderColor: statusColors[item] }} className={classes.itemFilters} key={index}>{item.replaceAll('_', ' ')}</p>
                ))}
            </div>

            {gettingAllTickets ? (
                <div className={classes.loader_con}>
                    <Skeleton width="100%" height="100%" />
                </div>
            ) : (
                ticketsData && ticketsData.tickets.length > 0 ? (
                    <div className={classes.table_container}>

                        <p className={classes.showing}>
                            Showing all {status} tickets: <span>1 - {ticketsData.tickets.length} of {ticketsData.totalCount}</span>
                        </p>

                        <div className={classes.scrollable}>
                            <table className={classes.table}>
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>Full Name</th>
                                        <th>Email</th>
                                        <th>Description</th>
                                        <th>Status</th>
                                        <th>Mark as</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ticketsData.tickets.map((item, index) => (
                                        <tr key={index}>
                                            <td >{index + 1}</td>
                                            <td className={classes.tooltip_container}>
                                                <p className={classes.item_text}>{item.fullName || 'N/A'}</p>
                                                <p className={classes.tooltip}>{item.fullName || 'N/A'}</p>
                                            </td>

                                            <td className={classes.tooltip_container}>
                                                <p className={classes.item_text}>{item.email}</p>
                                                <p className={classes.tooltip}>{item.email}</p>
                                            </td>


                                            <td className={classes.tooltip_container}>
                                                <p className={classes.item_text}>{item.description}</p>
                                                <p className={classes.tooltip}>{item.description}</p>
                                            </td>

                                            <td className={classes.tooltip_container}>
                                                <p style={{ color: statusColors[item.status] }} className={classes.item_text}>{item.status.replaceAll('_', ' ')}</p>
                                                <p className={classes.tooltip}>{item.status}</p>
                                            </td>

                                            <td className={classes.tooltip_container}>
                                                <div className={classes.actionContainer}>

                                                    {
                                                        updating === item._id ?
                                                            <p>updating...</p>
                                                            :
                                                            statusEnums?.map((status, index) => (
                                                                <button disabled={updating} style={{ background: statusColors[status] }} className={classes.action} onClick={() => { updateStatus(item._id, status) }} key={index}>{status.replaceAll('_', ' ')}</button>
                                                            ))
                                                    }
                                                </div>
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className={classes.page_icons_grouped}>
                            <p>Page: <span>{ticketsData.page}</span></p>
                            <div
                                onClick={() => { setSearchValues((prev) => ({ ...prev, page: parseInt(ticketsData.page) - 1 })) }}
                                className={ticketsData.hasPreviousPage ? classes.angle_icon_container : classes.angle_icon_container_inactive}
                            >
                                <FaAngleLeft className={classes.angle_icon} />
                            </div>
                            <div
                                onClick={() => { setSearchValues((prev) => ({ ...prev, page: parseInt(ticketsData.page) + 1 })) }}
                                className={ticketsData.hasNextPage ? classes.angle_icon_container : classes.angle_icon_container_inactive}
                            >
                                <FaAngleRight className={classes.angle_icon} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={classes.empty_container}>
                        <p className={classes.empty_message}>
                            {searchQuery ? `No ${status.replaceAll('_', ' ')} tickets found with "${searchQuery}" keywords` : `There are no ${status.replaceAll('_', ' ')} tickets`}
                        </p>
                    </div>
                )
            )}
        </div>
    )
}
