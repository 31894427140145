import { ChevronLeftIcon } from '@chakra-ui/icons'
import { Avatar, Box, Divider, Flex, IconButton, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner, Text, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FiImage, FiSend } from 'react-icons/fi'
import { TfiAngleLeft } from 'react-icons/tfi'
import { useSelector } from 'react-redux'
import moment from 'moment'
import axios from 'axios'
import axiosInstance from '../../../../../../../../services/api'
import CustomInput from '../../../../../../../../components/customInput/customInput'

const MessageWindow = ({ messageId }) => {
  const navigate = useNavigate();
  const [details, setDetails] = useState([]);
  const [message, setMessage] = useState("")
  const [imageURL, setImageURL] = useState('')
  const [imageToView, setImageToView] = useState('')
  const [loading, setLoading] = useState(true)
  const [loadingi, setLoadingi] = useState(false)
  const [loadingc, setLoadingc] = useState(false)
  const [refetch, setRefetch] = useState("")
  const [modalView, setModalView] = useState('msg')
  const currentUser = useSelector(({ userData }) => userData?.currentUser);
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getMessageDetails = async () => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.get(`messages/${messageId}`)
      setDetails(data?.data)
    } catch (error) {
      // toast({
      //   title: "An Error Occurred",
      //   description: error?.response?.data?.message,
      //   status: "error",
      // });
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getMessageDetails()
  }, [refetch])

  const messageRef = useRef();

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView(
        {
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        })
    }
  }, [refetch]);

  const uploadImages = async (file) => {
    setLoadingi(true)
    setModalView('msg')
    const formData = new FormData();
    formData.append('upload_preset', 'jmrkiyu0');
    formData.append('file', file);

    try {
      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/declutters/image/upload`,
        formData
      );

      if (response.data && response.data.secure_url) {
        setImageURL(response.data.secure_url);
      }
      onOpen()
    } catch (error) {
      console.error('Error uploading image: ', error);
    } finally {
      setLoadingi(false)
    }
  };

  const respond = async (e) => {
    e.preventDefault()
    setLoadingc(true)

    const datax = imageURL !== '' ?
      {
        text: message,
        imageURL
      }
      :
      {
        text: message,
      }

    try {
      const { data } = await axiosInstance.patch(`/messages/${messageId}/respond`, datax)
      setRefetch(Math.random())
      setMessage('')
      setImageURL('')
      onClose()
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
      });
    } finally {
      setLoadingc(false)
    }
  }

  const handleDisplayImage = (image) => {
    setImageToView(image)
    setModalView('view')
    onOpen()
  }

  return (
    <>
    
        {
          // loading ? 
          // <SpinLoader />
          // :
          details?.responses?.map(({ user, userModel, text, imageURL, sentAt, _id }) => (
            <Box key={_id} mb=".9rem">
              <Flex justifyContent={user?._id === currentUser?._id ? "right" : "left"}>
                <Box>
                  <Box p={imageURL ? ".6rem .5rem .6rem .5rem" : ".6rem 1rem .6rem .5rem"} maxW={"250px"} borderRadius={user?._id === currentUser?._id ? "8px 8px 0 8px" : "0 8px 8px 8px"} bg={user?._id === currentUser?._id ? "#FDF3FF" : "#E9F0FF"}>
                    <Text mb=".1rem" fontSize={"12px"} color={"#333"} opacity={".7"}>{user?.role === 'Client' ? `${user?.companyName} (${user?.role})` : user?.role === 'Agency' ? `${user?.name} (${user?.role})` : user?.role?.includes('Subuser') ? `${user?.name} (Sub-Client)` : `${user?.firstName} ${user?.lastName} (${user?.role})`}</Text>
                    {imageURL && <Image onClick={() => handleDisplayImage(imageURL)} cursor={"pointer"} borderRadius={"4px"} my=".3rem" src={imageURL} />}
                    <Text fontSize={"12px"} color={user?._id === currentUser?._id ? "#5F1980" : "#194380"}>{text}</Text>
                  </Box>
                  <Text color="#475467" mt=".05rem" fontSize={"10px"} textAlign={user?._id === currentUser?._id ? "right" : "left"}>{moment(sentAt).calendar()}</Text>
                </Box>
              </Flex>
            </Box>
          ))}
        <Box ref={messageRef} />
      <form onSubmit={respond}>
        <Box mt="1rem" position={"relative"}>
          <CustomInput
            type={"text"}
            required
            borderColor="#667085"
            placeholder={"Type message"}
            onChange={e => setMessage(e.target.value)}
            fontSize=".9rem"
            value={message}
          />
          <IconButton type='submit' bg="#101828" position={"absolute"} zIndex={"9"} color="#FFFFFF" top="35%" right={"2%"} aria-label='Send message' icon={loadingc ? <Spinner /> : <FiSend style={{ transform: "scale(1.2)" }} />} />
          <Box height={"40px"} width={"40px"} cursor={"pointer"} position={"absolute"} zIndex={"9"} top="35%" right={"15%"}>
            <IconButton bg="#fff" cursor={"pointer"} color="#101828" aria-label='Send message' icon={loadingi ? <Spinner /> : <FiImage style={{ transform: "scale(1.7)" }} />} />
            <Input opacity={"0"} cursor={"pointer"} type='file' top="-110%" onChange={e => uploadImages(e.target.files[0])} />
          </Box>
        </Box>
      </form>

      <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mx={".5rem"}>
          <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{modalView === 'msg' ? 'Send Image' : 'View Image'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {modalView === 'msg' ?
              <Box>
                <Image src={imageURL} />
                <Box my="1.5rem" position={"relative"}>
                  <CustomInput
                    type={"text"}
                    required
                    borderColor="#667085"
                    placeholder={"Type message"}
                    onChange={e => setMessage(e.target.value)}
                    fontSize=".9rem"
                    value={message}
                  />
                  <IconButton onClick={respond} bg="#101828" position={"absolute"} zIndex={"9"} color="#FFFFFF" top="35%" right={"2%"} aria-label='Send message' icon={loadingc ? <Spinner /> : <FiSend style={{ transform: "scale(1.2)" }} />} />
                  <Box height={"40px"} width={"40px"} cursor={"pointer"} position={"absolute"} zIndex={"9"} top="35%" right={"15%"}>
                    <IconButton bg="#fff" cursor={"pointer"} color="#101828" aria-label='Send message' icon={loadingi ? <Spinner /> : <FiImage style={{ transform: "scale(1.7)" }} />} />
                    <Input opacity={"0"} cursor={"pointer"} type='file' top="-110%" onChange={e => uploadImages(e.target.files[0])} />
                  </Box>
                </Box>
              </Box>
              :
              <Image mb="1.5rem" src={imageToView} />
            }
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default MessageWindow