import {
  Box,
  Flex,
  Grid,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { VscCircleFilled } from "react-icons/vsc";
import Overview from "../../../../../../../../components/overView/overView";
import axiosInstance from "../../../../../../../../services/api";
import { BiCurrentLocation } from "react-icons/bi";
import { HiArrowNarrowRight } from "react-icons/hi";
import CustomInput from "../../../../../../../../components/customInput/customInput";
import ViewEmployee from "../../../../../../../../components/employeeView/viewEmployee/viewEmployee";
import CustomDrawer from "../../../../../../../../components/customDrawer/customDrawer";
import SpinLoader from "../../../../../../../../components/spinLoader/spinLoader";
import EmptyState from "../../../../../../../../components/emptyState/emptyState";
import moment from "moment";
import ListMap from "../../../../../../../../components/mapView/mapViewList";
import { buildResourceURL } from "../../../../../../../../utils/helper";
import { useSelector } from "react-redux";
import CustomSelect from "../../../../../../../../components/customSelect/customSelect";
import CustomTabs from "../../../../../../../../components/customTabs/customTabs";
import { ArrowRightIcon, ChevronRightIcon } from "@chakra-ui/icons";
import ClockOutRationale from "./clockOutRationale";

const Attendance = ({initiativeStore}) => {
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const [routes, setRoutes] = useState([]);

  const currentUser = useSelector(({ userData }) => userData?.currentUser);

  const [recordsP, setRecordsP] = useState([]);
  const [recordsS, setRecordsS] = useState([]);

  const [dayRecordsP, setDayRecordsP] = useState([]);
  const [dayRecordsS, setDayRecordsS] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [dataToView, setDataToView] = useState(null)

  const [routeView, setRouteView] = useState("");
  const [user, setUser] = useState("");

  const [date, setDate] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const arr = [...Array(31).keys()];

  const { sid } = useParams();

  const today = new Date();
  const day = today.getDate();
  const monthx =
    (today.getMonth() + 1)?.toString()?.length === 1
      ? `0${today.getMonth() + 1}`
      : today.getMonth() + 1;
  const year = today.getFullYear();

  const [dateToViewP, setDateToViewP] = useState("");
  const [dateToViewS, setDateToViewS] = useState("");

  const months = [
    {
      title: `January ${year}`,
      value: "01",
    },
    {
      title: `February ${year}`,
      value: "02",
    },
    {
      title: `March ${year}`,
      value: "03",
    },
    {
      title: `April ${year}`,
      value: "04",
    },
    {
      title: `May ${year}`,
      value: "05",
    },
    {
      title: `June ${year}`,
      value: "06",
    },
    {
      title: `July ${year}`,
      value: "07",
    },
    {
      title: `August ${year}`,
      value: "08",
    },
    {
      title: `September ${year}`,
      value: "09",
    },
    {
      title: `October ${year}`,
      value: "10",
    },
    {
      title: `November ${year}`,
      value: "11",
    },
    {
      title: `December ${year}`,
      value: "12",
    },
  ];

  const [month, setMonth] = useState(monthx);

  const queryParamsP = [
    {
        key: 'initiativeStore',
        value: sid
    },
    {
        key: 'user',
        value: initiativeStore?.promoter?._id
    },
]
  

  const getAttendanceRecordsP = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(buildResourceURL('attendances', queryParamsP));
      setRecordsP(data?.data);
    } catch (error) {
      console.log(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const queryParamsS = [
    {
        key: 'initiativeStore',
        value: sid
    },
    {
        key: 'user',
        value: initiativeStore?.supervisor?._id
    }
]

  const getAttendanceRecordsS = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(buildResourceURL('attendances', queryParamsS));
      setRecordsS(data?.data);
    } catch (error) {
      console.log(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAttendanceRecordsP();
    getAttendanceRecordsS();
  }, []);

  const queryParamsPx = [
    {
        key: 'initiativeStore',
        value: sid
    },
    {
        key: 'user',
        value: initiativeStore?.promoter?._id
    },
    {
        key: 'clockInDate',
        value: dateToViewP
    }
]

  const getSingleAttendanceP = async () => {
    setLoadings(true);
    try {
      const { data } = await axiosInstance.get(buildResourceURL('attendances', queryParamsPx));
      setDayRecordsP(data?.data);
      // console.log(`single data for ${dateToView}`, data?.data)
    } catch (error) {
      console.log(error?.response?.data?.message);
    } finally {
      setLoadings(false);
    }
  };

  useEffect(() => {
    getSingleAttendanceP();
  }, [dateToViewP]);

  const queryParamsSx = [
    {
        key: 'initiativeStore',
        value: sid
    },
    {
        key: 'user',
        value: initiativeStore?.supervisor?._id
    },
    {
        key: 'clockInDate',
        value: dateToViewS
    }
]

  const getSingleAttendanceS = async () => {
    setLoadings(true);
    try {
      const { data } = await axiosInstance.get(buildResourceURL('attendances', queryParamsSx));
      setDayRecordsS(data?.data);
      // console.log(`single data for ${dateToView}`, data?.data)
    } catch (error) {
      console.log(error?.response?.data?.message);
    } finally {
      setLoadings(false);
    }
  };

  useEffect(() => {
    getSingleAttendanceS();
  }, [dateToViewS]);

  const queryParams = [
    {
      key: "user",
      value: user,
    },
    {
      key: "date",
      value: date,
    },
    {
      key: "date[gte]",
      value: startDate,
    },
    {
      key: "date[lte]",
      value: endDate,
    },
  ];

  const getRoutes = async () => {
    setLoadingx(true);
    try {
      const { data } = await axiosInstance.get(
        buildResourceURL("route-trackers", queryParams)
      );
      setRoutes(data?.data);
      console.log(data?.data);
    } catch (error) {
      console.log(error?.response?.data?.message);
    } finally {
      setLoadingx(false);
    }
  };

  useEffect(() => {
    getRoutes();
  }, [date, initiativeStore, user, startDate, endDate]);



  const data = [
    {
      title: "Days worked",
      value: recordsP?.filter((i) => i?.hoursWorked >= 8)?.length,
    },
    {
      title: "Hours worked",
      value: recordsP
        ?.reduce(
          (acc, curr) =>
            acc +
            (curr?.hoursWorked < 1
              ? curr?.hoursWorked * -1
              : curr?.hoursWorked),
          0
        )
        ?.toFixed(2),
    },
    {
      title: "% Attendance",
      value: (
        (recordsP?.reduce(
          (acc, curr) =>
            acc +
            (curr?.hoursWorked < 1
              ? curr?.hoursWorked * -1
              : curr?.hoursWorked),
          0
        ) /
          240) *
        100
      )?.toFixed(2),
    },
    {
      title: "Avg daily hours",
      value: (
        recordsP?.reduce(
          (acc, curr) =>
            acc +
            (curr?.hoursWorked < 1
              ? curr?.hoursWorked * -1
              : curr?.hoursWorked),
          0
        ) / day
      )?.toFixed(2),
    },
  ];


  const coords = routes?.map((i) => ({
    coordinates: [i?.longitude, i?.latitude]
  }));

  const handleOpenModal = (val, t) => {
    setRouteView(t);
    setUser(val);
    onOpen();
  };


  return (
    <Box>
      <Box>
        <Flex alignItems={"center"} gap="1rem">
          {/* <CustomSelect 
                placeholder={"Store"}
                selection={store}
                setSelection={setStore}
                options={options1}
                mb=".8rem"
                height="38px"
                width="90px"
                fontSize=".9rem"
                borderColor="#667085 !important"
                /> */}

          <CustomSelect
            selection={month}
            setSelection={setMonth}
            options={[
              months?.filter((i) => i.value === monthx?.toString())[0],
              ...months?.filter((i) => i.value !== monthx?.toString()),
            ]}
            mb=".8rem"
            height="38px"
            width="170px"
            fontSize=".9rem"
          />

          {/* months?.filter(i => i.value === (monthx?.toString()))[0],
                ...months?.filter(i => i.value !== (monthx?.toString())) */}
        </Flex>
        <Overview data={data} />
        <Box mt="1.5rem">
          <Heading
            fontWeight={500}
            mt="1.5rem"
            fontSize="1.1rem"
            mb="1rem"
            color="#475467"
            fontFamily="Roboto"
          >
            Attendance records
          </Heading>


          <CustomTabs
          data={[
            {
              title: "Promoter",
              render: 
              <Flex gap="5rem">
            <Box flex={"1 1 350px"}>
              <Flex justifyContent={"flex-end"} mb=".5rem">
                <Flex gap="1rem">
                  <Text fontSize={"10px"} color="#667085">
                    <VscCircleFilled
                      style={{
                        display: "inline",
                        transform: "translateY(1.5px) scale(1.4)",
                      }}
                      color="#348E3D"
                    />{" "}
                    Days Attended
                  </Text>
                  <Text fontSize={"10px"} color="#667085">
                    <VscCircleFilled
                      style={{
                        display: "inline",
                        transform: "translateY(1.5px) scale(1.4)",
                      }}
                      color="goldenrod"
                    />{" "}
                    Days Clocked In
                  </Text>
                  <Text fontSize={"10px"} color="#667085">
                    <VscCircleFilled
                      style={{
                        display: "inline",
                        transform: "translateY(1.5px) scale(1.4)",
                      }}
                      color="#FF9089"
                    />{" "}
                    Days Missed
                  </Text>
                  <Text fontSize={"10px"} color="#667085">
                    <VscCircleFilled
                      style={{
                        display: "inline",
                        transform: "translateY(1.5px) scale(1.4)",
                      }}
                      color="#6941C6"
                    />{" "}
                    Day in progress
                  </Text>
                </Flex>
              </Flex>

              {loading ? (
                <SpinLoader />
              ) : (
                <Grid
                  width={"100%"}
                  templateColumns={[
                    "repeat(auto-fill, minmax(50px, 1fr))",
                    "repeat(auto-fill, minmax(50px, 1fr))",
                  ]}
                  gap={".7rem"}
                >
                  {arr?.map((i) => {
                    return (
                      <CustomDrawer
                        key={i}
                        position={"right"}
                        title={`Attendance for ${i + 1}/${
                          month || monthx
                        }/${year}`}
                        triggerClose={null}
                        setTriggerClose={() => {}}
                        toggleElement={
                          <Flex
                            onClick={() =>
                              setDateToViewP(
                                `${year}-${
                                  (month?.toString() || monthx?.toString())
                                    ?.length === 1
                                    ? `0${month || monthx}`
                                    : month || monthx
                                }-${
                                  (i + 1)?.toString()?.length === 1
                                    ? `0${i + 1}`
                                    : i + 1
                                }`
                              )
                            }
                            cursor={"pointer"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            height={"50px"}
                            width={"50px"}
                            borderRadius={"4px"}
                            bg={
                              day === i + 1
                                ? "#6941C6"
                                : i + 1 > day && monthx <= parseInt(month)
                                ? "#D0D5DD"
                                : recordsP
                                    ?.filter(
                                      (v) =>
                                        ((parseInt(v?.clockInDate?.slice(-2)) ===
                                        i + 1) && (v?.clockInDate ===
                                          `${year}-${
                                            month?.toString().length === 1
                                              ? `0${month}`
                                              : month
                                          }-${
                                            (i + 1)?.toString()?.length === 1
                                              ? `0${i + 1}`
                                              : i + 1
                                          }`))
                                    )
                                    ?.reduce(
                                      (acc, curr) => acc + curr?.hoursWorked,
                                      0
                                    ) >= 8
                                ? "#348E3D"
                                : recordsP?.filter(
                                    (v) =>
                                      v?.clockInDate ===
                                      `${year}-${
                                        month?.toString().length === 1
                                          ? `0${month}`
                                          : month
                                      }-${
                                        (i + 1)?.toString()?.length === 1
                                          ? `0${i + 1}`
                                          : i + 1
                                      }`
                                  )?.length > 0
                                ? "#DAA520"
                                : "#FF9089"
                            }
                          >
                            <Text
                              color="#FCFCFD"
                              fontSize={".9rem"}
                              fontWeight={"600"}
                            >
                              {i + 1}
                            </Text>
                            {/* <Text color="#FCFCFD" fontSize={".9rem"} fontWeight={"600"}>{`${year}-${month?.toString().length === 1 ? `0${month}` : month}-${(i + 1)?.toString()?.length === 1 ? `0${i + 1}` : (i + 1)}`}</Text> */}
                          </Flex>
                        }
                        content={
                          <>
                            {loadings ? (
                              <SpinLoader />
                            ) : dayRecordsP?.length < 1 ? (
                              <EmptyState
                                mt="4rem"
                                text="No Clock-In records found for this day"
                              />
                            ) : (
                              <Box>
                                <Flex
                                  justifyContent="flex-end"
                                  mt="1rem"
                                  mb=".3rem"
                                >
                                  <Text
                                    fontWeight={"500"}
                                    color={"#98A2B3"}
                                    fontSize={".95rem"}
                                  >
                                    Total Hours Worked:{" "}
                                    <span style={{ color: "#667085" }}>
                                      {dayRecordsP
                                        ?.reduce(
                                          (acc, curr) =>
                                            acc + curr?.hoursWorked,
                                          0
                                        )
                                        ?.toFixed(2)}
                                    </span>
                                  </Text>
                                </Flex>
                                {dayRecordsP?.map(
                                  ({
                                    _id: id,
                                    clockInTime,
                                    clockOutTime,
                                    hoursWorked,
                                    locations,
                                    geofenceEvents
                                  }) => (
                                    <Box
                                      key={id}
                                      p=".7rem 1rem"
                                      mb=".8rem"
                                      borderRadius={"10px"}
                                      border=".5px solid #D0D5DD"
                                    >
                                      <Flex
                                        width={"90%"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                      >
                                        <Box>
                                          <Text
                                            mb=".2rem"
                                            fontWeight={"500"}
                                            color={"#667085"}
                                            fontSize={".9rem"}
                                          >
                                            Clock In
                                          </Text>
                                          <Text
                                            fontWeight={"500"}
                                            color={"#98A2B3"}
                                            fontSize={".83rem"}
                                          >
                                            {moment(
                                              clockInTime,
                                              "YYYY-MM-DD HH:mm:ss"
                                            ).format("h:mm A")}
                                          </Text>
                                        </Box>
                                        <Box>
                                          <Text
                                            mb=".2rem"
                                            fontWeight={"500"}
                                            color={"#667085"}
                                            fontSize={".9rem"}
                                          >
                                            Clock Out
                                          </Text>
                                          <Text
                                            fontWeight={"500"}
                                            color={"#98A2B3"}
                                            fontSize={".83rem"}
                                          >
                                            {moment(
                                              clockOutTime,
                                              "YYYY-MM-DD HH:mm:ss"
                                            ).format("h:mm A") || "--"}
                                          </Text>
                                        </Box>
                                        <Box>
                                          <Text
                                            mb=".2rem"
                                            fontWeight={"500"}
                                            color={"#667085"}
                                            fontSize={".9rem"}
                                          >
                                            Hours Worked
                                          </Text>
                                          <Text
                                            fontWeight={"500"}
                                            color={"#98A2B3"}
                                            fontSize={".83rem"}
                                          >
                                            {hoursWorked || "--"}
                                          </Text>
                                        </Box>
                                      </Flex>

                                      <Flex mt="1rem" justifyContent={'space-between'} alignItems={'center'}>
                                        <Text
                                          width={"fit-content"}
                                          fontSize={"12px"}
                                          fontWeight={"500"}
                                          borderRadius={"20px"}
                                          padding={".2rem .8rem .3rem .8rem"}
                                          bg={
                                            clockOutTime === null
                                              ? "#FFF2DB"
                                              : "#E0FFDB"
                                          }
                                          color={
                                            clockOutTime === null
                                              ? "#EC913C"
                                              : "#248C41"
                                          }
                                          textTransform={"capitalize"}
                                        >
                                          {clockOutTime === null
                                            ? "Pending"
                                            : "Done"}
                                        </Text>
                                        <CustomDrawer 
                                        position={"right"}
                                        title={'Clock-out Rationale'}
                                        triggerClose={''}
                                        setTriggerClose={() => {}}
                                        toggleElement={
                                          <Text onClick={() => setDataToView({locations, geofenceEvents, clockInTime, clockOutTime, hoursWorked})} textDecoration={'underline'} cursor={"pointer"} fontSize={'.87rem'}>Review <ChevronRightIcon /></Text>
                                        }
                                        content={
                                            <ClockOutRationale data={{locations, geofenceEvents, clockInTime, clockOutTime, hoursWorked}} />
                                        }
                                        />
                                      </Flex>
                                    </Box>
                                  )
                                )}
                              </Box>
                            )}
                          </>
                        }
                      />
                    );
                  })}
                </Grid>
              )}
              <Flex
                mt="1.5rem"
                onClick={() =>
                  handleOpenModal(initiativeStore?.promoter?._id, "Promoter's")
                }
                cursor={"pointer"}
                p="1rem"
                borderRadius={"8px"}
                alignItems={"center"}
                justifyContent={"space-between"}
                bg="#E1FFD3"
              >
                <Flex alignItems={"center"} gap=".5rem">
                  <BiCurrentLocation color="#039855" />
                  <Text fontWeight={"500"} fontSize={".95rem"} color="#027A48">
                    Track promoter's daily routes{" "}
                  </Text>
                </Flex>
                <HiArrowNarrowRight />
              </Flex>
              <Flex
                mt=".5rem"
                onClick={() =>
                  handleOpenModal(
                    initiativeStore?.supervisor?._id,
                    "Supervisor's"
                  )
                }
                cursor={"pointer"}
                p="1rem"
                borderRadius={"8px"}
                alignItems={"center"}
                justifyContent={"space-between"}
                bg="#EBF3FF"
              >
                <Flex alignItems={"center"} gap=".5rem">
                  <BiCurrentLocation color="#1671D9" />
                  <Text fontWeight={"500"} fontSize={".95rem"} color="#1671D9">
                    Track supervisor's daily routes{" "}
                  </Text>
                </Flex>
                <HiArrowNarrowRight />
              </Flex>
            </Box>
            <Box flex={"2 1 350px"} pr="5rem">
              {currentUser?.role?.includes("Admin") && (
                <ViewEmployee
                  type="ns"
                  title={"Promoter"}
                  data={initiativeStore?.promoter}
                />
              )}
            </Box>
          </Flex>
            },
            {
              title: "Supervisor",
              render: 
              <Flex gap="5rem">
            <Box flex={"1 1 350px"}>
              <Flex justifyContent={"flex-end"} mb=".5rem">
                <Flex gap="1rem">
                  <Text fontSize={"10px"} color="#667085">
                    <VscCircleFilled
                      style={{
                        display: "inline",
                        transform: "translateY(1.5px) scale(1.4)",
                      }}
                      color="#348E3D"
                    />{" "}
                    Days Attended
                  </Text>
                  <Text fontSize={"10px"} color="#667085">
                    <VscCircleFilled
                      style={{
                        display: "inline",
                        transform: "translateY(1.5px) scale(1.4)",
                      }}
                      color="goldenrod"
                    />{" "}
                    Days Clocked In
                  </Text>
                  <Text fontSize={"10px"} color="#667085">
                    <VscCircleFilled
                      style={{
                        display: "inline",
                        transform: "translateY(1.5px) scale(1.4)",
                      }}
                      color="#FF9089"
                    />{" "}
                    Days Missed
                  </Text>
                  <Text fontSize={"10px"} color="#667085">
                    <VscCircleFilled
                      style={{
                        display: "inline",
                        transform: "translateY(1.5px) scale(1.4)",
                      }}
                      color="#6941C6"
                    />{" "}
                    Day in progress
                  </Text>
                </Flex>
              </Flex>

              {loading ? (
                <SpinLoader />
              ) : (
                <Grid
                  width={"100%"}
                  templateColumns={[
                    "repeat(auto-fill, minmax(50px, 1fr))",
                    "repeat(auto-fill, minmax(50px, 1fr))",
                  ]}
                  gap={".7rem"}
                >
                  {arr?.map((i) => {
                    return (
                      <CustomDrawer
                        key={i}
                        position={"right"}
                        title={`Attendance for ${i + 1}/${
                          month || monthx
                        }/${year}`}
                        triggerClose={null}
                        setTriggerClose={() => {}}
                        toggleElement={
                          <Flex
                            onClick={() =>
                              setDateToViewS(
                                `${year}-${
                                  (month?.toString() || monthx?.toString())
                                    ?.length === 1
                                    ? `0${month || monthx}`
                                    : month || monthx
                                }-${
                                  (i + 1)?.toString()?.length === 1
                                    ? `0${i + 1}`
                                    : i + 1
                                }`
                              )
                            }
                            cursor={"pointer"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            height={"50px"}
                            width={"50px"}
                            borderRadius={"4px"}
                            bg={
                              day === i + 1
                                ? "#6941C6"
                                : i + 1 > day && monthx <= parseInt(month)
                                ? "#D0D5DD"
                                : recordsS
                                    ?.filter(
                                      (v) =>
                                        ((parseInt(v?.clockInDate?.slice(-2)) ===
                                        i + 1) && (v?.clockInDate ===
                                          `${year}-${
                                            month?.toString().length === 1
                                              ? `0${month}`
                                              : month
                                          }-${
                                            (i + 1)?.toString()?.length === 1
                                              ? `0${i + 1}`
                                              : i + 1
                                          }`))
                                    )
                                    ?.reduce(
                                      (acc, curr) => acc + curr?.hoursWorked,
                                      0
                                    ) >= 8
                                ? "#348E3D"
                                : recordsS?.filter(
                                    (v) =>
                                      v?.clockInDate ===
                                      `${year}-${
                                        month?.toString().length === 1
                                          ? `0${month}`
                                          : month
                                      }-${
                                        (i + 1)?.toString()?.length === 1
                                          ? `0${i + 1}`
                                          : i + 1
                                      }`
                                  )?.length > 0
                                ? "#DAA520"
                                : "#FF9089"
                            }
                          >
                            <Text
                              color="#FCFCFD"
                              fontSize={".9rem"}
                              fontWeight={"600"}
                            >
                              {i + 1}
                            </Text>
                            {/* <Text color="#FCFCFD" fontSize={".9rem"} fontWeight={"600"}>{`${year}-${month?.toString().length === 1 ? `0${month}` : month}-${(i + 1)?.toString()?.length === 1 ? `0${i + 1}` : (i + 1)}`}</Text> */}
                          </Flex>
                        }
                        content={
                          <>
                            {loadings ? (
                              <SpinLoader />
                            ) : dayRecordsS?.length < 1 ? (
                              <EmptyState
                                mt="4rem"
                                text="No Clock-In records found for this day"
                              />
                            ) : (
                              <Box>
                                <Flex
                                  justifyContent="flex-end"
                                  mt="1rem"
                                  mb=".3rem"
                                >
                                  <Text
                                    fontWeight={"500"}
                                    color={"#98A2B3"}
                                    fontSize={".95rem"}
                                  >
                                    Total Hours Worked:{" "}
                                    <span style={{ color: "#667085" }}>
                                      {dayRecordsS
                                        ?.reduce(
                                          (acc, curr) =>
                                            acc + curr?.hoursWorked,
                                          0
                                        )
                                        ?.toFixed(2)}
                                    </span>
                                  </Text>
                                </Flex>
                                {dayRecordsS?.map(
                                  ({
                                    _id: id,
                                    clockInTime,
                                    clockOutTime,
                                    hoursWorked,
                                    locations,
                                    geofenceEvents
                                  }) => (
                                    <Box
                                      key={id}
                                      p=".7rem 1rem"
                                      mb=".8rem"
                                      borderRadius={"10px"}
                                      border=".5px solid #D0D5DD"
                                    >
                                      <Flex
                                        width={"90%"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                      >
                                        <Box>
                                          <Text
                                            mb=".2rem"
                                            fontWeight={"500"}
                                            color={"#667085"}
                                            fontSize={".9rem"}
                                          >
                                            Clock In
                                          </Text>
                                          <Text
                                            fontWeight={"500"}
                                            color={"#98A2B3"}
                                            fontSize={".83rem"}
                                          >
                                            {moment(
                                              clockInTime,
                                              "YYYY-MM-DD HH:mm:ss"
                                            ).format("h:mm A")}
                                          </Text>
                                        </Box>
                                        <Box>
                                          <Text
                                            mb=".2rem"
                                            fontWeight={"500"}
                                            color={"#667085"}
                                            fontSize={".9rem"}
                                          >
                                            Clock Out
                                          </Text>
                                          <Text
                                            fontWeight={"500"}
                                            color={"#98A2B3"}
                                            fontSize={".83rem"}
                                          >
                                            {moment(
                                              clockOutTime,
                                              "YYYY-MM-DD HH:mm:ss"
                                            ).format("h:mm A") || "--"}
                                          </Text>
                                        </Box>
                                        <Box>
                                          <Text
                                            mb=".2rem"
                                            fontWeight={"500"}
                                            color={"#667085"}
                                            fontSize={".9rem"}
                                          >
                                            Hours Worked
                                          </Text>
                                          <Text
                                            fontWeight={"500"}
                                            color={"#98A2B3"}
                                            fontSize={".83rem"}
                                          >
                                            {hoursWorked || "--"}
                                          </Text>
                                        </Box>
                                      </Flex>
                                      <Flex mt="1rem" justifyContent={'space-between'} alignItems={'center'}>
                                        <Text
                                          width={"fit-content"}
                                          fontSize={"12px"}
                                          fontWeight={"500"}
                                          borderRadius={"20px"}
                                          padding={".2rem .8rem .3rem .8rem"}
                                          bg={
                                            clockOutTime === null
                                              ? "#FFF2DB"
                                              : "#E0FFDB"
                                          }
                                          color={
                                            clockOutTime === null
                                              ? "#EC913C"
                                              : "#248C41"
                                          }
                                          textTransform={"capitalize"}
                                        >
                                          {clockOutTime === null
                                            ? "Pending"
                                            : "Done"}
                                        </Text>
                                        <CustomDrawer 
                                        position={"right"}
                                        title={'Clock-out Rationale'}
                                        triggerClose={''}
                                        setTriggerClose={() => {}}
                                        toggleElement={
                                          <Text onClick={() => setDataToView({locations, geofenceEvents, clockInTime, clockOutTime, hoursWorked})} textDecoration={'underline'} cursor={"pointer"} fontSize={'.87rem'}>Review <ChevronRightIcon /></Text>
                                        }
                                        content={
                                            <ClockOutRationale data={{locations, geofenceEvents, clockInTime, clockOutTime, hoursWorked}} />
                                        }
                                        />
                                      </Flex>
                                    </Box>
                                  )
                                )}
                              </Box>
                            )}
                          </>
                        }
                      />
                    );
                  })}
                </Grid>
              )}
              <Flex
                mt="1.5rem"
                onClick={() =>
                  handleOpenModal(initiativeStore?.promoter?._id, "Promoter's")
                }
                cursor={"pointer"}
                p="1rem"
                borderRadius={"8px"}
                alignItems={"center"}
                justifyContent={"space-between"}
                bg="#E1FFD3"
              >
                <Flex alignItems={"center"} gap=".5rem">
                  <BiCurrentLocation color="#039855" />
                  <Text fontWeight={"500"} fontSize={".95rem"} color="#027A48">
                    Track promoter's daily routes{" "}
                  </Text>
                </Flex>
                <HiArrowNarrowRight />
              </Flex>
              <Flex
                mt=".5rem"
                onClick={() =>
                  handleOpenModal(
                    initiativeStore?.supervisor?._id,
                    "Supervisor's"
                  )
                }
                cursor={"pointer"}
                p="1rem"
                borderRadius={"8px"}
                alignItems={"center"}
                justifyContent={"space-between"}
                bg="#EBF3FF"
              >
                <Flex alignItems={"center"} gap=".5rem">
                  <BiCurrentLocation color="#1671D9" />
                  <Text fontWeight={"500"} fontSize={".95rem"} color="#1671D9">
                    Track supervisor's daily routes{" "}
                  </Text>
                </Flex>
                <HiArrowNarrowRight />
              </Flex>
            </Box>
            <Box flex={"2 1 350px"} pr="5rem">
              {currentUser?.role?.includes("Admin") && (
                <ViewEmployee
                  type="ns"
                  title={"Promoter"}
                  data={initiativeStore?.promoter}
                />
              )}
            </Box>
          </Flex>
       
            }
          ]}
          />









           </Box>
      </Box>

      <Modal
        size={"4xl"}
        isCentered
        motionPreset="slideInBottom"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent borderRadius={"12px"}>
          <ModalHeader
            fontSize={"1rem"}
            textTransform={"capitalize"}
          ></ModalHeader>
          <ModalCloseButton
            border="2px solid #292D32"
            borderRadius={"50%"}
            transform={"scale(.8) translateY(.5rem)"}
            fontWeight={"700"}
          />
          <ModalBody>
            <Text
              whiteSpace={"nowrap"}
              fontWeight={"500"}
              fontSize={"1.15rem"}
              color={"#27272A"}
            >
              {routeView} route tracker
            </Text>
            <Flex justifyContent={"flex-end"}>
              <Flex alignItems={"center"} gap={'.5rem'}>
                <CustomInput
                  width="150px"
                  type={"date"}
                  mt="0 !important"
                  py=".5rem !important"
                  required
                  placeholder={"Choose Date"}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                -
                <CustomInput
                  width="150px"
                  type={"date"}
                  mt="0 !important"
                  py=".5rem !important"
                  required
                  placeholder={"Choose Date"}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Flex>
            </Flex>

            {loadingx ? (
              <SpinLoader mb="6rem" />
            ) : coords?.length < 1 ? (
              <Box pt="3rem" pb="6rem">
                <EmptyState
                  text={
                    "No Location, Check-in or attendance records for this period"
                  }
                />
              </Box>
            ) : (
              <Flex gap="1rem" my="1rem">
                {/* <Box height={"500px"} flex={"1 1 200px"}>
                  <Text
                    whiteSpace={"nowrap"}
                    fontWeight={"500"}
                    fontSize={"1.1rem"}
                    color={"#475467"}
                  >
                    Activity Log
                  </Text>
                </Box> */}
                <ListMap coordinates={coords} height={"500px"} />
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Attendance;
