import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
// import 'react-leaflet-markercluster/dist/styles.min.css'; // Add marker cluster styles
import MarkerClusterGroup from 'react-leaflet-markercluster';
import L from 'leaflet';
import { Loader } from 'components/Loader/loader';
import axiosInstance from 'services/api';
import { buildResourceURL } from 'utils/helper';
import { useParams } from 'react-router-dom';
import classes from './mapWithMarkers.module.css'

// Fix Leaflet icon issues with React
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const handleMarkerClick = (latitude, longitude) => {
  console.log(`Marker clicked at Latitude: ${latitude}, Longitude: ${longitude}`);
};
// longitude

const MapWithMarkers = ({ responses, singleMarker }) => {


  let coordinates = []
  if (responses) {
    coordinates = responses.map((item) => ({
      longitude: item?.location?.coordinates[0],
      latitude: item?.location?.coordinates[1]
    })) || []
  } else if (Object.keys(singleMarker).length) {
    coordinates = [{
      longitude: singleMarker?.longitude,
      latitude: singleMarker?.latitude,
    }]
  }

console.log(coordinates);


  return (
    <div className={classes.container}>

      <MapContainer
        center={[9.0820, 8.6753]} // Centered on Nigeria
        zoom={6} // Set a reasonable zoom level for Nigeria
        style={{ height: '400px', width: '100%' }}
        maxBounds={[[4.2700, 2.6769], [13.8920, 14.6780]]} // Nigeria bounds
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <MarkerClusterGroup>
          {coordinates.map((coord, index) => (
            coord.latitude && coord.longitude ? ( // Ensure both latitude and longitude exist
              <Marker
                key={index}
                position={[coord.latitude, coord.longitude]}
                eventHandlers={{
                  click: () => handleMarkerClick(coord.latitude, coord.longitude),
                }}
              >
                <Popup>
                  Latitude: {coord.latitude}, Longitude: {coord.longitude}
                </Popup>
              </Marker>
            ) : null
          ))}
        </MarkerClusterGroup>
      </MapContainer>
    </div>
  );
};

export default MapWithMarkers;
