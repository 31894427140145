/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import classes from './surveyAnalytics.module.css'
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import { creators, questionIndices, questions } from 'dummy';
import { numberFormat } from 'helpers/numberFormatter';
import crown from '../../../../../assets/crown.png'
import axios from 'axios';
import moment from 'moment';
import { Loader } from 'components/Loader/loader';

// Register Chart.js components
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const url = process.env.REACT_APP_BASE_URL

export default function SurveyAnalytics() {
    const [startDate, setStartDate] = useState(new Date());
    const [loading, setLoading] = useState(false)
    const [endDate, setEndDate] = useState(new Date());
    const [dateType, setDateType] = useState("daily");
    const [visibleMonth, setVisibleMonth] = useState(new Date()); // Track the visible month
    const [isSelecting, setIsSelecting] = useState(false); // Track if user is selecting a range


    useEffect(() => {
        const today = new Date();

        if (dateType === "daily") {
            setStartDate(today);
            setEndDate(today);
            setVisibleMonth(today); // Set visible month
        } else if (dateType === "weekly") {
            const startOfWeek = new Date(today);
            const dayOfWeek = today.getDay();
            const daysToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
            startOfWeek.setDate(today.getDate() - daysToMonday);

            setStartDate(startOfWeek);

            const endOfWeek = new Date(startOfWeek);
            endOfWeek.setDate(startOfWeek.getDate() + 6);
            setEndDate(endOfWeek);
            setVisibleMonth(startOfWeek); // Set visible month
        } else if (dateType === "monthly") {
            const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            setStartDate(startOfMonth);

            const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
            setEndDate(endOfMonth);
            setVisibleMonth(startOfMonth); // Set visible month

        } else if (dateType === "custom") {
            setStartDate(null); // Reset start and end dates for custom range
            setEndDate(null);
            setVisibleMonth(today);
        }
    }, [dateType]);




    const handleDayClick = (day) => {
        setStartDate(day);

        if (dateType === "weekly") {
            const newEndDate = new Date(day);
            newEndDate.setDate(day.getDate() + 6);
            setEndDate(newEndDate);
        } else if (dateType === "monthly") {
            const newEndDate = new Date(day.getFullYear(), day.getMonth() + 1, 0);
            setEndDate(newEndDate);
        } else if (dateType === "custom") {
            if (!startDate || (startDate && endDate)) {
                // Start new range selection
                setStartDate(day);
                console.log('here')
                setEndDate(null);
                setIsSelecting(true);
            } else {
                console.log('there')
                console.log(day)
                // Set endDate and complete the range
                setEndDate(day >= startDate ? day : startDate); // Ensure valid range
                setStartDate(day < startDate ? day : startDate); // Swap if needed
                setIsSelecting(false);
            }
        } else {
            setEndDate(day);
        }



        setVisibleMonth(day); // Update visible month to selected day
    };

    const handleMonthChange = (month) => {
        setVisibleMonth(month);
    };



    // Get today's day index (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const todayIndex = new Date().getDay(); // Chart.js labels start from Monday


    const options = {
        responsive: true,
        maintainAspectRatio: false, // Disable maintaining the aspect ratio
        plugins: {
            legend: {
                display: false, // Hide legend
            },
        },
        scales: {
            x: {
                grid: {
                    // display: false, // Hide grid lines for the x-axis
                },
                ticks: {
                    color: "#6c757d", // Style x-axis labels
                    font: {
                        size: 12, // Smaller font size for labels
                    },
                },
                border: {
                    // display: false, // Hide the x-axis border
                },
            },
            y: {
                grid: {
                    // drawBorder: false, // Remove y-axis border
                    // display: false, // Completely hide horizontal grid lines
                },
                ticks: {
                    // display: false, // Hide y-axis labels
                },
                border: {
                    // display: false, // Hide the y-axis border
                },
            },
        },
    };


    const formatDate = (dateString) => moment(dateString).format("YYYY-MM-DD");

    const path = window.location.pathname
    const surveyId = path.split('/').pop()


    const [analysis, setAnalysis] = useState(null)
    const questionIndices = analysis?.questionIndices
    const questions = analysis?.analytics?.questions
    const creators = analysis?.analytics?.users
    const dates = analysis?.analytics?.dates
    const datesInDays = dates?.map((item) =>
        new Date(item?.date).toLocaleDateString('en-US', { weekday: 'short' })
    );



    const data = {
        labels: datesInDays,
        datasets: [
            {
                label: "Entries for the day",
                data: dates?.map((item) => (item.count)), // Dummy activity data
                backgroundColor: (ctx) => {
                    const chartLabels = datesInDays;
                    // Adjust today's index to align with Monday starting charts
                    const adjustedTodayIndex = todayIndex === 0 ? 6 : todayIndex - 1;

                    return chartLabels.map((_, index) =>
                        index === adjustedTodayIndex ? "#6F34FF" : "#D5C6FF"
                    )[ctx.dataIndex];
                },
                borderRadius: 2,
                barPercentage: 1,
            },
        ],
    };



    const getAnalytics = async () => {
        const { questionIndex, answer } = filter

        setLoading(true)
        const config = {
            headers: {
                authorization: `Bearer ${localStorage.getItem('PRTK')}`
            }
        }

        try {
            const res = await axios.get(`${url}/survey-responses/analyze/responses?survey=${surveyId}&startDate=${formatDate(startDate)}&endDate=${formatDate(endDate)}&questionIndex=${questionIndex}&answer=${answer}`, config)
            const data = res.data
            setAnalysis(data?.data)
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }
    }


    const defaultFilter = {
        questionIndex: '',
        answer: ''
    }
    const [filter, setFilter] = useState(defaultFilter)
    const [showReset, setShowReset] = useState(false)


    const selectQuestionIndex = (questionIndex) => {
        setFilter({
            questionIndex,
            answer: ''
        })
    }

    const selectAnswerToSearch = (answer) => {
        setFilter((prev) => ({
            ...prev,
            answer
        }))
    }

    const resetFn = () => {
        setShowReset(false)
        setFilter(defaultFilter)
    }

    const searchFn = () => {
        setShowReset(true)
    }

    const disableSearch = !filter?.questionIndex || !filter?.answer

    useEffect(() => {
        if ((dateType === 'custom' && endDate) || (dateType !== 'custom')) {
            getAnalytics()
        }

    }, [surveyId, endDate, showReset])




    return (
        <div className={`${classes.container} ${!analysis?.analytics?.dates?.length ? classes.columnContainer : ''}`}>
            {
                loading ?
                    <div className={classes.loaderCon}>
                        <Loader />
                    </div>
                    :
                    <>
                        {analysis?.analytics?.dates?.length ?
                            <>

                                <div className={classes.firstContainer}>
                                    <div style={{ background: '#fff' }} className={classes.firstFirstContainer}>
                                        <p className={classes.containerTitle}>Activity</p>
                                        <div className={classes.barCon}>
                                            {
                                                dateType === 'monthly' || 'custom' ?
                                                    <p className={classes.barMessage}>Bar Chart unavailable for monthly & custom date type, switch to weekly or daily for comparison</p>
                                                    :
                                                    <Bar data={data} options={options} />
                                            }
                                        </div>

                                    </div>

                                    <div className={classes.firstFirstContainer}>
                                        <p className={classes.containerTitle}>Top 5 Creators</p>

                                        <div className={classes.creatorsGrouped}>
                                            {creators?.sort((a, b) => b.count - a.count).slice(0, 5)?.map((item, index) => (
                                                <div className={classes.creatorContainer} key={index}>
                                                    <div className={classes.creatorImageContainer}>
                                                        <img alt='' className={classes.creatorImage} src={item?.user?.profilePicture || ''} />
                                                    </div>
                                                    <div className={classes.creatorTextContainer}>
                                                        <p className={classes.creatorFullName}>{item?.user?.firstName} {item?.user?.lastName}</p>
                                                        <p className={`${classes.creatorFullName} ${classes.creatorEmail}`}>{item?.user?.email}</p>
                                                    </div>
                                                    <div className={classes.creatorCountContainer}>
                                                        {index === 0 && <img src={crown} alt='' className={classes.crown} />}
                                                        <p className={classes.creatorCount}>{numberFormat?.format(item?.count)}</p> {/* Count */}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                </div>

                                <div className={`${classes.firstContainer} ${classes.secondContainer}`}>
                                    <div >
                                        <div>{

                                            showReset ? <div className={classes.appliedFilterContainer}>
                                                <p className={classes.filterText}> Showing analytics for question:{questionIndices[filter?.questionIndex]} and answer: {filter?.answer}</p>
                                                <button disabled={disableSearch} style={disableSearch ? { opacity: 0.5 } : {}} className={`${classes.searchBtn} ${classes.resetBtn}`} onClick={resetFn}>Reset</button>
                                            </div>
                                                :
                                                <p>Filter</p>
                                        }</div>


                                        {
                                            !showReset &&
                                            <div className={classes.filterContainer}>

                                                <div className={classes.selectContainer} >
                                                    <select className={classes.select} onChange={(e) => { selectQuestionIndex(e.target.value) }}>
                                                        <option value={''}>{questionIndices?.[filter?.questionIndex] || 'Select a question'}</option>
                                                        {
                                                            questionIndices?.map((item, index) => (
                                                                <option value={index} key={index}>{item}</option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>


                                                {
                                                    filter?.questionIndex &&
                                                    <div className={classes.selectContainer} >
                                                        <select className={classes.select} onChange={(e) => { selectAnswerToSearch(e.target.value) }}>
                                                            <option value={''}>{filter?.answer || 'Select answers to search '}</option>
                                                            {
                                                                questions?.[filter?.questionIndex]?.answers
                                                                    ?.sort((a, b) => {
                                                                        // Convert non-string values to strings for comparison
                                                                        const valA = typeof a.value === 'boolean' ? a.value.toString() : String(a.value ?? '');
                                                                        const valB = typeof b.value === 'boolean' ? b.value.toString() : String(b.value ?? '');
                                                                        return valA.localeCompare(valB);
                                                                    })
                                                                    ?.map((item, index) => (
                                                                        <option value={item.value} key={index}>
                                                                            {typeof item.value === 'boolean'
                                                                                ? item.value.toString() // Explicitly handle boolean display
                                                                                : item.value}
                                                                        </option>
                                                                    ))
                                                            }

                                                        </select>
                                                    </div>

                                                }


                                                <button disabled={disableSearch} style={disableSearch ? { opacity: 0.5 } : {}} className={classes.searchBtn} onClick={searchFn}>Search</button>

                                            </div>
                                        }

                                    </div>


                                    <p className={classes.containerTitle}>Analytics of text answers</p>
                                    {
                                        Object.entries(questions)?.map(([key, question], index) => (
                                            <>
                                                {question?.answers && question?.type !== 'url'  && question?.fieldType !== 'tel' && (
                                                    <div>
                                                        <p className={classes.questionTitle}>
                                                            Top 5 answers to "{questionIndices[index]}"
                                                        </p>
                                                        <div className={classes.barCon2}>
                                                            <Bar
                                                                data={{
                                                                    labels: question.answers
                                                                        ?.sort((a, b) => b.count - a.count) // Sort by count in descending order
                                                                        .slice(0, 5) // Take the top 5
                                                                        .map((item) => `${item.value} (${numberFormat.format(item.count || 0)})`), // Add count to label

                                                                    datasets: [
                                                                        {
                                                                            label: "Percentage (%)",
                                                                            data: question.answers
                                                                                ?.sort((a, b) => b.count - a.count) // Sort by count in descending order
                                                                                .slice(0, 5) // Take the top 5
                                                                                .map((item) => {
                                                                                    const total = question.answers.reduce(
                                                                                        (sum, answer) => sum + answer.count,
                                                                                        0
                                                                                    ); // Calculate the total count'
                                                                                    return (item.count / total) * 100; // Convert to percentage
                                                                                }),
                                                                            backgroundColor: (ctx) => {
                                                                                const values = question.answers
                                                                                    ?.sort((a, b) => b.count - a.count) // Sort by count in descending order
                                                                                    .slice(0, 5) // Take the top 5
                                                                                    .map((item) => {
                                                                                        const total = question.answers.reduce(
                                                                                            (sum, answer) => sum + answer.count,
                                                                                            0
                                                                                        ); // Calculate the total count
                                                                                        return (item.count / total) * 100; // Convert to percentage
                                                                                    });

                                                                                const maxValue = Math.max(...values); // Find the maximum percentage
                                                                                const currentValue = values[ctx.dataIndex];

                                                                                // Highlight the highest bar
                                                                                return currentValue === maxValue
                                                                                    ? "#6F34FF"
                                                                                    : "#D5C6FF";
                                                                            },
                                                                            borderRadius: 2,
                                                                            barPercentage: 1,
                                                                        },
                                                                    ],
                                                                }}
                                                                options={options}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        ))
                                    }



                                </div>
                            </>
                            :
                            <p className={classes.noDataFoundText}>No Data found {dateType === 'custom' ? '- Select a start and end date' : `from ${startDate?.toDateString() || ''} to ${endDate?.toDateString() || ''}`} </p>
                        }

                        <div className={`${classes.firstContainer} ${classes.lastContainer}`}>

                            <div>
                                <div className={classes.dateTypePickerContainer}>
                                    <button style={dateType === 'daily' ? { background: '#fff', color: '#000' } : {}} className={classes.btnPicker} onClick={() => setDateType("daily")}>Daily</button>
                                    <button style={dateType === 'weekly' ? { background: '#fff', color: '#000' } : {}} className={classes.btnPicker} onClick={() => setDateType("weekly")}>Weekly</button>
                                    <button style={dateType === 'monthly' ? { background: '#fff', color: '#000' } : {}} className={classes.btnPicker} onClick={() => setDateType("monthly")}>Monthly</button>
                                    <button style={dateType === 'custom' ? { background: '#fff', color: '#000' } : {}} className={classes.btnPicker} onClick={() => setDateType("custom")}>Custom</button>
                                </div>
                            </div>

                            <div className={`${classes.firstFirstContainer} ${classes.third}`}>

                                <p>{dateType === 'custom' ? 'Select a start and end date to fetch a result' : ''}</p>

                                <p className={classes.containerTitle}>Date</p>


                                <DayPicker
                                    selected={startDate}
                                    onDayClick={handleDayClick}
                                    month={visibleMonth} // Control the visible month
                                    onMonthChange={handleMonthChange} // Allow navigation with arrows
                                    modifiers={{
                                        selectedRange: { from: startDate, to: endDate },
                                        selected: startDate,
                                    }}
                                    modifiersStyles={{
                                        selectedRange: {
                                            backgroundColor: "#d4e9fc",
                                            color: "#000",
                                        },
                                        selected: {
                                            backgroundColor: "#000",
                                            color: "#fff",
                                        },
                                    }}
                                />
                            </div>

                            {
                                !loading && analysis?.analytics?.dates?.length ?
                                    <div className={`${classes.firstFirstContainer} ${classes.fourth}`}>
                                        <p className={classes.containerTitle}>Analytics of number answers</p>
                                        <div className={classes.secondContainerCon}>
                                            {Object.entries(questions)?.map(([key, question], index) => {
                                                const lightColors = [
                                                    "#E3F2FD", // Light blue
                                                    "#FFEBEE", // Light red
                                                    "#F1F8E9", // Light green
                                                    "#FFF3E0", // Light orange
                                                    "#EDE7F6", // Light purple
                                                ];
                                                const bgColor = lightColors[index % lightColors.length]; // Cycle through colors

                                                if (question.type === 'number' && question.fieldType !== 'tel') {
                                                    return (
                                                        <>
                                                            <div key={`${key}-sum`} className={classes.gridItem} style={{ backgroundColor: bgColor }}>
                                                                <span className={classes.span}>{question.sum}</span>
                                                                <p className={classes.lab}>Sum</p>
                                                                <div className={classes.arrow}>
                                                                    <div className={classes.question2}>
                                                                        <span></span>
                                                                        {questionIndices[index]}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div key={`${key}-count`} className={classes.gridItem} style={{ backgroundColor: bgColor }}>
                                                                <span className={classes.span}>{question.count}</span>
                                                                <p className={classes.lab}>Count</p>
                                                                <div className={classes.arrow}>
                                                                    <div className={classes.question2}>
                                                                        <span></span>
                                                                        {questionIndices[index]}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div key={`${key}-min`} className={classes.gridItem} style={{ backgroundColor: bgColor }}>
                                                                <span className={classes.span}>{question.min}</span>
                                                                <p className={classes.lab}>Min</p>
                                                                <div className={classes.arrow}>
                                                                    <div className={classes.question2}>
                                                                        <span></span>
                                                                        {questionIndices[index]}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div key={`${key}-max`} className={classes.gridItem} style={{ backgroundColor: bgColor }}>
                                                                <span className={classes.span}>{question.max}</span>
                                                                <p className={classes.lab}>Max</p>
                                                                <div className={classes.arrow}>
                                                                    <div className={classes.question2}>
                                                                        <span></span>
                                                                        {questionIndices[index]}
                                                                        {question.type}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div key={`${key}-average`} className={classes.gridItem} style={{ backgroundColor: bgColor }}>
                                                                <span className={classes.span}>{question.average}</span>
                                                                <p className={classes.lab}>Average</p>
                                                                <div className={classes.arrow}>
                                                                    <div className={classes.question2}>
                                                                        <span></span>
                                                                        {questionIndices[index]}
                                                                        {question.fieldType}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                } 
                                            })}
                                        </div>
                                    </div>
                                    :
                                    <></>
                            }





                        </div>
                    </>
            }








        </div>
    )
}