import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import CustomTable from '../../../../../../../../components/customTable/customTable';
import axiosInstance from '../../../../../../../../services/api';
import EmptyState from '../../../../../../../../components/emptyState/emptyState';
import CustomInput from '../../../../../../../../components/customInput/customInput';
import LineSkeleton from '../../../../../../../../components/lineSkeleton';
import ExportCSV from '../../../../../../../../components/exportCsv';
import CustomSelect from '../../../../../../../../components/customSelect/customSelect';
import { buildResourceURL, formatNumber } from '../../../../../../../../utils/helper';
import statesLgaJson from '../../../../../../../../utils/stateLGAs';
import { constants } from '../../../../../../../../utils/constants';
import classes from './storeInventory.module.css'

const AggregateStoreInventory = ({ data, startDatex, endDatex, regionx, statesx, src }) => {

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    const places = statesLgaJson?.map(i => ({
        title: i?.state,
        value: i?.state?.toLowerCase(),
        listValue: i?.lgas?.map(i => ({
            title: i,
            value: i,
        }))
    }))

    const regionsData = data?.client?.statesFilterGroups || []
    const regionsDataOpts = regionsData?.map(i => ({
        title: i?.name,
        value: i?.states?.join(',')
    }))

    const storeGroupData = data?.initiativeStoresFilterGroups || []
    const storeGroupDataOpts = storeGroupData?.map(i => ({
        title: i?.name,
        value: i?.initiativeStoresIDs?.join(',')
    }))

    const [product, setProduct] = useState("");

    const productGroupData = data?.productsFilterGroups || []
    const productGroupDataOpts = productGroupData?.map(i => ({
        title: i?.name,
        value: i?.products?.join(',')
    }))


    const [loading, setLoading] = useState(false);
    const [docs, setDocs] = useState([]);
    const [refetch, setRefetch] = useState('');
    // const [date, setDate] = useState(formattedDate);
    const [states, setStates] = useState("");
    const [region, setRegion] = useState("");
    const [group, setGroup] = useState("");
    const { iid } = useParams();

    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    const queryParams = [
        {
            key: 'initiativeId',
            value: iid
        },
        {
            key: 'startDate',
            value: startDatex || startDate
        },
        {
            key: 'endDate',
            value: endDatex || endDate
        },
        {
            key: 'states',
            value: statesx || states
        },
        {
            key: 'initiativeStoresFilterGroupId',
            value: group
        },
        {
            key: 'productsFilterGroupId',
            value: product
        },
    ]


    const getDocs = async () => {
        setLoading(true)
        try {
            const res = await axiosInstance.get(buildResourceURL('/store-inventories/aggregate', queryParams))
            setDocs(res?.data?.data)
        } catch (error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getDocs()
    }, [refetch, startDate, endDate, states, startDatex, endDatex, regionx, statesx, group, product])

    const datax = [
        // {
        //     title: "Date",
        //     value: docs?.salesResult?.map(i => docs?.date)
        // },
        {
            title: "Brand",
            value: docs?.map(i => i?.brandName)
        },
        {
            title: "SKU",
            value: docs?.map(i => i?.sku)
        },
        {
            title: "Total Stock Qty",
            value: docs?.map(i => formatNumber(i?.totalStockQty))
        },
        {
            title: "Total Cases",
            value: docs?.map(i => formatNumber(i?.totalCase))
        },
        {
            title: `Total Value (${constants?.currency})`,
            value: docs?.map(i => formatNumber(i?.totalValue))
        },
        {
            title: "Avg Stock Qty",
            value: docs?.map(i => formatNumber(i?.avgStockQty))
        },
        {
            title: "Avg Total Case",
            value: docs?.map(i => formatNumber(i?.avgTotalCase))
        },
        {
            title: `Avg Total Value (${constants?.currency})`,
            value: docs?.map(i => formatNumber(i?.avgTotalValue))
        },
        {
            title: 'Entries',
            value: docs?.map(i => formatNumber(i?.count))
        }
    ]

    const refinedData = docs?.map(i => ({
        brandName: i?.brandName,
        sku: i?.sku,
        total: formatNumber(i?.totalStockQty),
        totalCases: formatNumber(i?.totalCase),
        totalValue: formatNumber(i?.totalValue),
        avgStockQty: formatNumber(i?.avgStockQty),
        avgTotalCase: formatNumber(i?.avgTotalCase),
        avgTotalValue: formatNumber(i?.avgTotalValue),
        count: formatNumber(i?.count),
    }))

    const headers = [
        {
            key: 'brandName',
            name: 'Brand',
        },
        {
            key: 'sku',
            name: 'SKU',
        },
        {
            key: 'total',
            name: 'Total Stock Qty',
        },
        {
            key: 'totalCases',
            name: 'Total Cases',
        },
        {
            key: 'totalValue',
            name: 'Total Value',
        },
        {
            key: 'avgStockQty',
            name: 'Average Stock Qty',
        },
        {
            key: 'avgTotalCase',
            name: 'Total Value',
        },
        {
            key: 'avgTotalValue',
            name: 'Average Total Value',
        },
        {
            key: 'count',
            name: 'Count',
        }
    ]

    return (
        <div className={classes.screenContainer}>
            {src !== 'metrics' &&
                <Flex mb="1.2rem" alignItems={"center"} justifyContent={"space-between"}>
                    <Heading fontWeight={500} fontSize="1.1rem" color="#475467" fontFamily='Roboto'>Store Inventory Aggregate
                        <span style={{ padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054" }}>{docs?.length}</span>
                    </Heading>
                    <Flex alignItems={"center"} gap="1rem" justifyContent={"space-between"}>
                        <ExportCSV headers={headers} items={refinedData} />
                        {/* <Box width={"150px"}>
                <CustomInput
                type={"date"}
                required
                placeholder={"Select Date"}
                onChange={e => setDate(e.target.value)}
                mt="0"
                />
            </Box> */}
                        <CustomSelect
                            selection={region}
                            setSelection={setStates}
                            options={[{ title: "All Regions", value: "" }, ...regionsDataOpts]}
                            height="40px"
                            fontSize=".8rem"
                            width="120px"
                            mt="0"
                            cursor="pointer"
                        />
                        <CustomSelect
                            selection={group}
                            setSelection={setGroup}
                            options={[{ title: "All Groups", value: "" }, ...storeGroupDataOpts]}
                            height="40px"
                            fontSize=".8rem"
                            width="120px"
                            mt="0"
                            cursor="pointer"
                        />
                        <CustomSelect
                            selection={product}
                            setSelection={setProduct}
                            options={[{ title: "All Product Groups", value: "" }, ...productGroupDataOpts]}
                            height="40px"
                            fontSize=".8rem"
                            width="158px"
                            mt="0"
                            cursor="pointer"
                        />
                        <CustomSelect
                            selection={states}
                            setSelection={setStates}
                            options={[{ title: "All States", value: "" }, ...places]}
                            height="40px"
                            fontSize=".8rem"
                            width="120px"
                            mt="0"
                            cursor="pointer"
                        />
                        {/* <Flex gap="1rem" alignItems={"center"}>
                <Box width={"120px"}>
                    <CustomInput
                    type={"date"}
                    required
                    placeholder={"Select Date"}
                    onChange={e => setStartDate(e.target.value)}
                    value={startDate}
                    mt="0"
                    py="1rem !important"
                    />
                </Box>
                -
                <Box width={"120px"}>
                    <CustomInput
                    type={"date"}
                    required
                    placeholder={"Select Date"}
                    onChange={e => setEndDate(e.target.value)}
                    value={endDate}
                    mt="0"
                    py="1rem !important"
                    />
                </Box>
            </Flex> */}
                    </Flex>
                </Flex>}

            {loading ?
                <LineSkeleton num={10} />
                :
                docs?.length < 1 ? <EmptyState text={"No inventory recorded for this date."} />
                    :
                    // date !== "" &&
                    <CustomTable data={datax ?? []} />
            }
        </div>
    )
}

export default AggregateStoreInventory;