import { Box, Checkbox, Divider, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure, useToast } from '@chakra-ui/react'
import CustomInput from '../../../../components/customInput/customInput'
import { useEffect, useState } from 'react'
import CustomSelect from '../../../../components/customSelect/customSelect';
import TagsInput from 'react-tagsinput';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import axiosInstance from '../../../../services/api';
import SpinLoader from '../../../../components/spinLoader/spinLoader';
import EditQuestion from './editQuestion/editQuestion';

const ManageQuestions = ({id, setTriggerClose}) => {
  const [survey, setSurvey] = useState([])
  const [title, setTitle] = useState("");
  const [fieldName, setFieldName] = useState("");
  const [fieldType, setFieldType] = useState("");
  const [isRequired, setIsRequired] = useState("");
  const [fieldOptions, setFieldOptions] = useState([]);
  const [refetch, setRefetch] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = useState(null);
  const [modalType, setModalType] = useState("");

  const toast = useToast()
  
  const [loading, setLoading] = useState(false);
  const [loadingd, setLoadingd] = useState(false);
  const [loadingc, setLoadingc] = useState(false);

  const getSurvey = async() => {
    setLoading(true)
    try {
        const { data } = await axiosInstance.get(`surveys/${id}`)
        setSurvey(data?.data)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
}

useEffect(() => {
    getSurvey()
}, [refetch])

  const handleAddOptions = (option) => {
    setFieldOptions(option)
  }

  const addQuestion = async(e) => {
    e.preventDefault()
    setLoadingc(true)
    const datax = fieldOptions?.length > 0 ? 
    {
      title,
      fieldName,
      fieldType,
      fieldOptions,
      isRequired
    }
    :
    {
      title,
      fieldName,
      fieldType,
      isRequired
    }
    try {
      const {data} = await axiosInstance.post(`/surveys/${id}/questions`, datax)
      toast({
        description: data?.message,
        status: "success",
        position: "top"
      });
      setRefetch(Math.random())
      setFieldName('')
      setFieldType('')
      setTitle('')
      setFieldOptions([])
      setIsRequired(false)
      //setTriggerClose('close' + (Math.random()))
    } catch(error) {
      toast({
        // title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
      });
    } finally {
      setLoadingc(false)
    }
  }

  const deleteSurvey = async() => {
    setLoadingd(true)
    try {
        const { data } = await axiosInstance.delete(`surveys/${id}/questions/${selected?.id}`)
        toast({
        description: data?.message,
        status: "success",
        position: "top"
        });
        setRefetch(Math.random())
        onClose()
    } catch(error) {
        toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
        });
    } finally {
        setLoadingd(false)
    }
  }

  const handleModal = (type, user) => {
    setModalType(type)
    setSelected(user)
    onOpen()
  }

  const fieldTypeOptions = [
    {
        title: "Text",
        value: "text"
    },
    {
        title: "Select",
        value: "select"
    },
    {
        title: "Email",
        value: "email"
    },
    {
        title: "Tel",
        value: "tel"
    },
    {
        title: "Date",
        value: "date"
    },
    {
        title: "Textarea",
        value: "textarea"
    },
    {
        title: "Image",
        value: "image"
    },
    {
        title: "Checkbox",
        value: "checkbox"
    },
    {
        title: "Radio",
        value: "radio"
    },
  ]

  return (
    <>

    <Box mb="2rem">
        <CustomInput
        type={"text"}
        required
        placeholder={"Title"}
        onChange={e => setTitle(e.target.value)}
        value={title}
        />
        <CustomInput
        type={"text"}
        required
        placeholder={"Field Name"}
        onChange={e => setFieldName(e.target.value)}
        mt=".5rem"
        value={fieldName}
        />
        <CustomSelect
        required
        options={fieldTypeOptions}
        value={fieldType}
        placeholder={"Field Type"}
        onChange={e => setFieldType(e.target.value)}
        borderRadius="10px"
        my=".5rem"
        />
        {(fieldType === "select" || fieldType === "checkbox" || fieldType === "radio") && <TagsInput allowUnique={true} value={fieldOptions} className='tag-input' inputProps={{placeholder: "Add Options"}} maxTags={100} onChange={handleAddOptions} />}
        <Flex alignItems={"center"} mt=".7rem" gap=".3rem">
            <Checkbox onChange={(e) => setIsRequired(e.target.checked)} />
            <Text opacity={".8"}>Is Field Required ?</Text>
        </Flex>
        
        <PrimaryButton mt="2rem" type="submit" text="Add Question" onClick={addQuestion} loading={loadingc} />
    </Box>

    <Divider my="2rem" />

    {loading ?
    <SpinLoader />
    :
    survey?.questions?.map(({_id: id, fieldName, fieldOptions, fieldType, isRequired, title}) => {
        const data = {_id: id, fieldName, fieldOptions, fieldType, isRequired, title}
        return (
            <Flex p=".5rem .5rem 1rem .5rem" border="1px solid lightgray" mb=".8rem" borderRadius={"6px"} position={"relative"}>
                <Box>
                    <Text fontSize={".9rem"} fontWeight={"500"} color={"#7F56D9"}>{title}</Text>
                    <Text fontSize={".85rem"}>Field Type: <strong style={{color: "#7F56D9"}}>{fieldType}</strong></Text>
                    <Text fontSize={".85rem"}>Field Name: <strong style={{color: "#7F56D9"}}>{fieldName}</strong></Text>
                    {fieldOptions?.length > 0 && <Text fontSize={".85rem"}>Field Options: <strong style={{color: "#7F56D9"}}>{fieldOptions?.join(', ')}</strong></Text>}
                    <Text fontSize={".85rem"}>Is Field Required: <strong style={{color: "#7F56D9"}}>{isRequired === true ? 'Required' : 'Optional'}</strong></Text>
                </Box>
                <Flex position={"absolute"} bottom={"3%"} right="3%" gap=".5rem">
                    <Text onClick={() => handleModal('edit', data)} cursor={"pointer"} textDecoration={"underline"} fontSize={".85rem"} fontWeight={"500"}>Edit</Text>
                    <Text onClick={() => handleModal('delete', {name: fieldName, id})} cursor={"pointer"} textDecoration={"underline"} fontSize={".85rem"} fontWeight={"500"} color={"crimson"}>Delete</Text>
                </Flex>
            </Flex>
        )
    })}

    <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{`${modalType} Survey Question`}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {modalType === 'delete' &&
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm deletion of {selected?.name}?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                            <PrimaryButton onClick={deleteSurvey} text="Delete" width="150px" bg="crimson !important" padding="1rem !important" loading={loadingd} />
                        </Flex>
                    </>
                    }
                    {modalType === 'edit' && <EditQuestion data={selected} setRefetch={setRefetch} surveyId={id} onClose={onClose} />}
                    <Text pb="1rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Cancel</Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    </>
  )
}

export default ManageQuestions