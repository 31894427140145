/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Checkbox, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, Tooltip, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../../../../../../services/api';
import CustomInput from '../../../../../../../../components/customInput/customInput';
import { buildResourceURL, truncateText } from '../../../../../../../../utils/helper';
import { InitiativePaginationComponent } from '../../../../../../../../components/initiativePaginationComponent/initiativePaginationComponent';
import { InitiativeEmptyState } from '../../../../../../../../components/initiativeEmptyState/initiativeEmptyState';
import { numberFormat } from '../../../../../../../../helpers/numberFormatter';
import { moneyFormat } from '../../../../../../../../helpers/moneyFormatter';
import { formatDateHeader } from '../../../../../../../../helpers/formatDateHeader';
import { defaultSearchValueState } from '../../../../../../../../constants/defaultStates';
import { InitiativeQueryComponent } from '../../../../../../../../components/initiativeQueryComponent/initiativeQueryComponent';
import { Loader } from '../../../../../../../../components/Loader/loader';
import classes from './shipment.module.css'
import UtilityContext from '../../../../../../../../context/utilityContext';
import { FaRegUser } from 'react-icons/fa';
import { MdOutlineInventory2, MdStorefront } from 'react-icons/md';
import defaultImage from './shipment.module.css'
import CustomDrawer from '../../../../../../../../components/customDrawer/customDrawer';
import SecondaryButton from '../../../../../../../../components/secondaryButton/secondarybutton';
import ExportCSV from '../../../../../../../../components/exportCsv';
import CreateShipment from './createShipment';
import PrimaryButton from '../../../../../../../../components/primaryButton/primarybutton';
import moment from 'moment';


const Shipments = ({ data }) => {

  const limit = 200
  const [loading, setLoading] = useState(false);
  const { draw } = useContext(UtilityContext)

  const [docs, setDocs] = useState([])
  const [total, setTotal] = useState('')
  const [refetch, setRefetch] = useState('')
  const { iid, sid } = useParams()


  const [searchValues, setSearchValues] = useState(defaultSearchValueState)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()

  const { page } = searchValues


  const storeGroupData = data?.initiativeStoresFilterGroups || [];
  const regionsData = data?.client?.statesFilterGroups || []
  const productGroupData = data?.productsFilterGroups || [];
  const endDate = new Date(searchValues.endDate);  // Create a new date object based on the current date

  endDate.setDate(endDate.getDate() + 1);

  const options1 = data ? data?.brands?.map(i => ({
    title: `${i?.name}-${i?.sku}`,
    value: JSON.stringify({ brandName: i?.name, sku: i?.sku })
  })) : []



  const storeGroupQuery = storeGroupData.filter(item => item.name.toLowerCase() === searchValues.groups.toLowerCase())[0]?.['initiativeStoresIDs'].map((item) => ({
    key: "initiativeStore[in]",
    value: item
  })) || []

  const productGroupBrandQuery = productGroupData.filter(item => item.name.toLowerCase() === searchValues.products.toLowerCase())[0]?.['products'].map((item) => ({
    key: "brandName[in]",
    value: item?.split("-")[0]
  })) || []

  const productGroupSkuQuery = productGroupData.filter(item => item.name.toLowerCase() === searchValues.products.toLowerCase())[0]?.['products'].map((item) => ({
    key: "brandName[in]",
    value: item?.split("-")[1] // todo this splitting may result to unwanted behavior because e.g if item is pr-k2-20kg where 20kg is the sku, wherever the data is coming should send it as a stringified object then you parse here.
  })) || []

  const queryParams = [
    {
      key: sid ? 'initiativeStore' : 'initiative',
      value: sid ? sid : iid
    },
    {
      key: 'date[gte]',
      value: searchValues.startDate
    },
    {
      key: 'date[lt]',
      value: searchValues?.endDate ? endDate.toISOString().split('T')[0] : ""
    },
    {
      key: "brandName",
      value: JSON.parse(searchValues?.brandName || "{}")?.brandName
    },
    {
      key: 'sku',
      value: JSON.parse(searchValues?.brandName || "{}")?.sku
    },
    {
      key: 'limit',
      value: 20
    },
    {
      key: 'state',
      value: searchValues.states.toLowerCase()
    },
    {
      key: 'initiativeStoresFilterGroupId',
      value: searchValues.groups
    },
    {
      key: 'productsFilterGroupId',
      value: searchValues.products
    },
    {
      key: 'statesFilterGroupId',
      value: searchValues.regions
    },
    {
      key: 'search',
      value: searchValues.searchQuery
    }, {
      key: 'page',
      value: page
    },
  ]


  const queryParamsx = [
    {
      key: sid ? 'initiativeStore' : 'initiative',
      value: sid ? sid : iid
    },
    {
      key: 'date[gte]',
      value: searchValues.startDate
    },
    {
      key: 'date[lt]',
      value: searchValues?.endDate ? endDate.toISOString().split('T')[0] : ""
    },
    {
      key: "brandName",
      value: JSON.parse(searchValues?.brandName || "{}")?.brandName
    },
    {
      key: 'sku',
      value: JSON.parse(searchValues?.brandName || "{}")?.sku
    },
    {
      key: 'state',
      value: searchValues.states.toLowerCase()
    },
    {
      key: 'initiativeStoresFilterGroupId',
      value: searchValues.groups
    },
    {
      key: 'productsFilterGroupId',
      value: searchValues.products
    },
    {
      key: 'statesFilterGroupId',
      value: searchValues.regions
    },
  ]

  const getDocs = async () => {
    setLoading(true)
    try {
      const res = await axiosInstance.get(buildResourceURL('shipments', queryParams))
      setDocs(res?.data?.data)
      setTotal(res?.data?.totalCount)
    } catch (error) {
      console.error(error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDocs()
  }, [searchValues, refetch])


  const [inputPage, setInputPage] = useState(page)
  options1.sort((a, b) => a.title.localeCompare(b.title))

  const [triggerClose, setTriggerClose] = useState('');
  const [searchValx, setSearchValx] = useState("")
  const [datex, setDatex] = useState()
  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleSelect = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add item to the array if it doesn't already exist
      if (!selectedProducts.includes(value)) {
        setSelectedProducts([...selectedProducts, value]);
      }
    } else {
      // Remove item from the array if it exists
      const filteredItems = selectedProducts.filter((item) => item !== value);
      setSelectedProducts(filteredItems);
    }
  }

  const filteredBrands = searchValx?.length > 0 ? data?.brands?.filter(i => i?.name?.toLowerCase().includes(searchValx?.toLowerCase())) : data?.brands


  const headers = [
    {
      key: 'name',
      name: 'Brand',
    },
    {
      key: 'sku',
      name: 'SKU',
    },
    {
      key: 'caseUnitsNumber',
      name: 'Case Units Number',
    },
    {
      key: 'pricePerCase',
      name: 'Price Per Case',
    },
    {
      key: '',
      name: 'Cases',
    },
    {
      key: '',
      name: 'Units',
    },
    {
      key: 'date',
      name: 'Date',
    },
  ]

  const [loadingd, setLoadingd] = useState(false);
  const [selected, setSelected] = useState(null)


  const handleModal = (item) => {
    setSelected(item)
    onOpen()
  }

  const deleteDoc = async (id) => {
    setLoadingd(true)
    try {
      const { data } = await axiosInstance.delete(`/shipments/${selected}`)
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      onClose()
      setRefetch(Math.random());
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingd(false)
    }
  }

  return (
    < div className={classes.screenContainer}>

      <InitiativeQueryComponent
        data={data}
        searchValues={searchValues}
        setSearchValues={setSearchValues}
        filterList1={{ title: 'Brand', name: 'brandName', data: options1 }}
        entity='shipments' queryParamsx={queryParamsx}
      />

      {
        loading ?
          <div className={classes.loader_con}>
            <Loader />
          </div>
          :
          <>
            {
              docs.length ?
                <div className={classes.table_container}>

                  <div className={classes.titleAndInstructionContainer}>
                    <div>
                      <p className={classes.title}>Total Shipments Found: {total}</p>
                      <p className={classes.instruction}>Click on each row to see more details</p>
                    </div>

                    <div className={classes.actionsContainer}>
                      <CustomDrawer
                        position={"right"}
                        title={"Select brands to generate"}
                        triggerClose={triggerClose}
                        setTriggerClose={setTriggerClose}
                        toggleElement={
                          <SecondaryButton text={"Generate Upload Template"} icon="download" loading={false} />
                        }
                        content={
                          <>
                            <CustomInput
                              type={"text"}
                              required
                              value={searchValx}
                              placeholder={`Search brands..`}
                              onChange={e => setSearchValx(e.target.value)}
                              mt=".7rem"
                              mb="1.2rem"
                            />
                            <CustomInput
                              type={"date"}
                              required
                              value={datex}
                              placeholder={`Select date`}
                              onChange={e => setDatex(e.target.value)}
                              mb="1.2rem"
                            />
                            <Box height={"50vh"} overflowY={"scroll"}>
                              {filteredBrands?.map((i, index) => (
                                <Flex key={index} alignItems={"center"} gap=".5rem" mb=".5rem" border={'.5px solid lightgray'} p=".3rem .5rem .45rem .5rem" borderRadius={'6px'}>
                                  <Checkbox onChange={e => handleSelect(e)} value={JSON.stringify(i)} isChecked={selectedProducts?.includes(JSON.stringify(i))} colorScheme='green' />
                                  <Tooltip label={`${i?.name}-${i?.sku}`}>
                                    <Text textTransform={'capitalize'} fontSize={'.9rem'} fontWeight={'600'}>{truncateText(`${i?.name}-${i?.sku}`, 30)}</Text>
                                  </Tooltip>
                                </Flex>
                              ))}
                            </Box>
                            <br /><br />
                            {/* <button onClick={() => console.log(selectedProducts?.map(x => ({...JSON.parse(x), date: datex})))}>goo</button> */}
                            <ExportCSV text={"Generate Upload Template"} width='100%' height="50px" headers={headers} items={selectedProducts?.map(x => ({ ...JSON.parse(x), date: datex }))} />
                          </>
                        }
                      />

                      <CustomDrawer
                        position={"right"}
                        title={"Add Shipment"}
                        triggerClose={triggerClose}
                        setTriggerClose={setTriggerClose}
                        toggleElement={
                          <SecondaryButton type="submit" text={"Add Shipment"} icon="add" loading={false} />
                        }
                        content={
                          <CreateShipment setTriggerClose={setTriggerClose} setRefetch={setRefetch} data={data} />
                        }
                      />
                    </div>
                  </div>

                  <p className={classes.showing}>
                    Showing: <span>{docs.length || 1} of {total}</span>
                  </p>
                  <div className={classes.scrollable}>
                    <table className={classes.table}>
                      <thead>
                        <tr>
                          <th>Creation Date</th>
                          <th>Brand</th>
                          <th>SKU</th>
                          <th>Store</th>
                          <th>Location</th>
                          <th>Units Shipped</th>
                          <th>Cases Shipped</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {docs.map((item, index) => (
                          <tr onClick={() => draw({
                            title: 'Orders', content:
                              <div className={classes.modalContainer}>

                                <div className={classes.topButtonContainer}>

                                  <button onClick={() => handleModal(item._id)} className={classes.deleteButton}>Delete</button>
                                </div>

                                <img onClick={item.imageURL ? () => window.open(item.imageURL) : null} src={item.imageURL || defaultImage} alt='' className={classes.image} />

                                <div className={classes.modalTitleAndDateContainer}>
                                  <p className={classes.modalTitle}>{item?.brandName} - {item?.sku}</p>
                                  <p>{formatDateHeader(moment(item.date).format('YYYY-MM-DD'))}</p>
                                </div>

                                <div className={classes.modalStoreAddressContainer}>
                                  <p className={classes.modalStoreAddress}>{item?.initiativeStore?.store?.name} - {item?.initiativeStore?.store?.streetNum || 'No Bld. num.'}, {item?.initiativeStore?.store?.streetName}</p>
                                  <MdStorefront className={classes.modalIcon} />
                                </div>

                                <div className={classes.modalStoreAddressContainer}>
                                  <div style={{ width: '90%' }}>
                                    <p className={classes.modalStoreAddress} >{item?.user?.name ? item?.user?.name : `${item?.user?.firstName} ${item?.user?.lastName || ''}`}</p>
                                    <p className={classes.messageDescription}>{item?.user?.role}</p>
                                  </div>

                                  <FaRegUser className={classes.modalIcon} />
                                </div>

                                <div className={classes.modalStoreAddressContainer}>
                                  <div style={{ width: '90%' }}>
                                    <p className={classes.modalStoreAddress} >{item?.unitsShipped}</p>
                                    <p className={classes.messageDescription}>Units Shipped</p>
                                  </div>

                                  <MdOutlineInventory2 className={classes.modalIcon} />
                                </div>


                                <div className={classes.smallGrouped}>
                                  <div className={classes.smallContainer}>
                                    <p className={classes.modalStoreAddress} >{numberFormat.format(item?.totalCase)}</p>
                                    <p className={classes.messageDescription}>Cases Shipped</p>
                                  </div>

                                  <div className={classes.smallContainer}>
                                    <p className={classes.modalStoreAddress} >{moneyFormat.format(item.totalValue)}</p>
                                    <p className={classes.messageDescription}>Value</p>
                                  </div>
                                </div>

                              </div>
                          })} key={index}>
                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>
                                {formatDateHeader(moment(item.date).format('YYYY-MM-DD'))}
                              </p>
                              <p className={classes.tooltip}>
                                {formatDateHeader(moment(item.date).format('YYYY-MM-DD'))}
                              </p>
                            </td>

                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{item.brandName}</p>
                              <p className={classes.tooltip}>{item.brandName}</p>
                            </td>
                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{item.sku}</p>
                              <p className={classes.tooltip}>{item.sku}</p>
                            </td>
                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{item.initiativeStore?.store?.name}</p>
                              <p className={classes.tooltip}>{item.initiativeStore?.store?.name}</p>
                            </td>
                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{item.initiativeStore?.store?.state}</p>
                              <p className={classes.tooltip}>{item.initiativeStore?.store?.state}</p>
                            </td>

                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{numberFormat.format(item.unitsShipped)}</p>
                              <p className={classes.tooltip}>{numberFormat.format(item.unitsShipped)}</p>
                            </td>

                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{numberFormat.format(item.totalCase)}</p>
                              <p className={classes.tooltip}>{numberFormat.format(item.totalCase)}</p>
                            </td>

                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{moneyFormat.format(item.totalValue)}</p>
                              <p className={classes.tooltip}>{moneyFormat.format(item.totalValue)}</p>
                            </td>

                          </tr>
                        ))}
                      </tbody>


                    </table>

                  </div>




                  <InitiativePaginationComponent
                    page={page} totalCount={total} setSearchValues={setSearchValues} setInputPage={setInputPage} inputPage={inputPage} limit={limit}
                  />
                </div>
                :
                <InitiativeEmptyState searchValues={searchValues} name={'shipments'} />

            }


            <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{`Delete Shipment`}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {/* {modalType === 'delete' && */}
                  <>
                    <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm deletion of shipment?</Text>
                    <Flex justifyContent={"center"} my="1.3rem">
                      <PrimaryButton onClick={deleteDoc} text="Delete" width="150px" bg="crimson !important" padding="1rem !important" loading={loadingd} />
                    </Flex>
                  </>
                  {/* 
                    } */}
                  <Text pb="1rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Cancel</Text>
                </ModalBody>
              </ModalContent>
            </Modal>

          </>
      }



    </div>
  )
}

export default Shipments;