import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import EmptyState from '../../../../components/emptyState/emptyState'
import axiosInstance from '../../../../services/api'
import { HiArrowRight } from 'react-icons/hi'
import LineSkeleton from '../../../../components/lineSkeleton'
import { useNavigate } from 'react-router-dom'
import { buildResourceURL, truncateText } from '../../../../utils/helper'
import CustomDrawer from '../../../../components/customDrawer/customDrawer'
import CreateSurvey from '../../surveys/createSurvey/createSurvey'
import classes from '../home.module.css'

const SurveysView = ({size, totalSurveys, setTotalSurveys}) => {
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingc, setLoadingc] = useState(false);
  const [triggerClose, setTriggerClose] = useState("")

  const navigate = useNavigate()

  const [page, setPage] = useState(1)
  const queryParams = [
    {
        key: 'initiativeStore',
        value: ''
    },
    {
        key: 'page',
        value: page
    },
    {
        key: 'limit',
        value: 5
    },
]


const getSurveys = async() => {
    setLoading(true)
    try {
        const { data } = await axiosInstance.get(buildResourceURL('surveys', queryParams))
        setSurveys(data?.data)
        setTotalSurveys(data?.totalCount)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
}

useEffect(() => {
  getSurveys()
}, [])


  return (
    <Box boxShadow="0px 1px 2px 0px #1018280D" flex={["1 0 300px", "1 1 400px"]} bg="#FFF" border=".5px solid #F2F4F7" p="1.3rem" borderRadius={"12px"}>
        <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>Surveys <span style={{padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054"}}>{totalSurveys}</span></Text>
            <Flex alignItems={"center"} gap=".8rem">
                <CustomDrawer
                  position={"right"}
                  title={"Create Survey"}
                  triggerClose={triggerClose}
                  setTriggerClose={setTriggerClose}
                  toggleElement={
                      <button className={classes.createButton}>Create Survey</button>
                  }
                  content={
                      <CreateSurvey setTriggerClose={setTriggerClose} />
                  }
              />
            </Flex>
        </Flex>
        <Divider my="1.5rem" />
        <Box height={size === 'full' ? "auto" : "267.41px"} overflow={size === "full" ? "none": "scroll"}
        sx={
            { 
            '::-webkit-scrollbar':{
                display:'none'
            }
            }
        }>
            {loading ? 
            <LineSkeleton num={6} />
            :
            surveys?.length < 1 ?
            <EmptyState mt="1.5rem" text="You have not created any surveys yet" />
            :
            surveys?.slice(0, 10)?.map((({_id: id, name, createdAt, description}) => {
                return (
                    <Flex key={id} onClick={() => navigate(`/surveys/${id}`)} cursor={"pointer"} padding={".7rem"} borderRadius={"16px"} border="1px solid #EAECF0" mb=".8rem">
                        <Flex alignItems={"center"} w="100%" justifyContent={"space-between"}>
                            <Flex alignItems={"center"} gap=".7rem">
                                <Box height={"50px"} width={"60px"} borderRadius={"6px"} bg="lightgray" backgroundSize={"cover"} backgroundRepeat={"no-repeat"}  />
                                <Box>
                                    <Text mb=".2rem" color={"#667085"} fontWeight={"500"}>{name}</Text>
                                    <Text fontWeight={"300"} fontSize={".85rem"} color="#667085">{truncateText(description, 25)}</Text>
                                    {/* <Text fontWeight={"300"} fontSize={".85rem"} color="#667085">{moment(createdAt).calendar()}</Text> */}
                                </Box>
                            </Flex>
                            <HiArrowRight />
                        </Flex>
                    </Flex>
                )
            }))
            }
        </Box>

        <br />
        <Text onClick={() => navigate('/surveys')} color={"#ff6f61"} fontSize={".9rem"} fontWeight={"600"} cursor={"pointer"}>See more</Text>
        
    </Box>
  )
}

export default SurveysView;