import { Box, Heading } from '@chakra-ui/react'
import React from 'react'
import ClientsView from '../home/clientsView/clientsView';
import ClientsTable from './clientsTable/clientsTable';

const Clients = () => {
  return (
    <Box>
        <ClientsTable />
    </Box>
  )
}

export default Clients;