import classes from './initiativeHeaderTab.module.css'

export function InitiativeHeaderTab({ list, selected, setSelected }) {


    return (
        <div className={classes.container}>
            {
                list?.map((item, index) => (
                    <div className={classes.itemsContainer} onClick={() => setSelected(item)} key={index}>
                        <p className={classes.text} style={selected === item ? { color: '#ffa347', fontWeight: 'bold' } : {}} >{item}</p>
                        {selected === item && <div className={classes.indicator} />}
                    </div>
                ))
            }

        </div>
    )
}