import React, { useState } from 'react'
import { useToast } from '@chakra-ui/react';
import CustomInput from '../../../../../components/customInput/customInput';
import axiosInstance from '../../../../../services/api';
import PrimaryButton from '../../../../../components/primaryButton/primarybutton';
import countriesData from '../../../../../utils/countries.json'
import CustomSelect from 'components/customSelect/customSelect';


const CreateClient = ({loading, setLoading, setTriggerClose}) => {
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("")

  const toast = useToast();

  const createClient = async(e) => {
    e.preventDefault()
    setLoading(true)
    const datax = {
      companyName,
      email,
      country,
      phoneNumber: phoneNumber.charAt(0) === "0"
      ? "+234" + phoneNumber.slice(1)
      : "+234" + phoneNumber,
    }
    try {
      const {data} = await axiosInstance.post('/clients', datax)
      toast({
        description: data?.message,
        status: "success",
        position: "top"
      });
      setTriggerClose('close' + (Math.random()))
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
      });
    } finally {
      setLoading(false)
    }
  }

  const countries = countriesData?.map(x => ({
    title: x?.name,
    value: x?.name?.toLowerCase()
  }))


  return (
    <form onSubmit={createClient}>
        <CustomInput
        type={"text"}
        required
        placeholder={"Client Company Name"}
        onChange={e => setCompanyName(e.target.value)}
        />
        <CustomInput
        type={"email"}
        required
        placeholder={"Email Address"}
        onChange={e => setEmail(e.target.value)}
        />
         <CustomSelect
            required
            options={countries}
            placeholder={"Country"}
            onChange={e => setCountry(e.target.value)}
          />
         <CustomInput
        type={"tel"}
        minLength={11}
        maxLength={11}
        required
        placeholder={"Phone number"}
        mb={".8rem"}
        onChange={e => setPhoneNumber(e.target.value)}
        />
        <PrimaryButton mt="1rem" type="submit" text="Add client" func={() => {}} loading={loading} />
    </form>
  )
}

export default CreateClient;