import { Box, Flex, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import CustomInput from '../../../../../../components/customInput/customInput'
import CustomSelect from '../../../../../../components/customSelect/customSelect'
import PrimaryButton from '../../../../../../components/primaryButton/primarybutton'
import { AiFillCloseCircle } from 'react-icons/ai'
import axiosInstance from '../../../../../../services/api'
import { useParams } from 'react-router-dom'

const DrawQuestions = ({data}) => {
    const defaults = data?.games?.find(i => i?.name === "true or false")?.questions
    const fid = defaults?.map(i => ({
        text: i?.text,
        answer: i?.answer
    }))

    const [question, setQuestion] = useState("")
    const [answer, setAnswer] = useState("")
    const [saved, setSaved] = useState(false)
    const [quizList, setQuizList] = useState(fid);
    const [loadingx, setLoadingx] = useState(false)
    const toast = useToast()
    const {iid} = useParams()

    const answerOptions = [
        {
          title: "True",
          value: "true"
        },
        {
          title: "False",
          value: "false"
        }
      ]

      const handleAdd = () => {
        setQuizList([
          ...quizList,
          {
            text: question,
            answer
          }
        ])
        setQuestion("")
        setAnswer("")
        setSaved(true)
      }
    
      const removeQuiz = (val) => {
        const arr2 = quizList.filter(i => i.text !== val)
        setQuizList(arr2)
      };

      const updateDraw = async() => {
        setLoadingx(true)
        const datax = {
            name: "true or false",
            imageURL: data?.games?.find(i => i.name === "true or false")?.imageURL ?? 'n/a',
            questions: quizList
        }
        try {
            const res = await axiosInstance.patch(`/initiatives/${iid}/games/${data?.games?.find(i => i.name === "true or false")?._id}`, datax)
            toast({
            description: res?.data?.message,
            status: "success",
            position: "top"
            });
        } catch(error) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }

  return (
    <>
            <>

            <Flex gap={"1rem"} alignItems={"center"}>
                <CustomInput
                type={"text"}
                name="name"
                placeholder={`Question ${quizList?.length + 1}`}
                onChange={e => setQuestion(e.target.value)}
                value={question}
                mt="0 !important"
                width="280px"
                />
                <CustomSelect
                required
                options={answerOptions}
                value={answer}
                placeholder={"Answer"}
                onChange={e => setAnswer(e.target.value)}
                borderRadius="10px"
                mt="0 !important"
                />
            </Flex>

            <Box my="1.5rem">
                {quizList?.map(({text, answer}) => {
                return (
                    <Text position={"relative"} mb=".5rem" key={text} padding={".4rem 1rem"} borderRadius={"4px"} border={"1px solid lightgray"}>{text}?  <strong>{answer}</strong> <AiFillCloseCircle onClick={() => removeQuiz(text)} color='#EB5017' fontSize={"1.2rem"} style={{position: "absolute", right: "-1.5%", top: "20%", cursor: "pointer"}} /></Text>
                )
                })}
            </Box>

            <Flex justifyContent={"center"}>
                <PrimaryButton mt="2rem" text="Save" width="120px" py="1.4rem" onClick={handleAdd} loading={false} isDisabled={question === "" ?? answer === ""} />
            </Flex>

            <PrimaryButton mt="3rem" text="Update Draw" onClick={updateDraw} loading={loadingx} isDisabled={saved === false} />
            </>
    </>
  )
}

export default DrawQuestions