import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import GoBack from '../../../../../../components/goBack';
import axiosInstance from '../../../../../../services/api';
import SpinLoader from '../../../../../../components/spinLoader/spinLoader';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import ExportCSV from '../../../../../../components/exportCsv';
import CustomSelect from '../../../../../../components/customSelect/customSelect';
import LineSkeleton from '../../../../../../components/lineSkeleton';
import EmptyState from '../../../../../../components/emptyState/emptyState';
import { truncateText } from '../../../../../../utils/helper';
import { HiArrowNarrowRight } from 'react-icons/hi';
import CustomInput from '../../../../../../components/customInput/customInput';
import CustomDrawer from '../../../../../../components/customDrawer/customDrawer';

const statusOptions = [
    {
        title: "All",
        value: "all"
    },
    {
        title: "Pending",
        value: 'pending'
    },
    {
        title: "Completed",
        value: 'completed'
    },
]

const TaskDetails = () => {
    const [task, setTask] = useState();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("all");
    const [searchVal, setSearchVal] = useState('')

    const {tid} = useParams();

    const getTask = async() => {
        setLoading(true)
        try {
            const { data } = await axiosInstance.get(`tasks/${tid}`)
            setTask(data?.data)
        } catch(error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }
    
    useEffect(() => {
        getTask()
    }, [])

    const filteredStores = searchVal?.length > 0 ? task?.assignedInitiativeStores?.filter(v => (v?.initiativeStore?.store?.name?.toLowerCase()?.includes(searchVal?.toLowerCase()))) : task?.assignedInitiativeStores
    const finalFilteredStores = status === 'pending' ? filteredStores?.filter(v => v?.reply === null) : status === 'completed' ? filteredStores?.filter(v => v?.reply !== null) : filteredStores

    const refinedData = finalFilteredStores?.map(x => ({
        storeName: x?.initiativeStore?.store?.name,
        location: x?.initiativeStore?.store?.area + ', ' + x?.initiativeStore?.store?.state,
        comment: x?.reply?.comment,
        status: x?.reply === null ? 'Pending' : 'Completed',
        dateSubmitted: x?.reply === null ? '' : moment(x?.reply?.date).calendar(),
        imageOne: x?.reply?.uploadURLs[0],
        imageTwo: x?.reply?.uploadURLs[1],
        imageThree: x?.reply?.uploadURLs[2],
    }))

    const headers = [
        {
            key: 'storeName',
            name: 'Store Name'
        },
        {
            key: 'location',
            name: 'Location'
        },
        {
            key: 'comment',
            name: 'Comment'
        },
        {
            key: 'status',
            name: 'Status'
        },
        {
            key: 'dateSubmitted',
            name: 'Date Submitted'
        },
        {
            key: 'imageOne',
            name: 'Image One'
        },
        {
            key: 'imageTwo',
            name: 'Image Two'
        },
        {
            key: 'imageThree',
            name: 'Image Three'
        },
    ]

  return (
    <Box bg="#FFF" borderRadius={"6px"} p="1rem">
        <GoBack />
        {loading ?
        <SpinLoader />
        :
        <Box mt="1.2rem">
            <Box border="1px solid #D0D5DD" p="1rem" borderRadius={"8px"} mb="2rem" alignItems={"center"} gap=".8rem" justifyContent={"space-between"}>
                <Flex gap=".7rem" alignItems={"center"}>
                    {task?.uploadURLs?.slice(0,1)?.map(i => (
                        <a key={i} href={i} target="_blank" rel="noopener noreferrer">
                        <Box height={"70px"} cursor={"pointer"} width={"80px"} borderRadius={"4px"} backgroundImage={i} backgroundSize={"cover"}>

                        </Box></a>
                    ))}
                    <Box>
                        <Text whiteSpace={"nowrap"} mb=".6rem" fontWeight={"600"} fontSize={"1.15rem"} color={"#27272A"}>{task?.name}</Text>
                        <Text whiteSpace={"nowrap"} fontWeight={"400"} fontSize={"1.05rem"} color={"#27272A"}>{task?.comment}</Text>
                    </Box>
                </Flex>
               
                <Flex mb="1.5rem" mt="1rem" alignItems={"center"} gap=".5rem">
                    
                </Flex>
                <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <Text whiteSpace={"nowrap"} opacity={".7"} fontWeight={"500"} fontSize={".85rem"} color={"#27272A"}>Due on <strong>{task?.dueDate}</strong> at <strong>{task?.dueTime}</strong></Text>
                    <Text whiteSpace={"nowrap"} opacity={".7"} fontWeight={"500"} fontSize={".85rem"} color={"#27272A"}>Created <strong>{moment(task?.createdAt).calendar()}</strong></Text>
                </Flex>
            </Box>



            <Flex alignItems={"center"} mb="1rem" gap=".8rem" justifyContent={"space-between"}>
                <Flex gap="1rem" alignItems={"center"}>
                    <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>Responses <span style={{padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054"}}>{task?.assignedInitiativeStores?.filter(i => i.reply !== null)?.length} / {task?.assignedInitiativeStores?.length}</span></Text>
                    <CustomInput
                      type={"text"}
                      required
                      placeholder={"Search Store"}
                      onChange={e => setSearchVal(e.target.value)}
                      py="1.2rem"
                      mt="0"
                      width="!200px"
                    />
                    <CustomSelect
                        required
                        options={statusOptions}
                        onChange={e => setStatus(e.target.value)}
                        height="40px !important"
                        mt="0"
                        borderRadius={"8px"}
                        width="150px"
                        cursor="pointer"
                    />
                </Flex>
                <ExportCSV headers={headers} items={refinedData} />
            </Flex>

            <Table mt="1.2rem" borderRadius={"12px"} overflow={"hidden"}>
                <Thead bg="#F9FAFB" border="1px solid #EAECF0">
                    <Tr>
                        <Th whiteSpace={"nowrap"} textTransform={"capitalize !important"} color={"#475467"}>Store Name</Th>
                        <Th whiteSpace={"nowrap"} textTransform={"capitalize !important"} color={"#475467"}>Location</Th>
                        <Th whiteSpace={"nowrap"} textTransform={"capitalize !important"} color={"#475467"}>Comment</Th>
                        <Th whiteSpace={"nowrap"} textTransform={"capitalize !important"} color={"#475467"}>Images</Th>
                        <Th whiteSpace={"nowrap"} textTransform={"capitalize !important"} color={"#475467"}>Status</Th>
                        <Th whiteSpace={"nowrap"} textTransform={"capitalize !important"} color={"#475467"}>Date Submitted</Th>
                        <Th whiteSpace={"nowrap"} textTransform={"capitalize !important"} color={"#475467"}></Th>
                    </Tr>
                </Thead>
                
                <Tbody>
                {loading ? 
                <LineSkeleton width="100%" num={5} />
                :
                finalFilteredStores?.length < 1 ?
                <EmptyState mt="1.5rem" text="No records have been submitted yet" />
                :
                finalFilteredStores?.map((({initiativeStore, reply}, index) => {
                    return (
                        <Tr key={index}>
                            <Td>
                                <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{initiativeStore?.store?.name}</Text>
                            </Td>
                            <Td color={"#475467"} fontSize={".9rem"}>{initiativeStore?.store?.area}, {initiativeStore?.store?.state}</Td>
                            <Tooltip label={reply?.comment} hasArrow>
                            <Td color={"#475467"} fontSize={".9rem"}>{truncateText(reply?.comment, 22) || '--'}</Td>
                            </Tooltip>
                            <Td color={"#475467"} fontSize={".9rem"}>{reply?.uploadURLs?.length > 0 ? reply?.uploadURLs?.length > 1 ? `${reply?.uploadURLs?.length} Images` : (truncateText(reply?.uploadURLs[0], 20))  : "--"}</Td>
                            <Td>
                                <Text fontSize={"12px"} width={"90px"} textAlign={"center"} whiteSpace={"nowrap"} fontWeight={"500"} textTransform={"capitalize"} borderRadius={"20px"} padding={".2rem 1rem"} bg={reply === null ? "#FFE3B0" : "#E5FFE4"} color={reply === null ? "#EC913C" : "#44A234"}>{reply === null ? "Pending" : "Completed"}</Text>
                            </Td>
                            <Td>
                                <Text fontSize={".8rem"}>{reply !== null ? moment(reply?.date).calendar() : '--'}</Text>
                            </Td>
                            <Td>
                                <CustomDrawer
                                key={index}
                                position={"right"}
                                title={"Task Submission Details"}
                                triggerClose={''}
                                setTriggerClose={() => {}}
                                toggleElement={
                                    <HiArrowNarrowRight cursor={"pointer"} />
                                }
                                content={
                                    <>
                                        <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>Store Name: <strong>{initiativeStore?.store?.name}</strong></Text>
                                        <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>Location: <strong>{initiativeStore?.store?.area}, {initiativeStore?.store?.state}</strong></Text>
                                        <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>Comment: <strong>{reply?.comment}</strong></Text>
                                        <Text mb=".7rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>Date Submitted: <strong>{reply !== null ? moment(reply?.date).calendar() : '--'}</strong></Text>
                                       
                                        {reply?.uploadURLs?.map(x => (
                                            <a href={x} target="_blank" rel="noopener noreferrer">
                                            <Box 
                                            height={'250px'}
                                            width={'full'}
                                            borderRadius={'8px'}
                                            backgroundImage={x}
                                            backgroundSize={"cover"}
                                            backgroundPosition={'center'}
                                            mt="1rem"
                                            />
                                            </a>
                                        ))}
                                    </>
                                }
                                />
                            </Td>
                        </Tr>
                    )
                }))
                }
                </Tbody>
            </Table>





















            {/* {task?.assignedInitiativeStores?.map(({initiativeStore, reply}) => (
                <Box mb=".7rem" border="1px solid #D0D5DD" p="1rem" borderRadius={"8px"}>
                    <Text fontWeight={"500"} color="#667085" textTransform={"capitalize"}>{initiativeStore?.store?.name}</Text>
                    <Text fontWeight={"400"} fontSize={".85rem"} color="#667085" textTransform={"capitalize"}>{initiativeStore?.store?.area}, {initiativeStore?.store?.state}</Text>
                    {reply !== null && 
                    <Flex my="1rem" alignItems={"start"} border="1px solid #D0D5DD" gap="1rem" p="1rem" borderRadius={"8px"}>
                        <Flex alignItems={"center"} gap={".7rem"}>
                            {reply?.uploadURLs?.map(i => (
                                <a key={i} href={i} target="_blank" rel="noopener noreferrer">
                                <Box height={"40px"} cursor={"pointer"} width={"50px"} borderRadius={"4px"} bg="lightgray" backgroundImage={i} backgroundSize={"cover"}>

                                </Box></a>
                            ))}
                        </Flex>
                        <Text opacity={".7"} transform={"translateY(-6px)"} fontWeight={"500"} fontSize={".85rem"} color={"#27272A"}>{reply?.comment}</Text>
                    </Flex>}
                    <Flex alignItems={"center"} justifyContent={"space-between"} mt={reply === null ? "1rem" : ""}>
                        <Text fontSize={"12px"} width={"90px"} textAlign={"center"} whiteSpace={"nowrap"} fontWeight={"500"} textTransform={"capitalize"} borderRadius={"20px"} padding={".2rem 1rem"} bg={reply === null ? "#FFE3B0" : "#E5FFE4"} color={reply === null ? "#EC913C" : "#44A234"}>{reply === null ? "Pending" : "Completed"}</Text>
                        {reply !== null && <Text fontWeight={"400"} fontSize={".85rem"} color="#667085" textTransform={"capitalize"}>{moment(reply?.date).calendar()}</Text>}
                    </Flex>
                </Box>
            ))} */}
        </Box>}
    </Box>
  )
}

export default TaskDetails;