import React, { useEffect, useState } from 'react'
import CustomInput from '../../../../components/customInput/customInput';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import axiosInstance from '../../../../services/api';
import { Flex, Switch, Text, useToast } from '@chakra-ui/react';
import CustomSelect from '../../../../components/customSelect/customSelect';
import { useSelector } from 'react-redux';

const EditAdmin = ({data, setTriggerClose}) => {
  const [loading, setLoading] = useState(false)

  const [email, setEmail] = useState(data?.email);
    const [firstName, setFirstName] = useState(data?.firstName);
    const [lastName, setLastName] = useState(data?.lastName);
    const [phoneNumber, setPhoneNumber] = useState(data?.phoneNumber);
    const [password, setPassword] = useState(data?.password);
    const [superx, setSuperx] = useState(data?.isDefaultAdmin);

  const currentUser = useSelector(({ userData }) => userData.currentUser);

  const toast = useToast();

  const editAdmin = async(e) => {
    e.preventDefault()
    setLoading(true)
    const datax = {
      firstName,
      lastName,
      email,
      profilePicture: 'https://res.cloudinary.com/aoproton/image/upload/v1592466937/ichurch/uccsobarpmaobunukx6v.png',
      phoneNumber: phoneNumber.charAt(0) === "0"
      ? "+234" + phoneNumber.slice(1)
      : phoneNumber.charAt(0) === "+" ? phoneNumber : "+234" + phoneNumber,
    }
    try {
      const res = await axiosInstance.patch(`/admins/${data?.id}`, datax)
      toast({
        description: res?.data?.message,
        status: "success",
        position: "top"
      });
      setTriggerClose('close' + (Math.random()))
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
      });
    } finally {
      setLoading(false)
    }
  }

  return (
    <form onSubmit={editAdmin}>
                        <CustomInput
                        type={"text"}
                        required
                        placeholder={"First Name"}
                        onChange={e => setFirstName(e.target.value)}
                        value={firstName}
                        />
                        <CustomInput
                        type={"text"}
                        required
                        placeholder={"Last Name"}
                        onChange={e => setLastName(e.target.value)}
                        value={lastName}
                        />
                        <CustomInput
                        type={"email"}
                        required
                        placeholder={"Email Address"}
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        />
                        <CustomInput
                        type={"tel"}
                        minLength={14}
                        maxLength={14}
                        required
                        placeholder={"Phone number"}
                        onChange={e => setPhoneNumber(e.target.value)}
                        value={phoneNumber}
                        />
                        
                        <PrimaryButton mt="3rem" type="submit" text={`Update admin`} func={() => {}} loading={loading} />
                    </form>
  )
}

export default EditAdmin;