import classes from './initiativeEmptyState.module.css';
import promoramaMan from '../../assets/promoramaMan.svg';

export function InitiativeEmptyState({ searchValues, name }) {

    // Filter out "page" from searchValues, and check if any filter exists
    const { page, ...filteredSearchValues } = searchValues || {}



    // Build filter string while omitting falsy values
    const filters = Object.entries(filteredSearchValues)
        .filter(([key, value]) => value) // Filter out falsy values
        .map(([key, value]) => `${key}: ${value}`) // Format key-value pairs
        .join(', '); // Join filters with commas

    return (
        <div className={classes.empty_container}>
            <img className={classes.empty_image} src={promoramaMan} alt="Empty State" />
            <p className={classes.empty_message}>
                No {name} data {filters ? `with - ${filters}` : ''}
            </p>
        </div>
    );
}
