import React, { useEffect, useState } from 'react'
import { Box, Checkbox, Divider, Flex, Text, useToast } from '@chakra-ui/react';
import axiosInstance from '../../../../../../services/api';
import CustomInput from '../../../../../../components/customInput/customInput';
import PrimaryButton from '../../../../../../components/primaryButton/primarybutton';
import ExportCSV from '../../../../../../components/exportCsv';


const UpdateGroup = ({setTriggerClose, iid, setRefetch, groupData, allStores}) => {
  const [name, setName] = useState(groupData?.name);
  const [searchVal, setSearchVal] = useState('');
  const [selectedStores, setSelectedStores] = useState(groupData?.initiativeStoresIDs);
  const [loading, setLoading] = useState(false);
  const [loadingx, setLoadingx] = useState(false);


  const [storeGroup, setStoreGroup] = useState(null)

  const toast = useToast();

  const getStoreGroup = async() => {
    setLoading(true)
    try {
        const { data } = await axiosInstance.get(`initiatives/${iid}/initiative-stores-filter-groups/${groupData?._id}`)
        setStoreGroup(data?.data)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
  }

  useEffect(() => {
    getStoreGroup()
  }, [])

  const updateGroup = async(e) => {
    e.preventDefault()
    setLoadingx(true)
    const datax = {
      name,
      initiativeStoresIDs: selectedStores
    }
    try {
      const {data} = await axiosInstance.patch(`initiatives/${iid}/initiative-stores-filter-groups/${groupData?._id}`, datax)
      toast({
        description: data?.message,
        status: "success",
        position: "top"
      });
      setTriggerClose('close' + (Math.random()))
      setRefetch(Math.random())
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
      });
    } finally {
      setLoadingx(false)
    }
  }


  const handleSelect = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add item to the array if it doesn't already exist
      if (!selectedStores.includes(value)) {
        setSelectedStores([...selectedStores, value]);
      }
    } else {
      // Remove item from the array if it exists
      const filteredItems = selectedStores.filter((item) => item !== value);
      setSelectedStores(filteredItems);
    }
  }

  const filteredStores = searchVal?.length > 0 ? allStores?.filter(i => i?.store?.name?.toLowerCase().includes(searchVal?.toLowerCase())) : allStores

  const refinedData = filteredStores?.filter(x => selectedStores?.includes(x?._id))?.map(v => v?.store)

  const headers = [
    {
    key: 'name',
    name: 'Name',
    },
    {
    key: 'phoneNumber',
    name: 'Phone Number',
    },
    {
    key: 'area',
    name: 'LGA',
    },
    {
    key: 'streetName',
    name: 'Street',
    },
    {
    key: 'state',
    name: 'State',
    },
    {
    key: 'category',
    name: 'Category',
    },
    {
    key: 'comment',
    name: 'Comment',
    },
    {
    key: 'imageURL',
    name: 'Image',
    },
]


  return (
    <form onSubmit={updateGroup}>
        <CustomInput
        type={"text"}
        required
        placeholder={"Group Name"}
        onChange={e => setName(e.target.value)}
        value={name}
        label={'Group Name'}
        mb="1rem"
        />

        <Divider />

        <CustomInput
        type={"text"}
        placeholder={"Search stores"}
        onChange={e => setSearchVal(e.target.value)}
        />

        <Flex mt="1rem" mb=".5rem" justifyContent={"flex-end"} gap=".7rem" alignItems={"center"}>
          <Text><strong>{selectedStores?.length}</strong> selected</Text>
          <ExportCSV headers={headers} items={refinedData} />
        </Flex>

        <Box>
          {filteredStores?.filter(x => selectedStores?.includes(x?._id))?.map(({store, _id}) => {
            return (
              <Flex key={_id} alignItems={"center"} justifyContent={'space-between'} mb=".5rem" border={'.5px solid lightgray'} p=".3rem .5rem .45rem .5rem" borderRadius={'6px'}>
                <Box>
                  <Text textTransform={'capitalize'} fontSize={'.9rem'} fontWeight={'600'}>{store?.name}</Text>
                  <Text textTransform={'capitalize'} fontSize={'.9rem'}>{`${store?.area}, ${store?.state}`}</Text>
                </Box>
                <Checkbox onChange={e => handleSelect(e)} value={_id} isChecked={selectedStores?.includes(_id)} colorScheme='green' />
              </Flex>
            )
          })}
          {filteredStores?.filter(x => !selectedStores?.includes(x?._id))?.map(({store, _id}) => {
            return (
              <Flex key={_id} alignItems={"center"} justifyContent={'space-between'} mb=".5rem" border={'.5px solid lightgray'} p=".3rem .5rem .45rem .5rem" borderRadius={'6px'}>
                <Box>
                  <Text textTransform={'capitalize'} fontSize={'.9rem'} fontWeight={'600'}>{store?.name}</Text>
                  <Text textTransform={'capitalize'} fontSize={'.9rem'}>{`${store?.area}, ${store?.state}`}</Text>
                </Box>
                <Checkbox onChange={e => handleSelect(e)} value={_id} isChecked={selectedStores?.includes(_id)} colorScheme='green' />
              </Flex>
            )
          })}
        </Box>
       
        <PrimaryButton mt="3rem" type="submit" text="Update Group" loading={loadingx} />
    </form>
  )
}

export default UpdateGroup;