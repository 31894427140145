const moment = require('moment');

export const groupMessagesByDate = (messages) => {
    const groupedMessages = {};

    messages.forEach(message => {
        const date = moment(message.timeStamp).format('YYYY-MM-DD');
        if (!groupedMessages[date]) {
            groupedMessages[date] = [];
        }
        groupedMessages[date].push(message);
    });

    return groupedMessages;
};
