import React, { useState } from 'react'
import Shelf from './components/shelf'
import Posm from './components/posm'
import CustomTabs from '../../../../../../components/customTabs/customTabs'
import { useToast } from '@chakra-ui/react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import axiosInstance from '../../../../../../services/api'

const ShelfPosm = ({src, setTriggerClose, setRefetch, data}) => {
  const [shelfImages, setShelfImages] = useState([]);
  const [posmImages, setPosmImages] = useState([]);
  const [uLoading, setULoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate()
  const {search} = useLocation()
  const {iid} = useParams()

  const uploadImages = async() => {
    if(src === "edit") {
      setULoading(true)
      const datax = {
        shelfImages,
        posmImages
      }
      try {
        const { data } = await axiosInstance.patch(`initiatives/${iid}/shelf-and-posm-images`, datax)
        toast({
          description: data?.message,
          status: "success",
          position: "top"
        });
        setTriggerClose('close' + (Math.random()))
        setRefetch(Math.random())
      } catch(error) {
        toast({
          title: "An Error Occurred",
          description: error?.response?.data?.message,
          status: "error",
          position: "top"
        });
      } finally {
        setULoading(false)
      }
    } else {
    
      if(shelfImages?.length < 1 || posmImages?.length < 1) {
        toast({
          description: "Please ensure that both Shelf and POSM Images are supplied",
          status: "error",
          position: "top"
        });
      } else {
        setULoading(true)
        const datax = {
          shelfImages,
          posmImages
        }
        try {
          const { data } = await axiosInstance.patch(`initiatives/${search.slice(5)}/shelf-and-posm-images`, datax)
          toast({
            description: data?.message,
            status: "success",
            position: "top"
          });
          navigate(`/initiatives/create/3?iid=${search.slice(5)}&mode=new`)
        } catch(error) {
          toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top"
          });
        } finally {
          setULoading(false)
        }
      }

    }
  }

  const datax = [
    {
        title: "Shelf",
        render: <Shelf shelfImages={shelfImages} setShelfImages={setShelfImages} uLoading={uLoading} uploadImages={uploadImages} />
    },
    {
        title: "POSM",
        render: <Posm posmImages={posmImages} setPosmImages={setPosmImages} uLoading={uLoading} uploadImages={uploadImages} />
    }
  ]

  return (
    <>
     <CustomTabs data={datax} />
    </>
  )
}

export default ShelfPosm