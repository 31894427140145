import { Box, Divider, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Switch, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import LineSkeleton from '../../../components/lineSkeleton';
import EmptyState from '../../../components/emptyState/emptyState';
import axiosInstance from '../../../services/api';
import { buildResourceURL, truncateText } from '../../../utils/helper';
import CustomDrawer from '../../../components/customDrawer/customDrawer';
import SecondaryButton from '../../../components/secondaryButton/secondarybutton';
import PrimaryButton from '../../../components/primaryButton/primarybutton';
import CustomInput from '../../../components/customInput/customInput';
import CustomTextArea from '../../../components/customTextArea/customTextArea';
import { useSelector } from 'react-redux';
import TagsInput from 'react-tagsinput';
import countriesData from '../../../utils/countries.json'
import CustomSelect from 'components/customSelect/customSelect';

const AppData = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingc, setLoadingc] = useState(false);
    const [triggerClose, setTriggerClose] = useState("");
    const [refetch, setRefetch] = useState("");
    const [saved, setSaved] = useState(false);
    const [country, setCountry] = useState("")
    const [country2, setCountry2] = useState("")

    const [selectedUser, setSelectedUser] = useState(null)

    const currentUser = useSelector(({userData})=>  userData?.currentUser);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [name, setName] = useState("");
    const [types, setTypes] = useState([]);
    const [levels, setLevels] = useState([]);
    const [levelsx, setLevelsx] = useState([]);

    const [namex, setNamex] = useState("");
    const [typex, setTypex] = useState("");
    const [type, setType] = useState("");

    const toast = useToast()

    const queryParams = [
      {
          key: 'country',
          value: country2
      }
  ]

    const getCategories = async() => {
        setLoading(true)
        try {
            const { data } = await axiosInstance.get(buildResourceURL('store-categories', queryParams))
            setCategories(data?.data)
        } catch(error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getCategories()
    }, [triggerClose, refetch, country2])

    const handleAddTypes = (type) => {
      setTypes(type)
    }

    const handleAddLevels = (level, ref) => {
      setSaved(false)
      setLevels(level)
       setLevelsx([
        {
          name: ref,
          levels: level?.split(', ')
        },
        ...levelsx
      ])
    }


    const createCategory = async(e) => {
        e.preventDefault()
        setLoadingc(true)
        const datax =  {
          name,
          types: levelsx,
          country
        }
        

        try {
          const {data} = await axiosInstance.post('/store-categories', datax)
          toast({
            description: data?.message,
            status: "success",
            position: 'top'
          });
          setTriggerClose('close' + (Math.random()))
        } catch(error) {
          toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
          });
        } finally {
          setLoadingc(false)
        }
      }

    const editCategory = async(e) => {
        e.preventDefault()
        setLoadingc(true)
        const datax =  {
          name: namex,
          types: typex?.split(','),
        }

        try {
          const {data} = await axiosInstance.patch(`/store-categories/${selectedUser?.id}`, datax)
          toast({
            description: data?.message,
            status: "success",
            position: 'top'
          });
          setTypes([])
          setTriggerClose('close' + (Math.random()))
        } catch(error) {
          toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
          });
        } finally {
          setLoadingc(false)
        }
      }

      const handleModal = (user) => {
        setSelectedUser(user)
        onOpen()
      }

    const deleteCategory = async() => {
        setLoadingc(true)
        try {
            const { data } = await axiosInstance.delete(`store-categories/${selectedUser?.id}`)
            toast({
            description: data?.message,
            status: "success",
            position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch(error) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top"
            });
        } finally {
            setLoadingc(false)
        }
    }

    const handleEdit = (name, id, types) => {
        setNamex(name)
        setTypex(types)
        // setSelectedUser({name, id, type})
    }

    const handleToggle = async(id) => {
      setLoadingc(true)
      try {
          const { data } = await axiosInstance.patch(`store-categories/${id}/selection-status`)
          toast({
          description: data?.message,
          status: "success",
          position: "top"
          });
          // setRefetch(Math.random())
      } catch(error) {
          toast({
          title: "An Error Occurred",
          description: error?.response?.data?.message,
          status: "error",
          position: "top"
          });
      } finally {
          setLoadingc(false)
      }
  }

  const countries = countriesData?.map(x => ({
          title: x?.name,
          value: x?.name?.toLowerCase()
        }))


  return (
    <>
        <Flex alignItems={"center"} gap=".8rem" justifyContent={"space-between"}>
            <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>Categories <span style={{padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054"}}>{categories?.length}</span></Text>
            <CustomSelect
                    required
                    options={[{title: "All Countries", value: ""}, ...countries]}
                    onChange={e => setCountry2(e.target.value)}
                    height="40px !important"
                    mt="0"
                    borderRadius={"12px"}
                    width="170px"
                    cursor="pointer"
                    />
            {currentUser?.role === "Super Admin" &&
            <CustomDrawer
            position={"right"}
            title={"Create Category"}
            triggerClose={triggerClose}
            setTriggerClose={setTriggerClose}
            toggleElement={
                <SecondaryButton type="submit" text={"Add Category"} icon="add" loading={false}  />
            }
            content={
                <>
                  <form onSubmit={createCategory}>
                    <CustomSelect
                      required
                      options={countries}
                      placeholder={"Country"}
                      onChange={e => setCountry(e.target.value)}
                    />
                    <CustomInput
                    type={"text"}
                    required
                    placeholder={"Category Name"}
                    onChange={e => setName(e.target.value)}
                    mb=".8rem"
                    />
                    <TagsInput allowUnique={true} value={types} className='tag-input' inputProps={{placeholder: "Add Types"}} maxTags={20} onChange={handleAddTypes} />
                    <Divider my="1rem" />
                    {types?.map(i => (
                      <CustomTextArea
                      required
                      rows="8"
                      placeholder={`Types ${i} (Seperated by commas, e.g one, two, three,..)`}
                      onBlur={e => handleAddLevels(e.target.value, i)}
                      />
                    ))}
                    <Flex justifyContent={"center"}>
                      {saved === false && <PrimaryButton width="100px" py="1.5rem" mx="auto" mt="2rem" onClick={() => setSaved(true)} text="Save" loading={false} />}
                    </Flex>
                    <PrimaryButton mt="2rem" text="Create" type="submit" loading={loadingc} isDisabled={saved === false} />
                    {/* <PrimaryButton mt="2rem" onClick={() => console.log(levelsx)} text="Check" loading={false} /> */}
                  </form>
                </>
            }
            />}
        </Flex>
    
        <Box bg="#FFF">
            <Table mt="1.2rem" borderRadius={"12px"} overflow={"hidden"}>
                <Thead bg="#F9FAFB" border="1px solid #EAECF0">
                    <Tr>
                        <Th textTransform={"capitalize !important"} color={"#475467"}>ID</Th>
                        <Th textTransform={"capitalize !important"} color={"#475467"}>Name</Th>
                        <Th textTransform={"capitalize !important"} color={"#475467"}>Country</Th>
                        <Th textTransform={"capitalize !important"} color={"#475467"}>Types</Th>
                        <Th textTransform={"capitalize !important"} color={"#475467"}>Levels</Th>
                        <Th textTransform={"capitalize !important"} color={"#475467"}>Status</Th>
                        <Th></Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                
                <Tbody>
                {loading ? 
                <LineSkeleton width="100%" num={10} />
                :
                categories?.length < 1 ?
                <EmptyState mt="1.5rem" text="There are no categories added yet" />
                :
                categories?.map((({_id: id, name, types, isSelectable, country}) => {
                    return (
                        <Tr key={id} cursor={"pointer"}>
                            <Td color={"#475467"} fontSize={".9rem"}>{truncateText(id, 15)}</Td>
                            <Td>
                                <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{name}</Text>
                            </Td>
                            <Td>
                                <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{country}</Text>
                            </Td>
                            <Tooltip label={types?.map(i => i?.name)?.join(", ")}>
                              <Td color={"#475467"} fontSize={".9rem"}>
                                  {types?.slice(0, 3)?.map(i => i?.name)?.join(", ")}..
                              </Td>
                            </Tooltip>
                            <Tooltip label={types?.map(i => (
                                <Text>{i?.name} - {i?.levels?.map(i => i)?.join(", ")}</Text>
                              ))}>
                            <Td color={"#475467"} fontSize={".9rem"}>
                              {types?.slice(0,2)?.map(i => (
                                <Text>{i?.name} - {i?.levels?.slice(0, 3)?.map(i => i)?.join(", ")}...</Text>
                              ))}
                            </Td>
                            </Tooltip>
                            <Td>
                              {currentUser?.role === "Super Admin" && <Switch onChange={() => handleToggle(id)} defaultChecked={isSelectable} />}
                            </Td>
                            {currentUser?.role === "Super Admin" &&
                            <Td color={"crimson"} display={"flex"} gap="1rem" fontSize={".85rem"} cursor={"pointer"} fontWeight={"500"}>
                                {/* <CustomDrawer
                                position={"right"}
                                title={"Create Category"}
                                triggerClose={triggerClose}
                                setTriggerClose={setTriggerClose}
                                toggleElement={
                                    <Text onClick={() => handleEdit(name, id, types)} textDecoration={"underline"} mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>Edit</Text>
                                }
                                content={
                                    <>
                                    <form onSubmit={editCategory}>
                                        <CustomInput
                                        type={"text"}
                                        required
                                        value={namex}
                                        placeholder={"Category Name"}
                                        onChange={e => setNamex(e.target.value)}
                                        mb=".8rem"
                                        />
                                        <CustomTextArea
                                        required
                                        rows="8"
                                        value={typex}
                                        placeholder={"Types (Seperated by commas, e.g one, two, three,..)"}
                                        onChange={e => setTypex(e.target.value)}
                                        />
                                        <PrimaryButton mt="2rem" text="Edit" type="submit" loading={loadingc} />
                                    </form>
                                    </>
                                }
                                /> */}
                                <Text onClick={() => handleModal({name, id})} textDecoration={"underline"} mb=".2rem" color={"crimson"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>Delete</Text>
                            </Td>}
                        </Tr>
                    )
                }))
                }
                </Tbody>
            </Table>
        </Box>

        <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{'Delete category'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm deletion of {selectedUser?.name}?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                        <PrimaryButton onClick={deleteCategory} text="Delete" width="150px" bg="crimson !important" padding="1rem !important" loading={loadingc} />
                        </Flex>
                    </>
                    <Text pb="1rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Cancel</Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    </>
  )
}

export default AppData;