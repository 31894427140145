/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Text, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import axiosInstance from '../../../../../services/api';
import { buildResourceURL } from '../../../../../utils/helper';
import { useParams } from 'react-router-dom';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import PrimaryButton from '../../../../../components/primaryButton/primarybutton';
import { InitiativeQueryComponent } from '../../../../../components/initiativeQueryComponent/initiativeQueryComponent';
import classes from './reports.module.css'
import { defaultSearchValueState } from '../../../../../constants/defaultStates';
import { Loader } from '../../../../../components/Loader/loader';
import { formatDateHeader } from '../../../../../helpers/formatDateHeader';
import { MdOutlineCheck, MdOutlineClose, MdStorefront } from 'react-icons/md';
import { InitiativePaginationComponent } from '../../../../../components/initiativePaginationComponent/initiativePaginationComponent';
import { InitiativeEmptyState } from '../../../../../components/initiativeEmptyState/initiativeEmptyState';
import UtilityContext from '../../../../../context/utilityContext';
import { FaRegUser } from 'react-icons/fa';
import moment from 'moment';



const Reports = ({ data }) => {
  const [reports, setReports] = useState([])
  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState('')
  const [refetch, setRefetch] = useState('')
  const [loadingx, setLoadingx] = useState(false)
  const { iid } = useParams()
  const { draw } = useContext(UtilityContext)

  const limit = 50

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast()

  const [selectedUser, setSelectedUser] = useState(null)

  const [searchValues, setSearchValues] = useState(defaultSearchValueState)

  const { page } = searchValues

  const endDate = new Date(searchValues.endDate);  // Create a new date object based on the current date
  endDate.setDate(endDate.getDate() + 1);

  const queryParams = [
    {
      key: 'initiative',
      value: iid
    },
    {
      key: 'page',
      value: page
    },
    {
      key: 'limit',
      value: limit
    },
    {
      key: 'date[gte]',
      value: searchValues.startDate
    },
    {
      key: 'date[lt]',
      value: searchValues?.endDate ? endDate.toISOString().split('T')[0] : ""
    },
    {
      key: 'sort',
      value: '-date'
    },
  ]


  const getReports = async () => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.get(buildResourceURL('/initiative-stores-reports', queryParams))
      setReports(data?.data)
      setTotal(data?.totalCount)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getReports()
  }, [searchValues, refetch])





  const handleModal = (type, user) => {
    setSelectedUser(user)
    onOpen()
  }

  const deleteReport = async () => {
    setLoadingx(true)
    try {
      const res = await axiosInstance.delete(`//reports/${selectedUser?.id}`)
      toast({
        description: res?.data?.message,
        status: "success",
        position: "top"
      });
      onClose()
      setRefetch(Math.random())
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
      });
    } finally {
      setLoadingx(false)
    }
  }



  const [inputPage, setInputPage] = useState(page)

  const queryParamsx = [
    {
      key: 'initiative',
      value: iid
    },
    {
      key: 'date[gte]',
      value: searchValues.startDate
    },
    {
      key: 'date[lt]',
      value: searchValues?.endDate ? endDate.toISOString().split('T')[0] : ""
    },
  ]


  return (
    < div className={classes.screenContainer}>

      <InitiativeQueryComponent
        data={null}
        searchValues={searchValues}
        setSearchValues={setSearchValues}
        entity='reports' queryParamsx={queryParamsx}
      />




      {
        loading ?
          <div className={classes.loader_con}>
            <Loader />
          </div>
          :
          <>
            {
              reports.length ?
                <div className={classes.table_container}>

                  <div className={classes.titleAndInstructionContainer}>
                    <p className={classes.title}>Total Reports Found: {total}</p>
                    <p className={classes.instruction}>Click on each row to see more </p>
                  </div>

                  <p className={classes.showing}>
                    Showing: <span>{reports.length || 1} of {total}</span>
                  </p>
                  <div className={classes.scrollable}>
                    <table className={classes.table}>
                      <thead>
                        <tr>
                          <th>Creation Date</th>
                          <th>Store</th>
                          <th>Location</th>
                          <th>Store Mgt.</th>
                          <th>Personnel Atd.</th>
                          <th>Personnel Kng.</th>
                          <th>Sales</th>
                          <th>MSL</th>
                          <th>SOS</th>
                          <th>OOS</th>
                          <th>Low Stock</th>
                          <th>Orders</th>
                          <th>Payments</th>
                          <th>Shelf</th>
                          <th>Pricing</th>
                        </tr>
                      </thead>
                      <tbody>
                        {reports.map((item, index) => (
                          <tr onClick={() => draw({
                            title: 'Message Thread', content:
                              <div className={classes.modalContainer}>

                                <div className={classes.topButtonContainer}>

                                  <button onClick={() => handleModal(item._id)} className={classes.deleteButton}>Delete</button>
                                </div>

                                <div className={classes.modalTitleAndDateContainer}>
                                  <p>{formatDateHeader(moment(item.date).format('YYYY-MM-DD'))}</p>
                                </div>

                                <div className={classes.modalStoreAddressContainer}>
                                  <p className={classes.modalStoreAddress}>{item?.initiativeStore?.store?.name} - {item?.initiativeStore?.store?.streetNum || 'No Bld. num.'}, {item?.initiativeStore?.store?.streetName}</p>
                                  <MdStorefront className={classes.modalIcon} />
                                </div>

                                <div className={classes.modalStoreAddressContainer}>
                                  <div style={{ width: '90%' }}>
                                    <p className={classes.modalStoreAddress} >{item?.user?.name ? item?.user?.name : `${item?.user?.firstName} ${item?.user?.lastName || ''}`}</p>
                                    <p className={classes.messageDescription}>{item?.user?.role}</p>
                                  </div>

                                  <FaRegUser className={classes.modalIcon} />
                                </div>

                                {(item.storeManagement?.text || item.storeManagement?.imageURL) && <CommentCard title={'Store Management'} imageURL={item.storeManagement?.imageURL} text={item.storeManagement?.text} />}
                                {(item.personnelAttendance?.text || item.personnelAttendance?.imageURL) && <CommentCard title={'Personnel Attendance'} imageURL={item.personnelAttendance?.imageURL} text={item.personnelAttendance?.text} />}
                                {(item.personnelKnowledge?.text || item.personnelKnowledge?.imageURL) && <CommentCard title={'Personnel Knowledge'} imageURL={item.personnelKnowledge?.imageURL} text={item.personnelKnowledge?.text} />}
                                {(item.sales?.text || item.sales?.imageURL) && <CommentCard title={'Sales'} imageURL={item.sales?.imageURL} text={item.sales?.text} />}
                                {(item.mustStockList?.text || item.mustStockList?.imageURL) && <CommentCard title={'Must Stock List (MSL)'} imageURL={item.mustStockList?.imageURL} text={item.mustStockList?.text} />}
                                {(item.shareOfShelf?.text || item.shareOfShelf?.imageURL) && <CommentCard title={'Share of Shelf'} imageURL={item.shareOfShelf?.imageURL} text={item.shareOfShelf?.text} />}
                                {(item.outOfStock?.text || item.outOfStock?.imageURL) && <CommentCard title={'Out of Stock'} imageURL={item.outOfStock?.imageURL} text={item.outOfStock?.text} />}
                                {(item.lowStock?.text || item.lowStock?.imageURL) && <CommentCard title={'Low Stock'} imageURL={item.lowStock?.imageURL} text={item.lowStock?.text} />}
                                {(item.orders?.text || item.orders?.imageURL) && <CommentCard title={'Orders'} imageURL={item.orders?.imageURL} text={item.orders?.text} />}
                                {(item.payments?.text || item.payments?.imageURL) && <CommentCard title={'Payments'} imageURL={item.payments?.imageURL} text={item.payments?.text} />}
                                {(item.shelf?.text || item.shelf?.imageURL) && <CommentCard title={'Shelf'} imageURL={item.shelf?.imageURL} text={item.shelf?.text} />}
                                {(item.pricing?.text || item.pricing?.imageURL) && <CommentCard title={'Pricing'} imageURL={item.pricing?.imageURL} text={item.pricing?.text} />}



                              </div>
                          })} key={index}>
                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>
                                {formatDateHeader(moment(item.date).format('YYYY-MM-DD'))}
                              </p>
                              <p className={classes.tooltip}>
                                {formatDateHeader(moment(item.date).format('YYYY-MM-DD'))}
                              </p>
                            </td>

                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{item.initiativeStore?.store?.name}</p>
                              <p className={classes.tooltip}>{item.initiativeStore?.store?.name}</p>
                            </td>
                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{item.initiativeStore?.store?.state}</p>
                              <p className={classes.tooltip}>{item.initiativeStore?.store?.state}</p>
                            </td>
                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{Object.keys(item?.storeManagement || {}).length ? <MdOutlineCheck className={classes.contentIcon} style={{ color: 'green' }} /> : <MdOutlineClose style={{ color: 'red' }} className={classes.contentIcon} />}</p>
                              <p className={classes.tooltip}>{item.storeManagement?.text || 'Nothing'}</p>
                            </td>
                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{Object.keys(item?.personnelAttendance || {}).length ? <MdOutlineCheck className={classes.contentIcon} style={{ color: 'green' }} /> : <MdOutlineClose style={{ color: 'red' }} className={classes.contentIcon} />}</p>
                              <p className={classes.tooltip}>{item.personnelAttendance?.text || 'Nothing'}</p>
                            </td>
                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{Object.keys(item?.personnelKnowledge || {}).length ? <MdOutlineCheck className={classes.contentIcon} style={{ color: 'green' }} /> : <MdOutlineClose style={{ color: 'red' }} className={classes.contentIcon} />}</p>
                              <p className={classes.tooltip}>{item.personalKnowledge?.text || 'Nothing'}</p>
                            </td>
                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{Object.keys(item?.sales || {}).length ? <MdOutlineCheck className={classes.contentIcon} style={{ color: 'green' }} /> : <MdOutlineClose style={{ color: 'red' }} className={classes.contentIcon} />}</p>
                              <p className={classes.tooltip}>{item.sales?.text || 'Nothing'}</p>
                            </td>
                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{Object.keys(item?.mustStockList || {}).length ? <MdOutlineCheck className={classes.contentIcon} style={{ color: 'green' }} /> : <MdOutlineClose style={{ color: 'red' }} className={classes.contentIcon} />}</p>
                              <p className={classes.tooltip}>{item.sales?.msl || 'Nothing'}</p>
                            </td>
                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{Object.keys(item?.shareOfShelf || {}).length ? <MdOutlineCheck className={classes.contentIcon} style={{ color: 'green' }} /> : <MdOutlineClose style={{ color: 'red' }} className={classes.contentIcon} />}</p>
                              <p className={classes.tooltip}>{item.sales?.shareOfShelf || 'Nothing'}</p>
                            </td>
                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{Object.keys(item?.outOfStock || {}).length ? <MdOutlineCheck className={classes.contentIcon} style={{ color: 'green' }} /> : <MdOutlineClose style={{ color: 'red' }} className={classes.contentIcon} />}</p>
                              <p className={classes.tooltip}>{item.sales?.outOfStock || 'Nothing'}</p>
                            </td>
                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{Object.keys(item?.lowStock || {}).length ? <MdOutlineCheck className={classes.contentIcon} style={{ color: 'green' }} /> : <MdOutlineClose style={{ color: 'red' }} className={classes.contentIcon} />}</p>
                              <p className={classes.tooltip}>{item.sales?.lowStock || 'Nothing'}</p>
                            </td>
                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{Object.keys(item?.orders || {}).length ? <MdOutlineCheck className={classes.contentIcon} style={{ color: 'green' }} /> : <MdOutlineClose style={{ color: 'red' }} className={classes.contentIcon} />}</p>
                              <p className={classes.tooltip}>{item.sales?.orders || 'Nothing'}</p>
                            </td>
                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{Object.keys(item?.payments || {}).length ? <MdOutlineCheck className={classes.contentIcon} style={{ color: 'green' }} /> : <MdOutlineClose style={{ color: 'red' }} className={classes.contentIcon} />}</p>
                              <p className={classes.tooltip}>{item.sales?.payments || 'Nothing'}</p>
                            </td>
                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{Object.keys(item?.shelf || {}).length ? <MdOutlineCheck className={classes.contentIcon} style={{ color: 'green' }} /> : <MdOutlineClose style={{ color: 'red' }} className={classes.contentIcon} />}</p>
                              <p className={classes.tooltip}>{item.sales?.shelf || 'Nothing'}</p>
                            </td>
                            <td className={classes.tooltip_container}>
                              <p className={classes.item_text}>{Object.keys(item?.pricing || {}).length ? <MdOutlineCheck className={classes.contentIcon} style={{ color: 'green' }} /> : <MdOutlineClose style={{ color: 'red' }} className={classes.contentIcon} />}</p>
                              <p className={classes.tooltip}>{item.sales?.pricing || 'Nothing'}</p>
                            </td>
                          </tr>
                        ))}
                      </tbody>


                    </table>

                  </div>




                  <InitiativePaginationComponent
                    page={page} totalCount={total} setSearchValues={setSearchValues} setInputPage={setInputPage} inputPage={inputPage} limit={limit}
                  />
                </div>
                :
                <InitiativeEmptyState searchValues={searchValues} name={'reports'} />

            }



            <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{`Delete Report`}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {/* {modalType === 'delete' && */}
                  <>
                    <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm deletion of report?</Text>
                    <Flex justifyContent={"center"} my="1.3rem">
                      <PrimaryButton onClick={deleteReport} text="Delete" width="150px" bg="crimson !important" padding="1rem !important" loading={loadingx} />
                    </Flex>
                  </>
                  {/* 
                    } */}
                  <Text pb="1rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Cancel</Text>
                </ModalBody>
              </ModalContent>
            </Modal>

          </>
      }



    </div>
  )
}

function CommentCard({ title, imageURL, text }) {

  const viewImage = (imgUrl) => {
    window.open(imgUrl, '_blank')
  }


  return (

    <div className={classes.reportsCommentContainer}>
      <div className={classes.modalTitleAndDateContainer}>
        <p>{title}</p>
      </div>
      <div className={classes.modalCommentContainer}>
        {imageURL &&
          <img onClick={() => { viewImage(imageURL) }} src={imageURL} alt={text} className={classes.modalImage} />
        }
        <p className={classes.messageDescription}>{text || 'No comment found'}</p>
      </div>
    </div>
  )
}

export default Reports;