import axiosInstance from 'services/api';
import classes from '../../components/initiativeQueryComponent/initiativeQueryComponent.module.css'
import { buildResourceURL } from 'utils/helper';
import { saveAs } from 'file-saver';
import { useState } from 'react';
import { Spinner } from '@chakra-ui/react';

const ExcelJS = require('exceljs');


const createExcelFileBufferWithExcelJS = async (excelData) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`Data`);

    if (excelData.length > 0) {
        // Extract headers from the first row's keys
        worksheet.columns = Object.keys(excelData[0]).map((key) => ({
            header: key,
            key,
            width: 30, // Adjust width for readability
        }));

        // Style the header row
        const headerRow = worksheet.getRow(1);
        headerRow.eachCell((cell) => {
            cell.font = { bold: true };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFD2D2D2' },
            };
            cell.alignment = { wrapText: true };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            };
        });

        // Add data rows
        excelData.forEach((row) => {
            const excelRow = worksheet.addRow(row);
            excelRow.eachCell({ includeEmpty: true }, (cell) => {
                cell.alignment = { wrapText: true };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };
            });
        });
    }

    const buffer = await workbook.xlsx.writeBuffer();
    return buffer;
};



const exportToExcel = async (excelData, title) => {

    // Generate Excel buffer
    const buffer = await createExcelFileBufferWithExcelJS(excelData);

    // Create a blob from the buffer
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${title}.xlsx`;

    // Append the link to the document and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up the link element
    document.body.removeChild(link);
};

function NewExport({ excelData, title }) {
    return (
        <button className={classes.exportButton} onClick={() => { exportToExcel(excelData, title) }}>Export</button>
    )
}


function ServerExport2({ entity, queryParamsx }) {

    const [downloading, setDownloading] = useState(false)

    const downloadResponse = async () => {

        setDownloading(true)

        try {
            const res = await axiosInstance.get(buildResourceURL(entity, queryParamsx), {
                responseType: 'blob', // Ensures binary data is handled correctly
            });

            // Create a Blob from the response data
            const blob = new Blob([res?.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            console.log(blob)

            // Use FileSaver.js to save the file
            saveAs(blob, `${entity}_results.xlsx`);
        } catch (error) {
            console.log(error);
            const msg = error?.response?.data?.message || 'unexpected error';
            console.log(msg);

        } finally {
            setDownloading(false)
        }
    };


    return (
        <button className={classes.exportButton} onClick={downloadResponse}>
            {downloading ? <Spinner /> : 'Export'}
        </button>
    )
}

export { NewExport, ServerExport2 }