import { useContext } from 'react'
import classes from './drawer.module.css'
import UtilityContext from '../../context/utilityContext'

export function Drawer() {

    const { drawerProps, closeDrawer } = useContext(UtilityContext)
    const { title, content } = drawerProps
    return (

        <div className={title ? classes.drawer_main_container : classes.drawer_main_container_inactive}>
            <div className={classes.drawer_sub_container}>
                {content}
                <button onClick={closeDrawer} className={classes.cancelContainer}>Cancel</button>
            </div>

        </div>

    )
}