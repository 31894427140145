import { Box } from "@chakra-ui/react";
import AppData from "./components/appData";
import CustomTabs from "../../components/customTabs/customTabs";
import ChangePassword from "./components/changePassword";
import { useSelector } from "react-redux";
import Admins from "./components/admins";
import Rewards from "./components/rewards";
import RegionsTable from "../dashboard/clients/clientRegions/regionsTable/regionsTable";

const Settings = () => {

    const currentUser = useSelector(({userData})=>  userData?.currentUser);

    const data = [
        {
            title: "App Data",
            render: <AppData />
        },
        {
            title: "Privacy & Security",
            render: <ChangePassword />
        },
        {
            title: "Access Management",
            render: <Admins />
        },
        {
            title: "Rewards",
            render: <Rewards />
        },
    ]

    const data3 = [
        {
            title: "App Data",
            render: <AppData />
        },
        {
            title: "Privacy & Security",
            render: <ChangePassword />
        }
    ]

    const data2 = [
        {
            title: "Privacy & Security",
            render: <ChangePassword />
        },
        {
            title: "Region Filters",
            render: <RegionsTable clientId={currentUser?._id} regionsData={currentUser?.statesFilterGroups} />
        }
    ]

    const data4 = [
        {
            title: "App Data",
            render: <AppData />
        },
        {
            title: "Privacy & Security",
            render: <ChangePassword />
        }
    ]

    const data5 = [
        {
            title: "Privacy & Security",
            render: <ChangePassword />
        }
    ]

    return (
        <Box>
            <CustomTabs data={currentUser?.role === "Client" ? data2 : currentUser?.role === "Admin" ? data3 : currentUser?.role === "Agency" ? data4 : currentUser?.role === "Basic Subuser" ? data5 : data} />
        </Box>
    )
}

export default Settings;