import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import GoBack from '../../../../components/goBack'
import Mechanics from './components/mechanics'
import ShelfPosm from './components/shelfPosm/shelfPosm'
import AssignStore from './components/assignStore/assignStore'
import Draws from './components/draws/draws'

const CreateInitiative = () => {
  const {step} = useParams()
  const {search} = useLocation()
  const steps = ["1", "2", "3", "4"]
  const navigate = useNavigate()

  return (
    <Box bg="#FFF" borderRadius={"12px"} p="1.2rem 1.5rem">
        <Flex mb="2rem" alignItems={"center"} justifyContent={"space-between"}>
            <Box>
                <GoBack />
                {step === "1" && 
                <>
                <Text mt=".5rem" mb=".2rem" fontWeight={"500"} fontSize={"1.2rem"}>Mechanics</Text>
                <Text>Create initiative for specific brands around you</Text>
                </>
                }
                {step === "2" && 
                <>
                <Text mt=".5rem" mb=".2rem" fontWeight={"500"} fontSize={"1.2rem"}>Shelf & POSM</Text>
                <Text opacity={"0"}>Create initiative for specific brands around you</Text>
                </>
                }
                {step === "3" && 
                <>
                <Text mt=".5rem" mb=".2rem" fontWeight={"500"} fontSize={"1.2rem"}>Initiative Rewards</Text>
                <Text opacity={"0"}>Create initiative for specific brands around you</Text>
                </>
                }
                {step === "4" && 
                <>
                <Text mt=".5rem" mb=".2rem" fontWeight={"500"} fontSize={"1.2rem"}>Assign Store</Text>
                <Text opacity={"0"}>Create initiative for specific brands around you</Text>
                </>
                }
            </Box>
            <Flex gap="1rem" alignItems={"center"}>
                {steps?.map((i) => (
                    <Flex onClick={search?.length > 0 ? () => navigate(`/initiatives/create/${i}`) : () => {}} cursor={"pointer"} transition={"250ms ease"} justifyContent={"center"} borderRadius={"50%"} alignItems={"center"} height={"34px"} width={"34px"} fontWeight={step === i ? "700" : "400"} color={step === i ? "#FFF" : "#344054"} bg={step === i ? "#7F56D9" : "transparent"} border={step === i ? "1px solid #7F56D9" : "1px solid #344054"}>
                        <Text>{i}</Text>
                    </Flex>
                ))}
            </Flex>
        </Flex>
        {step === "1" && <Mechanics />}
        {step === "2" && <ShelfPosm />}
        {step === "3" && <Draws />}
        {step === "4" && <AssignStore />}
    </Box>
  )
}

export default CreateInitiative;