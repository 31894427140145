/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import classes from './assets.module.css'
import { useContext, useEffect, useState } from "react";
import QRCode from 'qrcode';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { CiSearch } from "react-icons/ci";
import { defaultSearchValueState } from "constants/defaultStates";
import UtilityContext from "context/utilityContext";
import MapWithMarkers from "components/mapWIthMarkers/mapWithMarkers";
import { Spinner, useToast } from "@chakra-ui/react";
import { Loader } from "components/Loader/loader";
import { TfiMapAlt } from "react-icons/tfi";
import { MdDeleteForever, MdOutlineFileDownload, MdStorefront } from "react-icons/md";
import { saveAs } from 'file-saver'
import JSZip from "jszip";
import GoBack from "components/goBack";
import { AssetSideNav } from "components/assets/assetsSideNav";
import CreateStore from "pages/dashboard/stores/createStore/createStore";
import { IoIosArrowDropdownCircle, IoMdClose, IoMdCloseCircleOutline, IoMdReturnRight } from "react-icons/io";
import { AssetComment } from "./components/AssetComment";
import { AssetInventory } from "./components/AssetInventory";
import { AssetCondition } from "./components/AssetCondition";
import { AssetMSL } from "./components/AssetMSL";
import { AssetImage } from "./components/AssetImage";
import { AssetOverview } from "components/assets/assetsOverView";
import AssetTable from "./components/Overview";
import { InitiativePaginationComponent } from "components/initiativePaginationComponent/initiativePaginationComponent";
import { formatDateHeader } from "helpers/formatDateHeader";
import moment from "moment";
import defaultImage from '../../assets/placeholderImage.png'
import clickHere from '../../assets/uploadImage.png'
import { FaCheck } from "react-icons/fa";
import { LuHourglass } from "react-icons/lu";
import AvailableForRegistration from "./components/assetsAvailableForRegistration";


const url = process.env.REACT_APP_BASE_URL;

const rolesRequiringParams = ['Super Admin', 'Admin', 'Agency']

export default function AssetsList({ client, name }) {

    const { draw, drawerProps } = useContext(UtilityContext)

    const currentUser = useSelector(({ userData }) => userData.currentUser);

    const location = useLocation()
    const navigate = useNavigate()

    const params = new URLSearchParams(location.search);
    const clientId = params.get('clientId') || client;
    const companyName = params.get('name') || name;

    const [activeScreen, setActiveScreen] = useState("Active Assets");
    const [modal, setModal] = useState(null)
    const [refetchAllGroups, setRefetchAllGroups] = useState(false)
    const [brandGroups, setBrandGroups] = useState([])
    const [storeGroups, setStoreGroups] = useState([])
    const [loading, setLoading] = useState(false)

    const toast = useToast()


    const showToast = (description, status) => {
        toast({ description, status, position: 'top' });
    };


    const items = {
        "Active Assets": <AllAssets brandGroups={brandGroups} storeGroups={storeGroups} draw={draw} currentUser={currentUser} clientId={clientId} navigate={navigate} companyName={companyName} setModal={setModal} showToast={showToast} setRefetchAllGroups={setRefetchAllGroups} />,
        "Assets Comments": <AssetComment brandGroups={brandGroups} storeGroups={storeGroups} draw={draw} clientId={clientId} />,
        "Assets Inventories": <AssetInventory brandGroups={brandGroups} storeGroups={storeGroups} draw={draw} clientId={clientId} />,
        "Assets Conditions": <AssetCondition brandGroups={brandGroups} storeGroups={storeGroups} draw={draw} clientId={clientId} />,
        "Assets MSL Scores": <AssetMSL brandGroups={brandGroups} storeGroups={storeGroups} draw={draw} clientId={clientId} />,
        "Assets Images": <AssetImage brandGroups={brandGroups} storeGroups={storeGroups} draw={draw} clientId={clientId} />,
        "Overview": <AssetTable brandGroups={brandGroups} storeGroups={storeGroups} draw={draw} clientId={clientId} showToast={showToast} />

    };


    console.log(storeGroups)

    const groupTypeMapping = {
        'store groups': 'stores',
        'asset groups': 'assets',
    }


    const getQueryGroups = async (type) => {
        const token = localStorage.getItem('PRTK');
        const config = {
            headers: {
                authorization: `Bearer ${token}`,
            },
        };

        try {

            const endpoint = (groupingType) => {
                return (axios.get(`${url}/assets/clients/groups?clientId=${clientId}&groupingType=${groupingType}`, config))
            }


            if (type === 'stores') {
                const storeResponse = await endpoint('stores')
                setStoreGroups(storeResponse?.data?.data?.assetGroups || [])
            } else if (type === 'assets') {
                const brandResponse = await endpoint('assets')
                setStoreGroups(brandResponse?.data?.data?.assetGroups || [])
            } else {
                const [storeResponse, brandResponse] = await Promise.all([
                    endpoint('stores'),
                    endpoint('assets'),
                ])


                setBrandGroups(brandResponse?.data?.data?.assetGroups || []);
                setStoreGroups(storeResponse?.data?.data?.assetGroups || [])
            }

        } catch (error) {
            console.error(error)
            const message = error?.response?.data?.message || 'Something went wrong';
            showToast(message, 'error');
        }
    };

    useEffect(() => {
        if (rolesRequiringParams.includes(currentUser?.role) && !clientId) {
            navigate('/');
            return
        }
        const fn = async () => {
            setLoading(true)
            await getQueryGroups(groupTypeMapping[drawerProps?.title]);
            setLoading(false)
        }

        fn()

    }, [clientId, currentUser, refetchAllGroups]);




    return (
        <div className={classes.container}>



            {
                loading ?

                    <div className={classes.loaderCon}>
                        <Spinner />
                    </div>
                    :
                    <>
                        {
                            modal &&
                            <div className={classes.createContainer}>
                                {modal}
                            </div>
                        }



                        <div className={classes.nameAndBackContainer}>
                            <GoBack />
                            <p>{companyName}'s Assets </p>
                        </div>




                        <div className={classes.screenContainer}>
                            <div className={classes.itemsContainer}>
                                <AssetSideNav
                                    activeScreen={activeScreen}
                                    setActiveScreen={setActiveScreen}
                                />
                                <AssetOverview
                                    activeScreen={activeScreen}
                                    setActiveScreen={setActiveScreen}
                                />
                            </div>

                            <div className={classes.screen}> {items[activeScreen]}</div>
                        </div>
                    </>
            }






        </div>
    );
}


function AllAssets({ draw, currentUser, clientId, navigate, companyName, setModal, showToast, setRefetchAllGroups }) {

    const limit = 12


    const [showMap, setShowMap] = useState(null)


    const [selectedAssets, setSelectedAssets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null)
    const [pendingData, setPendingData] = useState(null)
    const [searchValues, setSearchValues] = useState(defaultSearchValueState)


    const [inputPage, setInputPage] = useState(searchValues?.page);


    const [pending, setPending] = useState(false)


    const handleInputChange = (e) => {
        const { value, name } = e.target;
        setSearchValues((prev) => ({ ...prev, [name]: value }));
    };

    const getAssetList = async (page) => {
        const token = localStorage.getItem('PRTK');
        if (!token) {
            console.error("Authorization token is missing.");
            return;
        }

        const config = {
            headers: { authorization: `Bearer ${token}` },
        };

        setLoading(true);

        const queryParams = {
            clientId,
            search: searchValues?.searchQuery || '',
            startDate: searchValues?.startDate || '',
            endDate: searchValues?.endDate || '',
            limit,
            page: page || searchValues?.page || 1,
        };

        const constructUrl = (isPending) =>
            `${url}/assets?${new URLSearchParams({ ...queryParams, pending: isPending, sort: '-updatedAt' }).toString()}`;

        try {
            const [genuineResponse, pendingResponse] = await Promise.all([
                axios.get(constructUrl(false), config),
                axios.get(constructUrl(true), config),
            ]);

            if (page) {
                setInputPage(page)
            }

            setData(genuineResponse.data.data || []);
            setPendingData(pendingResponse.data.data || []);
        } catch (error) {
            console.error("Error fetching asset data:", error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        if (rolesRequiringParams.includes(currentUser?.role) && !clientId) {
            navigate('/');
            return
        }
        getAssetList();
    }, [clientId, currentUser, searchValues]);




    const handleQrDownload = async (brand, category, type, id, outlet) => {
        try {
            // Generate the QR code as a Data URL
            const qrCodeDataUrl = await QRCode.toDataURL(id, { margin: 2, scale: 8 });

            // Create a canvas
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            // Set canvas dimensions
            const canvasWidth = 300;
            const canvasHeight = 370; // Extra space for multiple text lines
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            // Set the background color to dark blue
            context.fillStyle = '#001a2ac8'; // Dark blue with transparency
            context.fillRect(0, 0, canvasWidth, canvasHeight);

            // Add "powered by Promorama" at the top
            context.fillStyle = '#B0B0B0'; // Soft grey
            context.font = '12px Arial';
            context.textAlign = 'center';
            context.fillText('powered by Promorama', canvasWidth / 2, 15);

            // Draw the QR code onto the canvas
            const qrImage = new Image();
            qrImage.src = qrCodeDataUrl;

            qrImage.onload = () => {
                const qrSize = 250; // Size of the QR code
                const qrX = (canvasWidth - qrSize) / 2; // Center horizontally
                const qrY = 30; // Below the top text
                context.drawImage(qrImage, qrX, qrY, qrSize, qrSize);

                // Add brand-category-type text below the QR code
                context.fillStyle = '#FFFFFF'; // White text
                context.font = 'bold 16px Arial';
                context.fillText(`${brand} - ${category} - ${type}`, canvasWidth / 2, qrY + qrSize + 30);

                // Add outlet text on a separate line
                context.font = '14px Arial';
                context.fillText(`${outlet}`, canvasWidth / 2, qrY + qrSize + 60);

                // Create a temporary link element for download
                const link = document.createElement('a');
                link.href = canvas.toDataURL('image/png');
                link.download = `${brand}-${category}-${type}-QR.png`;

                // Trigger the download
                link.click();
            };

            qrImage.onerror = () => {
                console.error('Failed to load QR code image.');
            };
        } catch (error) {
            console.error('QR code generation or download failed', error);
        }
    };




    const [popUpSelectionCheckbox, selectPopUpSelectionCheckbox] = useState(false)

    const bulkDownloadQrCodes = async () => {
        if (selectedAssets.length === 0) {
            console.warn("No assets selected for download");
            return;
        }

        const zip = new JSZip();

        try {
            await Promise.all(
                selectedAssets.map(async (asset) => {
                    const { brand, category, type, id, outlet } = asset; // Include outlet

                    // Generate QR code data URL
                    const qrCodeDataUrl = await QRCode.toDataURL(id, { margin: 2, scale: 8 });

                    // Create a canvas and add text
                    const canvas = document.createElement("canvas");
                    const context = canvas.getContext("2d");
                    const canvasWidth = 300;
                    const canvasHeight = 370; // Adjusted height for extra text
                    canvas.width = canvasWidth;
                    canvas.height = canvasHeight;

                    // Set background color and text styling
                    context.fillStyle = "#001a2ac8"; // Dark blue background
                    context.fillRect(0, 0, canvasWidth, canvasHeight);

                    // Add "powered by Promorama" at the top
                    context.fillStyle = "#B0B0B0"; // Soft grey text
                    context.font = "12px Arial";
                    context.textAlign = "center";
                    context.fillText("powered by Promorama", canvasWidth / 2, 15);

                    // Add QR code to canvas
                    const qrImage = new Image();
                    qrImage.src = qrCodeDataUrl;

                    await new Promise((resolve) => {
                        qrImage.onload = () => {
                            const qrSize = 250;
                            const qrX = (canvasWidth - qrSize) / 2;
                            const qrY = 30;
                            context.drawImage(qrImage, qrX, qrY, qrSize, qrSize);

                            // Add brand-category-type text below the QR code
                            context.fillStyle = "#FFFFFF"; // White text
                            context.font = "bold 16px Arial";
                            context.fillText(`${brand} - ${category} - ${type}`, canvasWidth / 2, qrY + qrSize + 30);

                            // Add outlet text on a separate line
                            context.font = "14px Arial";
                            context.fillText(`${outlet.name} - ${outlet.town}, ${outlet.state}`, canvasWidth / 2, qrY + qrSize + 60);

                            resolve();
                        };
                    });

                    // Add image to ZIP
                    const canvasDataUrl = canvas.toDataURL("image/png");
                    const imgData = canvasDataUrl.split(",")[1];
                    zip.file(`${brand}-${category}-${type}-${outlet}-QR.png`, imgData, { base64: true }); // Include outlet in filename
                })
            );

            // Generate ZIP file and trigger download
            const zipBlob = await zip.generateAsync({ type: "blob" });
            saveAs(zipBlob, "SelectedAssets_QRCodes.zip");
        } catch (error) {
            console.error("Error during bulk QR code download", error);
        }
    };


    const handlePopUSelectionChange = () => {

        selectPopUpSelectionCheckbox(!popUpSelectionCheckbox)

        if (popUpSelectionCheckbox) {
            setSelectedAssets([])
        }
    }



    return (
        <div>
            {
                showMap &&
                <div className={classes.showMapParent}>
                    <div className={classes.showMapSecondParent}>
                        <MapWithMarkers singleMarker={showMap} />
                        <button onClick={() => { setShowMap(null) }} className={classes.closeMap}>Close Map</button>
                    </div>
                </div>
            }
            <div className={classes.topContainer}>
                <div className={classes.inputContainer}>
                    <CiSearch className={classes.searchIcon} />
                    <input
                        className={classes.input}
                        type="text"
                        placeholder={"Search by brand name / category, or full address"}
                        onChange={handleInputChange}
                        name="searchQuery"
                        value={searchValues?.searchQuery}
                    />
                </div>
                <div className={classes.dateGrouped}>
                    <div className={classes.dateContainer}>
                        <input
                            name="startDate"
                            value={searchValues.startDate}
                            onChange={handleInputChange}
                            className={classes.input}
                            type="date"
                        />
                    </div>
                    to
                    <div className={classes.dateContainer}>
                        <input
                            name="endDate"
                            value={searchValues?.endDate}
                            onChange={handleInputChange}
                            className={classes.input}
                            type="date"
                        />
                    </div>
                </div>

                <div onClick={() => { setPending((prev) => !prev); setSearchValues(defaultSearchValueState) }} className={classes.dataGenuinenessNameContainer}>
                    <div style={pending ? { background: 'green' } : { background: 'red' }} className={classes.dataGenuinenessNumberContainer}>{pending ? data?.totalCount : pendingData?.totalCount}</div>
                    <button className={`${classes.createButton} ${pending ? classes.pendingButton : classes.genuineBtn}`}>
                        {pending ? 'Show Genuine ' : 'Show Pending '}
                        Assets
                        {pending ? <FaCheck /> : <LuHourglass />}
                    </button>
                </div>

                <button className={classes.createButton}
                    onClick={() => {
                        draw({
                            title: 'Create Asset',
                            content: <CreateAsset companyName={companyName} clientId={clientId} getAssetList={getAssetList} setModal={setModal} showToast={showToast} />
                        })
                    }}
                >Create Asset</button>

            </div>



            <div className={classes.downloadSection}>

                <p className={classes.showing}>
                    Total {pending ? 'Pending' : 'Genuine'} Assets found: {pending ? pendingData?.totalCount : data?.totalCount}
                </p>

                {
                    !pending &&
                    <>

                        <button className={`${classes.createButton} ${classes.manageUsersButton}`} onClick={() => {
                            draw({
                                title: 'store groups',
                                content: <ManageGroups companyName={companyName} setModal={setModal} clientId={clientId} showToast={showToast} setRefetchAllGroups={setRefetchAllGroups} groupingType={'store'} />
                            })
                        }}>
                            Store groups
                        </button>

                        <button className={`${classes.createButton} ${classes.manageUsersButton}`} onClick={() => {
                            draw({
                                title: 'asset groups',
                                content: <ManageGroups companyName={companyName} setModal={setModal} clientId={clientId} showToast={showToast} setRefetchAllGroups={setRefetchAllGroups} groupingType={'asset'} />
                            })
                        }}>
                            Asset groups
                        </button>

                        <button className={`${classes.createButton}`} onClick={() => {
                            draw({
                                title: 'Users',
                                content: <ManageUsers companyName={companyName} setModal={setModal} clientId={clientId} showToast={showToast} />
                            })
                        }}>
                            Users
                        </button>


                        <button className={`${classes.createButton}`} onClick={() => {
                            draw({
                                title: 'Brands',
                                content: <AvailableForRegistration clientId={clientId} showToast={showToast} />
                            })
                        }}>
                            Brands
                        </button>




                        <div className={classes.divider} />


                        <button className={`${classes.createButton} ${classes['select-qr']}`} onClick={handlePopUSelectionChange}>
                            {popUpSelectionCheckbox ? 'Unselect All QR' : 'Select Multiple QR'}
                        </button>

                        <button className={`${classes.createButton} ${classes.downloadAll}`} onClick={bulkDownloadQrCodes}>Download {selectedAssets.length ?
                            `(${selectedAssets.length ? selectedAssets.length : ''}) Selected` : 'All'
                        } QR</button>
                    </>
                }
            </div>


            <div className={classes.sub_container}>
                {loading ? (
                    <div className={classes.allCards}>
                        <div className={classes.loaderCon}>
                            <Loader />
                        </div>
                    </div>
                ) : (
                    <div>
                        <List setSelectedAssets={setSelectedAssets} selectedAssets={selectedAssets} data={pending ? pendingData : data} handleQrDownload={handleQrDownload} setShowMap={setShowMap} popUpSelectionCheckbox={popUpSelectionCheckbox} draw={draw} showToast={showToast} pending={pending} getAssetList={getAssetList} clientId={clientId} searchValues={searchValues} pendingData={pendingData} setSearchValues={setSearchValues} setInputPage={setInputPage} inputPage={inputPage} limit={limit} />


                    </div>

                )}
            </div>
        </div>
    )
}


function List({ data, handleQrDownload, selectedAssets, setShowMap, setSelectedAssets, popUpSelectionCheckbox, draw, showToast, pending, getAssetList, clientId, inputPage, setInputPage, searchValues, setSearchValues, limit, pendingData }) {



    const toggleSelectAsset = (asset) => {
        setSelectedAssets((prev) => {
            const exists = prev.find((a) => a.id === asset.id);
            return exists
                ? prev.filter((a) => a.id !== asset.id)
                : [...prev, { id: asset.id, brand: asset.brand, type: asset.type, category: asset.category, outlet: asset.outlet }];
        });
    };




    return (
        <div >
            {
                data?.assets?.length === 0 ? (
                    <div className={classes.no_image_container}>
                        {/* <img className={classes.noImage} src={noImage} alt='No events available' /> */}
                        <p className={classes.no_item_to_display_text}>
                            There are currently zero (0) items that match at the moment. Don't fret; you can check back later—we might just have your desired interest!
                        </p>
                    </div>
                )
                    :
                    <div>
                        <div className={classes.allCards}>

                            {

                                data?.assets?.map((item, index) => (

                                    <div key={index} className={classes.listParentHoldingCheckbox}>
                                        {
                                            popUpSelectionCheckbox &&
                                            <input
                                                type="checkbox"
                                                checked={selectedAssets.some((asset) => asset.id === item._id)}
                                                onChange={() => toggleSelectAsset({
                                                    id: item._id,
                                                    brand: item.brand,
                                                    type: item.type,
                                                    category: item.category,
                                                    outlet: item.outlet
                                                })}
                                                className={classes.AssetsSelectionCheckbox}
                                            />
                                        }


                                        <div className={classes.ticketsContainer}>


                                            <div className={classes.qrContainer}>
                                                <div
                                                    className={classes.imageContainer}
                                                    style={{
                                                        backgroundImage: `url(${item.image ? item.image : 'https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM='})`,
                                                    }}>

                                                    <div onClick={() => {
                                                        setShowMap({
                                                            longitude: item?.outlet?.location?.coordinates[0] || item.addressDetails?.coordinates?.longitude,
                                                            latitude: item?.outlet?.location?.coordinates[1] || item.addressDetails?.coordinates?.latitude
                                                        })
                                                    }} className={classes.viewMapIconBackground}>
                                                        <TfiMapAlt className={classes.viewMapIcon} />
                                                    </div>

                                                    <div onClick={() => {
                                                        setShowMap({
                                                            longitude: item?.outlet?.location?.coordinates[0] || item.addressDetails?.coordinates?.longitude,
                                                            latitude: item?.outlet?.location?.coordinates[1] || item.addressDetails?.coordinates?.latitude
                                                        })
                                                    }} className={classes.viewMapIconBackground}>
                                                        <TfiMapAlt className={classes.viewMapIcon} />
                                                    </div>
                                                    {
                                                        !pending &&

                                                        <div
                                                            onClick={() => handleQrDownload(item.brand, item.category, item.type, item._id, `${item.outlet?.name} - ${item.outlet.town}, ${item.outlet.state}`)}
                                                            className={`${classes.viewMapIconBackground} ${classes.download}`}>
                                                            <MdOutlineFileDownload style={{ color: '#fff' }} className={classes.viewMapIcon} />
                                                        </div>
                                                    }

                                                </div>
                                            </div>

                                            <div className={classes.subTicketInfoContainer}>
                                                <p className={classes.brandTitle}>{item.brand}</p>
                                                <p className={classes.brandDesc} >category: <span className={classes.brandTitle} style={{ fontWeight: 'bold' }}>{item.category}</span></p>
                                                <p className={classes.brandDesc}>type: <span className={classes.brandTitle} style={{ fontWeight: 'bold' }}>{item.type || 'no assigned type'}</span></p>
                                            </div>

                                            {
                                                item.pending ?
                                                    <button
                                                        onClick={() =>
                                                            draw({
                                                                title: "Approve Asset",
                                                                content: (
                                                                    <EditAsset assetDetails={item} action={'approve'} showToast={showToast} getAssetList={getAssetList} clientId={clientId} setShowMap={setShowMap} />
                                                                ),
                                                            })
                                                        }
                                                        style={{ background: 'green' }} className={classes.downloadButton}>
                                                        Open Registered Info.
                                                    </button>
                                                    :
                                                    <button
                                                        className={classes.downloadButton}
                                                        onClick={() =>
                                                            draw({
                                                                title: "Open Asset",
                                                                content:
                                                                    <OpenAsset draw={draw} item={item} showToast={showToast} getAssetList={getAssetList} clientId={clientId} setShowMap={setShowMap} />
                                                                ,
                                                            })
                                                        }
                                                    >
                                                        Open Assets
                                                    </button>
                                            }

                                        </div>

                                    </div>

                                ))
                            }

                        </div>

                        <InitiativePaginationComponent
                            page={searchValues?.page} totalCount={pending ? pendingData?.totalCount : data?.totalCount} setSearchValues={setSearchValues} setInputPage={setInputPage} inputPage={inputPage} limit={limit}
                        />
                    </div>
            }

        </div >
    )
}


function CreateAsset({ companyName, clientId, getAssetList, setModal, showToast }) {
    const defaultState = { brand: '', category: '', type: '', outlet: '', image: null };
    const [values, setValues] = useState(defaultState);
    const [loading, setLoading] = useState(false);
    const [outletSearchQuery, setOutletSearchQuery] = useState('');
    const [outlets, setOutlets] = useState([]);
    const [loadingOutlets, setLoadingOutlets] = useState(false);
    const [refetch, setRefetch] = useState(true)


    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setValues((prev) => ({
                ...prev,
                [e.target.name]: { localUrl: URL.createObjectURL(file), file },
            }));
        }
    };


    const selectOutlet = (outletID) => {
        setValues((prev) => ({
            ...prev,
            outlet: outletID
        }));
        setOutlets([]);
        setRefetch(false)
    };

    const validate = (e) => {
        e.preventDefault();
        if (!values.brand) {
            return showToast('Brand name/title cannot be empty', 'error');
        }
        if (!values.category) {
            return showToast('Category cannot be empty', 'error');
        }
        if (!values.outlet) {
            return showToast('Select an outlet', 'error');
        }
        create();
    };



    const create = async () => {
        setLoading(true);
        const token = localStorage.getItem('PRTK');
        const config = {
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
        };

        const formData = new FormData();
        formData.append('image', values.image?.file);
        formData.append('brand', values.brand);
        formData.append('category', values.category);
        formData.append('type', values.type);
        formData.append('outlet', values.outlet);

        if (clientId) {
            formData.append('client', clientId);
        }


        try {
            await axios.post(`${url}/assets`, formData, config);
            await getAssetList();
            setValues(defaultState);
            showToast('New asset created!', 'success');
        } catch (error) {
            const message = error?.response?.data?.message || 'Something went wrong';
            showToast(message, 'error');
        } finally {
            setLoading(false);
        }
    };



    const handleSearchOutlets = (e) => {
        setRefetch(true)
        setOutletSearchQuery(e.target.value);
    };

    const getOutlet = async () => {
        const config = {
            headers: { authorization: `Bearer ${localStorage.getItem('PRTK')}` }
        };
        setLoadingOutlets(true);
        try {
            const res = await axios.get(`${url}/stores?search=${outletSearchQuery}&limit=10&isApproved=${true}`, config);
            setOutlets(res?.data?.data || []);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingOutlets(false);
        }
    };

    useEffect(() => {
        if (outletSearchQuery && refetch) {
            getOutlet();

        };
    }, [outletSearchQuery, refetch]);




    const [loadingNames, setLoadingNames] = useState(false);
    const [names, setNames] = useState([]);

    const getNames = async () => {
        const token = localStorage.getItem('PRTK');
        const config = {
            headers: { authorization: `Bearer ${token}` },
        };
        setLoadingNames(true);

        try {
            const response = await axios.get(`${url}/assets/fetch/pre-name?client=${clientId}&sort=+brand`, config);
            const fetchedData = response?.data?.data?.names || [];
            setNames(fetchedData);

        } catch (error) {
            const message = error?.response?.data?.message || 'Something went wrong';
            showToast(message, 'error');
        } finally {
            setLoadingNames(false);
        }
    };


    useEffect(() => {
        getNames()
    }, [])


    const selectedBrandObject = (values?.brand && names?.find((item) => (
        item.brand === values?.brand
    ))) || null

    const selectedCategoryObject = (values?.category && selectedBrandObject?.categories?.find(item => (
        item.category === values?.category
    ))) || null






    return (
        <div className={classes.createAssetContainer}>
            {
                loadingNames ?
                    <div className={classes.loaderCon}>
                        <Spinner />
                    </div>
                    :
                    <>


                        <p className={classes.createAssetModalTitle}>
                            Create Asset for <span style={{ fontWeight: 'bold', color: '#ffa347' }}>{companyName}</span>
                        </p>
                        <div className={classes.createAssetSubContainer}>

                            <input type="file" accept=".png, .jpeg, .jpg" name="image" onChange={handleImageChange} style={{ display: "none" }} id="imageUpload"
                            />
                            <label htmlFor="imageUpload">
                                <img
                                    src={
                                        values?.image?.localUrl || clickHere
                                    }
                                    alt="Banner"
                                    className={classes.bannerImage}
                                />
                            </label>

                            <select className={classes.createInput} name="brand" value={values.brand} onChange={handleChange} >
                                <option value={''}>
                                    Brand*
                                </option>
                                {
                                    names?.map((item, index) => (
                                        <option key={index}>{item?.brand}</option>
                                    ))
                                }
                            </select>

                            {
                                selectedBrandObject &&
                                <select className={classes.createInput} name="category" value={values.category} onChange={handleChange} >
                                    <option value={''}>
                                        Category*
                                    </option>
                                    {
                                        selectedBrandObject?.categories?.sort((a, b) => a.category.localeCompare(b.category))?.map((item, index) => (
                                            <option key={index}>{item?.category}</option>
                                        ))
                                    }
                                </select>
                            }


                            {
                                selectedCategoryObject &&
                                <select className={classes.createInput} name="type" value={values.type} onChange={handleChange} >
                                    <option value={''}>
                                        Type (optional)
                                    </option>
                                    {
                                        selectedCategoryObject?.types?.map((item, index) => (
                                            <option key={index}>{item}</option>
                                        ))
                                    }
                                </select>
                            }

                            <input value={outletSearchQuery} onChange={handleSearchOutlets} placeholder="Search outlet" className={classes.createInput} />
                            {outletSearchQuery && outlets.length ? (
                                <div className={classes.outletsModal}>
                                    {loadingOutlets ? (
                                        <p>Loading...</p>
                                    ) : (
                                        outlets.map((item, index) => (
                                            <p
                                                key={index}
                                                onClick={() => {
                                                    setOutletSearchQuery(item.name);
                                                    selectOutlet(item._id);
                                                }}
                                                className={classes.outletsNames}
                                            >
                                                {item?.name}
                                            </p>
                                        ))
                                    )}
                                </div>
                            )
                                :
                                <></>
                            }


                            <p onClick={
                                () => {
                                    setModal(
                                        <CreateOutlet setModal={setModal} />
                                    )
                                }
                            } className={classes.cantFindStatement}>Can't find your desired outlet? <span style={{ fontWeight: 'bold' }}>Create One</span></p>

                            <button disabled={loading} onClick={validate} className={classes.createBtn}>
                                Creat{loading ? 'ing...' : 'e'}
                            </button>
                        </div>
                    </>
            }
        </div>
    );
}


function ManageUsers({ companyName, setModal, clientId, showToast }) {



    const [activeScreen, setActiveScreen] = useState('Add user')
    const nav = {
        'Add user': <AddUser companyName={companyName} setModal={setModal} clientId={clientId} showToast={showToast} />,
        'Manage users': <MyUsers companyName={companyName} clientId={clientId} showToast={showToast} />,
    }



    return (
        <div>
            <div className={classes.topNames}>
                {Object.keys(nav).map((item, index) => (
                    <p onClick={() => { setActiveScreen(item) }}
                        className={`${classes.topNamesText} ${activeScreen === item ? classes.activeTopNamesText : ''}`} key={index}>{item}</p>
                ))}
            </div>
            {nav[activeScreen]}
        </div>
    )
}

function AddUser({ companyName, setModal, clientId, showToast }) {
    const [selectedUsers, setSelectedUsers] = useState([]); // Stores multiple users
    const [loading, setLoading] = useState(false);
    const [outletSearchQuery, setOutletSearchQuery] = useState('');
    const [allUsers, setAllUsers] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [refetch, setRefetch] = useState(true);



    const setSelectedUser = (userId, fullName) => {
        // Check if the userId already exists in selectedUsers
        if (!selectedUsers.some(user => user.userId === userId)) {
            // Add the user with fullName and userId if not already selected
            setSelectedUsers([...selectedUsers, { fullName, userId }]);
        }
        setAllUsers([]);
        setRefetch(false);
    };

    const validate = (e) => {
        e.preventDefault();
        if (!selectedUsers.length) {
            return showToast('Select at least one user', 'error');
        }

        create();
    };



    const create = async () => {
        setLoading(true);
        const token = localStorage.getItem('PRTK');
        const config = {
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        };

        const user = selectedUsers?.map(item => item.userId)

        const body = JSON.stringify({ user, client: clientId }); // Send array of users

        try {
            await axios.post(`${url}/assets/users`, body, config);
            setSelectedUsers([]); // Clear selected users
            showToast('Users added successfully!', 'success');
        } catch (error) {
            const message = error?.response?.data?.message || 'Something went wrong';
            showToast(message, 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleSearchOutlets = (e) => {
        setRefetch(true);
        setOutletSearchQuery(e.target.value);
    };

    const getOutlet = async () => {
        const config = {
            headers: { authorization: `Bearer ${localStorage.getItem('PRTK')}` }
        };
        setLoadingUsers(true);
        try {
            const res = await axios.get(`${url}/employees?search=${outletSearchQuery}&limit=10`, config);
            setAllUsers(res?.data?.data || []);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingUsers(false);
        }
    };

    useEffect(() => {
        if (outletSearchQuery && refetch) {
            getOutlet();
        }
    }, [outletSearchQuery, refetch]);


    const removeUser = (index) => {
        // Filter out the user at the given index
        const updatedUsers = selectedUsers.filter((_, i) => i !== index);
        setSelectedUsers(updatedUsers);
    };

    return (
        <div className={classes.createAssetContainer}>
            <p className={classes.createAssetModalTitle}>
                Add Users for <span style={{ fontWeight: 'bold', color: '#ffa347' }}>{companyName}</span>
            </p>

            <p className={classes.createAssetModalTitle}>
                You can add multiple users at the same time
            </p>

            <div className={classes.createAssetSubContainer}>


                <input
                    value={outletSearchQuery}
                    onChange={handleSearchOutlets}
                    placeholder="Search user"
                    className={classes.createInput}
                />
                {outletSearchQuery && allUsers.length ? (
                    <div className={classes.outletsModal}>
                        {loadingUsers ? (
                            <p>Loading...</p>
                        ) : (
                            allUsers.map((item, index) => (
                                <div
                                    key={index}
                                    onClick={() => {
                                        setOutletSearchQuery('');
                                        setSelectedUser(item._id, `${item.firstName} ${item.lastName}`);
                                    }}
                                    className={classes.outletsNames}
                                >
                                    {`${item?.firstName} ${item?.lastName}`}
                                    <p className={classes.smallEmail}>{item?.email}</p>

                                </div>
                            ))
                        )}
                    </div>
                ) : null}

                <div className={classes.selectedUserGroupedContainer}>
                    {selectedUsers?.map((item, index) => (
                        <div className={classes.selectedUserIndividualContainer} key={index}>
                            <p>{item.fullName}</p>
                            <IoMdClose className={classes.removeUserFromListIcon} onClick={() => { removeUser(index) }} />
                        </div>
                    ))}
                </div>

                <p
                    onClick={() => {
                        setModal(<CreateOutlet setModal={setModal} />);
                    }}
                    className={classes.cantFindStatement}
                >
                    Can't find your desired user? <span style={{ fontWeight: 'bold' }}>Invite One</span>
                </p>

                <button disabled={loading} onClick={validate} className={classes.createBtn}>
                    Creat{loading ? 'ing...' : 'e'}
                </button>
            </div>
        </div>
    );
}

function MyUsers({ showToast, companyName, clientId }) {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [searchValues, setSearchValues] = useState(defaultSearchValueState);
    const [inputPage, setInputPage] = useState(searchValues?.page);


    const limit = 100

    const getUsers = async (page) => {
        setLoading(true);
        const token = localStorage.getItem('PRTK');
        const config = {
            headers: {
                authorization: `Bearer ${token}`,
            },
        };

        try {
            const response = await axios.get(`${url}/assets/clients/users?clientId=${clientId}&limit=${limit}&page=${page || searchValues.page}`, config);
            setUsers(response.data.data.assetUsers || []);
            setTotalCount(response.data.data.totalCount || 0);

            if (page) {
                setInputPage(page)
            }

        } catch (error) {
            const message = error?.response?.data?.message || 'Something went wrong';
            showToast(message, 'error');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getUsers();
    }, [clientId]);

    const [deleting, setDeleting] = useState('')

    const removeUser = async (userDetails) => {

        setDeleting(userDetails._id);
        const token = localStorage.getItem('PRTK');
        const config = {
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        };

        try {
            await axios.delete(`${url}/assets/users/delete/${userDetails._id}`, config);
            await getUsers(1)
            showToast('User removed successfully!', 'success');
        } catch (error) {
            console.error(error)
            const message = error?.response?.data?.message || 'Something went wrong';
            showToast(message, 'error');
        } finally {
            setDeleting('');
        }
    }



    return (
        <div className={classes.createAssetContainer}>
            <p className={classes.createAssetModalTitle}>
                Here are all the Users added to <span style={{ fontWeight: 'bold', color: '#ffa347' }}>{companyName}</span>
            </p>

            <p className={classes.totalCount}>Total Users added: {totalCount}</p>

            {loading ? (
                <div className={classes.loaderCon}>
                    <Loader />
                </div>
            ) : users.length > 0 ? (
                <div className={classes.allUsers}>
                    {users?.map((item, index) => (

                        <div className={classes.addedUsers} key={index}>
                            <div className={classes.addedUsersSubCon} >
                                {item.user.firstName} {item.user.lastName}
                                <p className={classes.smallEmail}>{item.user.email}</p>
                            </div>

                            <div className={classes.deleteUserCon}>
                                {
                                    deleting === item._id ?
                                        <Spinner />
                                        :
                                        <MdDeleteForever className={classes.deleteIcon} onClick={deleting !== item._id ? () => removeUser(item) : null} />
                                }

                            </div>
                        </div>
                    ))}
                    <InitiativePaginationComponent
                        page={searchValues?.page}
                        totalCount={totalCount}
                        setSearchValues={setSearchValues}
                        setInputPage={setInputPage}
                        inputPage={inputPage}
                        limit={limit}
                    />
                </div>
            ) : (
                <p>No users found.</p>
            )}
        </div>
    );
}



function CreateOutlet({ setModal }) {
    const [loadingc, setLoadingc] = useState(false);

    return (
        <div className={classes.createModalWhite}>
            <div onClick={() => setModal(null)} className={classes.closeContainer}>
                <IoMdCloseCircleOutline className={classes.closeBtn} />
            </div>

            <p className={classes.createTitle}>Create outlet</p>

            <p className={classes.createTitle}>Kindly ensure that you are at the outlet before creating an outlet. This is to ensure the right geo are picked</p>
            <CreateStore oading={loadingc} setTriggerClose={() => setModal(null)} setLoading={setLoadingc} />

        </div>
    )
}


function ManageGroups({ companyName, setModal, clientId, showToast, setRefetchAllGroups, groupingType }) {


    const [activeScreen, setActiveScreen] = useState(`Create ${groupingType} groups`)
    const nav = {
        [`Create ${groupingType} groups`]: <CreateOrUpdateGroup companyName={companyName} setModal={setModal} clientId={clientId} showToast={showToast} setRefetchAllGroups={setRefetchAllGroups} groupingType={groupingType} />,
        [`Manage ${groupingType} groups`]: <MyGroups companyName={companyName} clientId={clientId} showToast={showToast} setRefetchAllGroups={setRefetchAllGroups} groupingType={groupingType} />,
    }



    return (
        <div>
            <div className={classes.topNames}>
                {Object.keys(nav).map((item, index) => (
                    <p onClick={() => { setActiveScreen(item) }}
                        className={`${classes.topNamesText} ${activeScreen === item ? classes.activeTopNamesText : ''}`} key={index}>{item}</p>
                ))}
            </div>
            {nav[activeScreen]}
        </div>
    )
}


function CreateOrUpdateGroup({ companyName, groupingType, clientId, showToast, setRefetchGroups, update, groupId, setRefetchAllGroups }) {
    const [selectedResults, setSelectedResults] = useState([]); // Stores multiple users
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [allResults, setAllResults] = useState([]);
    const [loadingAssets, setLoadingAssets] = useState(false);
    const [refetch, setRefetch] = useState(true);
    const [groupName, setGroupName] = useState('')


    const selected = (assetId, brandName) => {
        // Check if the assetId already exists in selectedAssets
        if (!selectedResults.some(asset => asset.assetId === assetId)) {
            // Add the asset with brandName and assetId if not already selected
            setSelectedResults([...selectedResults, { brandName, assetId }]);
        }
        setAllResults([]);
        setRefetch(false);
    };

    const validate = (e) => {
        e.preventDefault();

        if (!update && !groupName) {
            return showToast('Enter a group name', 'error');
        }

        if (!selectedResults.length) {
            return showToast('Select at least one asset', 'error');
        }


        if (update) {
            updateGroup();
        } else {
            create();
        }


    };


    const updateGroup = async () => {
        setLoading(true);
        const token = localStorage.getItem('PRTK');
        const config = {
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        };

        const items = selectedResults?.map(item => item.assetId)

        const body = JSON.stringify({ groupingType: `${groupingType}s`, groupId, items });



        try {
            await axios.patch(`${url}/assets/clients/groups`, body, config);
            setSelectedResults([]); // Clear selected users
            setGroupName('')
            setRefetchGroups((prev) => !prev)
            setRefetchAllGroups((prev) => !prev)
            showToast(`${groupingType} added to group successfully!`, 'success');
        } catch (error) {
            console.error(error)
            const message = error?.response?.data?.message || 'Something went wrong';
            showToast(message, 'error');
        } finally {
            setLoading(false);
        }
    };



    const create = async () => {
        setLoading(true);
        const token = localStorage.getItem('PRTK');
        const config = {
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        };

        const items = selectedResults?.map(item => item.assetId)

        let payload
        if (clientId) {
            payload = { groupingType: `${groupingType}s`, groupName, items, clientId }
        } else {
            payload = { groupingType: `${groupingType}s`, groupName, items }
        }

        const body = JSON.stringify(payload);

        try {
            await axios.post(`${url}/assets/clients/groups`, body, config);
            setSelectedResults([]); // Clear selected users
            setGroupName('')
            showToast('Group created successfully!', 'success');
            setRefetchAllGroups((prev) => !prev)
        } catch (error) {
            const message = error?.response?.data?.message || 'Something went wrong';
            showToast(message, 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleSearchAssets = (e) => {
        setRefetch(true);
        setSearchQuery(e.target.value);
    };

    const getSearchedResult = async () => {

        const config = {
            headers: { authorization: `Bearer ${localStorage.getItem('PRTK')}` }
        };
        setLoadingAssets(true);
        try {
            const res = await axios.get(`${url}/${groupingType}s?search=${searchQuery}${groupingType === 'asset' ? `&clientId=${clientId}` : ""}&limit=10&isApproved=${true}`, config);
            setAllResults(res?.data?.data || []);

        } catch (error) {
            console.error(error);
        } finally {
            setLoadingAssets(false);
        }
    };

    useEffect(() => {
        if (searchQuery && refetch) {
            getSearchedResult();
        }
    }, [searchQuery, refetch]);


    const removeAsset = (index) => {
        // Filter out the asset at the given index
        const updatedUsers = selectedResults.filter((_, i) => i !== index);
        setSelectedResults(updatedUsers);
    };


    const groupingMap = {
        'asset': searchQuery && allResults?.assets?.length ? (
            <div className={classes.outletsModal}>
                {loadingAssets ? (
                    <p>Loading...</p>
                ) : (
                    allResults?.assets?.map((item, index) => (
                        <div
                            key={index}
                            onClick={() => {
                                setSearchQuery('');
                                selected(item._id, `${item.brand}-${item.category}-${item.type}`);
                            }}
                            className={classes.outletsNames}
                        >
                            {`${item?.brand}-${item?.category}-${item?.type}`}
                        </div>
                    ))
                )
                }
            </div>
        ) : null,

        'store': searchQuery && allResults?.length ? (
            <div className={classes.outletsModal}>
                {loadingAssets ? (
                    <p>Loading...</p>
                ) : (
                    allResults?.map((item, index) => (
                        <div
                            key={index}
                            onClick={() => {
                                setSearchQuery('');
                                selected(item._id, `${item?.name} - ${item?.streetName},${item?.state}`);
                            }}
                            className={classes.outletsNames}
                        >
                            {`${item?.name} - ${item?.streetName},${item?.state}`}
                        </div>
                    ))
                )
                }
            </div>
        ) : null
    }

    return (
        <div className={classes.createAssetContainer}>
            {!update && <p className={classes.createAssetModalTitle}>
                Create {groupingType} groups for <span style={{ fontWeight: 'bold', color: '#ffa347' }}>{companyName}</span>
            </p>}


            <div className={classes.createAssetSubContainer}>
                {
                    !update &&
                    <input
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                        placeholder={`Enter ${groupingType} group name`}
                        className={classes.createInput}
                    />}


                <input
                    value={searchQuery}
                    onChange={handleSearchAssets}
                    placeholder={`Search ${groupingType}s`}
                    className={classes.createInput}
                />
                {groupingMap[groupingType]}

                <div className={classes.selectedUserGroupedContainer}>
                    {selectedResults?.map((item, index) => (
                        <div className={classes.selectedUserIndividualContainer} key={index}>
                            <p>{item.brandName}</p>
                            <IoMdClose className={classes.removeUserFromListIcon} onClick={() => { removeAsset(index) }} />
                        </div>
                    ))}
                </div>

                <button disabled={loading} onClick={validate} className={classes.createBtn}>
                    {update ? 'Updat' : 'Creat'}{loading ? 'ing...' : 'e'}
                </button>
            </div>
        </div>
    );
}


function MyGroups({ showToast, companyName, clientId, setRefetchAllGroups, groupingType }) {
    const [loading, setLoading] = useState(false);
    const [groups, setGroups] = useState([]);
    const [openDropdown, setOpenDropdown] = useState(null); // Track the open dropdown
    const [refetchGroups, setRefetchGroups] = useState(false)
    const [totalCount, setTotalCount] = useState(0);
    const [searchValues, setSearchValues] = useState(defaultSearchValueState);
    const [inputPage, setInputPage] = useState(searchValues?.page);


    const limit = 100


    const getGroups = async () => {
        setLoading(true);
        const token = localStorage.getItem('PRTK');
        const config = {
            headers: {
                authorization: `Bearer ${token}`,
            },
        };


        try {
            // todo paginate 
            const response = await axios.get(`${url}/assets/clients/groups?clientId=${clientId}&paginate=${true}&limit=${limit}&page=${searchValues.page}&groupingType=${groupingType}s`, config);
            setGroups(response.data.data.assetGroups || []);
            setTotalCount(response.data.data.totalCount || 0);
        } catch (error) {
            const message = error?.response?.data?.message || 'Something went wrong';
            showToast(message, 'error');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getGroups();
    }, [clientId, refetchGroups]);

    const handleDropdownToggle = (index) => {
        setOpenDropdown((prev) => (prev === index ? null : index)); // Toggle the current dropdown
    };


    // const groupTypeMapping = {
    //     'stores': '',
    //     'assets': `${asset.brand} - {$asset.category} - {asset.type}`
    // }


    return (
        <div className={classes.createAssetContainer}>
            <p className={classes.createAssetModalTitle}>
                Here are all the {groupingType} Groups for{' '}
                <span style={{ fontWeight: 'bold', color: '#ffa347' }}>{companyName}</span>
            </p>

            <p className={classes.totalCount}>Total Groups added: {totalCount}</p>

            {loading ? (
                <div className={classes.loaderCon}>
                    <Loader />
                </div>
            ) : groups.length > 0 ? (
                <div className={classes.allUsers}>
                    {groups.map((item, index) => (
                        <div key={index}>
                            <div
                                className={`${classes.addedUsers} ${classes.addedGroups}`}
                                onClick={() => handleDropdownToggle(index)} // Toggle on click
                            >
                                {item.name}
                                <IoIosArrowDropdownCircle className={`${classes.dropdownIcon} ${openDropdown === index ? classes.toggleOpen : ''}`} />
                            </div>
                            {openDropdown === index && ( // Only show if this dropdown is open
                                <div className={classes.assetsList}>
                                    <IoMdReturnRight className={classes.returnRightIcon} />
                                    <div className={classes.assetsListSub}>
                                        {item?.[`${groupingType}s`]?.map((result, idx) => (
                                            <div key={idx} className={classes.assetItem}>
                                                {
                                                    groupingType === 'store' ?
                                                        <>{result.name} - {result.streetName}, {result.state}</>
                                                        :
                                                        <>{result.brand} - {result.category} - {result.type}</>
                                                }

                                            </div>
                                        ))}
                                        <CreateOrUpdateGroup clientId={clientId} showToast={showToast} update={true} groupId={item._id} setRefetchGroups={setRefetchGroups} setRefetchAllGroups={setRefetchAllGroups} groupingType={groupingType} />
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}

                    <InitiativePaginationComponent
                        page={searchValues?.page}
                        totalCount={totalCount}
                        setSearchValues={setSearchValues}
                        setInputPage={setInputPage}
                        inputPage={inputPage}
                        limit={limit}
                    />
                </div>
            ) : (
                <p>No groups found.</p>
            )}
        </div>
    );
}



function EditAsset({ assetDetails, action, showToast, getAssetList, clientId, setShowMap, deleting, deleteAsset }) {

    const { closeDrawer } = useContext(UtilityContext)
    const otherDetailsFromAsset = {
        user: `${assetDetails?.fieldRegisterer?.firstName} ${assetDetails?.fieldRegisterer?.lastName}`,
        email: `${assetDetails?.fieldRegisterer?.email}`,

    }


    const [loadingNames, setLoadingNames] = useState(false);
    const [names, setNames] = useState([]);

    const getNames = async () => {
        const token = localStorage.getItem('PRTK');
        const config = {
            headers: { authorization: `Bearer ${token}` },
        };
        setLoadingNames(true);

        try {
            const response = await axios.get(`${url}/assets/fetch/pre-name?client=${clientId}&sort=+brand`, config);
            const fetchedData = response?.data?.data?.names || [];
            setNames(fetchedData);

        } catch (error) {
            const message = error?.response?.data?.message || 'Something went wrong';
            showToast(message, 'error');
        } finally {
            setLoadingNames(false);
        }
    };


    useEffect(() => {
        getNames()
    }, [])


    const isApproveAction = action === 'approve'

    const [values, setValues] = useState({
        brand: assetDetails.brand,
        category: assetDetails.category,
        outlet: assetDetails.outlet,
        type: assetDetails.type,
        image: { localUrl: assetDetails?.image, file: null },
        additionalInfo: assetDetails.additionalInfo,
        longitude: assetDetails.addressDetails?.coordinates?.longitude,
        latitude: assetDetails.addressDetails?.coordinates?.latitude,
        buildingNo: assetDetails.addressDetails?.buildingNo,
        streetName: assetDetails.addressDetails?.streetName,
        city: assetDetails.addressDetails?.city,
        LGA: assetDetails.addressDetails?.LGA,
        state: assetDetails.addressDetails?.state,
    });
    const [loading, setLoading] = useState(false);


    const validate = (e) => {
        e.preventDefault();

        // Define the required fields
        const requiredFields = {
            brand: "Brand name/title cannot be empty",
            category: "Category cannot be empty",
            image: "Please upload an image",
            additionalInfo: "Additional information cannot be empty",
            longitude: "Longitude cannot be empty",
            latitude: "Latitude cannot be empty",
            streetName: "Street name cannot be empty",
            city: "City cannot be empty",
            LGA: "LGA cannot be empty",
            state: "State cannot be empty",
        };

        // Iterate through each required field
        for (const field in requiredFields) {
            if (!values[field] || (field === "image" && !values.image.localUrl)) {
                return showToast(requiredFields[field], "error");
            }
        }

        // If all validations pass
        update();
    };


    const update = async () => {
        setLoading(true);
        const token = localStorage.getItem('PRTK');
        const config = {
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        };

        const formData = new FormData();

        // Append all fields to FormData
        { values.image?.file && formData.append('image', values.image?.file) }
        formData.append('brand', values.brand);
        formData.append('category', values.category);
        formData.append('type', values.type);
        formData.append('additionalInfo', values.additionalInfo);

        { action === 'approve' && formData.append('shouldApprove', true) }

        try {
            await axios.patch(`${url}/assets/edit/${assetDetails._id}`, formData, config);
            showToast('Asset updated successfully!', 'success');

            // Optionally, refresh asset list or reset form values here
            await getAssetList();
            closeDrawer()
        } catch (error) {
            const message = error?.response?.data?.message || 'Something went wrong';
            showToast(message, 'error');
        } finally {
            setLoading(false);
        }
    };








    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setValues((prev) => ({
                ...prev,
                [e.target.name]: { localUrl: URL.createObjectURL(file), file },
            }));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const [deleteModalIsOpen, setDeleteModalOpen] = useState(false)


    const selectedBrandObject = (values?.brand && names?.find((item) => (
        item.brand === values?.brand
    ))) || null

    const selectedCategoryObject = (values?.category && selectedBrandObject?.categories?.find(item => (
        item.category === values?.category
    ))) || null




    return (
        <div className={classes.modalContainer}>

            {
                loadingNames ?

                    <div className={classes.loaderCon}>
                        <Spinner />
                    </div>
                    :
                    <>
                        <p className={classes.createAssetModalTitle}>
                            Edit {isApproveAction && '& Approve'} <span style={{ fontWeight: 'bold', color: '#ffa347' }}>{values.brand} - {values.category} - {values.type}</span> asset
                            {isApproveAction && <>, created by: <span style={{ color: '#ffa347' }}>{otherDetailsFromAsset?.user} - {otherDetailsFromAsset.email}</span></>}
                        </p>

                        {
                            deleteModalIsOpen &&
                            <DeleteModal getAssetList={getAssetList} showToast={showToast} setDeleteModalOpen={setDeleteModalOpen} item={assetDetails} />
                        }


                        <div className={classes.topButtonContainer}>
                            <button onClick={() => { setDeleteModalOpen(true) }} className={classes.deleteButton}>Delete</button>
                        </div>

                        <div className={classes.createAssetSubContainer}>


                            <input type="file" accept=".png, .jpeg, .jpg" name="image" onChange={handleImageChange} style={{ display: "none" }} id="editUploadedImage"
                            />
                            <label htmlFor="editUploadedImage">
                                <img
                                    src={
                                        values?.image?.localUrl || clickHere
                                    }
                                    alt="Banner"
                                    className={classes.bannerImage}
                                />
                            </label>



                            <label className={classes.editLabel}>Brand</label>
                            <select className={classes.createInput} name="brand" value={values.brand} onChange={handleChange} >
                                <option>
                                    {values?.brand || 'Brand*'}
                                </option>
                                {
                                    names?.map((item, index) => (
                                        <option key={index}>{item?.brand}</option>
                                    ))
                                }
                            </select>

                            <label className={classes.editLabel}>Category</label>
                            {
                                values?.brand &&
                                <select className={classes.createInput} name="category" value={values.category} onChange={handleChange} >
                                    <option >
                                        {values?.category || 'Category*'}
                                    </option>
                                    {
                                        selectedBrandObject?.categories?.sort((a, b) => a.category.localeCompare(b.category))?.map((item, index) => (
                                            <option key={index}>{item?.category}</option>
                                        ))
                                    }
                                </select>
                            }


                            <label className={classes.editLabel}>Type (optional)</label>
                            {
                                values?.category &&
                                <select className={classes.createInput} name="type" value={values.type} onChange={handleChange} >
                                    <option >
                                        {values?.type || 'Type (optional)'}
                                    </option>
                                    {
                                        selectedCategoryObject?.types?.map((item, index) => (
                                            <option key={index}>{item}</option>
                                        ))
                                    }
                                </select>
                            }


                            {
                                values?.outlet ?
                                    <>
                                        <label className={classes.editLabel}>Outlet</label> <input readOnly disabled value={values.outlet} className={classes.createInput} />
                                    </>
                                    :
                                    <>
                                        <label className={classes.editLabel}>Building No.</label>    <input readOnly disabled name="buildingNo" value={values.buildingNo} onChange={handleChange} placeholder="Enter building number" className={classes.createInput} />
                                        <label className={classes.editLabel}>Street Name</label> <input readOnly disabled name="streetName" value={values.streetName} onChange={handleChange} placeholder="Enter street name" className={classes.createInput} />
                                        <label className={classes.editLabel}>City</label>    <input readOnly disabled name="city" value={values.city} onChange={handleChange} placeholder="Enter city's name" className={classes.createInput} />
                                        <label className={classes.editLabel}>State</label>  <input readOnly disabled name="state" value={values.state} onChange={handleChange} placeholder="Select a State" className={classes.createInput} />
                                        <label className={classes.editLabel}>Local Government Area</label>  <input readOnly disabled name="LGA" value={values.LGA} onChange={handleChange} placeholder="Select a Local Government Area" className={classes.createInput} />
                                        <label className={classes.editLabel}>Additional Info</label>  <input readOnly disabled name="additionalInfo" value={values.additionalInfo} onChange={handleChange} placeholder="Enter additional info" className={classes.createInput} />

                                        <label className={classes.editLabel}>Map Location</label>
                                        <div onClick={() => {
                                            setShowMap({
                                                longitude: values.longitude,
                                                latitude: values.latitude
                                            });

                                        }} className={`${classes.createInput} ${classes.clickToOpenMap}`} >
                                            Click to open position on map
                                        </div>

                                    </>
                            }




                            {/* <input type="checkbox" /> <label>Assign from outlet</label> */}

                            <button disabled={loading} onClick={validate} className={classes.createBtn}>
                                {isApproveAction ? 'Approv' : 'Updat'}{loading ? 'ing...' : 'e'}
                            </button>


                        </div>
                    </>
            }
        </div>
    )
}


function OpenAsset({ draw, item, showToast, getAssetList, names, getNames, loadingNames, clientId, setShowMap, deleteAsset, deleting }) {

    console.log(deleteAsset)
    const [deleteModalIsOpen, setDeleteModalOpen] = useState(false)



    return (
        <div className={classes.modalContainer}>
            <div className={classes.topButtonContainer}>
                <button className={classes.resolveButton}
                    onClick={() =>
                        draw({
                            title: "Approve Asset",
                            content: (
                                <EditAsset assetDetails={item} action={'edit'} showToast={showToast} getAssetList={getAssetList} names={names} getNames={getNames} loadingNames={loadingNames} clientId={clientId} setShowMap={setShowMap} />
                            ),
                        })
                    }
                >Edit</button>
                <button onClick={() => { setDeleteModalOpen(true) }} className={classes.deleteButton}>Delete</button>
            </div>

            {
                deleteModalIsOpen &&
                <DeleteModal getAssetList={getAssetList} showToast={showToast} setDeleteModalOpen={setDeleteModalOpen} item={item} />
            }


            <img onClick={item.image ? () => window.open(item.image) : null} src={item.image || defaultImage} alt='' className={classes.image} />
            <div
                className={classes.modalTitleAndDateContainer}
            >
                <p className={classes.modalTitle}>
                    {item?.brand} - {item?.category} -{item?.type}
                </p>
                <p>
                    {formatDateHeader(
                        moment(item.updatedAt).format('YYYY-MM-DD')
                    )}
                </p>
            </div>

            <div
                className={classes.modalStoreAddressContainer}
            >
                <p className={classes.modalStoreAddress}>
                    {item?.outlet?.name || `Registered on Field by ${item?.fieldRegisterer?.firstName} ${item?.fieldRegisterer?.lastName}`} - {""}
                    {item?.outlet?.streetNumber ||
                        item?.addressDetails?.buildingNo ||
                        "No Bld. num."}
                    , {item?.outlet?.streetName || item?.addressDetails?.streetName}, {item?.outlet?.town || item?.addressDetails?.city}, {item?.outlet?.area || item?.addressDetails?.LGA}, {item?.outlet?.state || item?.addressDetails?.state}
                </p>
                <MdStorefront className={classes.modalIcon} />
            </div>

        </div>
    )
}



function DeleteModal({ setDeleteModalOpen, item, getAssetList, showToast }) {
    const { closeDrawer } = useContext(UtilityContext)

    const [deleting, setDeleting] = useState(false)

    const deleteAsset = async (assetDetails) => {

        setDeleting(true);
        const token = localStorage.getItem('PRTK');
        const config = {
            headers: {
                authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        };

        try {
            await axios.delete(`${url}/assets/delete/${assetDetails._id}`, config);
            await getAssetList(1)
            closeDrawer()
            showToast('Asset deleted successfully!', 'success');
        } catch (error) {
            console.error(error)
            const message = error?.response?.data?.message || 'Something went wrong';
            showToast(message, 'error');
        } finally {
            setDeleting(false);
        }
    }

    return (
        <div className={classes.deleteModal}>
            <div className={classes.deleteSubModal}>
                <p className={classes.deleteQuestion}>Are you sure you want to delete  <span style={{ color: '#ffa347' }}>{item?.brand} - {item?.category} -{item?.type}</span> Asset?</p>
                <div className={classes.promptBtnGrouped}>
                    <button disabled={deleting} onClick={() => { deleteAsset(item); console.log(item) }} style={{ background: 'red', color: '#fff' }} className={classes.promptBtn}>
                        {deleting ? <Spinner /> : 'Yes'}
                    </button>
                    <button disabled={deleting} onClick={() => { setDeleteModalOpen(false) }} className={classes.promptBtn}>No</button>
                </div>
            </div>
        </div>
    )
}