import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import CustomInput from '../../../components/customInput/customInput';
import LineSkeleton from '../../../components/lineSkeleton';
import EmptyState from '../../../components/emptyState/emptyState';
import { buildResourceURL } from '../../../utils/helper';
import axiosInstance from '../../../services/api';
import PrimaryButton from '../../../components/primaryButton/primarybutton';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const AssignTeam = ({setTriggerClose, employeeId, title, type, setRefetch}) => {

  const [loading, setLoading] = useState(false)
  const [loadingx, setLoadingx] = useState(false)
  const [stores, setStores] = useState([])
  const [sid, setSid] = useState([])
  const [searchVal, setSearchVal] = useState("")
  const {iid} = useParams()

  const toast = useToast()

  const currentUser = useSelector(({userData})=>  userData?.currentUser);

  const queryParams = [
    {
        key: 'role',
        value: title
    },
    {
        key: 'search',
        value: searchVal
    },
    {
        key: 'limit',
        value: 7
    },
    
]

  const getEmployees = async() => {
    setLoading(true)
    try {
        const { data } = await axiosInstance.get(buildResourceURL('employees', queryParams))
        setStores(data?.data)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
}

useEffect(() => {
    getEmployees()
},[searchVal])

  const assignTeam = async() => {
    setLoadingx(true)
    const datax = {
        userIDs: sid?.map(i => i?.id)
    }
    try {
        const { data } = await axiosInstance.patch(type === 'assign' ? `/employees/${employeeId}/assign-team-members` : `employees/${employeeId}/unassign-team-members`, datax)
        toast({
            description: data?.message,
            status: "success",
            position: 'top'
        });
        setTriggerClose('close' + (Math.random()))
        setRefetch(Math.random())
    } catch(error) {
        toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
          });
    } finally {
        setLoadingx(false)
    }
  }

  const addUser = (data) => {
    if(sid?.map(i => i?.id)?.includes(data?.id)) {
      const newArr = sid?.filter(i => i?.id !== data?.id)
      setSid(newArr)
    } else {
      const newArr = [...sid, data]
      setSid(newArr)
    }
  }

  const removeUser = (id) => {
    const newArr = sid?.filter(i => i?.id !== id)
    setSid(newArr)
  }




  return (
    <>
    <Flex flexWrap={"wrap"} gap=".3rem">
      {sid?.map(({id, firstName, lastName}) => (
        <Flex key={id} gap={".7rem"} alignItems={"center"} borderRadius={"4px"} bg="#eee" px=".3rem" width={"fit-content"} border={"1px solid lightgray"}>
          <Text mb=".2rem" color={"#667085"} textTransform={"capitalize"} fontWeight={"500"}>{firstName + " " + lastName}</Text>
          <AiOutlineCloseCircle cursor={"pointer"} onClick={() => removeUser(id)} />
        </Flex>
      ))}
    </Flex>
    
    <Box>
      <CustomInput
      type={"text"}
      placeholder={`Search ${title}s`}
      mb="1rem"
      onChange={e => setSearchVal(e.target.value)}
      />

      {loading ?
      <LineSkeleton num={6} />
      :
      stores?.length < 1 ?
      <EmptyState text={`No ${title}s found`} />
      :
      searchVal?.length >= 3 &&
      stores?.map(({_id: id, firstName, lastName, email, profilePicture, isApproved}) => {
        const data = {id, firstName, lastName, email, profilePicture, isApproved}
        return (
          <Flex onClick={() => addUser(data)} cursor={"pointer"} position={"relative"} padding={".7rem 1rem"} gap=".6rem" alignItems={"center"} mt=".8rem" borderRadius={"6px"} border={sid?.map(i => i?.id)?.includes(id) ? "2px solid #7F56D9": "1px solid lightgrey"}>
            <Flex alignItems={"center"} w="100%" justifyContent={"space-between"}>
                <Flex alignItems={"center"}>
                    <Box height={"50px"} width={"60px"} backgroundImage={profilePicture} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}  />
                    <Box>
                        <Text mb=".2rem" cxolor={"#667085"} textTransform={"capitalize"} fontWeight={"500"}>{firstName + " " + lastName}</Text>
                        <Text fontWeight={"300"} fontSize={".85rem"} color="#667085">{email}</Text>
                    </Box>
                </Flex>
                <Text fontSize={"12px"} fontWeight={"500"} color={"#98A2B3"}>{isApproved ? "Verified" : "Unverified"}</Text>
            </Flex>
          </Flex>
        )
      })
      }
    </Box>

    <PrimaryButton mt="3rem" onClick={assignTeam} text={`Assign ${title} to team`} loading={loadingx} disabled={sid?.length < 1} />
    </>
  )
}

export default AssignTeam;