import React from 'react'
import classes from './activeResources.module.css'
import { ActiveResourceCard } from './card';
import promoramaMan from '../../../../../assets/promoramaMan.svg'

const ResourceListTemplate = ({ data, type }) => {
  const uniqueByEmail = data?.filter((obj, index, self) => {
    // Check if the current email is the first occurrence in the array
    return index === self.findIndex((el) => el?.email === obj?.email);
  });


  // const uniqueByEmail = ([
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }
  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  //   ,
  //   {
  //     "profilePicture": "https://example.com/profiles/0.png",
  //     "email": "jane.doe0@example.com",
  //     "firstName": "Jane",
  //     "lastName": "Doe",
  //     "phoneNumber": "+1-612-664-5907"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/1.png",
  //     "email": "alex.taylor1@example.com",
  //     "firstName": "Alex",
  //     "lastName": "Taylor",
  //     "phoneNumber": "+1-637-300-9221"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/2.png",
  //     "email": "morgan.miller2@example.com",
  //     "firstName": "Morgan",
  //     "lastName": "Miller",
  //     "phoneNumber": "+1-537-339-4185"
  //   },
  //   {
  //     "profilePicture": "https://example.com/profiles/99.png",
  //     "email": "pat.moore99@example.com",
  //     "firstName": "Pat",
  //     "lastName": "Moore",
  //     "phoneNumber": "+1-453-697-2550"
  //   }

  // ])



  return (
    <div>


      {/* <Flex mt="1.2rem" flexWrap={"wrap"} gap=".6rem">
        {uniqueByEmail?.map(({ _id: id, profilePicture, name, email, firstName, lastName, phoneNumber }) => {
          return (
            <Flex key={id} alignItems={"center"} gap={".6rem"} flexWrap={"wrap"} borderRadius={"6px"} border={".5px solid lightgray"} padding={".6rem 1.2rem .6rem .7rem"} bg={"#F5F5F5"}>
              <Box width={"70px"} height={"70px"} borderRadius={"50%"} backgroundColor={"lightgray"} backgroundImage={profilePicture} backgroundSize={"cover"} backgroundPosition={"center"} />
              <Box>
                <Text textTransform={"capitalize"} fontSize={".87rem"} fontWeight={"500"}>{type === 'subuser' ? name : `${firstName} ${lastName}`}</Text>
                <Text fontSize={".87rem"} fontWeight={"500"}>{email}</Text>
                {type !== 'subuser' && <Text textTransform={"capitalize"} fontSize={".8rem"} fontWeight={"500"}>{phoneNumber}</Text>}
              </Box>
            </Flex>
          )
        })}
      </Flex> */}
      {
        !uniqueByEmail?.length ?
          <div className={classes.empty_container}>
            <img className={classes.empty_image} src={promoramaMan} alt='' />
            <p className={classes.empty_message}>
              <p className={classes.click_here}>
                No "{type}" found
              </p>
            </p>
          </div>
          :

          <>
            <p className={classes.title}>Total {type} Found: {uniqueByEmail?.length}</p>
            <div className={classes.cardGrouped}>
              {

                uniqueByEmail?.map((item, index) => (
                  <ActiveResourceCard
                    key={index}
                    img={item?.profilePicture}
                    name={item?.name ? item?.name : `${item?.firstName} ${item?.lastName || ''}`}
                    mail={item?.email}
                    phoneNumber={item?.phoneNumber}
                    position={item?.type}
                  />
                ))
              }

            </div>
          </>
      }

    </div>
  )
}

export default ResourceListTemplate;