import { Box, Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react'
import { BsDot } from 'react-icons/bs';
import moment from 'moment';
import Map from '../../../../../components/mapView/mapView';
import CopyButton from '../../../../../components/copyButton';
import CustomTabs from '../../../../../components/customTabs/customTabs';

const ViewResponse = ({data}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const datax = [
      {
          title: "Image",
          render: <Box height={"250px"} onClick={onOpen} cursor={"pointer"} borderRadius={"4px"} backgroundImage={data?.entries?.find(i => i?.answer?.includes('cloudinary'))?.answer} backgroundSize={"cover"}></Box>
      },
      {
          title: "Map View",
          render: <Map coordinates={data?.location?.coordinates} height={"250px"} />
      }
  ]

  const responseDetails = data?.entries?.filter(i => !(i?.answer?.includes('cloudinary')))?.map((v, index) => ({
    title: data?.survey?.questions[index]?.title,
    value: v?.answer,
    icon: <BsDot color='#98A2B3' />
  }))

  const handleDownload = async (imgData) => {
    const imageUrl = data?.entries?.find(i => i?.answer?.includes('cloudinary'))?.answer;

    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download =  'survey_submission_image.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Release the object URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  return (
    <Box>
      <CustomTabs data={datax} />
      <Box mt="1rem">
        {responseDetails?.map(({title, value, icon}) => (
          <Flex key={title} mb="1rem" alignItems={"center"} justifyContent={"space-between"}>
            <Flex alignItems={"center"} gap="1rem">
              {/* {icon} */}
              {value &&
              <Box>
                <Text mb=".1rem" color={"#475467"} fontSize={".8rem"} fontWeight={"500"} textTransform={"capitalize"}>{title}</Text>
                <Text color={"#101928"} fontSize={".9rem"} fontWeight={"500"}>{value}</Text>
              </Box>}
            </Flex>
            {title === "Store ID" && <CopyButton toCopy={value} />}
          </Flex>
        ))}
      </Box>




      <Modal size={"6xl"} isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{'Image'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {/* <Image src={data?.imageURL} /> */}
                    <Box cursor={"pointer"} onClick={() => handleDownload(data)} height={"68vh"} borderRadius={"4px"} width={"100%"} backgroundImage={data?.entries?.find(i => i?.answer?.includes('cloudinary'))?.answer} backgroundSize={"contain"} backgroundPosition={"center"} backgroundRepeat={"no-repeat"} />
                    <Text pb="1rem" mt="2rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Close</Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    </Box>
  )
}

export default ViewResponse;