
export const STATUS = {
    OPEN: 'open',
    IN_PROGRESS: 'in_progress',
    RECTIFIED: 'rectified',
    INVALID: 'invalid',
};

export const getStatusEnums = () => {
    try {
        return Object.values(STATUS);
    } catch (error) {
        return []
    }
}
export const statusColors ={
    open: '#007bff',
    in_progress: '#ffc107',
    rectified: '#28a745',
    invalid: '#dc3545',
    
}