import { Box, Flex, Heading, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { userActionTypes } from "../../../redux/constants/userActionTypes";
import PrimaryButton from "../../../components/primaryButton/primarybutton";
import CustomInput from "../../../components/customInput/customInput";
import AuthBg from "../../../assets/bg-element.svg";
import axiosInstance from "../../../services/api";

const SubuserLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);


  const toast = useToast();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const datax = {
    email,
    password,
  };

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axiosInstance.post('auth/subusers/login', datax);
      localStorage.setItem("PRTK", data?.data?.token);
      localStorage.setItem("userType", data?.data?.user?.role === 'Basic Subuser' ? "subuser" : "admin");
      dispatch({
        type: userActionTypes.SIGNIN_SUCCESS,
        payload: data?.data?.user
      });
      window.location.href = "/initiatives"
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex height={"100vh"} flexWrap={"wrap"}>
      <Box flex={"1 1 400px"}bg="#0C0C0C" backgroundImage={AuthBg} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}></Box>
      <Flex flex={"1 1 400px"} p={["1.5rem 1rem", "5.5rem 4rem"]} bg="#F5F5F5" alignItems={"center"} justifyContent={"center"}>
        <Box
          padding={["2rem .7rem 1.5rem .7rem", "4rem 2rem 1.5rem 2rem"]}
          bg="#FFF"
          width={"100%"}
          height={"100%"}
          boxShadow={"0px 4px 24px rgba(0, 0, 0, 0.16)"}
          borderRadius={"8px"}
          textAlign={"center"}
          border="0.5px solid #D0D5DD"
        >
          <Heading
            mt={6}
            fontWeight={"600"}
            fontSize={["1.35rem", "1.5rem"]}
            color="#27272A"
          >
            Sub User Login
          </Heading>
          <Text mt={2} mb="1rem" color={"#3F3F46"} fontSize={".9rem"}>
          Jump back in.
          </Text>
          <form onSubmit={login}>
            <CustomInput
              placeholder={"Email Address"}
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value?.toLowerCase())}
            />

            <CustomInput
              placeholder={"Password"}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Flex
              mb="2rem"
              mt=".5rem"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Text></Text>
              <Text
                onClick={() =>
                  navigate("/forgot-password", {
                    state: { userType: "subusers" },
                  })
                }
                fontSize={".9rem"}
                fontWeight={"400"}
                color="#F03738"
                cursor={"pointer"}
                transition={"250ms ease"}
                _hover={{ color: "#F03738", fontWeight: "500" }}
              >
                Forgot Password
              </Text>
            </Flex>
            <PrimaryButton type="submit" text="Login" loading={loading} />
            {/* <Text
              mt="1.2rem"
              mb=".3rem"
              textAlign={"center"}
              fontSize={".9rem"}
              color="#3C3148"
            >
              {`Not ${loginState === "admin" ? "an Admin" : "a Client"} `}?{" "}
              <span
                onClick={() =>
                  setLoginState(loginState === "admin" ? "client" : "admin")
                }
                style={{
                  fontWeight: "500",
                  color: "#7E2EDB",
                  cursor: "pointer",
                  transition: "250ms ease",
                }}
              >
                Login as {loginState === "admin" ? "client" : "admin"}
              </span>
            </Text> */}
          </form>
        </Box>
      </Flex>
    </Flex>
  );
};

export default SubuserLogin;