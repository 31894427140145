/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../../../../../services/api";
import { useParams } from "react-router-dom";
import { buildResourceURL } from "../../../../../../../../utils/helper";
import classes from "./chunkTable.module.css";
import { InitiativeQueryComponent } from "../../../../../../../../components/initiativeQueryComponent/initiativeQueryComponent";
import { Loader } from "../../../../../../../../components/Loader/loader";
import {
  defaultSearchValueState,
  defaultTableStoreInvisibleColumns,
  defaultTableStoreVisibleColumns,
} from "../../../../../../../../constants/defaultStates";
import { numberFormat } from "../../../../../../../../helpers/numberFormatter";
import { moneyFormat } from "../../../../../../../../helpers/moneyFormatter";
import Fuse from "fuse.js";

const ChunkStoresTable = ({ iData }) => {
  const [loading, setLoading] = useState(false);
  const { iid } = useParams();

  const storeGroupData = iData?.initiativeStoresFilterGroups || [];
  const regionsData = iData?.client?.statesFilterGroups || [];
  const productGroupData = iData?.productsFilterGroups || [];
  const sosCategories = iData?.sosBrands?.map((item) => item?.sku) || [];

  const [data, setData] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState(
    defaultTableStoreVisibleColumns
  );
  const [filteredData, setFilteredData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const [searchValues, setSearchValues] = useState(defaultSearchValueState);

  const endDate = new Date(searchValues.endDate); // Create a new date object based on the current date
  endDate.setDate(endDate.getDate() + 1);

  const storeGroupQuery =
    storeGroupData
      .filter(
        (item) => item.name.toLowerCase() === searchValues.groups.toLowerCase()
      )[0]
      ?.["initiativeStoresIDs"].map((item) => item)
      .join(",") || "";
  const regionGroupQuery =
    regionsData
      .filter(
        (item) => item.name.toLowerCase() === searchValues.regions.toLowerCase()
      )[0]
      ?.["states"].map((item) => item)
      .join(",") || "";
  const productGroupQuery =
    productGroupData
      .filter(
        (item) =>
          item.name.toLowerCase() === searchValues.products.toLowerCase()
      )[0]
      ?.["products"].map((item) => item)
      .join(",") || "";

  const options1 = iData?.brands?.map((i) => ({
    title: `${i?.name}-${i?.sku}`,
    value: JSON.stringify({ brandName: i?.name, sku: i?.sku }),
  }));

  options1.sort((a, b) => a.title.localeCompare(b.title));

  const queryParams = [
    {
      key: "initiativeId",
      value: iid,
    },
    {
      key: "brandName",
      value: JSON.parse(searchValues?.brandName || "{}")?.brandName,
    },
    {
      key: "sku",
      value: JSON.parse(searchValues?.brandName || "{}")?.sku,
    },
    {
      key: "startDate",
      value: searchValues?.startDate,
    },
    {
      key: "endDate",
      value:
        searchValues?.startDate && searchValues?.endDate
          ? endDate.toISOString().split("T")[0]
          : "",
    },
    {
      key: 'state',
      value: searchValues.states.toLowerCase()
  },
    {
      key: 'initiativeStoresFilterGroupId',
      value: searchValues.groups
    },
    {
      key: 'productsFilterGroupId',
      value: searchValues.products
    },
    {
      key: 'statesFilterGroupId',
      value: searchValues.regions
    },
  ];

  const getResults = async (e) => {
    e?.preventDefault();
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        buildResourceURL(`data-table`, queryParams)
      );
      const { result } = data;
      setData(result);
    } catch (error) {
      console.log(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getResults();
  }, [searchValues]);

  const handleColumnToggle = (column) => {
    setVisibleColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  const excelData = data
    ? Object.keys(data).map((store) => {
      const storeData = data[store];
      const rowData = { "Store Name": store };

      if (visibleColumns["Sales (Total)"]) {
        rowData["Sales (Total)"] = numberFormat.format(
          storeData.sales?.total || 0
        );
      }
      if (visibleColumns["Sales (Value)"]) {
        rowData["Sales (Value)"] = moneyFormat.format(
          storeData.sales?.value || 0
        );
      }
      if (visibleColumns["Shelf Inventory (Total)"]) {
        rowData["Shelf Inventory (Total)"] = numberFormat.format(
          storeData.shelfInventory?.total || 0
        );
      }
      if (visibleColumns["Shelf Inventory (Value)"]) {
        rowData["Shelf Inventory (Value)"] = moneyFormat.format(
          storeData.shelfInventory?.value || 0
        );
      }
      if (visibleColumns["MSL (Avg)"]) {
        rowData["MSL (Avg)"] = `${numberFormat.format(
          storeData.msl?.avg * 100 || 0
        )}%`;
      }
      if (visibleColumns["SOS (Avg)"]) {
        rowData["SOS (Avg)"] = `${numberFormat.format(
          storeData.sos?.avg || 0
        )}%`;
      }
      if (visibleColumns["Store Inventory (Total Case)"]) {
        rowData["Store Inventory (Total Case)"] = numberFormat.format(
          storeData.storeInventory?.totalCase || 0
        );
      }
      if (visibleColumns["Store Inventory (Total Value)"]) {
        rowData["Store Inventory (Total Value)"] = moneyFormat.format(
          storeData.storeInventory?.totalValue || 0
        );
      }
      if (visibleColumns["OOS (Entries)"]) {
        rowData["OOS (Entries)"] = numberFormat.format(
          storeData.oos?.entries || 0
        );
      }
      if (visibleColumns["Shipment (Total Case)"]) {
        rowData["Shipment (Total Case)"] = numberFormat.format(
          storeData.shipment?.totalCase || 0
        );
      }
      if (visibleColumns["Shipment (Total Value)"]) {
        rowData["Shipment (Total Value)"] = moneyFormat.format(
          storeData.shipment?.totalValue || 0
        );
      }
      if (visibleColumns["Orders (Total)"]) {
        rowData["Orders (Total)"] = numberFormat.format(
          storeData.orders?.total || 0
        );
      }
      if (visibleColumns["Orders (Avg)"]) {
        rowData["Orders (Avg)"] = numberFormat.format(
          storeData.orders?.avg || 0
        );
      }

      if (visibleColumns["SOS Categories"]) {
        sosCategories?.map(
          (item) =>
          (rowData[item] = `${numberFormat.format(
            storeData[item]?.avg || 0
          )}%`)
        );
      }

      return rowData;
    })
    : {};

  const fuseOptions = {
    keys: ["Store Name"],
    threshold: 0.3,
  };

  const fuse = new Fuse(excelData, fuseOptions);

  const searchFn = (val) => {
    setSearchQuery(val);
    const result = fuse.search(val);
    setFilteredData(result);
  };

  return (
    <div className={classes.container}>
      <div className={classes.screenContainer}>
        <InitiativeQueryComponent
          data={iData}
          searchValues={searchValues}
          setSearchValues={setSearchValues}
          filterList1={{ title: "Brand", name: "brandName", data: options1 }}
          newExcel={{ excelData, title: "Table-Stores" }}
          searchFn={(e) => {
            searchFn(e);
          }}
        />

        {loading ? (
          <div className={classes.loader_con}>
            <Loader />
          </div>
        ) : (
          <>
            {/* Render table if data is available */}
            {data ? (
              <div className={classes.table_container}>
                <div className={classes.titleAndInstructionContainer}>
                  <p className={classes.title}>
                    Total Stores Found:{" "}
                    {searchQuery
                      ? filteredData.length
                      : Object.keys(data).length}
                  </p>

                  <div className={classes.columnToggle}>
                    <div className={classes.toggleBtns}>
                      {/* <p>Toggle Columns:</p> */}
                      <p
                        onClick={() => {
                          setVisibleColumns(defaultTableStoreInvisibleColumns);
                        }}
                        className={classes.uncheckAllBtn}
                      >
                        Un-Check All
                      </p>
                    </div>

                    <div className={classes.allCheckBoxGrouped}>
                      {Object.keys(visibleColumns).map((column) => (
                        <div
                          className={classes.toggleCheckBoxContainer}
                          key={column}
                        >
                          <input
                            type="checkbox"
                            checked={visibleColumns[column]}
                            onChange={() => handleColumnToggle(column)}
                            className={classes.toggleCheckBox}
                          />
                          <p>{column}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <p className={classes.showing}>
                  Showing:{" "}
                  <span>
                    All{" "}
                    {searchQuery
                      ? filteredData.length
                      : Object.keys(data).length}{" "}
                    stores
                  </span>
                </p>

                <div className={classes.scrollable}>
                  <table className={classes.table}>
                    <thead>
                      <tr>
                        <th>Store Name</th>
                        {visibleColumns["Sales (Total)"] && (
                          <th>Sales (Total)</th>
                        )}
                        {visibleColumns["Sales (Value)"] && (
                          <th>Sales (Value)</th>
                        )}
                        {visibleColumns["Shelf Inventory (Total)"] && (
                          <th>Shelf Inventory (Total)</th>
                        )}
                        {visibleColumns["Shelf Inventory (Value)"] && (
                          <th>Shelf Inventory (Value)</th>
                        )}
                        {visibleColumns["MSL (Avg)"] && <th>MSL (Avg)</th>}
                        {visibleColumns["SOS (Avg)"] && <th>SOS (Avg)</th>}
                        {visibleColumns["Store Inventory (Total Case)"] && (
                          <th>Store Inventory (Total Case)</th>
                        )}
                        {visibleColumns["Store Inventory (Total Value)"] && (
                          <th>Store Inventory (Total Value)</th>
                        )}
                        {visibleColumns["OOS (Entries)"] && (
                          <th>OOS (Entries)</th>
                        )}
                        {visibleColumns["Shipment (Total Case)"] && (
                          <th>Shipment (Total Case)</th>
                        )}
                        {visibleColumns["Shipment (Total Value)"] && (
                          <th>Shipment (Total Value)</th>
                        )}
                        {visibleColumns["Orders (Total)"] && (
                          <th>Orders (Total)</th>
                        )}
                        {visibleColumns["Orders (Avg)"] && (
                          <th>Orders (Avg)</th>
                        )}

                        {visibleColumns["SOS Categories"] &&
                          sosCategories?.map((item) => <th>{item} (Avg)</th>)}
                      </tr>
                    </thead>
                    {searchQuery ? (
                      <tbody>
                        {(searchQuery
                          ? filteredData
                          : Object.entries(data)
                        ).map((store, index) => {
                          const storeData = searchQuery ? store.item : store[1];
                          const storeName = searchQuery
                            ? store.item["Store Name"]
                            : store[0];

                          return (
                            <tr key={index}>
                              <td className={classes.tooltip_container}>
                                <p className={classes.item_text}>{storeName}</p>
                                <p className={classes.tooltip}>{storeName}</p>
                              </td>

                              {Object.keys(visibleColumns).map(
                                (column) =>
                                  visibleColumns[column] && (
                                    <td
                                      key={column}
                                      className={classes.tooltip_container}
                                    >
                                      <p className={classes.item_text}>
                                        {storeData[column]}
                                      </p>
                                      <p className={classes.tooltip}>
                                        {storeData[column]}
                                      </p>
                                    </td>
                                  )
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        {Object?.keys(data)
                          ?.sort((a, b) => a.localeCompare(b))
                          .map((store, index) => {
                            const storeData = data[store];
                            return (
                              <tr key={index}>
                                <td className={classes.tooltip_container}>
                                  <p className={classes.item_text}>{store}</p>
                                  <p className={classes.tooltip}>{store}</p>
                                </td>
                                {visibleColumns["Sales (Total)"] && (
                                  <td className={classes.tooltip_container}>
                                    <p className={classes.item_text}>
                                      {numberFormat.format(
                                        storeData.sales?.total || 0
                                      )}
                                    </p>
                                    <p className={classes.tooltip}>
                                      {numberFormat.format(
                                        storeData.sales?.total || 0
                                      )}
                                    </p>
                                  </td>
                                )}

                                {visibleColumns["Sales (Value)"] && (
                                  <td className={classes.tooltip_container}>
                                    <p className={classes.item_text}>
                                      {moneyFormat.format(
                                        storeData.sales?.value || 0
                                      )}
                                    </p>
                                    <p className={classes.tooltip}>
                                      {moneyFormat.format(
                                        storeData.sales?.value
                                      ) || 0}
                                    </p>
                                  </td>
                                )}

                                {visibleColumns["Shelf Inventory (Total)"] && (
                                  <td className={classes.tooltip_container}>
                                    <p className={classes.item_text}>
                                      {numberFormat.format(
                                        storeData.shelfInventory?.total || 0
                                      )}
                                    </p>
                                    <p className={classes.tooltip}>
                                      {numberFormat.format(
                                        storeData.shelfInventory?.total || 0
                                      )}
                                    </p>
                                  </td>
                                )}

                                {visibleColumns["Shelf Inventory (Value)"] && (
                                  <td className={classes.tooltip_container}>
                                    <p className={classes.item_text}>
                                      {moneyFormat.format(
                                        storeData.shelfInventory?.value || 0
                                      )}
                                    </p>
                                    <p className={classes.tooltip}>
                                      {moneyFormat.format(
                                        storeData.shelfInventory?.value || 0
                                      )}
                                    </p>
                                  </td>
                                )}

                                {visibleColumns["MSL (Avg)"] && (
                                  <td className={classes.tooltip_container}>
                                    <p className={classes.item_text}>
                                      {numberFormat.format(
                                        storeData.msl?.avg * 100 || 0
                                      )}
                                      %
                                    </p>
                                    <p className={classes.tooltip}>
                                      {numberFormat.format(
                                        storeData.msl?.avg * 100 || 0
                                      )}
                                      %
                                    </p>
                                  </td>
                                )}

                                {visibleColumns["SOS (Avg)"] && (
                                  <td className={classes.tooltip_container}>
                                    <p className={classes.item_text}>
                                      {numberFormat.format(
                                        storeData.sos?.avg || 0
                                      )}
                                      %
                                    </p>
                                    <p className={classes.tooltip}>
                                      {numberFormat.format(
                                        storeData.sos?.avg || 0
                                      )}
                                      %
                                    </p>
                                  </td>
                                )}

                                {visibleColumns[
                                  "Store Inventory (Total Case)"
                                ] && (
                                    <td className={classes.tooltip_container}>
                                      <p className={classes.item_text}>
                                        {numberFormat.format(
                                          storeData.storeInventory?.totalCase || 0
                                        )}
                                      </p>
                                      <p className={classes.tooltip}>
                                        {numberFormat.format(
                                          storeData.storeInventory?.totalCase || 0
                                        )}
                                      </p>
                                    </td>
                                  )}

                                {visibleColumns[
                                  "Store Inventory (Total Value)"
                                ] && (
                                    <td className={classes.tooltip_container}>
                                      <p className={classes.item_text}>
                                        {moneyFormat.format(
                                          storeData.storeInventory?.totalValue ||
                                          0
                                        )}
                                      </p>
                                      <p className={classes.tooltip}>
                                        {moneyFormat.format(
                                          storeData.storeInventory?.totalValue ||
                                          0
                                        )}
                                      </p>
                                    </td>
                                  )}

                                {visibleColumns["OOS (Entries)"] && (
                                  <td className={classes.tooltip_container}>
                                    <p className={classes.item_text}>
                                      {numberFormat.format(
                                        storeData.oos?.entries || 0
                                      )}
                                    </p>
                                    <p className={classes.tooltip}>
                                      {numberFormat.format(
                                        storeData.oos?.entries || 0
                                      )}
                                    </p>
                                  </td>
                                )}

                                {visibleColumns["Shipment (Total Case)"] && (
                                  <td className={classes.tooltip_container}>
                                    <p className={classes.item_text}>
                                      {numberFormat.format(
                                        storeData.shipment?.totalCase || 0
                                      )}
                                    </p>
                                    <p className={classes.tooltip}>
                                      {numberFormat.format(
                                        storeData.shipment?.totalCase || 0
                                      )}
                                    </p>
                                  </td>
                                )}

                                {visibleColumns["Shipment (Total Value)"] && (
                                  <td className={classes.tooltip_container}>
                                    <p className={classes.item_text}>
                                      {moneyFormat.format(
                                        storeData.shipment?.totalValue || 0
                                      )}
                                    </p>
                                    <p className={classes.tooltip}>
                                      {moneyFormat.format(
                                        storeData.shipment?.totalValue || 0
                                      )}
                                    </p>
                                  </td>
                                )}

                                {visibleColumns["Orders (Total)"] && (
                                  <td className={classes.tooltip_container}>
                                    <p className={classes.item_text}>
                                      {numberFormat.format(
                                        storeData.orders?.total || 0
                                      )}
                                    </p>
                                    <p className={classes.tooltip}>
                                      {numberFormat.format(
                                        storeData.orders?.total
                                      ) || 0}
                                    </p>
                                  </td>
                                )}

                                {visibleColumns["Orders (Avg)"] && (
                                  <td className={classes.tooltip_container}>
                                    <p className={classes.item_text}>
                                      {numberFormat.format(
                                        storeData.orders?.avg || 0
                                      )}
                                    </p>
                                    <p className={classes.tooltip}>
                                      {numberFormat.format(
                                        storeData.orders?.avg || 0
                                      )}
                                    </p>
                                  </td>
                                )}

                                {visibleColumns["SOS Categories"] &&
                                  sosCategories?.map((item) => (
                                    <td className={classes.tooltip_container}>
                                      <p className={classes.item_text}>
                                        {numberFormat.format(
                                          storeData[item]?.avg || 0
                                        )}
                                        %
                                      </p>
                                      <p className={classes.tooltip}>
                                        {numberFormat.format(
                                          storeData[item]?.avg || 0
                                        )}
                                        %
                                      </p>
                                    </td>
                                  ))}
                              </tr>
                            );
                          })}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            ) : (
              <p>No data available</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ChunkStoresTable;
