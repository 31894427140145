/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import InitiativeDetails from "./initiativeDetails";
import axiosInstance from "../../../../services/api";
import { useLocation, useParams } from "react-router-dom";
import { Box, Text } from "@chakra-ui/react";
import GoBack from "../../../../components/goBack";
import SpinLoader from "../../../../components/spinLoader/spinLoader";
import CustomTabs from "../../../../components/customTabs/customTabs";
import { InitiativeSideNav } from "../../../../components/initiativeSideNav/initiativeSideNav";
import classes from "../../../../styles/init.module.css";
import ActiveResources from "./activeResources/activeResources";
import Attendance from "./initiativeStores/initiativeStoreDetails/components/attendance/attendance";
import AllCompetitors from "./initiativeStores/initiativeStoreDetails/components/competitors/allCompetitors";
import AllMsl from "./initiativeStores/initiativeStoreDetails/components/msl/allMsl";
import Mechanics from "./mechanics/mechanics";
import MessagesTable from "./initiativeStores/initiativeStoreDetails/components/messages/messages";
import Orders from "./initiativeStores/initiativeStoreDetails/components/orders/orders";
import AllOrders from "./initiativeStores/initiativeStoreDetails/components/orders/allOrders";
import AllPayments from "./initiativeStores/initiativeStoreDetails/components/payments/allPayments";
import SurveysTable from "../../surveys/surveysTable/surveysTable";
import AllPricing from "./initiativeStores/initiativeStoreDetails/components/priceCheck/allPricing";
import Reports from "./reports/OutletReports";
import Rewards from "./rewards/rewards";
import AllSales from "./initiativeStores/initiativeStoreDetails/components/sales/allSales";
import AggAttendance from "./aggAttendance/aggAttendance";
import ListCompare from "../../../../components/listCompare/listCompare";
import AllSos from "./initiativeStores/initiativeStoreDetails/components/sos/allSos";
import ShelfAndPosm from "./initiativeStores/initiativeStoreDetails/components/shelfPosm/shelfPosm";
import AllInventory from "./initiativeStores/initiativeStoreDetails/components/inventory/allInventory";
import AllShipments from "./initiativeStores/initiativeStoreDetails/components/shipments/allShipments";
import StoreInventory from "./initiativeStores/initiativeStoreDetails/components/storeInventory/storeInventory";
import Tasks from "./tasks/tasks";
import IStores from "./iStores/iStores";
import AllStoreInventory from "./initiativeStores/initiativeStoreDetails/components/storeInventory/allStoreInventory";
import AllAttendance from "./aggAttendance/allAttendance";
import AllOos from "./initiativeStores/initiativeStoreDetails/components/oos/allOos";
import AllReports from "./reports/allReports";
import ProductGroups from "./mechanics/productGroups/productGroups";
import InitiativeChunkSection from "../../../../components/initiativeSideNav/initiativeChunkSection";
import ChunkStoresTable from "./initiativeStores/initiativeStoreDetails/components/chunkTable/chunkTable";
import Analytics from "./initiativeStores/initiativeStoreDetails/components/analytics/analytics";
import Overview from "./mechanics/overView";

const Init = () => {
  const [initiative, setInitiative] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState("");
  const { iid } = useParams();

  const getInitiative = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(`initiatives/${iid}`);
      setInitiative(data?.data);
      // console.log(iid, data?.data)
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInitiative();
  }, [refetch]);

  const [activeScreen, setActiveScreen] = useState("Stores");

  const { name: initiativeName } = initiative || {};

  const items = {
    //  rest nav

    Stores: <IStores data={initiative} setRefetch={setRefetch} refetch={refetch} />,
    "Active Resources": <ActiveResources initiativeId={initiative?._id} />,
    Attendance: <AllAttendance initiative={initiative} />,
    Competitors: (
      <AllCompetitors initiative={initiative} setRefetch={setRefetch} />
    ),
    MSL: <AllMsl data={initiative} initiative={initiative} />,
    Mechanics: (
      <Overview initiative={initiative} setRefetch={setRefetch} />
    ),
    "Message Center": <MessagesTable initiativeId={initiative?._id} />,
    Orders: (
      <AllOrders
        data={initiative}
        initiative={initiative}
        setRefetch={setRefetch}
      />
    ),
    "Out Of Stock": <AllOos data={initiative} initiative={initiative} />,
    Payments: (
      <AllPayments
        data={initiative}
        initiative={initiative}
        setRefetch={setRefetch}
      />
    ),
    "Price Checks": <AllPricing initiative={initiative} />,
    Reports: <AllReports data={initiative} />,
    Rewards: <Rewards data={initiative} />,
    Sales: <AllSales data={initiative} initiative={initiative} />,
    "Share of Shelf": (
      <AllSos
        data={initiative}
        initiative={initiative}
        setRefetch={setRefetch}
      />
    ),
    "Shelf & POSM": <ShelfAndPosm data={initiative} src={"full"} />,
    "Shelf Inventory": (
      <AllInventory data={initiative} initiative={initiative} />
    ),
    Shipments: <AllShipments data={initiative} initiative={initiative} />,
    "Store Inventory": (
      <AllStoreInventory data={initiative} initiative={initiative} />
    ),
    Surveys: <SurveysTable initiativeId={initiative?._id} />,
    Tasks: <Tasks data={initiative} />,
    "Product Groups": (
      <ProductGroups initiative={initiative} setRefetch={setRefetch} />
    ),
    // summary
    "Table-Stores": <ChunkStoresTable iData={initiative} />,
    Analytics: <Analytics />,
  };

  return (
    <div className={classes.container}>
      <div className={classes.nameAndBackContainer}>
        <GoBack />
        <p>{initiativeName} </p>
      </div>

      <div className={classes.screenContainer}>
        <div className={classes.itemsContainer}>
          <InitiativeSideNav
            activeScreen={activeScreen}
            setActiveScreen={setActiveScreen}
          />
          <InitiativeChunkSection
            activeScreen={activeScreen}
            setActiveScreen={setActiveScreen}
          />
        </div>

        <div className={classes.screen}> {items[activeScreen]}</div>
      </div>
    </div>
  );
};

export default Init;
