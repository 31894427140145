/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Switch, Text, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useSelector } from 'react-redux'
import axiosInstance from '../../../../../../../../services/api';
import { buildResourceURL, } from '../../../../../../../../utils/helper';
import PrimaryButton from '../../../../../../../../components/primaryButton/primarybutton';
import { Loader } from '../../../../../../../../components/Loader/loader';
import classes from './messages.module.css'
import { InitiativeEmptyState } from '../../../../../../../../components/initiativeEmptyState/initiativeEmptyState';
import { formatDateHeader } from '../../../../../../../../helpers/formatDateHeader';
import { InitiativeQueryComponent } from '../../../../../../../../components/initiativeQueryComponent/initiativeQueryComponent';
import { InitiativePaginationComponent } from '../../../../../../../../components/initiativePaginationComponent/initiativePaginationComponent';
import { defaultSearchValueState } from '../../../../../../../../constants/defaultStates';
import UtilityContext from '../../../../../../../../context/utilityContext';
import { MdStorefront } from "react-icons/md";
import { FaRegUser } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import MessageWindow from './messageWindow';
import moment from 'moment';



const MessagesTable = ({ initiativeId }) => {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingx, setLoadingx] = useState(false);
    const [modalType, setModalType] = useState("");
    const [selectedUser, setSelectedUser] = useState(null);
    const [refetch, setRefetch] = useState("");
    const [total, setTotal] = useState('');
    const [changingStatus, setChangingStatus] = useState(false)
    const { draw } = useContext(UtilityContext)

    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const currentUser = useSelector(({ userData }) => userData?.currentUser);

    const [searchValues, setSearchValues] = useState(defaultSearchValueState)
    const { page } = searchValues

    const limit = 100

    const { sid } = useParams()
    const [inputPage, setInputPage] = useState(page)


    const endDate = new Date(searchValues?.endDate);  // Create a new date object based on the current date

    endDate.setDate(endDate.getDate() + 1);

    const queryParams = [
        {
            key: sid ? 'initiativeStore' : 'initiative',
            value: sid ? sid : initiativeId
        },
        {
            key: 'page',
            value: page
        },
        {
            key: 'limit',
            value: limit
        },
        {
            key: 'sort',
            value: '-updatedAt'
        },
        {
            key: 'createdAt[gte]}',
            value: searchValues.startDate
        },
        {
            key: 'createdAt[lt]',
            value: searchValues?.endDate ? endDate.toISOString().split('T')[0] : ""
        },
        {
            key: 'isResolved',
            value: searchValues.resolved || ''
        },
        {
            key: 'search',
            value: searchValues.searchQuery
        },
    ]




    const getMessages = async (action) => {
        setLoading(true)
        try {
            const { data } = await axiosInstance.get(buildResourceURL('messages', queryParams))
            setMessages(data?.data)
            setTotal(data?.totalCount)
        } catch (error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getMessages()
    }, [searchValues, refetch])

    const handleModal = (type, user) => {
        setModalType(type)
        setSelectedUser(user)
        onOpen()
    }

    const deleteMessage = async () => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.delete(`messages/${selectedUser?.id}`)
            toast({
                description: data?.message,
                status: "success",
                position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch (error) {
            if (error?.response?.data?.message) {
                toast({
                    title: "An Error Occurred",
                    description: error?.response?.data?.message,
                    status: "error",
                    position: "top"
                });
            }
        } finally {
            setLoadingx(false)
        }
    }


    const handleToggle = async (id) => {
        setChangingStatus(true)
        draw({})


        try {
            const { data } = await axiosInstance.patch(`messages/${id}/toggle-resolve-status`)
            toast({
                description: data?.message,
                status: "success",
                position: "top"
            });

            setRefetch(Math.random())
            // getMessages('toggleStatusChange')
        } catch (error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top"
            });
        } finally {
            setChangingStatus(false)
        }
    }


    const queryParamsx = [
        {
            key: sid ? 'initiativeStore' : 'initiative',
            value: sid ? sid : initiativeId
        },
        {
            key: 'sort',
            value: '-updatedAt'
        },
        {
            key: 'createdAt[gte]}',
            value: searchValues.startDate
        },
        {
            key: 'createdAt[lt]',
            value: searchValues?.endDate ? endDate.toISOString().split('T')[0] : ""
        },
        {
            key: 'isResolved',
            value: searchValues.resolved || ''
        },
    ]

    return (
        < div className={classes.messagesContainer}>

            <>
                <InitiativeQueryComponent
                    data={null}
                    searchValues={searchValues}
                    setSearchValues={setSearchValues}
                    handleInputChange={null}
                    filterList1={{ title: 'All Messages', name: 'resolved', data: [{ title: 'Resolved', value: true }, { title: 'Unresolved', value: false }] }}
                    entity='messages' queryParamsx={queryParamsx}
                />

                <div className={classes.titleAndInstructionContainer}>
                    <p className={classes.title}>Total Messages Found: {total}</p>
                    <p className={classes.instruction}>Click on each row to see more </p>
                </div>



                {
                    loading ?
                        <div className={classes.loader_con}>
                            <Loader />
                        </div>
                        :
                        <>
                            {
                                messages.length ?
                                    <div className={classes.table_container}>
                                        <p className={classes.showing}>
                                            Showing: <span>{messages.length || 1} of {total}</span>
                                        </p>
                                        <div className={classes.scrollable}>
                                            <table className={classes.table}>
                                                <thead>
                                                    <tr>
                                                        <th>Creation Date</th>
                                                        <th>Title</th>
                                                        <th>Description</th>
                                                        <th>Store</th>
                                                        <th>Location</th>
                                                        <th>Created By</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {messages.map((item, index) => (
                                                        <tr onClick={() => draw({
                                                            title: 'Message', content:
                                                                <div className={classes.modalContainer}>
                                                                    <div className={classes.topButtonContainer}>

                                                                        <button onClick={async () => { await handleToggle(item._id) }} className={classes.resolveButton} disabled={changingStatus}>{item.isResolved && 'un-'}
                                                                            resolve
                                                                        </button>

                                                                        {(currentUser?.role === 'Super Admin') &&

                                                                            <button onClick={() => handleModal('delete', { name: item.title, id: item._id })} className={classes.deleteButton}>Delete</button>
                                                                        }
                                                                    </div>
                                                                    <div className={classes.modalTitleAndDateContainer}>
                                                                        <p className={classes.modalTitle}>{item?.title}</p>
                                                                        <p>{formatDateHeader(moment(item.createdAt).format('YYYY-MM-DD'))}</p>
                                                                    </div>

                                                                    <div className={classes.modalStoreAddressContainer}>
                                                                        <p className={classes.modalStoreAddress}>{item?.initiativeStore?.store?.name} - {item?.initiativeStore?.store?.streetNum || 'No Bld. num.'}, {item?.initiativeStore?.store?.streetName}</p>
                                                                        <MdStorefront className={classes.modalIcon} />
                                                                    </div>

                                                                    <div className={classes.modalStoreAddressContainer}>
                                                                        <div style={{ width: '90%' }}>
                                                                            <p className={classes.modalStoreAddress} style={{ fontWeight: 'bold' }}>{item?.user?.name ? item?.user?.name : `${item?.user?.firstName} ${item?.user?.lastName || ''}`}</p>
                                                                            <p className={classes.messageDescription} style={{ fontWeight: 'normal' }}>{item?.user?.role}</p>
                                                                        </div>

                                                                        <FaRegUser className={classes.modalIcon} />
                                                                    </div>

                                                                    <div className={classes.modalTitleAndDescriptionContainer}>
                                                                        <p className={classes.messageDescription}>{item.description}</p>
                                                                    </div>

                                                                    <MessageWindow messageId={item._id} data={item} />


                                                                </div>
                                                        })} key={index}>

                                                            <td className={classes.tooltip_container}>
                                                                <p className={classes.item_text}>
                                                                    {formatDateHeader(moment(item.createdAt).format('YYYY-MM-DD'))}
                                                                </p>
                                                                <p className={classes.tooltip}>
                                                                    {formatDateHeader(moment(item.createdAt).format('YYYY-MM-DD'))}
                                                                </p>
                                                            </td>

                                                            <td className={classes.tooltip_container}>
                                                                <p className={classes.item_text}>{item.title}</p>
                                                                <p className={classes.tooltip}>{item.title}</p>
                                                            </td>
                                                            <td className={classes.tooltip_container}>
                                                                <p className={classes.item_text}>{item.description}</p>
                                                                <p className={classes.tooltip}>{item.description}</p>
                                                            </td>
                                                            <td className={classes.tooltip_container}>
                                                                <p className={classes.item_text}>{item.initiativeStore?.store?.name}</p>
                                                                <p className={classes.tooltip}>{item.initiativeStore?.store?.name}</p>
                                                            </td>
                                                            <td className={classes.tooltip_container}>
                                                                <p className={classes.item_text}>{item.initiativeStore?.store?.state}</p>
                                                                <p className={classes.tooltip}>{item.initiativeStore?.store?.state}</p>
                                                            </td>
                                                            <td className={classes.tooltip_container}>
                                                                <p className={classes.item_text}>{item.user && (item.user?.role === "Client" ? "🆑" : item.user?.role === "Promoter" ? "🅿️" : item.user?.role === "Supervisor" ? "🆚" : "🅰️")} {item.user ? item.user?.role === "Client" ? item.user?.companyName : (item.user?.firstName + " " + item.user?.lastName?.slice(0, 1).toUpperCase()) : "--"}</p>
                                                                <p className={classes.tooltip}>{item.user && (item.user?.role === "Client" ? "🆑" : item.user?.role === "Promoter" ? "🅿️" : item.user?.role === "Supervisor" ? "🆚" : "🅰️")} {item.user ? item.user?.role === "Client" ? item.user?.companyName : (item.user?.firstName + " " + item.user?.lastName?.slice(0, 1).toUpperCase()) : "--"}</p>
                                                            </td>

                                                            <td className={classes.tooltip_container}>

                                                                <Switch onChange={() => handleToggle(item._id)} defaultChecked={item.isResolved} />
                                                            </td>

                                                        </tr>
                                                    ))}
                                                </tbody>


                                            </table>

                                        </div>




                                        <InitiativePaginationComponent
                                            page={page} totalCount={total} setSearchValues={setSearchValues} setInputPage={setInputPage} inputPage={inputPage} limit={limit}
                                        />
                                    </div>
                                    :
                                    <InitiativeEmptyState name={'messages'} />

                            }

                        </>
                }



            </>



            <Modal isCentered motionPreset='slideInBottom' isOpen={modalType === 'delete' && isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{`${modalType} Message`}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {modalType === 'delete' &&
                            <>
                                <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm deletion of {selectedUser?.name}?</Text>
                                <Flex justifyContent={"center"} my="1.3rem">
                                    <PrimaryButton onClick={deleteMessage} text="Delete" width="150px" bg="crimson !important" padding="1rem !important" loading={loadingx} />
                                </Flex>
                            </>
                        }
                        <Text pb="1rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Cancel</Text>
                    </ModalBody>
                </ModalContent>
            </Modal>

            {/* <CustomPagination
                page={page}
                setPage={setPage}
                pageSize={limit}
                totalElements={total}
            /> */}
        </div>
    )
}



export default MessagesTable;