import React, { useEffect, useState } from "react";
import { withGoogleMap, GoogleMap, Marker, InfoWindow, withScriptjs, Circle, Polyline } from "react-google-maps";
import { findDefaultCenter } from "../../utils/helper";

const ListMap = ({coordinates, height, defaultZoom}) => {
  const [selectedCenter, setSelectedCenter] = useState(null);
//   const tmp = [
//    [3.665341, 6.444293],
//    [3.36304, 6.52104]
// ]

  const coordinatesList = coordinates?.map(i => i?.coordinates)
  const defaultCenter = findDefaultCenter(coordinatesList);


  const circleOptions = {
   strokeColor: "green",  // Circle border color
   strokeOpacity: 0.8,      // Border opacity
   strokeWeight: 2,         // Border thickness
   fillColor: "#FF0000",    // Fill color
   fillOpacity: 0.0,        // Fill opacity (transparent)
   radius: 200,            // Circle radius in meters
  };

  const polylineOptions = {
   strokeColor: '#4285F4' || '#FF0000', // Blue or Red color
   strokeOpacity: 0.8,
   strokeWeight: 4,
   geodesic: true,
  zIndex: 2,
  clickable: true,
  icons: [
    {
      icon: {
        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 2,
        strokeColor: '#FF5733'
      },
      offset: '100%',
      repeat: '50px'
    }
  ]
 };
 
 // Define the path for the polyline
 const pathCoordinates = coordinatesList?.map(x => ({
   lat: x[1],
   lng: x[0]
 }))

//  console.log(pathCoordinates)

  const MyMapComponent = withGoogleMap((props) => (
    <GoogleMap defaultZoom={defaultZoom || 12} defaultCenter={{ lat: defaultCenter?.lat, lng: defaultCenter?.lng  }}>
      {coordinates?.map(i => (
            <Marker
            key={Math.random()} 
            labelStyle={{color: "red !important"}} 
            defaultTitle={i?.name}
            position={{  lat: i?.coordinates[1], lng: i?.coordinates[0]  }} 
            onClick={() => {
           }}
           clickable={true}
           onRightClick={() => {
            console.log("hello");
         }}
            />
         ))}

      {defaultCenter && (
        <Circle
          center={{ lat: defaultCenter?.lat, lng: defaultCenter?.lng }}
          options={circleOptions}
        />
      )}
      <Polyline
         path={pathCoordinates}
         options={polylineOptions}
      />
    </GoogleMap>
  ));

  useEffect(() => {
    const listener = e => {
       if (e.key === "Escape") {
          setSelectedCenter(null);
       }
    };
    window.addEventListener("keydown", listener);
    return () => {
       window.removeEventListener("keydown", listener);
    };
 }, 
 [])

  return (
    <div className="live-tracking" style={{flex: 3}}>
      {selectedCenter && (
   <InfoWindow
      onCloseClick={() => {
         setSelectedCenter(null);
      }}
      position={{
         lat: selectedCenter.latitude,
         lng: selectedCenter.longitude
      }}
   >
   </InfoWindow>
)}

        <div style={{position: "relative"}} className="map-area">
            <MyMapComponent isMarkerShown
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: height }} />}
            mapElement={<div style={{ height: `100%` }} />}
            />
        </div>
        {/* <button onClick={() => console.log(selectedCenter)}>go</button> */}
    </div>
  );
};

export default ListMap;