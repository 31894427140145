import React, { useEffect, useState } from "react";
import CustomListInput from "../../../../../components/customListInput/customListInput";
import PrimaryButton from "../../../../../components/primaryButton/primarybutton";
import axiosInstance from "../../../../../services/api";
import { useToast } from "@chakra-ui/react";
import CustomSelect from "../../../../../components/customSelect/customSelect";

const TeamAssign = ({triggerClose, setTriggerClose, setRefetch, storeId, promoter}) => {
  const [storeData, setStoreData] = useState(null)
  const [supervisorData, setSupervisorData] = useState(null)
  const [promoterData, setPromoterData] = useState(null)
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const [loadingy, setLoadingy] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [employeesx, setEmployeesx] = useState([]);
  const [initiative, setInitiative] = useState('');
  const [initiatives, setInitiatives] = useState([]);

  const [tempData, setTempData] = useState([]);
  const [addCache, setAddCache] = useState([]);
  const [isidx, setIsidx] = useState("");

  const [storeCombo, setStoreCombo] = useState({
    storeId: '',
    promoterId: `${promoter?.firstName} ${promoter?.lastName}`,
    supervisorId: ''
  });

  const toast = useToast();

  const getInitiatives = async() => {
    setLoadingy(true)
    try {
        const { data } = await axiosInstance.get('initiatives')
        setInitiatives(data?.data)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoadingy(false)
    }
  }

  useEffect(() => {
    getInitiatives()
  }, [])

  const handleInputChange = (e) => {
    const {name, value} = e.target
    setStoreCombo({...storeCombo, [name]: value})
  }

    const setSupervisorDataFunc = (val) => {
        setSupervisorData(val)
        setStoreCombo({...storeCombo, supervisorId: val.title})
    }
  
    const getSupervisors = async() => {
        setLoading2(true)
        try {
            const { data } = await axiosInstance.get(`employees?role=Supervisor&search=${storeCombo.supervisorId}`)
            setEmployees(data?.data)
        } catch(error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading2(false)
        }
    }

    useEffect(() => {
        getSupervisors()
    }, [triggerClose, storeCombo?.supervisorId])

    const setPromoterDataFunc = (val) => {
        setPromoterData(val)
        setStoreCombo({...storeCombo, promoterId: val.title})
    }

    const getPromoters = async() => {
        setLoading3(true)
        try {
            const { data } = await axiosInstance.get(`employees?role=Promoter&search=${storeCombo.promoterId}`)
            setEmployeesx(data?.data)
        } catch(error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading3(false)
        }
    }

    useEffect(() => {
        getPromoters()
    }, [triggerClose, storeCombo?.promoterId])

    const assignStore = async(sidx, suidx, pridx, isid) => {
        setIsidx(isid)
        setLoadingx(true)
        const datax = tempData?.length > 0 ?
        {
            storeId,
            supervisorId: suidx,
            promoterId: pridx
        }
        :
        {
          storeId: storeId,
          supervisorId: supervisorData?.id,
          promoterId: promoter?._id || promoterData?.id
        }
        try {
          const {data} = await axiosInstance.post(`/initiatives/${initiative}/initiative-stores`, datax)
          toast({
            description: data?.message,
            status: "success",
            position: "top"
          });
          if(tempData?.length === 0) {
              setTriggerClose('close')
              setRefetch(Math.random())
              setStoreCombo({
                storeId: '',
                promoterId: '',
                supervisorId: ''
              })
              setStoreData(null)
              setSupervisorData(null)
              setPromoterData(null)
          } else {
            setAddCache([...addCache, isid])
          }
        
        } catch(error) {
          toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top"
          });
        } finally {
          setLoadingx(false)
        }
      }

      const initiativeOptions = initiatives?.map(i => ({
        title: i?.name,
        value: i?._id
      }))


  return (
    <>
      <CustomSelect
        options={[{title: "Not Applicable", value: ""}, ...initiativeOptions]}
        placeholder={"Initiative"}
        onChange={e => setInitiative(e.target.value)}
        mb=".5rem"
      />
      <CustomListInput
        type={"text"}
        name="supervisorId"
        value={storeCombo.supervisorId}
        placeholder={"Search supervisors"}
        onChange={(e) => handleInputChange(e)}
        list={employees?.map((i) => ({
          id: i?._id,
          title: i?.firstName + " " + i?.lastName,
          subtitle: i?.email,
        }))}
        setData={setSupervisorDataFunc}
        data={supervisorData}
        loading={loading2}
      />
      <CustomListInput
        type={"text"}
        name="promoterId"
        value={storeCombo.promoterId}
        placeholder={"Search promoters"}
        onChange={(e) => handleInputChange(e)}
        list={employeesx?.map((i) => ({
          id: i?._id,
          title: i?.firstName + " " + i?.lastName,
          subtitle: i?.email,
        }))}
        setData={setPromoterDataFunc}
        data={promoterData}
        loading={loading3}
      />
      <PrimaryButton
        onClick={assignStore}
        position="absolute"
        width="90%"
        bottom="3%"
        text="Submit"
        loading={loadingx}
      />
    </>
  );
};

export default TeamAssign;
