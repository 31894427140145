import AggAttendance from './aggAttendance'
import { useState } from 'react'
import { InitiativeHeaderTab } from '../../../../../components/initiativeHeaderTab/initiativeHeaderTab'
import classes from './attendance.module.css'
import AllAttendanceDaily from './allAttendanceDaily'

const AllDaily = ({ initiative }) => {

    const assignor = {
        "All": <AllAttendanceDaily data={initiative} />,
        "Cummulative": <AggAttendance data={initiative} />,
    }

    const [selected, setSelected] = useState(Object.keys(assignor)[0])


    return (
        <div className={classes.container}>
            <InitiativeHeaderTab list={Object.keys(assignor)} selected={selected} setSelected={setSelected} />
            <div>
                {assignor[selected]}
            </div>
        </div>
    )
}

export default AllDaily;