import ListCompare from '../../../../../../../../components/listCompare/listCompare'
import Orders from './orders'
import AggregateOrders from './aggregateOrders'
import PeriodAggregate from './periodAggregate'
import { InitiativeHeaderTab } from '../../../../../../../../components/initiativeHeaderTab/initiativeHeaderTab'
import { useState } from 'react'
import classes from './orders.module.css'
import PreloadedBrands from './PreloadedOrders/preloadedBrands'

const AllOrders = ({ initiative, setRefetch }) => {


    const assignor = {
        "All Orders": <Orders data={initiative} />,
        "Brand Aggregates": <AggregateOrders data={initiative} />,
        "Store Aggregates": <PeriodAggregate iData={initiative} />,
        "Preloaded Order Brands": <PreloadedBrands initiative={initiative} />,
        "Not Submitted": <ListCompare listType={'order'} />
    }

    const [selected, setSelected] = useState(Object.keys(assignor)[0])

    return (
        <div className={classes.container}>
            <InitiativeHeaderTab list={Object.keys(assignor)} selected={selected} setSelected={setSelected} />
            <div>
                {assignor[selected]}
            </div>
        </div>
    )
}

export default AllOrders;