import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import moment from 'moment';
import axiosInstance from '../../../../../../../../services/api';
import SpinLoader from '../../../../../../../../components/spinLoader/spinLoader';

const StoreTasks = () => {
    const [task, setTask] = useState();
    const [loading, setLoading] = useState(false);

    const {sid} = useParams();

    const getTask = async() => {
        setLoading(true)
        try {
            const { data } = await axiosInstance.get(`tasks/initiative-store-tasks/${sid}`)
            setTask(data?.data)
        } catch(error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }
    
    useEffect(() => {
        getTask()
    }, [])

    // const data = [
    //     {
    //         title: "All",
    //         render: <Mechanics data={initiative} merged={true} />
    //     },
    //     {
    //         title: "Completed",
    //         render: <Competitors data={initiative} />
    //     },
    //     {
    //         title: "Pending",
    //         render: <Sales data={initiative} />
    //     }
    // ]

  return (
    <Box bg="#FFF" borderRadius={"6px"} p="1rem">
        {loading ?
        <SpinLoader />
        :
        <Box mt="1.2rem">
            {task?.map(({assignedInitiativeStores, comment, name, dueDate, dueTime}) => {
                const reply = assignedInitiativeStores?.filter(i => i?.initiativeStore === sid)[0]?.reply
                return (
                    <Box mb=".7rem" border="1px solid #D0D5DD" p="1rem" borderRadius={"8px"}>
                        <Text fontWeight={"500"} color="#667085" textTransform={"capitalize"}>{name}</Text>
                        <Text opacity={".7"} fontWeight={"500"} fontSize={".85rem"} color={"#27272A"}>{comment}</Text>

                        {reply !== null && 
                        <Flex my="1rem" alignItems={"start"} border="1px solid #D0D5DD" gap="1rem" p="1rem" borderRadius={"8px"}>
                            <Flex alignItems={"center"} gap={".7rem"}>
                                {reply?.uploadURLs?.map(i => (
                                    <a key={i} href={i} target="_blank" rel="noopener noreferrer">
                                    <Box height={"40px"} cursor={"pointer"} width={"50px"} borderRadius={"4px"} bg="lightgray" backgroundImage={i} backgroundSize={"cover"}>

                                    </Box></a>
                                ))}
                            </Flex>
                            <Text opacity={".7"} transform={"translateY(-6px)"} fontWeight={"500"} fontSize={".85rem"} color={"#27272A"}>{reply?.comment}</Text>
                        </Flex>}

                        <Flex alignItems={"center"} justifyContent={"space-between"} mt={reply === null ? "1rem" : ""}>
                            <Text fontSize={"12px"} width={"90px"} textAlign={"center"} whiteSpace={"nowrap"} fontWeight={"500"} textTransform={"capitalize"} borderRadius={"20px"} padding={".2rem 1rem"} bg={reply === null ? "#FFE3B0" : "#E5FFE4"} color={reply === null ? "#EC913C" : "#44A234"}>{reply === null ? "Pending" : "Completed"}</Text>
                            {reply !== null ? 
                            <Text fontWeight={"400"} fontSize={".85rem"} color="#667085" textTransform={"capitalize"}>{moment(reply?.date).calendar()}</Text>
                            :
                            <Text fontWeight={"400"} fontSize={".85rem"} color="#667085" textTransform={"capitalize"}>Due: {moment(dueDate).calendar()}</Text>
                            }
                        </Flex>
                    </Box>
            )})}
        </Box>}
    </Box>
  )
}

export default StoreTasks;