import React, { useState } from 'react'
import { useToast } from '@chakra-ui/react';
import CustomInput from '../../../../components/customInput/customInput';
import axiosInstance from '../../../../services/api';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';


const CreateSubUser = ({loading, setLoading, setTriggerClose}) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");

  const toast = useToast();

  const createUser = async(e) => {
    e.preventDefault()
    setLoading(true)
    const datax = {
      name,
      email
    }
    try {
      const {data} = await axiosInstance.post('/subusers', datax)
      toast({
        description: data?.message,
        status: "success",
        position: "top"
      });
      setTriggerClose('close' + (Math.random()))
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
      });
    } finally {
      setLoading(false)
    }
  }


  return (
    <form onSubmit={createUser}>
        <CustomInput
        type={"text"}
        required
        placeholder={"Name"}
        onChange={e => setName(e.target.value)}
        />
        <CustomInput
        type={"email"}
        required
        placeholder={"Email Address"}
        onChange={e => setEmail(e.target.value)}
        />
         {/* <CustomInput
        type={"tel"}
        minLength={11}
        maxLength={11}
        required
        placeholder={"Phone number"}
        mb={".8rem"}
        onChange={e => setPhoneNumber(e.target.value)}
        /> */}
        <PrimaryButton mt="4rem" type="submit" text="Add Subuser" func={() => {}} loading={loading} />
    </form>
  )
}

export default CreateSubUser;