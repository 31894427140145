import OutletReports from './OutletReports'
import { InitiativeHeaderTab } from '../../../../../components/initiativeHeaderTab/initiativeHeaderTab'
import { useState } from 'react'
import classes from './reports.module.css'
import Reports from './reports'

const AllReports = ({ initiative }) => {


    const assignor = {
        "Initiative Store Reports":<OutletReports data={initiative} /> ,
        "General Reports": <Reports data={initiative} />,
    }

    const [selected, setSelected] = useState(Object.keys(assignor)[0])


    return (
        <div className={classes.container}>
            <InitiativeHeaderTab list={Object.keys(assignor)} selected={selected} setSelected={setSelected} />
            <div>
                {assignor[selected]}
            </div>
        </div>
    )
}

export default AllReports;