import React, { useState } from 'react'
import { useToast } from '@chakra-ui/react';
import CustomInput from '../../../../components/customInput/customInput';
import axiosInstance from '../../../../services/api';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';


const EditSubuser = ({loading, setLoading, setTriggerClose, datay}) => {
  const [email, setEmail] = useState(datay?.email);
  const [name, setName] = useState(datay?.name);

  const toast = useToast();

  const editSubuser = async(e) => {
    e.preventDefault()
    setLoading(true)
    const datax = {
      name,
      email,
    }
    try {
      const {data} = await axiosInstance.patch(`/subusers/${datay?.id}`, datax)
      toast({
        description: data?.message,
        status: "success",
        position: "top"
      });
      setTriggerClose('close' + (Math.random()))
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
      });
    } finally {
      setLoading(false)
    }
  }


  return (
    <form onSubmit={editSubuser}>
        <CustomInput
        type={"text"}
        required
        value={name}
        placeholder={"Name"}
        onChange={e => setName(e.target.value)}
        />
        <CustomInput
        type={"email"}
        required
        value={email}
        placeholder={"Email Address"}
        onChange={e => setEmail(e.target.value)}
        />
        <PrimaryButton mt="1rem" type="submit" text="Update Subuser" func={() => {}} loading={loading} />
    </form>
  )
}

export default EditSubuser;