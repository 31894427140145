import ListCompare from '../../../../../../../../components/listCompare/listCompare'
import Payments from './payments'
import AggregatePayments from './aggregatePayments'
import PeriodAggregate from './periodAggregate'
import { InitiativeHeaderTab } from '../../../../../../../../components/initiativeHeaderTab/initiativeHeaderTab'
import classes from './payments.module.css'
import { useState } from 'react'
import PreloadedBrands from './PreloadedPayments/preloadedBrands'


const AllPayments = ({ initiative, setRefetch }) => {

    const assignor = {
        "All Payments": <Payments data={initiative} />,
        "Brand Aggregates": <AggregatePayments data={initiative} />,
        "Store Aggregates": <PeriodAggregate iData={initiative} />,
        "Payment Brand": <PreloadedBrands initiative={initiative} setRefetch={setRefetch} />,
        "Not Submitted": <ListCompare listType={'payment'} />
    }

    const [selected, setSelected] = useState(Object.keys(assignor)[0])

    return (
        <div className={classes.container}>
            <InitiativeHeaderTab list={Object.keys(assignor)} selected={selected} setSelected={setSelected} />
            <div>
                {assignor[selected]}
            </div>
        </div>
    )
}

export default AllPayments;