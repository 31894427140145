import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import CustomSelect from '../../../../../../../../components/customSelect/customSelect';
import CustomInput from '../../../../../../../../components/customInput/customInput';
import PrimaryButton from '../../../../../../../../components/primaryButton/primarybutton';
import axiosInstance from '../../../../../../../../services/api';
import LineSkeleton from '../../../../../../../../components/lineSkeleton';
import EmptyState from '../../../../../../../../components/emptyState/emptyState';
import CustomTable from '../../../../../../../../components/customTable/customTable';
import { useParams } from 'react-router-dom';
import { buildResourceURL, formatNumber } from '../../../../../../../../utils/helper';
import { useSelector } from 'react-redux';
import statesLgaJson from '../../../../../../../../utils/stateLGAs';
import ExportCSV from '../../../../../../../../components/exportCsv';
import classes from './sos.module.css'
import { InitiativeQueryComponent } from '../../../../../../../../components/initiativeQueryComponent/initiativeQueryComponent';
import { Loader } from '../../../../../../../../components/Loader/loader';
import { InitiativeEmptyState } from '../../../../../../../../components/initiativeEmptyState/initiativeEmptyState';
import { defaultSearchValueState } from '../../../../../../../../constants/defaultStates';
import { numberFormat } from '../../../../../../../../helpers/numberFormatter';

const StoreBrandAggregate = ({ iData }) => {
    const [brand, setBrand] = useState("")
    const [startDate, setStartDate] = useState("")
    const [loading, setLoading] = useState(false)
    const { iid } = useParams();
    const [showBrandColumn, setShowBrandColumn] = useState(false)

    const [searchValues, setSearchValues] = useState(defaultSearchValueState)

    const endDate = new Date(searchValues.endDate);  // Create a new date object based on the current date
    endDate.setDate(endDate.getDate() + 1);

    const [docs, setDocs] = useState([])

    const options1 = iData?.brands?.map(i => ({
        title: `${i?.name}-${i?.sku}`,
        value: JSON.stringify({ brandName: i?.name, sku: i?.sku })
    }))

    options1.sort((a, b) => a.title.localeCompare(b.title))


    const queryParams = [
        {
            key: "initiativeId",
            value: iid
        },
        {
            key: "brandName",
            value: JSON.parse(searchValues?.brandName || "{}")?.brandName
        },
        {
            key: "sku",
            value: JSON.parse(searchValues?.brandName || "{}")?.sku
        },
        {
            key: "startDate",
            value: searchValues?.startDate
        },
        {
            key: "endDate",
            value: (searchValues?.startDate && searchValues?.endDate) ? endDate.toISOString().split('T')[0] : ''
        },
        {
            key: 'state',
            value: searchValues.states.toLowerCase()
        },
        {
            key: 'initiativeStoresFilterGroupId',
            value: searchValues.groups
        },
        {
            key: 'productsFilterGroupId',
            value: searchValues.products
        },
        {
            key: 'statesFilterGroupId',
            value: searchValues.regions
        },
    ]

    const getResults = async (e) => {
        e?.preventDefault()
        setLoading(true)
        try {
            const { data } = await axiosInstance.get(buildResourceURL(`/sos/aggregate/store-and-product`, queryParams))
            // console.log(data?.data)
            setDocs(data?.data)
            if (brand !== "") {
                setShowBrandColumn(true)
            } else {
                setShowBrandColumn(false)
            }
        } catch (error) {
            console.log(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getResults()
    }, [searchValues])

    
    // Helper function to format the data
const formatData = (data) => {
    const storeMap = {};

    // Loop through each entry in the input data
    data.forEach(item => {
        const storeId = item.store._id;
        const storeName = item.store.name;

        if (!storeMap[storeId]) {
            // Initialize the store in the map if not present
            storeMap[storeId] = {
                storeName: storeName,
                sosBrands: [],
                storeDetails: {
                    state: item.store.state,
                    area: item.store.area,
                    town: item.store.town,
                    streetName: item.store.streetName,
                    imageURL: item.store.imageURL,
                    ownerPhoneNumber: item.store.ownerPhoneNumber,
                    ownerLastName: item.store.ownerLastName
                }
            };
        }

        // Add the brand info to the sosBrands array, but limit to 4
        if (storeMap[storeId].sosBrands.length < 4) {
            storeMap[storeId].sosBrands.push({
                brandName: item.brandName,
                avg: item.avg,
                count: item.count,
                total: item.total
            });
        }
    });

    // Convert the map into an array format
    return Object.values(storeMap);
};

const formattedData = formatData(docs);


const refinedData = formattedData?.map(i => ({
    // ...spreadObj,
    [iData?.sosBrands[0]?.name?.split(' ')[0]]: numberFormat.format(i?.sosBrands[0]?.avg),
    [iData?.sosBrands[1]?.name?.split(' ')[0]]: numberFormat.format(i?.sosBrands[1]?.avg),
    [iData?.sosBrands[2]?.name?.split(' ')[0]]: numberFormat.format(i?.sosBrands[2]?.avg),
    [iData?.sosBrands[3]?.name?.split(' ')[0]]: numberFormat.format(i?.sosBrands[3]?.avg),
    [iData?.sosBrands[4]?.name?.split(' ')[0]]: numberFormat.format(i?.sosBrands[4]?.avg),
    [iData?.sosBrands[5]?.name?.split(' ')[0]]: numberFormat.format(i?.sosBrands[5]?.avg),
    outlet: i?.storeName,
    location: i?.storeDetails?.state,
    lga: i?.storeDetails?.area,
}))

const headers = [
    {
        key: 'outlet',
        name: 'Outlet',
    },
    {
        key: 'location',
        name: 'Location',
    },
    {
        key: 'lga',
        name: 'LGA',
    },
    ...iData?.sosBrands?.map(x => ({ 
        key: x?.name?.split(' ')[0],
        name: `${x?.name?.slice(0, -14)} (%)`,
    }))
]

    return (
        <div className={classes.screenContainer}>

        <InitiativeQueryComponent
            data={iData}
            searchValues={searchValues}
            setSearchValues={setSearchValues}
            filterList1={{ title: 'Brand', name: 'brandName', data: options1 }}
            aggregate={{ headers, refinedData }}
        />


        {
            loading ?
                <div className={classes.loader_con}>
                    <Loader />
                </div>
                :
                <>
                    {
                        formattedData.length ?
                            <div className={classes.table_container}>

                                <div className={classes.titleAndInstructionContainer}>
                                    <p className={classes.title}>Store Aggregates (SOS) Found: {formattedData.length}</p>
                                    <p className={classes.instruction}>Click on each row to see more </p>
                                </div>

                                <p className={classes.showing}>
                                    Showing: <span>{formattedData.length || 1} of {formattedData.length}</span>
                                </p>
                                <div className={classes.scrollable}>
                                    <table className={classes.table}>
                                        <thead>
                                            <tr>
                                                <th>Store</th>
                                                <th>Location</th>
                                                {iData?.sosBrands?.map(i => (
                                                    <th>{i?.name?.slice(0, -14)}</th>
                                                ))}
                                                {/* <th>Average</th>
                                                <th>Entries</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formattedData.map((item, index) => (
                                                <tr key={index}>

                                                    <td className={classes.tooltip_container}>
                                                        <p className={classes.item_text}>{item?.storeName}</p>
                                                        <p className={classes.tooltip}>{item?.storeName}</p>
                                                    </td>
                                                    <td className={classes.tooltip_container}>
                                                        <p className={classes.item_text}>{item?.storeDetails?.area}, {item?.storeDetails?.state}</p>
                                                        <p className={classes.tooltip}>{item?.storeDetails?.area}, {item?.storeDetails?.state}</p>
                                                    </td>
                                                    <td className={classes.tooltip_container}>
                                                        <p className={classes.item_text}>{numberFormat.format(item?.sosBrands[0]?.avg)}%</p>
                                                        <p className={classes.tooltip}>{numberFormat.format(item?.sosBrands[0]?.avg)}%</p>
                                                    </td>
                                                    <td className={classes.tooltip_container}>
                                                        <p className={classes.item_text}>{numberFormat.format(item?.sosBrands[1]?.avg)}%</p>
                                                        <p className={classes.tooltip}>{numberFormat.format(item?.sosBrands[1]?.avg)}%</p>
                                                    </td>
                                                    <td className={classes.tooltip_container}>
                                                        <p className={classes.item_text}>{numberFormat.format(item?.sosBrands[2]?.avg)}%</p>
                                                        <p className={classes.tooltip}>{numberFormat.format(item?.sosBrands[2]?.avg)}%</p>
                                                    </td>
                                                    <td className={classes.tooltip_container}>
                                                        <p className={classes.item_text}>{numberFormat.format(item?.sosBrands[3]?.avg)}%</p>
                                                        <p className={classes.tooltip}>{numberFormat.format(item?.sosBrands[3]?.avg)}%</p>
                                                    </td>

                                                    {/* <td className={classes.tooltip_container}>
                                                        <p className={classes.item_text}>{numberFormat.format(item.count)}</p>
                                                        <p className={classes.tooltip}>{numberFormat.format(item.count)}</p>
                                                    </td> */}

                                                </tr>
                                            ))}
                                        </tbody>


                                    </table>

                                </div>

                            </div>
                            :
                            <InitiativeEmptyState searchValues={searchValues} name={'store aggregate sos'} />

                    }

                </>
        }



    </div>
    )
}

export default StoreBrandAggregate;