import ListCompare from '../../../../../../../../components/listCompare/listCompare'
import Competitors from './competitors'
import PreloadedCompetitors from './PreloadedCompetitors/preloadedCompetitors'
import AggregateCompetitors from './aggregateCompetitors'
import PeriodAggregate from './periodAggregate'
import { useState } from 'react'
import { InitiativeHeaderTab } from '../../../../../../../../components/initiativeHeaderTab/initiativeHeaderTab'
import classes from './competitors.module.css'


const AllCompetitors = ({ initiative, setRefetch }) => {

    const assignor = {
        "All": <Competitors data={initiative} />,
        "Brand Aggregates": <AggregateCompetitors data={initiative} />,
        "Store Aggregates": <PeriodAggregate iData={initiative} />,
        "Not Submitted": <ListCompare listType={'competitor'} />,
        "Preloaded Competitors": <PreloadedCompetitors initiative={initiative} setRefetch={setRefetch} />
    }

    const [selected, setSelected] = useState(Object.keys(assignor)[0])

    return (
        <div className={classes.container}>
            <InitiativeHeaderTab list={Object.keys(assignor)} selected={selected} setSelected={setSelected} />
            <div>
                {assignor[selected]}
            </div>
        </div>
    )
}

export default AllCompetitors;