import "./sidebar.scss";
import Logo from "../../assets/full-logo.svg";
import LogoIcon from "../../assets/logo.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import User from "./icons/user.svg";
import { Avatar, Box, Flex, Image, Switch, Text } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import React, { useState } from "react";
import { FiSettings } from "react-icons/fi";
import { GoGitPullRequest } from "react-icons/go";
import { AiOutlineAppstore, AiOutlineNotification, AiOutlineSetting } from "react-icons/ai";
import { BiStoreAlt, BiMessageSquareDetail, BiLogOut, BiGitRepoForked } from "react-icons/bi";
import {
  IoMdNotificationsOutline,
} from "react-icons/io";
import { TbDeviceAnalytics } from "react-icons/tb";
import { useSelector } from "react-redux";
import { MdWorkspacesOutline } from "react-icons/md";
import { FaWpforms } from "react-icons/fa";
import classes from './sideBar.module.css'
import { IoSettingsOutline } from "react-icons/io5";
import { CgBell } from "react-icons/cg";
import { RiMapPinUserLine, RiOrganizationChart, RiUserFollowLine } from "react-icons/ri";
import { BsPersonRolodex } from "react-icons/bs";
import { HiOutlineCollection } from "react-icons/hi";



const Sidebar = ({ collapsed, setCollapsed }) => {
  const { pathname } = useLocation();
  const [expanded, setExpanded] = useState("");
  const [expandedx, setExpandedx] = useState(false);
  const [ProfileBlobOpen, setProfileBlobOpen] = useState(false);

  const navigate = useNavigate();

  const currentUser = useSelector(({ userData }) => userData.currentUser);


  const handleExpanded = (name, route) => {
    setExpandedx(!expandedx);
    setExpanded(name);
    navigate(route);
  };

  const handleUnfocus = () => {
    setExpanded("");
    setExpandedx(false);
  };

  const logout = () => {
    localStorage.removeItem("PRTK");
    localStorage.removeItem("userType");
    window.location.href = currentUser?.role === "Client" ? "/" : currentUser?.role === "Agency" ? "/agency" : currentUser?.role === 'Basic Subuser' ? "/" : "/login";
  };

  const sideNavData = [
    {
      icon: (
        <AiOutlineAppstore
          className={classes.icon}
        />
      ),
      name: "Overview",
      subNavs: [],
      routes: ["/"],
    },
    {
      icon: (
        <TbDeviceAnalytics
          className={classes.icon}
        />
      ),
      name: "Initiatives",
      subNavs: [],
      routes: ["/initiatives"],
    },
    {
      icon: (
        <RiOrganizationChart
          className={classes.icon}
        />
      ),
      name: "Agencies",
      subNavs: [],
      routes: ["/agencies"],
    },
    {
      icon: (
        <BsPersonRolodex
          className={classes.icon}
        />
      ),
      name: "Clients",
      subNavs: [],
      routes: ["/clients"],
    },
    {
      icon: (
        <RiUserFollowLine
          className={classes.icon}
        />
      ),
      name: "Supervisors",
      subNavs: [],
      routes: ["/supervisors"],
    },
    {
      icon: (
        <RiMapPinUserLine
          className={classes.icon}
        />
      ),
      name: "Promoters",
      subNavs: [],
      routes: ["/promoters"],
    },
    {
      icon: (
        <BiStoreAlt
          className={classes.icon}
        />
      ),
      name: "Outlets",
      subNavs: [],
      routes: ["/outlets"],
    },
    {
      icon: (
        <FaWpforms
          className={classes.icon}
        />
      ),
      name: "Surveys",
      subNavs: [],
      routes: ["/surveys"],
    },
    {
      icon: (
        <GoGitPullRequest
          className={classes.icon}
        />
      ),
      name: "Requests",
      subNavs: [],
      routes: ["/requests"],
    },
    {
      icon: (
        <FiSettings
          className={classes.icon}
        />
      ),
      name: "Settings",
      subNavs: [],
      routes: ["/settings"],
    }
  ];

  const agencySideNavData = [
    // {
    //   icon: (
    //     <AiOutlineAppstore
    //       style={{
    //         marginLeft: collapsed ? "1rem" : "",
    //         transform: collapsed ? "scale(1.3)" : "",
    //       }}
    //       className="img"
    //     />
    //   ),
    //   name: "Overview",
    //   subNavs: [],
    //   routes: ["/"],
    // },
    {
      icon: (
        <TbDeviceAnalytics
          className={classes.icon}
        />
      ),
      name: "Initiatives",
      subNavs: [],
      routes: ["/initiatives"],
    },
    // {
    //   icon: (
    //     <MdWorkspacesOutline
    //       style={{
    //         marginLeft: collapsed ? "1rem" : "",
    //         transform: collapsed ? "scale(1.3)" : "",
    //       }}
    //       className="img"
    //     />
    //   ),
    //   name: "Clients",
    //   subNavs: [],
    //   routes: ["/clients"],
    // },
    {
      icon: (
        <RiUserFollowLine
          className={classes.icon}
        />
      ),
      name: "Supervisors",
      subNavs: [],
      routes: ["/supervisors"],
    },
    {
      icon: (
        <RiMapPinUserLine
          className={classes.icon}
        />
      ),
      name: "Promoters",
      subNavs: [
        // {
        //   name: "Transactions",
        //   route: "/dashboard/wallet",
        // },
      ],
      routes: ["/promoters"],
    },
    {
      icon: (
        <BiStoreAlt
          className={classes.icon}
        />
      ),
      name: "Outlets",
      subNavs: [],
      routes: ["/outlets"],
    },
    {
      icon: (
        <GoGitPullRequest
          className={classes.icon}
        />
      ),
      name: "Requests",
      subNavs: [],
      routes: ["/requests"],
    },
    {
      icon: (
        <FiSettings
          className={classes.icon}
        />
      ),
      name: "Settings",
      subNavs: [],
      routes: ["/settings"],
    }
  ];

  const clientSideNavData = [
    {
      icon: (
        <TbDeviceAnalytics
          className={classes.icon}
        />
      ),
      name: "Initiatives",
      subNavs: [],
      routes: ["/initiatives"],
    },
    {
      icon: (
        <BiStoreAlt
          className={classes.icon}
        />
      ),
      name: "Outlets",
      subNavs: [],
      routes: ["/outlets"],
    },
    {
      icon: (
        <RiMapPinUserLine
          className={classes.icon}
        />
      ),
      name: "Promoters",
      subNavs: [],
      routes: ["/promoters"],
    },
    {
      icon: (
        <RiUserFollowLine
          className={classes.icon}
        />
      ),
      name: "Supervisors",
      subNavs: [],
      routes: ["/supervisors"],
    },
    {
      icon: (
        <HiOutlineCollection
          className={classes.icon}
        />
      ),
      name: "Assets",
      subNavs: [],
      routes: [`/assets?name=${currentUser?.companyName}`],
    },
    {
      icon: (
        <FaWpforms
          className={classes.icon}
        />
      ),
      name: "Surveys",
      subNavs: [],
      routes: ["/surveys"],
    },
    {
      icon: (
        <FaWpforms
          className={classes.icon}
        />
      ),
      name: "Sub-Users",
      subNavs: [],
      routes: ["/subusers"],
    },
    {
      icon: (
        <FiSettings
          className={classes.icon}
        />
      ),
      name: "Settings",
      subNavs: [],
      routes: ["/settings"],
    }
  ];

  const subuserSideNavData = [
    {
      icon: (
        <TbDeviceAnalytics
          className={classes.icon}
        />
      ),
      name: "Initiatives",
      subNavs: [],
      routes: ["/initiatives"],
    },
    {
      icon: (
        <BiStoreAlt
          className={classes.icon}
        />
      ),
      name: "Outlets",
      subNavs: [],
      routes: ["/outlets"],
    },
    {
      icon: (
        <RiMapPinUserLine
          className={classes.icon}
        />
      ),
      name: "Promoters",
      subNavs: [],
      routes: ["/promoters"],
    },
    {
      icon: (
        <RiUserFollowLine
          className={classes.icon}
        />
      ),
      name: "Supervisors",
      subNavs: [],
      routes: ["/supervisors"],
    },
    {
      icon: (
        <FaWpforms
          className={classes.icon}
        />
      ),
      name: "Surveys",
      subNavs: [],
      routes: ["/surveys"],
    },
    {
      icon: (
        <FiSettings
          className={classes.icon}
        />
      ),
      name: "Settings",
      subNavs: [],
      routes: ["/settings"],
    }
  ];

  const currData = currentUser?.role === "Client" ? clientSideNavData : currentUser?.role === "Agency" ? agencySideNavData : currentUser?.role === "Basic Subuser" ? subuserSideNavData : sideNavData


  return (

    <div className={classes.container}>

      <img src={Logo} alt="logo" className={classes.logo} />
      <div className={classes.iconsGrouped}>
        {
          currData?.map((item, index) => (
            <div onClick={() => navigate(item?.routes[0])} className={classes.navContainer} key={index}>
              {React.cloneElement(item.icon, {
                style: { color: item.routes.includes(pathname) ? '#ff6f61' : '' },
              })}
              <p className={classes.name}>{item?.name}</p>
            </div>
          ))
        }
      </div>

      <div className={classes.avatarContainer}>
        <img className={classes.avatar} src={User} alt="user" />
        <div className={classes.profileModal}>
          <div onClick={() => navigate('/settings')} className={classes.profileNav}><IoSettingsOutline className={classes.profileIcon} />Settings</div>
          {currentUser?.role !== "Client" && <div onClick={() => navigate('/requests')} className={classes.profileNav}><CgBell className={classes.profileIcon} />Requests</div>}
          <div onClick={logout} className={classes.profileNav}><BiLogOut className={classes.profileIcon} />Log Out</div>
        </div>


      </div>
    </div>
  );
};

export default Sidebar;























