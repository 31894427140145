/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import classes from "./assetsComponents.module.css";
import { Loader } from "components/Loader/loader";
import { InitiativeEmptyState } from "components/initiativeEmptyState/initiativeEmptyState";
import { numberFormat } from "helpers/numberFormatter";
import { formatDateHeader } from "helpers/formatDateHeader";
import moment from "moment";
import { MdStorefront } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import defaultImage from '../../../assets/placeholderImage.png'
import { GiLevelTwoAdvanced } from "react-icons/gi";
import { CiSearch } from "react-icons/ci";
import Fuse from "fuse.js";
import { defaultAssetOverviewInvisibleColumns, defaultAssetOverviewVisibleColumns, defaultSearchValueState, overViewTableInclusionList } from "constants/defaultStates";
import { calculateDeviation } from "helpers/Haversine";
import { NewExport } from "components/NewExport/NewExport";
import { InitiativeQueryComponent } from "components/initiativeQueryComponent/initiativeQueryComponent";
import { CustomSelect } from "./customSelect";

const url = process.env.REACT_APP_BASE_URL;

export default function AssetTable({ clientId, showToast, draw, brandGroups, storeGroups }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [flattenedData, setFlattenedData] = useState([]);
    const [searchValues, setSearchValues] = useState(defaultSearchValueState);

    const [visibleColumns, setVisibleColumns] = useState(
        defaultAssetOverviewVisibleColumns
    );
    const [selectedDate, setSelectedDate] = useState(() => {
        const today = new Date();
        return today.toISOString().split("T")[0];
    });

    const limit = 100

    const getData = async (date) => {
        setLoading(true);
        const token = localStorage.getItem("PRTK");
        const config = {
            headers: {
                authorization: `Bearer ${token}`,
            },
        };

        try {
            const response = await axios.get(
                `${url}/assets/clients/overviewTable/?clientId=${clientId}&search=${searchValues?.searchQuery}&date=${selectedDate || ''}&limit=${limit}&page=${searchValues.page}&brandGroup=${searchValues?.['brand group'] || ''}&storeGroup=${searchValues?.['store group'] || ''}`, config);

            setData(response.data.data || []);
        } catch (error) {
            const message = error?.response?.data?.message || "Something went wrong";
            showToast(message, "error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData(selectedDate);
    }, [clientId, selectedDate, searchValues]);

    useEffect(() => {
        const flattened = data.flatMap((group) =>
            Object.entries(group).flatMap(([groupName, entries]) =>
                entries.map((entry) => ({
                    groupName,
                    asset: `${entry?.asset?.brand} - ${entry?.asset?.category} - ${entry?.asset?.type}` || "N/A",
                    location: entry?.asset?.outlet ? entry?.asset?.outlet?.location?.coordinates : [entry?.asset?.addressDetails?.coordinates?.longitude, entry?.asset?.addressDetails?.coordinates?.latitude],
                    user: entry.user
                        ? `${entry.user.firstName} ${entry.user.lastName}`
                        : "N/A",
                    image: entry.image || "N/A",
                    imageLocation: entry.imageLocation || "N/A",
                    comment: entry.comment || "N/A",
                    commentLocation: entry.commentLocation || "N/A",
                    condition: entry.condition || "N/A",
                    conditionLocation: entry.conditionLocation || "N/A",
                    mslLevel: entry.mslLevel || "N/A",
                    mslLevelLocation: entry.mslLevelLocation || "N/A",
                    inventoryLevel: entry.inventoryLevel || "N/A",
                    inventoryLevelLocation: entry.inventoryLevelLocation || "N/A",
                }))
            )
        );
        setFlattenedData(flattened);
    }, [data]);

    const handleDateChange = (e) => setSelectedDate(e.target.value);



    const handleColumnToggle = (column) => {
        setVisibleColumns((prev) => ({ ...prev, [column]: !prev[column] }));
    }




    return (
        <div className={classes.container}>

            <div className={`${classes.titleAndInstructionContainer} ${classes.flexTitleAndInstructionContainer}`}>


                <div className={classes.inputContainer}>
                    <CiSearch className={classes.searchIcon} />
                    <input
                        className={classes.input}
                        type="text"
                        placeholder={"Search by brand name / category, or full address"}
                        onChange={(e) => { setSearchValues((prev) => ({ ...prev, searchQuery: e.target.value })) }}
                        name="searchQuery"
                        value={searchValues?.searchQuery}
                    />
                </div>

                <div className={classes.customerSelectContainer}>
                    <CustomSelect type={'brand group'} groupList={brandGroups} searchValues={searchValues} setSearchValues={setSearchValues} />
                    <CustomSelect type={'store group'} groupList={storeGroups} searchValues={searchValues} setSearchValues={setSearchValues} />
                </div>

                <input
                    className={classes.date}
                    type="date"
                    value={selectedDate}
                    onChange={handleDateChange}
                />




                <NewExport
                    excelData={flattenedData.map(row => {
                        const filteredRow = {};
                        Object.keys(row).forEach(key => {
                            if (visibleColumns[key] || overViewTableInclusionList.includes(key)) {
                                // Replace headers for specific keys
                                if (key === 'groupName') {
                                    filteredRow['outlet'] = row[key];
                                } else if (key === 'inventoryLevel') {
                                    filteredRow['inventory score'] = row[key];
                                } else if (key === 'mslLevel') {
                                    filteredRow['msl score'] = row[key];
                                } else if (key === 'imageLocation') {
                                    filteredRow['image location'] = calculateDeviation(row.location, row?.imageLocation).status
                                } else if (key === 'commentLocation') {
                                    filteredRow['comment location'] = calculateDeviation(row.location, row?.commentLocation).status
                                } else if (key === 'conditionLocation') {
                                    filteredRow['condition location'] = calculateDeviation(row.location, row?.conditionLocation).status
                                } else if (key === 'mslLevelLocation') {
                                    filteredRow['msl score location'] = calculateDeviation(row.location, row?.mslLevelLocation).status
                                } else if (key === 'inventoryLevelLocation') {
                                    filteredRow['inventory level location'] = calculateDeviation(row.location, row?.inventoryLevelLocation).status
                                }

                                else {
                                    filteredRow[key] = row[key];
                                }
                            }
                        });
                        return filteredRow;
                    })}
                    title={'Assets Overview'}
                />

            </div>

            {/* <InitiativeQueryComponent
                searchValues={searchValues}
                setSearchValues={setSearchValues}
                placeholder={'search asset'}
                customSelect={
                    <div className={classes.customerSelectContainer}>
                        <CustomSelect type={'brand group'} groupList={brandGroups} searchValues={searchValues} setSearchValues={setSearchValues} />
                        <CustomSelect type={'store group'} groupList={storeGroups} searchValues={searchValues} setSearchValues={setSearchValues} />
                    </div>
                }
            /> */}

            <p className={classes.showing}>
                Total User Entries Found: {flattenedData.length}
            </p>

            <div className={classes.columnToggle}>
                <div className={classes.toggleBtns}>
                    {/* <p>Toggle Columns:</p> */}
                    <p
                        onClick={() => {
                            setVisibleColumns(defaultAssetOverviewInvisibleColumns);
                        }}
                        className={classes.uncheckAllBtn}
                    >
                        Un-Check All
                    </p>
                </div>

                <div className={classes.allCheckBoxGrouped}>
                    {Object.keys(visibleColumns).map((column) => (
                        <div
                            className={classes.toggleCheckBoxContainer}
                            key={column}
                        >
                            <input
                                type="checkbox"
                                checked={visibleColumns[column]}
                                onChange={() => handleColumnToggle(column)}
                                className={classes.toggleCheckBox}
                            />
                            <p>{column === 'inventoryLevel' ? 'inventory score' : column === 'mslLevel' ? 'MSL Score' : column}</p>
                        </div>
                    ))}
                </div>
            </div>

            {loading ? (
                <div className={classes.loaderCon}>
                    <Loader />
                </div>
            ) : (

                flattenedData.length ?
                    <table
                        className={classes.table}

                    >
                        <thead>
                            <tr>
                                <th>Outlet</th>
                                <th>Asset</th>
                                <th>User</th>
                                {visibleColumns?.image && <th>Image</th>}
                                {visibleColumns?.imageLocation && <th>Image Location</th>}
                                {visibleColumns?.comment && <th>Comment</th>}
                                {visibleColumns?.commentLocation && <th>Comment Location</th>}
                                {visibleColumns?.condition && <th>Condition</th>}
                                {visibleColumns?.conditionLocation && <th>Condition Location</th>}
                                {visibleColumns?.mslLevel && <th>MSL Score</th>}
                                {visibleColumns?.mslLevelLocation && <th>MSL Score Location</th>}
                                {visibleColumns?.inventoryLevel && <th>Inventory Score</th>}
                                {visibleColumns?.inventoryLevelLocation && <th>Inventory Level Location</th>}
                            </tr>
                        </thead>

                        <tbody>
                            {flattenedData.map((row, index) => (
                                <tr
                                    onClick={() =>
                                        draw({
                                            title: "Assets Comment",
                                            content: (
                                                <div className={classes.modalContainer}>

                                                    <img onClick={row.image ? () => window.open(row.image) : null} src={row.image || defaultImage} alt='' className={classes.image} />

                                                    <div
                                                        className={classes.modalTitleAndDateContainer}
                                                    >
                                                        <p className={classes.modalTitle}>
                                                            {row?.asset}
                                                        </p>
                                                        <p>
                                                            {formatDateHeader(
                                                                moment(row.updatedAt).format('YYYY-MM-DD')
                                                            )}
                                                        </p>
                                                    </div>

                                                    <div
                                                        className={classes.modalStoreAddressContainer}
                                                    >
                                                        <p className={classes.modalStoreAddress}>
                                                            {row?.groupName}
                                                        </p>
                                                        <MdStorefront className={classes.modalIcon} />
                                                    </div>

                                                    <div
                                                        className={classes.modalStoreAddressContainer}
                                                    >
                                                        <div style={{ width: "90%" }}>
                                                            <p className={classes.modalStoreAddress}>
                                                                {row?.user}
                                                            </p>

                                                        </div>

                                                        <FaRegUser className={classes.modalIcon} />
                                                    </div>

                                                    <div className={classes.modalStoreAddressContainer}>
                                                        <div style={{ width: "90%" }}>
                                                            <p className={classes.modalStoreAddress}>
                                                                {row.mslLevel != null && !isNaN(Number(row.mslLevel))
                                                                    ? numberFormat.format(Number(row.mslLevel))
                                                                    : 'N/A'}
                                                            </p>
                                                            <p className={classes.messageDescription}>
                                                                MSL Score
                                                            </p>
                                                        </div>

                                                        <GiLevelTwoAdvanced className={classes.modalIcon} />
                                                    </div>

                                                    <div className={classes.modalStoreAddressContainer}>
                                                        <div style={{ width: "90%" }}>
                                                            <p className={classes.modalStoreAddress}>
                                                                {row.inventoryLevel != null && !isNaN(Number(row.inventoryLevel))
                                                                    ? numberFormat.format(Number(row.inventoryLevel))
                                                                    : 'N/A'}
                                                            </p>
                                                            <p className={classes.messageDescription}>
                                                                Inventory Score
                                                            </p>
                                                        </div>
                                                    </div>


                                                    <div className={classes.modalStoreAddressContainer}>
                                                        <p className={classes.messageDescription}>{row?.comment || 'No comment found'}</p>
                                                    </div>

                                                    <div className={classes.modalStoreAddressContainer}>
                                                        <p className={classes.messageDescription}>{row?.condition || 'No condition found'}</p>
                                                    </div>
                                                </div>
                                            ),
                                        })
                                    }

                                    key={index}
                                >
                                    <td className={classes.tooltip_container}>
                                        <p className={classes.item_text}>{row.groupName}</p>
                                        <p className={classes.tooltip}>{row.groupName}</p>
                                    </td>
                                    <td className={classes.tooltip_container}>
                                        <p className={classes.item_text}>{row.asset}</p>
                                        <p className={classes.tooltip}>{row.asset}</p>
                                    </td>
                                    <td className={classes.tooltip_container}>
                                        <p className={classes.item_text}>{row.user}</p>
                                        <p className={classes.tooltip}>{row.user}</p>
                                    </td>
                                    {visibleColumns?.image &&
                                        <td style={{ cursor: "pointer" }}>
                                            {row.image !== "N/A" ? (
                                                <a
                                                    href={row.image}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ textDecoration: "none", color: "blue" }}
                                                >
                                                    View Image
                                                </a>
                                            ) : (
                                                "N/A"
                                            )}
                                        </td>}


                                    {visibleColumns?.imageLocation && <td className={classes.tooltip_container}>
                                        <p
                                            className={classes.item_text}
                                            style={{ color: calculateDeviation(row.location, row?.imageLocation).color }}
                                        >
                                            {calculateDeviation(row.location, row?.imageLocation).status}
                                        </p>

                                        <p className={classes.tooltip}>
                                            {calculateDeviation(row.location, row?.imageLocation).status}
                                        </p>
                                    </td>}

                                    {visibleColumns?.comment && <td className={classes.tooltip_container}>
                                        <p className={classes.item_text}>{row.comment}</p>
                                        <p className={classes.tooltip}>{row.comment}</p>
                                    </td>}


                                    {visibleColumns?.commentLocation && <td className={classes.tooltip_container}>
                                        <p
                                            className={classes.item_text}
                                            style={{ color: calculateDeviation(row.location, row?.commentLocation).color }}
                                        >
                                            {calculateDeviation(row.location, row?.commentLocation).status}
                                        </p>

                                        <p className={classes.tooltip}>
                                            {calculateDeviation(row.location, row?.commentLocation).status}
                                        </p>
                                    </td>}

                                    {visibleColumns?.condition && <td className={classes.tooltip_container}>
                                        <p className={classes.item_text}>{row.condition}</p>
                                        <p className={classes.tooltip}>{row.condition}</p>
                                    </td>}


                                    {visibleColumns?.conditionLocation && <td className={classes.tooltip_container}>
                                        <p
                                            className={classes.item_text}
                                            style={{ color: calculateDeviation(row.location, row?.conditionLocation).color }}
                                        >
                                            {calculateDeviation(row.location, row?.conditionLocation).status}
                                        </p>

                                        <p className={classes.tooltip}>
                                            {calculateDeviation(row.location, row?.conditionLocation).status}
                                        </p>
                                    </td>}

                                    {visibleColumns?.mslLevel && <td className={classes.tooltip_container}>
                                        <p className={classes.item_text}>{isNaN(row.mslLevel) ? 'N/A' : numberFormat.format(row.mslLevel)}</p>
                                        <p className={classes.tooltip}>{isNaN(row.mslLevel) ? 'N/A' : numberFormat.format(row.mslLevel)}</p>
                                    </td>}

                                    {visibleColumns?.mslLevelLocation && <td className={classes.tooltip_container}>
                                        <p
                                            className={classes.item_text}
                                            style={{ color: calculateDeviation(row.location, row?.mslLevelLocation).color }}
                                        >
                                            {calculateDeviation(row.location, row?.mslLevelLocation).status}
                                        </p>

                                        <p className={classes.tooltip}>
                                            {calculateDeviation(row.location, row?.mslLevelLocation).status}
                                        </p>
                                    </td>}

                                    {visibleColumns?.inventoryLevel && <td className={classes.tooltip_container}>
                                        <p className={classes.item_text}>{isNaN(row.inventoryLevel) ? 'N/A' : numberFormat.format(row.inventoryLevel)}</p>
                                        <p className={classes.tooltip}>{isNaN(row.inventoryLevel) ? 'N/A' : numberFormat.format(row.inventoryLevel)}</p>
                                    </td>}

                                    {visibleColumns?.inventoryLevelLocation && <td className={classes.tooltip_container}>
                                        <p
                                            className={classes.item_text}
                                            style={{ color: calculateDeviation(row.location, row?.inventoryLevelLocation).color }}
                                        >
                                            {calculateDeviation(row.location, row?.inventoryLevelLocation).status}
                                        </p>

                                        <p className={classes.tooltip}>
                                            {calculateDeviation(row.location, row?.inventoryLevelLocation).status}
                                        </p>
                                    </td>}

                                </tr>
                            ))}
                        </tbody>
                    </table>
                    :
                    <InitiativeEmptyState searchValues={{ date: selectedDate }} name={"overview"} />
            )}
        </div>
    );
}
