import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import classes from './initiativePaginationComponent.module.css'

export function InitiativePaginationComponent({ page, totalCount, setSearchValues, setInputPage, inputPage, limit=100 }) {

    const skip = (page - 1) * (limit );
    const hasNextPage = skip + limit < totalCount;
    const hasPreviousPage = skip > 0;
    const totalPages = Math.ceil(totalCount / limit);



    const handleManualInputChange = (e) => {
        e?.preventDefault()
        setSearchValues((prev) => ({ ...prev, page: parseInt(inputPage) }))
    }

    return (
        <>
        <div className={classes.page_icons_grouped}>

            <div
                onClick={() => { setSearchValues((prev) => ({ ...prev, page: parseInt(page) - 1 })); setInputPage(parseInt(page) - 1) }}
                className={hasPreviousPage ? classes.angle_icon_container : classes.angle_icon_container_inactive}
            >
                <FaAngleLeft className={classes.angle_icon} />
            </div>

            <form onSubmit={handleManualInputChange} className={classes.pageContainer}>Page: <input onChange={(e) => { e.target.value > totalPages ? setInputPage(totalPages) : setInputPage(e.target.value) }} type='number' className={classes.paginationInput} value={inputPage} /><span>of {totalPages}</span></form>

            <div
                onClick={() => { setSearchValues((prev) => ({ ...prev, page: parseInt(page) + 1 })); setInputPage(parseInt(page) + 1) }}
                className={hasNextPage ? classes.angle_icon_container : classes.angle_icon_container_inactive}
            >
                <FaAngleRight className={classes.angle_icon} />
            </div>
        </div>
        <br />
        <br />
        <br />
        </>
    )
}