import React, { useState } from 'react'
import Mechanics from './mechanics'
import ProductGroups from './productGroups/productGroups'
import { InitiativeHeaderTab } from 'components/initiativeHeaderTab/initiativeHeaderTab'
import classes from './orders.module.css'

const Overview = ({initiative, setRefetch}) => {
  const assignor = {
    "Overview":<Mechanics data={initiative} merged={true} setRefetch={setRefetch} />,
    "Product Groups": <ProductGroups initiative={initiative} setRefetch={setRefetch} />
}

const [selected, setSelected] = useState(Object.keys(assignor)[0])
  
  return (
    <div className={classes.container}>
    <InitiativeHeaderTab list={Object.keys(assignor)} selected={selected} setSelected={setSelected} />
    <div>
        {assignor[selected]}
    </div>
</div>
  )
}

export default Overview