import PeriodAggAttendance from './periodAggAttendance'
import ListCompare from '../../../../../components/listCompare/listCompare'
import TotalPeriodAggAttendance from './totalPeriodAggAttendance'
import { useState } from 'react'
import { InitiativeHeaderTab } from '../../../../../components/initiativeHeaderTab/initiativeHeaderTab'
import classes from './attendance.module.css'
import AggAttendanceWeb from './aggAttendanceWeb'
import AllDaily from './allDaily'

const AllAttendance = ({ initiative }) => {

    const assignor = {
        "Daily": <AllDaily initiative={initiative} />,
        "Daily Aggregates": <PeriodAggAttendance iData={initiative} />,
        "Total Periodic Aggregates": <TotalPeriodAggAttendance iData={initiative} />,
        "Daily Web": <AggAttendanceWeb data={initiative} />,
        "Not Submitted": <ListCompare listType={"attendance"} />
    }

    const [selected, setSelected] = useState(Object.keys(assignor)[0])


    return (
        <div className={classes.container}>
            <InitiativeHeaderTab list={Object.keys(assignor)} selected={selected} setSelected={setSelected} />
            <div>
                {assignor[selected]}
            </div>
        </div>
    )
}

export default AllAttendance;