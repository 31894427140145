import {
  Box,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import CustomDrawer from "../../../../components/customDrawer/customDrawer";
import axiosInstance from "../../../../services/api";
import EmptyState from "../../../../components/emptyState/emptyState";
import PrimaryButton from "../../../../components/primaryButton/primarybutton";
import LineSkeleton from "../../../../components/lineSkeleton";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { buildResourceURL } from "../../../../utils/helper";
import { useSelector } from "react-redux";
import CustomPagination from "../../../../components/customPagination/customPagination";
import ServerExport from "../../../../components/serverExport/serverExport";
import SecondaryButton from "../../../../components/secondaryButton/secondarybutton";
import CreateSurvey from "../createSurvey/createSurvey";
import ManageQuestions from "../manageQuestions/manageQuestions";
import EditSurvey from "../editSurvey/editSurvey";
import { useNavigate } from "react-router-dom";
import { Loader } from "components/Loader/loader";
import { formatDateHeader } from "helpers/formatDateHeader";
import classes from "../../initiatives/initiativeDetails/initiativeStores/initiativeStoreDetails/components/orders/orders.module.css";
import { numberFormat } from "helpers/numberFormatter";
import { InitiativeEmptyState } from "components/initiativeEmptyState/initiativeEmptyState";
import Assignees from "../assignees/assignees";

const SurveysTable = ({ clientId, initiativeId }) => {
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const [triggerClose, setTriggerClose] = useState("");
  const [modalType, setModalType] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [refetch, setRefetch] = useState("");
  const [team, setTeam] = useState("");
  const [total, setTotal] = useState("");

  const currentUser = useSelector(({ userData }) => userData.currentUser);
  const subid = currentUser?.role?.includes("Subuser")
    ? currentUser?.mainUser
    : "";

  const [searchVal, setSearchVal] = useState("");
  // const [clientId, setClientId] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [clients, setClients] = useState([]);
  const [loadingi, setLoadingi] = useState(false);
  const [loadingc, setLoadingc] = useState(false);

  const navigate = useNavigate();

  const getClients = async () => {
    setLoadingi(true);
    try {
      const { data } = await axiosInstance.get("clients");
      setClients(data?.data);
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoadingi(false);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  const [page, setPage] = useState(1);
  const limit = 10;

  const queryParams = [
    {
      key: "initiativeStore",
      value: "",
    },
    {
      key: "page",
      value: page,
    },
    {
      key: "client",
      value:
        currentUser?.role === "Client"
          ? currentUser?._id
          : currentUser?.role?.includes("Subuser")
          ? subid
          : clientId,
    },
    {
      key: "limit",
      value: limit,
    },
    {
      key: "team",
      value: team,
    },
    {
      key: "initiative",
      value: initiativeId,
    },
  ];
  const queryParamsx = [
    {
      key: "initiative",
      value: initiativeId,
    },
    {
      key: "page",
      value: page,
    },
    {
      key: "client",
      value:
        currentUser?.role === "Client"
          ? currentUser?._id
          : currentUser?.role?.includes("Subuser")
          ? subid
          : clientId,
    },
  ];

  const getSurveys = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        buildResourceURL("surveys", queryParams)
      );
      setSurveys(data?.data);
      setTotal(data?.totalCount);
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSurveys();
  }, [triggerClose, refetch, page, team, clientId]);

  const handleModal = (type, user) => {
    setModalType(type);
    setSelectedUser(user);
    onOpen();
  };

  const deleteSurvey = async () => {
    setLoadingx(true);
    try {
      const { data } = await axiosInstance.delete(
        `surveys/${selectedUser?.id}`
      );
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      setRefetch(Math.random());
      onClose();
    } catch (error) {
      if (error?.response?.data?.message) {
        toast({
          title: "An Error Occurred",
          description: error?.response?.data?.message,
          status: "error",
          position: "top",
        });
      }
    } finally {
      setLoadingx(false);
    }
  };

  const teamOptions = [{ title: "All Teams", value: "" }];

  for (let i = 0; i < 100; i++) {
    const newObj = {
      title: `Team ${i + 1}`,
      value: i + 1,
    };
    teamOptions.push(newObj);
  }

  const handleToggle = async (id) => {
    setLoadingc(true);
    try {
      const { data } = await axiosInstance.patch(`surveys/${id}/active-status`);
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      // setRefetch(Math.random())
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingc(false);
    }
  };

  return (
    <>
      <Flex alignItems={"center"} gap=".8rem" justifyContent={"space-between"}>
        <Flex gap="1.5rem" alignItems={"center"}>
          <Text
            whiteSpace={"nowrap"}
            fontWeight={"500"}
            fontSize={"1.15rem"}
            color={"#27272A"}
          >
            Surveys{" "}
            <span
              style={{
                padding: ".1rem .3rem",
                borderRadius: "50%",
                backgroundColor: "#F9FAFB",
                border: "1px solid #EAECF0",
                fontSize: "12px",
                fontWeight: "500",
                color: "#344054",
              }}
            >
              {total}
            </span>
          </Text>

          {/* <CustomInput
                type={"text"}
                required
                value={searchVal}
                placeholder={"Search outlets.."}
                onChange={e => setSearchVal(e.target.value)}
                mt="0"
                sType="search"
                py="1.19rem"
                width="250px"
                /> */}
          {/* <CustomSelect
                options={teamOptions}
                onChange={e => setTeam(e.target.value)}
                height="40px !important"
                mt="0"
                borderRadius={"12px"}
                width="120px"
                cursor="pointer"
                />

                {currentUser?.role?.includes("Admin") &&
                <CustomSelect
                options={clientOptions}
                placeholder={"All Clients"}
                onChange={e => setClientId(e.target.value)}
                height="40px !important"
                mt="0"
                borderRadius={"12px"}
                width="120px"
                cursor="pointer"
                />} */}
        </Flex>

        <Flex gap="1.5rem">
          {/* <ServerExport entity={"surveys"} queries={buildResourceURL('', queryParamsx)} /> */}
          <CustomDrawer
            position={"right"}
            title={"Create Survey"}
            triggerClose={triggerClose}
            setTriggerClose={setTriggerClose}
            toggleElement={
              <SecondaryButton
                type="submit"
                text={"Create Survey"}
                icon="add"
                loading={false}
              />
            }
            content={
              <CreateSurvey
                clientIdx={clientId}
                setTriggerClose={setTriggerClose}
                initiativeId={initiativeId}
              />
            }
          />
        </Flex>
      </Flex>

   
      {loading ? (
        <div className={classes.loader_con}>
          <Loader />
        </div>
      ) : (
        <>
          {surveys.length ? (
            <div className={classes.table_container}>
              <div className={classes.titleAndInstructionContainer}>
                <p className={classes.title}>Total Surveys Found: {total}</p>
                <p className={classes.instruction}>
                  Click on each row to see more details
                </p>
              </div>

              <p className={classes.showing}>
                Showing:{" "}
                <span>
                  {surveys.length || 1} of {total}
                </span>
              </p>
              <div className={classes.scrollable}>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Client</th>
                      <th>Store</th>
                      <th>Initiative</th>
                      <th>Status</th>
                      <th>Created</th>
                    </tr>
                  </thead>
                  <tbody>
                    {surveys.map((item, index) => {
                      const { question, ...data } = item;
                      return (
                        <tr key={index}>
                          <td className={classes.tooltip_container}>
                            <p className={classes.item_text}>{item.name}</p>
                            <p className={classes.tooltip}>{item.name}</p>
                          </td>
                          <td className={classes.tooltip_container}>
                            <p className={classes.item_text}>
                              {item.description}
                            </p>
                            <p className={classes.tooltip}>
                              {item.description}
                            </p>
                          </td>
                          <td className={classes.tooltip_container}>
                            <p className={classes.item_text}>
                              {item.client?.companyName}
                            </p>
                            <p className={classes.tooltip}>
                              {item.client?.companyName}
                            </p>
                          </td>
                          <td className={classes.tooltip_container}>
                            <p className={classes.item_text}>
                              {item?.initiative === null
                                ? "--"
                                : item?.initiative}
                            </p>
                            <p className={classes.tooltip}>
                              {item?.initiative === null
                                ? "--"
                                : item?.initiative}
                            </p>
                          </td>
                          <td className={classes.tooltip_container}>
                            <p className={classes.item_text}>
                              <Switch
                                onChange={() => handleToggle(item?._id)}
                                disabled={currentUser?.role === "Client"}
                                defaultChecked={item?.isActive}
                              />
                            </p>
                          </td>

                          <td className={classes.tooltip_container}>
                            <p className={classes.item_text}>
                              {item?.createdAt?.slice(0, 10)}
                            </p>
                            <p className={classes.tooltip}>
                              {item?.createdAt?.slice(0, 10)}
                            </p>
                          </td>

                          <td>
                            <Menu width={"200px !important"}>
                              <MenuButton>
                                <BiDotsVerticalRounded
                                  style={{ cursor: "pointer" }}
                                />
                              </MenuButton>
                              <MenuList>
                                <Divider my=".2rem" />
                                <MenuItem
                                  onClick={() =>
                                    navigate(`/surveys/${item?._id}`)
                                  }
                                  fontWeight={"500"}
                                  fontSize={".85rem"}
                                >
                                  View Survey
                                </MenuItem>
                                {currentUser?.role === "Super Admin" && (
                                  <>
                                <Divider my=".2rem" />
                                <CustomDrawer
                                  position={"right"}
                                  title={"Update Survey"}
                                  triggerClose={triggerClose}
                                  toggleElement={
                                    <MenuItem
                                      fontWeight={"500"}
                                      fontSize={".85rem"}
                                    >
                                      Edit Survey
                                    </MenuItem>
                                  }
                                  content={
                                    <EditSurvey
                                      data={data}
                                      setTriggerClose={setTriggerClose}
                                    />
                                  }
                                />
                                <Divider my=".2rem" />
                                <CustomDrawer
                                  position={"right"}
                                  title={"Manage survey questions"}
                                  triggerClose={triggerClose}
                                  toggleElement={
                                    <MenuItem
                                      fontWeight={"500"}
                                      fontSize={".85rem"}
                                    >
                                      Manage Questions
                                    </MenuItem>
                                  }
                                  content={
                                    <ManageQuestions
                                      questions={item?.questions}
                                      id={item?._id}
                                    />
                                  }
                                />
                                
                                
                                    <Divider my=".2rem" />
                                    <MenuItem
                                      onClick={() =>
                                        handleModal("delete", {
                                          name: item?.name,
                                          id: item?._id,
                                        })
                                      }
                                      color={"crimson"}
                                      fontWeight={"500"}
                                      fontSize={".85rem"}
                                    >
                                      Delete Survey
                                    </MenuItem>
                                  </>
                                )}
                                <Divider my=".2rem" />
                                <CustomDrawer
                                  position={"right"}
                                  title={"Survey Assignees"}
                                  triggerClose={triggerClose}
                                  toggleElement={
                                    <MenuItem
                                      fontWeight={"500"}
                                      fontSize={".85rem"}
                                    >
                                      View Assignees
                                    </MenuItem>
                                  }
                                  content={
                                   <Assignees surveyId={item?._id} />
                                  }
                                />
                              </MenuList>
                            </Menu>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <CustomPagination
                page={page}
                setPage={setPage}
                pageSize={limit}
                totalElements={total}
              />
            </div>
          ) : (
            <InitiativeEmptyState searchValues={null} name={"surveys"} />
          )}
        </>
      )}


        <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{'Delete Survey'}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {modalType === 'delete' &&
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm deletion of {selectedUser?.name}?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                            <PrimaryButton onClick={deleteSurvey} text="Delete" width="150px" bg="crimson !important" padding="1rem !important" loading={loadingx} />
                        </Flex>
                    </>
                    }
                    <Text pb="1rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Cancel</Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    </>
  );
};

export default SurveysTable;
