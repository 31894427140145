import {
  Box,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import LineSkeleton from "../../../../../components/lineSkeleton";
import EmptyState from "../../../../../components/emptyState/emptyState";
import moment from "moment";
import axiosInstance from "../../../../../services/api";
import { BiDotsVerticalRounded, BiImageAlt } from "react-icons/bi";
import PrimaryButton from "../../../../../components/primaryButton/primarybutton";
import { useParams } from "react-router-dom";
import Overview from "../../../../../components/overView/overView";
import {
  buildResourceURL,
  buildResourceURL2,
  truncateText,
} from "../../../../../utils/helper";
import ServerExport from "../../../../../components/serverExport/serverExport";
import CustomPagination from "../../../../../components/customPagination/customPagination";
import CustomDrawer from "../../../../../components/customDrawer/customDrawer";
import SecondaryButton from "../../../../../components/secondaryButton/secondarybutton";
import CreatePrize from "./createPrize";
import Prizes from "../mechanics/prizes/prizes";
import { Loader } from "components/Loader/loader";
import classes from "./reward.module.css";
import { InitiativeEmptyState } from "components/initiativeEmptyState/initiativeEmptyState";
import CustomInput from "components/customInput/customInput";

const Rewards = ({ data }) => {
  const [winners, setWinners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingx, setLoadingx] = useState(false);
  const [total, setTotal] = useState("");
  const [idx, setIdx] = useState(null);
  const [refetch, setRefetch] = useState();
  const { iid, sid } = useParams();
  const [searchVal, setSearchVal] = useState("");

  const [triggerClose, setTriggerClose] = useState("");

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const [prizeAnalytics, setPrizesAnalytics] = useState([]);
  const [modalType, setModalType] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [page, setPage] = useState(1);
  const limit = 20;

  const finalData = sid ? data : data?.initiative;

  const queryParams = [
    {
      key: "initiativeStore",
      value: sid,
    },
    {
      key: "initiative",
      value: sid ? "" : iid,
    },
    {
      key: "page",
      value: page,
    },
    {
      key: "limit",
      value: limit,
    },
    {
      key: "createdAt[gte]",
      value: startDate,
    },
    {
      key: "createdAt[lte]",
      value: endDate,
    },
    {
      key: "search",
      value: searchVal,
    },
  ];
  const queryParamsx = [
    {
      key: "initiativeStore",
      value: sid,
    },
    {
      key: "initiative",
      value: iid,
    },
    {
      key: "createdAt[gte]",
      value: startDate,
    },
    {
      key: "createdAt[lte]",
      value: endDate,
    },
    {
      key: "search",
      value: searchVal,
    },
  ];

  const getGames = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        buildResourceURL("/game-winners", queryParams)
      );
      setWinners(data?.data);
      setTotal(data?.totalCount);
    } catch (error) {
      console.errror(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGames();
  }, [refetch, page, startDate, endDate, searchVal]);

  const queryParamsy = [
    {
      key: "initiativeStoreId",
      value: sid,
    },
    {
      key: "initiativeId",
      value: iid,
    },
    {
      key: "startDate",
      value: startDate,
    },
    {
      key: "endDate",
      value: endDate,
    },
    {
      key: "searchQuery",
      value: searchVal,
    },
  ];

  const getPrizesAnalytics = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(buildResourceURL('/game-winners/prize-won-aggregates', queryParamsy));
      setPrizesAnalytics(data?.data);
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPrizesAnalytics();
  }, [startDate, endDate, searchVal]);

  const handleModal = (type, user) => {
    setModalType(type);
    setIdx(user);
    onOpen();
  };

  const setPrizeClaim = async () => {
    setLoadingx(true);
    try {
      const { data } = await axiosInstance.patch(
        `/game-winners/${idx?.id}/toggle-prize-claim-status`
      );
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      setRefetch(Math.random());
      onClose();
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingx(false);
    }
  };

  const deleteRecord = async () => {
    setLoadingx(true);
    try {
      const { data } = await axiosInstance.delete(`/game-winners/${idx?.id}`);
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      setRefetch(Math.random());
      onClose();
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingx(false);
    }
  };

  const datax = [
    {
      title: "Sales achieved",
      value: "" || 0,
    },
    {
      title: "Sales target",
      value: "" || 0,
    },
    {
      title: "Target met",
      value: "" || 0,
    },
    {
      title: "Target met",
      value: "" || 0,
    },
    {
      title: "Target met",
      value: "" || 0,
    },
    {
      title: "Time gone",
      value: "" || 0,
    },
  ];

  const dataxy = sid ? prizeAnalytics?.map((i) => ({
    title: `${i?.prize} - ${
      data?.gamePrizes?.find(
        (k) => k.name?.toLowerCase() === i?.prize?.toLowerCase()
      )?.quantityAvailable +
      i?.count -
      i?.count
    } left`,
    value: `${i?.count}/${
      data?.gamePrizes?.find(
        (k) => k.name?.toLowerCase() === i?.prize?.toLowerCase()
      )?.quantityAvailable + i?.count
    }`,
  })) :
  prizeAnalytics?.map((i) => ({
    title: i?.prize,
    value: i?.count
  }))


  return (
    <div className={classes.screenContainer}>
      <Flex
        alignItems={"center"}
        gap=".8rem"
        mb=".5rem"
        justifyContent={"space-between"}
      >
        <Text
          whiteSpace={"nowrap"}
          fontWeight={"500"}
          fontSize={"1.15rem"}
          color={"#27272A"}
        >
          Draw Winners{" "}
          <span
            style={{
              padding: ".1rem .3rem",
              borderRadius: "50%",
              backgroundColor: "#F9FAFB",
              border: "1px solid #EAECF0",
              fontSize: "12px",
              fontWeight: "500",
              color: "#344054",
            }}
          >
            {total}
          </span>
        </Text>
        <CustomInput
                type={"text"}
                required
                value={searchVal}
                placeholder={'Search..'}
                onChange={e => setSearchVal(e.target.value)}
                mt="0"
                sType="search"
                py="1.19rem"
                width="350px"
                />
        <Flex gap="1rem" alignItems={"center"}>
          {/* <CustomDrawer
            position={"right"}
            title={"Add Game Prize"}
            triggerClose={triggerClose}
            setTriggerClose={setTriggerClose}
            toggleElement={
              <SecondaryButton
                type="submit"
                text={"Add Prize"}
                icon="add"
                loading={false}
              />
            }
            content={
              <Prizes
                datum={finalData}
                setTriggerClose={setTriggerClose}
                triggerClose={triggerClose}
                setRefetch={setRefetch}
              />
            }
          /> */}
          <Flex alignItems={'center'} gap=".5rem">
                      <CustomInput
                      width="150px"
                      type={"date"}
                      mt="0 !important"
                      py=".5rem !important"
                      required
                      placeholder={"Choose Date"}
                      onChange={e => setStartDate(e.target.value)}
                      />
                      -
                      <CustomInput
                      width="150px"
                      type={"date"}
                      mt="0 !important"
                      py=".5rem !important"
                      required
                      placeholder={"Choose Date"}
                      onChange={e => setEndDate(e.target.value)}
                      />
                    </Flex>
          <ServerExport
            entity={"game-winners"}
            queries={buildResourceURL2("", queryParamsx)}
          />
        </Flex>
      </Flex>
      <Overview data={dataxy} loading={loading} />

      <Modal
        isCentered
        motionPreset="slideInBottom"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>
            {modalType === "delete"
              ? "Delete Record"
              : idx?.status
              ? "Set Prize Unclaimed"
              : "Set Prize Claimed"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {modalType === "delete" ? (
              <>
                <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>
                  Confirm deletion ?
                </Text>
                <Flex justifyContent={"center"} my="1.3rem">
                  <PrimaryButton
                    onClick={deleteRecord}
                    text="Delete"
                    width="150px"
                    bg="crimson !important"
                    padding="1rem !important"
                    loading={loadingx}
                  />
                </Flex>
              </>
            ) : (
              <>
                <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>
                  Confirm status of {idx?.name}?
                </Text>
                <Flex justifyContent={"center"} my="1.3rem">
                  <PrimaryButton
                    onClick={() => setPrizeClaim(!idx?.status)}
                    text="Confirm"
                    width="150px"
                    padding="1rem !important"
                    loading={loadingx}
                  />
                </Flex>
              </>
            )}
            <Text
              pb="1rem"
              textAlign={"center"}
              fontSize={".9rem"}
              fontWeight={"500"}
              cursor={"pointer"}
              onClick={onClose}
            >
              Cancel
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>

      <CustomPagination
        page={page}
        setPage={setPage}
        pageSize={limit}
        totalElements={total}
      />

      {loading ? (
        <div className={classes.loader_con}>
          <Loader />
        </div>
      ) : (
        <>
          {winners.length ? (
            <div className={classes.table_container}>
              <div className={classes.titleAndInstructionContainer}/>

            
              <div className={classes.scrollable}>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Outlet</th>
                      <th>Winner</th>
                      <th>Prize Won</th>
                      <th>Promoter</th>
                      <th>Date Won</th>
                      <th>Comment</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {winners.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className={classes.tooltip_container}>
                            <p className={classes.item_text}>{item.gameTitle || item.gameName}</p>
                            <p className={classes.tooltip}>{item.gameTitle || item.gameName}</p>
                          </td>
                          <td className={classes.tooltip_container}>
                            <p className={classes.item_text}>
                              {item.initiativeStore?.store?.name}, {item.initiativeStore?.store?.state}
                            </p>
                            <p className={classes.tooltip}>
                              {item.initiativeStore?.store?.name}, {item.initiativeStore?.store?.state}
                            </p>
                          </td>
                         
                          <td className={classes.tooltip_container}>
                            <a
                              href={item?.winnerDetails?.imageURL}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <BiImageAlt />
                            </a>
                            <p className={classes.item_text}>{item?.winnerDetails?.name}</p>
                            <p className={classes.item_text}>{item?.winnerDetails?.phoneNumber}</p>
                          </td>

                          <td className={classes.tooltip_container}>
                            <p className={classes.item_text}>{item.prizeWon}</p>
                            <p className={classes.tooltip}>{item.prizeWon}</p>
                          </td>

                          <td className={classes.tooltip_container}>
                            <p className={classes.item_text}>{item?.user?.firstName} {item?.user?.lastName}</p>
                            <p className={classes.tooltip}>{item?.user?.firstName} {item?.user?.lastName}</p>
                          </td>

                          <td className={classes.tooltip_container}>
                            <p className={classes.item_text}>{item.createdAt?.slice(0, 10)}</p>
                            <p className={classes.tooltip}>{item.createdAt?.slice(0, 10)}</p>
                          </td>


                          <td className={classes.tooltip_container}>
                            <p className={classes.item_text}>{item?.isPrizeClaimed ? "Claimed" : "Unclaimed"}</p>
                          </td>

                          <td className={classes.tooltip_container}>
                          <Menu width={"200px !important"}>
                        <MenuButton>
                          <BiDotsVerticalRounded
                            style={{ cursor: "pointer" }}
                          />
                        </MenuButton>
                        <MenuList>
                          <Divider my=".2rem" />
                          <MenuItem
                            onClick={() =>
                              handleModal("claim", {
                                id: item?.id,
                                name: item?.prizeWon,
                                status: item?.sPrizeClaimed,
                              })
                            }
                            fontWeight={"500"}
                            fontSize={".85rem"}
                          >
                            {item?.isPrizeClaimed
                              ? "Set Prize Unclaimed"
                              : "Set Prize Claimed"}
                          </MenuItem>
                          <Divider my=".2rem" />
                          <MenuItem
                            onClick={() =>
                              handleModal("delete", {
                                id:item?._id,
                                name: item?.prizeWon,
                                status: item?.isPrizeClaimed,
                              })
                            }
                            fontWeight={"500"}
                            fontSize={".85rem"}
                            color="crimson"
                          >
                            Delete
                          </MenuItem>
                          <Divider my=".2rem" />
                        </MenuList>
                      </Menu>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <CustomPagination
                page={page}
                setPage={setPage}
                pageSize={limit}
                totalElements={total}
              />
            </div>
          ) : (
            <InitiativeEmptyState searchValues={null} name={"rewards"} />
          )}
        </>
      )}
    </div>
  );
};

export default Rewards;
