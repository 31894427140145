import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../services/api';
import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import PrimaryButton from '../../primaryButton/primarybutton';
import CustomDrawer from '../../customDrawer/customDrawer';
import AssignTeam from '../assignTeam/assignTeam';

const ViewTeam = ({id, setTriggerClose, triggerClose}) => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false)
  const [loadingx, setLoadingx] = useState(false)
  const [selected, setSelected] = useState([])
  const [refetch, setRefetch] = useState('')

  const toast = useToast()

  const getTeamMembers = async() => {
    setLoading(true)
    try {
        const res = await axiosInstance.get(`employees/${id}/team-members`)
        setMembers(res?.data?.data)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
  }

  useEffect(() => {
    getTeamMembers()
  }, [refetch])

  const assignTeam = async() => {
    setLoadingx(true)
    const datax = {
        userIDs: selected?.map(i => i?._id)
    }
    try {
        const { data } = await axiosInstance.patch(`employees/${id}/unassign-team-members`, datax)
        toast({
            description: data?.message,
            status: "success",
            position: 'top'
        });
        // setTriggerClose('close' + (Math.random()))
        setRefetch(Math.random())
    } catch(error) {
        if(error?.response?.data?.message) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
          });
        }
        
    } finally {
        setLoadingx(false)
    }
  }

  const addUser = (data) => {
    if(selected?.map(i => i?._id)?.includes(data?._id)) {
      const newArr = selected?.filter(i => i?._id !== data?._id)
      setSelected(newArr)
    } else {
      const newArr = [...selected, data]
      setSelected(newArr)
    }
  }


  return (
    <>
    
        <CustomDrawer
        position={"right"}
        title={`Assign Team`}
        triggerClose={triggerClose}
        toggleElement={
            <Flex justifyContent={"flex-end"} mb=".7rem">
                <Text fontSize={".85rem"} color="#7F56D9" cursor={"pointer"} fontWeight={"500"} textDecoration={"underline"}>Assign Members</Text>
            </Flex>
        }
        content={
            <AssignTeam title={'Promoter'} setTriggerClose={setTriggerClose} type={"assign"} employeeId={id} setRefetch={setRefetch} />
        }
        />
    
    {members?.map(({_id: id, firstName, lastName, email, profilePicture, isApproved}) => {
        const data = {_id: id, firstName, lastName, email, profilePicture, isApproved}
        return (
        <Flex key={id} onClick={() => addUser(data)} cursor={"pointer"} position={"relative"} padding={".7rem 1rem"} gap=".6rem" alignItems={"center"} mt=".8rem" borderRadius={"6px"} border={selected?.map(i => i?._id)?.includes(id) ? "2px solid #7F56D9": "1px solid lightgrey"}>
            <Flex alignItems={"center"} w="100%" justifyContent={"space-between"}>
                <Flex alignItems={"center"}>
                    <Box height={"50px"} width={"60px"} backgroundImage={profilePicture} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}  />
                    <Box>
                        <Text mb=".2rem" color={"#667085"} textTransform={"capitalize"} fontWeight={"500"}>{firstName + " " + lastName}</Text>
                        <Text fontWeight={"300"} fontSize={".85rem"} color="#667085">{email}</Text>
                    </Box>
                </Flex>
                <Text fontSize={"12px"} fontWeight={"500"} color={"#98A2B3"}>{isApproved ? "Verified" : "Unverified"}</Text>
            </Flex>
        </Flex>
    )})}

    <PrimaryButton mt="3rem" onClick={assignTeam} text={'Unassign Member'} loading={loadingx} disabled={selected?.length < 1} />
    </>
  )
}

export default ViewTeam;