import React, { useEffect, useState } from 'react'
import CustomInput from '../../../../components/customInput/customInput';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import axiosInstance from '../../../../services/api';
import { useToast } from '@chakra-ui/react';
import CustomSelect from '../../../../components/customSelect/customSelect';
import { useSelector } from 'react-redux';

const EditSurvey = ({data, setTriggerClose}) => {
  const [loading, setLoading] = useState(false)
  const [loadingx, setLoadingx] = useState(false)

  const [description, setDescription] = useState(data?.description);
  const [name, setName] = useState(data?.name);
  const [clientId, setClientId] = useState(data?.client?._id);
  const [clients, setClients] = useState([]);

  const currentUser = useSelector(({ userData }) => userData.currentUser);

  const toast = useToast();

  const getClients = async() => {
    setLoadingx(true)
    try {
        const { data } = await axiosInstance.get('clients')
        setClients(data?.data)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoadingx(false)
    }
  }

  useEffect(() => {
    getClients()
  }, [])

  const editSurvey = async(e) => {
    e.preventDefault()
    setLoading(true)
    const datax = {
      name,
      description,
      clientId: currentUser?.role === 'Clients' ? currentUser?._id : clientId
    }
    try {
      const res = await axiosInstance.patch(`/surveys/${data?._id}`, datax)
      toast({
        description: res?.data?.message,
        status: "success",
        position: "top"
      });
      setTriggerClose('close' + (Math.random()))
    } catch(error) {
      if(error?.response?.data?.message) {
        toast({
          title: "An Error Occurred",
          description: error?.response?.data?.message,
          status: "error",
          position: "top"
        });
      }
    } finally {
      setLoading(false)
    }
  }

  const clientOptions = clients?.map(i => ({
    title: i?.companyName,
    value: i?._id
  }))


  return (
    <form onSubmit={editSurvey}>
        <CustomInput
        type={"text"}
        required
        placeholder={"Name"}
        onChange={e => setName(e.target.value)}
        value={name}
        />
        <CustomInput
        type={"text"}
        required
        placeholder={"Description"}
        onChange={e => setDescription(e.target.value)}
        value={description}
        />
        {currentUser?.role !== "Client" &&
        <CustomSelect
        options={[{title: "Not Applicable", value: ""}, ...clientOptions]}
        placeholder={data?.client?.companyName}
        onChange={e => setClientId(e.target.value)}
        />}
        <PrimaryButton mt="4rem" type="submit" text="Update Survey" func={() => {}} loading={loading} />
    </form>
  )
}

export default EditSurvey