import { Box, Flex, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import CustomInput from '../../../../../../../components/customInput/customInput'
import CustomSelect from '../../../../../../../components/customSelect/customSelect'
import PrimaryButton from '../../../../../../../components/primaryButton/primarybutton'
import { AiFillCloseCircle } from 'react-icons/ai'
import axiosInstance from '../../../../../../../services/api'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const rankOptions = [
    {
        title: "1",
        value: 1
    },
    {
        title: "2",
        value: 2
    },
    {
        title: "3",
        value: 3
    },
    {
        title: "4",
        value: 4
    },
    {
        title: "5",
        value: 5
    },
    {
        title: "6",
        value: 6
    },
    {
        title: "7",
        value: 7
    },
    {
        title: "8",
        value: 8
    },
    {
        title: "9",
        value: 9
    },
    {
        title: "10",
        value: 10
    },
]

const Prizes = ({datum}) => {
  const [name, setName] = useState("")
  const [rank, setRank] = useState("")
  const [quantity, setQuantity] = useState("")
  const [prizeList, setPrizeList] = useState(datum?.gamePrizes)
  const [saved, setSaved] = useState(false)
  const [loadingx, setLoadingx] = useState(false)
  

    const searchx = window.location.search;
    const params = new URLSearchParams(searchx);
    const iid = params.get('iid')

  const {search} = useLocation()
  const toast = useToast()
  const navigate = useNavigate()

  const removePrize = (val) => {
    const arr2 = prizeList.filter(i => i.text !== val)
    setPrizeList(arr2)
  };

//   const handleAdd = () => {
//     setPrizeList([
//       ...prizeList,
//       {
//         name,
//         rank,
//         quantityAvailable: parseInt(quantity)
//       }
//     ])
//     setName("")
//     setRank("")
//     setQuantity("")
//     setSaved(true)
//   }

  const addPrize = async() => {
    setLoadingx(true)
    const datax = {
        name,
        rank: parseInt(rank),
        quantityAvailable: parseInt(quantity)
      }
    try {
        const { data } = await axiosInstance.post(`/initiatives/${iid}/game-prizes`, datax)
        toast({
        description: data?.message,
        status: "success",
        position: "top"
        });
        setPrizeList(data?.data?.gamePrizes)
        setName("")
        setRank("")
        setQuantity("")
        setSaved(true)
        // navigate(`/initiatives/create/4?iid=${search.slice(5)}`)
    } catch(error) {
        toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
        });
    } finally {
        setLoadingx(false)
    }
  }

  return (
    <>
        <Text mb="1rem" fontWeight={"500"}>Create prizes to be won. <span style={{opacity: ".6"}}>(The higher the rank, the more valuable the prize item)</span></Text>

        <Box my="1.5rem">
            {prizeList?.map(({name, quantityAvailable, rank}) => {
            return (
                <Box position={"relative"} mb=".5rem" key={name} padding={".4rem 1rem"} borderRadius={"4px"} border={"1px solid lightgray"}>
                    <Text fontWeight={"600"} textTransform={"capitalize"}>{name}</Text>
                    <Text fontSize={".9rem"}>Qty: {quantityAvailable}</Text>
                    <Text fontSize={".9rem"}>Value Rank: {rank}</Text>
                    <AiFillCloseCircle onClick={() => removePrize(name)} color='#EB5017' fontSize={"1.2rem"} style={{position: "absolute", right: "-.8%", top: "37%", cursor: "pointer"}} />
                </Box>
                // <Text position={"relative"} mb=".5rem" key={name} padding={".4rem 1rem"} borderRadius={"4px"} border={"1px solid lightgray"}>{quantity}?  <strong>{rank}</strong> <AiFillCloseCircle onClick={() => removePrize(name)} color='#EB5017' fontSize={"1.2rem"} style={{position: "absolute", right: "-.8%", top: "20%", cursor: "pointer"}} /></Text>
            )
            })}
        </Box>

        <Flex gap={"1rem"} alignItems={"center"}>
            <CustomInput
            type={"text"}
            placeholder={'Prize'}
            onChange={e => setName(e.target.value)}
            value={name}
            mt="0 !important"
            width="500px"
            />
            <CustomSelect
            required
            options={rankOptions}
            value={rank}
            placeholder={"Prize Rank"}
            onChange={e => setRank(e.target.value)}
            borderRadius="10px"
            mt="0 !important"
            />
            <CustomInput
            type={"tel"}
            placeholder={'Quantity'}
            onChange={e => setQuantity(e.target.value)}
            value={quantity}
            mt="0 !important"
            width="300px"
            />
        </Flex>

        <Flex justifyContent={"center"}>
            <PrimaryButton mt="2rem" text="Save" width="120px" py="1.4rem" onClick={addPrize} loading={loadingx} isDisabled={name === "" && quantity === "" && rank === ""} />
        </Flex>

        <PrimaryButton mt="3rem" type="submit" text="Finish" onClick={() => navigate(`/initiatives/create/4?iid=${search.slice(5)}`)} loading={false} isDisabled={saved === false} />
    </>
  )
}

export default Prizes