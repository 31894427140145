import { Avatar, Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import User from "../../assets/user2.png";
import { useSelector } from 'react-redux';
import Notifications from '../notifications/notifications';
import classes from './navbar.module.css'

const Navbar = ({ collapsed }) => {
  const { pathname } = useLocation();
  const currentUser = useSelector(({ userData }) => userData.currentUser);

  const navigate = useNavigate()

  const path = window.location.pathname
  console.log(path);


  return (
    <div className={classes.container}>

      <p className={classes.activeScreenText}> {pathname === '/' ? "Overview" : pathname.split('/')[1]?.replaceAll('/', '')}</p>

      {
        (currentUser?.role?.includes('Admin') || currentUser?.role?.includes('Super Admin')) && path !== '/tech-support-tickets' &&
        <p className={classes.tickets} style={{ cursor: 'pointer' }} onClick={() => navigate('/tech-support-tickets')} fontWeight={"500"} fontSize={".9rem"}>TS Tickets</p>
      }

      <Notifications />

      <div className={classes.avatarContainer}>
        <Avatar className={classes.avatar} cursor={"pointer"} onClick={() => { }} size={"md"} transform={"scale(.85)"} border={"1.73913px solid #CFD0D3"} src={currentUser?.imgUrl || User} />
        <div>
          <p className={classes.name} fontWeight={"500"} fontSize={".9rem"}>{currentUser?.role?.includes('Admin') ? (currentUser?.firstName) : currentUser?.role === "Agency" ? currentUser?.name : currentUser?.role === "Basic Subuser" ? currentUser?.name : currentUser?.companyName}</p>
          <p className={classes.role} color="#667085" fontSize={".9rem"}>{currentUser?.role}</p>
        </div>
      </div>

    </div>
  )
}

export default Navbar;