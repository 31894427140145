const moment = require('moment');

const date = new Date();

export const defaultSearchValueState = {
    page: 1,
    searchQuery: '',
    states: '',
    regions: '',
    groups: '',
    products: '',
    startDate: '',
    endDate: moment(date).format('YYYY-MM-DD'),
    limit: 100
};

export const defaultDrawerState = {
    title: '',
    content: '',
}

export const defaultTableStoreVisibleColumns = {
    'Sales (Total)': true,
    'Sales (Value)': true,
    'Shelf Inventory (Total)': true,
    'Shelf Inventory (Value)': true,
    'MSL (Avg)': true,
    'SOS (Avg)': true,
    'Store Inventory (Total Case)': true,
    'Store Inventory (Total Value)': true,
    'OOS (Entries)': true,
    'Shipment (Total Case)': true,
    'Shipment (Total Value)': true,
    'Orders (Total)': true,
    'Orders (Avg)': true,
    'SOS Categories': true
}

export const defaultTableStoreInvisibleColumns = {
    'Sales (Total)': false,
    'Sales (Value)': false,
    'Shelf Inventory (Total)': false,
    'Shelf Inventory (Value)': false,
    'MSL (Avg)': false,
    'SOS (Avg)': false,
    'Store Inventory (Total Case)': false,
    'Store Inventory (Total Value)': false,
    'OOS (Entries)': false,
    'Shipment (Total Case)': false,
    'Shipment (Total Value)': false,
    'Orders (Total)': false,
    'Orders (Avg)': false,
    'SOS Categories': false

}


export const defaultAssetOverviewVisibleColumns = {
    'condition': true,
    'comment': true,
    'inventoryLevel': true,
    'mslLevel': true,
    'image': true,
    commentLocation: true,
    inventoryLevelLocation: true,
    imageLocation: true,
    conditionLocation: true,
    mslLevelLocation: true
}

export const defaultAssetOverviewInvisibleColumns = {
    'condition': false,
    'comment': false,
    'inventoryLevel': false,
    'mslLevel': false,
    'image': false,
    commentLocation: false,
    inventoryLevelLocation: false,
    imageLocation: false,
    conditionLocation: false,
    mslLevelLocation: false
}

export const overViewTableInclusionList = [
    'groupName', 'asset', "user",
]
