import { ExportJsonCsv } from 'react-export-json-csv';
import classes from './initiativeQueryComponent/initiativeQueryComponent.module.css'

const ExportCSV = ({ headers, items }) => {



  return (
    <ExportJsonCsv headers={headers} items={items}>
      <button className={classes.exportButton}>Export</button>
    </ExportJsonCsv>
  )
}

export default ExportCSV