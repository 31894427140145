import { Box, Divider, Flex, Menu, MenuButton, MenuItem, MenuList, Switch, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import CustomDrawer from '../../../../components/customDrawer/customDrawer'
import axiosInstance from '../../../../services/api'
import SecondaryButton from '../../../../components/secondaryButton/secondarybutton'
import EmptyState from '../../../../components/emptyState/emptyState'
import PrimaryButton from '../../../../components/primaryButton/primarybutton'
import LineSkeleton from '../../../../components/lineSkeleton'
import CreateClient from '../../home/clientsView/createClient/createClient'
import { buildResourceURL, truncateText } from '../../../../utils/helper'
import { useNavigate } from 'react-router-dom'
import EditClient from '../../home/clientsView/editClient/editClient'
import { useSelector } from 'react-redux'
import CustomPagination from '../../../../components/customPagination/customPagination'
import ClientRegions from '../clientRegions/createRegion/createRegions'
import ServerExport from '../../../../components/serverExport/serverExport'
import CustomSelect from 'components/customSelect/customSelect'
import countriesData from '../../../../utils/countries.json'

const ClientsTable = () => {
    const [clients, setClients] = useState([]);
    const [totalClients, setTotalClients] = useState("");
    const [loading, setLoading] = useState(true);
    const [loadingc, setLoadingc] = useState(false);
    const [loadingx, setLoadingx] = useState(false);
    const [modalType, setModalType] = useState("");
    const [selectedUser, setSelectedUser] = useState(null)
    const [refetch, setRefetch] = useState("");
    const [binView, setBinView] = useState(false);
    const [country, setCountry] = useState('')

    const [triggerClose, setTriggerClose] = useState("");
    const currentUser = useSelector(({ userData }) => userData?.currentUser);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast()
    const navigate = useNavigate()

    const [page, setPage] = useState(1)
    const limit = 10

    const queryParams = [
        {
            key: 'isDeleted',
            value: binView === false ? "" : binView
        },
        {
            key: 'page',
            value: page
        },
        {
            key: 'limit',
            value: limit
        },
        {
            key: 'country',
            value: country
        }
    ]

    const getClients = async () => {
        try {
            const { data } = await axiosInstance.get(buildResourceURL('clients', queryParams))
            setClients(data?.data)
            setTotalClients(data?.totalCount)
        } catch (error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getClients()
    }, [triggerClose, refetch, page, country])

    const handleModal = (type, user) => {
        setModalType(type)
        setSelectedUser(user)
        onOpen()
    }

    const approveEmployee = async () => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.patch(`clients/${selectedUser?.id}/approve`)
            toast({
                description: data?.message,
                status: "success",
                position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch (error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }


    const suspendEmployee = async () => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.delete(`clients/${selectedUser?.id}/soft`)
            toast({
                description: data?.message,
                status: "success",
                position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch (error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }

    const restoreEmployee = async () => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.patch(`clients/${selectedUser?.id}/restore`)
            toast({
                description: data?.message,
                status: "success",
                position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch (error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }

    const deleteEmployee = async () => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.delete(`clients/${selectedUser?.id}`)
            toast({
                description: data?.message,
                status: "success",
                position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch (error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }

    const handleViewBin = () => {
        setBinView(!binView)
        setRefetch(Math.random())
    }

    const headers = [
        {
            key: '_id',
            name: 'ID',
        },
        {
            key: 'companyName',
            name: 'Company Name',
        },
        {
            key: 'email',
            name: 'Email',
        },
        {
            key: 'phoneNumber',
            name: 'Phone Number',
        },
        {
            key: 'role',
            name: 'Role',
        },
        {
            key: 'isDeleted',
            name: 'Deleted?',
        },
        {
            key: 'createdAt',
            name: 'Joined',
        },
    ]

    const handleToggle = async (id) => {
        setLoadingc(true)
        try {
            const { data } = await axiosInstance.patch(`clients/${id}/show-on-store-creation`)
            toast({
                description: data?.message,
                status: "success",
                position: "top"
            });
            // setRefetch(Math.random())
        } catch (error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top"
            });
        } finally {
            setLoadingc(false)
        }
    }

     const countries = countriesData?.map(x => ({
        title: x?.name,
        value: x?.name?.toLowerCase()
      }))

  return (
    <>
    <Flex mb="1rem" justify={"flex-end"}>
    {currentUser?.role === 'Super Admin' &&
        <Menu width={"200px !important"}>
            <MenuButton>
                <BiDotsVerticalRounded style={{cursor: "pointer"}} />
            </MenuButton>
            <MenuList>
                <Divider my=".2rem" />
                <MenuItem onClick={handleViewBin} fontWeight={"500"} fontSize={".85rem"}>{binView ? "Back to full list" : "View Bin"}</MenuItem>
                <Divider my=".2rem" />
            </MenuList>
        </Menu>}
        </Flex>

            <Flex alignItems={"center"} gap=".8rem" justifyContent={"space-between"}>
                <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>Clients <span style={{ padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054" }}>{totalClients}</span></Text>

                <Flex alignItems={"center"} gap="1rem">
                    {/* <ExportCSV headers={headers} items={clients} /> */}
                    <ServerExport entity={"clients"} />

                    <CustomSelect
                    required
                    options={[{title: "All Countries", value: ""}, ...countries]}
                    onChange={e => setCountry(e.target.value)}
                    height="40px !important"
                    mt="0"
                    borderRadius={"12px"}
                    width="200px"
                    cursor="pointer"
                    />

                    {currentUser?.role === "Super Admin" &&
                        <CustomDrawer
                            position={"right"}
                            title={"Add Client"}
                            triggerClose={triggerClose}
                            setTriggerClose={setTriggerClose}
                            toggleElement={
                                <SecondaryButton type="submit" text={"Add Client"} icon="add" loading={false} />
                            }
                            content={
                                <CreateClient loading={loadingc} setTriggerClose={setTriggerClose} setLoading={setLoadingc} />
                            }
                        />}
                </Flex>

            </Flex>
            <Box p="0 1rem" bg="#FFF" mt="1rem" height={"84vh"} overflowY={"scroll"} borderRadius={"12px"}>

                <Table mt="1.2rem" borderRadius={"12px"} overflow={"hidden"}>
                    <Thead bg="#F9FAFB" border="1px solid #EAECF0">
                        <Tr>
                            <Th textTransform={"capitalize !important"} color={"#475467"}>ID</Th>
                            <Th textTransform={"capitalize !important"} color={"#475467"}>Name</Th>
                            <Th textTransform={"capitalize !important"} color={"#475467"}>Email</Th>
                            <Th textTransform={"capitalize !important"} color={"#475467"}>Country</Th>
                            <Th textTransform={"capitalize !important"} color={"#475467"}>Phone</Th>
                            <Th textTransform={"capitalize !important"} color={"#475467"}>Joined</Th>
                            <Th textTransform={"capitalize !important"} color={"#475467"}>Visibility</Th>
                            <Th textTransform={"capitalize !important"} color={"#475467"}>Status</Th>
                            <Th textTransform={"capitalize !important"} color={"#475467"}></Th>
                            <Th></Th>
                        </Tr>
                    </Thead>

                    {loading ?
                        <LineSkeleton width="100%" num={10} />
                        :
                        clients?.length < 1 ?
                            <EmptyState mt="1.5rem" text="There are no clients yet" />
                            :
                            <Tbody>

                                {clients?.map((({ _id: id, companyName, createdAt, email, phoneNumber, isDeleted, isApproved, showOnStoreCreation, country }) => {
                                    const data = { id, companyName, createdAt, email, phoneNumber, isDeleted, isApproved }
                                    return (
                                        <Tr key={id}>
                                            <Td color={"#475467"} fontSize={".9rem"}>{truncateText(id, 10)}</Td>
                                            <Td>
                                                <Flex alignItems={"center"} gap=".4rem">
                                                    {/* <Box height={"40px"} width={"40px"} backgroundImage={profilePicture} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}  /> */}
                                                    <Box>
                                                        <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{companyName}</Text>
                                                    </Box>
                                                </Flex>
                                            </Td>
                                            <Td color={"#475467"} fontSize={".9rem"}>{email}</Td>
                                            <Td color={"#475467"} fontSize={".9rem"} textTransform={"capitalize"}>{country}</Td>
                                            <Td color={"#475467"} fontSize={".9rem"}>{phoneNumber}</Td>
                                            <Td color={"#475467"} fontWeight={"500"} fontSize={".85rem"}>{createdAt?.slice(0, 10)}</Td>
                                            <Td color={"#475467"} fontWeight={"500"} fontSize={".85rem"}>
                                                <Switch onChange={() => handleToggle(id)} defaultChecked={showOnStoreCreation} />
                                            </Td>
                                            <Td>
                                                <Text textAlign={"center"} fontSize={"12px"} fontWeight={"500"} p=".1rem" borderRadius={"16px"} border={isApproved ? "1px solid #ABEFC6" : "1px solid lightgray"} color={isApproved ? "#067647" : "#475467"} bg={isApproved ? "#ECFDF3" : "#F9FAFB"}>{isApproved ? "Verified" : "Unverified"}</Text>
                                            </Td>
                                            <Td>
                                                <Menu width={"200px !important"}>
                                                    <MenuButton>
                                                        <BiDotsVerticalRounded style={{ cursor: "pointer" }} />
                                                    </MenuButton>
                                                    <MenuList>
                                                        <Divider my=".2rem" />
                                                        <MenuItem fontWeight={"500"} onClick={() => navigate(`/clients/${id}`)} fontSize={".85rem"}>View Client</MenuItem>
                                                        <Divider my=".2rem" />
                                                        <MenuItem fontWeight={"500"} onClick={() => navigate(`/assets?clientId=${id}&name=${companyName}`)} fontSize={".85rem"}>Assets</MenuItem>
                                                        {(currentUser?.role === "Super Admin" && isApproved === false) &&
                                                            <>
                                                                <Divider my=".2rem" />
                                                                <MenuItem onClick={() => handleModal('approve', { companyName, id })} fontWeight={"500"} fontSize={".85rem"}>Approve Client</MenuItem>
                                                            </>}
                                                        <Divider my=".2rem" />
                                                        {currentUser?.role === "Super Admin" &&
                                                            <>
                                                                <CustomDrawer
                                                                    position={"right"}
                                                                    title={"Update Client Details"}
                                                                    triggerClose={triggerClose}
                                                                    toggleElement={
                                                                        <MenuItem fontWeight={"500"} fontSize={".85rem"}>Edit Client</MenuItem>
                                                                    }
                                                                    content={
                                                                        <EditClient loading={loadingc} setTriggerClose={setTriggerClose} setLoading={setLoadingc} datay={data} />
                                                                    }
                                                                />
                                                                <Divider my=".2rem" />
                                                                {isDeleted ?
                                                                    <MenuItem onClick={() => handleModal('restore', { companyName, id })} fontWeight={"500"} fontSize={".85rem"}>Restore Client</MenuItem>
                                                                    :
                                                                    <MenuItem onClick={() => handleModal('suspend', { companyName, id })} fontWeight={"500"} fontSize={".85rem"}>Suspend Client</MenuItem>
                                                                }
                                                                <Divider my=".2rem" />
                                                                <CustomDrawer
                                                                    position={"right"}
                                                                    title={"Update Region Group"}
                                                                    triggerClose={triggerClose}
                                                                    setTriggerClose={setTriggerClose}
                                                                    toggleElement={
                                                                        <MenuItem fontWeight={"500"} fontSize={".85rem"}>Update Client Regions</MenuItem>
                                                                    }
                                                                    content={
                                                                        <ClientRegions cid={id} setTriggerClose={setTriggerClose} />
                                                                    }
                                                                />
                                                                <Divider my=".2rem" />
                                                                <MenuItem onClick={() => handleModal('delete', { companyName, id })} color={"crimson"} fontWeight={"500"} fontSize={".85rem"}>Delete Client</MenuItem>
                                                                <Divider my=".2rem" />
                                                            </>}
                                                    </MenuList>
                                                </Menu>
                                            </Td>
                                        </Tr>
                                    )
                                }))
                                }
                            </Tbody>}
                </Table>

                <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{`${modalType} Client`}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {modalType === 'approve' &&
                                <>
                                    <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm approval of {selectedUser?.companyName}?</Text>
                                    <Flex justifyContent={"center"} my="1.3rem">
                                        <PrimaryButton onClick={approveEmployee} text="Approve" width="150px" padding="1rem !important" loading={loadingx} />
                                    </Flex>
                                </>
                            }
                            {modalType === 'suspend' &&
                                <>
                                    <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm suspension of {selectedUser?.companyName}?</Text>
                                    <Flex justifyContent={"center"} my="1.3rem">
                                        <PrimaryButton onClick={suspendEmployee} text="Suspend" width="150px" padding="1rem !important" loading={loadingx} />
                                    </Flex>
                                </>
                            }
                            {modalType === 'restore' &&
                                <>
                                    <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm restore of {selectedUser?.companyName}?</Text>
                                    <Flex justifyContent={"center"} my="1.3rem">
                                        <PrimaryButton onClick={restoreEmployee} text="Restore" width="150px" padding="1rem !important" loading={loadingx} />
                                    </Flex>
                                </>
                            }
                            {modalType === 'delete' &&
                                <>
                                    <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm deletion of {selectedUser?.companyName}?</Text>
                                    <Flex justifyContent={"center"} my="1.3rem">
                                        <PrimaryButton onClick={deleteEmployee} text="Delete" width="150px" bg="crimson !important" padding="1rem !important" loading={loadingx} />
                                    </Flex>
                                </>
                            }
                            <Text pb="1rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Cancel</Text>
                        </ModalBody>
                    </ModalContent>
                </Modal>

                <CustomPagination
                    page={page}
                    setPage={setPage}
                    pageSize={limit}
                    totalElements={totalClients}
                />
            </Box>
        </>
    )
}

export default ClientsTable