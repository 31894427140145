import { Box, Flex, Heading, Text, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../../services/api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { truncateText } from '../../../../utils/helper';
import { HiArrowRight } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import LineSkeleton from '../../../../components/lineSkeleton';
import EmptyState from '../../../../components/emptyState/emptyState';
import GoBack from '../../../../components/goBack';
import Initiatives from '../../initiatives/initiatives';
import CustomTabs from '../../../../components/customTabs/customTabs';
import StoresTable from '../../stores/storesTable/storesTable';
import { MdVerified } from 'react-icons/md';
import SpinLoader from '../../../../components/spinLoader/spinLoader';
import RegionsTable from '../clientRegions/regionsTable/regionsTable';
import EmployeeTable from '../../../../components/employeeView/employeeTable/employeeTable';
import SurveysTable from '../../surveys/surveysTable/surveysTable';
import SubusersTable from '../../subUsers/subusersTable/subusersTable';
import AssetsList from 'pages/assets/assets';

const ClientInitiatives = () => {
    const [details, setDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams()

    const getClientDetails = async () => {
        try {
            const { data } = await axiosInstance.get(`clients/${id}`)
            setDetails(data?.data)
        } catch (error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getClientDetails()
    }, [])

    const datax = [
        {
            title: "Initiatives",
            render: <Initiatives clientId={id} />
        },
        {
            title: "Outlets",
            render: <StoresTable clientId={id} />
        },
        {
            title: "Promoters",
            render: <EmployeeTable title={'Promoter'} clientId={id} />
        },
        {
            title: "Supervisors",
            render: <EmployeeTable title={'Supervisor'} clientId={id} />
        },
        {
            title: "Surveys",
            render: <SurveysTable clientId={id} />
        },
        {
            title: "Sub-Users",
            render: <SubusersTable clientId={id} />
        },
        {
            title: "Region Filters",
            render: <RegionsTable clientId={id} regionsData={details?.statesFilterGroups} />
        },
        {
            title: "Assets",
            render: <AssetsList client={id} name={details?.companyName} />
        },
    ]

    datax.sort((a, b) => (a.title.localeCompare(b.title)))

    return (
        <>
            {loading ?
                <SpinLoader />
                :
                <>
                    <GoBack />
                    <br />
                    <Flex mb="2rem" alignItems={"center"} gap=".7rem">
                        <Box height={"45px"} width={"45px"} position={"relative"} backgroundColor={"lightgray"} backgroundImage={""} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}>
                            <MdVerified color="#1671D9" style={{ position: "absolute", zIndex: "10", bottom: "0", right: "-10%", transform: "scale(1.2)" }} />
                        </Box>
                        <Box>
                            <Text mb=".1rem" color={"#101828"} fontSize={"1.1rem"} fontWeight={"500"} textTransform={"capitalize"}>{details?.companyName}</Text>
                            <Text color={"#475467"} fontSize={".8rem"} fontWeight={"500"}>{details?.email}</Text>
                        </Box>
                    </Flex>
                    <CustomTabs mb="0 !important" data={datax} />
                </>}
        </>
    )
}

export default ClientInitiatives;