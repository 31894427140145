import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import ImageUploader from '../../../../../../../components/imageUploader/imageUploader'
import PrimaryButton from '../../../../../../../components/primaryButton/primarybutton'

const Posm = ({setPosmImages, posmImages, uploadImages, uLoading}) => {
  const [loading, setLoading] = useState(false)
  const [loadingx, setLoadingx] = useState(false)

  const handleImagesUpload = (images) => {
    setPosmImages([...posmImages, images[0]])
  };

  return (
    <Box border=".5px solid #D0D5DD" borderRadius={"12px"} py="2rem">
     <Box maxW="400px" mx="auto">
        <Text textAlign={"center"} fontWeight={"500"}>Upload images of what the POSM should look like</Text>
        <Flex gap=".5rem" mt="1.5rem">
            {posmImages[0] !== undefined && <Box backgroundImage={posmImages[0]} backgroundSize={"cover"} height={"100px"} width={"140px"} borderRadius={"4px"} backgroundColor={"lightgray"} />}
            <ImageUploader
            onImagesUpload={handleImagesUpload}
            setLoading={setLoading}
            loading={loading}
            max={1}
            />
        </Flex>
        <Flex gap=".5rem" mt="1.5rem">
            {posmImages[1] !== undefined && <Box backgroundImage={posmImages[1]} backgroundSize={"cover"} height={"100px"} width={"140px"} borderRadius={"4px"} backgroundColor={"lightgray"} />}
            <ImageUploader
            onImagesUpload={handleImagesUpload}
            setLoading={setLoadingx}
            loading={loadingx}
            max={1}
            />
        </Flex>
        <PrimaryButton onClick={uploadImages} loading={uLoading} mt="2.5rem" text="Submit and continue" />
     </Box>
    </Box>
  )
}

export default Posm;