export default function LightenDarkenColor(col, amt) {
    var num = parseInt(col, 16);
    var r = (num >> 16) + amt;
    var b = ((num >> 8) & 0x00FF) + amt;
    var g = (num & 0x0000FF) + amt;
    var newColor = g | (b << 8) | (r << 16);
    return newColor.toString(16);
  }
  
export function truncateText(text, length) {
  let newText = text;
  if(text?.length > length) {
    newText = text?.slice(0,length)
    return newText + "..."
  }
  return newText;
}

export function convertToProperObject(data) {
  const formattedData = {};
  
  for (const key in data) {
      const unescapedKey = JSON.parse(key); // Remove the extra quotes using JSON.parse
      formattedData[unescapedKey] = data[key];
  }
  
  return formattedData;
}

export function removeQuotes(inputString) {
  return inputString.replace(/"/g, '');
}

export function buildResourceURL(baseURL, queryParams) {
  const queryString = queryParams
    .filter(({ value }) => value !== "" && value !== null && value !== undefined)
    .map(({ key, value }) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

  const finalURL = queryString ? `${baseURL}?${queryString}` : baseURL;

  return finalURL;
}

export function buildResourceURL2(baseURL, queryParams) {

  const queryString = queryParams
    .filter(({ value }) => value !== "" && value !== null && value !== undefined)
    .map(({ key, value }) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

  const finalURL = queryString ? `${baseURL}&${queryString}` : baseURL;

  return finalURL;
}

export const findDefaultCenter = (coordinates) => {
  if (!coordinates || coordinates.length === 0) {
    return null;
  }

  // Calculate average latitude and longitude
  const avgLatitude = coordinates.reduce((sum, coord) => sum + coord[1], 0) / coordinates.length;
  const avgLongitude = coordinates.reduce((sum, coord) => sum + coord[0], 0) / coordinates.length;

  return { lat: avgLatitude, lng: avgLongitude };
};

export const getNextDay = () => {
  const today = new Date();
  const nextDay = new Date(today);
  nextDay.setDate(today.getDate() + 1);

  const year = nextDay.getFullYear();
  const month = String(nextDay.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(nextDay.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const getCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getDateRelativeToPresetDay = (presetDate, offset) => {
  const presetDay = new Date(presetDate); // Example: Change this to your preset day
  const date = new Date(presetDay);
  date.setDate(presetDay.getDate() + offset);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function formatNumber(number) {
  const num = number || 0
  // Check if the number is an integer
  if (Number.isInteger(num)) {
      // Format the integer part with commas only
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
      // Convert the number to a string with maximum 2 decimal places
      const formattedNumber = num.toFixed(2);

      // Split the number into integer and decimal parts
      const [integerPart, decimalPart] = formattedNumber.split('.');

      // Use regex to add commas as thousand separators to the integer part
      const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      // Return the formatted number with the integer part and the decimal part
      return `${formattedIntegerPart}.${decimalPart}`;
  }
}