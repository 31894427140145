import { Box, Flex, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import CustomInput from '../../../../../../components/customInput/customInput'
import CustomSelect from '../../../../../../components/customSelect/customSelect'
import PrimaryButton from '../../../../../../components/primaryButton/primarybutton'
import { AiFillCloseCircle, AiFillEdit, AiOutlineEdit } from 'react-icons/ai'
import axiosInstance from '../../../../../../services/api'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import SpinLoader from '../../../../../../components/spinLoader/spinLoader'
import CustomDrawer from '../../../../../../components/customDrawer/customDrawer'

const rankOptions = [
    {
        title: "1",
        value: 1
    },
    {
        title: "2",
        value: 2
    },
    {
        title: "3",
        value: 3
    },
    {
        title: "4",
        value: 4
    },
    {
        title: "5",
        value: 5
    },
    {
        title: "6",
        value: 6
    },
    {
        title: "7",
        value: 7
    },
    {
        title: "8",
        value: 8
    },
    {
        title: "9",
        value: 9
    },
    {
        title: "10",
        value: 10
    },
]

const Prizes = ({datum, triggerClose, setTriggerClose, setRefetch, isid, sPrizes}) => {
  const [name, setName] = useState("")
  const [rank, setRank] = useState("")
  const [quantity, setQuantity] = useState("")
  const {iid, sid} = useParams()
  console.log(iid, sid)

  const [namex, setNamex] = useState("")
  const [rankx, setRankx] = useState("")
  const [quantityx, setQuantityx] = useState("")
  const [gpid, setGpid] = useState("")

  const [prizeList, setPrizeList] = useState((sid || isid) ? sPrizes : datum?.gamePrizeOptions)
  const [saved, setSaved] = useState(false)
  const [loadingx, setLoadingx] = useState(false)
  const [loadingd, setLoadingd] = useState(false)
  

  
  const toast = useToast()

  const removePrize = async(id, name) => {
    setLoadingd(true)
    try {
        const { data } = await axiosInstance.delete((sid || isid) ? `/initiative-stores/${sid || isid}/game-prizes/${id}` : `/initiatives/${iid}/game-prize-options/${id}`)
        toast({
        description: data?.message,
        status: "success",
        position: "top"
        });
        const arr2 = prizeList.filter(i => i.name !== name)
        setPrizeList(arr2)
        console.log(arr2)
        // navigate(`/initiatives/create/4?iid=${search.slice(5)}`)
    } catch(error) {
        toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
        });
    } finally {
        setLoadingd(false)
    }
  };

//   const handleAdd = () => {
//     setPrizeList([
//       ...prizeList,
//       {
//         name,
//         rank,
//         quantityAvailable: parseInt(quantity)
//       }
//     ])
//     setName("")
//     setRank("")
//     setQuantity("")
//     setSaved(true)
//   }

  const addPrize = async() => {
    setLoadingx(true)
    const datax = {
        name,
        rank: Number(rank),
        // quantityAvailable: parseInt(quantity)
      }
    try {
        const { data } = await axiosInstance.post((sid || isid) ? `/initiative-stores/${sid || isid}/game-prizes` : `/initiatives/${iid}/game-prize-options`, datax)
        toast({
        description: data?.message,
        status: "success",
        position: "top"
        });
        setPrizeList([
            ...prizeList,
            datax
        ])
        setName("")
        setRank("")
        setQuantity("")
        setSaved(true)
        // window.location.reload()
        // navigate(`/initiatives/create/4?iid=${search.slice(5)}`)
    } catch(error) {
        if(error?.response?.data?.message) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top"
            });
        }
    } finally {
        setLoadingx(false)
    }
  }

  const updatePrize = async() => {
    setLoadingx(true)
    const datax = (sid || isid) ? {
        name: namex,
        rank: parseInt(rankx),
        quantityAvailable: Number(quantityx)
      } : {
        name: namex,
        rank: parseInt(rankx),
      }
    try {
        const { data } = await axiosInstance.patch((sid || isid) ? `/initiative-stores/${sid || isid}/game-prizes/${gpid}` : `/initiatives/${iid}/game-prize-options/${gpid}`, datax)
        toast({
        description: data?.message,
        status: "success",
        position: "top"
        });
        setPrizeList([
            datax,
            ...prizeList?.filter(x => x?._id !== gpid)
        ])
        setTriggerClose('close' + (Math.random()))
        setRefetch(Math.random())
    } catch(error) {
        if(error?.response?.data?.message) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top"
            });
        }
        
    } finally {
        setLoadingx(false)
    }
  }

  const handleEdit = (val) => {
    setNamex(val?.name)
    setRankx(val?.rank)
    setQuantityx(val?.quantityAvailable)
    setGpid(val?._id)
  }

  return (
    <>
        <Text mb="1rem" fontWeight={"500"}>Create prizes to be won. <span style={{opacity: ".6"}}> <br />(The higher the rank, the more valuable the prize item)</span></Text>

        <Box my="1.5rem">
            {prizeList?.map(({_id, name, rank, quantityAvailable}) => {
            return (
                <Box position={"relative"} mb=".5rem" key={name} padding={".4rem 1rem"} borderRadius={"4px"} border={"1px solid lightgray"}>
                    <Text fontWeight={"600"} textTransform={"capitalize"}>{name}</Text>
                    <Text fontSize={".9rem"}>Value Rank: {rank}</Text>
                    <Text fontSize={".9rem"}>Qty: {quantityAvailable}</Text>
                    {/* {!loadingd ? */}
                    <CustomDrawer
                        position={"right"}
                        title={"Edit Initiative Details"}
                        triggerClose={triggerClose}
                        setTriggerClose={setTriggerClose}
                        toggleElement={
                            <AiOutlineEdit onClick={() => handleEdit({_id, name, rank, quantityAvailable})} color='#333' fontSize={"1.2rem"} style={{position: "absolute", right: "-2%", top: "15%", cursor: "pointer"}} />
                        }
                        content={
                            <form>
                                <CustomInput
                                type={"text"}
                                placeholder={'Prize'}
                                onChange={e => setNamex(e.target.value)}
                                value={namex}
                                mt="1rem"
                                />
                                <CustomSelect
                                required
                                // options={rankOptions?.filter(x => !prizeList?.map(v => v?.rank)?.includes(x?.value) )}
                                options={rankOptions}
                                value={rankx}
                                placeholder={rankx}
                                onChange={e => setRankx(e.target.value)}
                                borderRadius="10px"
                                mt="1rem"
                                isDisabled={(sid || isid) ? true : false}
                                />
                                {(sid || isid) &&
                                <CustomInput
                                type={"tel"}
                                placeholder={'Quantity'}
                                onChange={e => setQuantityx(e.target.value)}
                                value={quantityx}
                                mt="1rem"
                                />}
                                <PrimaryButton text="Update" onClick={updatePrize} loading={loadingx} mt="1.5rem" />
                            </form>
                        }
                    />
                    <AiFillCloseCircle onClick={() => removePrize(_id, name)} color='#EB5017' fontSize={"1.2rem"} style={{position: "absolute", right: "-2%", top: "60%", cursor: "pointer"}} />
                    {/* :
                    <Box height={"15px"} width={"15px"}>
                        <SpinLoader />
                    </Box>
                    } */}
                </Box>
                // <Text position={"relative"} mb=".5rem" key={name} padding={".4rem 1rem"} borderRadius={"4px"} border={"1px solid lightgray"}>{quantity}?  <strong>{rank}</strong> <AiFillCloseCircle onClick={() => removePrize(name)} color='#EB5017' fontSize={"1.2rem"} style={{position: "absolute", right: "-.8%", top: "20%", cursor: "pointer"}} /></Text>
            )
            })}
        </Box>

        {(!sid && !isid) &&
        <Flex gap={"1rem"} flexDirection={"column"} alignItems={"center"}>
            <CustomInput
            type={"text"}
            placeholder={'Prize'}
            onChange={e => setName(e.target.value)}
            value={name}
            mt="0 !important"
            />
            <CustomSelect
            required
            // options={rankOptions?.filter(x => !prizeList?.map(v => v?.rank)?.includes(x?.value) )}
            options={rankOptions}
            value={rank}
            placeholder={"Prize Rank"}
            onChange={e => setRank(e.target.value)}
            borderRadius="10px"
            mt="0 !important"
            />
            {/* <CustomInput
            type={"tel"}
            placeholder={'Quantity'}
            onChange={e => setQuantity(e.target.value)}
            value={quantity}
            mt="0 !important"
            /> */}
        </Flex>}

        <PrimaryButton mt="2rem" text="Save" onClick={addPrize} loading={loadingx} isDisabled={name === "" && quantity === "" && rank === ""} />
        
    </>
  )
}

export default Prizes