import { Box, Divider, Flex, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import CustomInput from '../../../../../components/customInput/customInput'
import PrimaryButton from '../../../../../components/primaryButton/primarybutton';
import CustomTextArea from '../../../../../components/customTextArea/customTextArea';
import ImageUploader from '../../../../../components/imageUploader/imageUploader';
import { AiFillCloseCircle } from 'react-icons/ai';
import axiosInstance from '../../../../../services/api';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomSelect from '../../../../../components/customSelect/customSelect';
import { useSelector } from 'react-redux';

const Mechanics = () => {
  const [loadingx, setLoadingx] = useState(false)
  const [saved, setSaved] = useState(false)
  const [client, setClient] = useState("");
  const [clients, setClients] = useState([]);
  const [loadingi, setLoadingi] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const currentUser = useSelector(({userData})=>  userData?.currentUser);

  const getClients = async() => {
    setLoadingi(true)
    try {
        const { data } = await axiosInstance.get('clients')
        setClients(data?.data)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoadingi(false)
    }
  }

  useEffect(() => {
    getClients()
  }, [])

  const toast = useToast()
  const [brandValues, setBrandValues] = useState({
    name: '',
    sku: '',
    target: '',
    durationInDays: '',
    image: '',
    caseUnitsNumber: '',
    pricePerCase: ''
  });
  const [formValues, setFormValues] = useState({
    clientId: currentUser?.role?.includes("Admin") ? client : currentUser?._id,
    name: '',
    description: '',
    instructions: '',
    brands: [],
    imageURL: '',
    // startDate: ''
  });

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    if(name === 'SKUs') {
      setBrandValues({ ...brandValues, [name]: value?.split(',')?.map(i => i?.trim()) });
    } else if(name === 'target' || name === 'durationInDays' || name === 'caseUnitsNumber' || name === 'pricePerCase') {
      setBrandValues({ ...brandValues, [name]: value ? parseInt(value) : "" });
    } else {
      setBrandValues({ ...brandValues, [name]: value });
    }
  };

  const handleImagesUpload = (images) => {
    setBrandValues({...brandValues, image: images[0]})
  };
  const handleImagesUpload2 = (images) => {
    setFormValues({...formValues, imageURL: images[0]})
  };

  const saveBrands = () => {
    if(brandValues?.name?.includes('-')) {
      toast({
        title: "Invalid Input",
        description: "Brand name cannot contain the character '-'",
        status: "info",
        position: "top"
      });
    } else {
      if(brandValues.name !== '') {
        // if(brandValues.name !== '' && formValues?.brands?.filter(i => i.name === brandValues?.name)?.length < 1) {
          setFormValues({...formValues, brands: [...formValues.brands, brandValues]})
        }
        setSaved(true)
        setBrandValues({
          name: '',
          sku: '',
          target: '',
          durationInDays: '',
          image: '',
          caseUnitsNumber: '',
          pricePerCase: ''
        })
    }
  };

  const removeBrand = (val) => {
    const arr2 = formValues.brands.filter(i => i.name !== val)
    setFormValues({...formValues, brands: arr2})
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    setLoadingx(true)
    try {
      const { data } = await axiosInstance.post('initiatives', formValues)
      toast({
        description: data?.message,
        status: "success",
        position: "top"
      });
      navigate(`/initiatives/create/2?iid=${data?.data?._id}`)
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
      });
    } finally {
      setLoadingx(false)
    }
  }

  const clientOptions = clients?.map(i => ({
    title: i?.companyName,
    value: i?._id
  }))

  return (
    <Box p="0 1rem 1rem 1rem" border=".5px solid #D0D5DD" borderRadius={"12px"}>
        <form onSubmit={handleSubmit}>
          {currentUser?.role?.includes("Admin") &&
          <CustomSelect
          required
          name="clientId"
          options={clientOptions}
          placeholder={"Client"}
          onChange={e => handleInputChange(e)}
          />}
          <CustomInput
          type={"text"}
          required
          name="name"
          placeholder={"Initiative Name"}
          onChange={e => handleInputChange(e)}
          />
          <CustomTextArea
          required
          rows={"3"}
          name="description"
          placeholder={"Description"}
          onChange={e => handleInputChange(e)}
          mt="1rem"
          />
          <CustomTextArea
          required
          rows={"3"}
          name="instructions"
          placeholder={"Instructions"}
          onChange={e => handleInputChange(e)}
          mb="1rem"
          />
          {/* <CustomInput
          type={"date"}
          required
          name="startDate"
          placeholder={"Initiative start date"}
          onChange={e => handleInputChange(e)}
          mb="1rem"
          /> */}
          <Flex gap=".5rem">
              {formValues?.imageURL !== '' && <Box backgroundImage={formValues?.imageURL} backgroundSize={"cover"} height={"100px"} width={"120px"} borderRadius={"4px"} backgroundColor={"lightgray"} />}
              <ImageUploader
              onImagesUpload={handleImagesUpload2}
              setLoading={setLoading2}
              loading={loading2}
              max={1}
              />
          </Flex>
          <Divider my="1.2rem" />
          <Flex justifyContent={"space-between"} mb=".5rem">
              <Text color={"#344054"} mt=".5rem" mb=".2rem" fontWeight={"500"} fontSize={".9rem"}>Brands in Promo</Text>
              {/* <Text onClick={addBrand} cursor={"pointer"} color={"#344054"} mt=".5rem" mb=".2rem" fontWeight={"500"} fontSize={".9rem"}><AddIcon transform={"translateY(-1px)"} fontSize={".7rem"} /> Add Brand</Text> */}
          </Flex>
          <Box p="0 .8rem .8rem .8rem" mb="1rem" borderRadius={"12px"} border=".5px solid #D0D5DD" position={"relative"}>
              <CustomInput
              type={"text"}
              name="name"
              value={brandValues?.name}
              placeholder={"Brand name"}
              onChange={e => handleInputChange2(e)}
              />
              <CustomInput
              type={"text"}
              name="sku"
              value={brandValues?.sku}
              placeholder={"SKU"}
              onChange={e => handleInputChange2(e)}
              />
              <CustomInput
              type={"tel"}
              name="target"
              value={brandValues?.target}
              placeholder={"Units Target"}
              onChange={e => handleInputChange2(e)}
              />
              <CustomInput
              type={"tel"}
              name="durationInDays"
              value={brandValues?.durationInDays}
              placeholder={"Duration (Days)"}
              onChange={e => handleInputChange2(e)}
              />
              <CustomInput
              type={"tel"}
              name="caseUnitsNumber"
              value={brandValues?.caseUnitsNumber}
              placeholder={"Case Units Number"}
              onChange={e => handleInputChange2(e)}
              />
              <CustomInput
              type={"tel"}
              name="pricePerCase"
              value={brandValues?.pricePerCase}
              placeholder={"Price Per Case"}
              onChange={e => handleInputChange2(e)}
              mb="1rem"
              />
              <Flex gap=".5rem">
                  {brandValues?.image !== '' && <Box backgroundImage={brandValues?.image} backgroundSize={"cover"} height={"100px"} width={"120px"} borderRadius={"4px"} backgroundColor={"lightgray"} />}
                  <ImageUploader
                  onImagesUpload={handleImagesUpload}
                  setLoading={setLoading}
                  loading={loading}
                  max={1}
                  />
              </Flex>
          </Box>

          <Flex justifyContent={"center"}>
            <PrimaryButton onClick={saveBrands} width="150px" mb="1rem" text="Save brand" loading={false} isDisabled={brandValues?.name === ''} />
          </Flex>

          {formValues?.brands?.map(({name, sku, image}) => (
            <Flex key={name} position={"relative"} mb=".7rem" border=".5px solid #D0D5DD" width={"100%"} alignItems={"center"} gap=".5rem" p=".5rem" borderRadius={"8px"}>
              <Box height={"30px"} bg="gray" width={"30px"} borderRadius={"4px"} backgroundImage={image} />
              <Box>
                <Text fontSize={".8rem"} fontWeight={"500"} mb=".05rem">{name}</Text>
                <Text fontSize={".7rem"}>{sku}</Text>
              </Box>
              <AiFillCloseCircle onClick={() => removeBrand(name)} color='#EB5017' fontSize={"1.4rem"} style={{position: "absolute", right: "1%", top: "30%", cursor: "pointer"}} />
            </Flex>
          ))}
          
          <PrimaryButton mt="1.5rem" type="submit" text="Submit" loading={loadingx} isDisabled={brandValues?.name !== ''} />
        </form>
    </Box>
  )
}

export default Mechanics

// http://localhost:3000/initiatives/create/3?iid=651de89f8c632633d7b041b4
// 65006dc4f39f4ecd03626b63