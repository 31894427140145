import React, { useState } from "react";
import AssignStore from "../../createInitiative/components/assignStore/assignStore";
import CustomTabs from "../../../../../components/customTabs/customTabs";
import StoreGroups from "./storeGroups/storeGroups";
import { InitiativeHeaderTab } from "components/initiativeHeaderTab/initiativeHeaderTab";
import classes from './iStore.module.css'

const IStores = ({ data, setRefetch, refetch }) => {
  const [allStores, setAllStores] = useState([]);
  const datax = [
    {
      title: "Stores",
      render: (
        <AssignStore
          altId={data?._id}
          setAllIStores={setAllStores}
          setRefetchx={setRefetch}
          refetchx={refetch}
          initiative={data}
        />
      ),
    },
    {
      title: "Store Groups",
      render: (
        <StoreGroups
          initiative={data}
          setRefetch={setRefetch}
          allStores={allStores}
        />
      ),
    },
  ];


  const assignor = {
    "Stores": <AssignStore
      altId={data?._id}
      setAllIStores={setAllStores}
      setRefetchx={setRefetch}
      initiative={data}
    />,
    "Store Groups": <StoreGroups
      initiative={data}
      setRefetch={setRefetch}
      allStores={allStores}
    />
  }

  const [selected, setSelected] = useState(Object.keys(assignor)[0])

  return (
    <div className={classes.container}>
      <InitiativeHeaderTab list={Object.keys(assignor)} selected={selected} setSelected={setSelected} />
      <div>
        {assignor[selected]}
      </div>
    </div>
  );
};

export default IStores;
