import {
  Box,
  Checkbox,
  Divider,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomDrawer from "../../../../../../components/customDrawer/customDrawer";
import SecondaryButton from "../../../../../../components/secondaryButton/secondarybutton";
import LineSkeleton from "../../../../../../components/lineSkeleton";
import EmptyState from "../../../../../../components/emptyState/emptyState";
import axiosInstance from "../../../../../../services/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomListInput from "../../../../../../components/customListInput/customListInput";
import PrimaryButton from "../../../../../../components/primaryButton/primarybutton";
import Illu from "./illu.svg";
import { BiDotsVerticalRounded } from "react-icons/bi";
import ExportCSV from "../../../../../../components/exportCsv";
import StoreSilo from "./storeSilo/storeSilo";
import { useDispatch, useSelector } from "react-redux";
import { buildResourceURL, buildResourceURL2 } from "../../../../../../utils/helper";
import CustomInput from "../../../../../../components/customInput/customInput";
import ServerExport from "../../../../../../components/serverExport/serverExport";
import CustomPagination from "../../../../../../components/customPagination/customPagination";
import { userActionTypes } from "../../../../../../redux/constants/userActionTypes";
import classes from "./store.module.css";
import StorePrizes from "./storePrizes/StorePrizes";
import CustomSelect from "components/customSelect/customSelect";
import Prizes from "pages/dashboard/initiatives/initiativeDetails/mechanics/prizes/prizes";

const AssignStore = ({ altId, setAllIStores, setRefetchx, refetchx, initiative }) => {
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const [triggerClose, setTriggerClose] = useState("");
  const [stores, setStores] = useState([]);
  const [total, setTotal] = useState("");
  const [storesx, setStoresx] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeesx, setEmployeesx] = useState([]);
  const currentUser = useSelector(({ userData }) => userData?.currentUser);
  const [binView, setBinView] = useState(false);
  const [searchVal, setSearchVal] = useState("");

  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedStores, setSelectedStores] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [prize, setPrize] = useState({});
  const [prizeFilter, setPrizeFilter] = useState('');
  const [quantity, setQuantity] = useState('')

  const [tempData, setTempData] = useState([]);
  const [addCache, setAddCache] = useState([]);
  const [isidx, setIsidx] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(500);

  const searchx = window.location.search;
  const params = new URLSearchParams(searchx);
  const iid = params.get("iid");

  const { iid: iidx } = useParams();

  const [refetch, setRefetch] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const [storeData, setStoreData] = useState(null);
  const [supervisorData, setSupervisorData] = useState(null);
  const [promoterData, setPromoterData] = useState(null);
  const [supervisorDatax, setSupervisorDatax] = useState(null);
  const [promoterDatax, setPromoterDatax] = useState(null);
  const [modalType, setModalType] = useState("");

  const [store, setStore] = useState(null);

  const queryParams = [
    {
      key: "isDeleted",
      value: binView === false ? "" : binView,
    },
    {
      key: "limit",
      value: limit,
    },
    {
      key: "page",
      value: page,
    },
    {
      key: "gamePrizeName",
      value: prizeFilter
    }
  ];

  const getInitiativeStores = async () => {
    setLoading1(true);
    try {
      const { data } = await axiosInstance.get(
        buildResourceURL(
          `initiatives/${iid || iidx}/initiative-stores`,
          queryParams
        )
      );
      setStores(data?.data);
      setAllIStores(data?.data);
      setTotal(data?.totalCount);
      dispatch({ type: userActionTypes.SAVE_STORES, payload: data?.data });
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading1(false);
    }
  };

  useEffect(() => {
    getInitiativeStores();
  }, [refetch, page, prizeFilter, refetchx]);

  const [storeCombo, setStoreCombo] = useState({
    storeId: "",
    promoterId: "",
    supervisorId: "",
  });

  const [storeCombox, setStoreCombox] = useState({
    promoterId: store?.promoter?.id,
    supervisorId: store?.supervisor?.id,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStoreCombo({ ...storeCombo, [name]: value });
  };
  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    setStoreCombox({ ...storeCombox, [name]: value });
  };

  const getStores = async () => {
    setLoading1(true);
    try {
      const { data } = await axiosInstance.get(
        `stores?search=${storeCombo.storeId}&isApproved=true`
      );
      setStoresx(data?.data);
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading1(false);
    }
  };

  useEffect(() => {
    getStores();
  }, [triggerClose, storeCombo?.storeId]);

  const setStoreDataFunc = (val) => {
    setStoreData(val);
    setStoreCombo({ ...storeCombo, storeId: val.title });
  };
  const setSupervisorDataFunc = (val) => {
    setSupervisorData(val);
    setStoreCombo({ ...storeCombo, supervisorId: val.title });
  };
  const setSupervisorDataFuncx = (val) => {
    setSupervisorDatax(val);
    setStoreCombox({ ...storeCombox, supervisorId: val.title });
  };

  const getSupervisors = async () => {
    setLoading2(true);
    try {
      const { data } = await axiosInstance.get(
        `employees?role=Supervisor&search=${storeCombo.supervisorId}`
      );
      setEmployees(data?.data);
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading2(false);
    }
  };

  useEffect(() => {
    getSupervisors();
  }, [triggerClose, storeCombo?.supervisorId]);

  const setPromoterDataFunc = (val) => {
    setPromoterData(val);
    setStoreCombo({ ...storeCombo, promoterId: val.title });
  };

  const setPromoterDataFuncx = (val) => {
    setPromoterDatax(val);
    setStoreCombox({ ...storeCombox, promoterId: val.title });
  };

  const getPromoters = async () => {
    setLoading3(true);
    try {
      const { data } = await axiosInstance.get(
        `employees?role=Promoter&search=${storeCombo.promoterId}`
      );
      setEmployeesx(data?.data);
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading3(false);
    }
  };

  useEffect(() => {
    getPromoters();
  }, [triggerClose, storeCombo?.promoterId]);

  const assignStore = async (sidx, suidx, pridx, isid) => {
    setIsidx(isid);
    setLoadingx(true);
    const datax =
      tempData?.length > 0
        ? {
          storeId: sidx,
          supervisorId: suidx,
          promoterId: pridx,
        }
        : {
          storeId: storeData?.id,
          supervisorId: supervisorData?.id,
          promoterId: promoterData?.id,
        };
    try {
      const { data } = await axiosInstance.post(
        `/initiatives/${iid || iidx}/initiative-stores`,
        datax
      );
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      if (tempData?.length === 0) {
        setTriggerClose("close");
        setRefetch(Math.random());
        setStoreCombo({
          storeId: "",
          promoterId: "",
          supervisorId: "",
        });
        setStoreData(null);
        setSupervisorData(null);
        setPromoterData(null);
      } else {
        setAddCache([...addCache, isid]);
      }
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingx(false);
    }
  };

  const updateTeam = async (id, prid, spid) => {
    setLoadingx(true);
    const datax = {
      supervisorId: supervisorData?.id || spid,
      promoterId: promoterData?.id || prid,
    };
    try {
      const { data } = await axiosInstance.patch(
        `/initiative-stores/${id}`,
        datax
      );
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      setTriggerClose("close");
      setRefetch(Math.random());
      setStoreCombo({
        promoterId: "",
        supervisorId: "",
      });
      setSupervisorData(null);
      setPromoterData(null);
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingx(false);
    }
  };

  const refinedData = stores?.map((i) => ({
    name: i?.store?.name,
    town: i?.store?.town,
    area: i?.store?.area,
    state: i?.store?.state,
    storePhone: i?.store?.phoneNumber,
    contactPhone: i?.store?.ownerPhoneNumber,
    supervisor: i?.supervisor?.firstName + " " + i?.supervisor?.lastName,
    promoter: i?.promoter?.firstName + " " + i?.promoter?.lastName,
    location: i?.store?.address,
  }));

  const headers = [
    {
      key: "name",
      name: "Name",
    },
    {
      key: "town",
      name: "Town",
    },
    {
      key: "area",
      name: "Area",
    },
    {
      key: "state",
      name: "State",
    },
    {
      key: "storePhone",
      name: "Store Phone",
    },
    {
      key: "contactPhone",
      name: "Contact Phone",
    },
    {
      key: "supervisor",
      name: "Supervisor",
    },
    {
      key: "promoter",
      name: "Promoter",
    },
    {
      key: "location",
      name: "Location",
    },
  ];

  const removeRow = (id) => {
    const newArr = tempData?.filter((i) => i?._id !== id);
    setTempData(newArr);
  };

  const addRow = async (sidx, suidx, pridx, isid) => {
    assignStore(sidx, suidx, pridx, isid);
  };

  const resetView = () => {
    setTempData([]);
    setRefetch(Math.random());
  };

  const handleModal = (type, user) => {
    setModalType(type);
    setSelectedUser(user);
    onOpen2();
  };

  const suspendEmployee = async () => {
    setLoadingx(true);
    try {
      const { data } = await axiosInstance.delete(
        `initiative-stores/${selectedUser?.id}/soft`
      );
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      setRefetch(Math.random());
      onClose2();
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingx(false);
    }
  };

  const restoreEmployee = async () => {
    setLoadingx(true);
    try {
      const { data } = await axiosInstance.patch(
        `initiative-stores/${selectedUser?.id}/restore`
      );
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      setRefetch(Math.random());
      onClose2();
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingx(false);
    }
  };

  const deleteEmployee = async () => {
    setLoadingx(true);
    try {
      const { data } = await axiosInstance.delete(
        `initiative-stores/${selectedUser?.id}`
      );
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      setRefetch(Math.random());
      onClose2();
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingx(false);
    }
  };

  const handleViewBin = () => {
    setBinView(!binView);
    setRefetch(Math.random());
  };

  const filteredStores =
    searchVal?.length > 0
      ? stores?.filter(
        (v) =>
          v?.store?.name?.toLowerCase()?.includes(searchVal?.toLowerCase()) ||
          v?.promoter?.firstName
            ?.toLowerCase()
            ?.includes(searchVal?.toLowerCase()) ||
          v?.supervisor?.firstName
            ?.toLowerCase()
            ?.includes(searchVal?.toLowerCase())
      )
      : stores;

  const handleSelect = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add item to the array if it doesn't already exist
      if (!selectedStores.includes(value)) {
        setSelectedStores([...selectedStores, value]);
      }
    } else {
      // Remove item from the array if it exists
      const filteredItems = selectedStores.filter((item) => item !== value);
      setSelectedStores(filteredItems);
    }
  };

  const selectAll = () => {
    if (selectedStores?.length === stores?.length) {
      setSelectedStores([]);
    } else {
      setSelectedStores(stores?.map((i) => i?._id));
    }
  };

  const createGroup = async () => {
    setLoadingx(true);
    const datax = {
      name: groupName,
      initiativeStoresIDs: selectedStores,
    };
    try {
      const { data } = await axiosInstance.post(
        `initiatives/${iid || iidx}/initiative-stores-filter-groups`,
        datax
      );
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      setIsSelecting(false);
      setSelectedStores([]);
      setRefetchx(Math.random());
      onClose2();
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingx(false);
    }
  };

  const handleAddPrize = (id) => {
    setSelectedStores([id, 'abcdefghijklmnopqrstuvwx'])
    setModalType('prize')
    onOpen2()
  }

  const assignPrizes = async () => {
    setLoadingx(true);
    const datax = {
      initiativeId: initiative?._id,
      name: JSON.parse(prize)?.name,
      rank: JSON.parse(prize)?.rank,
      entries: selectedStores?.map(x => ({
        initiativeStoreId: x,
        quantity: Number(quantity)
      }))
    };
    try {
      const { data } = await axiosInstance.post(
        '/initiative-stores/game-prizes/multiple-stores',
        datax
      );
      toast({
        description: data?.message,
        status: "success",
        position: "top",
      });
      // setIsSelecting(false);
      // setSelectedStores([]);
      setRefetchx(Math.random());
      // window.location.reload()
      // onClose2();
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top",
      });
    } finally {
      setLoadingx(false);
    }
  };

  const handleIsSelecting = () => {
    if (isSelecting === true) {
      setSelectedStores([]);
      setIsSelecting(false);
    } else {
      setIsSelecting(true);
    }
  };

  const prizeOptions = initiative?.gamePrizeOptions?.map(x => ({
    title: `${x?.name} - Rank ${x?.rank}`,
    value: JSON.stringify(x)
  }))

  const prizeFilterOptions = initiative?.gamePrizeOptions?.map(x => ({
    title: x?.name,
    value: x?.name
  })) || []


  return (
    <>
      <Flex mb="1rem" justifyContent={"flex-end"}>
        {currentUser?.role === 'Super Admin' &&
          <Menu width={"200px !important"}>
            <MenuButton>
              <BiDotsVerticalRounded style={{ cursor: "pointer" }} />
            </MenuButton>
            <MenuList>
              <Divider my=".2rem" />
              <MenuItem
                onClick={handleViewBin}
                fontWeight={"500"}
                fontSize={".85rem"}
              >
                {binView ? "Back to full list" : "View Bin"}
              </MenuItem>
              <Divider my=".2rem" />
            </MenuList>
          </Menu>}
      </Flex>

      <div className={classes.screenContainer}>
        <Text
          whiteSpace={"nowrap"}
          fontWeight={"500"}
          fontSize={"1.15rem"}
          color={"#27272A"}
        >
          Outlets{" "}
          <span
            style={{
              padding: ".1rem .3rem",
              borderRadius: "50%",
              backgroundColor: "#F9FAFB",
              border: "1px solid #EAECF0",
              fontSize: "12px",
              fontWeight: "500",
              color: "#344054",
            }}
          >
            {total}
          </span>
        </Text>
        <Flex
          alignItems={"center"}
          gap=".8rem"
          mt={'1rem'}
        >
          <CustomInput
            type={"text"}
            required
            value={searchVal}
            placeholder={`Search by outlet, promoter or supervisor`}
            onChange={(e) => setSearchVal(e.target.value)}
            mt="0"
            sType="search"
            py="1.19rem"
            width="310px"
          />
          <Box>
             <CustomSelect
            required
            options={prizeFilterOptions}
            placeholder={"Prize"}
            onChange={e => setPrizeFilter(e.target.value)}
            borderRadius="10px"
            mt="0"
            height="40px"
            width="100px"
          />
          </Box>
         
          <Flex gap="1rem" alignItems={"center"}>
            <CustomDrawer
              position={"right"}
              title={"Use existing outlet list"}
              triggerClose={triggerClose}
              setTriggerClose={setTriggerClose}
              toggleElement={
                <SecondaryButton
                  type="submit"
                  text={"Use existing outlet list"}
                  loading={false}
                />
              }
              content={
                <StoreSilo
                  tempData={tempData}
                  setTempData={setTempData}
                  setTriggerClose={setTriggerClose}
                />
              }
            />

            <SecondaryButton
              onClick={handleIsSelecting}
              text={
                isSelecting === true ? "Cancel Selection" : "Select Stores"
              }
              loading={false}
            />


          </Flex>

          <CustomDrawer
            position={"right"}
            title={"Assign team"}
            triggerClose={triggerClose}
            setTriggerClose={setTriggerClose}
            toggleElement={
              <SecondaryButton
                type="submit"
                text={"Assign new outlet"}
                icon="add"
                loading={false}
              />
            }
            content={
              <>
                <CustomListInput
                  type={"text"}
                  name="storeId"
                  value={storeCombo.storeId}
                  placeholder={"Search outlet"}
                  onChange={(e) => handleInputChange(e)}
                  list={storesx?.map((i) => ({
                    id: i?._id,
                    title: i?.name,
                    subtitle: i?.area + ", " + i?.state,
                  }))}
                  setData={setStoreDataFunc}
                  data={storeData}
                  loading={loading1}
                />
                <CustomListInput
                  type={"text"}
                  name="supervisorId"
                  value={storeCombo.supervisorId}
                  placeholder={"Search supervisors"}
                  onChange={(e) => handleInputChange(e)}
                  list={employees?.map((i) => ({
                    id: i?._id,
                    title: i?.firstName + " " + i?.lastName,
                    subtitle: i?.email,
                  }))}
                  setData={setSupervisorDataFunc}
                  data={supervisorData}
                  loading={loading2}
                />
                <CustomListInput
                  type={"text"}
                  name="promoterId"
                  value={storeCombo.promoterId}
                  placeholder={"Search promoters"}
                  onChange={(e) => handleInputChange(e)}
                  list={employeesx?.map((i) => ({
                    id: i?._id,
                    title: i?.firstName + " " + i?.lastName,
                    subtitle: i?.email,
                  }))}
                  setData={setPromoterDataFunc}
                  data={promoterData}
                  loading={loading3}
                />
                <PrimaryButton
                  onClick={assignStore}
                  position="absolute"
                  width="90%"
                  bottom="3%"
                  text="Submit"
                  loading={loadingx}
                />

                <Flex my="1rem" justifyContent={"center"}>
                  <Text fontWeight={"600"} opacity={".8"} fontSize={".9rem"}>
                    OR
                  </Text>
                </Flex>

                <Flex my="1rem" justifyContent={"center"}>
                  <CustomDrawer
                    position={"right"}
                    title={"Use existing outlet list"}
                    triggerClose={triggerClose}
                    setTriggerClose={setTriggerClose}
                    toggleElement={
                      <SecondaryButton
                        type="submit"
                        text={"Use existing outlet list"}
                        loading={false}
                      />
                    }
                    content={
                      <StoreSilo
                        tempData={tempData}
                        setTempData={setTempData}
                        setTriggerClose={setTriggerClose}
                      />
                    }
                  />
                </Flex>
              </>
            }
          />

          <ServerExport
            entity={"initiative-stores"}
            queries={buildResourceURL2("", [{ key: 'initiative', value: iid || iidx }])}
          />

        </Flex>

        <div className={classes.flex}>
          
        </div>

        {selectedStores?.length > 0 && (
          <Flex
            my="1.5rem"
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text>
              <strong>{selectedStores?.length}</strong> Selected
            </Text>
            <Flex alignItems={'center'} gap="1rem">
              <PrimaryButton
                onClick={() => handleModal("prize", { name: groupName })}
                width="250px"
                text="Assign Game Prizes"
                loading={false}
                isDisabled={selectedStores?.length < 1}
              />
              <PrimaryButton
                onClick={() => handleModal("group", { name: groupName })}
                width="250px"
                text="Create Store Group"
                loading={false}
                isDisabled={selectedStores?.length < 1}
              />
            </Flex>

          </Flex>
        )}


        <div className={classes.scrollable}>
          <table className={classes.table}>
            <thead>
              <tr>
                {isSelecting === true &&
                  <th><Checkbox
                    onChange={selectAll}
                    isChecked={selectedStores?.length === stores?.length}
                    colorScheme="green"
                  />
                  </th>
                }
                <th>Name</th>
                <th>Town</th>
                <th>LGA</th>
                <th>State</th>
                <th>Promoter</th>
                <th>Supervisor</th>
                {/* <th>Location</th> */}
                {prizeFilter && <th>Prize Quantity</th>}
              </tr>
            </thead>
            {tempData?.length < 1 && (
              <tbody>
                {loading1 ? (
                  <LineSkeleton width="100%" num={10} />
                ) : stores?.length < 1 ? (
                  <EmptyState mt="1.5rem" text="There are no outlet added yet" />
                ) : (
                  filteredStores?.map(
                    ({ _id: id, store, promoter, supervisor, isDeleted, gamePrizes }) => {
                      const data = { id, store, promoter, supervisor, isDeleted };
                      return (
                        <tr key={id} cursor={"pointer"}>
                          {isSelecting === true && (
                            <td>
                              <Checkbox
                                onChange={(e) => handleSelect(e)}
                                value={id}
                                isChecked={selectedStores?.includes(id)}
                                colorScheme="green"
                              />
                            </td>
                          )}
                          <td className={classes.tooltip_container}>
                            <p className={classes.item_text}> {store?.name}</p>
                            <p className={classes.tooltip}>{store?.name}</p>
                          </td>
                          <td
                            color={"#475467"}
                            fontSize={".9rem"}
                            textTransform={"capitalize"}
                          >
                            {store?.town}
                          </td>
                          <td
                            color={"#475467"}
                            fontSize={".9rem"}
                            textTransform={"capitalize"}
                          >
                            {store?.area}
                          </td>
                          <td
                            color={"#475467"}
                            fontSize={".9rem"}
                            textTransform={"capitalize"}
                          >
                            {store?.state}
                          </td>
                          <td
                            color={"#475467"}
                            fontSize={".9rem"}
                            textTransform={"capitalize"}
                          >
                            {promoter?.firstName + " " + promoter?.lastName}
                          </td>
                          <td
                            color={"#475467"}
                            fontSize={".9rem"}
                            textTransform={"capitalize"}
                          >
                            {supervisor?.firstName + " " + supervisor?.lastName}
                          </td>
                          {/* <td color={"#475467"} fontSize={".9rem"}>
                            {store?.address}
                          </td> */}
                          {prizeFilter &&
                            <td color={"#475467"} fontSize={".9rem"}>
                              {gamePrizes?.find(x => x?.name === prizeFilter)?.quantityAvailable}
                            </td>}
                          <td>
                            <Menu width={"200px !important"}>
                              <MenuButton>
                                <BiDotsVerticalRounded
                                  style={{ cursor: "pointer" }}
                                />
                              </MenuButton>
                              <MenuList>
                                <Divider my=".2rem" />
                                <MenuItem
                                  onClick={() => navigate(`store/${id}/front`)}
                                  fontWeight={"500"}
                                  fontSize={".85rem"}
                                >
                                  View
                                </MenuItem>
                                <Divider my=".2rem" />
                                <CustomDrawer
                                  position={"right"}
                                  title={`Update team for ${store?.name}`}
                                  triggerClose={triggerClose}
                                  setTriggerClose={setTriggerClose}
                                  toggleElement={
                                    <MenuItem
                                      onClick={() => setStore(data)}
                                      fontWeight={"500"}
                                      fontSize={".85rem"}
                                    >
                                      Update Team
                                    </MenuItem>
                                  }
                                  content={
                                    <>
                                      <>
                                        <Text fontSize={".85rem"} mb=".4rem">
                                          Supervisor:{" "}
                                          <strong>
                                            {storeCombo.supervisorId ||
                                              supervisor?.firstName +
                                              " " +
                                              supervisor?.lastName}
                                          </strong>
                                        </Text>
                                        <Text fontSize={".85rem"}>
                                          Promoter:{" "}
                                          <strong>
                                            {storeCombo.promoterId ||
                                              promoter?.firstName +
                                              " " +
                                              promoter?.lastName}
                                          </strong>
                                        </Text>
                                      </>
                                      <br />

                                      <CustomListInput
                                        type={"text"}
                                        name="supervisorId"
                                        value={storeCombo.supervisorId}
                                        placeholder={"Search supervisors"}
                                        onChange={(e) => handleInputChange(e)}
                                        list={employees?.map((i) => ({
                                          id: i?._id,
                                          title: i?.firstName + " " + i?.lastName,
                                          subtitle: i?.email,
                                        }))}
                                        setData={setSupervisorDataFunc}
                                        data={supervisorData}
                                        loading={loading2}
                                      />
                                      <CustomListInput
                                        type={"text"}
                                        name="promoterId"
                                        value={storeCombo.promoterId}
                                        placeholder={"Search promoters"}
                                        onChange={(e) => handleInputChange(e)}
                                        list={employeesx?.map((i) => ({
                                          id: i?._id,
                                          title: i?.firstName + " " + i?.lastName,
                                          subtitle: i?.email,
                                        }))}
                                        setData={setPromoterDataFunc}
                                        data={promoterData}
                                        loading={loading3}
                                      />
                                      <PrimaryButton
                                        onClick={() =>
                                          updateTeam(
                                            id,
                                            promoter?.id,
                                            supervisor?.id
                                          )
                                        }
                                        position="absolute"
                                        width="90%"
                                        bottom="3%"
                                        text="Update"
                                        loading={loadingx}
                                      />
                                    </>
                                  }
                                />
                                {/* {gamePrizes?.length > 0 ? */}
                                  <>
                                    <Divider my=".2rem" />
                                    <CustomDrawer
                                      position={"right"}
                                      title={`Update Prizes`}
                                      triggerClose={triggerClose}
                                      setTriggerClose={setTriggerClose}
                                      toggleElement={
                                        <MenuItem
                                          fontWeight={"500"}
                                          fontSize={".85rem"}
                                        >
                                          Update Prize
                                        </MenuItem>
                                      }
                                      content={
                                        <Prizes isid={id} sPrizes={gamePrizes} />
                                      }
                                    />

                                  </>
                                  {/* : */}
                                  <>
                                  <Divider my=".2rem" />
                                  <MenuItem
                                          fontWeight={"500"}
                                          fontSize={".85rem"}
                                          onClick={() => handleAddPrize(id)}
                                        >
                                          Add Prize
                                        </MenuItem>
                                  </>
                                  {/* } */}

                                {/* <Divider my=".2rem" />
                          {isDeleted ? 
                          <MenuItem onClick={() => handleModal('restore', {name, id})} fontWeight={"500"} fontSize={".85rem"}>Restore Initiative</MenuItem>
                          :
                          <MenuItem onClick={() => handleModal('suspend', {name, id})} fontWeight={"500"} fontSize={".85rem"}>Suspend Initiative</MenuItem>
                          } */}
                                <Divider my=".2rem" />
                                {currentUser?.role === "Super Admin" && (
                                  <>
                                    {isDeleted ? (
                                      <MenuItem
                                        onClick={() =>
                                          handleModal("restore", {
                                            name: store?.name,
                                            id,
                                          })
                                        }
                                        fontWeight={"500"}
                                        fontSize={".85rem"}
                                      >
                                        Restore Outlet
                                      </MenuItem>
                                    ) : (
                                      <MenuItem
                                        onClick={() =>
                                          handleModal("suspend", {
                                            name: store?.name,
                                            id,
                                          })
                                        }
                                        fontWeight={"500"}
                                        fontSize={".85rem"}
                                      >
                                        Suspend Outlet
                                      </MenuItem>
                                    )}
                                  </>
                                )}
                                {currentUser?.role === "Super Admin" && (
                                  <>
                                    <Divider my=".2rem" />
                                    <MenuItem
                                      onClick={() =>
                                        handleModal("delete", {
                                          name: store?.name,
                                          id,
                                        })
                                      }
                                      color={"crimson"}
                                      fontWeight={"500"}
                                      fontSize={".85rem"}
                                    >
                                      Delete Outlet
                                    </MenuItem>
                                  </>
                                )}
                                <Divider my=".2rem" />
                              </MenuList>
                            </Menu>
                          </td>
                        </tr>
                      );
                    }
                  )
                )}
              </tbody>
            )}
          </table>


          {tempData?.length > 0 && (
            <tbody>
              {loading1 ? (
                <LineSkeleton width="100%" num={10} />
              ) : tempData?.length < 1 ? (
                <EmptyState mt="1.5rem" text="There are no outlets added yet" />
              ) : (
                tempData?.map(({ _id: id, store, promoter, supervisor }) => {
                  const data = { _id: id, store, promoter, supervisor };
                  return (
                    <tr key={id} cursor={"pointer"}>

                      <td className={classes.tooltip_container}>
                        <p className={classes.item_text}> {store?.name}</p>
                        <p className={classes.tooltip}>{store?.name}</p>
                      </td>

                      <td
                        color={"#475467"}
                        fontSize={".9rem"}
                        textTransform={"capitalize"}
                      >
                        {store?.town}
                      </td>
                      <td
                        color={"#475467"}
                        fontSize={".9rem"}
                        textTransform={"capitalize"}
                      >
                        {store?.area}
                      </td>
                      <td
                        color={"#475467"}
                        fontSize={".9rem"}
                        textTransform={"capitalize"}
                      >
                        {store?.state}
                      </td>
                      <td
                        color={"#475467"}
                        fontSize={".9rem"}
                        textTransform={"capitalize"}
                      >
                        {promoter?.firstName + " " + promoter?.lastName}
                      </td>
                      <td
                        color={"#475467"}
                        fontSize={".9rem"}
                        textTransform={"capitalize"}
                      >
                        {supervisor?.firstName + " " + supervisor?.lastName}
                      </td>
                      <td color={"#475467"} fontSize={".9rem"}>
                        {store?.address}
                      </td>
                      <td>
                        {addCache?.includes(id) ? (
                          <Text
                            color="#12B76A"
                            mb=".2rem"
                            fontSize={".9rem"}
                            fontWeight={"500"}
                            textTransform={"capitalize"}
                          >
                            Added
                          </Text>
                        ) : (
                          <Flex alignItems={"center"} gap="1rem">
                            {/* <Text onClick={() => {}} textDecoration={"underline"} mb=".2rem" fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>Edit</Text> */}
                            <Text
                              onClick={() => removeRow(id)}
                              textDecoration={"underline"}
                              mb=".2rem"
                              color={"crimson"}
                              fontSize={".9rem"}
                              fontWeight={"500"}
                              textTransform={"capitalize"}
                            >
                              Remove
                            </Text>
                            <PrimaryButton
                              onClick={() =>
                                addRow(
                                  store?._id,
                                  supervisor?._id,
                                  promoter?._id,
                                  id
                                )
                              }
                              text="Add"
                              p="1rem"
                              bg=""
                              loading={isidx === id && loadingx}
                            />
                          </Flex>
                        )}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          )}

        </div>


        {search?.length > 0 && (
          <PrimaryButton
            onClick={onOpen}
            mt="4rem"
            text="Finish"
            loading={false}
            isDisabled={stores?.length < 1}
          />
        )}
        {tempData?.length > 0 && (
          <PrimaryButton
            onClick={resetView}
            mt="4rem"
            text="Finish"
            loading={false}
          />
        )}

        <Modal
          isCentered
          motionPreset="slideInBottom"
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay backdropFilter={"blur(10px)"} bg="blackAlpha.600" />
          <ModalContent mx=".5rem">
            <ModalHeader
              fontSize={"1rem"}
              textTransform={"capitalize"}
            ></ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Image src={Illu} width={"auto"} mx="auto" />
              <Text
                mb=".3rem"
                textAlign={"center"}
                fontSize={"1.2rem"}
                fontWeight={"700"}
              >
                That's a wrap 🎉
              </Text>
              <Text
                mb="1rem"
                textAlign={"center"}
                fontSize={".9rem"}
                color="#475467"
                fontWeight={"500"}
              >
                You have successfully created an initiative
              </Text>
              <PrimaryButton
                onClick={() => navigate(`/initiatives/${iid}`)}
                mb="1rem"
                py="1.35rem"
                text="See Initiative"
              />
            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal
          isCentered
          motionPreset="slideInBottom"
          isOpen={isOpen2}
          onClose={onClose2}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              fontSize={"1rem"}
              textTransform={"capitalize"}
            >{`${modalType} Initiative Store`}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {modalType === "prize" && (
                <>
                  <Text
                    textAlign={"center"}
                    color={"#475467"}
                    fontWeight={"500"}
                  >
                    Assign prizes to <strong>{selectedStores?.length}</strong> stores
                  </Text>
                  <br />The higher the rank, the more valuable the prize item
                  <CustomSelect
                    required
                    label={"Prize"}
                    options={prizeOptions}
                    placeholder={"Prize"}
                    onChange={e => setPrize(e.target.value)}
                    borderRadius="10px"
                    mt="1rem"
                  />
                  <CustomInput
                    onChange={(e) => setQuantity(e.target.value)}
                    label={"Quantity (assuming same quantity for all selected stores)"}
                    placeholder={"Quantity"}
                    type={"tel"}
                  />
                  <Flex justifyContent={"center"} my="1.3rem">
                    <PrimaryButton
                      onClick={assignPrizes}
                      text="Add"
                      width="150px"
                      padding="1rem !important"
                      loading={loadingx}
                    />
                  </Flex>
                </>
              )}
              {modalType === "group" && (
                <>
                  <Text
                    textAlign={"center"}
                    color={"#475467"}
                    fontWeight={"500"}
                  >
                    Add <strong>{selectedStores?.length}</strong> stores to a
                    group?
                  </Text>
                  <CustomInput
                    onChange={(e) => setGroupName(e.target.value)}
                    label={"Group Name"}
                    placeholder={"Group Name"}
                    type={"text"}
                  />
                  <Flex justifyContent={"center"} my="1.3rem">
                    <PrimaryButton
                      onClick={createGroup}
                      text="Create"
                      width="150px"
                      padding="1rem !important"
                      loading={loadingx}
                    />
                  </Flex>
                </>
              )}
              {modalType === "suspend" && (
                <>
                  <Text
                    textAlign={"center"}
                    color={"#475467"}
                    fontWeight={"500"}
                  >
                    Confirm suspension of {selectedUser?.name}?
                  </Text>
                  <Flex justifyContent={"center"} my="1.3rem">
                    <PrimaryButton
                      onClick={suspendEmployee}
                      text="Suspend"
                      width="150px"
                      padding="1rem !important"
                      loading={loadingx}
                    />
                  </Flex>
                </>
              )}
              {modalType === "restore" && (
                <>
                  <Text
                    textAlign={"center"}
                    color={"#475467"}
                    fontWeight={"500"}
                  >
                    Confirm restore of {selectedUser?.name}?
                  </Text>
                  <Flex justifyContent={"center"} my="1.3rem">
                    <PrimaryButton
                      onClick={restoreEmployee}
                      text="Restore"
                      width="150px"
                      padding="1rem !important"
                      loading={loadingx}
                    />
                  </Flex>
                </>
              )}
              {modalType === "delete" && (
                <>
                  <Text
                    textAlign={"center"}
                    color={"#475467"}
                    fontWeight={"500"}
                  >
                    Confirm deletion of {selectedUser?.name}?
                  </Text>
                  <Flex justifyContent={"center"} my="1.3rem">
                    <PrimaryButton
                      onClick={deleteEmployee}
                      text="Delete"
                      width="150px"
                      bg="crimson !important"
                      padding="1rem !important"
                      loading={loadingx}
                    />
                  </Flex>
                </>
              )}
              <Text
                pb="1rem"
                textAlign={"center"}
                fontSize={".9rem"}
                fontWeight={"500"}
                cursor={"pointer"}
                onClick={onClose2}
              >
                Cancel
              </Text>
            </ModalBody>
          </ModalContent>
        </Modal>

        <CustomPagination
          page={page}
          setPage={setPage}
          pageSize={limit}
          totalElements={total}
        />
      </div>
    </>
  );
};

export default AssignStore;
