import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../services/api'
import { Box, Divider, Flex, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure, useToast } from '@chakra-ui/react'
import {BiDotsVerticalRounded} from 'react-icons/bi'
import CustomDrawer from '../../../components/customDrawer/customDrawer'
import SecondaryButton from '../../../components/secondaryButton/secondarybutton'
import CustomInput from '../../../components/customInput/customInput'
import PrimaryButton from '../../../components/primaryButton/primarybutton'
import SpinLoader from '../../../components/spinLoader/spinLoader'
import EmptyState from '../../../components/emptyState/emptyState'
import ImageUploader from '../../../components/imageUploader/imageUploader'

const Rewards = () => {
  const [games, setGames] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingx, setLoadingx] = useState(false)
  const [triggerClose, setTriggerClose] = useState("")
  const [name, setName] = useState("")
  const [namex, setNamex] = useState("")
  const [refetch, setRefetch] = useState("");
  const [modalType, setModalType] = useState("");
  const [loadingi, setLoadingi] = useState(false);

  const [imageURL, setImageURL] = useState('');
  const [imageURLx, setImageURLx] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast()

  const handleImagesUpload = (images) => {
    setImageURL(images[0])
  };

  const handleImagesUploadx = (images) => {
    setImageURLx(images[0])
  };

  const getGames = async() => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.get('/game-categories')
      setGames(data?.data)
    } catch(error) {
        console.errror(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
  }

  useEffect(() => {
    getGames()
  }, [refetch])

  const createGame = async(e) => {
    e.preventDefault()
    setLoadingx(true)
    const datax = {
      name,
      imageURL
    }
    try {
      const { data } = await axiosInstance.post('/game-categories', datax)
      setTriggerClose('close' + (Math.random()))
      setRefetch(Math.random())
      toast({
        description: data?.message,
        status: "success",
        position: "top"
      });
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
        });
    } finally {
        setLoadingx(false)
    }
  }

  const updateGame = async(e, id) => {
    e.preventDefault()
    setLoadingx(true)
    const datax = {
      name: namex !== "" ? namex?.name : namex,
      imageURL: imageURLx
    }
    try {
      const { data } = await axiosInstance.patch(`/game-categories/${id}`, datax)
      setTriggerClose('close' + (Math.random()))
      setRefetch(Math.random())
      toast({
        description: data?.message,
        status: "success",
        position: "top"
      });
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
        });
    } finally {
        setLoadingx(false)
    }
  }

  const deleteGame = async(id) => {
    setLoadingx(true)
    try {
        const { data } = await axiosInstance.delete(`/game-categories/${id}`)
        toast({
        description: data?.message,
        status: "success",
        position: "top"
        });
        setRefetch(Math.random())
        onClose()
    } catch(error) {
        toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
        });
    } finally {
        setLoadingx(false)
    }
  }

  const handleModal = (type, user) => {
    setModalType(type)
    setNamex(user)
    onOpen()
}

const handleSet = (name, id, imageURL) => {
  setNamex({name, id})
  setImageURLx(imageURL)
}

  return (
    <>
    <Flex alignItems={"center"} gap=".8rem" justifyContent={"space-between"}>
            
      <Flex gap="1.5rem" alignItems={"center"}>
        <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>Draws <span style={{padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054"}}>{games?.length}</span></Text>
      </Flex>

      <Flex alignItems={"center"} gap="1rem">
          <CustomDrawer
            position={"right"}
            title={'Create Draw'}
            triggerClose={triggerClose}
            toggleElement={
                <SecondaryButton type="submit" text={`Create Draw`} icon="add" loading={false}  />
            }
            content={
                <form onSubmit={createGame}>
                  <CustomInput
                    type={"text"}
                    required
                    placeholder={"Name"}
                    onChange={e => setName(e.target.value)}
                    mb="1rem"
                  />
                   <Flex mb="1.8rem" gap=".5rem">
                        {imageURL !== '' && <Box backgroundImage={imageURL} backgroundSize={"cover"} height={"100px"} width={"120px"} borderRadius={"4px"} backgroundColor={"lightgray"} />}
                        <ImageUploader
                        onImagesUpload={handleImagesUpload}
                        setLoading={setLoadingi}
                        loading={loadingi}
                        max={1}
                        src="camera"
                        />
                    </Flex>
                  <PrimaryButton mt="3rem" type="submit" text={`Create Draw`} loading={loadingx} />
                </form>
            }
          />
      </Flex>

    </Flex>
    
    {loading ? 
    <SpinLoader />
    :
    games?.length < 1 ?
    <EmptyState text={"No Draws added yet"} />
    :
    <Box bg="#FFF" mt=".7rem" p="1.5rem 1rem" borderRadius={"12px"}>
      <Flex gap={"1rem"} flexWrap={"wrap"}>
        {games?.map(({name, _id: id, imageURL}) => (
          <Flex key={name} alignItems={"center"} gap={"1rem"} flexWrap={"wrap"} borderRadius={"6px"} border={".5px solid lightgray"} padding={".6rem .3rem"} bg={"#F5F5F5"}>
            <Box width={"70px"} height={"60px"} borderRadius={"6px"} backgroundColor={"lightgray"} backgroundImage={imageURL} backgroundSize={"cover"} backgroundPosition={"center"} />
            <Text textTransform={"capitalize"} fontSize={".87rem"} fontWeight={"500"}>{name}</Text>
            <Menu width={"200px !important"}>
                <MenuButton>
                    <BiDotsVerticalRounded style={{cursor: "pointer"}} />
                </MenuButton>
                <MenuList>
                    <Divider my=".2rem" />
                    <CustomDrawer
                      position={"right"}
                      title={'Update Draw'}
                      triggerClose={triggerClose}
                      toggleElement={
                        <MenuItem onClick={() => handleSet(name, id, imageURL)} fontWeight={"500"} fontSize={".85rem"}>Update Draw</MenuItem>
                      }
                      content={
                          <form onSubmit={(e) => updateGame(e, id)}>
                            <CustomInput
                              type={"text"}
                              required
                              placeholder={"Name"}
                              onChange={e => setNamex(e.target.value)}
                              value={namex?.name}
                              mb="1rem"
                            />
                            <Flex mb="1.8rem" gap=".5rem">
                                {imageURLx !== '' && <Box backgroundImage={imageURLx} backgroundSize={"cover"} height={"100px"} width={"120px"} borderRadius={"4px"} backgroundColor={"lightgray"} />}
                                <ImageUploader
                                onImagesUpload={handleImagesUploadx}
                                setLoading={setLoadingi}
                                loading={loadingi}
                                max={1}
                                src="camera"
                                />
                            </Flex>
                            <PrimaryButton mt="3rem" type="submit" text={`Update Draw`} loading={loadingx} />
                          </form>
                      }
                    />
                    <Divider my=".2rem" />
                    <MenuItem onClick={() => handleModal('delete', {name, id})}  fontWeight={"500"} color="crimson" fontSize={".85rem"}>Delete Draw</MenuItem>
                </MenuList>
            </Menu>
          </Flex>
        ))}
      </Flex>
    </Box>
    }


    <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{`${modalType} Game`}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {modalType === 'delete' &&
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm deletion of {namex?.name}?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                            <PrimaryButton onClick={() => deleteGame(namex?.id)} text="Delete" width="150px" bg="crimson !important" padding="1rem !important" loading={loadingx} />
                        </Flex>
                    </>
                    }
                    <Text pb="1rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Cancel</Text>
                </ModalBody>
            </ModalContent>
        </Modal>

    </>
  )
}

export default Rewards