import { Box, Checkbox, Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import CustomDrawer from '../../../../../components/customDrawer/customDrawer';
import SecondaryButton from '../../../../../components/secondaryButton/secondarybutton';
import LineSkeleton from '../../../../../components/lineSkeleton';
import EmptyState from '../../../../../components/emptyState/emptyState';
import { truncateText } from '../../../../../utils/helper';
import axiosInstance from '../../../../../services/api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PrimaryButton from '../../../../../components/primaryButton/primarybutton';
import Illu from './illu.svg'
import { HiArrowNarrowRight } from 'react-icons/hi';
import moment from 'moment';
import CustomInput from '../../../../../components/customInput/customInput';
import CustomTextArea from '../../../../../components/customTextArea/customTextArea';
import ImageUploader from '../../../../../components/imageUploader/imageUploader';
import ExportCSV from '../../../../../components/exportCsv';
import { Loader } from 'components/Loader/loader';
import classes from '../rewards/reward.module.css'
import { InitiativeEmptyState } from 'components/initiativeEmptyState/initiativeEmptyState';

const Tasks = ({altId}) => {
  const [loading, setLoading] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const [loadinga, setLoadinga] = useState(false);
  const [triggerClose, setTriggerClose] = useState("");
  const [tasks, setTasks] = useState([]);
  const [stores, setStores] = useState([])
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();

  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [searchVal, setSearchVal] = useState('')

  const handleImagesUpload = (images) => {
    setUploadedImages(images);
  };


  const [taskData, setTaskData] = useState({
    name: '',
    dueDate: '',
    dueTime: '',
    comment: ''
  })
  
  const {search} = useLocation()
  const {iid} = useParams()
  const navigate = useNavigate()
  const toast = useToast()

  const getTasks = async() => {
    setLoading(true)
    try {
        const { data } = await axiosInstance.get(`tasks/initiative-tasks/${iid}`)
        setTasks(data?.data)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
}

    useEffect(() => {
        getTasks()
    }, [triggerClose, loadinga])

    const getInitiativeStores = async() => {
      setLoading(true)
      try {
          const { data } = await axiosInstance.get(`initiatives/${iid}/initiative-stores?limit=350`)
          setStores(data?.data)
      } catch(error) {
          console.error(error?.response?.data?.message)
      } finally {
          setLoading(false)
      }
  }
  
      useEffect(() => {
          getInitiativeStores()
      }, [])



      const assignStore = async() => {
        const newData = {
          ...taskData,
          uploadURLs: uploadedImages
        }
        onOpen2()
      }

      const handleSelect = (event) => {
        const value = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
          // Add item to the array if it doesn't already exist
          if (!selectedStores.includes(value)) {
            setSelectedStores([...selectedStores, value]);
          }
        } else {
          // Remove item from the array if it exists
          const filteredItems = selectedStores.filter((item) => item !== value);
          setSelectedStores(filteredItems);
        }
      }

      const selectAll = () => {
        if(selectedStores?.length === stores?.length) {
          setSelectedStores([])
        } else {
          setSelectedStores(stores?.map(i => i?._id))
        }
      }

      const createTask = async() => {
        setLoadinga(true)
        const datax = uploadedImages?.length > 0 ?
        {
          ...taskData,
          initiativeId: iid,
          initiativeStoreIDs: selectedStores,
          uploadURLs: uploadedImages
        }
        :
        {
          ...taskData,
          initiativeId: iid,
          initiativeStoreIDs: selectedStores,
        }
        try {
          const {data} = await axiosInstance.post('tasks', datax)
          toast({
            description: data?.message,
            status: "success",
            position: "top"
          });
          setTriggerClose('close')
          setTaskData({
            name: '',
            dueDate: '',
            dueTime: '',
            comment: ''
          })
          setUploadedImages([])
          setSearchVal('')
          onClose2()
          onOpen()
        } catch(error) {
          toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top"
          });
        } finally {
          setLoadinga(false)
        }
      }


      const headers = [
        {
          key: "name",
          name: "Name"
        },
        {
          key: "initiative",
          name: "Initiative ID"
        },
        {
          key: "dueDate",
          name: "Due Date"
        },
        {
          key: "dueTime",
          name: "Due Time"
        },
        {
          key: "comment",
          name: "Comment"
        },
        {
          key: "uploadURLs",
          name: "Attachments"
        }
      ]

      const filteredStores = searchVal?.length > 0 ? stores?.filter(v => (v?.store?.name?.toLowerCase()?.includes(searchVal?.toLowerCase())) || (v?.promoter?.firstName?.toLowerCase()?.includes(searchVal?.toLowerCase())) || (v?.supervisor?.firstName?.toLowerCase()?.includes(searchVal?.toLowerCase()))) : stores

  return (
    <div className={classes.screenContainer}>
        <Flex alignItems={"center"} gap=".8rem" justifyContent={"space-between"}>
            <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>Tasks <span style={{padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054"}}>{tasks?.length}</span></Text>
            <Flex gap={"1rem"} alignItems={"center"}>
              <ExportCSV headers={headers} items={tasks} />
              <CustomDrawer
              position={"right"}
              title={"Create new task"}
              triggerClose={triggerClose}
              setTriggerClose={setTriggerClose}
              toggleElement={
                  <SecondaryButton type="submit" text={"Create new task"} icon="add" loading={false}  />
              }
              content={
                  <>
                    <CustomInput
                    type={"tel"}
                    required
                    placeholder={"Task Name"}
                    onChange={e => setTaskData({...taskData, name: e.target.value})}
                    />
                    <CustomInput
                    type={"date"}
                    required
                    placeholder={"Task Name"}
                    onChange={e => setTaskData({...taskData, dueDate: e.target.value})}
                    />
                    <CustomInput
                    type={"time"}
                    required
                    placeholder={"Task Name"}
                    onChange={e => setTaskData({...taskData, dueTime: e.target.value})}
                    />
                    <CustomTextArea
                    required
                    rows="5"
                    placeholder={"Comments/Instructions"}
                    onChange={e => setTaskData({...taskData, comment: e.target.value})}
                    mt='1rem'
                    mb="0"
                    />
                    <Box mt="1rem">
                      <ImageUploader
                          onImagesUpload={handleImagesUpload}
                          setLoading={setLoadingx}
                          loading={loadingx}
                          max={10}
                      />
                      <Flex gap=".5rem" mt=".7rem">
                          {uploadedImages.map((imageUrl) => (
                              <Box key={imageUrl} backgroundImage={imageUrl} backgroundSize={"contain"} height={"37px"} width={"40px"} borderRadius={"4px"} backgroundColor={"lightgray"}></Box>
                          ))}
                      </Flex>
                    </Box>
                    <PrimaryButton onClick={assignStore} position="absolute" width="90%" bottom="3%" text="Continue" loading={false} />
                  </>
              }
              />
            </Flex>
        </Flex>
      
        {search?.length > 0 && <PrimaryButton onClick={onOpen} mt="4rem" text="Finish" loading={false} isDisabled={tasks?.length < 1} />}

        {loading ? (
        <div className={classes.loader_con}>
          <Loader />
        </div>
      ) : (
        <>
          {tasks.length ? (
            <div className={classes.table_container}>
              <div className={classes.titleAndInstructionContainer}/>

            
              <div className={classes.scrollable}>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Date Assigned</th>
                      <th>Due Date</th>
                      <th>Due Time</th>
                      <th>Comment</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tasks.map((item, index) => {
                      return (
                        <tr onClick={()=>  navigate(`tasks/${item?._id}`)} key={index}>
                          <td className={classes.tooltip_container}>
                            <p className={classes.item_text}>{item.name}</p>
                            <p className={classes.tooltip}>{item.name}</p>
                          </td>

                          <td className={classes.tooltip_container}>
                            <p className={classes.item_text}>{item.dueTime}</p>
                            <p className={classes.tooltip}>{item.dueTime}</p>
                          </td>

                          
                          <td className={classes.tooltip_container}>
                            <p className={classes.item_text}>
                              {moment(item?.createdAt).calendar()}
                            </p>
                            <p className={classes.tooltip}>
                            {moment(item?.createdAt).calendar()}
                            </p>
                          </td>
                         
                          <td className={classes.tooltip_container}>
                            <p className={classes.item_text}>{item.dueTime}</p>
                            <p className={classes.tooltip}>{item.dueTime}</p>
                          </td>

                          <td className={classes.tooltip_container}>
                            <p className={classes.item_text}>{item.comment}</p>
                            <p className={classes.tooltip}>{item.comment}</p>
                          </td>

                        
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              
            </div>
          ) : (
            <InitiativeEmptyState searchValues={null} name={"rewards"} />
          )}
        </>
      )}

        <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay backdropFilter={"blur(10px)"} bg="blackAlpha.600" />
            <ModalContent mx=".5rem">
                <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}></ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Image src={Illu} width={"auto"} mx="auto" />
                    <Text mb=".3rem" textAlign={"center"} fontSize={"1.2rem"} fontWeight={"700"}>All Done!</Text>
                    <Text mb="1rem" textAlign={"center"} fontSize={".9rem"} color="#475467" fontWeight={"500"}>You have successfully created a task</Text>
                    <PrimaryButton onClick={onClose} mb="1rem" py="1.35rem" text="See Task" />
                </ModalBody>
            </ModalContent>
        </Modal>

        <Modal isCentered motionPreset='slideInBottom' size={"5xl"} isOpen={isOpen2} onClose={onClose2}>
            <ModalOverlay backdropFilter={"blur(10px)"} bg="blackAlpha.600" />
            <ModalContent mx=".5rem" my="2rem" maxH="90vh" overflowY={'scroll'} py="3rem">
                <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}></ModalHeader>
                <ModalCloseButton border="2px solid #292D32" borderRadius={"50%"} transform={"scale(.8) translateY(1rem)"} fontWeight={"700"} />
                <ModalBody>
                  <Flex alignItems={"center"} mb="1rem" gap=".8rem" justifyContent={"space-between"}>
                  <Flex gap={".5rem"} alignItems={"center"}>
                    <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>Assign to a store.</Text>
                    <CustomInput
                      type={"text"}
                      required
                      placeholder={"Search Store"}
                      onChange={e => setSearchVal(e.target.value)}
                      value={searchVal}
                      py="1.2rem"
                      mt="0"
                    />
                  </Flex>
                    
                    <Flex gap={".5rem"} alignItems={"center"}>
                      <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={".9rem"} color={"#27272A"}>{selectedStores?.length} selected</Text>
                      <SecondaryButton onClick={selectAll} text={selectedStores?.length === stores?.length ? "Unselect all stores" : "Select all stores"} loading={false}  />
                    </Flex>
                  </Flex>

                  <Table mt="1.2rem" borderRadius={"12px"} overflow={"hidden"}>
                      <Thead bg="#F9FAFB" border="1px solid #EAECF0">
                          <Tr>
                              <Th whiteSpace={"nowrap"} textTransform={"capitalize !important"} color={"#475467"}></Th>
                              <Th textTransform={"capitalize !important"} color={"#475467"}>Name</Th>
                              <Th textTransform={"capitalize !important"} color={"#475467"}>Town</Th>
                              <Th textTransform={"capitalize !important"} color={"#475467"}>LGA</Th>
                              <Th textTransform={"capitalize !important"} color={"#475467"}>Promoter</Th>
                              <Th textTransform={"capitalize !important"} color={"#475467"}>Supervisor</Th>
                              <Th textTransform={"capitalize !important"} color={"#475467"}>Location</Th>
                          </Tr>
                      </Thead>
                      
                      <Tbody>
                      {filteredStores?.length < 1 ?
                      <EmptyState mt="1.5rem" text="There are no stores added yet" />
                      :
                      filteredStores?.map((({_id: id, store, supervisor, promoter}) => {
                          return (
                              <Tr key={id} cursor={"pointer"}>
                                  <Td>
                                    <Checkbox onChange={e => handleSelect(e)} value={id} isChecked={selectedStores?.includes(id)} colorScheme='green' />
                                  </Td>
                                  <Td>
                                      <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{store?.name}</Text>
                                  </Td>
                                  <Td color={"#475467"} fontSize={".9rem"}>{store?.town}</Td>
                                  <Td color={"#475467"} fontSize={".9rem"}>{store?.area}</Td>
                                  <Td color={"#475467"} fontSize={".9rem"}>{promoter?.firstName + " " + promoter?.lastName}</Td>
                                  <Td color={"#475467"} fontSize={".9rem"}>{supervisor?.firstName + " " + supervisor?.lastName}</Td>
                                  <Td color={"#475467"} fontSize={".9rem"}>{truncateText(store?.address, 15)}</Td>
                              </Tr>
                          )
                      }))
                      }
                      </Tbody>
                  </Table>
                  <PrimaryButton onClick={createTask} mt="2rem" text="Assign task to selected store(s)" loading={loadinga} />
                </ModalBody>
            </ModalContent>
        </Modal>
    </div>
  )
}

export default Tasks;