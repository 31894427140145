import { Box, Divider, Flex, Menu, MenuButton, MenuItem, MenuList, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
import CustomDrawer from '../../../../components/customDrawer/customDrawer'
import axiosInstance from '../../../../services/api'
import SecondaryButton from '../../../../components/secondaryButton/secondarybutton'
import EmptyState from '../../../../components/emptyState/emptyState'
import PrimaryButton from '../../../../components/primaryButton/primarybutton'
import LineSkeleton from '../../../../components/lineSkeleton'
import { buildResourceURL, buildResourceURL2, truncateText } from '../../../../utils/helper'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import CustomPagination from '../../../../components/customPagination/customPagination'
import ServerExport from '../../../../components/serverExport/serverExport'
import CreateSubUser from '../createSubuser/createSubuser'
import EditSubuser from '../editSubuser/editSubuser'
import AssignInitiative from '../assignInitiative/assignInitiative'

const SubusersTable = ({clientId}) => {
    const [clients, setClients] = useState([]);
    const [totalClients, setTotalClients] = useState("");
    const [loading, setLoading] = useState(true);
    const [loadingc, setLoadingc] = useState(false);
    const [loadingx, setLoadingx] = useState(false);
    const [modalType, setModalType] = useState("");
    const [selectedUser, setSelectedUser] = useState(null)
    const [refetch, setRefetch] = useState("");
    const [binView, setBinView] = useState(false);

    const [triggerClose, setTriggerClose] = useState("");
    const currentUser = useSelector(({userData})=>  userData?.currentUser);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast()
    const navigate = useNavigate()

    

    const [page, setPage] = useState(1)
    const limit = 100

    const queryParams = [
        {
            key: 'isDeleted',
            value: binView === false ? "" : binView
        },
        {
            key: 'page',
            value: page
        },
        {
            key: 'limit',
            value: limit
        },
        {
            key: 'mainUser',
            value: clientId
        },
    ]

    const queryParamsx = [
        {
            key: 'isDeleted',
            value: binView === false ? "" : binView
        },
        {
            key: 'mainUser',
            value: currentUser?.role === 'Client' ? currentUser?._id : clientId
        },
    ]

     const getClients = async() => {
    try {
        const { data } = await axiosInstance.get(buildResourceURL('subusers', queryParams))
        setClients(data?.data)
        setTotalClients(data?.totalCount)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
  }

  useEffect(() => {
    getClients()
  }, [triggerClose, refetch, page])

    const handleModal = (type, user) => {
        setModalType(type)
        setSelectedUser(user)
        onOpen()
    }

    const approveEmployee = async() => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.patch(`clients/${selectedUser?.id}/approve`)
            toast({
            description: data?.message,
            status: "success",
            position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch(error) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }


    const suspendEmployee = async() => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.delete(`subusers/${selectedUser?.id}/soft`)
            toast({
            description: data?.message,
            status: "success",
            position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch(error) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }

    const restoreEmployee = async() => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.patch(`subusers/${selectedUser?.id}/restore`)
            toast({
            description: data?.message,
            status: "success",
            position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch(error) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }

    const deleteEmployee = async() => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.delete(`subusers/${selectedUser?.id}`)
            toast({
            description: data?.message,
            status: "success",
            position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch(error) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }

    const handleViewBin = () => {
        setBinView(!binView)
        setRefetch(Math.random())
    }

    const headers = [
        {
          key: '_id',
          name: 'ID',
        },
        {
          key: 'companyName',
          name: 'Company Name',
        },
        {
          key: 'email',
          name: 'Email',
        },
        {
          key: 'phoneNumber',
          name: 'Phone Number',
        },
        {
          key: 'role',
          name: 'Role',
        },
        {
          key: 'isDeleted',
          name: 'Deleted?',
        },
        {
          key: 'createdAt',
          name: 'Joined',
        },
      ]

  return (
    <>
    <Flex mb="1rem" justify={"flex-end"}>
        <Menu width={"200px !important"}>
            <MenuButton>
                <BiDotsVerticalRounded style={{cursor: "pointer"}} />
            </MenuButton>
            <MenuList>
                <Divider my=".2rem" />
                <MenuItem onClick={handleViewBin} fontWeight={"500"} fontSize={".85rem"}>{binView ? "Back to full list" : "View Bin"}</MenuItem>
                <Divider my=".2rem" />
            </MenuList>
        </Menu>
        </Flex>

        <Flex alignItems={"center"} gap=".8rem" justifyContent={"space-between"}>
            <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>Sub Users <span style={{padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054"}}>{totalClients}</span></Text>

            <Flex alignItems={"center"} gap="1rem">
                {/* <ExportCSV headers={headers} items={clients} /> */}
                <ServerExport entity={"subusers"} queries={buildResourceURL2('', queryParamsx)} />

                {currentUser?.role === 'Client' &&
                <CustomDrawer
                position={"right"}
                title={"Add Sub User"}
                triggerClose={triggerClose}
                setTriggerClose={setTriggerClose}
                toggleElement={
                    <SecondaryButton type="submit" text={"Add Sub User"} icon="add" loading={false}  />
                }
                content={
                    <CreateSubUser loading={loadingc} setTriggerClose={setTriggerClose} setLoading={setLoadingc} />
                }
                />}
            </Flex>

        </Flex>
    <Box  p="0 1rem" bg="#FFF" mt="1rem" height={"84vh"} overflowY={"scroll"} borderRadius={"12px"}>
        
        <Table mt="1.2rem" borderRadius={"12px"} overflow={"hidden"}>
            <Thead bg="#F9FAFB" border="1px solid #EAECF0">
                <Tr>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>ID</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Name</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Email</Th>
                    {/* <Th textTransform={"capitalize !important"} color={"#475467"}>Phone</Th> */}
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Joined</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Initiatives</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}></Th>
                    <Th></Th>
                </Tr>
            </Thead>
            
            {loading ? 
            <LineSkeleton width="100%" num={10} />
            :
            clients?.length < 1 ?
            <EmptyState mt="1.5rem" text="There are no subusers yet" />
            :
            <Tbody>
            
            {clients?.map((({_id: id, name, createdAt, email, initiatives, isDeleted}) => {
                const data = {id, name, createdAt, email, initiatives, isDeleted}
                return (
                    <Tr key={id}>
                        <Td color={"#475467"} fontSize={".9rem"}>{truncateText(id, 10)}</Td>
                        <Td>
                        <Flex alignItems={"center"} gap=".4rem">
                            {/* <Box height={"40px"} width={"40px"} backgroundImage={profilePicture} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}  /> */}
                            <Box>
                                <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{name}</Text>
                            </Box>
                        </Flex>
                        </Td>
                        <Td color={"#475467"} fontSize={".9rem"}>{email}</Td>
                        {/* <Td color={"#475467"} fontSize={".9rem"}>{phoneNumber}</Td> */}
                        <Td color={"#475467"} fontWeight={"500"} fontSize={".85rem"}>{createdAt?.slice(0,10)}</Td>
                        <Td>
                            {initiatives?.map(i => (
                                <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{i}</Text>
                            ))}
                        </Td>
                        <Td>
                            <Menu width={"200px !important"}>
                                <MenuButton>
                                    <BiDotsVerticalRounded style={{cursor: "pointer"}} />
                                </MenuButton>
                                <MenuList>
                                    {/* <Divider my=".2rem" />
                                    <MenuItem fontWeight={"500"} onClick={() => navigate(`/clients/${id}`)} fontSize={".85rem"}>View User</MenuItem> */}
                                    <Divider my=".2rem" />
                                    <>
                                    {currentUser?.role === 'Client' && 
                                    <>

                                   
                                    <CustomDrawer 
                                    position={"right"}
                                    title={"Update User Details"}
                                    triggerClose={triggerClose}
                                    toggleElement={
                                        <MenuItem fontWeight={"500"} fontSize={".85rem"}>Edit User</MenuItem>
                                    }
                                    content={
                                        <EditSubuser loading={loadingc} setTriggerClose={setTriggerClose} setLoading={setLoadingc} datay={data} />
                                    }
                                    />
                                    <Divider my=".2rem" />
                                    <CustomDrawer 
                                    position={"right"}
                                    title={"Assign to initiative"}
                                    triggerClose={triggerClose}
                                    toggleElement={
                                        <MenuItem fontWeight={"500"} fontSize={".85rem"}>Assign to initiative</MenuItem>
                                    }
                                    content={
                                        <AssignInitiative setTriggerClose={setTriggerClose} setRefetch={setRefetch} iid={id} mode="assign" />
                                    }
                                    />
                                    {initiatives?.length > 0 &&
                                    <>
                                    <Divider my=".2rem" />
                                    <CustomDrawer 
                                    position={"right"}
                                    title={"Unassign from initiative"}
                                    triggerClose={triggerClose}
                                    toggleElement={
                                        <MenuItem fontWeight={"500"} fontSize={".85rem"}>Unassign from initiative</MenuItem>
                                    }
                                    content={
                                        <AssignInitiative setTriggerClose={setTriggerClose} setRefetch={setRefetch} iid={id} mode="unassign" />
                                    }
                                    />
                                    </>}
                                    </>}
                                    <Divider my=".2rem" />
                                    {isDeleted ? 
                                    <MenuItem onClick={() => handleModal('restore', {name, id})} fontWeight={"500"} fontSize={".85rem"}>Restore User</MenuItem>
                                    :
                                    <MenuItem onClick={() => handleModal('suspend', {name, id})} fontWeight={"500"} fontSize={".85rem"}>Suspend User</MenuItem>
                                    }
                                    <Divider my=".2rem" />
                                    {(currentUser?.role === 'Client' || currentUser?.role === 'Super Admin') &&
                                    <>
                                        <MenuItem onClick={() => handleModal('delete', {name, id})} color={"crimson"} fontWeight={"500"} fontSize={".85rem"}>Delete User</MenuItem>
                                        <Divider my=".2rem" />
                                    </>
                                    }
                                    </>
                                </MenuList>
                            </Menu>
                        </Td>
                    </Tr>
                )
            }))
            }
            </Tbody>}
        </Table>

        <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{`${modalType} User`}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {modalType === 'suspend' &&
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm suspension of {selectedUser?.name}?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                            <PrimaryButton onClick={suspendEmployee} text="Suspend" width="150px" padding="1rem !important" loading={loadingx} />
                        </Flex>
                    </>
                    }
                    {modalType === 'restore' &&
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm restore of {selectedUser?.name}?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                            <PrimaryButton onClick={restoreEmployee} text="Restore" width="150px" padding="1rem !important" loading={loadingx} />
                        </Flex>
                    </>
                    }
                    {modalType === 'delete' &&
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm deletion of {selectedUser?.name}?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                            <PrimaryButton onClick={deleteEmployee} text="Delete" width="150px" bg="crimson !important" padding="1rem !important" loading={loadingx} />
                        </Flex>
                    </>
                    }
                    <Text pb="1rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Cancel</Text>
                </ModalBody>
            </ModalContent>
        </Modal>

        <CustomPagination
         page={page}
         setPage={setPage}
         pageSize={limit}
         totalElements={totalClients}
        />
    </Box>
    </>
  )
}

export default SubusersTable