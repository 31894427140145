import React, { useEffect, useState } from 'react'
import { buildResourceURL } from '../../../utils/helper';
import axiosInstance from '../../../services/api';
import { Box, Flex, Menu, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import LineSkeleton from '../../../components/lineSkeleton';
import EmptyState from '../../../components/emptyState/emptyState';
import CustomDrawer from '../../../components/customDrawer/customDrawer';
import ViewEmployee from '../../../components/employeeView/viewEmployee/viewEmployee';
import PrimaryButton from '../../../components/primaryButton/primarybutton';
import { useSelector } from 'react-redux';

const Requests = () => {
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingx, setLoadingx] = useState(false);
    const [triggerClose, setTriggerClose] = useState("");
    const [selectedUser, setSelectedUser] = useState(null)
    const [refetch, setRefetch] = useState("");

    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const currentUser = useSelector(({ userData }) => userData.currentUser);

    const requestUrl = currentUser?.role === "Agency" ? 'agencies/employees' : 'employees'

    const queryParams = [
        {
            key: 'isDeviceChangeRequested',
            value: true
        }
    ]

    const getEmployees = async() => {
        setLoading(true)
        try {
            const { data } = await axiosInstance.get(buildResourceURL(requestUrl, queryParams))
            setEmployees(data?.data)
        } catch(error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getEmployees()
    }, [triggerClose, refetch])


    const grantRequest = async(id) => {
        setLoadingx(true) 
        try {
            const {data} = await axiosInstance.patch(`/employees/${id}/enable-device-change`)
            toast({
                description: data?.message,
                status: "success",
                position: "top"
              });
            setTriggerClose('close')
            setRefetch(Math.random())
        } catch(error) {
            toast({
                title: "An Error Occurred",
                description: error?.response?.data?.message,
                status: "error",
                position: "top"
              });
        } finally {
            setLoadingx(false)
        }
    }
    

  return (
    <Box bg="#FFF" mt=".7rem" p="1.5rem 1rem" borderRadius={"12px"}>
        {/* <Flex alignItems={"center"} gap=".8rem" justifyContent={"space-between"}>
            
            <Flex gap="1.5rem" alignItems={"center"}>
            <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>{title}s <span style={{padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054"}}>{employees?.length}</span></Text>
                <CustomInput
                type={"text"}
                required
                value={searchVal}
                placeholder={`Search ${title}s..`}
                onChange={e => setSearchVal(e.target.value)}
                mt="0"
                sType="search"
                py="1.19rem"
                width="350px"
                />
            </Flex>
        </Flex> */}
        <Table mt="1.2rem" borderRadius={"12px"} overflow={"hidden"}>
            <Thead bg="#F9FAFB" border="1px solid #EAECF0">
                <Tr>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Name</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Email</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Role</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Phone</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Request Count</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Status</Th>
                    <Th></Th>
                </Tr>
            </Thead>
            
            {loading ? 
            <LineSkeleton width="100%" num={10} />
            :
            employees?.length < 1 ?
            <EmptyState mt="1.5rem" text="There are no device change requests yet" />
            :
            <Tbody>
            
            {employees?.map((({_id: id, firstName, lastName, email, profilePicture, isApproved, isDeleted, phoneNumber, createdAt, role, deviceChangeRequestReason, deviceChangeRequestsCount}) => {
                const data = {id, firstName, lastName, email, profilePicture, isApproved, isDeleted, phoneNumber, createdAt, role, deviceChangeRequestsCount}
                return (
                    <Tr key={id}>
                        <Td>
                        <Flex alignItems={"center"} gap=".4rem">
                            <Box height={"40px"} width={"40px"} backgroundImage={profilePicture} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}  />
                            <Box>
                                <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{firstName + " " + lastName}</Text>
                            </Box>
                        </Flex>
                        </Td>
                        <Td color={"#475467"} fontSize={".9rem"}>{email}</Td>
                        <Td color={"#475467"} fontSize={".9rem"}>{role}</Td>
                        <Td color={"#475467"} fontWeight={"500"} fontSize={".85rem"}>{phoneNumber}</Td>
                        <Td fontWeight={"500"} fontSize={".85rem"}>{deviceChangeRequestsCount}</Td>
                        <Td>
                            <Text textAlign={"center"} fontSize={"12px"} fontWeight={"500"} p=".1rem" borderRadius={"16px"} border={isApproved ? "1px solid #ABEFC6" : "1px solid lightgray"} color={isApproved ? "#067647" : "#475467"} bg={isApproved ? "#ECFDF3" : "#F9FAFB"}>{isApproved ? "Verified" : "Unverified"}</Text>
                        </Td>
                        <Td>
                        <CustomDrawer
                        position={"right"}
                        title={"Request details"}
                        triggerClose={triggerClose}
                        setTriggerClose={setTriggerClose}
                        toggleElement={
                            <Text cursor="pointer" fontSize={".85rem"} fontWeight={"600"} textDecoration={"underline"} color="#6941C6">Details</Text>
                        }
                        content={
                            <>
                            <ViewEmployee title={role} data={data} type={"ns"} />
                            <Text textDecoration={"underline"} opacity={".7"} mb="1rem">Device change request</Text>
                            <Text>Reason: {deviceChangeRequestReason}</Text>

                            <PrimaryButton onClick={() => grantRequest(id)} mt="4rem" text="Grant request" loading={loadingx} />
                            </>
                        }
                        />
                        </Td>
                    </Tr>
                )
            }))
            }
            </Tbody>}
        </Table>
    </Box>
  )
}

export default Requests;