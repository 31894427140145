import { Box, Heading, Image, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/primaryButton/primarybutton";
import AuthBg from "../../assets/bg-element.webp";
import FullLogo from "../../assets/full-logo.svg";
import Illustration from "./verifySucess.svg";

const Verify = () => {

  const navigate = useNavigate();

  return (
    <Box
      width={"100vw"}
      height="80vh"
      overflow={"hidden"}
      backgroundImage={AuthBg}
      backgroundSize="cover"
      p="1rem"
    >
      <Box
        padding={["2rem .7rem 1.5rem .7rem", "2rem 2rem 1.5rem 2rem"]}
        maxWidth={"600px"}
        bg="#003C4E"
        mx="auto"
        mt="9vh"
        boxShadow={"0px 4px 24px rgba(0, 0, 0, 0.16)"}
        borderRadius={["16px", "40px"]}
        textAlign={"center"}
      >
        <Image src={FullLogo} width={["50%", "33%"]} mx="auto" />
        <Image mt="2rem" src={Illustration} width={["40%", "22%"]} mx="auto" />
        <Heading
          mt={6}
          fontWeight={"700"}
          fontSize={["1.35rem", "1.5rem"]}
          color="#FFF"
        >
          Success!
        </Heading>
        <Text m="1rem auto" color={"#CFD0D3"} maxW="320px" fontSize={".9rem"}>
        Your password has been changed successfully.
        </Text>
        <PrimaryButton text="Login" onClick={() => navigate("/login")} loading={false} />
      </Box>
    </Box>
  );
};

export default Verify;
