/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from 'react';
import classes from './techSupportSide.module.css';
import { IoSend } from "react-icons/io5";
import io from 'socket.io-client';
import { GoSearch } from "react-icons/go";
import { timeFormatter } from '../../helpers/timeFormatter';
import { groupMessagesByDate } from '../../helpers/groupMessagesByDate';
import { formatDateHeader } from '../../helpers/formatDateHeader';
import { useSelector } from 'react-redux';
import { MdClose, MdOutlineCall, MdOutlineMail } from "react-icons/md";
import { HiOutlineChatBubbleOvalLeftEllipsis } from 'react-icons/hi2';
import { IoIosCloseCircle } from "react-icons/io";
import Fuse from 'fuse.js';
import { v4 as uuidv4 } from 'uuid';
import emptyImage from '../../assets/empty-folder.png'
import axios from 'axios';
import Dropzone from 'react-dropzone';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useToast } from '@chakra-ui/react';
import { TiImage } from "react-icons/ti";
import styles from './meeting.module.css'
import './meeting.css'
import { ImPhoneHangUp } from "react-icons/im";
import { LuMinimize2 } from "react-icons/lu";
import moment from 'moment';

const backend_chat_url = process.env.REACT_APP_BACKEND_CHAT_URL;

const socket = io(backend_chat_url); // Backend URL
const adminId = process.env.REACT_APP_ADMIN_CHAT_ID;
const superAdminId = process.env.REACT_APP_SUPER_ADMIN_ID;
const closureMessage = process.env.REACT_APP_CLOSURE_MESSAGE;

const chat_name = process.env.REACT_APP_BACKEND_CHAT_NAME
const register = process.env.REACT_APP_BACKEND_REGISTER


const url = process.env.REACT_APP_BASE_URL

export default function TechSupportSide() {



    const toast = useToast();

    const currentUser = useSelector(({ userData }) => userData?.currentUser);
    const { firstName, lastName, _id, email, phoneNumber } = currentUser || {}
    const fullName = `${firstName} ${lastName}`;
    const userId = _id;

    const [messages, setMessages] = useState(() => {
        // Retrieve messages from local storage
        const savedMessages = localStorage.getItem(`messages_${userId}`);
        return savedMessages ? JSON.parse(savedMessages) : [];
    });
    const [input, setInput] = useState('');
    const [activeScreen, setActiveScreen] = useState({});
    const [activeMessages, setActiveMessages] = useState([]);
    const [unreadCounts, setUnreadCounts] = useState(() => {
        // Retrieve unread counts from local storage
        const savedUnreadCounts = localStorage.getItem(`unreadCounts_${userId}`);
        return savedUnreadCounts ? JSON.parse(savedUnreadCounts) : {};
    });


    // all recipients array
    const [chatUsers, setChatUser] = useState([]);

    const [unreadCount, setUnreadCount] = useState(() => {
        // Retrieve unread count from local storage
        const savedUnreadCount = localStorage.getItem(`unreadCount_${userId}`);
        return savedUnreadCount ? parseInt(savedUnreadCount, 10) : 0;
    });

    const messagesEndRef = useRef(null);
    const containerRef = useRef(null);


    // Create an Audio object for the notification sound
    const notificationSound = useRef(new Audio('/assets/sounds/simple-notification-152054.mp3'));

    const [isChatBoxVisible, setIsChatBoxVisible] = useState(false);




    useEffect(() => {


        // Register the user ID with the server
        try {
            socket.emit(register, userId);
        } catch (error) {
        }

        socket.on(chat_name, (msg) => {
            setMessages((prevMessages) => {
                const updatedMessages = [...prevMessages, msg];
                // Save messages to local storage
                localStorage.setItem(`messages_${userId}`, JSON.stringify(updatedMessages));
                return updatedMessages;
            });

            // Play the notification sound
            notificationSound.current.play().catch((error) => {
            });

            // Increment unread messages count for the user if the chat box is not active
            if (msg.from !== activeScreen.from) {
                setUnreadCounts((prevCounts) => {
                    const newCounts = { ...prevCounts, [msg.from]: (prevCounts[msg.from] || 0) + 1 };
                    localStorage.setItem(`unreadCounts_${userId}`, JSON.stringify(newCounts));
                    return newCounts;
                });
            }

        });




        return () => {
            socket.off(chat_name);
            socket.off(register)
        };
    }, [userId, activeScreen]);





    useEffect(() => {
        scrollToBottom();

        messages.forEach((element) => {
            if (element && element.from) {
                setChatUser((prev) => {
                    // Filter out any existing entry with the same 'from' value
                    const filteredUsers = prev.filter(user => user.from !== element.from);

                    // Add the new entry
                    return [...filteredUsers, { fullName: element.fullName, from: element.from, timestamp: element.timeStamp, msg: element.msg, email: element.email, phoneNumber: element.phoneNumber, inputType: element.inputType }];
                });
            }
        });
    }, [messages]);

    useEffect(() => {
        const newMessage = messages.filter((item) => item.from === activeScreen.from || item.to === activeScreen.from);
        setActiveMessages(newMessage);
    }, [activeScreen, messages, userId]);

    const handleSubmit = (e) => {
        if (e) e.preventDefault();
        if (input) {
            socket.emit(chat_name, fullName, input, userId, activeScreen.from, new Date().toISOString(), email, phoneNumber, 'text');
            setInput('');
        }
    };




    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const groupedMessages = groupMessagesByDate(activeMessages);
    const sortedDates = Object.keys(groupedMessages).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    const [searchedMessage, setSearchedMessage] = useState([])

    const searchFn = (e) => {
        const options = {
            includeScore: true,
            keys: ['fullName']
        }


        const fuse = new Fuse(chatUsers, options);


        const result = fuse.search(e.target.value);


        result.sort((a, b) => a.score - b.score);


        const result_arr = [];
        result.forEach((element) => {
            result_arr.push(element.item);
        });

        setSearchedMessage(result_arr);

        return result_arr;
    };


    const [description, setDescription] = useState('')
    const [creatingTicket, SetCreatingTicket] = useState(false)

    const createTicket = async () => {
        const config = {
            headers: {
                authorization: `Bearer ${localStorage.getItem('PRTK')}`,
                'content-type': 'application/json'
            }
        }

        const { email, from, fullName } = activeScreen
        const payload = {
            email, userId: from, fullName, description
        }

        const body = JSON.stringify(payload)
        SetCreatingTicket(true)

        try {
            const res = await axios.post(`${url}/tech-support-ticket`, body, config)

            const ticketId = res.data.ticketId
            setDescription('')

            socket.emit(
                chat_name,
                fullName,
                `A support ticket has been opened for you with Ticket ID: ${ticketId}. We will keep you updated on the progress via email. You can also contact us directly to check the status of your ticket.`,
                userId,
                activeScreen.from,
                new Date().toISOString()
            );

        } catch (error) {

            const msg = error?.response?.data?.msg || 'unexpected error'
            return toast({
                description: msg,
                status: "error",
            });
        } finally {
            SetCreatingTicket(false)
        }
    }


    const closeConversation = () => {

        socket.emit(chat_name, fullName, closureMessage, userId, activeScreen.from, new Date().toISOString());
        // Filter out messages for the active screen
        const updatedMessages = messages.filter((msg) => msg.from !== activeScreen.from && msg.to !== activeScreen.from);

        // Save the updated messages to local storage
        localStorage.setItem(`messages_${userId}`, JSON.stringify(updatedMessages));

        // Update state with the filtered messages
        setMessages(updatedMessages);

        // Clear active screen
        setActiveScreen({});

        setChatUser((prevUsers) => {
            const updatedUsers = prevUsers.filter(user => user.from !== activeScreen.from);
            return updatedUsers;
        });

    }


    const mailUser = () => {
        window.open(`mailto:${activeScreen.email}`, '_blank');
    }




    const [image, setImage] = useState({});
    const [uploadProgress, setUploadProgress] = useState(0);

    const setImageToStorage = (file) => {
        if (file[0].type !== 'image/jpeg' && file[0].type !== 'image/png') {
            return toast({
                description: 'only jpg/png images supported',
                status: "error",
            });
        }

        const fileURL = URL.createObjectURL(file[0]);
        setImage({
            url: fileURL,
            file: file[0],
        });
    };


    const sendImage = async () => {
        const formData = new FormData();
        formData.append('image', image.file);
        formData.append('userId', userId);


        const config = {
            headers: {
                "Content-Type": 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
                const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                setUploadProgress(percentage);
            },
        };

        try {
            const res = await axios.post(`${backend_chat_url}/upload`, formData, config);
            const data = res.data.msg;

            socket.emit(chat_name, fullName, data, userId, activeScreen.from, new Date().toISOString(), email, phoneNumber, 'image');

            setImage({})

        } catch (error) {
            setImage({});
        } finally {
            setUploadProgress(0)
        }
    };

    const [callActive, setCallActive] = useState('ended')


    useEffect(() => {
        function handleClickOutside(event) {
            if (callActive === 'fullScreen' && containerRef.current && !containerRef.current.contains(event.target)) {
                setCallActive('minimized');
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [callActive]);

    const frameRef = useRef(null);


    useEffect(() => {
        // Load the Metered SDK script dynamically
        const script = document.createElement('script');
        script.src = 'https://cdn.metered.ca/sdk/frame/1.4.3/sdk-frame.min.js';
        script.async = true;
        document.body.appendChild(script);

        // Initialize MeteredFrame once the script has loaded
        script.onload = () => {
            if (window.MeteredFrame) {
                const frame = new window.MeteredFrame();
                frameRef.current = frame; // Store the frame instance in the ref
                frame.init({
                    roomURL: process.env.REACT_APP_ROOM_URL, // Replace with actual room URL
                    autoJoin: true
                }, document.getElementById('metered-frame'));

                // Add event listeners after initialization
                const handleParticipantLeft = (participantInfo) => {
                    // Handle participant left event
                };

                frame.on('participantLeft', handleParticipantLeft);

                // Clean up event listener on component unmount
                return () => {
                    frame.off('participantLeft', handleParticipantLeft);
                    frame.destroy(); // Ensure destruction of the frame instance
                };
            }
        };

        // Clean up script and frame instance on component unmount
        return () => {
            document.body.removeChild(script);
            if (frameRef.current) {
                frameRef.current.destroy(); // Ensure frame is destroyed
            }
        };
    }, []);



    const initiateCall = () => {
        socket.emit(chat_name, fullName, '📞 Click me to Join call', userId, activeScreen.from, new Date().toISOString(), email, phoneNumber, 'call');
        setCallActive('fullScreen')
        joinCall()
    }

    const joinCall = () => {
        if (frameRef.current) {
            setIsChatBoxVisible(false);
            setCallActive('fullScreen');
            frameRef.current.join({
                name: 'James Bond'
            });
            frameRef.current.init({
                roomURL: process.env.REACT_APP_ROOM_URL, // Replace with your actual room URL
                autoJoin: true
            }, document.getElementById('metered-frame'));
        } else {
        }
    };

    const end = () => {
        if (frameRef.current) {
            frameRef.current.leave();
            setIsChatBoxVisible(false);
            setCallActive('ended');
        } else {
        }
    };


    // New state for drag functionality
    const [dragging, setDragging] = useState(false);
    const [position, setPosition] = useState({ x: (window.innerWidth - 100), y: (window.innerHeight - 100) });
    const dragOffset = useRef({ x: 0, y: 0 });

    // Event handlers for dragging
    const handleDragStart = (e) => {
        e.preventDefault();
        setDragging(true);
        dragOffset.current = {
            x: e.clientX - position.x,
            y: e.clientY - position.y
        };
    };

    const handleDrag = (e) => {
        if (dragging) {
            setPosition({
                x: e.clientX - dragOffset.current.x,
                y: e.clientY - dragOffset.current.y
            });
        }
    };

    const handleDragEnd = () => {
        setDragging(false);
    };

    // Effect to add/remove mouse move and mouse up event listeners
    useEffect(() => {
        if (dragging) {
            document.addEventListener('mousemove', handleDrag);
            document.addEventListener('mouseup', handleDragEnd);
        } else {
            document.removeEventListener('mousemove', handleDrag);
            document.removeEventListener('mouseup', handleDragEnd);
        }

        // Cleanup function
        return () => {
            document.removeEventListener('mousemove', handleDrag);
            document.removeEventListener('mouseup', handleDragEnd);
        };
    }, [dragging]);



    return (

        <div ref={containerRef}>

            <div className={classes.container}>

                <div style={!isChatBoxVisible ? { padding: 0, opacity: 0, transition: '0.5s all' } : {}} className={classes.sub_container}>



                    <div className={isChatBoxVisible ? classes.chat_arena : classes.chat_arena_bland}>

                        {Object.keys(activeScreen).length > 0 && <div className={classes.cancel_button_con}><IoIosCloseCircle onClick={() => setActiveScreen({})} className={classes.cancel_button} /></div>}



                        <div className={classes.sub_con}>



                            {
                                activeScreen.from &&



                                <div className={classes.inst}>

                                    {/* instruction area */}
                                    <div className={classes.instruction_area}>

                                        <div style={{ width: '100%' }}>
                                            <p className={classes.chat_with_title}>{activeScreen.fullName}</p>
                                        </div>

                                        <div onClick={initiateCall} className={classes.action_icon_container}>
                                            <MdOutlineCall className={classes.icon} />
                                            <p className={classes.tooltip}>call user</p>
                                        </div>


                                        <div onClick={mailUser} className={classes.action_icon_container}><MdOutlineMail className={classes.icon} />  <p className={classes.tooltip}>mail user</p></div>
                                    </div>

                                    {
                                        userId !== superAdminId &&
                                        <div className={classes.actionsGrouped}>
                                            <div className={classes.textAreaContainer}>
                                                <textarea onChange={(e) => setDescription(e.target.value)} className={classes.textArea} value={description} placeholder='enter ticket description to create ticket' />
                                            </div>
                                            <div className={classes.closeConversation_container}>
                                                <button className={classes.closeConversation} onClick={() => closeConversation()}> close conversation</button>
                                                <button disabled={!description || creatingTicket} style={{ background: '#033f03' }} className={description ? classes.closeConversation : classes.disabledCloseConversation} onClick={() => { createTicket() }}> {creatingTicket ? 'creating...' : 'create ticket'}</button>
                                            </div>
                                        </div>
                                    }

                                    {/* chat side */}
                                    {
                                        activeScreen.from &&
                                        <div id="messages" className={classes.chat_side}>
                                            {sortedDates.map((date, index) => (
                                                <div key={uuidv4()} className={classes.main_chat}>
                                                    <div className={classes.date_header}>{formatDateHeader(moment(date).format('YYYY-MM-DD'))}</div>
                                                    {groupedMessages[date].map((item, msgIndex) => (
                                                        <div key={uuidv4()} className={
                                                            item.from === userId || item.from === superAdminId ? classes.client_side_chat_container : classes.admin_side_chat_container
                                                        }>

                                                            {item.inputType === 'call' ?
                                                                <p style={{ cursor: 'pointer' }} onClick={joinCall} >{item.msg}</p> :
                                                                item.inputType === 'image' ?
                                                                    <img src={item.msg} alt='' />
                                                                    :
                                                                    <p className={classes.chatMsg} key={uuidv4()}>{item.msg}</p>
                                                            }
                                                            <p style={item.from === userId || item.from === superAdminId ? { color: 'rgb(163, 163, 163)' } : {}} className={classes.time_stamp} key={uuidv4()}>{timeFormatter(new Date(item.timeStamp))}</p>

                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                            <br />
                                            <div style={{ background: 'red' }} ref={messagesEndRef} />
                                        </div>


                                    }

                                    <form onSubmit={handleSubmit} className={classes.input_container}>

                                        <input
                                            value={input}
                                            type='text'
                                            className={classes.input} onChange={(e) => setInput(e.target.value)}
                                            placeholder={'Type a message...'}
                                        />

                                        <Image image={image} setImageToStorage={setImageToStorage} setImage={setImage} sendImage={sendImage} uploadProgress={uploadProgress} />

                                        <div onClick={() => handleSubmit(undefined)} className={input ? classes.send_icon_bg : classes.send_icon_bg_opa}>
                                            <IoSend className={classes.send_icon} />
                                        </div>
                                    </form>
                                </div>

                            }


                            {/* names area  */}
                            <div className={classes.names_arena}>
                                <div className={classes.search_container}>
                                    <div className={classes.search_sub_container}>
                                        <GoSearch className={classes.search_icon} />
                                        <input onChange={(e) => searchFn(e)} className={classes.search} placeholder='Search' />
                                    </div>
                                </div>
                                {
                                    (searchedMessage?.length <= 0 && chatUsers?.length <= 0) ?
                                        <div className={classes.empty_msg_container}>
                                            <img src={emptyImage} alt='' className={classes.empty_image} />
                                            You have zero(0) messages
                                        </div>
                                        :

                                        <div className={classes.all_users_containers}>
                                            {
                                                (searchedMessage?.length > 0 ? searchedMessage : chatUsers)
                                                    ?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))  // Sort by most recent date/time
                                                    ?.map((item, index) => (
                                                        (item.from !== adminId && item.from !== superAdminId) &&
                                                        <div key={uuidv4()}
                                                            onClick={() => {
                                                                setActiveScreen(item);
                                                                setUnreadCounts((prevCounts) => {
                                                                    const newCounts = { ...prevCounts, [item.from]: 0 };
                                                                    localStorage.setItem(`unreadCounts_${userId}`, JSON.stringify(newCounts));
                                                                    return newCounts;
                                                                });
                                                            }}
                                                            className={activeScreen.from === item.from ? classes.users_container_active : classes.users_container}
                                                        >
                                                            <div className={classes.from_user_container}>
                                                                <p className={classes.fullName}>{item.fullName}</p>
                                                                {
                                                                    item.inputType === 'image' ?
                                                                        <div className={classes.image_message_con}>
                                                                            <TiImage className={classes.image_message} />
                                                                            <p className={classes.image_message_text} >Image</p>
                                                                        </div>
                                                                        :
                                                                        <p className={classes.user_msg}>{item.msg}</p>
                                                                }

                                                            </div>
                                                            <div className={classes.unread_messages_date_container}>
                                                                <p className={classes.timestamp_users}>
                                                                    {formatDateHeader(moment(item.timestamp).format('YYYY-MM-DD'))}
                                                                </p>

                                                                {unreadCounts[item.from] > 0 && <div className={classes._unread_count}>{unreadCounts[item.from]}</div>}
                                                            </div>
                                                        </div>
                                                    ))
                                            }
                                        </div>

                                }
                            </div>

                        </div>

                    </div>

                </div>


                {callActive === 'minimized' && <p className={styles.call_visibility_btn} onClick={() => setCallActive('fullScreen')}>Ongoing call with Tech Support</p>}
                <div
                    ref={containerRef}
                    className={classes.icon_container}
                    onClick={() => {
                        setIsChatBoxVisible(!isChatBoxVisible);
                        localStorage.setItem(`unreadCount_${userId}`, '0');
                        setUnreadCount(0);
                    }}
                    onMouseDown={handleDragStart}
                    style={{
                        cursor: dragging ? 'grabbing' : 'grab',
                        position: 'fixed',
                        top: `${position.y}px`,
                        left: `${position.x}px`,
                        zIndex: 1000 // Ensure it stays on top of other elements
                    }}
                >
                    {isChatBoxVisible ? <MdClose className={classes.chat_icon} /> : (
                        <>
                            <HiOutlineChatBubbleOvalLeftEllipsis className={classes.chat_icon} />
                            {unreadCount > 0 && <div className={classes.unread_count}>
                                <p className={classes}>{unreadCount}</p>
                            </div>}
                        </>
                    )}
                </div>


            </div>
            <div className={callActive === 'fullScreen' ? styles.container : styles.container_inactive} >
                <div className={classes.met} id="metered-frame" />

                <div className={styles.leave}>
                    <p className={styles.call_status}>Calling {activeScreen.fullName}</p>

                    <LuMinimize2 onClick={() => { setCallActive('minimized'); }} className={styles.minimize_icon} />

                    <div className={styles.hang_up_div}>
                        <ImPhoneHangUp />
                        <p onClick={end} >Hang up </p>
                    </div>

                </div>

            </div>
        </div>
    );
}


function Image({ image, setImageToStorage, setImage, sendImage, uploadProgress }) {

    return (
        <>
            <div className={Object.keys(image).length > 0 ? classes.image_previewer_container : classes.image_previewer_container_inactive}>
                <div style={image?.url ? { backgroundImage: `url(${image?.url})` } : {}} className={classes.image_container} >
                    {uploadProgress > 0 && (
                        <div className={classes.progress_bar_container}>
                            <CircularProgressbar
                                value={uploadProgress}
                                text={`${uploadProgress}%`}
                                styles={buildStyles({
                                    textColor: '#fff',
                                    pathColor: '#F97066',
                                    trailColor: '#eee',
                                })}
                            />
                        </div>
                    )}
                </div>

                <div className={classes.upload_btn_grouped}>
                    <p style={{ background: '#626262' }} className={classes.send} onClick={() => setImage({})}>cancel</p>
                    <p className={classes.send} onClick={() => { !uploadProgress && sendImage() }}>send</p>
                </div>
            </div>

            <Dropzone onDrop={acceptedFiles => {
                setImageToStorage(acceptedFiles);
            }}>
                {({ getRootProps, getInputProps }) => (
                    <section className={classes.send_icon_bg}>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <TiImage className={classes.image_upload_icon} />
                        </div>
                    </section>
                )}
            </Dropzone>
        </>
    )
}
