import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import SecondaryButton from '../../../../components/secondaryButton/secondarybutton'
import EmptyState from '../../../../components/emptyState/emptyState'
import axiosInstance from '../../../../services/api'
import { HiArrowRight } from 'react-icons/hi'
import moment from 'moment'
import CustomDrawer from '../../../../components/customDrawer/customDrawer'
import CreateClient from './createClient/createClient'
import LineSkeleton from '../../../../components/lineSkeleton'
import { useNavigate } from 'react-router-dom'
import classes from '../home.module.css'

const ClientsView = ({ setTotalClients, size }) => {
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingc, setLoadingc] = useState(false);
    const [triggerClose, setTriggerClose] = useState("")

    const navigate = useNavigate()

    const getClients = async () => {
        try {
            const { data } = await axiosInstance.get('clients?limit=5')
            setClients(data?.data)
            setTotalClients(data?.totalCount)
        } catch (error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getClients()
    }, [triggerClose])

    return (
        <Box boxShadow="0px 1px 2px 0px #1018280D" flex={["1 0 300px", "1 1 400px"]} bg="#FFF" border=".5px solid #F2F4F7" p="1.3rem" borderRadius={"12px"}>
            <Flex alignItems={"center"} justifyContent={"space-between"}>
                <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>Clients <span style={{ padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054" }}>{clients?.length}</span></Text>
                <Flex alignItems={"center"} gap=".8rem">
                    <CustomDrawer
                        position={"right"}
                        title={"Add client"}
                        triggerClose={triggerClose}
                        setTriggerClose={setTriggerClose}
                        toggleElement={
                            <button className={classes.createButton}>Add Client</button>
                        }
                        content={

                            <CreateClient loading={loadingc} setTriggerClose={setTriggerClose} setLoading={setLoadingc} />
                        }
                    />
                </Flex>
            </Flex>
            <Divider my="1.5rem" />
            <Box height={size === 'full' ? "auto" : "267.41px"} overflow={size === "full" ? "none" : "scroll"}
                sx={
                    {
                        '::-webkit-scrollbar': {
                            display: 'none'
                        }
                    }
                }>
                {loading ?
                    <LineSkeleton num={6} />
                    :
                    clients?.length < 1 ?
                        <EmptyState mt="1.5rem" text="You have not added any clients yet" />
                        :
                        clients?.map((({ _id: id, companyName, createdAt, profilePicture }) => {
                            return (
                                <Flex key={id} onClick={() => navigate(`/clients/${id}`)} cursor={"pointer"} padding={".7rem"} borderRadius={"16px"} border="1px solid #EAECF0" mb=".8rem">
                                    <Flex alignItems={"center"} w="100%" justifyContent={"space-between"}>
                                        <Flex alignItems={"center"}>
                                            <Box height={"50px"} width={"60px"} backgroundImage={profilePicture} backgroundSize={"contain"} backgroundRepeat={"no-repeat"} />
                                            <Box>
                                                <Text mb=".2rem" color={"#667085"} fontWeight={"500"}>{companyName}</Text>
                                                <Text fontWeight={"300"} fontSize={".85rem"} color="#667085">{moment(createdAt).calendar()}</Text>
                                            </Box>
                                        </Flex>
                                        <HiArrowRight />
                                    </Flex>
                                </Flex>
                            )
                        }))
                }
            </Box>
            <br />
            <Text onClick={() => navigate('/clients')} color={"#ff6f61"} fontSize={".9rem"} fontWeight={"600"} cursor={"pointer"}>See more</Text>
        </Box>


    )
}

export default ClientsView;