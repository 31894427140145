import React, { useEffect, useState } from 'react'
import { Box, Flex, useToast } from '@chakra-ui/react';
import statesLgaJson from '../../../../utils/stateLGAs';
import axiosInstance from '../../../../services/api';
import CustomInput from '../../../../components/customInput/customInput';
import CustomSelect from '../../../../components/customSelect/customSelect';
import ImageUploader from '../../../../components/imageUploader/imageUploader';
import CustomTextArea from '../../../../components/customTextArea/customTextArea';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';


const places = statesLgaJson?.map(i => ({
  title: i?.state,
  value: i?.state,
  listValue: i?.lgas?.map(i => ({
    title: i,
    value: i,
  }))
}))

const EditStore = ({setTriggerClose, storeData, setRefetch}) => {


  const [address, setAddress] = useState(storeData?.address);
  const [storeName, setStoreName] = useState(storeData?.name);
  const [ownersFName, setOwnersFName] = useState(storeData?.ownerFirstName);
  const [ownersLName, setOwnersLName] = useState(storeData?.ownerLastName);
  const [storeType, setStoreType] = useState(storeData?.type);
  const [storeLevel, setStoreLevel] = useState(storeData?.level);
  const [phone, setPhone] = useState(storeData?.ownerPhoneNumber);
  const [coordinates, setCoordinates] = useState(null);
  const [comment, setComment] = useState(storeData?.comment);
  const [category, setCategory] = useState(storeData?.category);
  const [imageURL, setImageURL] = useState(storeData?.imageURL);
  const [state, setState] = useState(storeData?.state);
  const [area, setArea] = useState(storeData?.area);
  const [town, setTown] = useState(storeData?.town);
  const [country, setCountry] = useState("");
  const [loadingi, setLoadingi] = useState(false);
  const [loading, setLoading] = useState(true);
  const [team, setTeam] = useState(storeData?.team);
  const [storePhone, setStorePhone] = useState(storeData?.phoneNumber)
  const [streetNumber, setStreetNumber] = useState(storeData?.streetNumber)
  const [streetName, setStreetName] = useState(storeData?.streetName)

  const [clients, setClients] = useState([]);
  const [loadingx, setLoadingx] = useState(false);

  const [categories, setCategories] = useState([]);

  const toast = useToast();

  useEffect(() => {
    fetchUserLocation();
  }, []);

  const fetchUserLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({latitude, longitude})
        },
        (error) => {
          console.error('Error fetching user location:', error);
        }
      );
    } else {
      console.error('Geolocation is not available in this browser.');
    }
  };

  const getCategories = async() => {
    setLoading(true)
    try {
        const { data } = await axiosInstance.get(`store-categories`)
        setCategories(data?.data)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
}

useEffect(() => {
    getCategories()
}, [])

const categoriesx = categories?.map(i => ({
  title: i?.name,
  value: i?.name,
  listValue: i?.types?.map(v => ({
    title: v?.name,
    value: v?.name,
    listValue: v?.levels?.map(k => ({
      title: k,
      value: k
    }))
  }))
}))

  const getClients = async() => {
    setLoadingx(true)
    try {
        const { data } = await axiosInstance.get('clients')
        setClients(data?.data)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoadingx(false)
    }
  }

  useEffect(() => {
    getClients()
  }, [])

  const editStore = async(e) => {
    e.preventDefault()
    setLoading(true)

    const myObj = {}

    const datax = {
      name: storeName,
      address,
      state,
      area,
      town,
      ownerFirstName: ownersFName,
      ownerLastName: ownersLName,
      ownerPhoneNumber: phone,
      type: storeType,
      level: storeLevel,
      category,
      imageURL,
      team,
      phoneNumber: storePhone,
      streetName,
      streetNumber
    }

    for (const key in datax) {
      const value = datax[key];
      if (value !== "" && value !== undefined && value !== null) {
        myObj[key] = value;
      }
    }

    try {
      const {data} = await axiosInstance.patch(`/stores/${storeData?.id}`, myObj)
      toast({
        description: data?.message,
        status: "success",
        position: 'top'
      });
      setTriggerClose('close' + (Math.random()))
      setRefetch(Math.random())
    } catch(error) {
      if(error?.response?.data?.message?.length > 3) {
        toast({
          title: "An Error Occurred",
          description: error?.response?.data?.message,
          status: "error",
        });
      }
      
    } finally {
      setLoading(false)
    }
  }

  const handleImagesUpload = (images) => {
    setImageURL(images[0])
  };


  const countries = [
    {
      title: "Nigeria",
      value: "nigeria"
    }
  ]


   const arr1 = categoriesx?.find(i => i?.value === category)
  const arr2 = arr1?.listValue?.find(i => i?.value === storeType)


  const teamOptions = [];

  for (let i = 0; i < 100; i++) {
    const newObj = {
      title: `Team ${i + 1}`,
      value: i + 1,
    };
    teamOptions.push(newObj);
  }




  return (
    <form onSubmit={editStore}>
        <Box height={"70vh"} overflowY={"scroll"}>
        <CustomInput
        type={"text"}
        placeholder={"Outlet Name"}
        onChange={e => setStoreName(e.target.value)}
        value={storeName}
        label="Store Name"
        />
        <CustomInput
        type={"tel"}
        placeholder={"Outlet Phone Number"}
        minLength={11}
        maxLength={11}
        onChange={e => setStorePhone(e.target.value)}
        mb=".8rem"
        value={storePhone}
        label="Owner's Phone"
        />
        <CustomInput
        type={"text"}
        placeholder={"Street Number"}
        onChange={e => setStreetNumber(e.target.value)}
        value={streetNumber}
        label="Street Number"
        />
        <CustomInput
        type={"text"}
        placeholder={"Street Name"}
        onChange={e => setStreetName(e.target.value)}
        value={streetName}
        label="Street Name"
        />

        <Flex gap={".8rem"} my=".8rem" width={"100%"}>
          <CustomSelect
          options={places}
          placeholder={state}
          onChange={e => setState(e.target.value)}
          label="State"
          />
          <CustomSelect
          options={places?.filter(i => i.value === state)[0]?.listValue}
          placeholder={state === storeData?.state.toLowerCase() ? area : "Area"}
          onChange={e => setArea(e.target.value)}
          label="Area"
          />
        </Flex>
        <Flex mb=".8rem" gap={".8rem"}>
           <CustomInput
            type={"text"}
            placeholder={"Town"}
            onChange={e => setTown(e.target.value)}
            value={town}
            label="Town"
           />
            <CustomSelect
            required
            options={countries}
            // placeholder={"Country"}
            onChange={e => setCountry(e.target.value)}
            label="Country"
            />
        </Flex>
        
        <Flex mb=".8rem" gap={".8rem"}>
          <CustomInput
          type={"text"}
          placeholder={"Owners First Name"}
          onChange={e => setOwnersFName(e.target.value)}
          mb=".8rem"
          value={ownersFName}
          label="Owner's Name"
          />
          <CustomInput
          type={"text"}
          placeholder={"Owners Last Name"}
          onChange={e => setOwnersLName(e.target.value)}
          mb=".8rem"
          value={ownersLName}
          label="Owner's Name"
          />
        </Flex>
         
        <CustomInput
        type={"tel"}
        placeholder={"Owners Phone Number"}
        minLength={11}
        maxLength={11}
        onChange={e => setPhone(e.target.value)}
        mb=".8rem"
        value={phone}
        label="Owner's Phone"
        />
        <CustomSelect
        options={categoriesx}
        placeholder={category}
        onChange={e => setCategory(e.target.value)}
        mb="rem"
        label="Category"
        />
        <CustomSelect
         options={categoriesx?.filter(i => i.value === category)[0]?.listValue}
         placeholder={category === storeData?.category?.toLowerCase() ? storeType : "Type of outlet"}
        onChange={e => setStoreType(e.target.value)}
        mb=".8rem"
        label="Type"
        />
        <CustomSelect
        options={arr2?.listValue}
        placeholder={storeType === storeData?.type.toLowerCase() ? storeLevel : "Level of outlet"}
        onChange={e => setStoreLevel(e.target.value)}
        mb=".8rem"
        label="Level"
        />
        <Flex mb="1.8rem" gap=".5rem">
            {imageURL !== '' && <Box backgroundImage={imageURL} backgroundSize={"cover"} height={"100px"} width={"120px"} borderRadius={"4px"} backgroundColor={"lightgray"} />}
            <ImageUploader
            onImagesUpload={handleImagesUpload}
            setLoading={setLoadingi}
            loading={loadingi}
            max={1}
            src="camera"
            />
        </Flex>
        <CustomTextArea
        rows="1"
        placeholder={"Comment (Optional)"}
        onChange={e => setComment(e.target.value)}
        mb=".8rem"
        value={comment}
        label="Comment"
        />
         <CustomSelect
        options={teamOptions}
        placeholder={`Team ${team}`}
        onChange={e => setTeam(e.target.value)}
        />

        </Box>
        <PrimaryButton mt="1rem" type="submit" text="Update Outlet" func={() => {}} loading={loading} />
    </form>
  )
}

export default EditStore;