/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../../../../../../services/api";
import { buildResourceURL } from "../../../../../../../../utils/helper";
import { InitiativePaginationComponent } from "../../../../../../../../components/initiativePaginationComponent/initiativePaginationComponent";
import { InitiativeEmptyState } from "../../../../../../../../components/initiativeEmptyState/initiativeEmptyState";
import { defaultSearchValueState } from "../../../../../../../../constants/defaultStates";
import { InitiativeQueryComponent } from "../../../../../../../../components/initiativeQueryComponent/initiativeQueryComponent";
import classes from "./oos.module.css";
import { Loader } from "../../../../../../../../components/Loader/loader";
import { formatDateHeader } from "../../../../../../../../helpers/formatDateHeader";
import { MdStorefront } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { numberFormat } from "../../../../../../../../helpers/numberFormatter";
import { moneyFormat } from "../../../../../../../../helpers/moneyFormatter";
import UtilityContext from "../../../../../../../../context/utilityContext";
import defaultImage from "../../../../../../../../assets/placeholderImage.png";
import moment from "moment";

const Oos = ({ data }) => {
  const { draw } = useContext(UtilityContext);

  const [loading, setLoading] = useState(false);

  const [docs, setDocs] = useState([]);
  const [total, setTotal] = useState("");
  const { iid, sid } = useParams();

  const [searchValues, setSearchValues] = useState(defaultSearchValueState);

  const { page } = searchValues;

  const limit = 100;

  const storeGroupData = data?.initiativeStoresFilterGroups || [];
  const regionsData = data?.client?.statesFilterGroups || [];
  const productGroupData = data?.productsFilterGroups || [];

  const endDate = new Date(searchValues.endDate); // Create a new date object based on the current date
  endDate.setDate(endDate.getDate() + 1);

  const storeGroupQuery =
    storeGroupData
      .filter(
        (item) => item.name.toLowerCase() === searchValues.groups.toLowerCase()
      )[0]
      ?.["initiativeStoresIDs"].map((item) => ({
        key: "initiativeStore[in]",
        value: item,
      })) || [];

  const productGroupBrandQuery =
    productGroupData
      .filter(
        (item) =>
          item.name.toLowerCase() === searchValues.products.toLowerCase()
      )[0]
      ?.["products"].map((item) => ({
        key: "brandName[in]",
        value: item?.split("-")[0],
      })) || [];

  const productGroupSkuQuery =
    productGroupData
      .filter(
        (item) =>
          item.name.toLowerCase() === searchValues.products.toLowerCase()
      )[0]
      ?.["products"].map((item) => ({
        key: "brandName[in]",
        value: item?.split("-")[1], // todo this splitting may result to unwanted behavior because e.g if item is pr-k2-20kg where 20kg is the sku, wherever the data is coming should send it as a stringified object then you parse here.
      })) || [];

  const options1 = data
    ? data?.brands?.map((i) => ({
      title: `${i?.name}-${i?.sku}`,
      value: JSON.stringify({ brandName: i?.name, sku: i?.sku }),
    }))
    : [];

  options1.sort((a, b) => a.title.localeCompare(b.title));

  const queryParams = [
    {
      key: sid ? "initiativeStore" : "initiative",
      value: sid ? sid : iid,
    },
    {
      key: "search",
      value: searchValues.searchQuery,
    },
    {
      key: "date[gte]",
      value: searchValues.startDate,
    },
    {
      key: "date[lte]",
      value: searchValues?.endDate ? endDate.toISOString().split("T")[0] : "",
    },
    {
      key: "brandName",
      value: JSON.parse(searchValues?.brandName || "{}")?.brandName,
    },
    {
      key: "sku",
      value: JSON.parse(searchValues?.brandName || "{}")?.sku,
    },
    {
      key: "limit",
      value: limit,
    },
    {
      key: "page",
      value: page,
    },
    {
      key: "state",
      value: searchValues.states.toLowerCase(),
    },
    {
      key: "level",
      value: 0,
    },
    ...(regionsData
      ?.find((i) => i?.name === searchValues?.regions)
      ?.states?.map((k) => ({
        key: "state[in]",
        value: k,
      })) || []),

    ...storeGroupQuery,
    ...productGroupBrandQuery,
    ...productGroupSkuQuery,
  ];

  const queryParamsx = [
    {
      key: sid ? "initiativeStore" : "initiative",
      value: sid ? sid : iid,
    },
    {
      key: "date[gte]",
      value: searchValues.startDate,
    },
    {
      key: "date[lte]",
      value: searchValues?.endDate ? endDate.toISOString().split("T")[0] : "",
    },
    {
      key: "brandName",
      value: JSON.parse(searchValues?.brandName || "{}")?.brandName,
    },
    {
      key: "sku",
      value: JSON.parse(searchValues?.brandName || "{}")?.sku,
    },
    {
      key: "state",
      value: searchValues.states.toLowerCase(),
    },
    {
      key: "level",
      value: 0,
    },
    ...(regionsData
      ?.find((i) => i?.name === searchValues?.regions)
      ?.states?.map((k) => ({
        key: "state[in]",
        value: k,
      })) || []),

    ...storeGroupQuery,
    ...productGroupBrandQuery,
    ...productGroupSkuQuery,
  ];

  const getDocs = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        buildResourceURL("inventories", queryParams)
      );
      setDocs(res?.data?.data);
      setTotal(res?.data?.totalCount);
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDocs();
  }, [searchValues]);

  const [inputPage, setInputPage] = useState(page);

  return (
    <div className={classes.screenContainer}>
      <InitiativeQueryComponent
        data={data}
        searchValues={searchValues}
        setSearchValues={setSearchValues}
        filterList1={{ title: "Brand", name: "brandName", data: options1 }}
        entity="inventory"
        queryParamsx={queryParamsx}
      />

      {loading ? (
        <div className={classes.loader_con}>
          <Loader />
        </div>
      ) : (
        <>
          {docs.length ? (
            <div className={classes.table_container}>
              <div className={classes.titleAndInstructionContainer}>
                <p className={classes.title}>Total OOS Found: {total}</p>
                <p className={classes.instruction}>
                  Click on each row to see more{" "}
                </p>
              </div>

              <p className={classes.showing}>
                Showing:{" "}
                <span>
                  {docs.length || 1} of {total}
                </span>
              </p>
              <div className={classes.scrollable}>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th>Creation Date</th>
                      <th>Brand</th>
                      <th>SKU</th>
                      <th>Store</th>
                      <th>Location</th>
                      <th>Current Inv.</th>
                      <th>Total Case</th>
                      <th>Total Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {docs.map((item, index) => (
                      <tr
                        onClick={() =>
                          draw({
                            title: "Message Thread",
                            content: (
                              <div className={classes.modalContainer}>
                                <img
                                  onClick={
                                    item.imageURL
                                      ? () => window.open(item.imageURL)
                                      : null
                                  }
                                  src={item.imageURL || defaultImage}
                                  alt=""
                                  className={classes.image}
                                />

                                <div
                                  className={classes.modalTitleAndDateContainer}
                                >
                                  <p className={classes.modalTitle}>
                                    {item?.brandName} - {item?.sku}
                                  </p>
                                  <p>{formatDateHeader(moment(item.date).format('YYYY-MM-DD'))}</p>

                                </div>

                                <div
                                  className={classes.modalStoreAddressContainer}
                                >
                                  <p className={classes.modalStoreAddress}>
                                    {item?.initiativeStore?.store?.name} -{" "}
                                    {item?.initiativeStore?.store?.streetNum ||
                                      "No Bld. num."}
                                    , {item?.initiativeStore?.store?.streetName}
                                  </p>
                                  <MdStorefront className={classes.modalIcon} />
                                </div>

                                <div
                                  className={classes.modalStoreAddressContainer}
                                >
                                  <div style={{ width: "90%" }}>
                                    <p className={classes.modalStoreAddress}>
                                      {item?.user?.name
                                        ? item?.user?.name
                                        : `${item?.user?.firstName} ${item?.user?.lastName || ""
                                        }`}
                                    </p>
                                    <p className={classes.messageDescription}>
                                      {item?.user?.role}
                                    </p>
                                  </div>

                                  <FaRegUser className={classes.modalIcon} />
                                </div>
                              </div>
                            ),
                          })
                        }
                        key={index}
                      >
                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>
                            {formatDateHeader(moment(item.date).format('YYYY-MM-DD'))}
                          </p>
                          <p className={classes.tooltip}>
                            {formatDateHeader(moment(item.date).format('YYYY-MM-DD'))}
                          </p>
                        </td>

                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>{item.brandName}</p>
                          <p className={classes.tooltip}>{item.brandName}</p>
                        </td>
                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>{item.sku}</p>
                          <p className={classes.tooltip}>{item.sku}</p>
                        </td>
                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>
                            {item.initiativeStore?.store?.name}
                          </p>
                          <p className={classes.tooltip}>
                            {item.initiativeStore?.store?.name}
                          </p>
                        </td>
                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>
                            {item.initiativeStore?.store?.state}
                          </p>
                          <p className={classes.tooltip}>
                            {item.initiativeStore?.store?.state}
                          </p>
                        </td>
                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>
                            {numberFormat.format(item.level)}
                          </p>
                          <p className={classes.tooltip}>
                            {numberFormat.format(item.level)}
                          </p>
                        </td>
                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>
                            {numberFormat.format(item.totalCase)}
                          </p>
                          <p className={classes.tooltip}>
                            {numberFormat.format(item.totalCase)}
                          </p>
                        </td>
                        <td className={classes.tooltip_container}>
                          <p className={classes.item_text}>
                            {moneyFormat.format(item.totalValue)}
                          </p>
                          <p className={classes.tooltip}>
                            {numberFormat.format(item.totalValue)}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <InitiativePaginationComponent
                page={page}
                totalCount={total}
                setSearchValues={setSearchValues}
                setInputPage={setInputPage}
                inputPage={inputPage}
                limit={limit}
              />
            </div>
          ) : (
            <InitiativeEmptyState searchValues={searchValues} name={"oos"} />
          )}
        </>
      )}
    </div>
  );
};

export default Oos;
