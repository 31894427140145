import { Box, Flex, Heading, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/primaryButton/primarybutton";
import AuthBg from "../../../assets/bg-element.svg";
import OtpInput from "react-otp-input";
import CustomInput from "../../../components/customInput/customInput";
import axiosInstance from "../../../services/api";

const ResetPassword = () => {
  const [otp, setOtp] = useState("")
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const {state} = useLocation()

  const toast = useToast();

  const navigate = useNavigate();

  const handleChange = (e) => {
    setOtp(e);
  };

  const datax = {
    email: state?.email,
    token: otp,
    password
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axiosInstance.post(`auth/${state?.userType}/reset-password`, datax);
      toast({
        description: data?.message,
        status: "success",
        position: "top"
      });
      navigate(state?.userType === "clients" ? "/login" : "/");
    } catch (error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex height={"100vh"} flexWrap={"wrap"}>
      <Box flex={"1 1 400px"}bg="#0C0C0C" backgroundImage={AuthBg} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}></Box>
      <Flex flex={"1 1 400px"} p={["1.5rem 1rem", "5.5rem 4rem"]} bg="#F5F5F5" alignItems={"center"} justifyContent={"center"}>
        <Box
          padding={["2rem .7rem 1.5rem .7rem", "4rem 2rem 1.5rem 2rem"]}
          bg="#FFF"
          width={"100%"}
          height={"100%"}
          boxShadow={"0px 4px 24px rgba(0, 0, 0, 0.16)"}
          borderRadius={"8px"}
          textAlign={"center"}
          border="0.5px solid #D0D5DD"
        >
          <Heading
            mt={6}
            fontWeight={"600"}
            fontSize={["1.35rem", "1.5rem"]}
            color="#27272A"
          >
            Reset Password
          </Heading>
          <Text mt={2} mb="1rem" color={"#3F3F46"} fontSize={".9rem"}>
          Enter the code sent to <span style={{color: "#7E2EDB", fontWeight: "500"}}>{state?.email}</span>
          </Text>
          <form onSubmit={resetPassword}>
            <Box margin="2.5rem auto 1rem auto" width={"70%"}>
              <OtpInput
                containerStyle={{
                  display: "flex",
                  margin: "0 auto",
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
                inputStyle={{
                  height: "58px",
                  width: "58px",
                  background: "lightgray",
                  color: "#333",
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  borderRadius: "4px"
                }}
                focusStyle={{
                  boxShadow: "none",
                  outline: "none",
                }}
                value={otp}
                onChange={handleChange}
                numInputs={6}
                isInputNum={true}
                shouldAutoFocus={true}
              />
            </Box>
            <CustomInput
              placeholder={"Enter new password"}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <br /><br /><br />
            <PrimaryButton type="submit" text="Submit" loading={loading} />
            {/* <Text
              mt="1.2rem"
              mb=".3rem"
              textAlign={"center"}
              fontSize={".9rem"}
              color="#3C3148"
            >
              <span
                onClick={() =>
                  navigate(-1)
                }
                style={{
                  fontWeight: "500",
                  color: "#7E2EDB",
                  cursor: "pointer",
                  transition: "250ms ease",
                }}
              >
                Back to Login
              </span>
            </Text> */}
          </form>
        </Box>
      </Flex>
    </Flex>
  );
};

export default ResetPassword;
