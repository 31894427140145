/* eslint-disable react-hooks/exhaustive-deps */
import './App.css';
import { Routes, Route, useNavigate } from "react-router-dom"
import { useEffect, useState } from 'react';
import { loaduser } from './redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { userActionTypes } from './redux/constants/userActionTypes';
import Login from './pages/auth/login/login';
import Signup from './pages/auth/signup/signup';
import ForgotPassword from './pages/auth/forgotPassword/forgotPassword';
import ResetPassword from './pages/auth/forgotPassword/resetPassword';
import Verify from './pages/auth/verify/verify';
import VerifySuccess from './pages/auth/verifySuccess';
import Dashboard from './pages/dashboard/dashboard';
import Home from './pages/dashboard/home/home';
import SpinLoader from './components/spinLoader/spinLoader';
import Clients from './pages/dashboard/clients/clients';
import Promoters from './pages/dashboard/promoters/promoters';
import Supervisors from './pages/dashboard/supervisors/supervisors';
import StoresTable from './pages/dashboard/stores/storesTable/storesTable';
import ClientInitiatives from './pages/dashboard/clients/clientInitiatives/clientInitiatives';
import InitiativeDetails from './pages/dashboard/initiatives/initiativeDetails/initiativeDetails';
import InitiativeStoreDetails from './pages/dashboard/initiatives/initiativeDetails/initiativeStores/initiativeStoreDetails/initiativestoreDetails';
import StoreFront from './pages/dashboard/initiatives/initiativeDetails/initiativeStores/initiativeStoreDetails/storeFront';
import Initiatives from './pages/dashboard/initiatives/initiatives';
import CreateInitiative from './pages/dashboard/initiatives/createInitiative/createInitiative';
import TaskDetails from './pages/dashboard/initiatives/initiativeDetails/tasks/taskDetails/taskDetails';
import Requests from './pages/dashboard/request/requests';
import Settings from './pages/settings/settings';
import ClientLogin from './pages/auth/login/clientLogin';
import AgencyLogin from './pages/auth/login/agencyLogin';
import AgenciesTable from './pages/dashboard/agencies/agenciesTable/agenciesTable';
import SurveysTable from './pages/dashboard/surveys/surveysTable/surveysTable';
import Init from './pages/dashboard/initiatives/initiativeDetails/init';
import SubusersTable from './pages/dashboard/subUsers/subusersTable/subusersTable';
import SubuserLogin from './pages/auth/login/subuserLogin';
import SurveyResponses from './pages/dashboard/surveys/surveyResponses/surveyResponses';
import TechSupportSide from './pages/quickResponse/techSupportSide';
import TechSupportTickets from './pages/techSupportTickets/techSupportTickets';
import { Drawer } from './components/Drawer2/drawer';
import AssetsList from 'pages/assets/assets';



const superAdminId = process.env.REACT_APP_SUPER_ADMIN_ID;

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(false)

  const loading = useSelector(({ userData }) => userData?.isLoading);
  const currentUser = useSelector(({ userData }) => userData?.currentUser);

  useEffect(() => {
    if (localStorage.PRTK) {
      dispatch(loaduser(navigate))
    } else {
      dispatch({ type: userActionTypes.LOADUSER_FAIL, payload: null })
    }
  }, [])


  const userId = currentUser?._id
  const adminId = process.env.REACT_APP_ADMIN_CHAT_ID;



  return (
    <>
      {
        currentUser &&
        <>
          {(userId === adminId || userId === superAdminId) && <TechSupportSide />}
        </>
      }


      {loading ?
        <SpinLoader />
        :
        <div className="App">
          <Drawer />
          <Routes>
            <Route path='/' element={currentUser ? <Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={currentUser?.role === 'Client' ? <Initiatives /> : <Home />} /> : <ClientLogin />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/login' element={<Login />} />
            <Route path='/agency' element={<AgencyLogin />} />
            <Route path='/subuser' element={<SubuserLogin />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/forgot-password/reset' element={<ResetPassword />} />
            <Route path='/verify' element={<Verify />} />
            <Route path='/verify/success' element={<VerifySuccess />} />
            <Route path="/initiatives/create/:step" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<CreateInitiative />} />} />
            <Route path="/initiatives" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<Initiatives />} />} />
            <Route path="/initiatives/:iid" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<Init />} />} />
            <Route path="/initiatives/:iid/tasks/:tid" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<TaskDetails />} />} />
            <Route path="/initiatives/:iid/store/:sid/front" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<StoreFront />} />} />
            <Route path="initiatives/:iid/store/:sid" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<InitiativeStoreDetails />} />} />
            <Route path="/agencies" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<AgenciesTable />} />} />
            <Route path="/clients" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<Clients />} />} />
            <Route path="clients/:id" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<ClientInitiatives />} />} />
            <Route path="clients/:id/initiatives/:iid" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<InitiativeDetails />} />} />
            <Route path="clients/:id/initiatives/:iid/store/:sid/front" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<StoreFront />} />} />
            <Route path="clients/:id/initiatives/:iid/store/:sid" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<InitiativeStoreDetails />} />} />
            <Route path="/supervisors" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<Supervisors />} />} />
            <Route path="/promoters" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<Promoters />} />} />
            <Route path="/outlets" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<StoresTable />} />} />
            <Route path="/requests" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<Requests />} />} />
            <Route path="/settings" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<Settings />} />} />
            <Route path="/surveys" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<SurveysTable />} />} />
            <Route path="/surveys/:id" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<SurveyResponses />} />} />
            <Route path="/subusers" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<SubusersTable />} />} />
            {/* <Route path="/settings" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<Account />} />} />
          <Route path="/messages" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<Account />} />} /> */}
            <Route path="/tech-support-tickets" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<TechSupportTickets />} />} />

            {/* ----------> Assets <---------- */}
            <Route path="/assets" element={<Dashboard collapsed={collapsed} setCollapsed={setCollapsed} component={<AssetsList />} />} />

          </Routes>
        </div>}
    </>
  );
}

export default App;
