/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../../../../../../services/api';
import { buildResourceURL } from '../../../../../../../../utils/helper';
import { InitiativeEmptyState } from '../../../../../../../../components/initiativeEmptyState/initiativeEmptyState';
import { numberFormat } from '../../../../../../../../helpers/numberFormatter';
import { moneyFormat } from '../../../../../../../../helpers/moneyFormatter';
import { InitiativeQueryComponent } from '../../../../../../../../components/initiativeQueryComponent/initiativeQueryComponent';
import { Loader } from '../../../../../../../../components/Loader/loader';
import classes from './shipment.module.css'
import { defaultSearchValueState } from '../../../../../../../../constants/defaultStates';
import { InitiativePaginationComponent } from 'components/initiativePaginationComponent/initiativePaginationComponent';


const AggregateShipments = ({ data, startDatex, endDatex, regionx, statesx, src }) => {


    const [searchValues, setSearchValues] = useState(defaultSearchValueState)
    const [total, setTotal] = useState('')
    const [inputPage, setInputPage] = useState(searchValues.page)

    const endDate = new Date(searchValues.endDate);  // Create a new date object based on the current date

    endDate.setDate(endDate.getDate() + 1);

    const [loading, setLoading] = useState(false);
    const [docs, setDocs] = useState([]);
    const { iid } = useParams();



    const queryParams = [
        {
            key: 'initiativeId',
            value: iid
        },
        {
            key: 'startDate',
            value: searchValues?.startDate
        },
        {
            key: 'endDate',
            value: (searchValues?.startDate && searchValues?.endDate) ? endDate.toISOString().split('T')[0] : ''
        },
        {
            key: 'state',
            value: searchValues.states.toLowerCase()
        },
        {
            key: 'initiativeStoresFilterGroupId',
            value: searchValues.groups
        },
        {
            key: 'productsFilterGroupId',
            value: searchValues.products
        },
        {
            key: 'statesFilterGroupId',
            value: searchValues.regions
        },
        {
            key: 'searchQuery',
            value: searchValues.searchQuery
        },
        {
            key: 'page',
            value: searchValues?.page
        },
        {
            key: 'limit',
            value: searchValues?.limit
        },
        {
            key: 'download',
            value: false
        },
    ]


    const getDocs = async () => {
        setLoading(true)
        try {
            const res = await axiosInstance.get(buildResourceURL('/shipments/aggregate', queryParams))
            setDocs(res?.data?.data)
            setTotal(res?.data?.totalCount)
        } catch (error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getDocs()
    }, [searchValues])



    const refinedData = docs?.map(i => ({
        brandName: i?.brandName,
        sku: i?.sku,
        total: i?.total,
        avg: i?.avg,
        totalCase: i?.totalCase,
        avgTotalCase: i?.avgTotalCase,
        totalValue: i?.totalValue,
        avgTotalValue: i?.avgTotalValue,
    }))

    const headers = [
        // {
        // key: 'date',
        // name: 'Date',
        // },
        {
            key: "brandName",
            name: 'Brand',
        },
        {
            key: 'sku',
            name: 'SKU',
        },
        {
            key: 'total',
            name: 'Total',
        },
        {
            key: 'avg',
            name: 'Average',
        },
        {
            key: 'totalCase',
            name: 'Total Case',
        },
        {
            key: 'avgTotalCase',
            name: 'Average Total Case',
        },
        {
            key: 'totalValue',
            name: 'Total Value',
        },
        {
            key: 'avgTotalValue',
            name: 'Average Total Value',
        },
    ]

    const querParamx = queryParams.filter((item) => (
        item.key !== 'page' && item.key !== 'limit' && item.key !== 'download'
    ))

    querParamx.push({
        key: 'download',
        value: true
    })

    return (
        < div className={classes.screenContainer}>

            <InitiativeQueryComponent
                data={data}
                searchValues={searchValues}
                setSearchValues={setSearchValues}
                serverExport2={true}
                entity="shipments/aggregate"
                queryParamsx={querParamx}
            />




            {
                loading ?
                    <div className={classes.loader_con}>
                        <Loader />
                    </div>
                    :
                    <>
                        {
                            docs.length ?
                                <div className={classes.table_container}>

                                    <div className={classes.titleAndInstructionContainer}>
                                        <p className={classes.title}>Total Aggregates (Shipments) Found: {total}</p>
                                    </div>

                                    <p className={classes.showing}>
                                        Showing: <span>{docs.length || 1} of {total}</span>
                                    </p>
                                    <div className={classes.scrollable}>
                                        <table className={classes.table}>
                                            <thead>
                                                <tr>
                                                    <th>Brand</th>
                                                    <th>SKU</th>
                                                    <th>Total Units</th>
                                                    <th>Total Cases</th>
                                                    <th>Total Value</th>
                                                    <th>Entries</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {docs.map((item, index) => (
                                                    <tr key={index}>

                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>{item.brandName}</p>
                                                            <p className={classes.tooltip}>{item.brandName}</p>
                                                        </td>
                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>{item.sku}</p>
                                                            <p className={classes.tooltip}>{item.sku}</p>
                                                        </td>
                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>{numberFormat.format(item.total)}</p>
                                                            <p className={classes.tooltip}>{numberFormat.format(item.total)}</p>
                                                        </td>

                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>{numberFormat.format(item.totalCase)}</p>
                                                            <p className={classes.tooltip}>{numberFormat.format(item.totalCase)}</p>
                                                        </td>



                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>{moneyFormat.format(item.totalValue)}</p>
                                                            <p className={classes.tooltip}>{moneyFormat.format(item.totalValue)}</p>
                                                        </td>

                                                        <td className={classes.tooltip_container}>
                                                            <p className={classes.item_text}>{numberFormat.format(item.count)}</p>
                                                            <p className={classes.tooltip}>{numberFormat.format(item.count)}</p>
                                                        </td>

                                                    </tr>
                                                ))}
                                            </tbody>


                                        </table>

                                    </div>

                                    <InitiativePaginationComponent
                                        page={searchValues.page} totalCount={total} setSearchValues={setSearchValues} setInputPage={setInputPage} inputPage={inputPage} limit={searchValues.limit}
                                    />

                                </div>
                                :
                                <InitiativeEmptyState searchValues={searchValues} name={'brand aggregated shipments'} />

                        }

                    </>
            }



        </div>
    )
}

export default AggregateShipments;