import { Box, Checkbox, Divider, Flex, Menu, MenuButton, MenuItem, MenuList, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import CustomDrawer from '../../customDrawer/customDrawer'
import SecondaryButton from '../../secondaryButton/secondarybutton'
import AddEmployee from '../addEmployee/addEmployee'
import axiosInstance from '../../../services/api'
import LineSkeleton from '../../lineSkeleton'
import EmptyState from '../../emptyState/emptyState'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import ViewEmployee from '../viewEmployee/viewEmployee'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
import PrimaryButton from '../../primaryButton/primarybutton'
import { buildResourceURL, buildResourceURL2 } from '../../../utils/helper'
import CustomSelect from '../../customSelect/customSelect'
import CustomInput from '../../customInput/customInput'
import { useSelector } from 'react-redux'
import InviteEmployee from '../inviteEmployee/inviteEmployee'
import CustomPagination from '../../customPagination/customPagination'
import ServerExport from '../../serverExport/serverExport'
import LinkEmployee from '../linkEmployee/linkEmployee'
import AssignTeam from '../assignTeam/assignTeam'
import countriesData from '../../../utils/countries.json'

const statusOptions = [
    {
        title: "All",
        value: "all"
    },
    {
        title: "Pending",
        value: false
    },
    {
        title: "Approved",
        value: true
    },
]

const EmployeeTable = ({title, clientId}) => {
    const [employees, setEmployees] = useState([]);
    const [total, setTotal] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingc, setLoadingc] = useState(false);
    const [loadings, setLoadings] = useState(false);
    const [loadingx, setLoadingx] = useState(false);
    const [triggerClose, setTriggerClose] = useState("");
    const [modalType, setModalType] = useState("");
    const [selectedUser, setSelectedUser] = useState(null)
    const [refetch, setRefetch] = useState("");
    const [binView, setBinView] = useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [status, setStatus] = useState("all");
    const [country, setCountry] = useState('')

    const currentUser = useSelector(({ userData }) => userData.currentUser);

    const [survey, setSurvey] = useState('');
    const [surveys, setSurveys] = useState([]);

    const subid = currentUser?.role?.includes("Subuser")
    ? currentUser?.mainUser
    : "";

    const [selectedEmployees, setSelectedEmployees] = useState([])
    const [isSelecting, setIsSelecting] = useState(false)

    const queryParamsy = [
        {
          key: "initiativeStore",
          value: "",
        },
        {
          key: "page",
          value: 1,
        },
        {
          key: "client",
          value:
            currentUser?.role === "Client"
              ? currentUser?._id
              : currentUser?.role?.includes("Subuser")
              ? subid
              : clientId,
        },
        {
          key: "limit",
          value: 50,
        },
      ];
      
    
      const getSurveys = async () => {
        setLoadings(true)
        try {
          const { data } = await axiosInstance.get(
            buildResourceURL("surveys", queryParamsy)
          );
          setSurveys(data?.data);
        } catch (error) {
          console.error(error?.response?.data?.message);
        } finally {
            setLoadings(false)
        }
      };
    
      useEffect(() => {
        getSurveys();
      }, [clientId]);

    const selectAll = () => {
        if (selectedEmployees?.length === employees?.length) {
          setSelectedEmployees([]);
        } else {
          setSelectedEmployees(employees?.map((i) => i?._id));
        }
      };

    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast()

    const employeeUrl = currentUser?.role === "Agency" ? 'agencies/employees' : currentUser?.role === 'Client' ? `clients/${clientId}/employees` : currentUser?.role === 'Basic Subuser' ? `clients/${currentUser?.mainUser}/employees` : 'employees'

    const [page, setPage] = useState(1)
    const limit = 10

    const queryParams = [
        {
            key: 'isDeleted',
            value: binView === false ? "" : binView
        },
        {
            key: 'role',
            value: title
        },
        {
            key: 'search',
            value: searchVal
        },
        {
            key: 'page',
            value: page
        },
        {
            key: 'limit',
            value: limit
        },
        {
            key: 'country',
            value: country
        }
    ]

    const queryParamsx = [
        {
            key: 'isDeleted',
            value: binView === false ? "" : binView
        },
        {
            key: 'role',
            value: title
        },
        {
            key: 'clientId',
            value: clientId
        },
        {
            key: 'search',
            value: searchVal
        },
        {
            key: 'country',
            value: country
        }
    ]

    const getEmployees = async() => {
        setLoading(true)
        try {
            const { data } = await axiosInstance.get(buildResourceURL(clientId ? `clients/${clientId}/employees` : employeeUrl, queryParams))
            setEmployees(data?.data)
            setTotal(data?.totalCount)
        } catch(error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getEmployees()
    }, [triggerClose, refetch, searchVal, status, page, country])

    const handleSelect = (event) => {
        const value = event.target.value;
        const isChecked = event.target.checked;
    
        if (isChecked) {
          // Add item to the array if it doesn't already exist
          if (!selectedEmployees?.includes(value)) {
            setSelectedEmployees([...selectedEmployees, value]);
          }
        } else {
          // Remove item from the array if it exists
          const filteredItems = selectedEmployees.filter((item) => item !== value);
          setSelectedEmployees(filteredItems);
        }
      };

    const handleModal = (type, user) => {
        setModalType(type)
        setSelectedUser(user)
        onOpen()
    }

    // const approveEmployee = async() => {
    //     setLoadingx(true)
    //     try {
    //         const { data } = await axiosInstance.patch(`${employeeUrl}/${selectedUser?.id}/approve`)
    //         toast({
    //         description: data?.message,
    //         status: "success",
    //         position: "top"
    //         });
    //         setRefetch(Math.random())
    //         onClose()
    //     } catch(error) {
    //         toast({
    //         title: "An Error Occurred",
    //         description: error?.response?.data?.message,
    //         status: "error",
    //         position: "top"
    //         });
    //     } finally {
    //         setLoadingx(false)
    //     }
    // }

    const suspendEmployee = async() => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.delete(`${employeeUrl}/${selectedUser?.id}/soft`)
            toast({
            description: data?.message,
            status: "success",
            position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch(error) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }

    const restoreEmployee = async() => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.patch(`${employeeUrl}/${selectedUser?.id}/restore`)
            toast({
            description: data?.message,
            status: "success",
            position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch(error) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }

    const deleteEmployee = async() => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.delete(`${employeeUrl}/${selectedUser?.id}`)
            toast({
            description: data?.message,
            status: "success",
            position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch(error) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }

    const removeEmployee = async() => {
        setLoadingx(true)
        try {
            const { data } = await axiosInstance.delete(`clients/employees/${selectedUser?.id}`)
            toast({
            description: data?.message,
            status: "success",
            position: "top"
            });
            setRefetch(Math.random())
            onClose()
        } catch(error) {
            toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top"
            });
        } finally {
            setLoadingx(false)
        }
    }

    const handleViewBin = () => {
        setBinView(!binView)
        setRefetch(Math.random())
    }

    const headers = [
        {
          key: '_id',
          name: 'ID',
        },
        {
          key: 'firstName',
          name: 'First Name',
        },
        {
          key: 'lastName',
          name: 'Last Name',
        },
        {
          key: 'email',
          name: 'Email',
        },
        {
          key: 'phoneNumber',
          name: 'Phone Number',
        },
        {
          key: 'role',
          name: 'Role',
        },
        {
          key: 'isApproved',
          name: "Status",
        },
        {
          key: 'isDeleted',
          name: 'Deleted?',
        },
        {
          key: 'createdAt',
          name: 'Joined',
        },
      ]

      const surveyOptions = !loadings ? surveys?.map(x => ({
        title: x?.name,
        value: x?._id
      })) : []

      const assignUsers = async () => {
        setLoadingx(true);
        const datax = {
          userIDs: selectedEmployees
        };
        try {
          const { data } = await axiosInstance.patch(
            `/surveys/${survey}/assign-users`,
            datax
          );
          toast({
            description: data?.message,
            status: "success",
            position: "top",
          });
          setIsSelecting(false);
          setSelectedEmployees([]);
          setRefetch(Math.random())
          onClose();
        } catch (error) {
          toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
            position: "top",
          });
        } finally {
          setLoadingx(false);
        }
      };

      const countries = countriesData?.map(x => ({
                    title: x?.name,
                    value: x?.name?.toLowerCase()
                  }))

  return (
    <>
    <Flex mb="1rem" justify={"flex-end"}>
    {currentUser?.role === 'Super Admin' &&
        <Menu width={"200px !important"}>
            <MenuButton>
                <BiDotsVerticalRounded style={{cursor: "pointer"}} />
            </MenuButton>
            <MenuList>
                <Divider my=".2rem" />
                <MenuItem onClick={handleViewBin} fontWeight={"500"} fontSize={".85rem"}>{binView ? "Back to full list" : "View Bin"}</MenuItem>
                <Divider my=".2rem" />
            </MenuList>
        </Menu>}
        </Flex>
        <Flex alignItems={"center"} gap=".8rem" justifyContent={"space-between"}>
            
            <Flex gap="1.5rem" alignItems={"center"}>
            <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>{title}s <span style={{padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054"}}>{total}</span></Text>
                <CustomSelect
                required
                options={statusOptions}
                onChange={e => setStatus(e.target.value)}
                height="40px !important"
                mt="0"
                borderRadius={"12px"}
                width="150px"
                cursor="pointer"
                />
                <CustomSelect
                    required
                    options={[{title: "All Countries", value: ""}, ...countries]}
                    onChange={e => setCountry(e.target.value)}
                    height="40px !important"
                    mt="0"
                    borderRadius={"12px"}
                    width="170px"
                    cursor="pointer"
                    />
                <CustomInput
                type={"text"}
                required
                value={searchVal}
                placeholder={`Search ${title}s..`}
                onChange={e => setSearchVal(e.target.value)}
                mt="0"
                sType="search"
                py="1.19rem"
                width="350px"
                />
            </Flex>

            <Flex alignItems={"center"} gap="1rem">
            
            {/* <ExportCSV headers={headers} items={employees} /> */}
            <ServerExport entity={'employees'} queries={buildResourceURL2('', queryParamsx)} />

            <SecondaryButton onClick={() => setIsSelecting(!isSelecting)} text={isSelecting ? 'Cancel Selection' : `Select ${title}s`} loading={false}  />
            {isSelecting && <SecondaryButton onClick={() => handleModal('survey', {})} text={'Assign to survey'} loading={false}  />}

            {((currentUser?.role?.includes('Admin') && !clientId) || currentUser?.role === 'Client' || currentUser?.role === 'Agency') &&
            <>
            <CustomDrawer
            position={"right"}
            title={`Invite ${title}s`}
            triggerClose={triggerClose}
            setTriggerClose={setTriggerClose}
            toggleElement={
                <SecondaryButton type="submit" text={`Invite ${title}s`} loading={false}  />
            }
            content={
                <InviteEmployee title={title} setRefetch={setRefetch} setTriggerClose={setTriggerClose} />
            }
            />

            </>}

            {((currentUser?.role?.includes('Admin') && !clientId) || currentUser?.role === 'Client') &&
            <CustomDrawer
            position={"right"}
            title={`Add ${title}`}
            triggerClose={triggerClose}
            setTriggerClose={setTriggerClose}
            toggleElement={
                <SecondaryButton type="submit" text={`Add ${title}`} icon="add" loading={false}  />
            }
            content={
                currentUser?.role === 'Client' ? <LinkEmployee title={title} setTriggerClose={setTriggerClose} /> : <AddEmployee title={title} clientId={clientId} loading={loadingc} setTriggerClose={setTriggerClose} setLoading={setLoadingc} />
            }
            />}

            

            </Flex>

        </Flex>
    <Box mt=".7rem" p="0 1rem" bg="#FFF" height={"84vh"} overflowY={"scroll"} borderRadius={"12px"} position={"relative"}>
        
        <Table mt="1.2rem" borderRadius={"12px"}>
            <Thead bg="#F9FAFB" border="1px solid #EAECF0">
                <Tr>
                    {isSelecting === true &&
                        <th><Checkbox
                        onChange={selectAll}
                        isChecked={selectedEmployees?.length === employees?.length}
                        colorScheme="green"
                        />
                        </th>
                    }
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Name</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Email</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Country</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Phone</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Date Joined</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Suspended?</Th>
                    <Th textTransform={"capitalize !important"} color={"#475467"}>Status</Th>
                    <Th></Th>
                </Tr>
            </Thead>
            
            {loading ? 
            <LineSkeleton width="100%" num={10} />
            :
            employees?.length < 1 ?
            <EmptyState mt="1.5rem" text="There are no employees yet" />
            :
            <Tbody>
            
            {employees?.map((({_id: id, firstName, lastName, email, profilePicture, isApproved, isDeleted, phoneNumber, createdAt, deviceChangeRequestsCount, country}) => {
                const data = {id, firstName, lastName, email, profilePicture, isApproved, isDeleted, phoneNumber, createdAt, deviceChangeRequestsCount}
                return (
                    <Tr key={id}>
                        {isSelecting === true && (
                        <td>
                            <Checkbox
                            onChange={(e) => handleSelect(e)}
                            value={id}
                            isChecked={selectedEmployees?.includes(id)}
                            colorScheme="green"
                            />
                        </td>
                        )}
                        <Td>
                        <Flex alignItems={"center"} gap=".4rem">
                            <Box height={"40px"} width={"40px"} backgroundImage={profilePicture} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}  />
                            <Box>
                                <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{firstName + " " + lastName}</Text>
                            </Box>
                        </Flex>
                        </Td>
                        <Td color={"#475467"} fontSize={".9rem"}>{email}</Td>
                        <Td color={"#475467"} fontSize={".9rem"} textTransform={"capitalize"}>{country}</Td>
                        <Td color={"#475467"} fontWeight={"500"} fontSize={".85rem"}>{phoneNumber}</Td>
                        <Td fontWeight={"500"} fontSize={".85rem"}>{createdAt?.slice(0,10)}</Td>
                        <Td fontWeight={"500"} fontSize={".85rem"}>{isDeleted ? "Yes" : "No"}</Td>
                        <Td>
                            <Text textAlign={"center"} fontSize={"12px"} fontWeight={"500"} p=".1rem" borderRadius={"16px"} border={isApproved ? "1px solid #ABEFC6" : "1px solid lightgray"} color={isApproved ? "#067647" : "#475467"} bg={isApproved ? "#ECFDF3" : "#F9FAFB"}>{isApproved ? "Verified" : "Unverified"}</Text>
                        </Td>
                        <Td>
                            
                            <Menu width={"200px !important"}>
                                <MenuButton>
                                    <BiDotsVerticalRounded style={{cursor: "pointer"}} />
                                </MenuButton>
                                <MenuList>
                                    <Divider my=".2rem" />
                                    <CustomDrawer 
                                    position={"right"}
                                    title={`${title} details`}
                                    triggerClose={triggerClose}
                                    toggleElement={
                                        <MenuItem fontWeight={"500"} fontSize={".85rem"}>View {title?.toLowerCase()}</MenuItem>
                                    }
                                    content={
                                        <ViewEmployee title={title} data={data} refetch={refetch} setRefetch={setRefetch} setTriggerClose={setTriggerClose} triggerClose={triggerClose} />
                                    }
                                    />
                                    {title === 'Supervisor' &&
                                    <>
                                    <Divider my=".2rem" />
                                    <CustomDrawer 
                                    position={"right"}
                                    title={`Assign Team`}
                                    triggerClose={triggerClose}
                                    toggleElement={
                                        <MenuItem fontWeight={"500"} fontSize={".85rem"}>Assign Team</MenuItem>
                                    }
                                    content={
                                        <AssignTeam title={'Promoter'} setTriggerClose={setTriggerClose} type={"assign"} employeeId={id} setRefetch={setRefetch} />
                                    }
                                    />
                                    </>
                                    }
                                    {/* <Divider my=".2rem" />
                                    {(!isApproved && currentUser?.role !== "Agency") && <MenuItem onClick={() => handleModal('approve', {firstName, lastName, id})} fontWeight={"500"} fontSize={".85rem"}>Approve {title?.toLowerCase()}</MenuItem>} */}
                                    <Divider my=".2rem" />
                                    {!(currentUser?.role?.includes('Admin')) && <MenuItem onClick={() => handleModal('remove', {firstName, lastName, id})} fontWeight={"500"} fontSize={".85rem"}>Remove {title?.toLowerCase()}</MenuItem>}
                                    <Divider my=".2rem" />
                                    {currentUser?.role === "Super Admin" &&
                                    <>
                                    {isDeleted ? 
                                    <MenuItem onClick={() => handleModal('restore', {firstName, lastName, id})} fontWeight={"500"} fontSize={".85rem"}>Restore {title?.toLowerCase()}</MenuItem>
                                    :
                                    <MenuItem onClick={() => handleModal('suspend', {firstName, lastName, id})} fontWeight={"500"} fontSize={".85rem"}>Suspend {title?.toLowerCase()}</MenuItem>
                                    }
                                    <Divider my=".2rem" />
                                    <MenuItem onClick={() => handleModal('delete', {firstName, lastName, id})} color={"crimson"} fontWeight={"500"} fontSize={".85rem"}>Delete {title?.toLowerCase()}</MenuItem>
                                    <Divider my=".2rem" />
                                    </>}
                                     
                                </MenuList>
                            </Menu>
                        </Td>
                    </Tr>
                )
            }))
            }
            </Tbody>}
            
        </Table>

        <Modal isCentered motionPreset='slideInBottom' isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize={"1rem"} textTransform={"capitalize"}>{`${modalType} ${title}`}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {/* {modalType === 'approve' &&
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm approval of {selectedUser?.firstName + " " + selectedUser?.lastName}?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                            <PrimaryButton onClick={approveEmployee} text="Approve" width="150px" padding="1rem !important" loading={loadingx} />
                        </Flex>
                    </>
                    } */}
                    {modalType === 'suspend' &&
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm suspension of {selectedUser?.firstName + " " + selectedUser?.lastName}?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                            <PrimaryButton onClick={suspendEmployee} text="Suspend" width="150px" padding="1rem !important" loading={loadingx} />
                        </Flex>
                    </>
                    }
                    {modalType === 'restore' &&
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm restore of {selectedUser?.firstName + " " + selectedUser?.lastName}?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                            <PrimaryButton onClick={restoreEmployee} text="Restore" width="150px" padding="1rem !important" loading={loadingx} />
                        </Flex>
                    </>
                    }
                    {modalType === 'delete' &&
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm deletion of {selectedUser?.firstName + " " + selectedUser?.lastName}?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                            <PrimaryButton onClick={deleteEmployee} text="Delete" width="150px" bg="crimson !important" padding="1rem !important" loading={loadingx} />
                        </Flex>
                    </>
                    }
                    {modalType === 'remove' &&
                    <>
                        <Text textAlign={"center"} color={"#475467"} fontWeight={"500"}>Confirm removal of {selectedUser?.firstName + " " + selectedUser?.lastName}?</Text>
                        <Flex justifyContent={"center"} my="1.3rem">
                            <PrimaryButton onClick={removeEmployee} text="Remove" width="150px" bg="crimson !important" padding="1rem !important" loading={loadingx} />
                        </Flex>
                    </>
                    }
                    {modalType === "survey" && (
                <>
                  <Text
                    textAlign={"center"}
                    color={"#475467"}
                    fontWeight={"500"}
                  >
                    Assign <strong>{selectedEmployees?.length}</strong> employees to a survey
                  </Text>
                  <br />
                  <CustomSelect
                    required
                    label={"Survey"}
                    options={surveyOptions}
                    placeholder={"Survey"}
                    onChange={e => setSurvey(e.target.value)}
                    borderRadius="10px"
                    mt="1rem"
                  />
                  <Flex justifyContent={"center"} my="1.3rem">
                    <PrimaryButton
                      onClick={assignUsers}
                      text="Add"
                      width="150px"
                      padding="1rem !important"
                      loading={loadingx}
                    />
                  </Flex>
                </>
              )}
                    <Text pb="1rem" textAlign={"center"} fontSize={".9rem"} fontWeight={"500"} cursor={"pointer"} onClick={onClose}>Cancel</Text>
                </ModalBody>
            </ModalContent>
        </Modal>

        


         <CustomPagination
         page={page}
         setPage={setPage}
         pageSize={limit}
         totalElements={total}
         />
    </Box>
    </>
  )
}

export default EmployeeTable