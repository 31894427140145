import React, { useEffect, useState } from 'react'
import CustomInput from '../../../../components/customInput/customInput';
import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import PrimaryButton from '../../../../components/primaryButton/primarybutton';
import axiosInstance from '../../../../services/api';
import { buildResourceURL } from '../../../../utils/helper';
import { useSelector } from 'react-redux';

const AssignInitiative = ({setTriggerClose, setRefetch, iid, mode}) => {
  const currentUser = useSelector(({userData})=>  userData?.currentUser);
  
  const [searchVal, setSearchVal] = useState("");
  const [selected, setSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const [initiatives, setInitiatives] = useState([]);
  const toast = useToast();

  

    const queryParams = [
        {
            key: 'isDeleted',
            value: false
        },
        {
            key: 'client',
            value: currentUser?._id
        },
        {
            key: 'limit',
            value: 50
        }
    ]

    const getInitiatives = async() => {
      try {
          const { data } = await axiosInstance.get(buildResourceURL('initiatives', queryParams))
          setInitiatives(data?.data)
      } catch(error) {
          console.error(error?.response?.data?.message)
      } finally {
          setLoading(false)
      }
    }

  useEffect(() => {
    getInitiatives()
  }, [])

  const assign = async(e) => {
    e.preventDefault()
    setLoadingx(true)
    const datax = {
      initiativeId: selected,
    }

    try {
      const {data} = await axiosInstance.patch(`/subusers/${iid}/${mode === 'unassign' ? `unassign-initiative` : `assign-initiative`}`, datax)
      toast({
        description: data?.message,
        status: "success",
        position: 'top'
      });
      setTriggerClose('close' + (Math.random()))
      setRefetch(Math.random())
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
      });
    } finally {
      setLoadingx(false)
    }
  }

  return (
    <>
    <form>
      <CustomInput
        type={"text"}
        required
        placeholder={"Search Initiatives"}
        onChange={e => setSearchVal(e.target.value)}
        // sType={"search"}
      />
    </form>
    <br />
    <Box>
      {initiatives?.filter(i => i.name.toLowerCase().includes(searchVal?.toLowerCase()))?.map(({_id: id, name, email}) => {
        return (
          <Flex key={id} padding={"0"} borderRadius={"16px"} p=".5rem" border={selected === id ? "2px solid #7F56D9" : "2px solid #FFF"} mb=".7rem" cursor={"pointer"} onClick={() => setSelected(id)}>
              <Flex alignItems={"center"} w="100%" justifyContent={"space-between"}>
                  <Flex alignItems={"center"}>
                      {/* <Box height={"50px"} width={"60px"} backgroundImage={profilePicture} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}  /> */}
                      <Box>
                          <Text mb=".2rem" color={"#101828"} fontSize={".9rem"} fontWeight={"500"} textTransform={"capitalize"}>{name}</Text>
                          <Text mb=".2rem" color={"#101828"} fontSize={".85rem"} opacity={".6"} fontWeight={"500"}>{email}</Text>
                      </Box>
                  </Flex>
              </Flex>
          </Flex>
        )
      })}
    </Box>

    <PrimaryButton mt="5rem" type="submit" text={mode} onClick={assign} isDisabled={selected === ''} loading={loadingx} />
    </>
  )
}

export default AssignInitiative;