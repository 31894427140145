export const initiativeNavItems = [
    //  "Stores",
    "Mechanics",
    "Shelf & POSM",
    "Competitors",
    "Price Checks",
    "Sales",
    "Shelf Inventory",
    "Shipments",
    "Store Inventory",
    "Tasks",
    "Attendance",
    "Reports",
    "Rewards",
    "Orders",
    "MSL",
    "Share of Shelf",
    "Payments",
    "Surveys",
    "Message Center",
    "Active Resources",
    "Out Of Stock",
]

export const chunkInitiativeNavItems = [
    "Analytics", "Table-Stores", 
]

initiativeNavItems.sort()
chunkInitiativeNavItems.sort()