/* eslint-disable no-loop-func */
import { CiSearch } from "react-icons/ci";
import classes from "./initiativeQueryComponent.module.css";
import statesLgaJson from "../../utils/stateLGAs";
import { defaultSearchValueState } from "../../constants/defaultStates";
import ServerExport from "../serverExport/serverExport";
import {buildResourceURL2 } from "../../utils/helper";
import ExportCSV from "../exportCsv";

import moment from "moment";
import { NewExport, ServerExport2 } from "components/NewExport/NewExport";


export function InitiativeQueryComponent({
  data,
  searchValues,
  setSearchValues,
  filterList1,
  entity,
  queryParamsx,
  aggregate,
  noStartDate,
  newExcel,
  searchFn,
  noSearch,
  placeholder,
  smallInput,
  customSelect,
  serverExport2
}) {
  const { headers, refinedData } = aggregate || {};
  const { excelData, title } = newExcel || {};

  const date = new Date();

  const { page, ...filteredSearchValues } = searchValues;
  const array = Object.values(filteredSearchValues).map((item) =>
    item === moment(date).format('YYYY-MM-DD') ? false : !!item
  );
  const hasFilter = array.includes(true);

  const places = statesLgaJson?.map((i) => ({
    title: i?.state,
    value: i?.state?.toLowerCase(),
    listValue: i?.lgas?.map((i) => ({
      title: i,
      value: i,
    })),
  }));

  const regionsData = data?.client?.statesFilterGroups || [];
  const regionsDataOpts = regionsData?.map((i) => ({
    title: i?.name,
    value: i?._id,
  }));

  const storeGroupData = data?.initiativeStoresFilterGroups || [];
  const storeGroupDataOpts = storeGroupData?.map((i) => ({
    title: i?.name,
    value: i?._id,
  }));

  const productGroupData = data?.productsFilterGroups || [];
  const productGroupDataOpts = productGroupData?.map((i) => ({
    title: i?.name,
    value: i?._id,
  }));

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setSearchValues((prev) => ({ ...prev, [name]: value }));
  };

  places?.sort((a, b) => a.title.localeCompare(b.title));
  storeGroupData.sort((a, b) => a.name.localeCompare(b.name));
  regionsData?.sort((a, b) => a.name.localeCompare(b.name));
  productGroupData?.sort((a, b) => a.name.localeCompare(b.name));


 
  return (
    <>
      <div className={classes.topContainer}>
        {/* --------- input container */}
        {!noSearch && (
          <div className={`${classes.inputContainer} ${smallInput ? classes.smallInputContainer : ''}`}>
            <CiSearch className={classes.searchIcon} />
            <input
              className={classes.input}
              type="text"
              placeholder={placeholder || "Search by brand name / sku"}
              onChange={
                searchFn
                  ? (e) => {
                    searchFn(e.target.value);
                  }
                  : (e) => handleInputChange(e)
              }
              name="searchQuery"
              value={searchValues?.searchQuery}
            />
          </div>
        )}

        {/* --------- date container */}
        <div className={classes.dateGrouped}>
          <div className={classes.dateContainer}>
            <input
              name="startDate"
              value={searchValues.startDate}
              onChange={handleInputChange}
              className={classes.input}
              type="date"
            />
          </div>
          to
          <div className={classes.dateContainer}>
            <input
              name="endDate"
              value={searchValues?.endDate}
              onChange={handleInputChange}
              className={classes.input}
              type="date"
            />
          </div>
        </div>



        <>
          {data && (
            <>
              <div className={classes.selectContainer}>
                <select
                  value={searchValues?.states}
                  name="states"
                  onChange={handleInputChange}
                  className={classes.input}
                >
                  <option value={""}>All States</option>
                  {places?.map((item, index) => (
                    <option key={index}>{item.title}</option>
                  ))}
                </select>
              </div>

              <div className={classes.selectContainer}>
                <select
                  value={searchValues?.regions}
                  name="regions"
                  onChange={handleInputChange}
                  className={classes.input}
                >
                  <option value={""}>All Regions</option>
                  {regionsDataOpts?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>

              <div className={classes.selectContainer}>
                <select
                  value={searchValues?.groups}
                  name="groups"
                  onChange={handleInputChange}
                  className={classes.input}
                >
                  <option value={""}>All Store Groups</option>
                  {storeGroupDataOpts?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>

              <div className={classes.selectContainer}>
                <select
                  value={searchValues?.products}
                  name="products"
                  onChange={handleInputChange}
                  className={classes.input}
                >
                  <option value={""}>All Product Groups</option>
                  {productGroupDataOpts?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}

          {filterList1 && Object.keys(filterList1).length && (
            <div className={classes.dateContainer}>
              <select
                value={searchValues[filterList1?.name] || ""}
                name={filterList1?.name}
                onChange={handleInputChange}
                className={classes.input}
              >
                <option value={""}>{filterList1?.title}</option>
                {filterList1?.data?.map((item, index) => (
                  <option value={item?.value} key={index}>
                    {item?.title}
                  </option>
                ))}
              </select>
            </div>
          )}

          {customSelect && customSelect}

          {!noSearch && (
            <button
              disabled={!hasFilter}
              onClick={() => setSearchValues(defaultSearchValueState)}
              style={!hasFilter ? { opacity: "0.2" } : {}}
              className={classes.clearFilter}
            >
              Clear Filters{" "}
            </button>
          )}
        </>
        {/* --------- export button */}
        {!noSearch && (
          <>
            {aggregate ? (
              <ExportCSV headers={headers} items={refinedData} />
            ) : excelData ? (
              <NewExport excelData={excelData} title={title} />
            ) :

              serverExport2 ?
                <ServerExport2  entity={entity || ""} queryParamsx={queryParamsx} />
                :

                (
                  <ServerExport
                    entity={entity || ""}
                    queries={buildResourceURL2("", queryParamsx || [])}
                  />
                )
            }
          </>
        )}
      </div>

      {/* <div className={classes.secondContainer}>

     
      </div> */}
    </>
  );
}


