import ListCompare from '../../../../../../../../components/listCompare/listCompare'
import PeriodAggregate from './periodAggregate'
import Msl from './msl'
import AggregateMsl from './aggregateMsl'
import { InitiativeHeaderTab } from '../../../../../../../../components/initiativeHeaderTab/initiativeHeaderTab'
import { useState } from 'react'
import classes from './msl.module.css'
import PreloadedBrands from './PreloadedMsl/preloadedBrands'

const AllMsl = ({initiative, setRefetch}) => {


    const assignor = {
        "All MSL": <Msl data={initiative} />,
        "Brand Aggregates": <AggregateMsl data={initiative} />,
        "Store Aggregates": <PeriodAggregate iData={initiative} />,
        "Not Submitted": <ListCompare listType={'msl'} />,
        "Preloaded MSL Brands": <PreloadedBrands initiative={initiative} setRefetch={setRefetch} />
    }

    const [selected, setSelected] = useState(Object.keys(assignor)[0])

    return (
        <div className={classes.container}>
            <InitiativeHeaderTab list={Object.keys(assignor)} selected={selected} setSelected={setSelected} />
            <div>
                {assignor[selected]}
            </div>
        </div>
    )
}
export default AllMsl;