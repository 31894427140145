import PeriodAggregate from './periodAggregate'
import Oos from './oos'
import AggregateOos from './aggregateOos'
import { InitiativeHeaderTab } from '../../../../../../../../components/initiativeHeaderTab/initiativeHeaderTab'
import { useState } from 'react'
import classes from './oos.module.css'

const AllOos = ({ initiative }) => {
  const assignor = {
    "All OOS": <Oos data={initiative} />,
    "Brand Aggregates": <AggregateOos data={initiative} />,
    "Store Aggregates": <PeriodAggregate iData={initiative} />
  }

  const [selected, setSelected] = useState(Object.keys(assignor)[0])

  return (
    <div className={classes.container}>
      <InitiativeHeaderTab list={Object.keys(assignor)} selected={selected} setSelected={setSelected} />
      <div>
        {assignor[selected]}
      </div>
    </div>
  )
}

export default AllOos;