import React, { useEffect, useState } from 'react'
import { Box, Checkbox, Divider, Flex, Text, useToast } from '@chakra-ui/react';
import axiosInstance from '../../../../../../services/api';
import CustomInput from '../../../../../../components/customInput/customInput';
import PrimaryButton from '../../../../../../components/primaryButton/primarybutton';


const CreateGroup = ({setTriggerClose, iid, setRefetch, allProducts}) => {
  const [name, setName] = useState('');
  const [searchVal, setSearchVal] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loadingx, setLoadingx] = useState(false);


  const toast = useToast();


  const createGroup = async(e) => {
    e.preventDefault()
    setLoadingx(true)
    const datax = {
      name,
      products: selectedProducts
    }
    try {
      const {data} = await axiosInstance.post(`initiatives/${iid}/products-filter-groups`, datax)
      toast({
        description: data?.message,
        status: "success",
        position: "top"
      });
      setTriggerClose('close' + (Math.random()))
      setRefetch(Math.random())
    } catch(error) {
      toast({
        title: "An Error Occurred",
        description: error?.response?.data?.message,
        status: "error",
        position: "top"
      });
    } finally {
      setLoadingx(false)
    }
  }

  const handleSelect = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      // Add item to the array if it doesn't already exist
      if (!selectedProducts.includes(value)) {
        setSelectedProducts([...selectedProducts, value]);
      }
    } else {
      // Remove item from the array if it exists
      const filteredItems = selectedProducts.filter((item) => item !== value);
      setSelectedProducts(filteredItems);
    }
  }

  const filteredProducts = searchVal?.length > 0 ? allProducts?.filter(i => i?.toLowerCase().includes(searchVal?.toLowerCase())) : allProducts

  console.log(allProducts)


  return (
    <form onSubmit={createGroup}>
        <CustomInput
        type={"text"}
        required
        placeholder={"Group Name"}
        onChange={e => setName(e.target.value)}
        label={'Group Name'}
        mb="1rem"
        />

        <Divider />

        <CustomInput
        type={"text"}
        placeholder={"Search products"}
        onChange={e => setSearchVal(e.target.value)}
        />

        <Box mt="1rem">
          {filteredProducts?.map((i, index) => {
            return (
              <Flex key={index} alignItems={"center"} gap=".5rem" mb=".5rem" border={'.5px solid lightgray'} p=".3rem .5rem .45rem .5rem" borderRadius={'6px'}>
                <Checkbox onChange={e => handleSelect(e)} value={i} isChecked={selectedProducts?.includes(i)} colorScheme='green' />
                <Text textTransform={'capitalize'} fontSize={'.9rem'} fontWeight={'600'}>{i}</Text>
              </Flex>
            )
          })}
        </Box>
       
        <PrimaryButton mt="3rem" type="submit" text="Create Group" loading={loadingx} />
    </form>
  )
}

export default CreateGroup;