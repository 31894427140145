import React, { useEffect, useState } from 'react'
import CustomInput from '../../customInput/customInput';
import { Box, Flex, Text } from '@chakra-ui/react';
import CustomSelect from '../../customSelect/customSelect';
import ServerExport from '../../serverExport/serverExport';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../services/api';
import { buildResourceURL, buildResourceURL2 } from '../../../utils/helper';
import SpinLoader from '../../spinLoader/spinLoader';
import EmptyState from '../../emptyState/emptyState';
import ExportCSV from '../../exportCsv';

const AttendanceAgg = ({userId}) => {
    const [loading, setLoading] = useState(false);
    const [loadingx, setLoadingx] = useState(false);
    const [initiatives, setInitiatives] = useState([]);
    const [initiative, setInitiative] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [records, setRecords] = useState([])
  
  
    const getInitiatives = async() => {
        setLoading(true)
        try {
            const res = await axiosInstance.get('initiatives')
            setInitiatives(res?.data?.data)
        } catch(error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
    }
  
    useEffect(() => {
        getInitiatives()
    }, [])
  
    const initiativeOptions = initiatives?.map(i => ({
      title: i?.name,
      value: i?._id
    }))

    const queryParams = [
        {
            key: 'userId',
            value: userId
        },
        {
            key: 'initiativeId',
            value: initiative
        },
        {
            key: 'startDate',
            value: startDate
        },
        {
            key: 'endDate',
            value: endDate
        }
    ]

    const getAttendanceRecords = async () => {
        setLoadingx(true);
        try {
          const { data } = await axiosInstance.get(buildResourceURL('attendances/aggregate/user', queryParams));
          setRecords(data?.data);
        } catch (error) {
          console.error(error?.response?.data?.message);
        } finally {
          setLoadingx(false);
        }
      };
    
      useEffect(() => {
        getAttendanceRecords();
      }, [initiative, startDate, endDate]);

      const refinedData = records?.aggregateResult?.map(i => ({
        date: i?.clockInDate,
        store: `${i?.store?.name}, ${i?.store?.area} ${i?.store?.state}`,
        totalHoursWorked: i?.totalHoursWorked
      }))
    
      const headers = [
            {
            key: 'date',
            name: 'Date',
            },
            {
            key: 'store',
            name: 'Store',
            },
            {
            key: 'totalHoursWorked',
            name: 'Total Hours Worked',
            },
        ]

  return (
    <>
    <Flex justifyContent={"flex-end"} mb="1rem" gap=".2rem">
        <ExportCSV headers={headers} items={refinedData} />
            </Flex>
            <Flex justifyContent={"flex-end"} mb="1rem" gap=".2rem">
              <CustomSelect
              options={[{title: "All Initiatives", value: ""}, ...initiativeOptions]}
              placeholder={"Initiative"}
              onChange={e => setInitiative(e.target.value)}
              height="40px !important"
              mt="0 !important"
              borderRadius={"12px"}
              cursor="pointer"
              />
              <Flex gap=".2rem" alignItems={"center"}>
                <CustomInput
                width="130px"
                type={"date"}
                mt="0 !important"
                py=".5rem !important"
                required
                placeholder={"Choose Date"}
                onChange={e => setStartDate(e.target.value)}
                cursor="pointer"
                />
                <p>-</p>
                <CustomInput
                width="130px"
                type={"date"}
                mt="0 !important"
                py=".5rem !important"
                required
                placeholder={"Choose Date"}
                onChange={e => setEndDate(e.target.value)}
                cursor="pointer"
                />
              </Flex>
            </Flex>

            {loadingx ? 
            <SpinLoader />
            :
            records?.aggregateResult?.length < 1 ?
            <EmptyState text="No records found" />
            :
            records?.aggregateResult?.map(({store, clockInDate, totalHoursWorked}, index) => {
                return (
                    <Box
                    key={index}
                    p=".7rem 1rem"
                    mb=".8rem"
                    borderRadius={"10px"}
                    border=".5px solid #D0D5DD"
                    >
                    <Flex
                        width={"90%"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                    >
                
                        <Box>
                        <Text
                            mb=".2rem"
                            fontWeight={"500"}
                            color={"#667085"}
                            fontSize={".9rem"}
                        >
                            Total Hours Worked
                        </Text>
                        <Text
                            fontWeight={"700"}
                            color={totalHoursWorked >= 8 ? "green" : "crimson"}
                            fontSize={".9rem"}
                        >
                            {totalHoursWorked || "--"}
                        </Text>
                        </Box>

                        <Box mt=".3rem">
                        <Text
                            mb=".2rem"
                            fontWeight={"500"}
                            color={"#667085"}
                            fontSize={".9rem"}
                        >
                            Date
                        </Text>
                        <Text
                            fontWeight={"500"}
                            color={"#98A2B3"}
                            fontSize={".83rem"}
                        >
                            {clockInDate}
                        </Text>
                        </Box>
                        
                    </Flex>
                    <Flex gap="1rem" alignItems={"center"}>
                    
                    <Box mt=".3rem">
                        <Text
                            mb=".2rem"
                            fontWeight={"500"}
                            color={"#667085"}
                            fontSize={".9rem"}
                        >
                            Store
                        </Text>
                        <Text
                            fontWeight={"500"}
                            color={"#98A2B3"}
                            fontSize={".9rem"}
                            textTransform={"capitalize"}
                        >
                            {`${store?.name}, ${store?.area} ${store?.state}`}
                        </Text>
                        </Box>
                    </Flex>
                
                    </Box>
                )
            })
            }
    </>
  )
}

export default AttendanceAgg;