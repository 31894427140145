import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import React, { useState } from 'react'
import CustomInput from '../../../components/customInput/customInput';
import PrimaryButton from '../../../components/primaryButton/primarybutton';
import axiosInstance from '../../../services/api';
import { useSelector } from 'react-redux';

const ChangePassword = () => {

  const [oPassword, setOPassword] = useState();
  const [nPassword, setNPassword] = useState();
  const [cPassword, setCPassword] = useState();
  const [loading, setLoading] = useState(false);

  const currentUser = useSelector(({userData})=>  userData?.currentUser);

  const toast = useToast()

  const update = async(e) => {
    e.preventDefault()

    if(nPassword === cPassword) {
        setLoading(true)
        const datax =  {
        currentPassword: oPassword,
        newPassword: nPassword,
        }

        try {
        const {data} = await axiosInstance.patch(currentUser?.role === 'Client' ? `/clients/change-password` : currentUser?.role === 'Agency' ? `/agencies/change-password` : currentUser?.role === 'Basic Subuser' ? `/subusers/change-password` : `/admins/change-password`, datax)
        setOPassword("")
        setNPassword("")
        setCPassword("")
        toast({
            description: data?.message,
            status: "success",
            position: 'top'
        });
        } catch(error) {
        toast({
            title: "An Error Occurred",
            description: error?.response?.data?.message,
            status: "error",
        });
        } finally {
        setLoading(false)
        }
    } else {
        toast({
            title: "An Error Occurred",
            description: "Passwords mismatch, please check and try again!",
            status: "error",
        });
    }
  }

  return (
    <>
     <Flex alignItems={"center"} gap=".8rem" justifyContent={"space-between"}>
        <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>Change Password</Text>
     </Flex>
     <Box bg="#FFF" padding={"2rem"} mt="1rem" borderRadius={"8px"}>
        <Box maxW={"600px"} margin="0 auto">
            <form onSubmit={update}>
                <CustomInput
                placeholder={"Old Password"}
                type="password"
                value={oPassword}
                onChange={(e) => setOPassword(e.target.value)}
                />
                <CustomInput
                placeholder={"New Password"}
                type="password"
                value={nPassword}
                minLength={8}
                onChange={(e) => setNPassword(e.target.value)}
                />
                <CustomInput
                placeholder={"Confirm Password"}
                type="password"
                minLength={8}
                value={cPassword}
                onChange={(e) => setCPassword(e.target.value)}
                />
                <PrimaryButton mt="2rem" type="submit" text="Change Password" loading={loading} />
            </form>
        </Box>
     </Box>
    </>
    
  )
}

export default ChangePassword;