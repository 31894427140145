import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import SecondaryButton from '../../components/secondaryButton/secondarybutton'
import { HiArrowRight } from 'react-icons/hi'
import moment from 'moment'
import CustomDrawer from '../customDrawer/customDrawer'
import EmptyState from '../emptyState/emptyState'
import axiosInstance from '../../services/api'
import LineSkeleton from '../lineSkeleton'
import AddEmployee from './addEmployee/addEmployee'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import classes from '../../pages/dashboard/home/home.module.css'

const EmployeeView = ({setTotalEmployees, size, title}) => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingc, setLoadingc] = useState(false);
  const [triggerClose, setTriggerClose] = useState("");
  const [total, setTotal] = useState("")

  const navigate = useNavigate()

  const currentUser = useSelector(({ userData }) => userData.currentUser);

  const employeeUrl = currentUser?.role === "Agency" ? 'agencies/employees' : currentUser?.role === 'Client' ? 'clients/employees' : 'employees'

  const getEmployees = async() => {
    try {
        const { data } = await axiosInstance.get(`${employeeUrl}?role=${title}&limit=5`)
        setEmployees(data?.data)
        setTotalEmployees(data?.totalCount)
        setTotal(data?.totalCount)
    } catch(error) {
        console.error(error?.response?.data?.message)
    } finally {
        setLoading(false)
    }
  }

  useEffect(() => {
    getEmployees()
  }, [triggerClose])

  return (
    <Box boxShadow="0px 1px 2px 0px #1018280D" flex={["1 0 300px", "1 1 400px"]} bg="#FFF" border=".5px solid #F2F4F7" p="1.3rem" borderRadius={"12px"}>
        <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Text whiteSpace={"nowrap"} fontWeight={"500"} fontSize={"1.15rem"} color={"#27272A"}>{title}s <span style={{padding: ".1rem .3rem", borderRadius: "50%", backgroundColor: "#F9FAFB", border: "1px solid #EAECF0", fontSize: "12px", fontWeight: "500", color: "#344054"}}>{total}</span></Text>
            <Flex alignItems={"center"} gap=".8rem">
                <CustomDrawer 
                position={"right"}
                title={`Add ${title}`}
                triggerClose={triggerClose}
                setTriggerClose={setTriggerClose}
                toggleElement={
                    <button className={classes.createButton}>{`Add ${title}`}</button>

                }
                content={
                    <AddEmployee title={title} loading={loadingc} setTriggerClose={setTriggerClose} setLoading={setLoadingc} />
                }
                />
            </Flex>
        </Flex>
        <Divider my="1.5rem" />
        <Box height={size === 'full' ? "auto" : "267.41px"} overflow={size === "full" ? "none": "scroll"}
        sx={
            { 
            '::-webkit-scrollbar':{
                display:'none'
            }
            }
        }>
            {loading ? 
            <LineSkeleton num={6} />
            :
            employees?.length < 1 ?
            <EmptyState mt="1.5rem" text="There are no employees yet" />
            :
            employees?.map((({id, firstName, lastName, email, profilePicture, isApproved}) => {
                return (
                    <Flex key={id} padding={"0 .7rem"} borderRadius={"16px"} mb="1.7rem">
                        <Flex alignItems={"center"} w="100%" justifyContent={"space-between"}>
                            <Flex alignItems={"center"}>
                                <Box height={"50px"} width={"60px"} backgroundImage={profilePicture} backgroundSize={"contain"} backgroundRepeat={"no-repeat"}  />
                                <Box>
                                    <Text mb=".2rem" color={"#667085"} textTransform={"capitalize"} fontWeight={"500"}>{firstName + " " + lastName}</Text>
                                    <Text fontWeight={"300"} fontSize={".85rem"} color="#667085">{email}</Text>
                                </Box>
                            </Flex>
                            <Text fontSize={"12px"} fontWeight={"500"} color={"#98A2B3"}>{isApproved ? "Verified" : "Unverified"}</Text>
                        </Flex>
                    </Flex>
                )
            }))
            }
        </Box>

        <br />
        <Text onClick={() => navigate(`/${title?.toLowerCase()}s`)} color={"#ff6f61"} fontSize={".9rem"} fontWeight={"600"} cursor={"pointer"}>See more</Text>
    </Box>
  )
}

export default EmployeeView;