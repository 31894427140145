import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import GoBack from '../../../../../../components/goBack'
import Mechanics from '../../mechanics/mechanics'
import CustomTabs from '../../../../../../components/customTabs/customTabs'
import SpinLoader from '../../../../../../components/spinLoader/spinLoader'
import axiosInstance from '../../../../../../services/api'
import ShelfAndPosm from './components/shelfPosm/shelfPosm'
import Inventory from './components/inventory/inventory'
import Sales from './components/sales/sales'
import Competitors from './components/competitors/competitors'
import StoreTasks from './components/storeTasks/storeTasks'
import Attendance from './components/attendance/attendance'
import Overview from '../../../../../../components/overView/overView'
import CustomSelect from '../../../../../../components/customSelect/customSelect'
import PriceCheck from './components/priceCheck/priceCheck'
import Shipments from './components/shipments/shipments'
import StoreInventory from './components/storeInventory/storeInventory'
import Orders from './components/orders/orders'
import Payments from './components/payments/payments'
import MessagesTable from './components/messages/messages'
import Msl from './components/msl/msl'
import Sos from './components/sos/sos'
import { formatNumber } from '../../../../../../utils/helper'
import { constants } from '../../../../../../utils/constants'
import Rewards from '../../rewards/rewards'

const InitiativeStoreDetails = () => {
    const [initiativeStore, setInitiativeStore] = useState("")
    const [loading, setLoading] = useState(true)
    const [loading2, setLoading2] = useState(true)
    const [analyticsData, setAnalyticsData] = useState(null);
    const {sid, iid} = useParams()

    const [brand, setBrand] = useState("");

    const getInitiativeStore = async() => {
        try {
            const { data } = await axiosInstance.get(`initiative-stores/${sid}`)
            setInitiativeStore(data?.data)
            setBrand(data?.data?.initiative?.brands[0]?._id)
        } catch(error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading(false)
        }
      }
    
      useEffect(() => {
        getInitiativeStore()
      }, [])


     

    const options1 = initiativeStore?.initiative?.brands?.map(i => ({
        title: `${i?.name}-${i?.sku}`,
        value: i?._id
      }))

    const getSalesAnalytics = async() => {
        setLoading2(true)
        try {
            const { data } = await axiosInstance.get(`/sales/analytics/initiative-store?initiativeStoreId=${sid}&brandName=${initiativeStore?.initiative?.brands?.filter(i => i._id === brand)[0]?.name}&sku=${initiativeStore?.initiative?.brands?.filter(i => i._id === brand)[0]?.sku}&target=${initiativeStore?.initiative?.brands?.filter(i => i._id === brand)[0]?.target}&durationInDays=${initiativeStore?.initiative?.brands?.filter(i => i._id === brand)[0]?.durationInDays}`)
            // const { data } = await axiosInstance.get(`/sales/analytics/initiative?initiativeId=${id}&brandName=${JSON.parse(brand)?.name ?? details?.initiative?.brands[0]?.name}&sku=${JSON.parse(brand)?.sku ?? details?.initiative?.brands[0]?.sku}&target=${JSON.parse(brand)?.target ?? details?.initiative?.brands[0]?.name}`)
            setAnalyticsData(data?.data)
        } catch(error) {
            console.error(error?.response?.data?.message)
        } finally {
            setLoading2(false)
        }
    }

    useEffect(() => {
        getSalesAnalytics()
    }, [brand])


    const datax = [
        {
            title: "Sales achieved",
            value: analyticsData?.totalUnitsSold || 0,
        },
        {
            title: "Sales achieved (cases)",
            value: formatNumber(analyticsData?.totalCase) || 0,
        },
        {
            title: "Total Value",
            value: `${constants?.currency}${analyticsData?.totalUnitsSold?.toLocaleString()}` || 0,
        },
        {
            title: "Sales target",
            value: analyticsData?.target || 0,
        },
        {
            title: 'Target met',
            value: `${analyticsData?.targetMetPercentage || 0}%`,
        },
        {
            title: "Time gone",
            value: `${analyticsData?.daysGone || 0}/${analyticsData?.durationInDays || 0} (${analyticsData?.timeGonePercentage || 0}%)`,
        }
    ]

    const data = [
        {
            title: "Mechanics",
            render: <Mechanics data={initiativeStore?.initiative} />
        },
        {
            title: "Attendance",
            render: <Attendance initiativeStore={initiativeStore} />
        },
        {
            title: "Shelf & POSM",
            render: <ShelfAndPosm data={initiativeStore} src={"min"} />
        },
        {
            title: "Competitors",
            render: <Competitors data={initiativeStore?.initiative} />
        },
        {
            title: "Price Check",
            render: <PriceCheck data={initiativeStore?.initiative} />
        },
        {
            title: "Sales",
            render: <>
                        <Flex gap={".7rem"} alignItems={"center"} mb=".8rem">
                            <CustomSelect
                                selection={brand}
                                setSelection={setBrand}
                                options={options1}
                                height="37px"
                                fontSize=".8rem"
                                width="150px"
                            />
                        </Flex>
                      <Overview data={datax} loading={loading2} />
                      <br />
                      <Sales data={initiativeStore?.initiative} />
                    </>
        },
        {
            title: "Shelf Inventory",
            render: <Inventory data={initiativeStore?.initiative} />
        },
        {
            title: "Shipments",
            render: <Shipments data={initiativeStore?.initiative} />
        },
        {
            title: "Orders",
            render: <Orders data={initiativeStore?.initiative} />
        },
        {
            title: "MSL",
            render: <Msl data={initiativeStore?.initiative} />
        },
        {
            title: "Share of Shelf",
            render: <Sos data={initiativeStore?.initiative} />
        },
        {
            title: "Payments",
            render: <Payments data={initiativeStore?.initiative} />
        },
        {
            title: "Store Inventory",
            render: <StoreInventory data={initiativeStore?.initiative} />
        },
        {
            title: "Rewards",
            render: <Rewards data={initiativeStore} />
        },
        {
            title: "Tasks",
            render: <StoreTasks />
        },
        {
            title: "Message Center",
            render: <MessagesTable initiativeId={initiativeStore?.initiative?._id} />
        },
    ]
  return (
    <Box>
        <GoBack />
        {loading ?
        <SpinLoader />
        :
        <Box bg="#FFF" mt="1rem" p=".7rem 1rem">
            <Text color="#1D2939" fontWeight={"500"} fontSize={"1.4rem"} textTransform={"capitalize"}>{initiativeStore?.store?.name}</Text>
            <Text color="#1D2939" fontWeight={"500"} opacity={".7"} fontSize={"1rem"} textTransform={"capitalize"}>{initiativeStore?.store?.address}</Text>
            <Box mt="1.5rem">
                <CustomTabs data={data} />
            </Box>
        </Box>}
        
        
    </Box>
  )
}

export default InitiativeStoreDetails